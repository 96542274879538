<script>
export default {

  props: [
    'item',
  ],


  computed: {

    activeThumbnail: function(){
      var worn = this.item.thumbnail_worn;
      if (false) {} // noop
      else if (worn==='blank') { return null }
      else if (worn==='self') { return this.item.thumbnail }
      else if (worn==='urldata') { return this.item.urldata.thumbnail }
      // else... choose automatically urldata thumbnail
      return this.item.urldata.thumbnail ? this.item.urldata.thumbnail : null;
    },

  },

  methods: {

    triggerURLScrape: function(){
      this.$axios({
        url: this.$store.state.api + '/trigger-url-scrape/',
        method: 'post',
        data: {
          url: this.item.url,
        },
      }).then(function(response){
        this.$alertify.success('Rescrape Success!');
      }.bind(this), function(error){
        this.$alertify.error('Rescrape Error!');
      }.bind(this));
    },


  },

};
</script>


<template>

  <div class="viewer-details">

    <div class="viewer-details-notice-wrapper">
      <div class="viewer-details-notice"><span class="icon icon-help"></span> You can have deeper look into this link with details below.</div>
    </div>

    <div class="viewer-details-content">


      <div class="viewer-detail-spacer"></div>


      <div class="viewer-detail">
        <div class="viewer-detail-title">Link URL Address</div>
        <div class="viewer-detail-content" v-if="item.url">
        {{item.url}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>


      <!--
      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Private</div>
        <div class="viewer-detail-content" v-if="item.is_private">
        Yes</div>
        <div class="viewer-detail-content  mod-empty" v-else>Not Provided</div>
      </div> -->


      <div class="viewer-detail-spacer"></div>


      <div class="viewer-detail">
        <div class="viewer-detail-title">Date Added</div>
        <div class="viewer-detail-content" v-if="item.date_created">
        {{$dfFormatC(item.date_created)}}
        ({{item.date_created|dfFormatDistanceFromNow}})</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Date Updated</div>
        <div class="viewer-detail-content" v-if="item.date_updated">
        {{$dfFormatC(item.date_updated)}}
        ({{item.date_updated|dfFormatDistanceFromNow}})</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Rating</div>
        <div class="viewer-detail-content" v-if="item.rating">
        {{item.rating}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>


      <div class="viewer-detail">
        <div class="viewer-detail-title">Label</div>
        <div class="viewer-detail-content" v-if="item.owner_notes">
        {{item.owner_notes}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>




      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Custom Title</div>
        <div class="viewer-detail-content" v-if="item.title">
        {{item.title}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Custom Description</div>
        <div class="viewer-detail-content" v-if="item.description">
        {{item.description}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>


      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Default Link Title</div>
        <div class="viewer-detail-content" v-if="item.urldata.title">
        {{item.urldata.title}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      <div class="viewer-detail">
        <div class="viewer-detail-title">Default Link Description</div>
        <div class="viewer-detail-content" v-if="item.urldata.description">
        {{item.urldata.description}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>



      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail ">
        <div class="viewer-detail-title">URL Data Thumbnail (Source)</div>
        <div class="viewer-detail-content" v-if="item.urldata.thumbnail">
        {{item.urldata.thumbnail}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      <div class="viewer-detail ">
        <div class="viewer-detail-title">URL Data Thumbnail</div>
        <div class="viewer-detail-content  mod-thumbnail" v-if="item.urldata.thumbnail">
        <img :src="item.urldata.thumbnail"></div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>


      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail ">
        <div class="viewer-detail-title">Bookmark Thumbnail (Source)</div>
        <div class="viewer-detail-content" v-if="item.thumbnail">
        {{item.thumbnail}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      <div class="viewer-detail ">
        <div class="viewer-detail-title">Bookmark Thumbnail</div>
        <div class="viewer-detail-content  mod-thumbnail" v-if="item.thumbnail">
        <img :src="item.thumbnail"></div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>


      <div class="viewer-detail-spacer"></div>


      <div class="viewer-detail ">
        <div class="viewer-detail-title">Thumbnail Worn</div>
        <div class="viewer-detail-content" v-if="item.thumbnail_worn">
        {{item.thumbnail_worn}}</div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>


      <!--

      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail ">
        <div class="viewer-detail-title">Thumbnail</div>
        <div class="viewer-detail-content  mod-thumbnail" v-if="activeThumbnail">
        <img :src="activeThumbnail"></div>
        <div class="viewer-detail-content  mod-empty" v-else>Empty</div>
      </div>

      -->


      <div class="viewer-detail-spacer"></div>

      <div class="viewer-detail ">
        <div class="viewer-detail-title">Rescrape URL Data</div>
        <div class="viewer-detail-content">
          <button @click.stop.prevent="triggerURLScrape">Trigger Rescrape</button>
        </div>
      </div>

    </div>

  </div>



</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .viewer-details {
    background-color: white;
    display: block;
  }

    .viewer-details-content {
      padding: 5px;
      padding-top: 0;
      display: block;
      padding-bottom: 100px;
    }

    .viewer-detail {
      margin-bottom: 15px;
      display: block;
    }

    .viewer-detail-spacer {
      margin: 30px 0;
      height: 1px;
      //background-color: lighten(#eee,3);

    }

    .viewer-detail-title {
      font-weight: 600;
      display: inline-block;
      font-size: 13px;
      width: 30%;
      padding-right: 10px;
      text-align: right;
      margin-top: 2px;
    }

    .viewer-detail-content {
      font-weight: 400;

      font-size: 12px;
      color: #555;

      width: 60%;

      //background-color: #eee;
      display: inline-block;
      border-bottom: 1px solid #eee;
      padding: 2px 4px;
      padding-bottom: 1px;
      border-radius: 2px;
      line-height: 1.2;

      font-size: 13px;

      strong {
        font-weight: 600;
      }

      & > img {
        max-width: 100%;
      }



      &.mod-empty {
        font-style: italic;
        color: #999;
        border-color: transparent;
      }

      &.mod-thumbnail {
        border: none;
        border-radius: 2px;
        overflow: hidden;
        background-color: #eee;
        padding: 5px;
        width: auto;
        & > img {
          max-width: 200px;
        }
      }
    }


    .viewer-details-notice-wrapper {
      display: block;
      text-align: center;
      //text-align: left;
      //margin-bottom: 25px;

      background-color: white;
      border-bottom: 1px solid darken(white,8);

      &.mod-copy {
        //box-shadow: 0 2px 5px 0 rgba(black,.2);
        //position: absolute;
        //bottom: 0;
        //left: 0;
        //right: 0;
      }
    }

    .viewer-details-notice {
      display: inline-block;
      color: #555;
      padding: 5px 10px;
      font-size: 13px;
      text-align: center;
      margin-top: 0px;
      width: 100%;
      //max-width: 600px;
      background-color: darken(white,4);

      & > .icon::before {
        line-height: 1.35;
      }


    }



</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .viewer-details {
    background-color: darken(white, 75);
  }




  .viewer-details-content {
  }


  .viewer-detail {
    color: rgba(white,.9);
  }

  .viewer-detail-spacer {
    //background-color: lighten(#eee,3);

  }

  .viewer-detail-title {
  }

  .viewer-detail-content {
    color: rgba(white,.9);

    border-color: darken(white,60);


    &.mod-empty {
      color: rgba(white,.9);
      border-color: transparent;
    }

    &.mod-thumbnail {
      background-color: darken(white, 70);
    }
  }


  .viewer-details-notice-wrapper {
    background-color: darken(white, 70);
    border-color: darken(white,65);
  }

  .viewer-details-notice {
    color: rgba(white,.9);
    background-color: darken(white, 70);
  }


}

</style>
