<script>
export default {
};
</script>


<template>
<div class="router-page">
<div class="error-page">

  <div class="big-number">401</div>
  <div class="text">&mdash; Unauthenticated</div>

</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .error-page {
    padding: 20px;
    display: block;
    height: 100%;
    min-height: calc( 100vh - 55px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .big-number {
    display: inline-block;
    vertical-align: middle;
    font-size: 40px;
    font-weight: 400;
    color: rgba(black,.9);
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    color: rgba(black,.5);
  }

</style>
