<script>
import _clone from 'lodash/clone';
import axios from 'axios';
export default {

  props: {
    sidebarContext: false,
  },

  data: function(){
    return {
    }
  },

  computed: {
    activeNav: function(){
      var kind = this.$route.meta.kind;
      if (kind=='all') { return 'library' };
      if (kind=='search') { return 'library' };
      //if (kind=='discover') { return 'social' };
      //if (kind=='discover') { return 'explore' };
      if (kind=='feed') { return 'social' };
      if (kind=='tagpage') {
        if (this.$store.state.user.is_anonymous) {
          return null;
        }
        else if (this.$route.params.username != this.$store.state.user.username) {
          //return 'social';
          return null;
        } else {
          return 'library';
        }
      }
      return null;
    },
    unseenItems: function(){
      if (this.$store.state.user.is_anonymous) { return false }
      if (!this.$store.state.unseenItems) { return false }
      return this.$store.state.unseenItems.count;
    },
  },



  methods: {
    strtobool: function(val){
      return (val===true) || val==="true" || val===1;
    },
    navClick: function(routePath, authRequired){
      if (authRequired && this.$store.state.user && this.$store.state.user.is_anonymous) {
        this.$store.commit('dialogbox', {kind: 'signinup', subkind: 'signup'});
        this.$cookie.set('redirectAfterAuth', encodeURIComponent(routePath));
        return;
      }
      this.$router.push(routePath);
    },
    logoClick: function(){
      if (this.$store.state.user.is_anonymous) return this.$router.push('/');
      this.$router.push('/all');
    },
  },

};
</script>


<template>
<div
  class="topbar"
  :class="{
    'mod-sidebar-context': $props.sidebarContext,
    'mod-sidebar-collapse': $props.sidebarContext && $store.getters.finalSidebarCollapse
  }"
>
<div class="topbar-inner">


  <div class="topbar-section  mod-nav">

    <button v-if="!$props.sidebarContext || !$store.getters.finalSidebarCollapse" type="button" @click.prevent="logoClick" class="topbar-logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438 668"><path fill="#000000" class="cls-1" d="M376.29,0h-33a60.31,60.31,0,0,0-54.49,34.46l-35,73.69a14.65,14.65,0,0,0,13.23,20.92h71.62c19.15,0,29.42,22.53,16.85,37-69.92,80.39-190.26,216-251.8,287-5.68,6.56-16.18-.2-12.36-8C120.5,385.68,180.81,268,209.8,208.45a7.42,7.42,0,0,0-6.68-10.67H115c-17.16,0-26.44-20.1-15.32-33.16l99-116.32C214.94,29.28,201.42,0,176.42,0H61.71A61.31,61.31,0,0,0,.4,61.31V636.42c0,29.36,36.28,43.16,55.79,21.23L173.86,525.41a60.42,60.42,0,0,1,90.28,0L381.81,657.65c19.52,21.93,55.79,8.13,55.79-21.23V61.31A61.31,61.31,0,0,0,376.29,0Z"/></svg>
    </button>



    <!--
    <a v-if="!$props.sidebarContext" href="/all" @click.prevent="navClick('/all', true)" class="topbar-library-btn">
     <span v-if="$store.state.user.is_authenticated">← My Library</span>
     <span v-if="$store.state.user.is_anonymous">Create Account</span>
    </a>
     -->


    <!--
    <div class="topbar-nav">
      <a href="/all" @click.prevent="navClick('/all', true)" class="topbar-nav-item  mod-library" :class="{'mod-active': activeNav=='library', 'mod-anon': $store.state.user.is_anonymous}">
        <span class="icon"><iconsvg icon="feather-layers" /></span>
        <span class="text">Library</span>
      </a>
      <a href="/feed" @click.prevent="navClick('/feed', true)" class="topbar-nav-item" :class="{'mod-active': activeNav=='social', 'mod-anon': $store.state.user.is_anonymous}">
        <span class="icon"><iconsvg icon="feather-radio" /></span>
        <span class="text">Feed</span>
        <div v-if="unseenItems" class="topbar-unseen-items" title="New unseen links count">{{unseenItems}}</div>
      </a>
      <a href="/discover" @click.prevent="navClick('/discover')" class="topbar-nav-item" :class="{'mod-active': activeNav=='explore'}">
        <span class="icon"><iconsvg icon="feather-grid" /></span>
        <span class="text">Discover</span>
      </a>
    </div>
    -->

  </div>


  <div class="topbar-section  mod-user" v-if="$store.state.user.is_anonymous">

    <updatenotice />

    <button type="button" class="topbar-menu-btn" @click.stop.prevent="$store.commit('userMenu', !$store.state.userMenu)">
      <iconsvg icon="more-filled" />
    </button>
  </div>

  <div class="topbar-section  mod-user"  v-if="$store.state.user.is_authenticated">


    <updatenotice />


    <!--
    <button type="button" @click.stop.prevent="$store.commit('activityVisible', !$store.state.activityVisible);" class="topbar-activity-btn" :class="{'mod-has-unread': $store.state.unreadNotifs}">
      <span v-if="$store.state.unreadNotifs" class="text">{{$store.state.unreadNotifs}}</span>
      <span class="icon"><iconsvg icon="feather-activity" /></span>
     -->
      <!--
      <div class="topbar-activity-loading" v-if="!$store.state.unreadNotifs">
        <spinner kind="circle" dark="true"></spinner>
      </div> -->
      <!--
    </button>
       -->

    <button type="button" @click.stop.prevent="$store.commit('userMenu', !$store.state.userMenu)" class="topbar-user-box">

      <div class="topbar-user-image">
        <div class="topbar-user-image-img">
          <img :src="$store.state.user.profile_image" />
        </div>
        <div class="topbar-user-image-badge" v-if="$store.state.user.is_premium" title="'You are on GGather Plus'">
          <svg viewBox="0 0 24 24" version="1.1" width="24px" height="24px">
            <path d="M 14.28125 2 L 6 13 L 11 13 L 9.28125 22 L 18 11 L 13 11 Z "/>
          </svg>
        </div>
      </div>


    </button>


  </div>

</div>
</div>
</template>



<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";


  .topbar {
    top: 0;
    left: 0;
    right: 0;

    height: 45px;
    position: absolute;
    z-index: 57;

    //background: white;
    border-bottom: 1px solid darken(white, 10);
    background: darken(white,2);
    //box-shadow: 0 0 20px 0 rgba(black,.07), 0 0 5px 0 rgba(black,.2);

    html.mod-transitions-enabled & {
      transition: background-color 100ms ease-in, border-color 100ms ease-in, transform 300ms ease-in-out;
    }


    //html.mod-topbar-color-applied & {}
    //html.mod-topbar-color-1 & {
    //  background-color: darken($clr-tag-1, 0);
    //  border-color:     darken($clr-tag-1, 10);
    //}
    //html.mod-topbar-color-2 & {
    //  background-color: darken($clr-tag-2, 0);
    //  border-color:     darken($clr-tag-2, 10);
    //}
    //html.mod-topbar-color-3 & {
    //  background-color: darken($clr-tag-3, 0);
    //  border-color:     darken($clr-tag-3, 10);
    //}
    //html.mod-topbar-color-4 & {
    //  background-color: darken($clr-tag-4, 0);
    //  border-color:     darken($clr-tag-4, 10);
    //}
    //html.mod-topbar-color-5 & {
    //  background-color: darken($clr-tag-5, 0);
    //  border-color:     darken($clr-tag-5, 10);
    //}
    //html.mod-topbar-color-6 & {
    //  background-color: darken($clr-tag-6, 0);
    //  border-color:     darken($clr-tag-6, 10);
    //}
    //html.mod-topbar-color-7 & {
    //  background-color: darken($clr-tag-7, 0);
    //  border-color:     darken($clr-tag-7, 10);
    //}
    //html.mod-topbar-color-8 & {
    //  background-color: darken($clr-tag-8, 0);
    //  border-color:     darken($clr-tag-8, 10);
    //}
    //html.mod-topbar-color-9 & {
    //  background-color: darken($clr-tag-9, 0);
    //  border-color:     darken($clr-tag-9, 10);
    //}



    @media (max-width: 700px){
      //box-shadow: 0 0 5px 0 rgba(black,.03), 0 0 5px 0 rgba(black,.1);
      //box-shadow: none;
      border-bottom: 1px solid darken(white,10);
    }


  }


    .topbar-inner {
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      //max-width: 1200px;
      //margin: 0 auto;
    }

    .topbar-section {

      &.mod-nav {
      }
      &.mod-user {
        display: flex;
        align-items: center;
      }
    }




  .topbar-activity-btn {
    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
    position: relative;

    outline: none;

    & > .icon {
      padding-top: 10px;
      padding-right: 2px;
      display: inline-block;
    }

    & > .icon svg {
      width: 16px;
      height: 16px;
      opacity: .6;
      stroke: rgba(black,1);
    }

    //html.mod-topbar-color-applied & > .icon svg {
    //  stroke: rgba(white,.9);
    //}


    & > .text {
      display: inline-block;
      font-weight: 600;

      position: absolute;
      top: 7px;
      left: 0px;


      width: 21px;
      height: 21px;
      line-height: 21px;
      border-radius: 50px;
      display: block;
      background-color: $clr-orange-400;


      font-weight: 700;
      font-size: 11px;
      text-align: center;
      z-index: 10;

      opacity: 1;

      background-color: $clr-ggather;
      border-color: $clr-ggather;
      color: white;

    }


    //html.mod-topbar-color-applied & > .text {
    //  background-color: white;
    //  border-color: white;
    //  color: $clr-ggather;
    //}

    &:hover {
      & > .icon {
        svg {
          opacity: .8;
        };
      }
    }


    &.mod-has-unread {
      & > .icon {
        svg {
          opacity: .9;
        }
      }
    }


    @media (max-width: 750px) {
      margin-right: 5px;
    }


    @media (max-width: 360px){
      margin-top: 6px;
      margin-right: 5px;
      font-size: 15px;
      & > .icon {
        svg {
          width: 14px;
          height: 14px;
        }
      }
      & > .text {
        top: 4px;
        width: 19px;
        height: 19px;
        line-height: 18px;
        font-size: 10px;

      }
    }



  }



  .topbar-activity-loading {
    pointer-events: none;
    position: absolute;
    top: 13px;
    left: 7px;
    z-index: 100;
    transform: scale(1.3);
  }




  .topbar-logo {
    display: inline-block;
    margin-top: 0px;
    outline: none;
    //cursor: default;
    //border-left: 1px solid #eee;
    //border-right: 1px solid #eee;
    //background-color: darken(white,2);
    margin-top: 12px;
    margin-left: 15px;
    text-align: center;
    //border-radius: 2px;
    & > svg {
      width: 13px;
      display: inline-block;
      position: relative;
      z-index: 5;
      transform-origin: 50% 50%;
      transform: scale(1.00);
    }
    html.mod-transitions-enabled & > svg {
      transition:  transform 150ms cubic-bezier(0.175, 0.885, 0.32, 2);
    }


    & > svg > path {
      fill: $clr-ggather;
    }

    //html.mod-topbar-color-applied & > svg > path {
    //  fill: rgba(white,.9);
    //}

    html.mod-transitions-enabled & > svg > path {
      transition: fill 100ms;
    }


  }


  .topbar-library-btn {
    display: inline-block;
    background: $clr-ggather;
    border: $clr-ggather;
    color: rgba(white,1);
    font-size: 13px;
    border-radius: 999px;
    margin-left: 10px;
    margin-top: 9px;
    padding: 4px 10px 4px 10px;
    padding-right: 12px;
    font-weight: 600;
    html.mod-transitions-enabled & {
      transition: border-color 100ms, background-color 100ms;
    }
    &:hover {
      background: $clr-ggather;
      border-color:  $clr-ggather;
    }
  }


  /*
  .topbar-nav {
    display: inline-block;

    .topbar.mod-sidebar-collapse & {
      padding-left: 42px;
    }

  }
  */


  /*
  .topbar-nav-item {
    font-size: 14px;
    display: inline-block;
    font-weight: 400;
    margin-left: 28px;
    margin-right: 5px;
    margin-top: 12px;
    padding-top: 1px;
    position: relative;

    & > .icon {
      vertical-align: middle;
      display: inline-block;
      position: relative;
      z-index: 2;
      padding: 5px;
      /deep/ svg {
        width: 18px;
        height: 18px;
        stroke: rgba(black,1);
      }
      opacity: .3;
    }

    //html.mod-topbar-color-applied & > .icon > svg {
    //  stroke: rgba(white,.6);
    //}

    & > .text {
      position: relative;
      z-index: 3;
      vertical-align: middle;

      display: none;
      margin-right: 1px;
      opacity: .6;

      color: rgba(black,1);
      font-weight: 600;
    }
    &.mod-library > .text {

    }
    //html.mod-transitions-enabled & > .text {
    //  transition: color 400ms ease-out;
    //}
    //html.mod-topbar-color-applied & > .text {
    //  color: rgba(white,1);
    //}


    //&::before {
    //  position: absolute;
    //  top: -4px;
    //  left: -14px;
    //  right: -18px;
    //  bottom: -5px;
    //  z-index: 1;
    //  border-radius: 4px;
    //  content: '';
    //  opacity: 0;
    //  transform: scale(.5);
    //  background-color: rgba($clr-ggather,.1);
    //  border: 1px solid rgba($clr-ggather,.1);
    //  //html.mod-transitions-enabled & {
    //  //  //transition: opacity 200ms ease-out, transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.2); // bouncy
    //  //  transition: opacity 300ms ease-out;
    //  //}
    //}



    //html.mod-topbar-color-applied &::before {
    //  background-color: rgba(white,.2);
    //  border: 1px solid rgba(white,.1);
    //}


    &:hover {
      & > .text {
        opacity: .8;
      }
      & > .icon {}
    }


    &.mod-active {
      & > .text {
        display: inline-block;
        opacity: .8;
      }
      & > .icon {
        opacity: .8;
      }
      &::before {
        opacity: .8;
        transform: scale(1);
      }
    }


    &.mod-anon {
      & > .text {
        opacity: .1;
      }
      & > .icon {}
    }


    @media (max-width: 900px){
      & > .text {
        display: none;
      }
      & > .icon {}
    }


    @media (max-width: 630px){
      &.mod-active > .text, & > .text {
        display: none;
      }
      &::before {
        right: -16px;
      }
    }

  }
  */












  .topbar-user-box {
    display: inline-block;
    text-align: left;
    margin-top: 12px;
    font-size: 0;
    outline: none;
    margin-right: 15px;


    @media (max-width: 780px) {
      margin-right: 15px;
    }

  }


    .topbar-user-image {
      display: inline-block;
      margin-top: 0px;
      position: relative;

    }

      .topbar-user-image-img {
        width: 22px;
        overflow: hidden;
        border-radius: 100px;
        background-color: darken(white,6);
        //border: 1px solid darken(white,10);
        display: block;
        position: relative;
        top: -1px;
        opacity: 0.9;

        //border: 1px solid darken($clr-ggather,5);

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        &:hover, &:focus {
          opacity: 1;
        }

        & > img {
          max-width: 100%;
          border-radius: 100px;
        }


      }

      .topbar-user-image-badge {
        position: absolute;
        right: -6px;
        top: -4px;
        width: 14px;
        height: 14px;
        background: white;
        border-radius: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        svg {
          width: auto;
          height: 12px;
          path {
            fill: $clr-ggather;
          }
        }

      }


    .topbar-user-label {
      display: inline-block;
    }


      .topbar-user-username {
        color: rgba(black,.8);
        font-weight: 600;
        font-size: 15px;
        display: block;
        line-height: 1;

        width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }
      .topbar-user-status {
        color: rgba(black,.5);
        font-weight: 400;
        font-size: 11px;
        display: block;
        line-height: 1;
      }




  .topbar-anon-btn {

    display: inline-block;
    color: rgba(white,.8);
    font-size: 14px;
    margin-top: 13px;
    margin-right: 0px;
    font-weight: 400;
    padding: 5px 5px 5px 10px;
    outline: none;
    position: relative;
    html.mod-transitions-enabled & {
      transition: color 100ms ease-out;
    }

    &:hover {
      color: rgba(white,1);
    }

  }




  .topbar-menu-btn {
    display: inline-block;
    color: rgba(white,.8);
    font-size: 20px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 5px 10px 5px 10px;
    outline: none;
    position: relative;
    opacity: .8;

    & /deep/ svg {
      width: 16px;
      height: 16px;
      fill: gray;
    }

    &:hover {
      opacity: 1;
    }

  }




    .topbar-unseen-items {
      position: absolute;
      top: 0px;
      left: 15px;
      width: 15px;
      height: 15px;
      line-height: 12px;
      border-radius: 50px;
      display: block;
      background-color: $clr-orange-400;
      border: 1px solid transparent;
      font-weight: 600;
      font-size: 10px;
      html.mod-transitions-enabled & {
        transition: right 100ms ease-out;
      }
      text-align: center;
      z-index: 10;

      //&.mod-highlighted {
        opacity: 1;
        background-color: #ff8711;
        border-color: #ff8711;
        color: white;
      //}

      color: rgba(white,1);

      //&.both-visible.unseen-items {
      //    right: 10px;
      //}

    }




</style>

















<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .topbar {
    //background-color: darken(white,80);
    border-color: darken(white,78);
    background: darken(white,85);
    //box-shadow: 0 0 20px 0 rgba(black,.1), 0 0 5px 0 rgba(black,.2);
    //@media (max-width: 700px){
    //  box-shadow: none;
    //}
  }


  //&.mod-topbar-color-applied .topbar {
  //}
  //&.mod-topbar-color-1 .topbar {
  //  //background-color: darken($clr-tag-dark-1, 0);
  //  background-color: darken(white,80);
  //  border-color: darken(white,75);
  //}
  //&.mod-topbar-color-2 .topbar {
  //  background-color: darken($clr-tag-dark-2, 0);
  //  border-color: lighten($clr-tag-dark-2, 5);
  //}
  //&.mod-topbar-color-3 .topbar {
  //  background-color: darken($clr-tag-dark-3, 0);
  //  border-color: lighten($clr-tag-dark-3, 5);
  //}
  //&.mod-topbar-color-4 .topbar {
  //  background-color: darken($clr-tag-dark-4, 0);
  //  border-color: lighten($clr-tag-dark-4, 5);
  //}
  //&.mod-topbar-color-5 .topbar {
  //  background-color: darken($clr-tag-dark-5, 0);
  //  border-color: lighten($clr-tag-dark-5, 5);
  //}
  //&.mod-topbar-color-6 .topbar {
  //  background-color: darken($clr-tag-dark-6, 0);
  //  border-color: lighten($clr-tag-dark-6, 5);
  //}
  //&.mod-topbar-color-7 .topbar {
  //  background-color: darken($clr-tag-dark-7, 0);
  //  border-color: lighten($clr-tag-dark-7, 5);
  //}
  //&.mod-topbar-color-8 .topbar {
  //  background-color: darken($clr-tag-dark-8, 0);
  //  border-color: lighten($clr-tag-dark-8, 5);
  //}
  //&.mod-topbar-color-9 .topbar {
  //  background-color: darken($clr-tag-dark-9, 0);
  //  border-color: lighten($clr-tag-dark-9, 5);
  //}


  .topbar-logo > svg > path {
    fill: $clr-ggather-dark;
    fill: #ececec;
  }


  .topbar-library-btn {
    background-color: darken(white,80);
    border-color: 1px solid darken(white,75);
    color: rgba(white,1);
  }




  .topbar-nav-item {

    &.mod-library > .text {
    }

    & > .text {
      color: rgba(white,.6);
      font-weight: 600;
    }
    & > .icon > svg {
      stroke: rgba(white,.6);
    }

    &::before {
      background: rgba(white,.07);
      border-color: rgba(white,.06);
    }


    &.mod-active {
      &::before {}
      & > .text {
        color: rgba(white,1);
      }
      & > .icon > svg {
        stroke: rgba(white,.9);
      }
    }


  }


  //&.mod-topbar-color-applied .topbar-nav-item > .text {
  //  color: rgba(white,1);
  //}




  .topbar-user-image-img {
    //background-color: darken(white,6);
    border-color: darken(white,60);
  }


  .topbar-user-username {
    color: rgba(white,.8);
    font-weight: 400;

  }

  .topbar-user-status {
    color: rgba(white,.5);
  }


  .topbar-activity-btn > .icon svg {
    stroke: rgba(white,1);
  }

  .topbar-activity-btn > .text {
    background-color: white;
    color: $clr-ggather;
  }

  .topbar-search-form .topbar-search-input {


    &::placeholder {
      color: rgba(white,.5);
    }
  }

  .topbar-search-form.mod-focus .topbar-search-input,
  .topbar-search-input:focus {
    border-color: rgba(white,.2);


  }

  .topbar-search-input-wrapper > .icon.icon-search {
    color: rgba(white,.4);
  }

  .topbar-search-type-btn {
    color: rgba(white,.3);
    &.mod-local {
      color: $clr-ggather;
    }
  }

}
</style>
