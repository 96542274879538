<script>
export default {

  /* THIS NEEDS FURTHER WORK */

  props: [
    'anchor',
  ],

  computed: {

    styleObject: function(){
      var e = this.anchor;
      return {
        position: 'fixed',
        top: e.clientY + 'px',
        left: e.clientX + 'px',
        color: 'red',
        fontSize: '13px',
        zIndex: '1000',
      }
    },

  },

  methods: {
  }

};
</script>

<template>
  <portal>
    <div class="popover" :style="styleObject">
      <slot></slot>
    </div>
  </portal>
</template>

<style lang="scss" scoped>
</style>
