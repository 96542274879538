<script>
import _find from 'lodash/find';
export default {

  props: {
    tag: {
      type: Object,
      required: true,
    },
    isParent: {
      type: Boolean,
      required: false,
      default: false,
    },
    mirrorOver: {
      type: Boolean,
      required: false,
      default: false,
    },
    mirrorOverNest: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: function(){
    return {
      menuOpen: false,
      itemDragOver: false,
    }
  },

  computed: {

    routeActive: function(){
      return this.tag.url_path === this.$route.path;
    },

    iconLetter: function(){
      return this.tag.name.toUpperCase().trim().slice(0,1);
    },

    hasChildren: function(){
      return this.tag.children && this.tag.children.length;
    },

  },


  watch: {

    //'mirrorOver': function(){
    //  if (newVal) this.addClass(this.$refs.sbartag, 'mod-mirror-over');
    //  else this.removeClass(this.$refs.sbartag, 'mod-mirror-over');
    //},

    // because draggable.js classes would get overwritten when using Vue binded classes
    // we have to use addClass/removeClass outside of Vue
    'mirrorOverNest': function(newVal){
      if (newVal) this.addClass(this.$refs.sbartag, 'mod-mirror-over-nest');
      else this.removeClass(this.$refs.sbartag, 'mod-mirror-over-nest');
    },




  },



  mounted: function(){
    this.setDnDEvents();
  },

  beforeDestroy: function(){
    this.removeDnDEvents();
  },


  methods: {

    addClass: function(el, className){
      if (el.classList)
        el.classList.add(className);
      else
        el.className += ' ' + className;
    },

    removeClass: function(el, className){
      if (el.classList)
        el.classList.remove(className);
      else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },

    linkClick: function(){
      this.$router.push(this.tag.url_path);
      this.$store.commit('tagPageMeta', JSON.parse(JSON.stringify(this.tag)));
    },




    dndDragEnter: function(event){
      event.preventDefault();
      this.itemDragOver = true;
    },

    dndDragOver: function(event){
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
      this.itemDragOver = true;
    },

    dndDragLeave: function(event){
      event.preventDefault();
      this.itemDragOver = false;
    },

    dndDrop: function(event){
      event.preventDefault();
      try {
        var data = JSON.parse(event.dataTransfer.getData('text'));
        var itemPK =  parseInt( data.itempk );
      } catch(e) { var itemPK = false; }
      itemPK ? this.addLinkToTag( itemPK ) : null;
      event.dataTransfer.clearData();
      this.removeCanvasDragImage();
      this.itemDragOver = false;
    },


    setDnDEvents: function(event){
      if (this.tag.owner.pk != this.$store.state.user.pk) { return false; }
      this.$refs.sbartagSelf.addEventListener('dragenter', this.dndDragEnter, false);
      this.$refs.sbartagSelf.addEventListener('dragover', this.dndDragOver, false);
      this.$refs.sbartagSelf.addEventListener('dragleave', this.dndDragLeave, false);
      this.$refs.sbartagSelf.addEventListener('drop', this.dndDrop, false);
    },

    removeDnDEvents: function(event){
      this.$refs.sbartagSelf.removeEventListener('dragenter', this.dndDragEnter, false);
      this.$refs.sbartagSelf.removeEventListener('dragover', this.dndDragOver, false);
      this.$refs.sbartagSelf.removeEventListener('dragleave', this.dndDragLeave, false);
      this.$refs.sbartagSelf.removeEventListener('drop', this.dndDrop, false);
    },

    removeCanvasDragImage: function(){
      if (window._itemDnDCanvasDragImage) {
        document.body.removeChild(window._itemDnDCanvasDragImage);
        delete window._itemDnDCanvasDragImage;
      }
    },


    addLinkToTag: function(itemPK){
      var itemURL = _find(this.$store.state.items, {pk: parseInt(itemPK)}).url;
      this.$axios({
        url: this.$store.state.api + '/edit-urlbookmark-tag/',
        method: 'post',
        data: {
          tag_pk: this.tag.pk,
          url: itemURL,
          action: 'add',
        },
      }).then(function(response){
        this.$alertify.log('Link was added to ' + this.tag.name + '.')
      }.bind(this));

      // remove from untagged ...
      if ((this.$route.meta.kind=='all') && this.$route.query.untagged) {
        this.$store.commit('deleteItem', {pk: parseInt(itemPK)});
      }

    },




    editTag: function(kind, value){
      var data = {};
      data['pk'] = this.tag.pk;
      data[kind] = value;
      this.$axios({
        url: this.$store.state.api + '/edit-tag/',
        method: 'post',
        data: data,
      }).then(function(response){
      }.bind(this), function(error){
        alert('[sbar-tag/editTag]. Something went wrong while updating tag. Please contact us if this problem persists.');
      }.bind(this));
    },


    toggleCollapse: function(){
      var tagsFilterApplied = this.$store.state.tagsFilter;
      if (tagsFilterApplied) return;

      var displayCollapsedValue = !this.tag.display_collapsed;
      this.$store.commit('updateTag', {pk: this.tag.pk, display_collapsed: displayCollapsedValue});
      this.editTag('display_collapsed', displayCollapsedValue);
    },


  },

};
</script>


<template>
  <div
    :class="[
      'sbartag',
      'mod-color-'+tag.color,
      {
        'mod-route-active': routeActive,
        'mod-tags-filter-applied': $store.state.tagsFilter,
        'mod-collapsed': tag.display_collapsed,
        'mod-is-parent': isParent,
        'mod-has-children': hasChildren,
        'mod-is-children': !isParent,
        'mod-is-visible': tag.__visible,
        'mod-is-hidden': !tag.__visible,
        'mod-item-drag-over': itemDragOver,
      },
    ]"
    draggable-item
    :tagpk="tag.pk"
    ref="sbartag"
    draggable="false"
  >



    <div class="sbartag-marker-sort"></div>

    <div class="sbartag-self" ref="sbartagSelf" @contextmenu.stop.prevent="menuOpen=!menuOpen">


      <button type="button" v-if="hasChildren" key="sbartagiconarrow" class="sbartag-icon-arrow" @click.stop.prevent="toggleCollapse">
        <svg width="15" height="10" viewBox="0 0 15 10">
          <path d="M2.43392 0.278564L7.3067 5.07295L12.1795 0.278565L14.3067 2.37614L7.3067 9.27856L0.306702 2.37614L2.43392 0.278564Z" />
        </svg>
      </button>



      <span v-else class="sbartag-icon" key="sbartagicon">
        <span></span>
      </span>


      <!--
      <div class="sbartag-drag-handle">
        <iconsvg icon="material-menu" />
      </div> -->


      <div class="sbartag-controls">
        <!--
        <button v-if="hasChildren" @click="toggleCollapse" class="sbartag-control-btn mod-expand" >
          <iconsvg icon="material-expand-arrow" />
        </button> -->

        <button ref="menuAnchor" class="sbartag-control-btn mod-menu" @click="menuOpen=true">
          <iconsvg icon="more-filled" />
        </button>

      </div>


      <Popover
        v-if="menuOpen"
        @close="menuOpen=false"
        :popover-style="{boxShadow: '0 2px 5px 0 rgba(0,0,0,.2)', borderRadius:'3px', marginLeft: '5px'}"
        :anchor-el="$refs.menuAnchor"
        :transform-origin="{vertical: 'top', horizontal: 'left'}"
        :anchor-origin="{vertical: 'top', horizontal: 'right'}"
      >
        <sbar-tag-menu
          @close="menuOpen=false"
          :tag="tag"
        />
      </Popover>



      <a :href="tag.url_path" @click.prevent="linkClick" class="sbartag-link" draggable="false">
        <span class="sbartag-name">{{tag.name}}</span>
      </a>

    </div>




    <div v-if="mirrorOver" :class="['sbartag-marker-nest']" :nestpk="tag.pk">
      <iconsvg icon="double-right-filled" />
      <span>Drop Here To Nest</span>
    </div>



    <div class="sbartag-nest-list">
      <sbar-tag
        v-for="childTag in tag.children"
        :key="'sbartagchild'+childTag.pk"
        :tag="childTag"
      />
    </div>



  </div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";


  .sbartag {
    display: block;
    position: relative;
    outline: none;
    user-select: none; // do not allow user to select natively - but only throught draggablejs

    &.draggable-mirror {
      //background: linear-gradient( to bottom, rgba($clr-ggather-very-dark,.5), rgba($clr-ggather-very-dark,.0) );
      border-radius: 3px;
      //border: 1px dashed rgba($clr-ggather-very-dark, .5);
      z-index: 100;
    }

    &.mod-has-children {
      margin-bottom: 6px;
    }

    &.mod-is-hidden,
    &.mod-has-children.mod-is-hidden {
      margin-bottom: 0px;
    }

    &.mod-collapsed {
      margin-bottom: 0px;
    }
    &.mod-tags-filter-applied.mod-collapsed {
      //margin-bottom: 0px;
    }


  }

  .sbartag-self {
    position: relative;

    .sbartag.mod-is-hidden > & {
      height: 0;
      overflow: hidden;
    }
  }


  .sbartag-controls {
    position: absolute;
    top: 0px;
    right: 0;
    padding-right: 0px;

    z-index: 10;

    display: none;

    .sbartag-self:hover & {
      display: block;
    }

    .sbartag.draggable--over > .sbartag-self > & {
      display: none;
    }

    .sbartag.mod-route-active > .sbartag-self > & {
      display: block;
    }

  }

    .sbartag-control-btn {
      height: 30px;
      width: 30px;
      z-index: 5;
      outline: none;



      & svg {
        width: 18px;
        height: 18px;
        opacity: .3;
        fill: rgba(black,1);
      }

      &.mod-expand svg {
        width: 26px;
        height: 26px;
      }

      .sbartag.mod-collapsed &.mod-expand svg {
        transform: rotateZ(90deg);
      }
      .sbartag.mod-route-active & svg {
        fill: rgba(white,1);
      }



      .sbartag-self:hover & svg {
        opacity: .3;
      }

      .sbartag-self:hover &:hover svg {
        opacity: .7;
      }

      .sbartag.draggable-mirror > .sbartag-self & {
        display: none;
      }

    }




  .sbartag-drag-handle {
    position: absolute;
    top: 6px;
    left: -22px;

    & svg {
      width: 16px;
      height: 16px;
      path {
        fill: rgba(black,.4);
      }
    }

    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    display: none;

    .sbartag-self:hover > & {
      display: block;
    }


    .sbartag.draggable-source--is-dragging > .sbartag-self > & {
      display: none;
    }

    .sbartag.draggable-mirror > .sbartag-self > & {
      display: none;
    }


  }




  .sbartag-link {
    position: relative;
    z-index: 5;
    display: block;
    border-radius: 3px;
    background-color: white;
    border: 1px solid darken(white,10);

    html.mod-transitions-enabled & {
      transition: all 70ms ease-out;
    }

    &:hover {
      background: rgba(black,.01);
    }

    .sbartag.mod-route-active > .sbartag-self > & {
    }


    .sbartag.mod-route-active.mod-color-1 > .sbartag-self > & {background: rgba(lighten($clr-tag-1,20), 1);}
    .sbartag.mod-route-active.mod-color-2 > .sbartag-self > & {background: rgba($clr-tag-2, 1);}
    .sbartag.mod-route-active.mod-color-3 > .sbartag-self > & {background: rgba($clr-tag-3, 1);}
    .sbartag.mod-route-active.mod-color-4 > .sbartag-self > & {background: rgba($clr-tag-4, 1);}
    .sbartag.mod-route-active.mod-color-5 > .sbartag-self > & {background: rgba($clr-tag-5, 1);}
    .sbartag.mod-route-active.mod-color-6 > .sbartag-self > & {background: rgba($clr-tag-6, 1);}
    .sbartag.mod-route-active.mod-color-7 > .sbartag-self > & {background: rgba($clr-tag-7, 1);}
    .sbartag.mod-route-active.mod-color-8 > .sbartag-self > & {background: rgba($clr-tag-8, 1);}
    .sbartag.mod-route-active.mod-color-9 > .sbartag-self > & {background: rgba($clr-tag-9, 1);}





    // out of scope style...
    .sbartags-list.draggable-container--is-dragging > .sbartag > .sbartag-self > & {
      cursor: default !important;
    }



    .sbartag.draggable--over > .sbartag-self > & {
      //border: 2px dashed rgba(white,.3);
    }

    .sbartag.draggable--over > .sbartag-self > & {
      background: rgba(white, .15);
    }

    .sbartag.draggable-source--is-dragging > .sbartag-self > & {
      background: rgba(white,.12);
      opacity: .4;
      //border: 2px solid transparent;
    }


    .sbartag.draggable-mirror & {
      background: darken(white, 0) !important;
      box-shadow: 0 2px 15px 0 rgba(black,.2), 0 2px 35px 0 rgba(black,.2);
      transform: rotateZ(3deg);
    }

    .sbartag.draggable-mirror  > .sbartag-self > & {
      transform: rotateZ(1.5deg);
    }



    .sbartag.mod-item-drag-over > .sbartag-self > & {
      background: rgba(white, .15);
    }


  }


    .sbartag-icon {
      position: absolute;
      z-index: 6;
      top: 9px;
      left: 8px;

      // dot
      > span {
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 10px;
      }
    }


    .sbartag.draggable-mirror .sbartag-icon {
      opacity: 0;
    }


    .sbartag.mod-color-1 > .sbartag-self > .sbartag-icon span {background: rgba(lighten($clr-tag-1,20), .75);}
    .sbartag.mod-color-2 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-2, .7);}
    .sbartag.mod-color-3 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-3, .7);}
    .sbartag.mod-color-4 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-4, .75);}
    .sbartag.mod-color-5 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-5, .7);}
    .sbartag.mod-color-6 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-6, .7);}
    .sbartag.mod-color-7 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-7, .7);}
    .sbartag.mod-color-8 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-8, .7);}
    .sbartag.mod-color-9 > .sbartag-self > .sbartag-icon span {background: rgba($clr-tag-9, .7);}


    .sbartag.mod-route-active > .sbartag-self > .sbartag-icon span {background: rgba(white,.7);}


























    .sbartag-icon-arrow {
      position: absolute;
      z-index: 6;
      top: 0px;
      left: 0px;
      width: 26px;
      height: 27px;
      border-radius: 2px;
      padding-top: 10px;
      padding-left: 1px;
      text-align: center;
      outline: none;
      display: block;

      & /deep/ svg {
        width: 15px;
        height: 10px;
        fill: rgba(red,.4);
        transform: rotateZ(-0deg);
        html.mod-transitions-enabled & {
          transition: transform 100ms ease-in-out;
        }
      }

      html.mod-transitions-enabled & {
        transition: transform 100ms ease-in-out;
      }

      &:hover {
        //background: rgba(white,.1);
        //transform: scale(1.1);
      }

    }

    .sbartag.mod-collapsed .sbartag-self > .sbartag-icon-arrow {
      //padding-top: 9px;
    }
    .sbartag.mod-collapsed .sbartag-self > .sbartag-icon-arrow /deep/ svg {
      transform: translateY(-1px) rotateZ(-90deg);
    }


    .sbartag.draggable-mirror .sbartag-icon-arrow {
      opacity: 0;
    }


    .sbartag.mod-color-1 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba(lighten($clr-tag-1,20), .75);}
    .sbartag.mod-color-2 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-2, .7);}
    .sbartag.mod-color-3 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-3, .7);}
    .sbartag.mod-color-4 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-4, .75);}
    .sbartag.mod-color-5 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-5, .7);}
    .sbartag.mod-color-6 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-6, .7);}
    .sbartag.mod-color-7 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-7, .7);}
    .sbartag.mod-color-8 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-8, .7);}
    .sbartag.mod-color-9 > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba($clr-tag-9, .7);}


    .sbartag.mod-route-active > .sbartag-self > .sbartag-icon-arrow /deep/ svg {fill: rgba(white,.7);}













    .sbartag-name {
      display: block;
      padding: 7px 32px 6px 25px;
      color: rgba(black,.9);
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
      word-break: break-all;
      white-space: normal;
    }

    .sbartag.mod-route-active > .sbartag-self > .sbartag-link > .sbartag-name {color: rgba(black,.99);}

    .sbartag.draggable-mirror .sbartag-name {color: rgba(black,1) !important;}

    .sbartag.mod-route-active > .sbartag-self > .sbartag-link > .sbartag-name {color: rgba(white,1); font-weight: 400;}






















  .sbartag-marker-sort {
    display: block;
    width: 100%;
    height: 4px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: rgba(black,.0);
      border-radius: 1px;
      border: 1px dashed rgba(black,.4);
      //border-left-color: rgba(black,0);
      //border-right-color: rgba(black,0);
      //border-bottom-color: rgba(black,0);
      opacity: 0;
    }


    .sbartag.draggable--over > & {
      &::after {
        opacity: 1;
      }
    }

    .sbartag.mod-mirror-over-nest > & {
      &::after {
        opacity: 0;
      }
    }


    .sbartag.draggable-source--is-dragging > & {
      &::after {
        opacity: 0;
      }
    }

    .sbartag.draggable-mirror > & {
      //display: none;
      height: 5px;
    }


    .sbartag.mod-is-hidden > & {
      height: 0;
      overflow: hidden;
    }



  }







  .sbartag-marker-nest {
    display: block;
    width: 100%;
    height: 38px;
    position: relative;

    padding-left: 10px;
    padding-top: 7px;

    opacity: .1;

    &::after {
      content: '';
      position: absolute;
      top: 6px;
      bottom: 0px;
      left: 15px;
      right: 0;
      background: rgba(black,.0);
      border-radius: 3px;
      border: 1px dashed rgba(black,.3);
      //border-left-color: rgba(black,0);
      //border-right-color: rgba(black,0);
      //border-bottom-color: rgba(black,0);
    }


    & > span {
      font-size: 11px;
      font-weight: 600;
      line-height: 1.2;
      color: rgba(black,.7);
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      margin-top: 9px;
    }

    & svg {
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px;
      margin-left: 15px;
      margin-top: 8px;
      path {
        fill: rgba(black,.5);
      }
    }

    .sbartag.draggable--over > & {
      & {
        opacity: .35;
      }
    }

    .sbartag.mod-mirror-over-nest > & {
      & {
        opacity: 1;
      }
    }

    .sbartag.draggable-source--is-dragging > & {
      & {
        opacity: 0;
      }
    }

    .sbartag.draggable-mirror > & {
      display: none;
    }



    .sbartag.mod-is-hidden > & {
      height: 0;
      overflow: hidden;
    }


  }








  .sbartag-nest-list {
    display: block;
    padding-left: 15px;
    transform-origin: top left;


    .sbartag.mod-collapsed & {
      display: none;
    }
    .sbartag.mod-tags-filter-applied.mod-collapsed & {
      display: block;
    }

  }

  .sbartag.draggable-mirror > .sbartag-nest-list {
    transform: scale(1, 0.5);

    & /deep/ .sbartag {
      display: none;
    }

    & /deep/ .sbartag-name {
      opacity: 0;
    }

    & /deep/ .sbartag:nth-of-type(1) {
      display: block;
      opacity: .9;
    }

    & /deep/ .sbartag:nth-of-type(2) {
      display: block;
      opacity: .6;
    }

    & /deep/ .sbartag:nth-of-type(3) {
      display: block;
      opacity: .3;
    }


  }






</style>






<style lang="scss">

  // WARNING: SELECTOR OUT OF COMPONENT SCOPE!
  .sbartags.mod-dragged-tag-has-children .sbartag.mod-is-children > .sbartag-marker-sort {
    &::after {
      opacity: 0;
    }
  }

  // WARNING: SELECTOR OUT OF COMPONENT SCOPE!
  .sbartags.mod-dragged-tag-has-children .sbartag > .sbartag-marker-nest {
    display: none;
  }

</style>







<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .sbartag-link {
    background: rgba(white, .15);
    border-color: rgba(white,.1);
    &:hover {
      background: rgba(white,.25);
    }
  }

  .sbartag.draggable-mirror .sbartag-link {
    background: darken(white, 60) !important;
    box-shadow: 0 2px 15px 0 rgba(black,.2), 0 2px 35px 0 rgba(black,.2);
  }


  .sbartag-name {
    color: rgba(white,.6);
  }

  .sbartag.mod-route-active > .sbartag-self > .sbartag-link > .sbartag-name  {
    color: rgba(white,.99);
  }

  .sbartag.draggable-mirror .sbartag-name {
    color: rgba(white,1) !important;
  }






  .sbartag-marker-nest {
    &::after {
      background: rgba(white,.0);
      border: 1px dashed rgba(white,.3);
    }
    & > span {
      color: rgba(white,.7);
    }
    & svg {
      path {
        fill: rgba(white,.5);
      }
    }
  }



  .sbartag-marker-sort::after {
    background: rgba(white,.0);
    border: 1px dashed rgba(white,.5);
  }


  .sbartag-control-btn {
    & svg {
      fill: rgba(white,1);
    }
  }






}
</style>
