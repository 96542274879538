<script>
export default {

  name: 'page-discover',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },


  data: function(){
    return {
      suggestedTags: null,

      popularTags: null,
      popularUsers: null,

      //newTagsExpanded: false,
      //newUsersExpanded: false,

      newTags: null,
      newUsers: null,

    };
  },

  created: function(){
    this.getAllDiscoverStuffs();
  },


  methods: {



    getAllDiscoverStuffs: function(){

      this.$store.state.user.is_authenticated ? this.getDiscoverStuff('suggestedtags') : null;

      // lol callbacks
      this.getDiscoverStuff('populartags', function(){

        this.getDiscoverStuff('popularusers', function(){

          this.getDiscoverStuff('newtags');
          this.getDiscoverStuff('newusers');

        }.bind(this));

      }.bind(this));

    },



    expandSection: function(kind){
      if (kind=='newtags') {
        this.newTagsExpanded = true;
        this.getDiscoverStuff('newtags');
      }
      if (kind=='newusers') {
        this.newUsersExpanded = true;
        this.getDiscoverStuff('newusers');
      }
    },





    getDiscoverStuff: function(kind, onSuccess){
      this.$axios({
        url: this.$store.state.api + '/get-discover-stuff/',
        method: 'get',
        params: {
          kind: kind,
        },
      }).then(function(response){
        if (kind=='suggestedtags') { this.suggestedTags = response.data }
        if (kind=='populartags') { this.popularTags = response.data }
        if (kind=='popularusers') { this.popularUsers = response.data }
        if (kind=='newtags') { this.newTags = response.data }
        if (kind=='newusers') { this.newUsers = response.data }
        onSuccess ? onSuccess(response) : null;
      }.bind(this));
    },





  },

};
</script>


<template>
<div class="router-page">
<div class="discover">

  <socialtabs context="discover" />


  <!-- Suggested Tags -->
  <div v-if="$store.state.user.is_authenticated" class="discover-section">
    <h3 class="discover-section-title">

    <span class="discover-big-icon mod-suggested" :style="{backgroundImage: 'url('+ $store.state.user.profile_image+')'}"></span>  Suggested Public Tags <span class="icon icon-help" v-tooltip="'These tags are specially selected for you based on your subscriptions, interests &amp; activity. <br>Check back later for more.'"></span></h3>

    <div  v-if="$store.state.user.is_authenticated" class="discover-slider-wrapper  mod-tagcards" :class="{'mod-loading': !suggestedTags}">

      <spinner v-if="!suggestedTags" kind="wave" dark="true"  :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container   common-light-scrollbar " ref="suggestedTagsSlider">
        <div class="discover-slider-inner  swiper-wrapper">
          <div v-for="tag in suggestedTags" class="discover-slider-item  swiper-slide">
            <tagcard :tag="tag"></tagcard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-suggestedtags mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-suggestedtags mod-prev"></button>

      <div class="discover-empty" v-if="suggestedTags && (!suggestedTags.length)">We couldn't get any recommendations for you. Suggested tags are generated based on your subscriptions and activity.  <br> So go out here! View some stuff or add some stuff by yourself and check back later here.</div>

    </div>

    <button v-else class="discover-btn" type="button"  @click.prevent="$store.commit('dialogbox', {'kind': 'signinup', subkind: 'signin',})">Sign in to see suggested tags</button>
  </div>

  <!-- Popular Tags -->
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-line-chart-filled"></span> Popular Public Tags</h3>
    <div class="discover-slider-wrapper  mod-tagcards" :class="{'mod-loading': !popularTags}">

      <spinner v-if="!popularTags" kind="wave" dark="true" :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container  common-light-scrollbar" ref="popularTagsSlider">
        <div class="discover-slider-inner  swiper-wrapper    ">
          <div v-for="tag in popularTags" class="discover-slider-item  swiper-slide">
            <tagcard :tag="tag"></tagcard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-populartags mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-populartags mod-prev"></button>

    </div>
  </div>

  <!-- Popular Users -->
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-line-chart-filled"></span> Popular Users  <span>(With Public Tags)</span></h3>
    <div class="discover-slider-wrapper  mod-usercards" :class="{'mod-loading': !popularUsers}">

      <spinner v-if="!popularUsers" kind="wave" dark="true"  :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container   common-light-scrollbar" ref="popularUsersSlider">
        <div class="discover-slider-inner  swiper-wrapper">
          <div v-for="user in popularUsers" class="discover-slider-item  swiper-slide">
            <usercard :user="user"></usercard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-popularusers mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-popularusers mod-prev"></button>

    </div>
  </div>



  <!-- New Tags -->
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-time-span-filled"></span> New Public Tags</h3>
    <div class="discover-slider-wrapper  mod-tagcards" :class="{'mod-loading': !newTags}">

      <spinner v-if="!newTags" kind="wave" dark="true"  :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container   common-light-scrollbar" ref="newTagsSlider">
        <div class="discover-slider-inner  swiper-wrapper">
          <div v-for="tag in newTags" class="discover-slider-item  swiper-slide">
            <tagcard :tag="tag"></tagcard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-newtags mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-newtags mod-prev"></button>

    </div>
  </div>



  <!-- New Users -->
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-time-span-filled"></span> New Users <span>(With Public Tags)</span></h3>
    <div class="discover-slider-wrapper mod-usercards" :class="{'mod-loading': !newUsers}">

      <spinner v-if="!newUsers" kind="wave" dark="true"  :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container   common-light-scrollbar" ref="newUsersSlider">
        <div class="discover-slider-inner  swiper-wrapper">
          <div v-for="user in newUsers" class="discover-slider-item  swiper-slide">
            <usercard :user="user"></usercard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-newusers mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-newusers mod-prev"></button>

    </div>
  </div>


  <!-- Freshest -->
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-link-filled"></span> Freshest Links <span>(In Public Tags)</span></h3>

    <items context="freshest"></items>

  </div>



  <!-- New Tags -->
  <!--
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-time-span-filled"></span> New Public Tags</h3>
    <div v-if="newTagsExpanded" class="discover-slider-wrapper  mod-tagcards" :class="{'mod-loading': !newTags}">

      <spinner v-if="!newTags" kind="wave" dark="true"  :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container    common-light-scrollbar" ref="newTagsSlider">
        <div class="discover-slider-inner  swiper-wrapper">
          <div v-for="tag in newTags" class="discover-slider-item  swiper-slide">
            <tagcard :tag="tag"></tagcard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-newtags mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-newtags mod-prev"></button>

    </div>
    <button v-else class="discover-btn" type="button"  @click.prevent="expandSection('newtags')">Show New Public Tags</button>
  </div> -->

  <!-- New Users -->
  <!--
  <div class="discover-section">
    <h3 class="discover-section-title"><span class="discover-big-icon icon icon-time-span-filled"></span> New Users <span>(With Public Tags)</span></h3>
    <div v-if="newUsersExpanded" class="discover-slider-wrapper mod-usercards" :class="{'mod-loading': !newUsers}">

      <spinner v-if="!newUsers" kind="wave" dark="true"  :size="1"></spinner>
      <div v-else class="discover-slider  swiper-container    common-light-scrollbar" ref="newUsersSlider">
        <div class="discover-slider-inner  swiper-wrapper">
          <div v-for="user in newUsers" class="discover-slider-item  swiper-slide">
            <usercard :user="user"></usercard>
          </div>
        </div>
      </div>
      <button type="button" class="discover-slider-arrow mod-newusers mod-next"></button>
      <button type="button" class="discover-slider-arrow mod-newusers mod-prev"></button>

    </div>
    <button v-else class="discover-btn" type="button"  @click.prevent="expandSection('newusers')">Show New Users  <span>(With Public Tags)</span></button>
  </div> -->

</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .discover {
    max-width: 1200px;
    margin: 0 auto;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    margin-bottom: 20px;
  }



  .discover-section {
    display: block;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 60px;
    @media (max-width: 800px) {
      padding: 10px;
    }
  }

  .discover-section-title {
    font-weight: 600;
    font-size: 17px;
    line-height: .8;
    opacity: .7;
    margin-left: 0px;
    margin-right: 0px;
    //border-bottom: 1px solid darken(#eee,5);

    & > .icon {
      margin-right: 2px;
    }
    & > .icon::before {
      line-height: 1;
    }

    & > .icon-help {
      display: inline-block;
      margin-left: 3px;
      font-size: 12px;
      opacity: .6;
    }
    & > .icon-help::before {
      line-height: .7;
    }

    span {
      opacity: .3;
    }


    @media (max-width: 500px){
      font-size: 12px;
      line-height: 1.3;
    }
  }

  .discover-slider-wrapper {
     // background-color: white;
    //border: 1px solid #eee;
    border-radius: 4px;
    padding: 5px;
    margin-right: 0px;
    white-space: nowrap;
    position: relative;
    background-color: #ddd;


    &.mod-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ddd;

      /*
      &::before {
        border-radius: 3px;
        position: absolute;
        content: '';
        top: 5px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(red,.7);
      }
      */
    }

    &.mod-loading.mod-usercards {
      min-height: 207px;
    }

    &.mod-loading.mod-tagcards {
      min-height: 162px;
    }


  }

  .discover-slider {
    width: auto;
    height: 100%;
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: auto;
  }

  .discover-slider-item {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    width: auto;

    opacity: .9;
    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-in-out;
    }
    &:hover {
      opacity: 1;
    }
  }


  .discover-slider-arrow {

    display: none !important;

    display: inline-block;
    position: absolute;
    top: -35px;
    right: 0;
    height: 35px;
    width: 30px;
    z-index: 10;
    outline: none;
    //background-color: white;
    //background-color: #f7f7f7;

    &::before {
      font-family: 'ggather';
      content: '';
      font-size: 18px;
      position: relative;
    }

    &.mod-prev {
      //left: 0px;
      right: 30px;

      &::before {
        content: '\e00c';
        left: -2px;
      }
    }

    &.mod-next {
      //right: 0px;

      &::before {
        content: '\e053';
        right: -2px;
      }
    }

    html.mod-transitions-enabled & {
      transition: color 70ms ease-in-out;
    }
    color: rgba(#333,.5);
    &:hover {
      color: rgba(#333,1);
    }

    &:active {
      color: rgba(#333,.7);
    }

    &.swiper-button-disabled {
      color: rgba(#333,.1);
    }

  }

  .discover-btn {
    background-color: white;
    background-color: rgba(white,.9);
    width: 100%;
    border: 1px solid #eee;
    border-radius: 0 0 2px 2px;
    //border-top: none;
    padding: 20px 50px;
    margin-top: 5px;
    display: block;
    font-size: 15px;
    font-weight: 500;
    outline-color: black;
    span {
      opacity: .3;
    }
  }

  .discover-empty {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;

    padding-top: 15px;
    color: darken(#bababa,4);
    border: 1px dashed darken(#eee,10);
    background-color: darken(#eee,2);
    border-radius: 3px;
    padding: 10px 20px;

    white-space: normal;

    min-height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

  }




  .discover-big-icon.discover-big-icon.discover-big-icon {
    background-color: #ccc;
    width: 25px;
    display: inline-block;
    height: 25px;
    border-radius: 20px;
    color: #111;
    text-align: center;
    line-height: 20px;
    vertical-align: top;
    margin-right: 4px;
    position: relative;
    top: -6px;
    background-size: cover;
    background-position: 50% 50%;
    &::before {
      line-height: 26px;
    }


    &.mod-suggested {
      opacity: 5;
    }

  }


</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .discover-section-title {
    color: rgba(white,.9);
  }

  .discover-slider-wrapper {
    background: darken(white,60);
  }

  .discover-empty {
    background-color: darken(white,80);
    border-color: darken(white,70);
  }


}
</style>
