<script>
import _findIndex from 'lodash/findIndex';
export default {

  props: [
    'tag',
  ],

  computed: {
    inFollowedTags: function(){
      return _findIndex(this.$store.state.followedTagsUsers, ['pk', this.tag.pk]) > -1;
    },
  },

};
</script>


<template>
<div class="tagcard" :class="'mod-color-'+tag.color">


  <router-link :to="'/'+tag.owner.username+'/'+tag.slug" class="tagcard-inner">



    <div class="tagcard-user">

      <div class="tagcard-user-avatar">
        <img :src="tag.owner.profile_image">
      </div>

      <div class="tagcard-user-name">
        {{tag.owner.username}}
      </div>

    </div>


    <!--
    <div class="tagcard-already-sub">
      <span class="icon icon-checkmark-filled"></span> Subscribed
    </div> -->


    <div class="tagcard-title" >

      <span class="tagcard-title-inner">

        <!--  <span v-if="(tag.visibility==1) || (tag.visibility==3)" class="icon icon-lock-filled" title="This tag is private"></span>-->

        <!--
        <span v-if="tag.visibility==3" class="icon icon-link"></span>
        <span v-if="tag.visibility==2" class="icon icon-online"></span> -->

        <span v-if="tag.visibility==1" class="tagcard-title-icon icon icon-lock-1"></span>
        <span v-if="tag.visibility==2" class="tagcard-title-icon icon icon-shield"></span>
        <span v-if="tag.visibility==3" class="tagcard-title-icon icon icon-visible-1"></span>
        {{tag.name}}

      </span>

    </div>

    <div class="tagcard-description">
    <span>
      {{tag.description}}
    </span>
    </div>

    <div class="tagcard-details-wrapper" :class="{'mod-three-cols': inFollowedTags || tag.is_followed}">

      <div class="tagcard-detail mod-subbed" v-if="inFollowedTags || tag.is_followed">
        <span v-tooltip="'You\'re subscribed to this tag.'">
        <div class="text">Subscribed</div>
        </span>
      </div>


      <div class="tagcard-detail  mod-links">
        <div class="text">{{tag.items_count}} links</div>
      </div>

      <div class="tagcard-detail  mod-subs" >
        <span>
          <div class="text">{{tag.subscribers_count}} <span>subs<span v-if="!tag.is_followed">cribers</span></span></div>
        </span>
      </div>


    </div>

  </router-link>



  <!--  <div class="tagcard-sub-wrapper">-->
  <button v-if="tag.owner.pk !== $store.state.user.pk" type="button" @click.stop.prevent="$store.dispatch('followOrUnfollowTag', tag.pk); tag.is_followed=!tag.is_followed" class="tagcard-sub-btn">
    <template v-if="inFollowedTags || tag.is_followed">Unsubscribe</template>
    <template v-else>Subscribe</template>
  </button>

  <!--  --><button v-if="tag.owner.pk === $store.state.user.pk" type="button" @click.stop.prevent="" class="tagcard-sub-btn mod-disabled">Owned</button>
  <!--</div>  -->



</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";




  /*------------------------------------*\
    tagcard
  \*------------------------------------*/



  .tagcard {
    display: inline-block;
    overflow: hidden;
    position: relative;
  }


    .tagcard-inner {
      padding: 15px 15px;
      padding-top: 12px;
      display: block;
      width: 250px;
      overflow: hidden;
      height: 130px;
      border-radius: 3px;
      white-space: normal;

      .tagcard.mod-color-1 & { background-color: rgba($clr-tag-1, 1); color: darken( rgba($clr-tag-1, 1), 10) }
      .tagcard.mod-color-2 & { background-color: rgba($clr-tag-2, 1); color: darken( rgba($clr-tag-2, 1), 10) }
      .tagcard.mod-color-3 & { background-color: rgba($clr-tag-3, 1); color: darken( rgba($clr-tag-3, 1), 10) }
      .tagcard.mod-color-4 & { background-color: rgba($clr-tag-4, 1); color: darken( rgba($clr-tag-4, 1), 10) }
      .tagcard.mod-color-5 & { background-color: rgba($clr-tag-5, 1); color: darken( rgba($clr-tag-5, 1), 10) }
      .tagcard.mod-color-6 & { background-color: rgba($clr-tag-6, 1); color: darken( rgba($clr-tag-6, 1), 10) }
      .tagcard.mod-color-7 & { background-color: rgba($clr-tag-7, 1); color: darken( rgba($clr-tag-7, 1), 10) }
      .tagcard.mod-color-8 & { background-color: rgba($clr-tag-8, 1); color: darken( rgba($clr-tag-8, 1), 10) }
      .tagcard.mod-color-9 & { background-color: rgba($clr-tag-9, 1); color: darken( rgba($clr-tag-9, 1), 10) }

    }


      .tagcard-sub-btn {
        position: absolute;
        bottom: 8px;
        right:  8px;
        display: block;
        background-color: rgba(white,.1);
        border: 1px solid rgba(white,.1);
        color: rgba(white,.9);
        padding: 2px 5px;
        font-size: 13px;
        border-radius: 2px;
        font-weight: 400;
        outline: none;
        z-index: 100;


        opacity: .0;
        html.mod-transitions-enabled & {
          transition: opacity 150ms ease-out, color 100ms ease-out, background-color 100ms ease-out, border-color 100ms ease-out;
        }

        .tagcard:hover & {
          opacity: 1;
          pointer-events: auto;


          &.mod-disabled {
            opacity: .5;
            pointer-events: none;
          }
        }


        &:hover {
          background-color: rgba(white,.3);
          border: 1px solid rgba(white,.1);
          color: rgba(white,1);

          &.mod-disabled {
            background-color: rgba(white,.1);
            border: 1px solid rgba(white,.1);
            color: rgba(white,.9);
          }

        }


      }



      .tagcard-user {
        html.mod-transitions-enabled & {
          transition: opacity 150ms ease-out;
        }
        display: block;
        opacity: 1;
        font-size: 0;
      }



        .tagcard-user-avatar {
          width: 12px;
          height: 12px;
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;
          margin-right: 3px;
          border-radius: 30px;
          opacity: .6;
          background-color: #eee;

          img {
            width: 100%;
            height: 100%;
          }
        }



      .tagcard-user-name {
        display: inline-block;
        font-size: 12px;
        vertical-align: middle;
        font-weight: 300;
        color: rgba(white,.6);
        margin-left: 4px;
      }




      .tagcard-already-sub {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 12px;
        color: rgba(white,.6);

      }



      .tagcard-title {
        margin-top: 6px;
        display: inline-block;
        white-space: nowrap;
        font-size: 17px;
        line-height: 1.2;
        font-weight: 600;
        color: white;
        display: block;
        overflow: hidden;
        position: relative;


        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 20px;
          height: 100%;
          display: block;
          //background-color: red;
        }


        .tagcard.mod-color-1 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-1, 1), rgba($clr-tag-1, 0.0) ); }
        .tagcard.mod-color-2 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-2, 1), rgba($clr-tag-2, 0.0) ); }
        .tagcard.mod-color-3 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-3, 1), rgba($clr-tag-3, 0.0) ); }
        .tagcard.mod-color-4 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-4, 1), rgba($clr-tag-4, 0.0) ); }
        .tagcard.mod-color-5 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-5, 1), rgba($clr-tag-5, 0.0) ); }
        .tagcard.mod-color-6 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-6, 1), rgba($clr-tag-6, 0.0) ); }
        .tagcard.mod-color-7 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-7, 1), rgba($clr-tag-7, 0.0) ); }
        .tagcard.mod-color-8 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-8, 1), rgba($clr-tag-8, 0.0) ); }
        .tagcard.mod-color-9 &::after {
         background-image: linear-gradient(to left, rgba($clr-tag-9, 1), rgba($clr-tag-9, 0.0) ); }



      }

        .tagcard-title-icon.tagcard-title-icon.tagcard-title-icon {
          opacity: .4;
          margin-right: 5px;
          &::before {
            line-height: 1;
            position: relative;
            top: 2px;
          }
        }


      .tagcard-description {
        margin-top: 6px;
        display: block;
        font-size: 13px;
        color: rgba(white,.8);
        line-height: 1.2;
        height: 37px;

        overflow: hidden;
        position: relative;

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 20px;
          display: block;
        }


        .tagcard.mod-color-1 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-1, 1), rgba($clr-tag-1, 0.0) ); }
        .tagcard.mod-color-2 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-2, 1), rgba($clr-tag-2, 0.0) ); }
        .tagcard.mod-color-3 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-3, 1), rgba($clr-tag-3, 0.0) ); }
        .tagcard.mod-color-4 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-4, 1), rgba($clr-tag-4, 0.0) ); }
        .tagcard.mod-color-5 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-5, 1), rgba($clr-tag-5, 0.0) ); }
        .tagcard.mod-color-6 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-6, 1), rgba($clr-tag-6, 0.0) ); }
        .tagcard.mod-color-7 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-7, 1), rgba($clr-tag-7, 0.0) ); }
        .tagcard.mod-color-8 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-8, 1), rgba($clr-tag-8, 0.0) ); }
        .tagcard.mod-color-9 &::after {
         background-image: linear-gradient(to top, rgba($clr-tag-9, 1), rgba($clr-tag-9, 0.0) ); }


      }





      .tagcard-footer {
        display: block;
        margin-top: 3px;

        .tagcard:hover & {
          opacity: 0;
        }
      }


        .tagcard-follow {
          margin-top: 10px;
          font-size: 13px;
          line-height: 1;
          color: rgba(black,.5);
          border-radius: 3px;
          padding: 1px 8px 3px 8px;
          border: 1px solid rgba(black,.1);
        }





      .tagcard-details-wrapper {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .mod-three-cols & {

        }

      }

        .tagcard-detail {
          width: 33.3%;
          font-size: 12px;
          display: inline-block;
          line-height: 1.2;
          white-space: nowrap;

          opacity: .7;

          html.mod-transitions-enabled & {
            transition: opacity 100ms ease-out;
          }

          .text {
            display: inline;
            color: rgba(white,.7);
          }


          .tagcard:hover & {
            &.mod-subs {
              opacity: .8;
            }
          }

          &.mod-subbed {
            width: 47%;
            flex: 0 0 47%;
            opacity: 1;
          }



          // links
          &.mod-links {
            text-align: left;
            .tagcard:hover & {
              opacity: .0;
            }
          }



          // subs
          &.mod-subs {
            text-align: right;
            .tagcard:hover & {
              opacity: .0;
            }
          }


          .tagcard-details-wrapper.mod-three-cols & {

            &.mod-subbed {

            }

            &.mod-links {
            }
            &.mod-subs {
            }




          }

        }







</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .tagcard.mod-color-1 .tagcard-inner { background-color: $clr-tag-dark-1; color: darken($clr-tag-dark-1, 5); }
  .tagcard.mod-color-2 .tagcard-inner { background-color: $clr-tag-dark-2; color: darken($clr-tag-dark-2, 5); }
  .tagcard.mod-color-3 .tagcard-inner { background-color: $clr-tag-dark-3; color: darken($clr-tag-dark-3, 5); }
  .tagcard.mod-color-4 .tagcard-inner { background-color: $clr-tag-dark-4; color: darken($clr-tag-dark-4, 5); }
  .tagcard.mod-color-5 .tagcard-inner { background-color: $clr-tag-dark-5; color: darken($clr-tag-dark-5, 5); }
  .tagcard.mod-color-6 .tagcard-inner { background-color: $clr-tag-dark-6; color: darken($clr-tag-dark-6, 5); }
  .tagcard.mod-color-7 .tagcard-inner { background-color: $clr-tag-dark-7; color: darken($clr-tag-dark-7, 5); }
  .tagcard.mod-color-8 .tagcard-inner { background-color: $clr-tag-dark-8; color: darken($clr-tag-dark-8, 5); }
  .tagcard.mod-color-9 .tagcard-inner { background-color: $clr-tag-dark-9; color: darken($clr-tag-dark-9, 5); }

  .tagcard.mod-color-1 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-1, rgba($clr-tag-dark-1, 0));
  }
  .tagcard.mod-color-2 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-2, rgba($clr-tag-dark-2, 0));
  }
  .tagcard.mod-color-3 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-3, rgba($clr-tag-dark-3, 0));
  }
  .tagcard.mod-color-4 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-4, rgba($clr-tag-dark-4, 0));
  }
  .tagcard.mod-color-5 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-5, rgba($clr-tag-dark-5, 0));
  }
  .tagcard.mod-color-6 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-6, rgba($clr-tag-dark-6, 0));
  }
  .tagcard.mod-color-7 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-7, rgba($clr-tag-dark-7, 0));
  }
  .tagcard.mod-color-8 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-8, rgba($clr-tag-dark-8, 0));
  }
  .tagcard.mod-color-9 .tagcard-description::after {
    background-image: linear-gradient(to top, $clr-tag-dark-9, rgba($clr-tag-dark-9, 0));
  }


  .tagcard.mod-color-1 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-1, rgba($clr-tag-dark-1, 0))
  }
  .tagcard.mod-color-2 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-2, rgba($clr-tag-dark-2, 0))
  }
  .tagcard.mod-color-3 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-3, rgba($clr-tag-dark-3, 0))
  }
  .tagcard.mod-color-4 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-4, rgba($clr-tag-dark-4, 0))
  }
  .tagcard.mod-color-5 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-5, rgba($clr-tag-dark-5, 0))
  }
  .tagcard.mod-color-6 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-6, rgba($clr-tag-dark-6, 0))
  }
  .tagcard.mod-color-7 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-7, rgba($clr-tag-dark-7, 0))
  }
  .tagcard.mod-color-8 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-8, rgba($clr-tag-dark-8, 0))
  }
  .tagcard.mod-color-9 .tagcard-title::after {
    background-image: linear-gradient(to left, $clr-tag-dark-9, rgba($clr-tag-dark-9, 0))
  }







}
</style>
