<script>
import getNativeScrollbarSize from '../../../common/script/scrollbar-width.js';
export default {
  name: 'html-class',


  computed: {

  },


  created: function(){
    this.setHTMLClass();
    //this.setWatchers();
  },

  mounted: function(){
    this.setHTMLClass();
  },

  updated: function(){
    this.setHTMLClass();
  },

  watch: {
    //'$route.fullPath': function(){
    //  this.setHTMLClass();
    //},
    //'$store.state': function(){
    //  this.setHTMLClass();
    //},

    '$store.state': {
      deep: true,
      handler: function(){
        this.setHTMLClass();
      },
    },

  },


  methods: {

    /*
    setWatchers: function(){

      this.$watch('$store.state', function(){
        this.setHTMLClass();
      }.bind(this), {
        deep: true
      })

    },
    */


    generate: function(){
      var state = this.$store.state;
      var getters = this.$store.getters;
      var route = this.$route;
      var inputNonBool = [
        'mod-build-'+state.BUILD,
        'mod-app-theme-'+state.appTheme,
        'mod-visible-item-layout-'+getters.visibleItemLayout,
        'mod-topbar-color-'+getters.topbarColor,
        'mod-route-meta-kind-'+route.meta.kind,
      ];
      var inputBool = {
        'mod-chrome': state.chrome,
        'mod-device-mobile': state.deviceMobile,
        'mod-final-sidebar-collapse': getters.finalSidebarCollapse,
        'mod-transitions-enabled': state.transitionsEnabled,
        'mod-viewer-expand-level-1': state.viewer && (state.viewerExpandLevel==1),
        'mod-dialogbox-opened': state.dialogbox,
        'mod-page-landing': (route.path==='/'),
        'mod-loaded': state.user,
        'mod-viewer-open': state.viewer,
        'mod-topbar-color-applied': getters.topbarColor !== 0,
        'mod-titlebar-visible': getters.titlebarVisible,
        'mod-page-with-sidebar': getters.pageWithSidebar,
        'mod-page-without-sidebar': !getters.pageWithSidebar,
      };
      var classesInString = '';
      classesInString = inputNonBool.join(' ') + ' ';
      for(var key in inputBool){
        var val = inputBool[key];
        if(!!val) classesInString += (key + ' ');
      }
      var classesInArray = classesInString.trim().split(' ').map(function(c, i){
        return c ? c.trim() : void 0;
      });
      return classesInArray;
    },



    setHTMLClass: function(){
      var classesArray = this.generate();
      //var classesArray = this.classes;
      window.document.documentElement.className = classesArray.join(' ');
      this.overflowOffsetFix(classesArray);
    },


    overflowOffsetFix: function(classesArray){
      var applyFix = false;
      if (classesArray.indexOf('mod-dialogbox-opened') > -1) (applyFix = true);
      if (classesArray.indexOf('mod-viewer-open') > -1) (applyFix = true);
      if (applyFix) {
        var scrollbarWidth = getNativeScrollbarSize();
        this.$store.commit('overflowModFix', scrollbarWidth + 'px');
      } else {
        this.$store.commit('overflowModFix', '0px');
      }
    },

    /*
    removeClass: function(el, className){
      if (el.classList) {
        el.classList.remove(className);
      } else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    },
    */


  },

};
</script>

<template>
</template>
