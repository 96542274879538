<script>
export default {

  props: [
    'itemLayout',
    'itemRating',
  ],


  methods: {

  },

}


</script>



<template>
<div class="itemrate" v-tooltip="''+itemRating+' heart'+(itemRating===1?'':'s')">

  <span class="itemrate-heart mod-filled" v-for="r in parseInt(itemRating)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
      <path d="M12,20c0,0-9-7.111-9-11.111C3,6.222,5.25,4,7.95,4C9.525,4,11.1,5.139,12,6.25C12.9,5.139,14.25,4,16.05,4 C18.75,4,21,6.222,21,8.889C21,12.889,12,20,12,20z" />
    </svg>
  </span>

  <span class="itemrate-heart mod-empty" v-for="r in (itemRating ? 5-parseInt(itemRating) : 5)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
      <path d="M12,20c0,0-9-7.111-9-11.111C3,6.222,5.25,4,7.95,4C9.525,4,11.1,5.139,12,6.25C12.9,5.139,14.25,4,16.05,4 C18.75,4,21,6.222,21,8.889C21,12.889,12,20,12,20z" />
    </svg>
  </span>

</div>

</template>








<style lang="scss">
@import "../../../common/style/_variables.scss";

  .itemrate {
    display: inline-block;
    background: #ffe0d9;
    border: 1px solid darken( desaturate(#ffe0d9, 25) ,7);
    border-radius: 3px;
    padding: 3px 3px 3px 3px;
    font-size: 0;
    opacity: 1;
  }

  .itemrate-heart {
    & svg {
      fill: #ff7251;
    }
    &.mod-filled svg {
    }
    &.mod-empty svg {
      opacity: .2;
    }
  }

</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .itemrate {
    background-color: darken(white,72);
    border-color: darken(white,68);
  }

  .itemrate-heart {
    & svg {
      fill: #cb4526;
    }
    &.mod-empty svg {
      //fill: black;
      opacity: .15;
    }
  }
}
</style>
