<script>
export default {

  name: 'page-import-html',

  data: function(){
    return {

      bookmarksFile: null,
      bookmarksFileName: null,
      bookmarksFileSize: null,

      previewsLoading: false,
      previewsError: false,
      previews: null,
      previewParsedCount: null,

      importLoading: false,
      importError: false,
      importErrorLimit: false,
      importSuccess: false,
      importCount: null,

    };
  },

  computed: {

    fileError: function(){
      if (this.fileTooLarge || this.fileBadExtension) return true;
    },

    fileTooLarge: function(){
      if ( this.bookmarksFileSize > (6291456-1024) ) return 'size';
    },

    fileBadExtension: function(){
      if ( this.bookmarksFileName.substr(-4).indexOf('htm') === -1 ) return 'extension';
    },

    uploading: function(){
      return this.importLoading || this.previewsLoading;
    },

    bookmarksFileSizeReadable: function(){
      var kb = this.bookmarksFileSize/1024;
      var mb = kb/1024;

      //console.warn('kb', kb);
      //console.warn('mb', mb);

      if (mb<1) {
        return (kb).toFixed(2) + ' KB';
      } else {
        return (mb).toFixed(2) + ' MB';
      }

    },

    bookmarksLimitMessage: function(){
      if (this.$store.state.user.is_premium) return false;
      if (this.previewParsedCount >= 2000) return true;
      if (this.importErrorLimit) return true;
    },


  },

  methods: {

    inputOnChange: function(val){
      var file = this.$refs.bookmarksFileInput.files[0];
      this.bookmarksFile = file;
      this.bookmarksFileName = file.name;
      this.bookmarksFileSize = file.size;
    },

    resetFileAndState: function(){

      this.resetFile();

      this.previewsError = false;
      this.previewsLoading = false;
      this.previews = null;
      this.previewParsedCount = null;

      this.importError = false;
      this.importErrorLimit = false;
      this.importLoading = false;
      this.importSuccess = false;
      this.importCount = null;
    },

    resetFile: function(){
      var input = this.$refs.bookmarksFileInput;
      input.value = '';
      //if(!/safari/i.test(navigator.userAgent)){
      input.type = ''; // safari
      input.type = 'file'; // safari
      //}

      this.bookmarksFile = null;
      this.bookmarksFileName = null;
      this.bookmarksFileSize = null;
    },


    buildFormData: function(onlyPreview){
      var data = new FormData();
      var file = this.$refs.bookmarksFileInput.files[0];
      data.append('bookmarks', file);
      data.append('preview', onlyPreview ? 'true' : 'false');
      return data;
    },


    importFile: function(onlyPreview){
      if (this.uploading) return;
      if (!this.bookmarksFile) return alert('You need to pick import file.');

      this.previewsError = false;
      this.previews = null;
      this.previewParsedCount = null;

      this.importError = false;
      this.importErrorLimit = false;
      this.importSuccess = false;
      this.importCount = null;

      if (onlyPreview) {
        this.previewsLoading = true;
      } else {
        this.importLoading = true;
      }


      this.$axios({
        url: this.$store.state.api + '/import/html',
        method: 'post',
        timeout: 600000,
        data: this.buildFormData(onlyPreview),
      }).then(function(response){

        if (onlyPreview) {
          this.previewsError = false;
          this.previewsLoading = false;
          this.previews = response.data.previews;
          this.previewParsedCount = response.data.parsed_count;
        } else {
          this.importError = false;
          this.importErrorLimit = response.data.error && (response.data.error==='pro-limit');
          this.importLoading = false;
          this.importSuccess = true;
          this.importCount = response.data.imported_count;
          this.resetFile();
          this.$store.dispatch('getCatsTags', 1);
        }

      }.bind(this), function(error){

        if (onlyPreview) {
          this.previewsError = true;
          this.previewsLoading = false;
          this.previewParsedCount = null;
        } else {
          this.importError = true;
          this.importErrorLimit = error.response.data && error.response.data.error && (error.response.data.error==='pro-limit');
          this.importLoading = false;
          this.importSuccess = false;
          this.importCount = null;
        }

        //this.$alertify.alert('There was an error while uploading or parsing your file. Please reload the page and try again. If you still encounter this error please contact us.');

      }.bind(this));
    },

  },

};
</script>





<template>
<div class="importhtml">

  <div class="importhtml-box mod-welcome">
    <h3 class="importhtml-box-title">Welcome to the new import page</h3>
    <p class="importhtml-box-line mod-first">Due to your feedback the importing feature was reimplemented. It should be now much faster and less buggy.</p>
    <p class="importhtml-box-line mod-icon">
      <iconsvg icon="material-help" /> If for some reason your file didn't get accepted or parsed correctly then please send it to <a href="mailto:contact@ggather.com">contact@ggather.com</a>. We will take a look into it and see where GGather importer needs improvements.</p>
    <p class="importhtml-box-line mod-icon">You can also try importing on our <router-link to="/settings/import-legacy">previous import page</router-link> which we will still support for some time.</p>
  </div>



  <div class="importhtml-box">
    <div class="importhtml-box-inner">

      <div v-if="bookmarksFile" class="importhtml-box-face mod-file" :class="{'mod-error': this.fileError}">
        <span><iconsvg icon="feather-file" /></span>
        <span>
          <span>{{this.bookmarksFileName}}</span>
          <span key="noerror" v-if="!fileError">{{this.bookmarksFileSizeReadable}}</span>
          <span key="error01" v-if="fileBadExtension">This file extension is invalid.</span>
          <span key="error02" v-if="fileTooLarge">This file is too large ({{this.bookmarksFileSizeReadable}}).</span>
        </span>
      </div>

      <label v-show="!bookmarksFile" class="importhtml-box-face mod-label" type="button" for="bookmarks-file-input">
        <span><iconsvg icon="feather-upload" /></span>
        <span>
          <span>Choose your bookmarks file</span>
          <span>Maximum 6 MB, .htm or .html file.</span>
        </span>
        <input id="bookmarks-file-input" name="bookmarks" type="file" @change="inputOnChange" ref="bookmarksFileInput">
      </label>


      <div class="importhtml-box-buttons-wrapper">

        <button type="button" class="importhtml-box-button" :disabled="!bookmarksFile || fileError || uploading" v-tooltip="'Import all of the bookmarks from the picked file.'" @click="importFile()">
          Import
        </button>

        <button type="button" class="importhtml-box-button" :disabled="!bookmarksFile || fileError || uploading" v-tooltip="'Upload file and get a preview of how will your bookmarks be imported.'" @click="importFile(true)">
          Generate Preview
        </button>

        <button type="button" class="importhtml-box-button" :disabled="!bookmarksFile || uploading" v-tooltip="'Reset file picker so you can choose another file.'" @click="resetFileAndState">
          Choose Another File
        </button>


      </div>

    </div>
  </div>


  <div v-if="importSuccess" class="importhtml-box mod-success">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">Import Success!</h3>
      <p class="importhtml-box-line">First step is done.  We've successfully saved or updated {{importCount}} bookmarks in your library.</p>
      <p class="importhtml-box-line">We're now generating their preview data like descriptions, thumbnails &amp; etc. behind the scenes. </p>
      <p class="importhtml-box-line">This may take a couple of minutes depending on how many bookmarks you've imported and during that process you may see bookmarks without thumbnail or description, but that will gradually change.</p>
      <p class="importhtml-box-line">You may already start organizing your library.</p>
    </div>
  </div>

  <div v-if="importLoading" class="importhtml-box">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">Importing</h3>
      <p class="importhtml-box-line">Importing bookmarks (it might take couple of minutes)...</p>
    </div>
  </div>

  <div v-if="bookmarksLimitMessage" class="importhtml-box mod-error">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">You're trying to import more than 2000 bookmarks.</h3>
      <p class="importhtml-box-line">As a temporary measure GGather limits importing to 2000 bookmarks for free users. It's due to infrastructure and maintenance costs. This limit will be lifted/changed in future. For now you can subscribe to <router-link to="/upgrade">GGather Plus</router-link> to import more than 2000 bookmarks. Sorry about the inconvenience!</p>
    </div>
  </div>

  <div v-if="importError && (!bookmarksLimitMessage)" class="importhtml-box mod-error">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">Import Error</h3>
      <p class="importhtml-box-line">Something went wrong while importing bookmarks...</p>
      <p class="importhtml-box-line">If you've tried to import big number of bookmarks, then please check if some of them were imported in your library - if yes - then please try importing again until all of them will be imported. Sorry about this inconvenience - we know about this issue and we're working on fixing it.</p>
      <p class="importhtml-box-line">You can also send your bookmarks import file to <a href="mailto:contact@ggather.com">contact@ggather.com</a>. We will take a look into it and see where GGather importer needs improvements and fixes.</p>
    </div>
  </div>


  <div v-if="previewsLoading" class="importhtml-box">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">Preview</h3>
      <p class="importhtml-box-line">Loading preview...</p>
    </div>
  </div>



  <div v-if="previewsError" class="importhtml-box mod-error">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">Preview Error</h3>
      <p class="importhtml-box-line">Something went wrong while generating preview...</p>
    </div>
  </div>


  <div v-if="previews" class="importhtml-box">
    <div class="importhtml-box-inner">
      <h3 class="importhtml-box-title">Preview</h3>
      <p class="importhtml-box-line">The preview below might not show all of the bookmarks that will be imported - it shows only 50 (or less) random bookmarks from your import file. </p>
      <div class="importhtml-preview-items">
        <a :href="bookmark.url" target="_blank" rel="noreferrer nooopener" class="importhtml-preview-item"
          v-for="(bookmark, index) in previews"
          :key="index + bookmark.url + bookmark.add_date"
        >
          <div class="importhtml-preview-item-icon"><iconsvg icon="feather-bookmark" /></div>
          <div class="importhtml-preview-item-content">
            <div class="importhtml-preview-item-title">
              <img :src="'https://s2.googleusercontent.com/s2/favicons?domain_url='+bookmark.url" width="16" height="16">
              {{bookmark.title ? bookmark.title : 'Untitled Bookmark'}}
            </div>
            <div class="importhtml-preview-item-subtitle">
              <!--
              <div class="importhtml-preview-item-subtitle-label">
                TAGGED
              </div> -->
              <div class="importhtml-preview-item-tags" v-tooltip="{placement: 'left', content: 'These tags will be imported and added to this bookmark as well. You will be able to change them after import in your library.'}">
                <div class="importhtml-preview-item-tag" v-if="bookmark.tags && bookmark.tags.length" v-for="tag, index in bookmark.tags" :key="tag+index">
                  <iconsvg icon="feather-tag" /> {{tag}}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>




</div>
</template>




<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .importhtml {
    font-size: 16px;
  }


  .importhtml-box {
    background: darken(white,5);
    border-radius: 8px;
    padding: 20px 20px;
    margin-top: 20px;

    &.mod-success {
      background: rgba($clr-green-100, .5);
    }

    &.mod-error {
      background: rgba($clr-red-100, .5);
    }

    &.mod-welcome {
      margin-top: 0;
    }
  }

    .importhtml-box-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      color: rgba(black,.9);

    }

    .importhtml-box-line {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      color: rgba(black,.85);
      margin-top: 10px;

      &.mod-first {
        font-weight: 400;
        margin-top: 10px;
      }


      &.mod-icon {
        position: relative;
        padding-left: 22px;
      }


      /deep/ svg {
        display: inline-block;
        vertical-align: baseline;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 1px;
        left: 0;
        fill: rgba(black,.8);

      }

      a {
        text-decoration: underline;
      }

    }








  .importhtml-box-face {

    background: $clr-ggather;
    display: block;
    padding: 15px 20px;
    border-radius: 8px;
    position: relative;

    html.mod-transitions-enabled & {
      transition: background 100ms ease-out;
    }

    &.mod-label {
      background: $clr-ggather-very-dark;
      cursor: pointer;
    }

    &.mod-file {
      background: $clr-green-a700;
      background: $clr-ggather;
      cursor: default;
    }

    &.mod-error {
      background: darken($clr-red-a400,4);
    }



    > input {
      // visually hidden
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px; width: 1px;
      margin: -1px; padding: 0; border: 0;
    }


    > span:nth-of-type(1) {
      position: absolute;
      top: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      display: block;
      /deep/ svg {
        stroke: rgba(white,.9);
        width: 24px;
        height: 24px;
      }
    }

    > span:nth-of-type(2) {
      display: block;
      padding-left: 40px;
    }

    > span:nth-of-type(2) > span:nth-of-type(1) {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.3;
      color: rgba(white,1);
      display: block;
    }

    > span:nth-of-type(2) > span:nth-of-type(2) {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      color: rgba(white,.8);
      display: block;
    }

    > span:nth-of-type(2) > span:nth-of-type(3) {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      color: rgba(white,.8);
      display: block;
    }
  }




  .importhtml-box-buttons-wrapper {
    margin-top: 5px;
  }

  .importhtml-box-button {
    display: inline-block;

    padding: 8px 20px;
    margin-top: 10px;
    margin-right: 10px;
    background: $clr-ggather-very-dark;
    border-radius: 3px;
    cursor: pointer;

    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    color: rgba(white,1);

    outline: none;

    html.mod-transitions-enabled & {
      transition: background 100ms ease-out;
    }

    &:focus {
      box-shadow: 0 0 0px 2px rgba(darken(white,50),.4);
    }

    &:hover {
      background: $clr-ggather;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      background: darken(white,30);
    }

    @media (max-width: 700px) {
      display: block;
      width: 100%;
    }

  }









  .importhtml-preview-items {
    margin-top: 20px;
  }


    .importhtml-preview-item {
      position: relative;
      padding: 10px 20px;
      background: white;
      border: 1px solid rgba(black,.1);
      border-radius: 3px;
      margin-top: 10px;
      display: block;
    }

      .importhtml-preview-item-icon {
        position: absolute;
        top: 20px;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        display: block;
        /deep/ svg {
          stroke: rgba($clr-ggather-very-dark,.7);
          width: 24px;
          height: 24px;
        }

        @media (max-width: 600px) {
          display: none;
        }
      }

      .importhtml-preview-item-content {
        padding-left: 50px;
        @media (max-width: 600px) {
          padding-left: 0;
        }
      }

        .importhtml-preview-item-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.2;
          color: rgba(black,1);
          display: block;
          margin-top: 5px;
          padding-bottom: 5px;

          img {
            vertical-align: top;
            margin-top: 1px;
            margin-right: 4px;
          }
        }

        .importhtml-preview-item-subtitle {
        }

          .importhtml-preview-item-subtitle-label {
            font-size: 14px;
            line-height: 1.2;
            margin-top: 10px;
            margin-bottom: 5px;
            display: block;
            font-weight: 400;
            color: rgba(black,.9);
            margin-right: 15px;
            display: none;
          }


          .importhtml-preview-item-tags {
            display: inline-block;
            padding-top: 5px;
          }

          .importhtml-preview-item-tag {
            font-size: 13px;
            font-weight: 400;
            line-height: 1.2;
            display: inline-block;
            margin-top: 2px;
            margin-bottom: 5px;
            color: rgba(black,.6);
            margin-right: 15px;

            /deep/ svg {
              margin-right: 1px;
              display: inline-block;
              vertical-align: middle;
              width: 14px;
              height: 14px;
              stroke: darken(white,25);
              fill: darken(white,25);
            }
          }






</style>




<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .importhtml-box {
    background-color: darken(white,70);
    border-color: darken(white,60);
    color: rgba(white,.8);
    h3 {
      color: rgba(white,.8);
    }
    p {
      color: rgba(white,.8);
    }
    & /deep/ svg path {
      fill: rgba(white,.6);
    }
  }


  .importhtml-box-button:disabled {
    background-color: darken(white,60);
  }

}
</style>
