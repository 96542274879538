<script>
import contains from 'dom-helpers/contains';
export default {


  data: function(){
    return {
      logoutConfirm: false,
    }
  },



  watch: {
    '$store.state.user': function(user){
      this.closeMenu();
    },
    '$store.state.userMenu': function(val){
      if (val) this.setupWindowClickEvent(1);
      else this.setupWindowClickEvent(0);
    },
    '$route': function(){
      this.closeMenu();
    },
    '$store.state.dialogbox': function(){
      this.closeMenu();
    },
    '$store.state.viewer': function(){
      this.closeMenu();
    },
    '$store.state.sidebarCollapse': function(){
      this.closeMenu();
    },
    '$store.state.sidebarVisible': function(){
      this.closeMenu();
    },
    '$store.state.activityVisible': function(){
      this.closeMenu();
    },
  },


  mounted: function(){
    this.getUdates();
  },


  methods: {

    getUdates: function(){
      fetch(this.$store.state.hq + '/updates?format=json', {method: 'GET'})
        .then(response => response.ok ? response.json() : null)
        .then(data => this.$store.commit('hqUpdates', data.docs))
    },

    setupWindowClickEvent: function(init){
      if (!this._usermenuWindowClickHandler) {
        var self = this;
        this._usermenuWindowClickHandler = function(event){
          var clickedInsideSection = contains(self.$refs.root, event.target);
          if (!clickedInsideSection) self.closeMenu();
        }
      }
      if (init) window.addEventListener('click', this._usermenuWindowClickHandler, false);
      else window.removeEventListener('click', this._usermenuWindowClickHandler, false);
    },

    openActivity: function(){
      this.$store.commit('userMenu', false);
      setTimeout(function() {
        this.$store.commit('activityVisible', true);
      }.bind(this), 1);
    },

    closeMenu: function(){
      this.$store.state.userMenu ? this.$store.commit('userMenu', false) : null;
    },


    toggleAppTheme: function(){
      this.$store.commit('appTheme', this.$store.state.appTheme==='default' ? 'dark' : 'default');
      // ms edge has problems painting/updating styles when changing theme... it's an edge case... lol
      var isEdge = window.navigator.userAgent.indexOf('Edge/') > -1;
      if (isEdge) window.location.reload();
    },

    //showFeedbackWidget: function(){
    //  this.closeMenu();
    //  try {
    //    window.UserLeap && window.UserLeap.displaySurvey(6914);
    //  } catch (error) {
    //    window.open('https://surveys.hotjar.com/s?siteId=382120&surveyId=127263', '_blank');
    //  }
    //},

  }



};
</script>


<template>
<div class="usermenu  common-light-scrollbar" v-if="$store.state.userMenu" ref="root">
<!--  <div class="usermenu-arrow"></div>-->
<div class="usermenu-inner">


  <template v-if="$store.state.user.is_anonymous">

    <button type="button" class="usermenu-btn"
    @click.prevent="$store.commit('dialogbox', {kind: 'signinup', subkind:'signin'})">
      <span class="text">Sign In</span>
    </button>

    <button type="button" class="usermenu-btn"
    @click.prevent="$store.commit('dialogbox', {kind: 'signinup', subkind:'signup'})">
      <span class="text">Create Account</span>
    </button>

    <div class="usermenu-spacer"></div>

  </template>


  <template v-if="$store.state.user.is_authenticated">

    <div class="usermenu-userbox">


      <router-link :to="'/' + $store.state.user.username"  class="usermenu-user-wrapper" :title="`(Click to open public profile) ${$store.state.user.username}`">

        <div class="usermenu-user-image">
          <img :src="$store.state.user.profile_image">
        </div>

        <div class="usermenu-user-label">
          <div class="usermenu-user-username">{{$store.state.user.username}}</div>
          <div class="usermenu-user-status">{{$store.state.user.is_premium ? 'Plus Account' : 'Basic Account'}}</div>
        </div>

      </router-link>

      <div class="usermenu-user-btns">

        <!--
        <router-link to="/activity" class="usermenu-user-btn">
          Activity
        </router-link> -->
        <!--
        <router-link :to="'/' + $store.state.user.username" class="usermenu-user-btn">
          View Profile
        </router-link>-->

        <router-link to="/upgrade" v-if="!$store.state.user.is_premium"  class="usermenu-user-btn">
          ⚡ Upgrade
        </router-link>

        <!--
        <button type="button" @click.prevent="openActivity" class="usermenu-user-btn mod-notifications" title="Notifications">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
            <path d="M 18.984375 -0.013671875 A 1.0001 1.0001 0 0 0 18 1 L 18 2 L 17 2 A 1.0001 1.0001 0 1 0 17 4 L 18 4 L 18 5 A 1.0001 1.0001 0 1 0 20 5 L 20 4 L 21 4 A 1.0001 1.0001 0 1 0 21 2 L 20 2 L 20 1 A 1.0001 1.0001 0 0 0 18.984375 -0.013671875 z M 12.390625 3.2929688 C 7.019625 4.4859687 3 9.27 3 15 C 3 21.627 8.373 27 15 27 C 20.73 27 25.514031 22.980375 26.707031 17.609375 C 25.171031 18.489375 23.397 19 21.5 19 C 15.701 19 11 14.299 11 8.5 C 11 6.603 11.510625 4.8289688 12.390625 3.2929688 z M 25 7 C 24.448 7 24 7.448 24 8 L 24 9 C 24 9.552 23.552 10 23 10 L 22 10 C 21.448 10 21 10.448 21 11 C 21 11.552 21.448 12 22 12 L 23 12 C 23.552 12 24 12.448 24 13 L 24 14 C 24 14.552 24.448 15 25 15 C 25.552 15 26 14.552 26 14 L 26 13 C 26 12.448 26.448 12 27 12 L 28 12 C 28.552 12 29 11.552 29 11 C 29 10.448 28.552 10 28 10 L 27 10 C 26.448 10 26 9.552 26 9 L 26 8 C 26 7.448 25.552 7 25 7 z"></path>
          </svg>
        </button>
         -->

        <button type="button" @click.prevent="toggleAppTheme" class="usermenu-user-btn mod-theme" title="Switch between light and dark application theme">
          <svg v-if="$store.state.appTheme==='default'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
            <path d="M 18.984375 -0.013671875 A 1.0001 1.0001 0 0 0 18 1 L 18 2 L 17 2 A 1.0001 1.0001 0 1 0 17 4 L 18 4 L 18 5 A 1.0001 1.0001 0 1 0 20 5 L 20 4 L 21 4 A 1.0001 1.0001 0 1 0 21 2 L 20 2 L 20 1 A 1.0001 1.0001 0 0 0 18.984375 -0.013671875 z M 12.390625 3.2929688 C 7.019625 4.4859687 3 9.27 3 15 C 3 21.627 8.373 27 15 27 C 20.73 27 25.514031 22.980375 26.707031 17.609375 C 25.171031 18.489375 23.397 19 21.5 19 C 15.701 19 11 14.299 11 8.5 C 11 6.603 11.510625 4.8289688 12.390625 3.2929688 z M 25 7 C 24.448 7 24 7.448 24 8 L 24 9 C 24 9.552 23.552 10 23 10 L 22 10 C 21.448 10 21 10.448 21 11 C 21 11.552 21.448 12 22 12 L 23 12 C 23.552 12 24 12.448 24 13 L 24 14 C 24 14.552 24.448 15 25 15 C 25.552 15 26 14.552 26 14 L 26 13 C 26 12.448 26.448 12 27 12 L 28 12 C 28.552 12 29 11.552 29 11 C 29 10.448 28.552 10 28 10 L 27 10 C 26.448 10 26 9.552 26 9 L 26 8 C 26 7.448 25.552 7 25 7 z"></path>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
            <path d="M 14.984375 0.98632812 A 1.0001 1.0001 0 0 0 14 2 L 14 5 A 1.0001 1.0001 0 1 0 16 5 L 16 2 A 1.0001 1.0001 0 0 0 14.984375 0.98632812 z M 5.796875 4.7988281 A 1.0001 1.0001 0 0 0 5.1015625 6.515625 L 7.2226562 8.6367188 A 1.0001 1.0001 0 1 0 8.6367188 7.2226562 L 6.515625 5.1015625 A 1.0001 1.0001 0 0 0 5.796875 4.7988281 z M 24.171875 4.7988281 A 1.0001 1.0001 0 0 0 23.484375 5.1015625 L 21.363281 7.2226562 A 1.0001 1.0001 0 1 0 22.777344 8.6367188 L 24.898438 6.515625 A 1.0001 1.0001 0 0 0 24.171875 4.7988281 z M 15 8 A 7 7 0 0 0 8 15 A 7 7 0 0 0 15 22 A 7 7 0 0 0 22 15 A 7 7 0 0 0 15 8 z M 2 14 A 1.0001 1.0001 0 1 0 2 16 L 5 16 A 1.0001 1.0001 0 1 0 5 14 L 2 14 z M 25 14 A 1.0001 1.0001 0 1 0 25 16 L 28 16 A 1.0001 1.0001 0 1 0 28 14 L 25 14 z M 7.9101562 21.060547 A 1.0001 1.0001 0 0 0 7.2226562 21.363281 L 5.1015625 23.484375 A 1.0001 1.0001 0 1 0 6.515625 24.898438 L 8.6367188 22.777344 A 1.0001 1.0001 0 0 0 7.9101562 21.060547 z M 22.060547 21.060547 A 1.0001 1.0001 0 0 0 21.363281 22.777344 L 23.484375 24.898438 A 1.0001 1.0001 0 1 0 24.898438 23.484375 L 22.777344 21.363281 A 1.0001 1.0001 0 0 0 22.060547 21.060547 z M 14.984375 23.986328 A 1.0001 1.0001 0 0 0 14 25 L 14 28 A 1.0001 1.0001 0 1 0 16 28 L 16 25 A 1.0001 1.0001 0 0 0 14.984375 23.986328 z"></path>
          </svg>
        </button>

      </div>

    </div>


    <!--
    <div class="usermenu-rwd-wrapper">

      <router-link to="/all" class="usermenu-btn">
        <span class="text">Library</span>
      </router-link>

      <router-link to="/feed" class="usermenu-btn">
        <span class="text">Social</span>
      </router-link>

      <router-link to="/discover" class="usermenu-btn">
        <span class="text">Discover</span>
      </router-link>

      <div class="usermenu-spacer"></div>

    </div>
    -->


    <div class="usermenu-spacer"></div>

    <router-link to="/settings" class="usermenu-btn">
      <span class="text">Account &amp; Settings</span>
    </router-link>

    <div class="usermenu-spacer"></div>



  </template>


  <!--
  <template v-if="!$store.state.user.is_premium">

    <router-link to="/upgrade" class="usermenu-btn" v-if="$store.state.user.is_premium">
      <span class="text">GGather Plus ⚡</span>
    </router-link>

    <router-link to="/upgrade" class="usermenu-btn" v-else-if="$store.state.user.is_authenticated">
      <span class="text">Upgrade To GGather Plus ⚡</span>
    </router-link>

    <router-link to="/upgrade" class="usermenu-btn" v-else-if="$store.state.user.is_anonymous">
      <span class="text">GGather Plus ⚡</span>
    </router-link>

    <div class="usermenu-spacer"></div>

  </template>
  -->


  <template v-if="$store.state.user.is_authenticated">

    <button class="usermenu-btn" @click.prevent="openActivity" >
      <span class="text">Notifications <span v-if="$store.state.unreadNotifs">({{$store.state.unreadNotifs}} Unread)</span></span>
    </button>
    <div class="usermenu-spacer"></div>


    <router-link to="/following" class="usermenu-btn">
      <span class="text">Following, Feed &amp; Discover</span>
    </router-link>
    <div class="usermenu-spacer"></div>

  </template>






  <a class="usermenu-btn" :href="$store.state.hq+'/kb'" target="_blank" rel="noopener noreferrer">
    <span class="text">Knowledge Base</span>
  </a>

  <div class="usermenu-spacer"></div>


  <!--
  <a href="https://surveys.hotjar.com/s?siteId=382120&surveyId=127263" target="_blank" rel="noopener noreferrer" class="usermenu-btn">
    <span class="text">Give Feedback</span>
  </a>-->

  <!--
  <a href="https://surveys.hotjar.com/s?siteId=382120&surveyId=127263" class="usermenu-btn" target="_blank" rel="noopener noreferrer">
    <span class="text">Submit Feedback ↗</span>
  </a>
   -->

  <!--
  <a class="usermenu-btn" :href="$store.state.hq+'/kb/help-ggather-growth'" target="_blank" rel="noopener noreferrer">
    <span class="text">Help GGather Growth ↗</span>
  </a>
  -->


  <router-link to="/contact" class="usermenu-btn">
    <span class="text">Contact</span>
  </router-link>







  <template v-if="$store.state.user.is_authenticated && $store.state.chrome">

    <div class="usermenu-spacer"></div>

    <a target="_blank" :href="$store.state.webapp" class="usermenu-btn" v-tooltip="'Sign out on GGather.com. You will be then automatically signed out from GGather extension.'">
      <span class="text">Sign Out</span>
    </a>

  </template>

  <template v-else-if="$store.state.user.is_authenticated">

    <div class="usermenu-spacer"></div>

    <button type="button" class="usermenu-btn"  v-if="logoutConfirm==false"
      @click.stop.prevent="logoutConfirm=true">
      <span class="text">Sign Out</span>
    </button>

    <button type="button" class="usermenu-btn" :class="{'mod-with-icon': logoutConfirm}" v-if="logoutConfirm==true"
      @click.stop.prevent="$store.dispatch('logoutUser');logoutConfirm=false;"  @mouseleave="logoutConfirm=false">
      <span class="text">Confirm Signing Out</span>
    </button>

  </template>



  <div v-if="$store.state.hqUpdates && $store.state.hqUpdates.length" class="usermenu-updates">
    <a :href="$store.state.hq+'/updates'" target="_blank" rel="noopener noreferrer" class="usermenu-updates-title">
      <span class="text">What's New</span>
    </a>
    <a v-for="doc of $store.state.hqUpdates.slice(0, 3)" class="usermenu-updates-btn" :href="$store.state.hq+doc.path" target="_blank" rel="noopener noreferrer">
      <span class="text">{{doc.title}}</span>
      <span class="subtext">{{doc.published_at_relative}}</span>
    </a>
  </div>


  <div class="usermenu-footer">

    <div class="usermenu-footer-btns">
      <a :href="$store.state.hq+'/legal/terms-of-use'" target="_blank" rel="noopener noreferrer" class="usermenu-footer-btn">
        <span class="text">Terms of Use</span>
      </a>
      <a :href="$store.state.hq+'/legal/privacy-policy'" target="_blank" rel="noopener noreferrer" class="usermenu-footer-btn">
        <span class="text">Privacy Policy</span>
      </a>
      <a :href="$store.state.hq+'/legal/cookies-policy'" target="_blank" rel="noopener noreferrer" class="usermenu-footer-btn">
        <span class="text">Cookies Policy</span>
      </a>
    </div>


    <div class="usermenu-footer-version-and-stuff">

      <a :href="$store.state.apiHost" target="_blank" class="usermenu-copy">

        <!--  <img class="usermenu-logo" src="../../../common/img/ggather-logo-big-blue.png">-->

        <div class="usermenu-logo-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438 668"><path fill="#000000" class="cls-1" d="M376.29,0h-33a60.31,60.31,0,0,0-54.49,34.46l-35,73.69a14.65,14.65,0,0,0,13.23,20.92h71.62c19.15,0,29.42,22.53,16.85,37-69.92,80.39-190.26,216-251.8,287-5.68,6.56-16.18-.2-12.36-8C120.5,385.68,180.81,268,209.8,208.45a7.42,7.42,0,0,0-6.68-10.67H115c-17.16,0-26.44-20.1-15.32-33.16l99-116.32C214.94,29.28,201.42,0,176.42,0H61.71A61.31,61.31,0,0,0,.4,61.31V636.42c0,29.36,36.28,43.16,55.79,21.23L173.86,525.41a60.42,60.42,0,0,1,90.28,0L381.81,657.65c19.52,21.93,55.79,8.13,55.79-21.23V61.31A61.31,61.31,0,0,0,376.29,0Z"/></svg>
        </div>

        <div class="usermenu-copy-item  mod-bold">

          <span v-if="$store.state.BUILD=='webapp'">
            <span v-tooltip="'Web App Version'">GGather.com</span>
          </span>

          <span v-if="$store.state.BUILD=='devwebapp'">
            <span v-tooltip="'Dev Web App Version'">127.0.0.1:9800</span>
          </span>

        </div>

        <div class="usermenu-copy-item">

          <span v-if="$store.state.BUILD=='chrome'">
            <span v-tooltip="'Chrome Extension Version'">CHROME</span>
          </span>

          <span v-if="$store.state.BUILD=='devchrome'">
            <span v-tooltip="'DevChrome Extension Version'">DEVCHROME</span>
          </span>

          <span>v{{$store.state.VERSION}}</span>

        </div>

        <div class="usermenu-copy-item " v-tooltip="'© 2017-2022 GGather.com. All Rights Reserved.'">© 2017-2022</div>
      </a>


      <!--
      <div class="usermenu-scal">

        <a target="_blank" href="//facebook.com/ggather" title="Like GGather on Facebook" class="usermenu-scal-btn mod-fb">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
            <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"></path>
          </svg>
        </a>

        <a target="_blank" href="//twitter.com/ggatherapp" title="Follow GGather on Twitter" class="usermenu-scal-btn mod-tw">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
            <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
          </svg>
        </a>

        <a target="_blank" href="//instagram.com/ggatherapp" title="Follow GGather on Instagram" class="usermenu-scal-btn mod-ig">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
            <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
          </svg>
        </a>
        -->


      </div>

    </div>

  </div>




</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .usermenu {

    z-index: 210;
    background-color: darken(white,0);
    border-radius: 8px;
    width: 340px;
    border: 1px solid darken(white,10);
    overflow: hidden;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;

    overflow: auto;

    position: fixed;
    top: 40px;
    left: 226px;

    //height: 100%;

    touch-action: manipulation;
    -webkit-overflow-scrolling: auto;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: scrollbar;

    max-height: calc(100% - 55px);

    box-shadow: 0 2px 15px 0px rgba(black,.07);


    @media (max-width: 700px){
      //width: auto;
      width: 280px;
      left: 10px;
    }

    html.mod-page-without-sidebar & {
      left: auto;
      right: 10px;
    }

  }


  .usermenu-arrow {
    background: red;
    width: 10px;
    height: 10px;
    position: relative;

  }

  .usermenu-inner {
    padding: 6px 0;
  }

    .usermenu-updates {
      background: rgba(darken($clr-ggather,0),.05);
      border-top: 1px solid rgba(darken($clr-ggather,0),.15);
      border-bottom: 1px solid rgba(darken($clr-ggather,0),.15);
      padding: 3px 0;
      margin: 3px 0;
    }

    .usermenu-updates-title {
      line-height: 1;
      padding: 10px 15px 5px 15px;
      display: block;
      text-align: left;
      font-weight: 400;
      display: block;

      font-size: 11px;
      color: rgba(black,.7);

      &:hover {
        background-color: rgba($clr-ggather,.1);
        color: rgba(black,.9);
      }
    }

    .usermenu-updates-btn {
      line-height: 1.3;
      padding: 7px 15px 6px 15px;
      display: block;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      position: relative;
      outline: none;

      html.mod-transitions-enabled & {
        transition: background-color 100ms ease-out;
      }

      & > .text {
        font-size: 13px;
        font-weight: 500;
        color: rgba(black,.9);
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }
      }

      & > .subtext {
        font-size: 11px;
        font-weight: 400;
        color: rgba(black,.7);
        display: block;
        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }
      }


      &:hover {
        background-color: rgba($clr-ggather,.1);
        & > .text {
          color: rgba(black,.9);
        }
        & > .subtext {
          color: rgba(black,.6);
        }
      }



    }





    .usermenu-btn {
      line-height: 1.3;
      padding: 8px 15px 9px 15px;
      display: block;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      position: relative;

      html.mod-transitions-enabled & {
        transition: background-color 100ms ease-out;
      }
      outline: none;

      & > .text {
        font-size: 13px;
        font-weight: 500;
        color: rgba(black,.9);
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }
      }
      & > .subtext {
        font-size: 11px;
        font-weight: 400;
        color: rgba(black,.65);
        display: block;
        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }
      }
      &:hover {
        background-color: rgba(black,.05);
        & > .text {
          color: rgba(black,.9);
        }
        & > .subtext {
          color: rgba(black,.6);
        }
      }
      &.mod-user-anonymous {
        opacity: .3;
      }

    }



  .usermenu-beta {
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    display: inline-block;
    font-size: 9px;
    font-weight: 500;
    line-height: .2;
    margin-left: 4px;
    margin-top: -4px;

    text-transform: uppercase;
    padding: 6px 3px;
    background-color: lighten(#eee,3);

    background-color: white;
    //.viewer-tab-btn.mod-active & {
    //    background-color: #222;
    //    color: #eee;
    //    border-color: #111;
    //}
  }


  .usermenu-rwd-wrapper {
    display: none;
    @media (max-width: 650px){
      display: block;
    }
  }


  .usermenu-spacer {
    margin: 3px 0;
    background-color: darken(white,7);
    height: 1px;
    width: auto;
  }




  .usermenu-userbox {
    display: block;
    text-align: left;
    padding: 5px 8px 8px 8px;
    position: relative;
    font-size: 0;
    //background-color: darken(white,2);
  }


    .usermenu-user-wrapper {
      display: inline-block;
      border-radius: 100px;
      padding: 5px 14px 5px 7px;
      &:hover {
        background: rgba(black, .05);
      }
    }


    .usermenu-user-image {
      width: 22px;
      overflow: hidden;
      border-radius: 100px;
      background-color: #eee;
      display: inline-block;
      margin-top: 1px;
      margin-right: 10px;

      & > img {
        max-width: 100%;
        border-radius: 100px;

      }

    }



    .usermenu-user-label {
      display: inline-block;

    }


      .usermenu-user-username {
        color: rgba(black,.8);
        font-weight: 600;
        font-size: 14px;
        display: block;
        line-height: 1;

        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 700px) {
          font-size: 13px;
          max-width: 80px;
        }
      }

      .usermenu-user-status {
        color: rgba(black,.5);
        font-weight: 400;
        font-size: 11px;
        display: block;
        line-height: 1;
      }



    .usermenu-user-btns {
      position: absolute;
      top: 8px;
      right: 15px;
      font-size: 0;

    }



    .usermenu-user-btn {
      font-size: 13px;
      font-weight: 600;
      padding: 5px 10px;
      margin-left: 12px;
      border-radius: 100px;
      display: inline-block;
      outline: none;

      background-color: darken(white,0);
      border: 1px solid darken(white,10);
      color: rgba(black,.85);

      html.mod-transitions-enabled & {
        transition: background-color 100ms ease-out, color 100ms ease-out;
      }
      html.mod-transitions-enabled & svg {
        transition: fill 100ms ease-out;
      }

      & svg {
        width: 20px;
        height: 20px;
        fill: darken(white,70);
      }

      &.mod-theme {
        padding: 4px;
      }

      &:hover {
        color: rgba(black,1);
        background-color: darken(white,10);
        border-color: darken(white,18);
        & svg {
          fill: darken(white,80);
        }
      }

    }













  .usermenu-footer {


  }


    .usermenu-footer-btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 15px;
      padding-top: 8px;
    }

    .usermenu-footer-btn {
      font-size: 11px;
      color: rgba(black,.5);

      &:hover {
        color: rgba(black,.9);
      }
    }



    .usermenu-footer-version-and-stuff {

      //border-top-style: solid;
      //border-top-width: 1px;

      white-space: nowrap;
      overflow: hidden;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 11px;
      padding-right: 20px;
      padding-top: 10px;
    }



    .usermenu-logo {
      max-width: 15px;
      max-height: 20px;
      display: inline-block;
      vertical-align: top;
      margin-right: 0px;
      opacity: .8;
      //margin-top: 3px;
    }

    .usermenu-logo-svg {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      > svg {
        width: 10px;
        height: 15px;

        > path {
          fill: $clr-ggather;
        }
      }
    }

    .usermenu-copy {
      display: inline-block;
      vertical-align: middle;
      //margin-top: 1px;
    }

      .usermenu-copy-item {
        margin-right: 0px;
        font-size: 11px;
        line-height: 1;
        margin-top: 3px;
        display: inline-block;
        vertical-align: top;
        color: rgba(black,.5);

        &.mod-bold {
          font-weight: 500;
        }

      }



    .usermenu-scal {
      font-size: 12px;
      display: inline-block;
      vertical-align: top;
      //margin-top: 2px;
      float: right;

      position: relative;
      top: -3px;

    }

      .usermenu-scal-btn {
        margin-left: 5px;
        display: inline-block;
        opacity: .6;
        &:hover {
          opacity: .9;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: rgba(black,1);

        }
        &.mod-ig {

        }
        &.mod-tw {

        }
        &.mod-fb {

        }
      }

</style>









  <style lang="scss">
  @import "../../../common/style/_variables.scss";
  html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .usermenu {
    background-color: darken(white,85);
    border-color: darken(white,80);
    color: rgba(white,.8);
  }

  .usermenu-spacer {
    background-color: darken(white,80);
  }

  .usermenu-btn:hover {
    background-color: rgba(white,.05);
  }

  .usermenu-btn>.text {
    color: rgba(white,.8);
  }

  .usermenu-btn:hover>.text {
    color: rgba(white,.9);
  }


  .usermenu-updates {
    background: rgba(darken($clr-ggather,0),.07);
    border-top: 1px solid rgba(darken($clr-ggather,0),.2);
    border-bottom: 1px solid rgba(darken($clr-ggather,0),.2);
  }



  .usermenu-updates-title {
    color: rgba(white,.5);
  }

  .usermenu-updates-title:hover {
    background-color: rgba($clr-ggather,.1);
    color: rgba(white,.7);
  }


  .usermenu-updates-btn:hover {
    background-color: rgba($clr-ggather,.1);
  }

  .usermenu-updates-btn>.text {
    color: rgba(white,.8);
  }

  .usermenu-updates-btn:hover>.text {
    color: rgba(white,.9);
  }

  .usermenu-updates-btn>.subtext {
    color: rgba(white,.5);
  }

  .usermenu-updates-btn:hover>.subtext {
    color: rgba(white,.6);
  }


  .usermenu-user-wrapper:hover {
    background-color: rgba(white,.05);
  }

  .usermenu-user-username {
    color: rgba(white,.8);
  }

  .usermenu-user-status {
    color: rgba(white,.5);
  }





  .usermenu-user-btn {
    background-color: darken(white,85);
    border-color: darken(white,75);
    color: rgba(white,.85);
  }

  .usermenu-user-btn svg {
    fill: darken(white,20);
  }

  .usermenu-user-btn:hover {
    color: rgba(white,1);
    background-color: darken(white,75);
    border-color: darken(white,65);
  }

  .usermenu-user-btn:hover svg {
    fill: darken(white,10);
  }




  .usermenu-footer-btn {
    color: rgba(white,.5);
  }
  .usermenu-footer-btn:hover {
    color: rgba(white,.9);
  }




  .usermenu-copy-item {
    color: rgba(white,.5);
  }

  .usermenu-scal-btn svg {
    fill: white;
  }


}
</style>
