/*
  Notes: window.chrome doesn't have window.chrome.runtime when site is on HTTP instead HTTPS

*/

var BrowserExtension = function(options){
  var options = options ? options : {};

  this.extension_id = options.extension_id;
  this.environment = options.environment ? options.environment : 'webapp';

  this.webapp = this.environment == 'webapp';
  this.chrome = this.environment == 'chrome';

  if (this.chrome && (!window.chrome.tabs)) {
    console.warn('(Vue-Extension Warning) Environment is set to Google Chrome but \'window.chrome.tabs\' API is not available.')
  }

  this.webext = window.chrome;

  //for (var key in options){
  //    this[key] = options[key];
  //}

}




BrowserExtension.prototype.getActiveTabUrl = function(callback){

  if (this.webapp) {
    callback ? callback('https://www.and.co/digital-nomad-book') : null;
    return;
  }

  this.webext.tabs.query({currentWindow: true, active: true}, function(tabs){
    callback ? callback(tabs[0].url) : null;
  });

}




BrowserExtension.prototype.sendMessage = function(data, callback){

  if (this.webapp) {
    callback ? callback({msg: 'This is callback for sendMessage', dataSent: data}) : null;
    return;
  }

  this.webext.runtime.sendMessage(this.extension_id, data, callback ? function(response){
    callback.call(this, response);
  } : void 0);

}



BrowserExtension.prototype.openLinksInNewTabs = function(urlsToOpen, callback){

  var data = {
    code: 'openLinksInNewTabs',
    urls: urlsToOpen,
  }

  // if we are in web app then send message to extension to open links
  if (this.webapp) {
    this.webext.runtime.sendMessage(this.extension_id, data, function(response){
      callback ? callback.call(this, response) : null;
    });
    return;
  }

  // if we are in extension then just open the links directly
  else {
    for (var i=0; i<urlsToOpen.length; i++) {
      var url = urlsToOpen[i];
      this.webext.tabs.create({url: url});
    }
    callback ? callback.call(this, 'Opened directly') : null;
  }


}



var VueExtension = {
  install: function(Vue, options){
    var extension = new BrowserExtension(options);
    Vue.extension = extension;
    Vue.prototype.$extension = extension;
  },
};

export default VueExtension;
