<script>
export default {

  name: 'page-settings',

  head: {
    title: function(){
      return {
        inner: this.headTitle,
      };
    },
  },


  data: function(){
    return {

      headTitle: '',

      tabs: {
        'account': {
          active: false,
          title: 'Account',
        },
        'experience': {
          active: false,
          title: 'Experience',
        },
        //extension: {
        //  active: false,
        //  title: 'Chrome Extension',
        //},
        'import': {
          active: false,
          title: 'Import (New)',
        },
        'import-legacy': {
          active: false,
          title: 'Import',
        },
        'export': {
          active: false,
          title: 'Export',
        },
        'billing': {
          active: false,
          title: 'Billing & Payments',
        },
        'newsletter': {
          active: false,
          title: 'Email Notifications',
        },
        'data': {
          active: false,
          title: 'Data Control',
        },
      },



    };
  },


  watch: {

    '$route.path': function(){
      this.activateTabFromPath();
    },

  },


  computed: {


    /*

    chromeOpenSave: {
      get: function(){
        return !this.$store.state.chromeOpenSave;
      },
      set: function(value) {
        this.$store.commit('chromeOpenSave', !value);
      },
    },



    chromeWidget: {
      get: function(){
        return this.$store.state.chromeWidget;
      },
      set: function(value) {
        this.$store.commit('chromeWidget', value);
        this.$extension.sendMessage({code: 'widgetOption'});
      },
    },

    */




    tabSlug: function(){
      var pathArray = this.$route.path.split('/');
      if (pathArray.length && pathArray[2] && (typeof this.tabs[pathArray[2]] !== 'undefined')) {
        return pathArray[2];
      } else {
        return '';
      }

    },

  },


  created: function(){
    this.activateTabFromPath();
  },


  mounted: function(){
  },

  updated: function(){
  },

  methods: {

    changeTab: function(slug){

      // external import & export in extension
      /*
      if (this.$store.state.chrome && ((slug=='import') || (slug=='export'))) {
        var win = window.open(this.$store.state.webapp+'/settings/'+slug, '_blank');
        win.focus();
        return;
      }*/

      this.$router.push('/settings/'+slug);
    },


    activateTabFromPath: function(){

      var slug = this.tabSlug;

      for (var key in this.tabs) {
        var tab = this.tabs[key];
        if (slug===key) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      }

      if (slug) {
        this.headTitle = this.tabs[slug].title + ' × ' + this.$route.meta.title;
      } else {
        this.headTitle = 'Settings';
        this.$router.replace('/settings/');
      }
      this.$emit('updateHead');
    },







  },

};
</script>


<template>
<div class="router-page" v-if="$store.getters.userIsAuthenticated">
<div class="settings" :class="['mod-tab-'+(tabSlug?tabSlug:'none'), tabSlug?'mod-enabled-tab':'mod-disabled-tab']">
<div class="settings-inner">



  <router-link to="/all" class="settings-back-btn mod-library">
    ← Back to My Library
  </router-link>

  <router-link v-if="tabSlug" to="/settings" class="settings-back-btn mod-notab">
    ← All Settings
  </router-link>



  <div class="settings-sections">




    <!-- Userbox + Nav -->
    <div class="settings-section-first">

      <div class="settings-userbox">

        <div class="settings-userbox-img">
          <img :src="$store.state.user.profile_image" alt="">
        </div>

        <div class="settings-userbox-text">

          <div class="settings-userbox-username">{{$store.state.user.username}}</div>
          <div class="settings-userbox-title">
            {{$store.state.user.is_premium ? 'Plus Account' : 'Basic Account'}}
          </div>


        </div>

      </div>




      <div class="settings-nav">


        <a :href="$store.state.webapp+'/settings/account'" class="settings-nav-btn mod-first" @click.prevent="changeTab('account')"
        :class="{'mod-active': tabs['account'].active}">
          <span class="title">Account</span>
          <span v-if="tabSlug===''" class="subtitle">Customize your email, username, avatar and similar things.</span>
        </a>

        <!--
        <a v-if="$store.state.chrome" :href="$store.state.webapp+'/settings/extension'" class="settings-nav-btn" @click.prevent="changeTab('extension')"
        :class="{'mod-active': tabs['extension'].active}">
          <span class="title">Chrome Extension</span>
          <span v-if="tabSlug===''" class="subtitle">Change GGather Google Chrome extension preferences.</span>
        </a>-->



        <a :href="$store.state.webapp+'/settings/experience'" class="settings-nav-btn" @click.prevent="changeTab('experience')"
        :class="{'mod-active': tabs['experience'].active}">
          <span class="title">Experience</span>
          <span v-if="tabSlug===''" class="subtitle">Customize GGather appearance and behavior.</span>
        </a>



        <a :href="$store.state.webapp+'/settings/billing'" class="settings-nav-btn" @click.prevent="changeTab('billing')"
        :class="{'mod-active': tabs['billing'].active}">
          <span class="title">Billing &amp; Payments</span>
          <span v-if="tabSlug===''" class="subtitle">Track your billing information.</span>
        </a>

        <!-- import -->



        <a v-if="!$store.state.chrome"  :href="$store.state.webapp+'/settings/import'" class="settings-nav-btn" @click.prevent="changeTab('import')"
        :class="{'mod-active': tabs['import'].active}">
          <span class="title">Import</span>
          <span v-if="tabSlug===''" class="subtitle">Import your bookmarks file.</span>
        </a>
  <!--

        <a v-if="!$store.state.chrome"  :href="$store.state.webapp+'/settings/import-legacy'" class="settings-nav-btn" @click.prevent="changeTab('import-legacy')"
        :class="{'mod-active': tabs['import-legacy'].active}">
          <span class="title">Import</span>
          <span v-if="tabSlug===''" class="subtitle">Import your bookmarks file.</span>
        </a> -->

        <a v-if="$store.state.chrome" target="_blank" :href="$store.state.webapp + '/settings/import'" class="settings-nav-btn">
          <span class="title">Import <span class="icon icon-external-link"></span></span>
          <span v-if="tabSlug===''" class="subtitle">Import your bookmarks file.</span>
        </a>




        <!--  export -->

        <a :href="$store.state.webapp+'/settings/export'" class="settings-nav-btn" @click.prevent="changeTab('export')"
        :class="{'mod-active': tabs['export'].active}">
          <span class="title">Export</span>
          <span v-if="tabSlug===''" class="subtitle">Export your GGather library to multiple formats. </span>
        </a>


        <a :href="$store.state.webapp+'/settings/data'" class="settings-nav-btn mod-last" @click.prevent="changeTab('data')"
        :class="{'mod-active': tabs['data'].active}">
          <span class="title">Data Control</span>
          <span v-if="tabSlug===''" class="subtitle">Learn how we use your data on GGather and cleanup/remove your account.</span>
        </a>

        <!--
        <a v-if="false" :href="$store.state.webapp+'/settings/newsletter'" class="settings-nav-btn mod-last" @click.prevent="changeTab('newsletter')"
        :class="{'mod-active': tabs['newsletter'].active}">
          <span class="title">Email Notifications</span>
          <span v-if="tabSlug===''" class="subtitle">Coming soon...</span>
        </a>
        -->


      </div>


    </div>



    <!-- Content -->
    <div class="settings-section-second"  v-if="tabSlug">
    <div class="settings-tabs">
    <!--<transition duration="300"  mode="out-in">  -->


      <!-- Account Tab -->
      <div class="settings-tab" v-if="tabs['account'].active"  key="tabaccount"
      :class="{'mod-active': tabs['account'].active}">
        <div class="settings-tab-title mod-first">Account</div>
        <div class="settings-tab-content mod-no-padding">
          <settings-account />
        </div>
      </div>



      <!-- Experience Tab -->
      <div class="settings-tab" v-if="tabs['experience'].active" key="tabexperience"
      :class="{'mod-active': tabs['experience'].active}">
        <div class="settings-tab-title mod-first">Experience</div>
        <div class="settings-tab-content mod-no-padding">
          <settings-exp />
        </div>
      </div>





      <!-- Extension Experience Tab -->
      <!--
      <div class="settings-tab" v-if="tabs['extension'].active" key="tabextension"
      :class="{'mod-active': tabs['extension'].active}">

        <div class="settings-tab-title mod-first">Chrome Extension</div>

        <div class="settings-tab-content mod-no-padding">


          <div class="settings-input-group">

            <label for="website" class="settings-input-label">Lightweight Widget</label>
            <div class="settings-input-control">

              <div class="settings-checkbox">
                <input type="checkbox" id="chromeWidget" v-model="chromeWidget">
                <label tabindex="1" for="chromeWidget">
                  Enable
                  <div class="settings-checkbox-desc">
                    Clicking on extension icon will bring up a less resource hungry lightweight &amp; fast save widget instead of your whole library. To disable it, right click on the extension icon and click "Lightweight".
                    <img src="../../../common/img/other/extension-option-lightweight.png">
                  </div>
                </label>
              </div>

            </div>

          </div>


          <div class="settings-input-group" :class="{'mod-disabled': chromeWidget}">

            <label for="website" class="settings-input-label">Save Button</label>
            <div class="settings-input-control">

              <div class="settings-checkbox">
                <input type="checkbox" id="chromeOpenSave" v-model="chromeOpenSave" :disabled="chromeWidget">
                <label tabindex="1" for="chromeOpenSave">
                  Enable

                  <div class="settings-checkbox-desc">
                    Clicking on extension icon will instantly save the page you're visiting. <br>If this is enabled you'll need to manually click "Save" button after opening extension window.
                    <img src="../../../common/img/other/extension-options-instant-save.png">
                  </div>
                </label>
              </div>

            </div>

          </div>




        </div>

      </div>
      -->




      <!-- Import Tab -->
      <div class="settings-tab" v-if="tabs['import'].active" key="tabimport"
      :class="{'mod-active': tabs['import'].active}">
        <div class="settings-tab-title mod-first">Import</div>
        <div class="settings-tab-content">
          <settings-importhtml />
        </div>
      </div>


      <!-- Import Legacy Tab -->
      <div class="settings-tab" v-if="tabs['import-legacy'].active" key="tabimportlegacy"
      :class="{'mod-active': tabs['import-legacy'].active}">
        <div class="settings-tab-title mod-first">Import (Legacy)</div>
        <div class="settings-tab-content">
          <settings-importhtmlold />
        </div>
      </div>





      <!-- Export Tab -->
      <div class="settings-tab" v-if="tabs['export'].active" key="tabexport"
      :class="{'mod-active': tabs['export'].active}">
        <div class="settings-tab-title mod-first">Export</div>
        <div class="settings-tab-content">
          <settings-export />
        </div>
      </div>






      <!-- Billing Tab -->
      <div class="settings-tab" v-if="tabs['billing'].active" key="tabbillinglegacy"
      :class="{'mod-active': tabs['billing'].active}">
        <div class="settings-tab-title mod-first">Billing &amp; Payments</div>
        <div class="settings-tab-content">
          <settings-billing />
        </div>
      </div>






      <!-- Newsletter Tab -->
      <div class="settings-tab" v-if="tabs['newsletter'].active"  key="tabnewsletter"
      :class="{'mod-active': tabs['newsletter'].active}">
        <div class="settings-tab-title mod-first">Email Notifications</div>
        <div class="settings-tab-content">
          <p>Planned...</p>
        </div>
      </div>


      <!-- Data Tab -->
      <div class="settings-tab" v-if="tabs['data'].active"   key="tabdata"
      :class="{'mod-active': tabs['data'].active}">
        <div class="settings-tab-title mod-first">Data Control</div>
        <div class="settings-tab-content">
          <settings-data />
        </div>
      </div>



    <!-- </transition>  -->
    </div>
    </div>



  </div>


</div>
</div>
</div>
</template>


<style lang="scss">

  @import "../../../common/style/_variables.scss";


  .settings {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    @media (max-width: 900px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

    .settings-inner {
      margin-bottom: 50px;
    }


    .settings-sections {
      display: flex;

      @media (max-width: 900px){
        display: block;
      }
    }



      .settings-back-btn {
        font-size: 14px;
        font-weight: 500;
        position: relative;
        display: block;
        text-align: left;
        outline: none;
        margin-top: 22px;
        width: 100%;
        color: darken(white,90);

        @media (max-width: 900px){
          padding: 0 20px;
        }

        &.mod-notab {
          display: none;
          @media (max-width: 900px){
            display: block;
          }
        }

        &.mod-library {
          display: block;
          .settings.mod-enabled-tab & {
            @media (max-width: 900px){
              display: none;
            }
          }
        }
      }


      .settings-section-first {
        display: inline-block;
        width: 300px;
        //min-width: 300px;
        flex-basis: 300px;
        flex-grow: 300px;
        flex-shrink: 300px;
        padding-right: 20px;
        padding-top: 20px;

        .settings.mod-disabled-tab & {
          display: block;
          width: auto;
          flex: auto;
        }

        @media (max-width: 900px){
          padding-right: 0;
          width: auto;

          .settings.mod-enabled-tab & {
            display: none;
          }

          .settings.mod-disabled-tab & {
            display: block;
          }

        }

      }

      .settings-section-second {
        display: inline-block;
        width: 900px;
        width: calc(100% - 300px);

        padding-top: 20px;

        @media (max-width: 900px){
          width: auto;
          display: block;
        }

      }




        .settings-userbox {
          background-color: $clr-ggather;
          border-radius: 8px;
          border: 1px solid darken($clr-ggather,10);
          padding: 13px 15px;

          display: flex;
          align-items: center;

          @media (max-width: 900px) {
            border-radius: 0px;
          }
        }

          .settings-userbox-img {
            width: 50px;
            overflow: hidden;
            background-color: #eee;
            height: 50px;
            display: inline-block;
            border-radius: 200px;

            margin-right: 20px;


            img {
              width: 100%;
              height: 100%;
            }
          }


          .settings-userbox-text {
            display: inline-block;
          }

          .settings-userbox-username {
            display: block;
            font-size: 17px;
            font-weight: 500;
            color: rgba(white,.9);
            line-height: 1.1;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .settings-userbox-title {
            font-size: 13px;
            font-weight: 400;
            color: rgba(white,.5);
            line-height: 1.1;
            margin-top: 2px;
          }






        .settings-nav {
          background-color: white;
          border-radius: 8px;
          border: 1px solid darken(white,7);
          margin-top: 20px;

          @media (max-width: 900px) {
            border-radius: 0px;
          }
        }


          .settings-nav-btn {
            padding: 15px 15px;
            padding-right: 30px;
            background-color: white;
            position: relative;
            display: block;
            width: 100%;
            text-align: left;
            border-bottom: 1px solid darken(white,7);
            outline: none;

            &.mod-first {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }


            &.mod-last {
              border-bottom: none;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            .title {
              display: block;
              font-size: 15px;
              font-weight: 500;
              color: rgba(black,.9);
              line-height: 1.1;
            }

            &.mod-active .title {
              font-weight: 700;
              color: rgba(black,1);
            }

            .subtitle {
              display: block;
              font-size: 13px;
              font-weight: 400;
              color: rgba(black,.6);
              line-height: 1.1;
              margin-top: 4px;

            }

            &:hover::after {
              color: rgba(black,.1);
            }


            &.mod-active::after {
              color: rgba(black,.7);
            }

          }







      .settings-tabs {

        background-color: white;
        border-radius: 9px;
        border: 1px solid darken(white,7);

        position: relative;

        @media (max-width: 900px) {
          border-radius: 0;
        }
      }


        .settings-tab {

          transform: translateX(0px);
          opacity: 1;


          html.mod-transitions-enabled & {
            transition:
              opacity 200ms ease-out,
              transform 200ms ease-out;
          }

          &.v-enter {
            //transform: translateX(10px);
            opacity: 0;
          }

          &.v-enter-active,
          &.v-leave-active {

          }

          &.v-leave-to {
            //transform: translateX(10px);
            opacity: 0;
          }


        }

        .settings-tab-title {


          border-bottom: 1px solid darken(white,7);
          color: rgba(black,.9);
          font-size: 20px;
          font-weight: 600;
          padding: 15px 20px 15px 20px;


          //font-size: 18px;
          //color: rgba(black,.7);

          margin-top: 20px;

          &.mod-first {
            margin-top: 0px;
            //color: rgba(black,.9);
            //font-size: 20px;
          }
        }


        .settings-tab-content {
          padding: 15px 15px;
          font-size: 15px;


          &.mod-no-padding {
            padding: 0;
          }
        }












    .settings-input-group {
      display: flex;
      border-bottom: 1px solid darken(white,3);
      padding: 17px 20px;
      align-items: flex-start;

      &:last-of-type {
        border: none;
      }

      &.mod-disabled {
        opacity: .5;
        pointer-events: none;
        user-select: none;
      }

      @media (max-width: 900px){
        display: block;

      }
    }

      .settings-input-label {
        display: inline-block;
        width: 200px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(black,.9);
        margin-top: 3px;


        @media (max-width: 900px){
          display: block;
          width: auto;

        }
      }


      .settings-input-control {
        display: inline-block;
        width: 75%;
        width: calc(100% - 200px);
        font-size: 14px;


        @media (max-width: 900px){
          display: block;
          width: auto;

        }

        input[type='checkbox'] {

        }

        textarea,
        select,
        input[type='url'],
        input[type='search'],
        input[type='password'],
        input[type='email'],
        input[type='text'] {
          background: white;
          font-size: 14px;
          border: 1px solid darken(white,10);
          width: 100%;
          font-weight: 400;
          max-width: 300px;
          padding: 7px 10px;
          border-radius: 2px;
          color: rgba(black,.9);
          line-height: 1.2;
          //font-family: monospace, monospace;
        }


        input:read-only {
          background-color: transparent;
        }

        select {
          appearance: menulist;
        }

        textarea {
          resize: vertical;
        }


        img {
          width: 100%;
          max-width: 100%;
          display: block;
          border: 1px solid darken(white,10);
          border-radius: 3px;
        }


        img.profile {
          //border-radius: 1000px;
          max-width: 300px;
          margin-bottom: 10px;
          background: darken(white,7);
        }

        img.cover {
          max-width: 300px;
          margin-bottom: 10px;
        }


        input.hidden {
          position: absolute;
          overflow: hidden;
          clip: rect(0 0 0 0);
          height: 1px; width: 1px;
          margin: -1px; padding: 0; border: 0;
        }


        .img-button {
          font-size: 13px;
          display: inline-block;
          margin-right: 10px;
          border: 1px solid darken(white,10);
          background: white;
          padding: 8px 10px;
          line-height: 1;
          border-radius: 2px;
          min-width: 143px;
          &:hover {

          }
        }

        .common-button {
          font-size: 13px;
          display: inline-block;
          margin-right: 10px;
          background: white;
          border: 1px solid darken(white,10);
          padding: 9px 10px 8px 10px;
          line-height: 1;
          border-radius: 2px;
          min-width: 143px;
          text-align: left;
          text-decoration: none;

          &:hover {

          }

          & svg {
            vertical-align: top;
            display: inline-block;
            fill: darken(white,80);
            width: 10px;
            height: 10px;
          }
        }


        .common-button.mod-margin-top-5 {
          margin-top: 5px;
        }


        .common-button.mod-margin-top-10 {
          margin-top: 10px;
        }

        .common-button,
        input,
        textarea,
        select,
        button {
          outline: none;

          &:hover {
            border-color: darken(white,25);
          }

          &:focus {
            border-color: darken(white,25);
            background-color: darken(white,2);
            //background-color: darken(white,4);
            //box-shadow: 0 0 0 2px rgba($clr-ggather,.2);
          }
        }

      }



      .settings-input-info {
        display: block;
        font-size: 12px;
        padding: 10px 15px;
        border: 1px solid darken(white,8);
        width: 100%;
        line-height: 1.1;
        max-width: 300px;
        margin-top: 10px;
        border-radius: 2px;



        position: relative;

        &.mod-warning {
          color: darken($clr-orange-900, 0);
          background-color: lighten($clr-orange-a100, 10);
          border-color: lighten($clr-orange-a100, 1);
        }
      }














    .settings-checkbox-desc {
      font-size: 13px;
      color: rgba(black,.6);
      display: block;
      margin-top: 2px;
      font-weight: 400;
      line-height: 1;

      img {
        width: auto;
        max-width: 100%;
        margin: 7px 0;
        border-radius: 3px;
        border: 1px solid darken(white,10);
        height: auto;
      }
    }

    .settings-checkbox {
      margin-top: 4px;
      display: inline-block;
      position: relative;
      //max-width: 300px;
      //pointer-events: none;

      input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        clip: rect(0,0,0,0);
      }

      label {
        cursor: pointer;
        position: relative;
        display: inline-block;
        color: rgba(black,.7);
        user-select: none;

        padding-left: 25px;

        font-weight: 500;
        font-size: 15px;
        line-height: 1.2;

        //outline-color: gray;
        outline: none;


        //background
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          //top: 50%;
          //transform: translateY(-50%);
          top: 0px;
          left: 0px;
          width: 16px;
          height: 16px;
          background: rgba(black,.08);
          border: 1px solid rgba(black,.08);
          border-radius: 2px;
          html.mod-transitions-enabled & {
            transition: background-color 80ms ease-out, border-color 80ms ease-out;
          }
        }

        //checkmark
        &::after {
          font-family: 'ggather';
          content: '\e018';
          color: rgba(white,.9);
          font-weight: 500;
          line-height: 1;
          display: inline-block;
          position: absolute;
          font-size: 10px;
          //top: 50%;
          //transform: translateY(-50%);
          top: 3px;
          left: 3px;
          opacity: .0;
          html.mod-transitions-enabled & {
            transition: opacity 80ms ease-out;
          }
        }

      }

      input:checked + label {
        color: rgba(black,.9);
        //font-weight: 500;

        &::before {
          background: rgba(black,.9);
          border-color: rgba(black,.9);
        }

        &::after {
          opacity: 1;
        }

      }

      input:disabled + label {
        cursor: default;
        opacity: .8;
      }



    }




    .settings-badge-new {
      border-radius: 2px;
      padding: 1px 4px;
      background: $clr-ggather;
      text-transform: uppercase;
      font-size: 8px;
      color: white;
      position: relative;
      top: -5px;
    }


</style>





<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .settings-back-btn {
    color: darken(white,20);
  }


  .settings-userbox {
    background-color: $clr-ggather;
    border-color: lighten($clr-ggather,10);
  }



  .settings-nav {
    background-color: darken(white,80);
    border-color: darken(white,75);
  }



  .settings-nav-btn {
    background-color: darken(white,80);
    border-color: darken(white,75);
  }


  .settings-nav-btn .title {
    color: rgba(white,.8);
  }
  .settings-nav-btn.mod-active .title {
    color: rgba(white,.95);
  }

  .settings-nav-btn .subtitle {
    color: rgba(white,.4);
  }


  .settings-tabs {
    background-color: darken(white,80);
    border-color: darken(white,75);
  }

  .settings-tab-title {
    color: rgba(white,.8);
    border-color: darken(white,75);
  }

  .settings-tab-content {
    color: rgba(white,.8);
  }


  .settings-input-label {
    color: rgba(white,.8);
  }

  .settings-input-group {
    border-color: darken(white,75);
  }

  .settings-input-control .common-button,
  .settings-input-control select,
  .settings-input-control textarea,
  .settings-input-control button,
  .settings-input-control img,
  .settings-input-control input {
    background-color: darken(white,72);
    border-color: darken(white,68);
    color: rgba(white,.8);
  }



  .settings-input-control .common-button,
  .settings-input-control input,
  .settings-input-control textarea,
  .settings-input-control select,
  .settings-input-control button {

    &:hover {
      border-color: darken(white,62);
    }

    &:focus {
      border-color: darken(white,62);
      background-color: darken(white,68);
    }
  }




  .settings-input-control input:read-only {
    background-color: transparent;
  }


  .settings-input-control .common-button svg {
    fill: darken(white,20);
  }


}
</style>
