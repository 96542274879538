/*------------------------------------*\
  Imports
\*------------------------------------*/
import authRequired from './middleware.js';
//import { pageError401, pageError403, pageError404, pageError500, pageLanding, pageAbout, pageSupport, pageContact, pagePricing,  pageDiscover, pageDebug } from './lazy.js';

var NODE_ENV = process.env.NODE_ENV;
var BUILD = process.env.BUILD;
var VERSION = process.env.VERSION;
var CORE_URL = process.env.CORE_URL;
var WEBAPP_URL = process.env.WEBAPP_URL;
var HQ_URL = process.env.HQ_URL;
var CHROME_EXTENSION_ID = process.env.CHROME_EXTENSION_ID;
var CHROME = (BUILD==='chrome') || (BUILD==='devchrome');




/*------------------------------------*\
  Routes
\*------------------------------------*/

var routes = [];

routes.push({
  path: '/',
  //component: pageLanding,
  component: require('../view/landing/landing.vue').default,
  meta: {
    kind: 'landing',
    title: 'GGather',
  },
});




routes.push({
  path: '/sign-in',
  component: require('../view/page/blank.vue').default,
  meta: {
    kind: 'dialogbox',
    title: 'Sign In',
    dialogbox: {
      kind: 'signinup',
      subkind: 'signin',
    },
  },
});


routes.push({
  path: '/create-account',
  component: require('../view/page/blank.vue').default,
  meta: {
    kind: 'dialogbox',
    title: 'Create Account',
    dialogbox: {
      kind: 'signinup',
      subkind: 'signup',
    },
  },
});


routes.push({
  path: '/about',
  //component: pageAbout,
  component: require('../view/page/about.vue').default,
  meta: {
    kind: 'page',
    title: 'About',
  },
});


routes.push({
  path: '/contact',
  //component: pageContact,
  component: require('../view/page/contact.vue').default,
  meta: {
    kind: 'page',
    title: 'Contact',
  },
});


routes.push({
  path: '/upgrade',
  component: require('../view/page/upgrade.vue').default,
  meta: {
    kind: 'page',
    title: 'Pricing & Upgrade',
  },
});


routes.push({
  path: '/settings',
  component: require('../view/settings/settings.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    kind: 'settings',
    title: 'Settings',
  },

  children: [
    {
      path: '/settings/*',
      component: require('../view/settings/settings.vue').default,
      //beforeEnter: authRequired,
      meta: {
        auth: true,
        kind: 'settings',
        title: 'Settings',
      },
    },
  ],
});



/*
{
  path: '/import-json',
  component: require('../view/page/import-json.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    kind: 'page',
    title: 'Import JSON',
  },
},
*/



routes.push({
  path: '/discover',
  //component: pageDiscover,
  component: require('../view/page/discover.vue').default,
  meta: {
    //auth: true,
    kind: 'discover',
    title: 'Discover',
    description: 'Find interesting users and tags.',
  },
});


routes.push({
  path: '/following',
  //component: pageDiscover,
  component: require('../view/page/followed.vue').default,
  meta: {
    //auth: true,
    kind: 'followed',
    title: 'Followed',
    description: 'Your followed tags.',
  },
});

routes.push({
  path: '/debug',
  //component: pageDebug,
  component: require('../view/page/debug.vue').default,
  meta: {
    kind: 'page',
    title: 'Debug',
  },
});

routes.push({
  path: '/401',
  //component: pageError401,
  component: require('../view/page/error401.vue').default,
  meta: {
    kind: 'error',
    title: '401',
  },
});

routes.push({
  path: '/403',
  //component: pageError403,
  component: require('../view/page/error403.vue').default,
  meta: {
    kind: 'error',
    title: '403',
  },
});


routes.push({
  path: '/404',
  //component: pageError404,
  component: require('../view/page/error404.vue').default,
  meta: {
    kind: 'error',
    title: '404',
  },
});

routes.push({
  path: '/500',
  //component: pageError500,
  component: require('../view/page/error500.vue').default,
  meta: {
    kind: 'error',
    title: '500',
  },
});


// debug
//{
//    path: '/savedebug',
//    component: require('../view/page/blank.vue').default,
//    //beforeEnter: auth,
//    meta: {
//        auth: true,
//        title: 'URL Save',
//        viewer: {
//            kind: 'urlsave',
//        },
//    },
//},




routes.push({
  path: '/all',
  component: require('../view/page/items-all.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    kind: 'all',
    title: 'All',
  },
});

routes.push({
  path: '/feed',
  component: require('../view/page/items-feed.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    kind: 'feed',
    title: 'Subscriptions Feed',
    description: 'All the links from your subscriptions.',
  },
});




routes.push({
  path: '/search',
  component: require('../view/page/items-search.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    kind: 'search',
    title: 'Search',
  },
});






routes.push({
  path: '/save',
  component: require('../view/page/blank.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    kind: 'save',
    title: 'Save Page',
    viewer: {
      kind: 'savepage',
    },
  },
});






// hackfix for extension "open on save"
// chrome open extension with a /app/index.html appendix
// and this triggers tag page instead save dialog
routes.push({
  path: '/app/*',
  component: require('../view/page/blank.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    title: CHROME ? 'Chrome Save' : 'URL Save',
    kind: CHROME ? 'chromesave' : 'urlsave',
    viewer: {
      kind: CHROME ? 'chromesave' : 'urlsave',
    },
  },
});






routes.push({
  path: '/:username(\[^\.^/]+)',
  component: require('../view/page/profilepage.vue').default,
  meta: {
    kind: 'profilepage',
    title: 'Profile',
  },
});



routes.push({
  path: '/:username(\[^\.^/]+)/:tagslug',
  component: require('../view/page/tagpage.vue').default,
  meta: {
    kind: 'tagpage',
    title: 'Tag',
  },
});





routes.push({
  //{ path: '/params-with-regex/:id(\\d+)' },
  // r'^(?P<provided_url>(\S+\.[^/\s]+(\/\S+|\/|)))/?$'
  //path: '/test/:url( \S+\.[^/\s]+(\/\S+|\/|) )',
  //path: '/test/:url(\\d+)',
  //path: '/:url(\\S+\.[^/\s]+(\/\S+|\/|)+)',
  path: '/*',
  component: require('../view/page/blank.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    title: CHROME ? 'Chrome Save' : 'URL Save',
    kind: CHROME ? 'chromesave' : 'urlsave',
    viewer: {
      kind: CHROME ? 'chromesave' : 'urlsave',
    },
  },
});



/*
{
  path: '/*',
  component: require('../view/page/blank.vue').default,
  //beforeEnter: authRequired,
  meta: {
    auth: true,
    title: 'New Link',
    viewer: {
      kind: 'newlink',
      //tempExpandLevel: 1,
    },
  },
},
*/




export default routes;
