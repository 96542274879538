<script>
import randomMaterialColor from '../../../common/script/random-material-color.js';
export default {

  props: [
    'item',
    'context',
  ],

  data: function(){
    return {
      parsedURL: null,
      isHighlighted: null,
      unseen: false,
      tagsPopoverOpen: false,
    };
  },

  computed: {
    selected: {
      get: function(){
        if (!this.$store.state.itemsSelected) { return false; }
        if (!this.$store.state.itemsSelected.length) { return false; }
        if (this.$store.state.itemsSelected === '*') { return true; }
        return (this.$store.state.itemsSelected.indexOf(this.item.pk) > -1) ? true : false;
      },
      set: function(value) {
        if (value) {
          this.$store.commit('selectItem', this.item.pk);
        } else {
          this.$store.commit('unselectItem', this.item.pk);
        }
      },
    },
    title: function(){
      if (this.item.title) { return this.item.title }
      else if (this.item.urldata.title) { return this.item.urldata.title }
      else { return this.item.url }
    },
    titleThumbSlice: function(){
      var len = 2;
      if (this.item.title) { return this.item.title.slice(0, len) }
      else if (this.item.urldata.title) { return this.item.urldata.title.slice(0, len) }
      else if (this.parsedURL.host.slice(0, 4) == 'www.' ) { return this.parsedURL.host.slice(4, 4+len) }
      else { return this.parsedURL.host.slice(0, len) }
    },
    description: function(){
      if (this.item.description) { return this.item.description }
      else if (this.item.urldata.description) { return this.item.urldata.description }
      else { return '' }
    },
    thumbnail: function(){
      var worn = this.item.thumbnail_worn;
      if (false) {} // noop
      else if (worn==='blank') { return null }
      else if (worn==='self') { return this.item.thumbnail }
      else if (worn==='urldata') { return this.item.urldata.thumbnail }
      // else... choose automatically urldata thumbnail
      return this.item.urldata.thumbnail ? this.item.urldata.thumbnail : null;
    },
    thumbnailWidth: function(){
      if ((!this.item.thumbnail_worn) || (this.item.thumbnail_worn==='urldata')) {
        return this.item.urldata.thumbnail_width;
      }
      return this.item.thumbnail_width;
    },
    thumbnailHeight: function(){
      if ((!this.item.thumbnail_worn) || (this.item.thumbnail_worn==='urldata')) {
        return this.item.urldata.thumbnail_height;
      }
      return this.item.thumbnail_height;
    },
    thumbnailColor: function(){
      var parsedURL = new URL(this.item.url);
      var defaultShade = this.$store.state.appTheme === 'default' ? '400' : '500';
      var colors = [
        ['red', defaultShade],
        ['pink', defaultShade],
        ['purple', defaultShade],
        ['deep-purple', defaultShade],
        ['indigo', defaultShade],
        ['blue', defaultShade],
        ['light-blue', defaultShade],
        ['cyan', defaultShade],
        ['teal', defaultShade],
        ['green', defaultShade],
        ['light-green', defaultShade],
        //['lime', defaultShade],
        //['yellow', defaultShade],
        ['amber', defaultShade],
        ['orange', defaultShade],
        ['deep-orange', defaultShade],
        ['brown', defaultShade],
        //['grey', defaultShade],
        ['blue-grey', defaultShade],
      ];
      var color = randomMaterialColor({seed: parsedURL.host, colors: colors});
      return color;
    },
    noTags: function(){
      return this.item.tags && (!this.item.tags.length);
    },
    itemLayout: function(){
      return this.$store.getters.visibleItemLayout;
    },
    followedTagsFirst: function(){
      if (!this.item.tags) { return null }
      if (!this.$store.state.followedTagsUsers) { return null }
      var tagsCopy = JSON.parse(JSON.stringify(this.item.tags));
      var followedTags = [];
      this.$store.state.followedTagsUsers.forEach(function(user){
        followedTags = followedTags.concat(user.followed_tags);
      });
      followedTags.forEach(function(ftag){
        tagsCopy.forEach(function(tag){
          if (ftag.pk == tag.pk) {
            tag.is_followed = true;
          }
        });
      }.bind(this));
      return tagsCopy.sort(function(a, b){
        return b.is_followed;
      }.bind(this));
    },
  },

  watch: {
    '$store.state.viewer': function(){
      this.setIsHighlighted();
    },
    '$store.state.items': function(){
      this.checkIfUnseen();
    },
    '$store.state.eventGetUnseenItems': function(v){
      this.checkIfUnseen();
    },
  },



  created: function(){
    this.parseURL();
  },

  mounted: function(){
    this.setDnDEvents();
    this.checkIfUnseen();
  },

  beforeUpdate: function(){
  },

  updated: function(){
  },

  beforeDestroy: function(){
    this.removeDnDEvents();
  },

  methods: {

    checkIfUnseen: function(){
      if (!this.$store.state.unseenItems) { return false }
      if (!this.$store.state.unseenItems.pks) { return false }
      this.unseen = this.$store.state.unseenItems.pks.indexOf(this.item.pk) > -1;
    },

    setIsHighlighted: function(){

      if (!this.$store.state.viewer) {
        setTimeout(function() {
          this.isHighlighted = false;
        }.bind(this), 0);
      }

      else if (!this.$store.state.viewer.item) {
        return false;
      }

      else if ( ((this.$store.state.viewer.item.pk != this.item.pk) ) && this.isHighlighted ) {
        setTimeout(function() {
          this.isHighlighted = false;
        }.bind(this), 100);
      }


    },

    parseURL: function(){
      var parsedURL = new URL(this.item.url);
      this.parsedURL = {
        hash: parsedURL.hash,
        host: parsedURL.host,
        hostname: parsedURL.hostname,
        href: parsedURL.href,
        origin: parsedURL.origin,
        password: parsedURL.password,
        pathname: parsedURL.pathname,
        port: parsedURL.port,
        protocol: parsedURL.protocol,
        search: parsedURL.search,
        username: parsedURL.username,
      }
    },



    selectOverlayClick: function(){
      if (this.$store.state.itemsSelected.indexOf(this.item.pk) > -1) {
        this.$store.commit('unselectItem', this.item.pk);
      } else {
        this.$store.commit('selectItem', this.item.pk);
      }
    },

    trackClick: function(){
      this.$nextTick(function(){
        this.$axios({
          url: this.$store.state.api + '/track-click/',
          method: 'get',
          params: {
            pk: this.item.pk,
          },
        }).then(function(response){
        }.bind(this), function(error){
        }.bind(this));
      });
    },



    linkStandardClick: function(event){
      event.stopPropagation();

      if (this.$store.state.itemClickAction==='viewer') {
        event.preventDefault();
        return true;
      }


      this.trackClick();

    },


    linkClick: function(event){
      this.unseen = false;


      var ctrl = event.ctrlKey;
      var leftClick = (event.which==1) || (event.type=='touchend');
      var middleClick = event.which==2;
      var rightClick = event.which==3;



      if (this.$store.state.itemsSelectMode) {
        this.$store.commit('selectItem', this.item.pk);
        return;
      }


      if (this.$store.state.itemClickAction==='native') {
        if (middleClick) this.trackClick();
        return true;
      }



      if (ctrl && leftClick) this.openLink();

      if ( (!ctrl) && leftClick) {
        event.preventDefault();
        if (this.isHighlighted) {
          this.isHighlighted = false;
          this.$store.commit('viewer', false);
        } else {
          this.isHighlighted = true;
          this.$store.commit('viewer', {kind: 'view', item: this.item});
        }
      }

      if ( (!ctrl) && (leftClick || middleClick) ) this.trackClick();


    },

    openLink: function(){
      this.unseen = false;
      window.open(this.item.url, '_blank');

      this.trackClick();
    },

    editLink: function(){
      this.unseen = false;
      this.$store.commit('viewer', {kind: 'edit', item: this.item});
      this.isHighlighted = true;
    },


    resaveLink: function(){
      if (this.$store.state.user.is_anonymous) {
        this.$store.commit('dialogbox', {kind: 'signinup', subkind: 'signin'});
        return false;
      }
      this.unseen = false;
      this.$store.commit('viewer', {kind: 'resave', item: this.item});
      this.isHighlighted = true;
    },

    toggleTagsPopover: function(val){
      val = val ? val : (!this.tagsPopoverOpen);
      this.tagsPopoverOpen = val;
    },

    tagsPopoverAnchorEl: function(){
      var itemNavTagsBtn = this.$refs.itemOuter.querySelector('[item-nav-btn]');
      if (itemNavTagsBtn) return itemNavTagsBtn;
      return this.$refs.itemOuter;
    },


    dndGenerateCanvas: function(){

      this.removeCanvasDragImage();

      var canvas = document.createElement('canvas');
      canvas.style.position = 'fixed';
      canvas.style.zIndex = '-10000';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.pointerEvents = 'none';

      window._itemDnDCanvasDragImage = canvas;
      document.body.append(canvas);

      var context = canvas.getContext('2d');

      canvas.width  = 300;
      canvas.height = 40;

      var rectX = 0;
      var rectY = 0;
      var rectWidth = canvas.width;
      var rectHeight = canvas.height;
      var cornerRadius = 4;

      //context.fillStyle = '#3079f4';
      //context.strokeStyle = '#3079f4';
      context.fillStyle = '#ff9f00';
      context.strokeStyle = '#ff9f00';
      context.lineJoin = "round";
      context.lineWidth = cornerRadius;

      context.strokeRect(rectX+(cornerRadius/2), rectY+(cornerRadius/2), rectWidth-cornerRadius, rectHeight-cornerRadius);
      context.fillRect(rectX+(cornerRadius/2), rectY+(cornerRadius/2), rectWidth-cornerRadius, rectHeight-cornerRadius);

      context.fillStyle = '#ffe6bb';
      context.font = '400 11px sans-serif';
      context.fillText('Drag me to any tag in your library!', 10, 31);
      context.fillStyle = '#eee';
      context.font = '600 15px sans-serif';
      context.fillText(this.item.title ? this.item.title : this.item.urldata.title, 10, 18);


      return canvas;
    },

    dndDragStart: function(event){
      if (this.$store.state.itemsSelectMode) return;
      var canvas = this.dndGenerateCanvas();
      event.dataTransfer.setDragImage(canvas, -10, -7);
      event.dataTransfer.setData('text', JSON.stringify({itempk: this.item.pk}));
      event.dataTransfer.effectAllowed = 'copy';
    },

    dndDragEnd: function(event){
      this.removeCanvasDragImage();
    },


    setDnDEvents: function(){
      if (this.item.owner.pk != this.$store.state.user.pk) {
        return false;
      }
      this.$refs.itemOuter.addEventListener('dragstart', this.dndDragStart, false);
      this.$refs.itemOuter.addEventListener('dragend', this.dndDragEnd, false);
    },

    removeDnDEvents: function(){
      if (!this.$refs.itemOuter) { return false; }
      this.$refs.itemOuter.removeEventListener('dragstart', this.dndDragStart, false);
      this.$refs.itemOuter.removeEventListener('dragend', this.dndDragEnd, false);
    },

    removeCanvasDragImage: function(){
      if (window._itemDnDCanvasDragImage) {
        document.body.removeChild(window._itemDnDCanvasDragImage);
        delete window._itemDnDCanvasDragImage;
      }
    },


    itemThumbLoad: function(){
      this.$store.commit('eventItemThumbLoad');
    },





    addLinkToTag: function(itemPK){
      var itemURL = this.item.url;
      this.$axios({
        url: this.$store.state.api + '/edit-urlbookmark-tag/',
        method: 'post',
        data: {
          tag_pk: this.tag.pk,
          url: itemURL,
          action: 'add',
        },
      }).then(function(response){
        this.$alertify.log('Link was added to ' + this.tag.name + '.')
      }.bind(this));

      // remove from untagged ...
      if ((this.$route.meta.kind=='all') && this.$route.query.untagged) {
        this.$store.commit('deleteItem', {pk: parseInt(itemPK)});
      }

    },




  }

}
</script>



<template>
<div
  :class="[
    'item-outer',
    'mod-'+itemLayout,
    {
      'mod-highlighted': isHighlighted,
    }
  ]"
  ref="itemOuter"
  draggable="true"
  :data-active="isHighlighted"
  :data-masonry="itemLayout=='grid' || itemLayout=='card' || itemLayout=='card-small'"
  :item-pk="item.pk"
>










  <!-- Dragged Tag Over Overlay -->
  <div class="item-dragged-tag-over-overlay">
    <div>Drop the tag to add it to this bookmark...</div>
  </div>




  <!-- Tags -->
  <Popover
    v-if="tagsPopoverOpen"
    @close="tagsPopoverOpen=false"
    :popover-style="{boxShadow: '0 2px 5px 0 rgba(0,0,0,.2)', borderRadius:'4px', background:'#474747', marginTop: '4px'}"
    :anchor-el="tagsPopoverAnchorEl()"
    :transform-origin="{vertical: 'top', horizontal: 'left'}"
    :anchor-origin="{vertical: 'bottom', horizontal: 'left'}"
  >

    <div class="item-tags-popover">
      <div class="item-tags-popover-inner-1">
        <div class="item-tags-popover-inner-2">
          <div v-for="tag in item.tags" :title="tag.is_private ? 'This private tag is visible to you because it\'s yours. <br> No one else can see it.' : ''">

            <router-link :to="'/'+tag.owner.username+'/'+tag.slug" :class="['item-tag', 'mod-color-' + tag.color,]">
              <div class="item-tag-user">
                <div class="item-tag-user-profile-image">
                  <img :src="tag.owner.profile_image" v-if="tag.owner.profile_image">
                  <img :src="tag.owner.blank_profile_image" v-else>
                </div>
                <div class="item-tag-username">{{tag.owner.username}}</div>
              </div>

              <div class="item-tag-name">
                <span v-if="tag.visibility==1" class="icon icon-lock-1" title="This tag is private"></span>
                <span v-if="tag.visibility==2" class="icon icon-shield" title="This tag is unlisted"></span>
                <span v-if="tag.visibility==3" class="icon icon-visible-1" title="This tag is public"></span>{{tag.name}}
                <template v-if="tag.request_user_following">
                  <span class="item-tag-user-follows-indicator">
                  <span class="icon icon-checkmark"></span>
                    Subscribed
                  </span>
                </template>
              </div>
            </router-link>

          </div>
        </div>
        <!--
        <div class="item-tags-popover-arrow"></div> -->
      </div>
    </div>
  </Popover>
















  <itemfeed

    v-if="itemLayout=='feed'"
    key="item-feed"

    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :is-highlighted="isHighlighted"
    :selected="selected"
    :unseen="unseen"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"
    :followed-tags-first="followedTagsFirst"

    :title="title"
    :description="description"
    :title-thumb-slice="titleThumbSlice"
    :thumbnail="thumbnail"
    :thumbnail-height="thumbnailHeight"
    :thumbnail-width="thumbnailWidth"
    :thumbnail-color="thumbnailColor"

    :select-overlay-click="selectOverlayClick"
    :link-click="linkClick"
    :link-standard-click="linkStandardClick"

    :item-thumb-load="itemThumbLoad"

  />





  <itemlist

    v-if="itemLayout=='list' || itemLayout=='list-compact'"
    key="item-list"

    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :is-highlighted="isHighlighted"
    :selected="selected"
    :unseen="unseen"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"
    :followed-tags-first="followedTagsFirst"

    :title="title"
    :description="description"
    :title-thumb-slice="titleThumbSlice"
    :thumbnail="thumbnail"
    :thumbnail-height="thumbnailHeight"
    :thumbnail-width="thumbnailWidth"
    :thumbnail-color="thumbnailColor"

    :select-overlay-click="selectOverlayClick"
    :link-click="linkClick"
    :link-standard-click="linkStandardClick"

    :item-thumb-load="itemThumbLoad"

  />




  <itempanel

    v-else-if="itemLayout=='panel' || itemLayout=='panel-multiple'"
    key="item-panel"

    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :is-highlighted="isHighlighted"
    :selected="selected"
    :unseen="unseen"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"
    :followed-tags-first="followedTagsFirst"

    :title="title"
    :description="description"
    :title-thumb-slice="titleThumbSlice"
    :thumbnail="thumbnail"
    :thumbnail-height="thumbnailHeight"
    :thumbnail-width="thumbnailWidth"
    :thumbnail-color="thumbnailColor"

    :select-overlay-click="selectOverlayClick"
    :link-click="linkClick"
    :link-standard-click="linkStandardClick"

    :item-thumb-load="itemThumbLoad"

  />





  <itemgrid

    v-else-if="itemLayout=='grid' || itemLayout=='grid-fixed' || itemLayout=='card' || itemLayout=='card-small' || itemLayout=='card-fixed-small' || itemLayout=='card-fixed'"
    key="item-grid"

    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :is-highlighted="isHighlighted"
    :selected="selected"
    :unseen="unseen"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"
    :followed-tags-first="followedTagsFirst"

    :title="title"
    :description="description"
    :title-thumb-slice="titleThumbSlice"
    :thumbnail="thumbnail"
    :thumbnail-height="thumbnailHeight"
    :thumbnail-width="thumbnailWidth"
    :thumbnail-color="thumbnailColor"

    :select-overlay-click="selectOverlayClick"
    :link-click="linkClick"
    :link-standard-click="linkStandardClick"

    :item-thumb-load="itemThumbLoad"

  />











</div>
</template>































<style lang="scss">

  @import "../../../common/style/_variables.scss";


  $clr-light-green-600: green;
  $clr-red: red;
  $clr-grey-200: grey;
  $clr-light-blue-400: blue;
  $clr-orange: orange;




  /*------------------------------------*\
    Item Outer
  \*------------------------------------*/

  .item-outer {
    position: relative;
    backface-visibility: hidden;
    //transform: rotate3d(0,0,0,0);

    &.mod-list {
      display: block;
      //margin-top: 5px;
    }

    &.mod-list-compact {
      display: block;
      margin-bottom: 5px;
    }

    &.mod-feed {
      display: block;
      margin-bottom: 15px;
      //max-width: 1000px;
      //margin: 0 auto;
    }

    &.mod-panel {
      display: block;
      margin-bottom: 10px;
      //max-width: 600px;
      //margin: 0 auto;
    }

    &.mod-panel-multiple {
      display: inline-block;
      width: 49%;
      width: calc(50% - 15px);
      margin-right: 10px;
      margin-bottom: 10px;

      // out of scope selector
      .items.mod-viewer-1 & {
        display: block;
        width: auto;
        margin-right: none;
      }
    }


    &.mod-grid {
      display: inline-block;
      width: 24%;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(25% - 13px);

      @media (max-width: 1400px) {
        width: 23%;
        width: calc(25% - 13px);
      }

      @media (max-width: 1100px) {
        width: 30%;
        width: calc(33.3% - 14px);
      }

      @media (max-width: 800px) {
        width: 49%;
        width: calc(50% - 15px);
      }

      // out of scope selector
      .items.mod-viewer-1 & {
        width: 49%;
        width: calc(50% - 15px);
      }

      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }


    }





    &.mod-grid-fixed {
      display: inline-block;
      width: 24%;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(25% - 13px);

      @media (max-width: 1400px) {
        width: 23%;
        width: calc(25% - 13px);
      }

      @media (max-width: 1100px) {
        width: 30%;
        width: calc(33.3% - 14px);
      }

      @media (max-width: 800px) {
        width: 49%;
        width: calc(50% - 15px);
      }

      // out of scope selector
      .items.mod-viewer-1 & {
        width: 49%;
        width: calc(50% - 15px);
      }

      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }


    }



    &.mod-card {
      display: inline-block;
      width: 24%;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(25% - 13px);

      @media (max-width: 1400px) {
        width: 23%;
        width: calc(25% - 13px);
      }

      @media (max-width: 1100px) {
        width: 30%;
        width: calc(33.3% - 14px);
      }

      @media (max-width: 800px) {
        width: 49%;
        width: calc(50% - 15px);
      }

      // out of scope selector
      .items.mod-viewer-1 & {
        width: 49%;
        width: calc(50% - 15px);
      }

      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }


    }




    &.mod-card-fixed {
      display: inline-block;
      width: 24%;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(25% - 13px);

      @media (max-width: 1400px) {
        width: 23%;
        width: calc(25% - 13px);
      }

      @media (max-width: 1100px) {
        width: 30%;
        width: calc(33.3% - 14px);
      }

      @media (max-width: 800px) {
        width: 49%;
        width: calc(50% - 15px);
      }

      // out of scope selector
      .items.mod-viewer-1 & {
        width: 49%;
        width: calc(50% - 15px);
      }

      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }


    }






    &.mod-card-small {
      display: inline-block;
      width: 19%;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(20% - 12px);

      @media (max-width: 1400px) {
        width: 23%;
        width: calc(25% - 13px);
      }

      @media (max-width: 1100px) {
        width: 30%;
        width: calc(33.3% - 14px);
      }

      @media (max-width: 800px) {
        width: 49%;
        width: calc(50% - 15px);
      }

      // out of scope selector
      .items.mod-viewer-1 & {
        width: 49%;
        width: calc(50% - 15px);
      }

      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }

    }



    &.mod-card-fixed-small {
      display: inline-block;
      width: 19%;
      margin-bottom: 10px;
      margin-right: 10px;
      width: calc(20% - 12px);

      @media (max-width: 1400px) {
        width: 23%;
        width: calc(25% - 13px);
      }

      @media (max-width: 1100px) {
        width: 30%;
        width: calc(33.3% - 14px);
      }

      @media (max-width: 800px) {
        width: 49%;
        width: calc(50% - 15px);
      }

      // out of scope selector
      .items.mod-viewer-1 & {
        width: 49%;
        width: calc(50% - 15px);
      }

      @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
      }

    }

  }





  .item-select-overlay {
    display: block;
    outline: none;
    position: absolute;
    top: -0px;
    left: -0px;
    right: -0px;
    bottom: -0px;
    width: 100%;
    height: 100%;

    cursor: pointer;
    background: rgba($clr-ggather,.7);
    border: 1px solid rgba($clr-ggather, 1);
    z-index: 105;
    border-radius: 5px;
    //pointer-events: none;
    opacity: .2;
    //transform: scale(1);
    transform-origin: 50%;

    backface-visibility: hidden;


    /*
    html.mod-transitions-enabled & {
      transition: opacity 50ms ease-out 0ms, transform 100ms ease-out 100ms;
      transition:
        top 100ms ease-out,
        left 100ms ease-out,
        width 100ms ease-out,
        height 100ms ease-out,
        opacity 100ms ease-out,
        transform 100ms ease-out;
    }
    */

    .item.mod-selected & {
      opacity: 1;
      //pointer-events: auto;
      //transform: scale(1);

      //top: -5px;
      //left: -5px;
      //width: calc(100% + 10px);
      //height: calc(100% + 10px);

      /*
      html.mod-transitions-enabled & {
        transition: opacity 100ms ease-out 70ms, transform 100ms ease-out;
        transition:
          top 100ms ease-out,
          left 100ms ease-out,
          width 100ms ease-out,
          height 100ms ease-out,
          opacity 100ms ease-out,
          transform 100ms ease-out;
      }
      */

    }


    .item.mod-panel.mod-selected & {
      //top: -2px;
      //left: -2px;
      //width: calc(100% + 4px);
      //height: calc(100% + 4px);
    }


    .item.item-list.mod-selected & {
      border-radius: 2px;
      //top: -1px;
      //left: -1px;
      //width: calc(100% + 2px);
      //height: calc(100% + 2px);
    }


    .item.mod-panel-multiple.mod-selected & {
      //top: -2px;
      //left: -2px;
      //width: calc(100% + 4px);
      //height: calc(100% + 4px);
    }

  }



  .item-unseen-notification {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 100;
    background-color: #ff8711;
    border-radius: 50px;
    width: 11px;
    height: 11px;
    box-shadow: 0 1px 5px 0 rgba( #ff8711, .4);

    &.mod-list {
      width: 9px;
      height: 9px;
      top: -3px;
      left: -4px;
    }

    &.mod-feed {
      top: 5px;
      left: auto;
      right: 5px;
    }
  }



  .item {
    position: relative;
    z-index: 5;

    .item-outer.mod-highlighted & {
      &::before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border-radius: 6px;
        background-color: rgba(#333,.1);
        background-color: rgba(31, 174, 255, 0.7);
        background-color: $clr-ggather;
        z-index: -1;
      }
      box-shadow: 0 1px 2px 0 rgba(black, .2);
    }

    .item-outer.mod-list.mod-highlighted &::before {
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
    }
  }








  /*------------------------------------*\
    item dragged tag over overlay
  \*------------------------------------*/
  .item-dragged-tag-over-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background: rgba($clr-ggather,.9);
    border-radius: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      color: rgba(white,.9);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
    }


    display: none;
    // this class gets added from sbar-tag...
    .item-outer.mod-dragged-tag-over & {
      display: flex;
    }


  }


  /*------------------------------------*\
    tags popover
  \*------------------------------------*/

  .item-tags-popover {
    display: block;
    font-size: 0;
  }

  .item-tags-popover-inner-1 {
    position: relative;
  }

  .item-tags-popover-inner-2 {
    //box-shadow: 0 1px 5px 0 rgba(black,.4);
    //background-color: darken(white,4);
    color: #333;
    line-height: 1.2;
    font-size: 12px;

    pointer-events: auto;

    max-height: 295px;
    overflow: auto;

    padding: 4px 4px 0 4px;
    //border-radius: 6px;
    position: relative;
    font-size: 0;
  }


  .item-tags-popover-arrow {

    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    pointer-events: none;
    //background-color: red;

    border-width: 5px;
    border-color: darken(white,4);
    border-style: solid;

    z-index: 10000;

    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;

    right: 50%;
    transform: translateX(50%);

  }



  .item-tag {
    display: inline-block;
    padding: 0px 3px 0px 3px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-right: 5px;
    border-width: 2px;
    color: rgba(white,.9);
    font-size: 12px;
    border-radius: 3px;
    outline: none;

    display: block;
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    margin: 0;
    margin-bottom: 5px;

    padding: 1px 5px 0px 5px;
    margin-bottom: 4px;

    html.mod-transitions-enabled & {
      transition: box-shadow 80ms ease-out, border-color 60ms ease-out;
    }

    &.mod-color-1 { border-color:  $clr-tag-1; background-color: $clr-tag-1; }
    &.mod-color-2 { border-color:  $clr-tag-2; background-color: $clr-tag-2; }
    &.mod-color-3 { border-color:  $clr-tag-3; background-color: $clr-tag-3; }
    &.mod-color-4 { border-color:  $clr-tag-4; background-color: $clr-tag-4; }
    &.mod-color-5 { border-color:  $clr-tag-5; background-color: $clr-tag-5; }
    &.mod-color-6 { border-color:  $clr-tag-6; background-color: $clr-tag-6; }
    &.mod-color-7 { border-color:  $clr-tag-7; background-color: $clr-tag-7; }
    &.mod-color-8 { border-color:  $clr-tag-8; background-color: $clr-tag-8; }
    &.mod-color-9 { border-color:  $clr-tag-9; background-color: $clr-tag-9; }


    //&:focus {
    //    &.is-color-1 { border-color: darken($clr-tag-1, 10); }
    //    &.is-color-2 { border-color: darken($clr-tag-2, 10); }
    //    &.is-color-3 { border-color: darken($clr-tag-3, 10); }
    //    &.is-color-4 { border-color: darken($clr-tag-4, 10); }
    //    &.is-color-5 { border-color: darken($clr-tag-5, 10); }
    //    &.is-color-6 { border-color: darken($clr-tag-6, 10); }
    //    &.is-color-7 { border-color: darken($clr-tag-7, 10); }
    //    &.is-color-8 { border-color: darken($clr-tag-8, 25); }
    //}


    &:focus {
      box-shadow: 0 2px 7px 0 rgba(black,.2);
    }

    &:hover {
      box-shadow: 0 2px 7px 0 rgba(black,.2);
    }


  }


  .item-tag-user {
    display: block;
    opacity: .5;
    font-size: 11px;
  }

    .item-tag-user-profile-image {
      display: inline-block;
      vertical-align: middle;
      background-color: #eee;
      width: 13px;
      height: 13px;
      overflow: hidden;
      border-radius: 30px;
      margin-right: 3px;

      width: 10px;
      height: 10px;
      margin-right: 3px;


      img {
        width: 100%;
        height: auto;
      }
    }


    .item-tag-username {
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
      font-size: 11px;
    }


  .item-tag-name {
    display: block;
    font-size: 14px;
    font-size: 12px;

    .icon {
      margin-right: 3px;
      font-size: 10px;
      opacity: .5;
      &::before {
        line-height: 1.4;
      }
    }
  }



  .item-tag-user-follows-indicator {

    display: inline-block;
    color: rgba(white,.8);
    padding: 1px 3px 1px 3px;
    border: 1px solid rgba(white,.2);
    border-radius: 2px;
    vertical-align: top;
    background-color: rgba(white,.15);
    outline: none;
    margin-left: 2px;
    line-height: 1;
    font-size: 9px;
    font-weight: 400;

    //border-color: rgba(white,.1);
    //background-color: rgba(white,.0);

    .icon {
      display: inline;

      &::before {
        line-height: 1;
      }
    }


    // specificity...
    .item-tag.item-tag .item-tag-name.item-tag-name & {
      .icon {
        margin-right: 0;
      }
    }




  }












</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .item-outer.mod-highlighted .item::before {
    background-color: $clr-ggather-dark;
  }

}
</style>
