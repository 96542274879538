<script>
export default {

  props: [
    'context',
    'tab',
  ],

  data: function(){
    return {
    }

  },

  computed: {
    alphabet: function(){
      return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',];
    },
    colors: function(){
      return {
        default: 1,
        brown: 2,
        red: 3,
        orange: 4,
        green: 5,
        teal: 6,
        blue: 7,
        pink: 8,
        purple: 9,
      };
    },

  },

  watch: {


  },


  mounted: function(){

  },


  methods: {

    tabClick: function(requestedTab){
      if (requestedTab==='close') {
        this.$emit('close');
        return;
      }
      else {
        this.$emit('tabchange', requestedTab);
      }

    },

    buttonClick: function(type, value){
      this.$emit('filter', {type: type, value: value});
    },


  },

};
</script>


<template>
  <div class="tagsfilters" :class="['mod-context-'+context]">

    <div class="tagsfilters-tabs" ref="tabs">

      <button @click="tabClick('close')" type="button" :class="['tagsfilters-tab mod-close', {'mod-active': tab==='close'}]">
        <iconsvg icon="material-delete" />
      </button>


      <button @click="tabClick(0)" type="button" :class="['tagsfilters-tab', {'mod-active': tab===0}]">
        Type
      </button>

      <button @click="tabClick(1)" type="button" :class="['tagsfilters-tab', {'mod-active': tab===1}]">
        Parent
      </button>

      <button @click="tabClick(2)" type="button" :class="['tagsfilters-tab', {'mod-active': tab===2}]">
        Color
      </button>

      <button @click="tabClick(3)" type="button" :class="['tagsfilters-tab', {'mod-active': tab===3}]">
        Letter
      </button>


    </div>




    <div class="tagsfilters-sections">


      <section v-if="tab===0" class="tagsfilters-section  mod-type">
        <h4 class="tagsfilters-title">Filter By Type</h4>

        <button key="tfbselected" v-if="context==='savetags'" class="tagsfilters-btn mod-type  mod-row-1" type="button" @click.prevent="buttonClick('selected')">Selected</button>

        <button key="tfbrecent" class="tagsfilters-btn mod-type   mod-row-1" type="button" @click.prevent="buttonClick('recent')">Recently Used</button>

        <div v-if="context==='savetags'"  class="tagsfilters-btn-sep"></div>

        <!--
        <button class="tagsfilters-btn mod-type mod-row-2" type="button" @click.prevent="buttonClick('all')">All</button>  -->

        <button class="tagsfilters-btn mod-type mod-row-2" type="button" @click.prevent="buttonClick('favorite')">Favorite</button>

        <div class="tagsfilters-btn-sep"></div>

        <button class="tagsfilters-btn mod-type  mod-row-3" type="button" @click.prevent="buttonClick('private')">Private</button>
        <button class="tagsfilters-btn mod-type  mod-row-3" type="button" @click.prevent="buttonClick('unlisted')">Unlisted</button>
        <button class="tagsfilters-btn mod-type  mod-row-3" type="button" @click.prevent="buttonClick('public')">Public</button>
      </section>


      <section v-if="tab===1" class="tagsfilters-section   mod-parent">
        <h4 class="tagsfilters-title">Filter By Parent Tag</h4>
        <div v-if="(!$store.getters.parentTagsWithChildren) || (!$store.getters.parentTagsWithChildren.length)" class="tagsfilters-empty">No parent tags...</div>
        <button v-for="tag in $store.getters.parentTagsWithChildren" class="tagsfilters-btn mod-parent" :class="['mod-color-'+tag.color]" type="button"  @click.prevent="buttonClick('parent', tag.slug)">{{tag.name}}</button>

      </section>

      <section v-if="tab===2" class="tagsfilters-section   mod-color">
        <h4 class="tagsfilters-title">Filter By Color</h4>
        <button v-for="(val, key, i) in colors" class="tagsfilters-btn mod-color" :class="['mod-color-'+val]" type="button"  @click.prevent="buttonClick('color', key)">{{key}}</button>
      </section>


      <section v-if="tab===3" class="tagsfilters-section   mod-letter">
        <h4 class="tagsfilters-title">Filter By First Tag Letter</h4>
        <button class="tagsfilters-btn mod-letter" type="button" @click.prevent="buttonClick('firstletter', '#')">#</button>
        <button v-for="letter in alphabet" class="tagsfilters-btn mod-letter" type="button"  @click.prevent="buttonClick('firstletter', letter)">{{letter.toUpperCase()}}</button>
      </section>


    </div>






  </div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";


  .tagsfilters {
    background: darken(white,72);
    border: 1px solid darken(white,65);
    border-top: none;
    border-radius: 4px;
    overflow: hidden;
    font-size: 0px;

    &.mod-context-savetags {
      max-width: 295px;
      margin-left: auto;
    }
  }


  .tagsfilters-tabs {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;
    //padding-left: 10px;
    //padding-right: 10px;
    border-bottom: 1px solid rgba(white,.1);
  }

  .tagsfilters-tab {
    width: 100%;
    display: block;
    font-size: 11px;
    font-weight: 500;
    color: rgba(white,.8);
    text-align: center;
    padding: 7px 5px;
    white-space: nowrap;
    outline: none;


    &.mod-active {
      background: #3079f4;
      font-weight: 500;
      color: rgba(white,1);
    }

    &.mod-close {
      text-align: center;
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }

    & /deep/ svg {
      width: 16px;
      height: 16px;
    }
    & /deep/ svg path {
      fill: rgba(white,.5);
    }

    &:hover {
      background: rgba(white,.1);
    }
  }


  .tagsfilters-sections {
    padding-top: 0px;
  }


  $tagsfilters-scrollbar-track-color: darken(white,72);
  $tagsfilters-scrollbar-bar-color: darken(white,65);

  .tagsfilters-section {
    padding-top: 5px;
    height: 150px;
    overflow-x: hidden;
    overflow-y: auto;

    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;



    &.mod-letter {
      padding-right: 0;
    }

    .tagsfilters.mod-context-savetags & {
      height: 120px;
      &.mod-letter {
        padding-right: 5px;
        padding-left: 0;
        text-align: right;
      }
      &.mod-color {
        text-align: right;
      }
    }



    &::-webkit-scrollbar {
      width: 15px;
      height: 12px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 0px;
      background-color: $tagsfilters-scrollbar-track-color;
      border-left: 1px solid lighten($tagsfilters-scrollbar-track-color,0);
    }

    &::-webkit-scrollbar-button {
      display: none;
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: lighten($tagsfilters-scrollbar-bar-color,0);
      background-clip: padding-box;
      border: 3px solid rgba(white,0);
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-radius: 10px;
      min-height: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: lighten($tagsfilters-scrollbar-bar-color, 0);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: lighten($tagsfilters-scrollbar-bar-color,25);
    }





  }

  .tagsfilters-title {
    font-size: 12px;
    color: rgba(white,.6);
    margin-top: 3px;
    font-size: 13px;
    font-weight: 500;

    display: none;

  }


  .tagsfilters-empty {
    font-size: 13px;
    line-height: 1.2;
    font-weight: 400;
    color: rgba(white,.8);
    padding-top: 5px;
  }

  .tagsfilters-btn {
    border-radius: 6px;
    background: rgba(white,.15);
    color: rgba(white,.9);
    padding: 6px 10px;
    margin-top: 5px;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 400;

    outline-color: white;


    min-width: 22px;


    &.mod-type {
      width: 100%;
      text-align: left;
    }
    &.mod-parent {
      width: 100%;
      text-align: left;
    }

    .tagsfilters.mod-context-savetags & {

      &.mod-row-1 {
        width: 45%;
        width: calc(49.99% - 6px);
      }

      &.mod-row-2 {
        //width: 45%;
        //width: calc(49.99% - 6px);
        width: 98.99%;
        width: calc(99% - 6px);
      }


      &.mod-row-3 {
        width: 30%;
        width: calc(33.33% - 6px);
      }

    }

    &.mod-letter {
      padding: 5px 3px;
      margin-right: 4px;
      min-width: 27px;

      border-radius: 2px;

      &:first-of-type {
        border-radius: 6px 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 6px 0;
      }
    }


    &.mod-color {
      color: rgba(black, 0);
      font-size: 0;
      border-radius: 100px;
      display: inline-block;
      padding: 0;
      margin-right: 4px;
      width: 19px;
      height: 19px;
      min-width: 0;
    }


    &.mod-color.mod-color-1 {
      //background-color: $clr-tag-1;
      background-color: lighten($clr-tag-1,20);
    }

    &.mod-color.mod-color-2 {
      background-color: $clr-tag-2;
    }

    &.mod-color.mod-color-3 {
      background-color: $clr-tag-3;
    }

    &.mod-color.mod-color-4 {
      background-color: $clr-tag-4;
    }

    &.mod-color.mod-color-5 {
      background-color: $clr-tag-5;
    }

    &.mod-color.mod-color-6 {
      background-color: $clr-tag-6;
    }

    &.mod-color.mod-color-7 {
      background-color: $clr-tag-7;
    }

    &.mod-color.mod-color-8 {
      background-color: $clr-tag-8;
    }

    &.mod-color.mod-color-9 {
      background-color: $clr-tag-9;
    }


  }



  .tagsfilters-btn-sep {
    width: 50%;
    height: 1px;
    background: rgba(black,.07);
    background: rgba(white,.07);
    display: block;
    margin: 6px auto 1px auto;

    display: none;
    .tagsfilters.mod-context-savetags & {
    }

  }



</style>








<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


}
</style>
