<script>
export default {

  data: function(){
    return {
      tagColor: 0,
      explicitHeight: 0,
    }
  },



  computed: {
    tagMetaSafe: function(){
      return this.$store.state.tagPageMeta ? this.$store.state.tagPageMeta : {};
    },
  },


  watch: {
    '$store.state.tagPageMeta': function(newVal, oldVal){
      if (newVal && newVal.color) {
        this.tagColor = newVal.color;
      }
      if (newVal && oldVal && (newVal.pk !== oldVal.pk)) {
        this.explicitHeight = 0;
      }
    },
  },

  mounted: function(){
    this.setExplicitHeight();
  },


  updated: function(){
    this.setExplicitHeight();
  },


  methods: {

    setExplicitHeight: function(){
      if (!this.$refs.elInner) { return false;}
      // method for anim
      this.$nextTick(function(){
        this.explicitHeight = this.$refs.elInner.scrollHeight + 'px';
      });
    },


    fofuClick: function(){
      this.$store.dispatch('followOrUnfollowTag', this.$store.state.tagPageMeta.pk);
    },

  },

};
</script>


<template>
<header class="ctabar" v-if="$store.getters.CTABarActive" :style="{height: $store.state.transitionsEnabled ? explicitHeight : ''}" :class="['mod-color-'+tagColor, {'mod-scrolled': $store.state.contentScrolled}]">
  <div class="ctabar-inner" ref="elInner" >


    <div class="ctabar-title-user-wrapper">
      <router-link class="ctabar-title-user mod-less-visible" :to="'/' + $route.params.username "  v-tooltip="'Tag created by ' + $route.params.username">

        <!--  by-->
        <div class="ctabar-title-user-avatar">
          <img v-if="tagMetaSafe && tagMetaSafe.owner"
           :src="tagMetaSafe.owner.profile_image">
           <!-- <img v-else src=""> -->
        </div>

        <div class="ctabar-title-user-name">

          <span v-if="tagMetaSafe.owner && (tagMetaSafe.owner.first_name || tagMetaSafe.owner.last_name)">
            {{tagMetaSafe.owner.first_name}} {{tagMetaSafe.owner.last_name}}
          </span>

          <span v-else-if="tagMetaSafe.owner && tagMetaSafe.owner.username">
            {{tagMetaSafe.owner.username}}
          </span>

          <!--
          <span v-else>
            {{$route.params.username}}
          </span> -->

        </div>

      </router-link>
    </div>



    <h2 class="ctabar-title">{{tagMetaSafe.name}}</h2>
    <p class="ctabar-subtitle">{{tagMetaSafe.description}}</p>


    <div class="ctabar-divider"></div>




    <div class="ctabar-details" >

      <div class="ctabar-detail"  v-tooltip="tagMetaSafe.items_count + ' link' + (tagMetaSafe.items_count==1?'':'s')">
        <iconsvg v-if="tagMetaSafe.items_count || tagMetaSafe.items_count===0" icon="sheets-filled"></iconsvg>
        <span>{{tagMetaSafe.items_count}}</span>
      </div>

      <button v-if="$store.state.user.is_anonymous" type="button" class="ctabar-cta" @click="$store.commit('dialogbox', {kind: 'signinup', subkind: 'signup'})">
        <iconsvg icon="notification-filled"></iconsvg>
        <!--Stay notified about new links in this tag by subscribing. -->
        Sign up to subscribe to this public tag <!--  and be notified about new links added to this tag. -->
      </button>


      <button  v-else-if="$store.state.tagPageMeta && (!$store.state.tagPageMeta.is_followed)" type="button" class="ctabar-cta" @click="fofuClick">
        <iconsvg icon="notification-filled"></iconsvg>
        Subscribe
      </button>

      <button v-else-if="$store.state.tagPageMeta" type="button" class="ctabar-cta" @click="fofuClick" >
        <iconsvg icon="notification"></iconsvg>
        Unsubscribe
      </button>


    </div>



  </div>


</header>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .ctabar {
    display: block;


    border-radius: 0px;

    overflow: hidden;
    margin: 0 0 -30px 0;

    //box-shadow: 0 1px 15px 0 rgba(darken(#1CB5E0, 35), .2), 0 1px 5px 0 rgba(darken(#1CB5E0, 25), .2);

    box-shadow: 0 2px 5px 0 rgba(black, .09), 0 2px 20px 5px rgba(black, .09);


    //background: #3079f4;
    //background-image: linear-gradient(to bottom right, darken(#3079f4,10), lighten(#3079f4, 5));

    position: relative;
    &::before {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: linear-gradient(to bottom, rgba(black,.05), rgba(white,.05));
      opacity: 1;
      transition: opacity 100ms ease-in-out;
      backface-visibility: hidden;
    }
    &.mod-color-0::before {
      opacity: 0;
    }

    &.mod-color-undefined { background-color: white; }
    &.mod-color-0 { background-color: white; }

    &.mod-color-1 { background-color: rgba($clr-tag-1, 1); }
    &.mod-color-2 { background-color: rgba($clr-tag-2, 1); }
    &.mod-color-3 { background-color: rgba($clr-tag-3, 1); }
    &.mod-color-4 { background-color: rgba($clr-tag-4, 1); }
    &.mod-color-5 { background-color: rgba($clr-tag-5, 1); }
    &.mod-color-6 { background-color: rgba($clr-tag-6, 1); }
    &.mod-color-7 { background-color: rgba($clr-tag-7, 1); }
    &.mod-color-8 { background-color: rgba($clr-tag-8, 1); }
    &.mod-color-9 { background-color: rgba($clr-tag-9, 1); }


    //&.mod-color-1{background-image:linear-gradient(to bottom,darken($clr-tag-1,3),lighten($clr-tag-1,0));}
    //&.mod-color-2{background-image:linear-gradient(to bottom,darken($clr-tag-2,3),lighten($clr-tag-2,0));}
    //&.mod-color-3{background-image:linear-gradient(to bottom,darken($clr-tag-3,3),lighten($clr-tag-3,0));}
    //&.mod-color-4{background-image:linear-gradient(to bottom,darken($clr-tag-4,3),lighten($clr-tag-4,0));}
    //&.mod-color-5{background-image:linear-gradient(to bottom,darken($clr-tag-5,3),lighten($clr-tag-5,0));}
    //&.mod-color-6{background-image:linear-gradient(to bottom,darken($clr-tag-6,3),lighten($clr-tag-6,0));}
    //&.mod-color-7{background-image:linear-gradient(to bottom,darken($clr-tag-7,3),lighten($clr-tag-7,0));}
    //&.mod-color-8{background-image:linear-gradient(to bottom,darken($clr-tag-8,3),lighten($clr-tag-8,0));}
    //&.mod-color-9{background-image:linear-gradient(to bottom,darken($clr-tag-9,3),lighten($clr-tag-9,0));}



    backface-visibility: hidden;
    //opacity: 1;
    //&.mod-scrolled {
    //  opacity: .1;
    //}

    //transition: height 200ms ease-in-out;
    html.mod-transitions-enabled & {
      transition: opacity 300ms ease-in-out, background 300ms ease-in-out, height 200ms ease-in-out;
    }




  }



    .ctabar-inner {
      padding: 20px 20px 27px 20px;

      //padding-top: 64px;

      position: relative;
      z-index: 5;

      max-width: 1200px;
      margin: 0 auto;
    }









      .ctabar-title-user-wrapper {
          display: block;
      }



      .ctabar-title-user {
        display: inline-block;
        margin-right: 5px;
        vertical-align: baseline;

        margin-top: 20px;
        margin-bottom: 10px;

        font-size: 14px;
        color: rgba(white,.5);

        .ctabar.mod-colored & {
          color: rgba(white,.5);
        }

      }


      .ctabar-title-user-avatar {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 334px;
        overflow: hidden;
        position: relative;
        top: 2px;
        & > img {
          width: 100%;
          height: auto;
          background-color: rgba(white,.4);
        }
        vertical-align: baseline;

        html.mod-transitions-enabled & {
          transition: background-color 100ms ease-out, opacity 100ms ease-out;
        }
        .ctabar.mod-colored & {
          background-color: rgba(white,.2);
        }


        opacity: .7;
        .ctabar-title-user:hover & {
          opacity: 1;
        }

      }

      .ctabar-title-user-name {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;

        margin-top: 1px;
        margin-left: 3px;

        vertical-align: baseline;

        color: rgba(white,.5);

        .ctabar-title-user:hover & {
          color: rgba(white,.8);
        }

        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }
      }










      .ctabar-title {
        display: block;
        font-size: 26px;
        font-weight: 600;
        color: rgba(white,.9);
        line-height: 1.2;
        min-height: 31px;
      }


      .ctabar-subtitle {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: rgba(white,.7);
        line-height: 1.2;
        margin-top: 13px;
        margin-bottom: 20px;
        //min-height: 16px;
      }




      .ctabar-divider {
        background: rgba(white,.15);
        height: 1px;
        width: 100%;
        display: block;
        overflow: hidden;
        margin: 10px 0;

      }




      .ctabar-details {
        display: block;
        min-height: 31px;
        margin-top: 5px;
        margin-bottom: 12px;
      }



        .ctabar-detail {

          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: rgba(white,.5);
          line-height: 1.2;
          padding: 10px 0 5px 0;

          text-align: left;
          margin-right: 20px;

          & /deep/ svg {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 5px;

            path {
              fill: rgba(white,.5);
            }
          }

        }



        .ctabar-cta {
          display: block;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: rgba(white,.5);
          line-height: 1.2;
          padding: 10px 0 5px 0;
          text-align: left;

          outline: none;

          html.mod-transitions-enabled & {
            transition: color 100ms ease-out;
          }
          html.mod-transitions-enabled & /deep/ svg {
            transition: fill 100ms ease-out;
          }

          & /deep/ svg {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 3px;

            path {
              fill: rgba(white,.5);
            }
          }


          &:hover {
            color: rgba(white,.8);
            & /deep/ svg path {
              fill: rgba(white,.8);
            }

          }

        }




</style>
