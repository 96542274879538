// autotrack
//import 'autotrack';

// https://github.com/egoist/vue-ga/blob/master/src/index.js
// https://github.com/googleanalytics/autotrack

var installAnalytics = function(options){

  // append analytics script
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.google-analytics.com/analytics'+ (options.debug?'_debug':'') +'.js';
  document.body.appendChild(script);

  // append autotrack script
  const script2 = document.createElement('script');
  script2.async = true;
  script2.src = 'https://cdnjs.cloudflare.com/ajax/libs/autotrack/2.4.1/autotrack.js';
  document.body.appendChild(script2);

  // setup ga
  window.ga = window.ga || function () {
    (ga.q = ga.q || []).push(arguments)
  }
  ga.l = Number(new Date())

  // init
  ga('create', options.id, 'auto');

  // autotrack plugins
  ga('require', 'cleanUrlTracker');
  ga('require', 'eventTracker');
  ga('require', 'impressionTracker');
  ga('require', 'maxScrollTracker');
  ga('require', 'mediaQueryTracker');
  ga('require', 'outboundFormTracker');
  ga('require', 'outboundLinkTracker');
  ga('require', 'pageVisibilityTracker');
  ga('require', 'socialWidgetTracker');
  ga('require', 'urlChangeTracker');

  //if (options.debug) {
  //    ga('set', 'sendHitTask', null);
  //}

  //ga('set', 'page', options.url);
  ga('send', 'pageview');

};

export default installAnalytics;

