<script>
export default {

  props: [
    'initialSubkind',
    'initialTag',
    'initialUser',
    'initialProfile',
  ],

  data: function(){
    return {
      subkind: JSON.parse(JSON.stringify(this.$props.initialSubkind)),
      subs: null,
      subsCount: null,

    }
  },

  created: function(){
    this.getProfileSubs();
  },


  methods: {


    getProfileSubs: function(){

      if (this.subs && this.subs.length) {
        return false;
      }

      var CancelToken = this.$axios.CancelToken;
      var source = CancelToken.source();
      this._getProfileSubsRequest = source;

      this.$axios({
        cancelToken: source.token,
        url: this.$store.state.api + '/get-profile-subs/',
        method: 'get',
        params: {
          kind: this.initialSubkind,
          user_pk: this.initialProfile.pk,
        },
      }).then(function(response){
        this.subs = response.data.subscribers;
        this.subsCount = response.data.count;
      }.bind(this));

    },


  },


};
</script>


<template>
<div class="followsbox">
<div class="followsbox-inner"  ref="followsboxInner">



  <div class="followsbox-header">


    <button v-if="initialSubkind=='out'" type="button" class="followsbox-title" :class="{'mod-active': subkind=='subs'}"> <span class="icon icon-gender-neutral-user-filled"></span>
     <!--  <template v-if="subsCount">{{subsCount}}  <span class="icon icon-forward"></span> </template> -->{{initialProfile.first_name ? initialProfile.first_name : initialProfile.username}} subscribes to tag(s):</button>

    <button v-if="initialSubkind=='in'" type="button" class="followsbox-title" :class="{'mod-active': subkind=='subs'}"> <span class="icon icon-gender-neutral-user-filled"></span>
     <!--  <template v-if="subsCount">{{subsCount}} <span class="icon icon-forward"></span> </span></template> -->{{initialProfile.first_name ? initialProfile.first_name : initialProfile.username}} tag(s) are subscribed by:</button>



  </div>



  <div>


    <!--
    <div class="followsbox-label-wrapper  mod-subs">
      <div class="followsbox-label">Subscribers</div>
      <div class="followsbox-sublabel"></div>
    </div> -->



    <spinner kind="dots" dark="true" v-if="!subs"></spinner>

    <div class="followsbox-subs-wrapper">

      <div v-if="subs && subs.length" class="followsbox-sub" v-for="sub in subs" :key="'followsboxsub'+sub.pk">

        <router-link :to="'/'+sub.username">
          <div class="followsbox-sub-profile-img">
            <img v-if="sub.profile_image" :src="sub.profile_image">
          </div>
          <div class="followsbox-sub-username">{{sub.username}}</div>
        </router-link>


        <div  v-if="initialSubkind=='out'" class="followsbox-things-label">subscribes to...</div>
        <div  v-if="initialSubkind=='in'" class="followsbox-things-label">subscribes to...</div>

        <div class="followsbox-things-subscribed" v-if="sub.tagfollows && sub.tagfollows.length">
          <router-link v-for="tag in sub.tagfollows" :key="'followsboxtag'+tag.pk" class="followsbox-thing-subscribed" :to="'/'+tag.owner_username+'/'+tag.slug">

          <div class="bg" :class="'mod-color-'+tag.color"></div>
          <strong>{{tag.owner_username}}</strong> | {{tag.name}}</router-link>
        </div>
      </div>

    </div>



    <div v-if="subs && (!subs.length)">
      No subscribers...
    </div>



  </div>




</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .followsbox {
    background-color: lighten(#eee,4);
    border-radius: 6px;
    width: 100vw;
    //border: 1px solid darken(white,10);
    //box-shadow: 0 2px 5px -1px rgba(black,.05);
    box-shadow: 0 2px 5px 0 rgba(black, .03), 0 2px 30px 5px rgba(black, .02);
    html.mod-transitions-enabled & {
      transition: height 200ms ease-in-out;
    }
    max-width: 400px;

    overflow: hidden;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;
  }



  .followsbox-inner {
    padding: 0 20px 15px 20px;
  }


  .followsbox-header {
    margin-bottom: 10px;
    text-align: left;
    //padding: 0 5px;
  }

  .followsbox-title {
    display: inline-block;
    outline: none;
    font-size: 18px;
    color: rgba($clr-ggather,1);
    font-weight: 500;
    padding-top: 20px;
    //padding-bottom: 2px;

    border-right: 1px solid #eee;
    padding-right: 20px;
    margin-right: 20px;

    &:last-of-type {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    &.mod-active {
      color: rgba(black,.9);
      font-weight: 600;
    }
  }


  .followsbox-spacer {
    display: block;
    width: auto;
    height: 1px;
    background-color: #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }








  .followsbox-subs-wrapper {
  }

  .followsbox-sub {

    display: block;
    margin: 5px 0;
    margin-bottom: 10px;
    padding: 10px 10px;
    padding-bottom: 5px;
    border-radius: 2px;
    border: 1px solid #eee;
    background-color: #f6f6f6;
  }

    .followsbox-sub-profile-img {
      display: inline-block;
      width: 25px;
      height: 25px;
      overflow: hidden;
      vertical-align: middle;
      background-color: #eee;
      border-radius: 50px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
      }
    }

    .followsbox-sub-username {
      font-size: 13px;
      display: inline-block;
      font-weight: 600;
      vertical-align: middle;
      margin-left: 3px;
      margin-top: 2px;
    }




  .followsbox-tip {
    border: 1px solid rgba(black,.05);
    border-radius: 2px;
    max-width: 500px;
    display: block;
    margin: 0 auto 0 auto;
    margin-bottom: 30px;
    font-size: 12px;
    padding: 5px 10px;
    background-color: rgba(black,.05);
  }





  .followsbox-things-label {
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    margin-top: 6px;
    margin-left: 4px;
    opacity: .5;
  }



  .followsbox-things-subscribed {
    margin-top: 5px;

  }


    .followsbox-thing-subscribed {
      background-color: white;
      padding: 2px 4px;
      display: inline-block;
      margin-right: 2px;
      margin-bottom: 2px;
      border: 1px solid #eee;
      border-radius: 2px;
      font-size: 11px;

      opacity: .8;

      &:hover {
        opacity: 1;
      }



      position: relative;
      padding-left: 23px;
      & > .bg {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50px;
        position: absolute;
        top: 3px;
        left: 6px;
        opacity: .8;
      }

      & > .bg.mod-color-1 {
        background-color: lighten($clr-tag-1, 1);
      }
      & > .bg.mod-color-2 {
        background-color: lighten($clr-tag-2, 1);
      }
      & > .bg.mod-color-3 {
        background-color: lighten($clr-tag-3, 1);
      }
      & > .bg.mod-color-4 {
        background-color: lighten($clr-tag-4, 1);
      }
      & > .bg.mod-color-5 {
        background-color: lighten($clr-tag-5, 1);
      }
      & > .bg.mod-color-6 {
        background-color: lighten($clr-tag-6, 1);
      }
      & > .bg.mod-color-7 {
        background-color: lighten($clr-tag-7, 1);
      }
      & > .bg.mod-color-8 {
        background-color: lighten($clr-tag-8, 1);
      }
      & > .bg.mod-color-9 {
        background-color: lighten($clr-tag-9, 1);
      }


    }

</style>
