<script>
export default {

  name: 'page-export',

  data: function(){
    return {
      bookmarksJSON: null,
      showJSONExample: false,
    };
  },

};
</script>


<template>
<div class="export">

  <h2 class="export-title">Please choose your export format</h2>

  <p>To download the export file directly just right click on button and choose "Save link as". If you have a lot of bookmarks the export file may take some time to generate.</p>


  <br>

  <p><strong>Important:</strong> GGather export feature requires from you the be signed into GGather API before using it. If you encounter authentication error this means that you aren't signed in. <br> To sign into GGather API use this link: <a :href="$store.state.apiHost+'/account/login/'">{{$store.state.apiHost}}/account/login/</a>.</p>

  <br>
  <h3>HTML export (standard 'Netscape' bookmarks format):</h3>

  <div class="settings-input-control">
    <a target="_blank" class="common-button" :href="$store.state.api + '/export/ggather.html'">Export HTML</a>
  </div>


  <br>


  <div>

    <br>

    <h3>JSON export:</h3>

    <div class="settings-input-control">
      <a target="_blank" class="common-button" :href="$store.state.api + '/export/ggather.json'">Export JSON</a>
      <button type="button" class="common-button"  @click="showJSONExample=!showJSONExample">
        <span v-if="showJSONExample">Hide</span><span v-else>Show</span> JSON Example
      </button>
    </div>


  </div>


  <div v-if="showJSONExample">
    <code class="export-json-example">
<pre>[
  {
    "title": "Example Domain",
    "urldata_title": null,
    "description": "This domain is established to be used for illustrative examples in documents. You may use this domain in examples without prior coordination or asking for permission.",
    "urldata_description": null,
    "owner_notes": null,
    "url": "http://example.com/",
    "date_created": "2019-01-26T04:41:01.417820Z",
    "date_updated": "2019-01-31T04:08:59.058845Z",
    "tags": [
      {
        "name": "Examples",
        "slug": "examples",
        "color": 5,
        "date_created": "2019-01-21T16:53:22.427715Z",
        "date_updated": "2019-01-31T06:52:57.051512Z",
        "parent": null,
        "description": null,
        "visibility": 1,
        "display_order": 4
      },
      {
        "name": "Placeholders",
        "slug": "placeholders",
        "color": 3,
        "date_created": "2019-01-21T16:53:22.427715Z",
        "date_updated": "2019-01-31T06:52:57.051512Z",
        "parent": null,
        "description": "Websites and tools that act as a placeholders.",
        "visibility": 1,
        "display_order": 9

      }
    ]
  }
]
</pre>
    </code>

  </div>



  <!--

  <div v-if="bookmarksJSON">
    <textarea rows="5" cols="100" class="export-dump">{{bookmarksJSON}}</textarea>
  </div>

  <spinner v-else kind="dots" dark="true"></spinner>
  -->


</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .export {
    padding-right: 20px;
    font-size: 15px;

    p a {
      color: $clr-ggather;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      border-top: 1px solid darken(white,8);
      padding-top: 15px;
    }

  }

  .export-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  /*
  .export-dump {
    background-color: rgba(black,.1);
    padding: 5px;
    width: 100%;
    display: block;
    height: 500px;
    max-height: 90vh;
    border-radius: 2px;
    background-color: #00a4fc;
    border: 1px solid #039be5;
    color: white;

  }*/

  .export-json-example {
    display: block;
    border-radius: 2px;
    margin-top: 5px;
    padding: 10px;
    background-color: darken(#eee,5);
    font-size: 12px;
    font-family: monotype, monotype;
    overflow: auto;


  }

</style>




<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .export {
    p a {
    }

    h3 {
      border-color: darken(white,70);
    }

  }
  .export-json-example {
    background-color: darken(white,70);

  }

}

</style>
