<script>
export default {

  props: {
    tag: {
      type: Object,
      required: true,
    },
  },

  data: function(){
    return {
      deleteConfirmed: false,
    }
  },

  computed: {

    randomTip: function(){
      var max = 7;
      var randomInt = Math.floor(Math.random()*max);
      return randomInt;
    },

  },

  watch: {


  },


  mounted: function(){

  },


  methods: {

    actionEdit: function(){
      this.$store.commit('dialogbox', {kind: 'tagbox', subkind: 'edit', tag: this.tag});
      this.$emit('close');
    },

    actionFavorite: function(){
      this.$store.commit('favoriteTag', {pk: this.tag.pk, favorite: !this.tag.favorite});
      //this.scheduleSyncTags();
      this.$store.dispatch('apiSyncTags');
    },


    actionDelete: function(){

      if (!this.deleteConfirmed) {
        this.deleteConfirmed = true;
        return;
      }

      this.$store.dispatch('apiDeleteTag', {
        pk: this.tag.pk,
        onSuccess: function(response){
        }.bind(this),
        onError: function(error){
        }.bind(this),
      });
    },

    //clearScheduleSyncTags(){
    //  this.$_syncTagsTimeout && clearTimeout(this.$_syncTagsTimeout);
    //},

    //scheduleSyncTags(){
    //  this.clearScheduleSyncTags();
    //  this.$_syncTagsTimeout = setTimeout(function () {
    //    this.$store.dispatch('apiSyncTags');
    //  }.bind(this), 1000);
    //},


  },

};
</script>


<template>
  <div class="sbartagmenu" >

    <header class="sbartagmenu-header">
      <h2 class="sbartagmenu-title">{{tag.name}}</h2>
    </header>

    <div class="sbartagmenu-content">

      <button
        type="button"
        class="sbartagmenu-btn"
        @click.prevent="actionFavorite"
      >
        <span v-if="tag.favorite"><iconsvg icon="material-checkmark" /></span>
        Favorite
      </button>

      <button
        type="button"
        class="sbartagmenu-btn"
        @click.prevent="actionEdit"
      >
        Edit
      </button>

      <button
        type="button"
        class="sbartagmenu-btn"
        @click.prevent="actionDelete"
        @mouseleave="deleteConfirmed=false"
      >
        <span v-if="deleteConfirmed">Confirm</span>
        <span v-else>Delete</span>
      </button>



      <div v-if="randomTip===0" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          You can sort &amp; nest your tags using drag and drop interactions. Long click or tap any tag to grab it.
        </div>
      </div>

      <div v-if="randomTip===1" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          Deleting tag won't delete bookmarks assigned to it.
        </div>
      </div>

      <!--
      <div v-if="randomTip===2" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          Favorite tags will be in order in which you've favorited them.
        </div>
      </div>  -->

      <div v-if="randomTip===3" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          You can not have two tags with the same name in your library (even in different parent tags).
        </div>
      </div>

      <div v-if="randomTip===4" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          One bookmark can be assigned to multiple tags.
        </div>
      </div>


      <div v-if="randomTip===5" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          You can drag and drop bookmark into a tag to add it to that tag. And vice versa.
        </div>
      </div>


      <div v-if="randomTip===2 || randomTip>=6" class="sbartagmenu-tip">
        <iconsvg icon="w10-info" />
        <div>
          You can organize your tags using drag and drop interactions. Including nesting tags in other tags up to one level.
        </div>
      </div>

    </div>



  </div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";


  .sbartagmenu {
    display: block;
    width: 200px;
    background: white;
    border-radius: 5px;
    //box-shadow: 0 2px 15px 0 rgba(black,.2), 0 2px 35px 0 rgba(black,.2);
    padding: 0px 0;
  }



  .sbartagmenu-header {
  }

    .sbartagmenu-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      color: rgba(black,.95);

      padding-top: 8px;
      padding-left: 10px;
      padding-right: 5px;
      padding-bottom: 8px;
    }

  .sbartagmenu-content {
    padding: 0px;
    padding-bottom: 5px;
  }

    .sbartagmenu-btn {
      display: block;
      width: 100%;
      background: transparent;
      padding: 8px 10px 7px 10px;
      font-size: 13px;
      font-weight: 600;
      line-height: 1.2;
      color: rgba(black,.7);
      text-align: left;
      border-bottom: 1px solid rgba(black,.1);
      outline: none;



      &:first-of-type {
        border-top: 1px solid rgba(black,.1);
      }

      &:last-of-type {
      }

      &:hover {
        background: rgba(black,.04);
      }

      &:focus {
        background: rgba(black,.06);
      }

      & svg {
        width: 14px;
        height: 14px;
        display: inline-block;
        fill: rgba(black,.9);
      }
    }

    .sbartagmenu-tip {
      display: block;
      width: 100%;
      //border: 1px solid rgba(black,.1);
      padding: 5px 9px 4px 9px;
      padding: 10px;
      padding-top: 5px;
      padding-bottom: 3px;
      margin-top: 2px;
      //border-radius: 0 0 4px 4px;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.2;
      color: rgba(black,.6);
      position: relative;

      padding-left: 28px;

      & svg {
        position: absolute;
        top: 4px;
        left: 9px;
        width: 15px;
        height: 15px;
        fill: rgba(black,.6);
      }

    }





</style>







<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .sbartagmenu {
    background: darken(white,72);
    border: 1px solid darken(white,65);
  }

  .sbartagmenu-title {
    color: rgba(white,.95);

  }

  .sbartagmenu-btn {
    color: rgba(white,.7);
    border-color: rgba(white,.1);

  }


  .sbartagmenu-btn:first-of-type {
    border-color: rgba(white,.1);
  }

  .sbartagmenu-btn:hover {
    background: rgba(white,.04);
  }

  .sbartagmenu-btn:focus {
    background: rgba(white,.06);
  }
  .sbartagmenu-btn svg {
    fill: rgba(white,.8);
  }

  .sbartagmenu-tip {
    color: rgba(white,.6);
  }

  .sbartagmenu-tip svg {
    fill: rgba(white,.6);
  }



}
</style>
