/*------------------------------------*\
  Environment
\*------------------------------------*/
var NODE_ENV = process.env.NODE_ENV;
var BUILD = process.env.BUILD;
var VERSION = process.env.VERSION;
var CORE_URL = process.env.CORE_URL;
var WEBAPP_URL = process.env.WEBAPP_URL;
var HQ_URL = process.env.HQ_URL;
var CHROME_EXTENSION_ID = process.env.CHROME_EXTENSION_ID;



/*------------------------------------*\
  Import Vue
\*------------------------------------*/
import Vue from 'vue';



/*------------------------------------*\
  Environment
\*------------------------------------*/
var environment;
if (['devwidget', 'devwebapp', 'devchrome', 'devchrome-without-app'].includes(BUILD)) (environment = 'development');
else if (['widget', 'webapp', 'chrome'].includes(BUILD)) (environment = 'production');
else (environment = 'unknown');



/*------------------------------------*\
  Elastic RUM
\*------------------------------------*/
import { init as initApm } from '@elastic/apm-rum'
window._apm = initApm({
  serviceName: 'ggather-webapp',
  serverUrl: 'https://57564719991c4802b95f61a1020a8d0b.apm.us-east-1.aws.cloud.es.io:443',
  serviceVersion: VERSION,
  environment: environment,
  active: environment === 'production', //Math.random() < 0.1
  instrument: true,
  breakdownMetrics: true,
  monitorLongtasks: true,
  //transactionSampleRate: 0.2,
})

window._apm && window._apm.addLabels({ build: BUILD })



/*------------------------------------*\
  Sentry
\*------------------------------------*/
//"@sentry/browser": "5.27.6",
//"@sentry/integrations": "5.27.6",
//"@sentry/tracing": "5.27.6",
//import * as Sentry from "@sentry/browser";
//import { Vue as VueIntegration } from "@sentry/integrations";
//import { Integrations } from "@sentry/tracing";
//Sentry.init({
//  dsn: 'https://ce134b16a1974682b22b7e42401c76ff@o387492.ingest.sentry.io/5537078',
//  integrations: [
//    new VueIntegration({
//      Vue,
//      tracing: true,
//      logErrors: true,
//    }),
//    new Integrations.BrowserTracing(),
//  ],
//  tracesSampleRate: 0.1,
//  release: `ggather-spa@${VERSION}`,
//  environment: environment,
//});
//Sentry.setTag('build', BUILD);



/*------------------------------------*\
  Polyfills
\*------------------------------------*/

// core-js
import 'regenerator-runtime/runtime';
import 'core-js/stable/number/is-integer';
import 'core-js/stable/object/assign';
import 'core-js/stable/object/entries';
import 'core-js/stable/object/keys';
import 'core-js/stable/object/values';
import 'core-js/stable/array/from';
import 'core-js/stable/array/find';
import 'core-js/stable/array/find-index';
import 'core-js/stable/array/fill';
import 'core-js/stable/array/includes';
import 'core-js/stable/symbol';
import 'core-js/stable/promise';
import 'core-js/stable/number/is-nan';
import 'core-js/stable/map';
import 'core-js/stable/set';
import 'core-js/stable/string';
//import 'core-js';

// custom
import '../common/script/polyfill-url.js';
import '../common/script/polyfill-remove.js';

// raf
//import 'raf/polyfill';

// smoothscroll
import smoothscrollPolyfill from 'smoothscroll-polyfill';
smoothscrollPolyfill.polyfill();




/*------------------------------------*\
  Browser Update & Analytics
\*------------------------------------*/
//import browserUpdate from '../common/script/browser-update.js';
//if ( (BUILD=='devwebapp') || (BUILD=='webapp') ) {
//  browserUpdate();
//}





/*------------------------------------*\
  Analytics
\*------------------------------------*/
import installAnalytics from '../common/script/analytics.js';
if ( (BUILD=='webapp') || (BUILD=='chrome') ) {
  installAnalytics({
    url: 'http://ggather.com',
    id: 'UA-46688343-13',
    debug: false,
  });
}




/*------------------------------------*\
  UserLeap
\*------------------------------------*/
//import installUserLeap from '../common/script/userleap.js';
//if ( (BUILD=='webapp') || (BUILD=='chrome') ) {
//  installUserLeap({
//    appId: 'oH_y4CGcG5',
//  });
//}



/*------------------------------------*\
  Drift
\*------------------------------------*/
/*
import installDrift from '../common/script/drift.js';
if ( (BUILD=='webapp') ) {
  installDrift();
}
*/




/*------------------------------------*\
  Register Common
  Libs & Plugins
\*------------------------------------*/

// cookie
import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

// alertify
import VueAlertify from '../common/script/vue-alertify.js';
Vue.use(VueAlertify);

// date-fns
import VueDateFns from '../common/script/vue-date-fns.js';
Vue.use(VueDateFns);

// axios
import VueAxios from '../common/script/vue-axios.js';
Vue.use(VueAxios);

// autosize
import VueAutosize from '../common/script/vue-autosize.js';
Vue.use(VueAutosize);

// tippy
import VueTippy from '../common/script/vue-tippy.js';
Vue.use(VueTippy);

// extension
import VueExtension from '../common/script/vue-extension.js';
Vue.use(VueExtension, {
  environment: ( (BUILD==='chrome') || (BUILD==='devchrome') ) ? 'chrome' : 'webapp',
  extension_id: CHROME_EXTENSION_ID,
});

// vuebar
import Vuebar from 'vuebar';
Vue.use(Vuebar);

// vuelidate
// import Vuelidate from 'vuelidate';
// Vue.use(Vuelidate);

// head
import VueHead from 'vue-head';
Vue.use(VueHead, {
  complement: 'GGather',
  separator: ' × ',
});

// vue overlays
import vueOverlays from 'vue-overlays';
Vue.component('Portal', vueOverlays.Portal);
Vue.component('Backdrop', vueOverlays.Backdrop);
Vue.component('Modal', vueOverlays.Modal);
Vue.component('Popover', vueOverlays.Popover);






/*------------------------------------*\
  Import Customized Store
\*------------------------------------*/
import store from './store/store.js'




/*------------------------------------*\
  Import Customized Router
\*------------------------------------*/
import router from './router/router.js';




/*------------------------------------*\
  Sync Vuex + Vue-Router
\*------------------------------------*/
import { sync } from 'vuex-router-sync';
sync(store, router);






/*------------------------------------*\
  Register Framework Components
\*------------------------------------*/
Vue.component('btn',         require('./view/framework/btn.vue').default);
Vue.component('portal',      require('./view/framework/portal.vue').default);
Vue.component('popover',     require('./view/framework/popover.vue').default);
Vue.component('iconsvg',     require('./view/framework/iconsvg.vue').default);
Vue.component('spinner',     require('./view/framework/spinner.vue').default);
Vue.component('spinner2',    require('./view/framework/spinner2.vue').default);


/*------------------------------------*\
  Register Logic Components
\*------------------------------------*/
Vue.component('htmlclass', require('./view/logic/htmlclass.vue').default);


/*------------------------------------*\
  Register Layout Components
\*------------------------------------*/
Vue.component('ctabar',      require('./view/layout/ctabar.vue').default);
Vue.component('titlebar',    require('./view/layout/titlebar.vue').default);
Vue.component('topbar',      require('./view/layout/topbar.vue').default);
Vue.component('titlebarheading', require('./view/layout/titlebarheading.vue').default);
Vue.component('selectbar',   require('./view/layout/selectbar.vue').default);
Vue.component('sidebarbtn',  require('./view/layout/sidebarbtn.vue').default);


/*------------------------------------*\
  Register Dialog Components
\*------------------------------------*/
Vue.component('dialogbox',   require('./view/dialog/dialogbox.vue').default);
Vue.component('followsbox',  require('./view/dialog/followsbox.vue').default);
Vue.component('profeature',  require('./view/dialog/profeature.vue').default);
Vue.component('signinup',    require('./view/dialog/signinup.vue').default);
Vue.component('tagbox',      require('./view/dialog/tagbox.vue').default);


/*------------------------------------*\
  Register Viewer Components
\*------------------------------------*/
Vue.component('viewer',          require('./view/viewer/viewer.vue').default);
Vue.component('viewertop',       require('./view/viewer/viewertop.vue').default);
Vue.component('viewernav',       require('./view/viewer/viewernav.vue').default);
Vue.component('comment',         require('./view/viewer/comment.vue').default);
Vue.component('comments',        require('./view/viewer/comments.vue').default);
Vue.component('reader',          require('./view/viewer/reader.vue').default);
Vue.component('embedel',         require('./view/viewer/embedel.vue').default);
Vue.component('embedimg',        require('./view/viewer/embedimg.vue').default);
Vue.component('saveurl',         require('./view/viewer/saveurl.vue').default);
Vue.component('savepreview',     require('./view/viewer/savepreview.vue').default);
Vue.component('saverating',      require('./view/viewer/saverating.vue').default);
Vue.component('savetags',        require('./view/viewer/savetags.vue').default);
Vue.component('savetagstag',     require('./view/viewer/savetagstag.vue').default);
Vue.component('savethumb',       require('./view/viewer/savethumb.vue').default);
Vue.component('savethumburlpop', require('./view/viewer/savethumburlpop.vue').default);
Vue.component('itemdetails',     require('./view/viewer/itemdetails.vue').default);


/*------------------------------------*\
  Register Items Components
\*------------------------------------*/
Vue.component('items',           require('./view/items/items.vue').default);
Vue.component('item',            require('./view/items/item.vue').default);
Vue.component('itemfeed',        require('./view/items/itemfeed.vue').default);
Vue.component('itemlist',        require('./view/items/itemlist.vue').default);
Vue.component('itempanel',       require('./view/items/itempanel.vue').default);
Vue.component('itemgrid',        require('./view/items/itemgrid.vue').default);
Vue.component('itemhighlights',  require('./view/items/itemhighlights.vue').default);
Vue.component('itemnav',         require('./view/items/itemnav.vue').default);
Vue.component('itemnote',        require('./view/items/itemnote.vue').default);
Vue.component('itemrate',        require('./view/items/itemrate.vue').default);


/*------------------------------------*\
  Register Common Components
\*------------------------------------*/
Vue.component('activity',        require('./view/common/activity.vue').default);
Vue.component('publishinvite',   require('./view/common/publishinvite.vue').default);
Vue.component('pmethod',         require('./view/common/pmethod.vue').default);
Vue.component('itemstoolbox',    require('./view/common/itemstoolbox.vue').default);
Vue.component('jumpnotif',       require('./view/common/jumpnotif.vue').default);
Vue.component('savebtn',         require('./view/common/savebtn.vue').default);
Vue.component('tagcard',         require('./view/common/tagcard.vue').default);
Vue.component('usercard',        require('./view/common/usercard.vue').default);
Vue.component('usermenu',        require('./view/common/usermenu.vue').default);
Vue.component('socialtabs',      require('./view/common/socialtabs.vue').default);
Vue.component('tagsfilters',     require('./view/common/tagsfilters.vue').default);
Vue.component('updatenotice',    require('./view/common/updatenotice.vue').default);


/*------------------------------------*\
  Register Landing Page Components
\*------------------------------------*/
Vue.component('landingnavbtn',   require('./view/landing/landingnavbtn.vue').default);
Vue.component('landinghero',     require('./view/landing/landinghero.vue').default);


/*------------------------------------*\
  Register Pricing Components
\*------------------------------------*/
Vue.component('pricingbadges',   require('./view/pricing/pricingbadges.vue').default);
Vue.component('pricingitem',     require('./view/pricing/pricingitem.vue').default);
Vue.component('pricingmgmt',     require('./view/pricing/pricingmgmt.vue').default);
Vue.component('pricingwrapper',  require('./view/pricing/pricingwrapper.vue').default);
Vue.component('pricingfaq',      require('./view/pricing/pricingfaq.vue').default);
Vue.component('pricinginfo',     require('./view/pricing/pricinginfo.vue').default);
Vue.component('pricingstatus',   require('./view/pricing/pricingstatus.vue').default);
Vue.component('pricingup',       require('./view/pricing/pricingup.vue').default);

/*------------------------------------*\
  Register Settings Components
\*------------------------------------*/
Vue.component('settings-account',require('./view/settings/settings-account.vue').default);
Vue.component('settings-billing',require('./view/settings/settings-billing.vue').default);
Vue.component('settings-data',require('./view/settings/settings-data.vue').default);
Vue.component('settings-exp',require('./view/settings/settings-exp.vue').default);
Vue.component('settings-export', require('./view/settings/settings-export.vue').default);
Vue.component('settings-importhtml',require('./view/settings/settings-importhtml.vue').default);
Vue.component('settings-importhtmlold',require('./view/settings/settings-importhtmlold.vue').default);
//Vue.component('settings-importjson',    require('./view/settings/settings-importjson.vue').default);


/*------------------------------------*\
  Register [S]ide[bar]
\*------------------------------------*/
Vue.component('sbar',            require('./view/sbar/sbar.vue').default);
Vue.component('sbar-grip',       require('./view/sbar/sbar-grip.vue').default);
Vue.component('sbar-favtag',     require('./view/sbar/sbar-favtag.vue').default);
Vue.component('sbar-tags',       require('./view/sbar/sbar-tags.vue').default);
Vue.component('sbar-tag',        require('./view/sbar/sbar-tag.vue').default);
Vue.component('sbar-tag-menu',   require('./view/sbar/sbar-tag-menu.vue').default);



/*------------------------------------*\
  Render App
\*------------------------------------*/
import App from './app.vue';

/*
Vue.config.errorHandler = function(exception, vm, info){
  window.logError(exception);
};
Vue.config.performance = (BUILD == 'devwebapp') ? true : false;
Vue.config.productionTip = false;
Vue.config.devtools = true;
*/

Vue.config.performance = false;

window.app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
