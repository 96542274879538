<script>
import Masonry from 'masonry-layout';
import _throttle from 'lodash/throttle';
export default {

  props: [
    'context',
    'username',
    'spinner',
  ],

  data: function(){
    return {
      loading: false,
      loadingStart: false,
      loadingNextPage: false,
      loadingMeta: false,

      startLoadingNextPage: false,
    }
  },

  computed: {
    items: function(){
      return this.$store.state.items;
    },
    spinnerEnabled: function(){
      return (
        this.spinner ||
        (this.context==='freshest') ||
        (this.context==='all') ||
        (this.context==='search') ||
        (this.context==='feed') ||
        (this.context==='tagpage')
      )
    },
    showEmptyStateButtons: function(){
      if (this.$route.query.untagged) { return false; }
      if (this.$route.query.rating) { return false; }
      if (this.context=='all') { return true; }
      if (this.context=='tagpage') {
        if (this.$route.params.username == this.$store.state.user.username) {
          return true;
        }
      }
    },
    itemLayout: function(){
      return this.$store.getters.visibleItemLayout;
    },
  },

  mounted: function(){
    this.getItems({ start: true, });
    this.scrollListener();
  },

  beforeDestroy: function(){
    this.cancelGetItems();
    this.scrollListener('destroy');
    this.masonry('destroy');
    this.$store.commit('items', null);
    this.$store.commit('itemsMeta', null);
  },

  watch: {
    '$route.path': function(){
      this.getItems({ start: true, });
    },
    '$route.query.untagged': function(){
      this.getItems({ start: true, });
    },
    '$route.query.query': function(){
      this.getItems({ start: true, });
    },
    '$route.query.rating': function(){
      this.getItems({ start: true, });
    },
    '$route.query.ordering': function(){
      this.getItems({ start: true, });
    },
    '$route.query.subtag': function(){
      this.getItems({ start: true, });
    },
    itemLayout: function(){
      this.masonry('init');
    },
    '$store.state.itemLayoutFavs': function(){
      this.masonry('layout');
    },
    '$store.state.items': function(items){
      if (items && items.length) {
        this.masonry('reloadItems');
        this.masonry('layout');
      } else {
        this.masonry('destroy');
      }
    },
    '$store.state.viewer': function(newVal, oldVal){
      this.masonry('layout', {disableTransition: true});
      this.scrollToActiveElement(1, newVal, oldVal) // scroll only on viewer open and close
      setTimeout(function() {
        this.masonry('layout', {disableTransition: true});
        this.scrollToActiveElement(1, newVal, oldVal) // scroll only on viewer open and close
      }.bind(this), 250);
    },
    '$store.state.windowWidth': function(){
      this.masonry('layout');
    },
    '$store.getters.finalSidebarCollapse': function(){
      this.masonry('layout');
    },
    '$store.state.sidebarCollapse': function(){
      this.masonry('layout');
    },
    '$store.state.eventSaveThumbChange': function(){
      this.masonryLayoutThrottled();
    },
    '$store.state.eventItemThumbLoad': function(){
      this.masonryLayoutThrottled();
    },
    '$store.state.eventBatchDeleted': function(){
      this.getItems({ onlyMeta: true, });
    },
    '$store.state.eventBatchEdited': function(){
      this.getItems({ onlyMeta: true, });
    },
    '$store.state.viewerExpandLevel': function(){
      this.masonry('layout', {disableTransition: true});
      setTimeout(function() {
        this.masonry('layout', {disableTransition: false});
      }.bind(this), 250);
    },
    'startLoadingNextPage': function(val){
      if (!this.$store.state.itemsMeta) return;
      val ? this.getItems({nextPage: true}) : null;
    },
  },

  methods: {

    before: function(options){
      this.loading = true;
      this.$store.commit('itemsLoading', true);
      if (options.start) {
        //this.$store.commit('items', null);
        //this.$store.commit('itemsMeta', null);
        this.loadingStart = true;
      }
      if (options.nextPage) {
        this.loadingNextPage = true;
      }
      if (options.onlyMeta) {
        this.loadingMeta = true;
      }
    },

    response: function(options, response){

      // extract data and setup meta
      var data = response.data;
      var items = data.results;
      delete data.results;


      // data without results is the 'meta'
      this.$store.commit('itemsMeta', null);
      this.$nextTick(function(){
        this.$store.commit('itemsMeta', data);
      });

      // set items
      if (options.start) {
        this.$store.commit('items', null);
        this.$nextTick(function(){
          this.$store.commit('items', items);
          this.masonry('init');
        });
      }

      // concat items
      if (options.nextPage) {
        this.$store.commit('itemsConcat', items);
        this.masonry('reloadItems', {disableTransition: true});
        this.masonry('layout', {disableTransition: true});
      }

      // meta
      if (data.tag_meta) this.$store.commit('tagPageMeta', data.tag_meta);

      // loading is end
      this.loading = false;
      this.loadingStart = false;
      this.loadingNextPage = false;
      this.loadingMeta = false;
      this.startLoadingNextPage = false;

      this.$store.commit('itemsLoading', false);

      // fire load more scroll handler one time to check
      // if more items need to be loaded
      // (when viewport is bigger than initial item load)
      this.$nextTick(function(){
        this.scrollHandler();
      });

    },

    request: function(options){

      // cancel token
      var CancelToken = this.$axios.CancelToken;
      var source = CancelToken.source();
      this._getItemsRequest = source;

      // initial request data
      var request = {
        cancelToken: source.token,
        method: 'get',
        params: {},
      }

      //  profile: set username
      if (this.context=='profilepage') {
        request.url = this.$store.state.api + '/get-urlbookmarks/?kind=profilepage';
        request.params.username = this.username;
      }

      //  tag page: set username and slug
      if (this.context=='tagpage') {
        request.url = this.$store.state.api + '/get-urlbookmarks/?kind=tagpage';
        request.params.slug = this.$route.params.tagslug;
        request.params.username = this.$route.params.username;
      }

      //  all
      if (this.context=='all') {
        request.url = this.$store.state.api + '/get-urlbookmarks/?kind=all';
      }

      //  search
      if (this.context=='search') {
        request.url = this.$store.state.api + '/search/';
        request.params.q = this.$route.query.query;
      }

      // all - untagged
      if (this.$route.query.untagged) {
        request.params.untagged = this.$route.query.untagged;
      }

      //  freshest
      if (this.context=='freshest') {
        request.url = this.$store.state.api + '/get-urlbookmarks/?kind=freshest';
      }

      //  feed
      if (this.context=='feed') {
        request.url = this.$store.state.api + '/get-urlbookmarks/?kind=feed';
      }

      // on mounted, when getting items first time
      if (options.start) {
        request.params.page = 1;
      }

      // when we want to load next page
      if (options.nextPage) {
        request.params.page = this.$store.state.itemsMeta.page + 1;
      }

      // ordering?
      if (this.$route.query.ordering) {
        request.params.ordering = this.$route.query.ordering;
      }

      // subtag?
      if (this.$route.query.subtag) {
        request.params.subtag = this.$route.query.subtag;
      }

      // rating?
      if (this.$route.query.rating) {
        request.params.rating = this.$route.query.rating;
      } else { delete request.params.rating; }

      // we return object with proper request
      return request;

    },



    cancelGetItems: function(){
      this._getItemsRequest ? this._getItemsRequest.cancel() : null;
    },

    getItems: function(options){
      this.cancelGetItems();
      this.before(options);
      this.$axios(this.request(options)).then(function(response){
        this.response(options, response);
      }.bind(this), function(error){
        alert('error' + error.response.status);
      }.bind(this));
    },


    masonryLayoutThrottled: _throttle(function(){
      this.masonry('layout', {disableTransition: true});
      this.scrollToActiveElement(1);
    }, 50),

    masonry: function(kind, options){

      var options = options ? options : {};

      var transitionsEnabled = (this.$store.state.transitionsEnabled);
      var transitionsOff = options.disableTransition || (!transitionsEnabled);

      var destroy = function(){
        if (this._masonry) {
          this._masonry.destroy();
          this._masonry = null;
          delete this._masonry;
        }
      }.bind(this);

      if (this.context=='feed') {
        destroy();
        return false;
      }

      if ( (this.itemLayout !== 'card') && (this.itemLayout !== 'grid') && (this.itemLayout !== 'card-small') ) {
        destroy();
        return false;
      }

      if (!this.$store.state.items) {
        destroy();
        return false;
      }

      if ((kind=='layout') && this._masonry) {
        if ((!this.$store.state.items)||(!this.$store.state.items.length)){return false;}
        this._masonry.options.transitionDuration = transitionsOff ? 0 : 500;
        this._masonry.layout();
        this._masonry.options.transitionDuration = transitionsEnabled ? 500 : 0;
        this.$nextTick(function(){
          this._masonry.options.transitionDuration = transitionsOff ? 0 : 500;
          this._masonry.layout();
          this._masonry.options.transitionDuration = transitionsEnabled ? 500 : 0;
        });
      }

      if ((kind=='reloadItems') && this._masonry) {
        if ((!this.$store.state.items)||(!this.$store.state.items.length)){return false;}
        this._masonry.options.transitionDuration = transitionsOff ? 0 : 500;
        this._masonry.reloadItems();
        this._masonry.options.transitionDuration = transitionsEnabled ? 500 : 0;
        this.$nextTick(function(){
          this._masonry.options.transitionDuration = transitionsOff ? 0 : 500;
          this._masonry.reloadItems();
        this._masonry.options.transitionDuration = transitionsEnabled ? 500 : 0;
        });
      }

      if (kind=='destroy') {
        destroy();
      }

      if (kind=='init') {
        if ((!this.$store.state.items)||(!this.$store.state.items.length)){return false;}
        destroy();
        this.$nextTick(function(){

          if (this.$store.state.items && this.$refs.itemsInner) {

            this._masonry = new Masonry( '[masonry-items-container]', {
              //itemSelector: '.item[data-masonry="true"]',
              itemSelector: '.item-outer[data-masonry="true"]',
              resize: false,
              transitionDuration: transitionsEnabled ? 500 : 0,
              stagger: 0,
              percentPosition: true,
              initLayout: false,
            });
            this._masonry.layout();

          }

        });
      }


    },




    scrollListener: function(action){
      if (action == 'destroy') {
        window.removeEventListener('scroll', this.scrollHandler, 1);
        return false;
      }
      window.addEventListener('scroll', this.scrollHandler, 1);
    },


    scrollHandler: _throttle(function(event){
      if (this.startLoadingNextPage) { return false; }
      var el = this.$refs.loadMoreButton;
      var target = window;
      if (!el) { return false; }
      //if ((!this.$store.state.itemsMeta) || (!this.$store.state.itemsMeta.has_next)) { return false; }
      var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      var elTop = el.getBoundingClientRect().top - viewHeight;
      var elTopInViewPadded = (elTop-500) < 0;
      if (elTopInViewPadded) {
        this.startLoadingNextPage = true;
      }

    }, 50),




    scrollToActiveElement: function(instant, viewerNewVal, viewerOldVal){

      // do it only when opening or closing link
      try {
        if (viewerNewVal.kind==viewerOldVal.kind){ return false; }
      } catch(e) {}

      this.$nextTick(function(){


        // don't scroll on certain item layouts
        if (this.itemLayout=='panel' || this.itemLayout=='list'  || this.itemLayout=='feed') {
          return false;
        }

        // don't scroll when viewer is expanded past 1
        if (this.$store.state.viewerExpandLevel!=1) {
          return false;
        }


        if (instant) {
          var activeElement = document.querySelector('.item-outer[data-active="true"]');
          if (!activeElement) { return false; }

          if (this.$route.meta.kind=='profilepage') {
            document.body.scrollTop = (activeElement.offsetTop + this.$refs.items.offsetTop) - 15;
          } else {
            document.body.scrollTop = activeElement.offsetTop - 55;
          }

        } else {
          setTimeout(function() {
            var activeElement = document.querySelector('.item-outer[data-active="true"]');
            if (!activeElement) { return false; }
            document.body.scrollTop = activeElement.offsetTop;
            //console.dir(activeElement);
            //console.warn('scrollll');
            //console.dir(this);

            if (this.$route.meta.kind=='profilepage') {
              document.body.scrollTop = (activeElement.offsetTop + this.$refs.items.offsetTop) - 15;
            } else {
              document.body.scrollTop = activeElement.offsetTop - 55;
            }
          }.bind(this), 200);
        }


      });

    },



  },

};
</script>


<template>
<div class="items-wrapper">
  <div ref="items" :class="[
    'items',
    'mod-item-layout-'+itemLayout,
    {
      'mod-no-items': (!$store.state.items) || ($store.state.items && (!$store.state.items.length)),
      'mod-loading': loadingStart,
      'mod-viewer-1': $store.state.viewer && ($store.state.viewerExpandLevel==1),
      'mod-no-titlebar': (context=='profilepage') || (context=='freshest'),
      'mod-show-favicon': $store.state.itemLayoutFavs,
    }
  ]">


    <!-- Items -->
    <div class="items-inner-inner" ref="itemsInner" masonry-items-container>
      <template v-for="(item, index) in $store.state.items">
        <div class="items-item-error" v-if="item.error" :key="item.error_document_id">Search error. {{item.error}}. Reference: {{item.error_document_id}}.</div>
        <item v-if="!item.error" :item="item" :key="index+'_'+item.pk" :context="context"></item>
      </template>
    </div>


    <!-- Empty Items -->
    <div v-if="((!$store.state.items) || (!$store.state.items.length)) && (!loadingStart)" >

      <!-- All Untagged -->
      <div class="items-empty" v-if="context=='all' && $route.query.untagged">No links...</div>

      <!-- All Completely Empty -->
      <div class="items-empty" v-else-if="context=='all' && (!$route.query.untagged)">Fresh start! You can start building your library by importing links or by adding them manually...</div>

      <!-- Feed -->
      <div class="items-empty" v-else-if="context=='feed'">All of the links from your subscriptions will be shown here.</div>

      <!-- Else -->
      <div v-else>

        <div class="items-empty">
          No links...
        </div>

      </div>



      <div class="items-empty-btns" v-if="showEmptyStateButtons">


        <button class="items-empty-btn" type="button" @click.prevent.stop="$store.commit('viewer', {item: null, kind: 'newlink'})">
          <span class="icon icon-plus-math"></span>
          <span class="text">
            Add New Link
          </span>
        </button>

        <a class="items-empty-btn" :href="$store.state.hq+'/kb/saving-methods'" target="_blank" rel="noopener noreferrer">
          <span class="icon icon-save"></span>
          <span class="text">
            Learn Saving Methods
          </span>
        </a>

        <a v-if="$store.state.chrome"  target="_blank" :href="$store.state.webapp + '/settings/import'" class="items-empty-btn">
          <span class="icon icon-import"></span>
          <span class="text">
            Import Your Bookmarks
          </span>
        </a>



        <router-link v-else class="items-empty-btn" to="/settings/import">
          <span class="icon icon-import"></span>
          <span class="text">
            Import Your Bookmarks
          </span>
        </router-link>

      </div>


    </div>




    <!-- Spinner -->
    <transition name="items-spinner-fade" :duration="$store.state.transitionsEnabled ? 150 : 0">
    <div v-if="spinnerEnabled && loadingStart" class="items-spinner-wrapper" :class="{'mod-profilepage': context=='profilepage'}">
      <spinner kind="dots" :dark="$store.state.appTheme !== 'dark'" :size="2"></spinner>
    </div>
     </transition>



    <!-- Load More Button -->
    <button v-if="$store.state.itemsMeta && $store.state.itemsMeta.has_next" type="button" class="items-load-more-btn" :class="{'mod-loading': loading}" @click="getItems({nextPage: true})" ref="loadMoreButton">
      <template v-if="!loadingNextPage">
        <span class="icon icon-activity-feed"></span>
        <!-- Load{{loadingNextPage ? 'ing' : ''}} {{$store.state.itemsMeta.next_page_count}} more...-->
        Load{{loadingNextPage ? 'ing' : ''}} more...
      </template>
      <template v-else>
        <spinner kind="dots" :dark="$store.state.appTheme !== 'dark'"></spinner>
      </template>
    </button>



  </div>
</div>
</template>


<style lang="scss" scoped>


  @import "../../../common/style/_variables.scss";

  .items-wrapper {
    padding: 20px;
    //padding-top: 50px;
    padding-top: 0px;
    max-width: 1200px;
    margin: 0 auto;

    html.mod-visible-item-layout-feed & {
      max-width: 500px;
    }

    html.mod-visible-item-layout-list & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-panel & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-panel-multiple & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-grid & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-grid-fixed & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-card & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-card-fixed & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-card-small & {
      padding-top: 0px;
    }

    html.mod-visible-item-layout-card-fixed-small & {
      padding-top: 0px;
    }

    html.mod-route-meta-kind-discover &,
    html.mod-route-meta-kind-profilepage & {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 900px){
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .items {
    position: relative;
    opacity: 1;
    overflow: hidden;
    //html.mod-transitions-enabled & {
    //  transition: opacity 80ms ease-in-out, filter 80ms ease-in-out;
    //}
    //&.mod-item-layout-anim {
    //    opacity: 0;
    //    filter: grayscale(100%);
    //}
  }

  .items-inner {
  }

  .items-inner-inner {
    padding-top: 45px;
    //min-height: calc(50vh - 100px);
    //html.mod-transitions-enabled & {
    //  transition: opacity 150ms ease-in-out, padding 150ms ease-in-out;
    //}
    .items.mod-loading & {
      opacity: .3;
    }
    .items.mod-no-titlebar & {
      padding-top: 0px;
    }

    /*
    .items.mod-item-layout-list & {}
    .items.mod-item-layout-panel & {}
    .items.mod-item-layout-panel-multiple & {}
    .items.mod-item-layout-grid & {}
    .items.mod-item-layout-grid-fixed & {}
    .items.mod-item-layout-card & {}
    .items.mod-item-layout-card-fixed & {}
    .items.mod-item-layout-card-small & {}
    .items.mod-item-layout-card-fixed-small & {}
    */


    .items.mod-item-layout-panel-multiple &,
    .items.mod-item-layout-grid &,
    .items.mod-item-layout-grid-fixed &,
    .items.mod-item-layout-card &,
    .items.mod-item-layout-card-fixed &,
    .items.mod-item-layout-card-small &,
    .items.mod-item-layout-card-fixed-small & {
      margin-right: -20px;
      @media (max-width: 500px){
        margin-right: 0px;
      }
    }

    .items.mod-no-items.mod-no-items.mod-no-items & {
      margin-right: 0px;
    }


    //@media (max-width: 700px){
    //  padding-top: 31px;
    //  .items.mod-no-titlebar & {
    //    padding-top: 0px;
    //  }
    //}



  }

  .items-item-error {
    font-size: 14px;
    font-weight: 600;
    color: darken(red,30);
    background: lighten(red,30);
    padding: 10px;
    margin: 10px 0;
    border-radius: 6px;
  }

  .items-load-more-btn {
    padding: 60px 50px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: white;
    color: #333;
    //background-color: #999;
    //color: #eee;
    user-select: none;
    outline: none;

    display: block;
    width: 100%;
    //margin-left: auto;
    //margin-right: auto;

    margin-top: 10px;
    display: none;
    pointer-events: none;

    //html.mod-transitions-enabled & {
    //  transition: opacity 100ms ease-out;
    //}

    .count {

    }

    .icon {
      margin-right: 5px;
      &::before {
        line-height: 1.35;
      }
    }

    //&.is-visible {
      display: block;
      opacity: .9;
      opacity: .7;
      pointer-events: auto;

      &:focus,
      &:hover {
        opacity: 1;
      }
    //}

    &.mod-loading {
      //opacity: .0;
      background: transparent;
      border: none;
      padding-top: 30px;
      padding-bottom: 30px;
      pointer-events: none;
      user-select: none;
    }

  }



    @keyframes items-spinner-wrapper-fade-in {
      0% {
        opacity: .0;
      }
      100% {
        opacity: 1;
      }
    }




  .items-spinner-wrapper {
    //margin: 20px;
    text-align: center;
    display: block;
    margin: auto;
    //margin-top: 50px;

    position: absolute;
    top: 0;
    //bottom: 0;
    left: 0;
    right: 0;

    //background-color: rgba(#f7f7f7,.7);
    z-index: 100;

    min-height: calc(50vh - 100px);

    display: flex;
    justify-content: center;
    align-items: center;

    animation-name: items-spinner-wrapper-fade-in;
    animation-duration: 1000ms;
    animation-delay: 2000ms;
    animation-fill-mode: both;

    &.items-spinner-fade-enter-active {
      html.mod-transitions-enabled & {
        transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
      }
    }

    &.items-spinner-fade-leave-active {
      html.mod-transitions-enabled & {
        transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
      }
    }

    &.items-spinner-fade-enter, &.items-spinner-fade-leave-to  {
      opacity: .0;
    }

    &.items-spinner-fade-enter {
    }

    &.items-spinner-fade-leave-to {
    }

    &.mod-profilepage {
      height: 150px;
    }
  }



  .items-empty {
    font-size: 14px;
    font-weight: 500;
    padding: 14px 15px 13px 15px;
    margin-top: 1px;
    border-radius: 4px;

    color: darken(white,70);
    border: 1px dashed darken(white,20);
    //background-color: darken(white,0);

  }




  .items-empty-btns {
    display: block;
    display: fixed;
    justify-content: space-between;
  }

  .items-empty-btn {
    outline: none;
    display: inline-block;
    border-radius: 4px;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 16px;
    color: darken(white,70);
    border: 1px solid darken(white,12);
    background-color: darken(white,0);

    margin-right: 10px;
    margin-top: 10px;
    &:last-of-type {
      margin-right: none;
    }

    html.mod-transitions-enabled & {
      transition: color 50ms ease-in-out, border-color 50ms ease-in-out, background-color 50ms ease-in-out;
    }

    &:hover, &:focus {
      color: darken(white,100);
      border-color: darken(white,12);
      background-color: darken(white,0);
      //box-shadow: 0 1px 5px 0 rgba(black,.05);
    }

    @media(max-width: 700px){
      width: 100%;
      display: block;
    }
  }



</style>















<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .items-load-more-btn {
    background-color: darken(white,85);
    border-color: darken(white,80);
    color: rgba(white,.3);
    &.mod-loading {
      background: transparent;
      border: none;
    }
  }

  .items-empty {
    color: darken(white,15);
    //background-color: darken(white,80);
    border-color: darken(white,70);
  }

  .items-empty-btn {
    color: darken(white,15);
    background-color: darken(white,80);
    border-color: darken(white,70);
    &:hover, &:focus {
      color: darken(white,5);
      border-color: darken(white,67);
      background-color: darken(white,77);
    }
  }

}
</style>
