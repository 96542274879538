<script>
import contains from 'dom-helpers/contains';
export default {

  props: [
    'tag',
    'parent',
    'urlbookmark',
    'tagsSelected',
    'tagsFiltered',
    'tagsFilterValue',
  ],

  data: function(){
    return {
      newTagFormExpanded: false,

      newTagValue: '',
      newTagLoading: false,

    }
  },


  computed: {

    selected: function(){
      return this.tagsSelected.indexOf(this.tag.pk) > -1;
    },

    visible: function(){
      if (!this.tagsFiltered) return true;
      return this.tagsFiltered.indexOf(this.tag.pk) > -1;
    },


  },


  watch: {
  },



  mounted: function(){
  },


  beforeDestroy: function(){
  },

  methods: {

    closeNewTagFormWhenItHasNoValue: function(){
      if (!this.newTagValue) {
        this.newTagFormExpanded = false;
      }
    },

    tagClick: function(){
      this.closeNewTagFormWhenItHasNoValue();
      if (this.selected) this.$emit('deselect', this.tag.pk);
      else this.$emit('select', this.tag.pk);
    },

    reEmitTagSelectEvent: function(tagPK){
      this.closeNewTagFormWhenItHasNoValue();
      this.$emit('select', tagPK);
    },

    reEmitTagDeselectEvent: function(tagPK){
      this.closeNewTagFormWhenItHasNoValue();
      this.$emit('deselect', tagPK);
    },







    clearNewTag: function(){
      this.newTagFormExpanded = false;
      if (!this.newTagValue) return;
      this.newTagValue = '';
    },



    addNewTag: function(){
      if(!this.newTagValue) return;
      this.newTagLoading = true;
      this.$store.dispatch('apiNewTag', {
        name: this.newTagValue,
        parent: this.tag.pk,
        onSuccess: function(response){
          this.newTagValue = '';
          this.newTagFormExpanded = false;
          this.newTagLoading = false;
        }.bind(this),
        onError: function(error){
          this.newTagLoading = false;
        }.bind(this),
      });
    },



    newTagBtnClick: function(){
      this.newTagFormExpanded = true;
      this.$nextTick(function(){
        if (this.$refs.newTagInput) this.$refs.newTagInput.focus();
      });
    },








  },


};
</script>



<template>
  <div
    :class="[
      'savetagstag',
      'mod-color-'+tag.color,
      {
        'mod-selected': selected,
        'mod-is-parent': parent,
        'mod-is-children': !parent,
        'mod-visible': visible,
        'mod-hidden': !visible,
        'mod-filter-enabled': !!tagsFilterValue,
      },
    ]"

    :key="'savetagstag-'+tag.pk"
  >

    <button type="button" class="savetagstag-self"  @click.prevent="tagClick" :title="tag.name">
      <span class="savetagstag-circle"></span>
      <span class="savetagstag-icon"><iconsvg icon="material-checkmark" /></span>
      <span class="savetagstag-name">{{tag.name}}</span>
    </button>


    <div v-if="tag.children && tag.children.length" class="savetagstag-children">

      <savetagstag
        v-for="tag in tag.children"
        :tag="tag"
        :key="'savetagstagstag-'+tag.pk"
        :parent="false"
        :urlbookmark="urlbookmark"
        :tags-selected="tagsSelected"
        :tags-filtered="tagsFiltered"
        :tags-filter-value="tagsFilterValue"
        @select="reEmitTagSelectEvent"
        @deselect="reEmitTagDeselectEvent"
      />

      <button v-if="!newTagFormExpanded" key="newTagBtn" class="savetagstag-new-tag-btn" type="button" @click.prevent="newTagBtnClick">
        <iconsvg icon="material-add" />
      </button>

      <form v-else key="newTagForm" action="" @submit.prevent="addNewTag" class="savetagstag-new-tag">
        <input class="savetagstag-new-tag-input" type="text" placeholder="New Tag..." v-model="newTagValue"  ref="newTagInput">
        <span key="newtagaddicon" v-if="!newTagLoading" class="savetagstag-new-tag-icon">
          <iconsvg icon="material-add" />
        </span>
        <button key="newtagclear" class="savetagstag-new-tag-clear" type="button" @click.prevent="clearNewTag">
          <iconsvg icon="material-delete" />
        </button>
        <div key="newtagspinner" v-if="newTagLoading" class="savetagstag-new-tag-spinner">
          <div>
            <spinner2 :size="18" :line-size="2" :speed="0.8" line-fg-color="black" line-bg-color="transparent" />
          </div>
        </div>
      </form>

    </div>

  </div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";



  .savetagstag {
    display: block;
    width: 100%;
    font-size: 0;

    &.mod-is-parent {
      margin-top: 3px;
    }

    &.mod-is-children {
      display: inline-block;
      width: 32%;
      width: calc(33.3% - 3px);
      margin-top: 3px;
      margin-left: 3px;

      @media (max-width: 500px) {
        display: block;
        margin-right: 0;
        width: 100%;
        margin-left: 0;
      }
    }

    //&.mod-filter-enabled.mod-is-children {
    //  display: block;
    //  width: 100%;
    //}

    &.mod-is-children.mod-hidden {
      display: none;
    }




  }


    .savetagstag-self {
      display: block;
      background: rgba(black,.05);
      width: 100%;
      text-align: left;
      border-radius: 4px;
      outline-color: rgba(white,.5);
      appearance: none;
      position: relative;
      outline: none;

      html.mod-transitions-enabled & {
        transition: background 70ms ease-in-out, color 70ms ease-in-out;
      }


      &:hover {
        background: rgba(black,.12);
      }


      .savetagstag.mod-hidden > & {
        display: none;
      }



    }


      .savetagstag-circle {
        position: absolute;
        top: 5px;
        left: 6px;

        width: 17px;
        height: 17px;
        border-radius: 3px;

        background: darken(white,12);
        border: 1px solid darken(white,12);

        html.mod-transitions-enabled & {
          transition: opacity 70ms ease-in-out, transform 70ms ease-in-out, background 70ms ease-in-out, border-color 70ms ease-in-out;
        }

        .savetagstag.mod-selected.mod-color-1 > .savetagstag-self > & {background: rgba(lighten($clr-tag-1,20), 1);}
        .savetagstag.mod-selected.mod-color-2 > .savetagstag-self > & {background: rgba($clr-tag-2, 1);}
        .savetagstag.mod-selected.mod-color-3 > .savetagstag-self > & {background: rgba($clr-tag-3, 1);}
        .savetagstag.mod-selected.mod-color-4 > .savetagstag-self > & {background: rgba($clr-tag-4, 1);}
        .savetagstag.mod-selected.mod-color-5 > .savetagstag-self > & {background: rgba($clr-tag-5, 1);}
        .savetagstag.mod-selected.mod-color-6 > .savetagstag-self > & {background: rgba($clr-tag-6, 1);}
        .savetagstag.mod-selected.mod-color-7 > .savetagstag-self > & {background: rgba($clr-tag-7, 1);}
        .savetagstag.mod-selected.mod-color-8 > .savetagstag-self > & {background: rgba($clr-tag-8, 1);}
        .savetagstag.mod-selected.mod-color-9 > .savetagstag-self > & {background: rgba($clr-tag-9, 1);}


        .savetagstag.mod-selected.mod-color-1 > .savetagstag-self > & {border-color: rgba(lighten($clr-tag-1,20), 1);}
        .savetagstag.mod-selected.mod-color-2 > .savetagstag-self > & {border-color: rgba($clr-tag-2, 1);}
        .savetagstag.mod-selected.mod-color-3 > .savetagstag-self > & {border-color: rgba($clr-tag-3, 1);}
        .savetagstag.mod-selected.mod-color-4 > .savetagstag-self > & {border-color: rgba($clr-tag-4, 1);}
        .savetagstag.mod-selected.mod-color-5 > .savetagstag-self > & {border-color: rgba($clr-tag-5, 1);}
        .savetagstag.mod-selected.mod-color-6 > .savetagstag-self > & {border-color: rgba($clr-tag-6, 1);}
        .savetagstag.mod-selected.mod-color-7 > .savetagstag-self > & {border-color: rgba($clr-tag-7, 1);}
        .savetagstag.mod-selected.mod-color-8 > .savetagstag-self > & {border-color: rgba($clr-tag-8, 1);}
        .savetagstag.mod-selected.mod-color-9 > .savetagstag-self > & {border-color: rgba($clr-tag-9, 1);}

        opacity: 1;
        .savetagstag.mod-selected > .savetagstag-self > & {
          opacity: 1;
        }


      }



      .savetagstag-icon {
        position: absolute;
        top: 6px;
        left: 7px;

        & /deep/ svg {
          width: 15px;
          height: 15px;
          path {
            fill: rgba(white,1);
          }
        }

        html.mod-transitions-enabled & {
          transition: opacity 70ms ease-in-out;
        }

        opacity: 0;
        .savetagstag.mod-selected > .savetagstag-self > & {
          opacity: 1;
        }

        //.savetagstag.mod-selected.mod-color-1 > .savetagstag-self > & svg path {fill: rgba(lighten($clr-tag-1,20), 1);}
        //.savetagstag.mod-selected.mod-color-2 > .savetagstag-self > & svg path {fill: rgba($clr-tag-2, 1);}
        //.savetagstag.mod-selected.mod-color-3 > .savetagstag-self > & svg path {fill: rgba($clr-tag-3, 1);}
        //.savetagstag.mod-selected.mod-color-4 > .savetagstag-self > & svg path {fill: rgba($clr-tag-4, 1);}
        //.savetagstag.mod-selected.mod-color-5 > .savetagstag-self > & svg path {fill: rgba($clr-tag-5, 1);}
        //.savetagstag.mod-selected.mod-color-6 > .savetagstag-self > & svg path {fill: rgba($clr-tag-6, 1);}
        //.savetagstag.mod-selected.mod-color-7 > .savetagstag-self > & svg path {fill: rgba($clr-tag-7, 1);}
        //.savetagstag.mod-selected.mod-color-8 > .savetagstag-self > & svg path {fill: rgba($clr-tag-8, 1);}
        //.savetagstag.mod-selected.mod-color-9 > .savetagstag-self > & svg path {fill: rgba($clr-tag-9, 1);}

      }


      .savetagstag-name {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: rgba(black,.5);
        padding: 7px 10px 6px 10px;
        padding-left: 31px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        html.mod-transitions-enabled & {
          transition: background 70ms ease-in-out, color 70ms ease-in-out, padding 70ms ease-in-out;
        }


        .savetagstag.mod-selected > .savetagstag-self > & {
          //color: rgba(white,1);
        }

        .savetagstag-self:hover > & {
          color: rgba(black,.9);
        }

        .savetagstag.mod-selected > .savetagstag-self > & {
          color: rgba(black,.9);
          font-weight: 500;
        }

        //.savetagstag.mod-selected.mod-color-1 > .savetagstag-self > & {color: rgba(lighten($clr-tag-1,20), 1);}
        //.savetagstag.mod-selected.mod-color-2 > .savetagstag-self > & {color: rgba($clr-tag-2, 1);}
        //.savetagstag.mod-selected.mod-color-3 > .savetagstag-self > & {color: rgba($clr-tag-3, 1);}
        //.savetagstag.mod-selected.mod-color-4 > .savetagstag-self > & {color: rgba($clr-tag-4, 1);}
        //.savetagstag.mod-selected.mod-color-5 > .savetagstag-self > & {color: rgba($clr-tag-5, 1);}
        //.savetagstag.mod-selected.mod-color-6 > .savetagstag-self > & {color: rgba($clr-tag-6, 1);}
        //.savetagstag.mod-selected.mod-color-7 > .savetagstag-self > & {color: rgba($clr-tag-7, 1);}
        //.savetagstag.mod-selected.mod-color-8 > .savetagstag-self > & {color: rgba($clr-tag-8, 1);}
        //.savetagstag.mod-selected.mod-color-9 > .savetagstag-self > & {color: rgba($clr-tag-9, 1);}


      }


    .savetagstag-children {
      display: block;
      width: 100%;

      padding-left: 21px;

      padding-bottom: 6px;

      .savetagstag.mod-hidden > & {
        padding-bottom: 0px;
      }

      .savetagstag.mod-filter-enabled > & {
        padding: 0;
      }


      @media (max-width: 500px) {
        padding-left: 13px;
        .savetagstag.mod-filter-enabled > & {
          padding: 0;
        }

      }

    }



















    .savetagstag-new-tag-btn {
      margin-top: 3px;
      margin-left: 3px;
      padding: 5px 5px 4px 6px;
      outline-color: rgba(black,.2);
      appearance: none;

      & /deep/ svg {
        width: 18px;
        height: 18px;
        path {
          fill: rgba(black,.1);
        }
      }


      &:hover {
        & /deep/ svg {
          path {
            fill: rgba(black,.8);
          }
        }
      }


      @media (max-width: 500px) {
        margin-top: 3px;
        margin-left: 0px;
      }



      .savetagstag.mod-filter-enabled > .savetagstag-children > & {
        display: none;
      }

    }




    .savetagstag-new-tag {
      display: block;
      width: 100%;
      position: relative;


      display: inline-block;
      width: 32%;
      width: calc(33.3% - 3px);
      margin-top: 3px;
      margin-left: 3px;

      @media (max-width: 500px) {
        display: block;
        margin-right: 0;
        width: 100%;
        margin-left: 0;
      }


      .savetagstag.mod-filter-enabled > .savetagstag-children > & {
        display: none;
      }



    }


      .savetagstag-new-tag-input {

        appearance: none;
        width: 100%;
        display: block;
        background: rgba(black,.05);
        color: rgba(black,.4);
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
        border-radius: 3px;
        text-align: left;
        outline: none;

        position: relative;
        z-index: 2;
        padding: 6px 12px 5px 12px;
        padding-right: 28px;
        padding-left: 31px;

        &::placeholder {
          color: rgba(black,.5);
        }

        &:focus {
          background: rgba(black,.1);
          color: rgba(black,.9);
          //box-shadow: 0 0 0 2px rgba(black,.10);
        }
      }


      .savetagstag-new-tag-icon {
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        position: absolute;
        pointer-events: none;
        z-index: 3;
        & /deep/ svg {
          width: 18px;
          height: 18px;
          path {
            fill: rgba(black,.4);
          }
        }
      }


      .savetagstag-new-tag-clear {
        top: 50%;
        right: 6px;
        appearance: none;
        transform: translateY(-50%);
        position: absolute;
        outline-color: rgba(black,.2);
        z-index: 4;
        & /deep/ svg {
          width: 16px;
          height: 16px;
          path {
            fill: rgba(black,.5);
          }
        }
      }


      .savetagstag-new-tag-input:focus + .savetagstag-new-tag-icon {
        & /deep/ svg path {
          fill: rgba(black,.9);
        }
      }


      .savetagstag-new-tag-spinner {
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        background: rgba(black,.2);
        border-radius: 4px;

        > div {
          top: 50%;
          left: 6px;
          transform: translateY(-55%);
          position: absolute;
          z-index: 21;
        }

      }




</style>










<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .savetagstag-self {
    background: rgba(white,.07);
    &:hover {
      background: rgba(white,.15);
    }
  }

  .savetagstag-name {
    color: rgba(white,.5);
  }

  .savetagstag.mod-selected > .savetagstag-self > .savetagstag-name {
    //color: rgba(white,1);
  }

  .savetagstag-self:hover > .savetagstag-name {
    color: rgba(white,.9);
  }

  .savetagstag.mod-selected > .savetagstag-self > .savetagstag-name {
    color: rgba(white,.9);
  }



  .savetagstag-circle {
    background: darken(white,55);
    border: 1px solid darken(white,52);
  }

  .savetagstag.mod-selected.mod-color-1 > .savetagstag-self > .savetagstag-circle {background: rgba(lighten($clr-tag-1,20), 1);}
  .savetagstag.mod-selected.mod-color-2 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-2, 1);}
  .savetagstag.mod-selected.mod-color-3 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-3, 1);}
  .savetagstag.mod-selected.mod-color-4 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-4, 1);}
  .savetagstag.mod-selected.mod-color-5 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-5, 1);}
  .savetagstag.mod-selected.mod-color-6 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-6, 1);}
  .savetagstag.mod-selected.mod-color-7 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-7, 1);}
  .savetagstag.mod-selected.mod-color-8 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-8, 1);}
  .savetagstag.mod-selected.mod-color-9 > .savetagstag-self > .savetagstag-circle {background: rgba($clr-tag-9, 1);}

  .savetagstag.mod-selected.mod-color-1 > .savetagstag-self > .savetagstag-circle {border-color: rgba(lighten($clr-tag-1,20), 1);}
  .savetagstag.mod-selected.mod-color-2 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-2, 1);}
  .savetagstag.mod-selected.mod-color-3 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-3, 1);}
  .savetagstag.mod-selected.mod-color-4 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-4, 1);}
  .savetagstag.mod-selected.mod-color-5 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-5, 1);}
  .savetagstag.mod-selected.mod-color-6 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-6, 1);}
  .savetagstag.mod-selected.mod-color-7 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-7, 1);}
  .savetagstag.mod-selected.mod-color-8 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-8, 1);}
  .savetagstag.mod-selected.mod-color-9 > .savetagstag-self > .savetagstag-circle {border-color: rgba($clr-tag-9, 1);}










  .savetagstag-new-tag-btn {
    outline-color: rgba(white,.2);
    & svg {
      path {
        fill: rgba(white,.1);
      }
    }
    &:hover {
      & svg {
        path {
          fill: rgba(white,.8);
        }
      }
    }
  }




  .savetagstag-new-tag {
  }


  .savetagstag-new-tag-input {
    background: rgba(white,.05);
    color: rgba(white,.4);
    &::placeholder {
      color: rgba(white,.5);
    }
    &:focus {
      background: rgba(white,.1);
      color: rgba(white,.9);
    }
  }


  .savetagstag-new-tag-icon svg path {
    fill: rgba(white,.4);
  }


  .savetagstag-new-tag-input:focus + .savetagstag-new-tag-icon svg path  {
    fill: rgba(white,.9);
  }

  .savetagstag-new-tag-clear {
    outline-color: rgba(white,.2);
    & svg path {
      fill: rgba(white,.5);
    }
  }




  .savetagstag-new-tag-spinner {
    background: rgba(white,.2);

  }





































}
</style>
