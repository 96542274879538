<script>
import _clone from 'lodash/clone';
export default {

  data: function(){
    return {
    }
  },

  mounted: function(){
  },

  watch: {
  },

  computed: {
  },

  methods: {
    collapseOrUncollapse: function(){
      this.$store.commit('sidebarCollapse', !this.$store.state.sidebarCollapse);
      this.$store.commit('sidebarVisible', false);
    },
  },

};
</script>


<template>
<button type="button" @click.prevent="collapseOrUncollapse" :class="{'sbar-grip': true, 'mod-sidebar-visible': $store.state.sidebarVisible}"></button>
</template>



<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .sbar-grip {

    position: absolute;
    left: 279px;
    top: 0;
    bottom: 0;

    width: 16px;

    z-index: 58;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: rgba($clr-ggather,0);
    }

    &:hover::before {
      background: rgba($clr-ggather,1);
    }

    @media (max-width: 900px) {
      display: none;
    }


  }

  html.mod-transitions-enabled .sbar-grip::before {
    transition: background 60ms ease-out, width 300ms ease-out;
  }

  html.mod-final-sidebar-collapse .sbar-grip {
    width: 16px;
    &::before {
      width: 8px;
    }
  }

  html.mod-final-sidebar-collapse .sbar-grip.mod-sidebar-visible {
    width: 16px;
    &::before {
      width: 4px;
    }
  }

</style>
<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

}
</style>
