<script>
export default {

  props: [
    'itemSearchHighlight',
    'itemLayout',
  ],

  computed: {
    fieldName: function() {
      var field = this.itemSearchHighlight.field;
      if (field === 'title') return 'Title';
      if (field === 'description') return 'Description';
      if (field === 'urldata_title') return 'Website Title';
      if (field === 'urldata_description') return 'Website Description';
      if (field === 'owner_notes') return 'Notes';
      if (field === 'url') return 'Website URL';
      if (field === 'tag_names') return 'Tags';
    },
  },

}


</script>



<template>
<div
  :class="['itemhighlights', 'mod-item-layout-'+itemLayout]"
>
  <div class="itemhighlights-text">
    <div class="itemhighlights-title">{{fieldName}} found: </div>

    <div v-if="itemSearchHighlight.snippet" class="itemhighlights-snippet">
      {{itemSearchHighlight.snippet}}
    </div>
    <div v-if="itemSearchHighlight.snippets" class="itemhighlights-snippet">
      {{itemSearchHighlight.snippets.join(', ')}}
    </div>
  </div>
</div>
</template>



<style lang="scss">
@import "../../../common/style/_variables.scss";

  .itemhighlights {
    display: block;
    position: relative;
    opacity: 1;
    background: rgba($clr-ggather,.2);
    padding: 3px 5px;
    //border: 1px solid darken( desaturate($clr-ggather, 30) ,10);
    //border-bottom: 1px solid rgba(black,.1);
    position: relative;
    font-size: 0;

    &:first-of-type {
      border-radius: 3px 3px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 3px 3px;
    }

  }

  .itemhighlights-text {
    display: inline-block;
    vertical-align: middle;
    color: $clr-ggather;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .itemhighlights-title {
    display: inline;
    opacity: 1;
    font-weight: 600;
  }

  .itemhighlights-snippet {
    display: inline;
    opacity: 1;

    mark {
      background:  darken( desaturate(#fff0d0, 30) ,10);
    }
  }



</style>



<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .itemhighlights {
    background-color: darken(white,72);
    border-color: darken(white,68);
  }

  .itemhighlights-text {
    color:  $clr-ggather;
  }

}
</style>
