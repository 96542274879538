<script>
export default {

  name: 'settings-profile',

  data: function(){
    return {

      profileImageFile: null,
      coverImageFile: null,

      profileImageUploadProgress: null,
      coverImageUploadProgress: null,

      usernameWarning: false,

      values: {
        email: this.$store.state.user.email,
        username: this.$store.state.user.username,
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
        website: this.$store.state.user.website,
        current_location: this.$store.state.user.current_location,
        bio: this.$store.state.user.bio,
      },


    };
  },

  computed: {


  },

  watch: {
  },

  mounted: function(){
  },

  updated: function(){
  },

  methods: {

    chooseFile: function(kind){
      var fileInput = null;
      (kind=='update-profile-image') ? (fileInput = this.$refs.profileImageInput) : null;
      (kind=='update-cover-image') ? (fileInput = this.$refs.coverImageInput) : null;
      fileInput.onchange = function(){
        this.updateSettings(kind);
      }.bind(this);
      fileInput.click();
    },



    trackUploadProgress: function(kind, progressEvent){

      var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

      if (kind=='update-profile-image') {
        this.profileImageUploadProgress = percentCompleted;
      }

      if (kind=='update-cover-image') {
        this.coverImageUploadProgress = percentCompleted;
      }

    },

    updateSettings: function(kind, fieldName){

      var fieldName = fieldName ? fieldName : null;
      var imageFile = null;
      var value = null;

      this.profileImageUploadProgress = null;
      this.coverImageUploadProgress = null;


      if (kind=='update-profile-image') {
        imageFile = this.$refs.profileImageInput.files[0];
      }

      if (kind=='update-cover-image') {
        imageFile = this.$refs.coverImageInput.files[0];
      }

      if (kind=='delete-profile-image') {
      }

      if (kind=='delete-cover-image') {
      }

      if (kind=='update-field') {
        value = this.values[fieldName];
      }


      var formData = new FormData();
      formData.append('kind', kind);
      imageFile  ? formData.append('imageFile',  imageFile, imageFile.name ) : null;
      fieldName  ? formData.append('fieldName',  fieldName                 ) : null;
      value      ? formData.append('value',      value                     ) : null;

      this.$axios({
        url: this.$store.state.api + '/update-settings/',
        method: 'post',
        data: formData,
        onUploadProgress: function (progressEvent) {
          this.trackUploadProgress(kind, progressEvent);
        }.bind(this),
      }).then(function(response){
        this.$alertify.success('Settings updated.');
        this.$store.commit('updateUser', response.data.user);
        this.profileImageUploadProgress = null;
        this.coverImageUploadProgress = null;
        if (fieldName == 'username') { this.usernameChanged(); }
      }.bind(this), function(error){
        this.$alertify.error('Something went wrong...');
        this.$alertify.alert('Something went wrong while updating settings... Make sure all of your data is correct.');
      }.bind(this));

    },




    usernameChanged: function(){
      this.$alertify.okBtn('Got It');
      this.$alertify.alert('Username changed successfully. You will be now logged out and the page will be refreshed.', function(){
        window.location.reload();
      });
      this.$alertify.reset();
    },



  },

};
</script>


<template>
<div class="settings-account">


  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Primary Email</label>
    <div class="settings-input-control">
      <input id="email" type="email" v-model="values.email" readonly />
      <div>
        <a target="_blank" class="common-button  mod-margin-top-10" :href="$store.state.apiHost + '/account/email/?appuser='+$store.state.user.pk">Change Email Address <iconsvg icon="material-external-link" /></a>
      </div>
    </div>
  </div>


  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Password</label>
    <div class="settings-input-control">
      Use the button below to change your existing password or create new password if you've registered via social connection.
      <div>
        <a target="_blank" class="common-button mod-margin-top-10" :href="$store.state.apiHost + '/account/password/change/?appuser='+$store.state.user.pk">Set or Change Password <iconsvg icon="material-external-link" /></a>
      </div>
    </div>
  </div>


  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Social Connections</label>
    <div class="settings-input-control">
      Use the button below to manage your social connections to Twitter, Facebook or Google.
      <div>
        <a target="_blank" class="common-button mod-margin-top-10" :href="$store.state.apiHost + '/account/social/connections/?appuser='+$store.state.user.pk">Social Connections <iconsvg icon="material-external-link" /></a>
      </div>
    </div>
  </div>

  <div class="settings-input-group">
    <label for="username" class="settings-input-label">Username</label>
    <div class="settings-input-control">
      <input id="username" type="text"  v-model="values.username"  @change="updateSettings('update-field', 'username')" @focus="usernameWarning=1" @blur="usernameWarning=0">
      <div class="settings-input-info mod-warning" v-if="usernameWarning">Warning! Changing your username will also change your public profile URL and all of your public tags URLs. <br><br>After changing username you will be logged out and this page will be refreshed.</div>
    </div>
  </div>

  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Profile Image</label>
    <div class="settings-input-control">

      <img class="profile" :src="$store.state.user.profile_image" alt="">
      <input class="hidden" ref="profileImageInput" type="file">


      <button v-if="profileImageUploadProgress" type="button" class="img-button" click.stop.prevent>
        {{ profileImageUploadProgress }}%
      </button>

      <button v-else type="button" class="img-button" @click="chooseFile('update-profile-image')">
        Upload {{ $store.state.user.profile_image ? 'New' : ''}}
      </button>

      <button type="button" class="img-button"  @click="updateSettings('delete-profile-image')">
        Remove</button>

    </div>
  </div>

  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Cover Image</label>
    <div class="settings-input-control">

      <img class="cover" :src="$store.state.user.cover_image" alt=""  v-if="$store.state.user.cover_image">

      <input class="hidden" ref="coverImageInput" type="file">


      <button v-if="coverImageUploadProgress" type="button" class="img-button" click.stop.prevent>
        {{ coverImageUploadProgress }}%
      </button>

      <button v-else type="button" class="img-button" @click="chooseFile('update-cover-image')">
        Upload {{ $store.state.user.cover_image ? 'New' : ''}}</button>

      <button v-if="$store.state.user.cover_image" type="button" class="img-button" @click="updateSettings('delete-cover-image')">
        Remove</button>

    </div>
  </div>

  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Description</label>
    <div class="settings-input-control">
      <textarea id="bio" v-model="values.bio" rows="4" @change="updateSettings('update-field', 'bio')"></textarea>
    </div>
  </div>

  <div class="settings-input-group">
    <label for="first_name" class="settings-input-label">First Name</label>
    <div class="settings-input-control">
      <input id="first_name" type="text" v-model="values.first_name" @change="updateSettings('update-field', 'first_name')">
    </div>
  </div>

  <div class="settings-input-group">
    <label for="last_name" class="settings-input-label">Last Name</label>
    <div class="settings-input-control">
      <input id="last_name" type="text" v-model="values.last_name" @change="updateSettings('update-field', 'last_name')">
    </div>
  </div>


  <div class="settings-input-group">
    <label for="location" class="settings-input-label">Current Location</label>
    <div class="settings-input-control">
      <input id="location" type="text" v-model="values.current_location" @change="updateSettings('update-field', 'current_location')">
    </div>
  </div>

  <div class="settings-input-group">
    <label for="website" class="settings-input-label">Website URL</label>
    <div class="settings-input-control">
      <input id="website" type="url" v-model="values.website" @change="updateSettings('update-field', 'website')">
    </div>
  </div>

  <!--
  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Gender</label>
    <div class="settings-input-control">
      <textarea id="bio" :value="$store.state.user.gender" rows="4"></textarea>
    </div>
  </div>-->

  <!--
  <div class="settings-input-group">
    <label for="bio" class="settings-input-label">Date of Birth</label>
    <div class="settings-input-control">
      <textarea id="bio" :value="$store.state.user.bio" rows="4"></textarea>
    </div>
  </div>-->


</div>
</template>



<style lang="scss" scoped>

</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


}
</style>
