<script>
export default {

  name: 'settings-exp',

  data: function(){
    return {


    };
  },

  computed: {

    sidebarCollapse: {
      get: function(){
        return Boolean(this.$store.state.sidebarCollapse);
      },
      set: function(value) {
        this.$store.commit('sidebarCollapse', Boolean(value));
      },
    },


    itemClickAction: {
      get: function(){
        return this.$store.state.itemClickAction;
      },
      set: function(value) {
        this.$store.commit('itemClickAction', value);
      },
    },


    topbarUseTagColor: {
      get: function(){
        return Boolean(this.$store.state.topbarUseTagColor);
      },
      set: function(value) {
        this.$store.commit('topbarUseTagColor', Boolean(value));
      },
    },

    transitionsEnabled: {
      get: function(){
        return Boolean(this.$store.state.transitionsEnabled);
      },
      set: function(value) {
        this.$store.commit('transitionsEnabled', Boolean(value));
      },
    },

    theme: {
      get: function(){
        return this.$store.state.appTheme;
      },
      set: function(value) {
        this.$store.commit('appTheme', value);
        this.reloadPageIfEdge();
      },
    },


  },

  watch: {
  },

  mounted: function(){
  },

  updated: function(){
  },

  methods: {

    reloadPageIfEdge: function(){
      // ms edge has problems painting/updating styles when changing theme... it's an edge case... lol
      var isEdge = window.navigator.userAgent.indexOf('Edge/') > -1;
      if (isEdge) window.location.reload();
    },


  },

};
</script>


<template>
  <div class="settings-exp">


  <div class="settings-input-group">
    <label for="exp5" class="settings-input-label">Application Theme</label>
    <div class="settings-input-control">

      <select id="exp5" v-model="theme">
        <option value="default">Light</option>
        <option value="dark">Dark</option>
      </select>

    </div>
  </div>


  <!--
  <div class="settings-input-group">
    <label for="exp4" class="settings-input-label">Topbar in Color of Tag</label>
    <div class="settings-input-control">

      <select id="exp4" v-model="topbarUseTagColor">
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>

    </div>
  </div>
   -->







  <div class="settings-input-group">
    <label for="exp1" class="settings-input-label">Sidebar Behavior</label>
    <div class="settings-input-control">

      <select id="exp1" v-model="sidebarCollapse">
        <option :value="false">Stickied (desktop only)</option>
        <option :value="true">Collapsible</option>
      </select>

    </div>
  </div>



  <div class="settings-input-group">
    <label for="settingtransitionsenabled" class="settings-input-label">Interface Transitions</label>
    <div class="settings-input-control">

      <select id="settingtransitionsenabled" v-model="transitionsEnabled">
        <option :value="true">Smooth</option>
        <option :value="false">Instant (most transitions disabled)</option>
      </select>

    </div>
  </div>



  <div class="settings-input-group">
    <label for="exp2" class="settings-input-label">Link Click Behavior <!--  <sup class="settings-badge-new">new</sup>--></label>
    <div class="settings-input-control">

      <select id="exp2" v-model="itemClickAction">
        <option value="viewer">Open in GGather Viewer</option>
        <option value="native">Open in new tab</option>
      </select>

    </div>
  </div>





</div>
</template>



<style lang="scss" scoped>

</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


}
</style>
