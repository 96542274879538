<script>
export default {

  name: 'page-blank',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },

};
</script>

<template>
</template>

<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

</style>
