<script>
export default {

  props: [

    'item',
    'context',
    'itemLayout',

    'isHighlighted',
    'selected',
    'unseen',
    'tagsPopoverOpen',
    'noTags',
    'followedTagsFirst',

    'title',
    'description',
    'titleThumbSlice',
    'thumbnail',
    'thumbnailHeight',
    'thumbnailWidth',
    'thumbnailColor',

    'selectOverlayClick',
    'linkClick',
    'linkStandardClick',

    'itemThumbLoad',

  ],

}
</script>



<template>
<div
  class="item item-panel"
  :class="['mod-'+itemLayout, { 'mod-selected': selected}]"
  >


  <div v-if="unseen" class="item-unseen-notification"   v-tooltip="'New since your last visit. <br>Added ' + $dfFormatDistanceFromNow(item.date_created) + '.'"></div>

  <button v-if="$store.state.itemsSelectMode" type="button" class="item-select-overlay" @click="selectOverlayClick"></button>


  <div class="item-panel-inner">



    <!-- Foreground with Controls -->
    <div class="item-panel-foreground-with-controls">


      <!--
      <div class="item-search-highlights" v-if="item.search_highlights && item.search_highlights.length">
        <itemhighlights
          v-for="highlight in item.search_highlights"
          :key="highlight.field"
          :item-search-highlight="highlight"
        />
      </div>
      -->

      <itemnav

        :item="item"
        :context="context"
        :item-layout="itemLayout"

        :selected="selected"
        :tags-popover-open="tagsPopoverOpen"
        :no-tags="noTags"

      />




      <!-- Foreground -->
      <a :href="item.url" :data-direct-url="item.url" :data-redirect-url="item.redirect_url" class="item-panel-foreground" rel="noopener noreferrer nofollow" target="_blank" @click="linkStandardClick"  @mouseup="linkClick">

        <div class="item-panel-thumb">

          <div class="item-panel-thumb-img" v-if="thumbnail"
          :style="{ backgroundImage: 'url(' + thumbnail + ')' }">
          </div>

          <div class="item-panel-thumb-img" v-if="item.image && !thumbnail"
          :style="{ backgroundImage: 'url(' + item.image + ')' }">
          </div>

          <div class="item-panel-thumb-bg" v-if="!item.image && !thumbnail"
          :style="{ backgroundColor: thumbnailColor }">
            <div>{{titleThumbSlice}}</div>
          </div>-

        </div>

        <div class="item-panel-favicon">
          <img :src="['https://s2.googleusercontent.com/s2/favicons?domain_url=' + item.url]" />
        </div>


        <div class="item-panel-content">

          <h3 class="item-panel-title" :title="title">{{title}}</h3>
          <p class="item-panel-description" :title="description">{{description}}</p>

        </div>



        <div class="item-panel-bottom">

          <div class="item-panel-bottom-section  is-first">



            <div class="item-panel-rating" v-if="item.rating">
              <itemrate
                :item-layout="itemLayout"
                :item-rating="item.rating"
              />
            </div>


            <div class="item-panel-notes" v-if="item.owner_notes">
              <itemnote
                :item-layout="itemLayout"
                :item-owner-username="item.owner.username"
                :item-owner-profile-image="item.owner.profile_image"
                :item-owner-notes="item.owner_notes"
              />
            </div>




          </div>

          <div class="item-panel-bottom-section  is-second">


            <!-- Date -->
            <!--
              <div class="item-panel-bottom-date-added  is-title-case" :title="$dfFormatC(item.date_created)">
                {{item.date_created|dfFormatDistanceFromNow}}
              </div>
            -->

          </div>

        </div>







      </a>
      <!-- / Foreground -->


    </div>
    <!-- / Foreground with Controls -->




  </div>
</div>
</template>































<style lang="scss">

  @import "../../../common/style/_variables.scss";


  $clr-light-green-600: green;
  $clr-red: red;
  $clr-grey-200: grey;
  $clr-light-blue-400: blue;
  $clr-orange: orange;


  /*------------------------------------*\
    Layout - Panel
  \*------------------------------------*/

  .item-panel {
    display: block;
    position: relative;
    backface-visibility: hidden;

    display: block;
    //max-width: 300px;
    width: auto;
    //margin-right: 20px;
    text-align: left;


    &:hover {
      z-index: 5;
    }

  }


    .item-panel-inner {
      background-color: white;
      border: 1px solid #eee;
      display: block;
      position: relative;
      font-size: 0;
      z-index: 2;

      backface-visibility: hidden;


      border-radius: 6px;

      border-top:    1px solid darken(white,7);
      border-right:  1px solid darken(white,8);
      border-left:   1px solid darken(white,8);
      border-bottom: 1px solid darken(white,10);

      .item-panel:focus &,
      .item-panel:hover & {
        //box-shadow: 0 0 10px 0 rgba(black,.07);
      }

    }





    .item-panel-foreground-with-controls {
      position: relative;
      //overflow: hidden;
    }







    .item-panel-foreground {
      position: relative;
      display: block;
      font-size: 0;
      z-index: 2;
      backface-visibility: hidden;
      //@include clearfix;

      &:focus {
        outline: none;
      }

    }


      .item-panel-thumb {
        width: 100px;
        height: 70px;
        display: inline-block;
        //border: 1px solid #eee;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        margin: 5px;
        margin-right: 0;
        user-select: none;
        opacity: 1;
        //background-color: $clr-grey-200;

        position: absolute;
        width: 100px;
        height: 71px;
        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }
        .item-panel:hover & {
          opacity: 1;
        }

        @media (max-width: 700px) {
          width: 60px;
        }

      }


        .item-panel-thumb-img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 2;
          background-position: 50%;
          background-size: cover;
          background-color: darken(white,3);
          border-radius: 1px;
        }

        .item-panel-thumb-bg {
          //background-color: $clr-grey-600;
          //background-color: $clr-grey-900;
          //background-color: $clr-light-blue-400;

          background: darken(white,3);
          background-size: 100%;
          background-position: 50% 50%;
          background-image: linear-gradient(145deg, rgba(white,.1), rgba(black,.12));

          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          border-radius: 1px;

          div:nth-of-type(1) {
            position: absolute;
            z-index: 3;
            top: 38%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 25px;
            line-height: 0;
            font-weight: 600;
            white-space: nowrap;
            color: rgba(white,.7);
            text-transform: uppercase;

            top: 49%;
          }

          div:nth-of-type(2) {
            position: absolute;
            z-index: 2;
            bottom: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 10px;
            font-weight: 400;
            white-space: nowrap;
            color: rgba(white,.5);
            display: block;
            display: none;

            line-height: 1.4;
            width: 85%;
            text-align: center;
            overflow: hidden;


          }


        }

      .item-panel-favicon {
        position: absolute;
        z-index: 5;
        top: 5px;
        left: 85px;
        border: 4px solid white;
        background-color: white;
        border-radius: 0 0 0 2px;

        img {
          width: 16px;
          height: 16px;
        }

        @media (max-width: 700px) {
          left: 45px;
        }

        display: none;
        // OUT OF SCOPE SELECTOR
        .items.mod-show-favicon & {
          display: inline-block;
        }

      }


      .item-panel-content {
        display: inline-block;
        //width: calc(100% - 120px);
        width: 100%;
        padding: 8px 10px 1px 115px;


        @media (max-width: 700px) {
          padding-left: 70px;
        }
      }

        .item-panel-title {
          font-size: 15px;
          font-weight: 500;
          overflow: hidden;
          height: 20px;
          color: #111;

          @media (max-width: 700px) {
            font-size: 13px;
          }


        }

        .item-panel-description {
          font-size: 13px;
          color: #808080;
          padding-top: 1px;
          font-weight: 400;
          overflow: hidden;
          line-height: 1.2;
          height: 17px;
          margin-bottom: 4px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }






















  /*------------------------------------*\
    bottom
  \*------------------------------------*/







  .item-panel-bottom {
    //border-top: 1px solid #eee;
    padding: 1px 5px 0px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    min-height: 31px;
    color: #aaa;
    font-weight: 400;
    overflow: hidden;
    margin-left: 105px;

    @media (max-width: 700px) {
      margin-left: 60px;
    }

  }




    .item-panel-bottom-section {
      width: 100%;
      white-space: nowrap;
      position: relative;
      padding-top: 1px;

      &.is-tags {
        // referenced later
      }
    }




      .item-panel-rating {
        display: inline-block;
        margin-right: 4px;
      }

      .item-panel-notes {
        display: inline-block;
        max-width: 80%;
        max-width: calc(100% - 90px);
      }

      .item-search-highlights {
        padding: 5px 5px 0 5px;
      }





      .item-panel-bottom-small-btns {
        display: inline-block;
        position: absolute;
        top: 6px;
        right: 5px;
        z-index: 5;
        background-color: white;
        opacity: 0;
        padding-right: 1px;
        padding-left: 1px;
        //box-shadow: 5px 0 5px 0 rgba(white,1);

        /* WARNING: .ITEM HOVER */
        .item-panel:hover & {
          opacity: 1;
        }
      }



      .item-panel-bottom-date-added {
        color: #aaa;
        opacity: .7;
        margin-right: 4px;
        margin-left: 3px;
        font-weight: 400;
        font-size: 12px;
        padding-top: 2px;
        margin-top: 7px;


        .icon {
          margin-right: 3px;
          font-size: 13px;

          &::before {
            line-height: 1.2;
          }
        }

        &.is-title-case {
          text-transform: capitalize;
        }

      }





      .item-panel-bottom-section.is-second {
        //width: 100px;
        float: right;
        //max-width: 100px;
        text-align: right;
      }


      .item-panel-bottom-tags-outer {
        display: inline-block;
        //position: relative;
        overflow: hidden;
        //width: 42px;
        //margin-right: 5px;
      }


      .item-panel-bottom-tags-btn {
        //position: absolute;
        display: block;
        width: 100%;
        padding: 1px 8px 1px 8px;
        margin-top: 6px;
        font-size: 12px;
        font-weight: 500;
        //top: 7px;
        //left: 0;
        //right: 0;
        //bottom: 0;
        z-index: 3;
        opacity: .7;
        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out, background-color 100ms ease-out, border-color 100ms ease-out;
        }



        background-color: rgba(#eee,.5);
        border: 1px solid rgba(darken(#eee,3),.5);


        color: #555;
        font-weight: 500;

        border-radius: 2px;
        outline: none;


        /* WARNING: .ITEM HOVER */
        .item-panel:hover & {
          opacity: .7;


          background-color: rgba(#eee,1);
          border-color: rgba(darken(#eee,3),1);

          &:hover {
            opacity: 1;
          }

        }

        &:focus {
          opacity: 1;
        }


        .icon {
          margin-right: 3px;
          pointer-events: none;
          &::before {
            line-height: 1.4;
          }
        }


        &.mod-no-tags.mod-no-tags.mod-no-tags {
          opacity: .7;
          pointer-events: none;
        }
      }











  .item-panel-bottom-small-btn {
    display: inline-block;
    //width: 100%;
    padding: 1px 6px 1px 5px;
    margin-top: 0px;
    margin-left: 5px;

    font-size: 12px;
    font-weight: 500;
    z-index: 3;
    opacity: .0;
    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-out, background-color 100ms ease-out, border-color 100ms ease-out;
    }


    background-color: transparent;
    border: none;
    //background-color: rgba(#eee,.5);
    border: 1px solid rgba(darken(#eee,3),.1);

    color: #555;
    font-weight: 500;

    border-radius: 2px;
    outline: none;


    /* WARNING: .ITEM HOVER */
    .item-panel:hover & {
      opacity: .7;

      background-color: rgba(#eee,1);
      border-color: rgba(darken(#eee,3),1);


      &:hover {
        opacity: 1;
      }

    }

    &:focus {
    }


    .icon {
      margin-right: 3px;
      pointer-events: none;

      &::before {
        line-height: 1.4;
      }
    }


  }




  .item-panel-bottom-resave-btn-resaved {
    display: block;
    width: 100%;
    padding: 1px 6px 1px 5px;
    font-size: 12px;
    font-weight: 500;
    z-index: 3;
    opacity: .6;
    background-color: rgba(#333,1);
    border: 1px solid rgba(darken(#333,5),1);
    color: #eee;
    font-weight: 500;
    border-radius: 2px;
    outline: none;
    margin-top: 6px;

    .icon {
      margin-right: 3px;
      pointer-events: none;

    }

  }












</style>











<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .item-panel-inner {
    background-color: darken(white,83);
    border-top-color: darken(white,79);
    border-right-color: darken(white,78);
    border-left-color: darken(white,78);
    border-bottom-color: darken(white,78);
  }


  .item-panel-title {
    color: rgba(white,.6);
  }

  .item-panel-description {
    color: rgba(white,.3);
  }

  .item-panel-favicon {
    background-color: lighten($clr-theme-dark,11);
    border-color: lighten($clr-theme-dark,11);
  }

  .item-panel-thumb-img {
    background-color: darken(white,80);
  }


  .item-panel-thumb-bg {
    background-color: darken(white,79);
  }
  .item-panel-thumb-bg div:nth-of-type(1) {
    //font-weight: 600;
    //color: rgba(white,.5);
  }



}
</style>
