import alertify from 'alertify.js';

var VueAlertify = {
  install: function(Vue, options){

    alertify.parent(document.body);
    alertify.maxLogItems(10);
    alertify.closeLogOnClick(true);
    alertify.logPosition('bottom right');

    Vue.alertify = alertify;
    Vue.prototype.$alertify = alertify;

  },
};

export default VueAlertify;
