<script>
export default {

  name: 'settings-billing',

  data: function(){
    return {


      showBraintreeBillingData: false,

      braintreeCustomer: null,
      braintreeCustomerLoading: false,
      braintreeCustomerSuccess: false,
      braintreeCustomerPaidThrough: null,


      userBillingData: null,
      userBillingDataLoading: null,

    };
  },

  computed: {



    paddlePaidTroughInPast: function(){
      if (!this.$store.state.user.paddle_sub_paid_through) return false;
      return new Date(this.$store.state.user.paddle_sub_paid_through) < new Date();
    },

    braintreePaidThroughInPast: function(){
      if (!this.$store.state.user.premium_paid_through) return false;
      return new Date(this.$store.state.user.premium_paid_through) < new Date();
    },

    showUpdateAndCancelControl: function(){

      // if user past_due
      if (this.$store.state.user.paddle_sub_status === 'past_due') return true;

      if (!this.paddlePaidTroughInPast) {
        return this.$store.state.user.paddle_sub_status==='past_due' || this.$store.state.user.paddle_sub_status==='active';
      }
      return false;
    },

    showNextPaymentDate: function(){
      if (!this.paddlePaidTroughInPast) {
        return this.$store.state.user.paddle_sub_status==='active';
      }
      return false;
    },

    showPricingStatus: function(){
      return this.$store.state.user.premium_paid_through || this.$store.state.user.paddle_sub_paid_through;
    },

    showBillingControls: function(){
      return this.$store.state.user.premium_paid_through || this.$store.state.user.paddle_sub_paid_through;
    },

    showPricingMgmt: function(){
      if (!this.$store.state.user.mgmt_premium_until) return false;
      return new Date(this.$store.state.user.mgmt_premium_until) > new Date();
    },

    showUpgradeButton: function(){
      //if (this.$store.state.user.paddle_sub_paid_through && this.paddlePaidTroughInPast) return true;
      return !this.$store.state.user.premium_paid_through && !this.$store.state.user.paddle_sub_paid_through;
    },


    readableNextPaymentDate: function(){
      if (!this.$store.state.user.paddle_sub_paid_through) return 'none';
      return this.$dfFormatD(this.$store.state.user.paddle_sub_paid_through);
    },

  },

  watch: {
  },

  mounted: function(){
    this.fetchBillingData();
  },

  updated: function(){
  },

  methods: {

    oldBillingDataClick: function(){
      if (this.showBraintreeBillingData) {
        this.showBraintreeBillingData = false;
        return;
      }
      this.showBraintreeBillingData = true;
      this.tryGetBraintreeCustomer();
    },





    tryGetBraintreeCustomer: function(){
      this.braintreeCustomer = null;
      this.braintreeCustomerLoading = false;
      this.braintreeCustomerSuccess = false;
      this.braintreeCustomerPaidThrough = null;
      this.fetchBraintreeCustomer();
    },


    fetchBraintreeCustomer: function(){
      this.braintreeCustomerLoading = true;
      this.$axios({
        url: this.$store.state.api + '/bt-customer/',
        method: 'get',
      }).then(function(response){
        this.braintreeCustomerLoading = false;
        this.braintreeCustomerSuccess = true;
        this.braintreeCustomer = response.data.customer;
        this.braintreeCustomerPaidThrough = response.data.paid_through;
      }.bind(this), function(error){
      }.bind(this));
    },


    fetchBillingData: function(){
      this.userBillingData = null;
      this.userBillingDataLoading = true;
      this.$axios({
        url: this.$store.state.api + '/user-billing-data/',
        method: 'get',
      }).then(function(response){
        this.userBillingDataLoading = false;
        this.userBillingData = response.data.billing_data;
      }.bind(this), function(error){
        this.userBillingDataLoading = false;
      }.bind(this));
    },







  },

};
</script>


<template>
<div class='settings-billing'>
  <div class="settings-billing-content" >


    <pricingmgmt v-if="showPricingMgmt" context="settings" />


    <div v-if="showPricingStatus" class="settings-billing-pricing-status-wrapper">
      <pricingstatus context="settings" />
    </div>



    <div v-if="showUpgradeButton" class="settings-billing-controls-wrapper mod-upgrade-only">
      <router-link to="/upgrade" class="settings-billing-control mod-upgrade">
        Upgrade to GGather Plus
      </router-link>
    </div>


    <div v-if="showBillingControls" class="settings-billing-controls-wrapper">


      <div v-if="userBillingData && $store.state.user.paddle_sub_status">



        <div v-if="showNextPaymentDate" class="settings-billing-control mod-detail mod-first">
          Next payment date is on {{readableNextPaymentDate}}.
        </div>

        <div v-if="$store.state.user.paddle_sub_status==='past_due'" class="settings-billing-control mod-detail mod-first">
          Your subscription payment is past due. Please ensure your current payment method can be correctly charged. 3 charge retry attempts will be made. If they fail your subscription will be automatically canceled. You can also update your payment method.
        </div>

        <a v-if="showUpdateAndCancelControl" class="settings-billing-control mod-edit" :href="userBillingData.paddle_sub_extra_update_url" target="_blank" rel="noopener noreferrer">
          Update payment method
        </a>

        <a v-if="showUpdateAndCancelControl" class="settings-billing-control mod-cancel mod-last" :href="userBillingData.paddle_sub_extra_cancel_url" target="_blank" rel="noopener noreferrer">
          Cancel subscription
        </a>

        <div v-if="$store.state.user.paddle_sub_status==='deleted'" class="settings-billing-control mod-detail mod-last">
          Subscription expired or canceled.
        </div>

      </div>

      <div v-if="userBillingDataLoading">

        <div class="settings-billing-control mod-loading">
          Loading details &amp; controls...
        </div>

      </div>

    </div>





    <div class="settings-billing-box mod-welcome">
      <!--  <h3 class="settings-billing-box-title">Billing &amp; Payments</h3>-->
      <!-- <p class="settings-billing-box-line mod-first">Here you can see and manage here your GGather Plus subscription.</p>-->

      <p class="settings-billing-box-line mod-icon">
        <iconsvg icon="material-help" /> If you have any problems/requests/questions you can use contact form on <router-link to="/contact">ggather.com/contact</router-link>.</p>

        <!--
      <p class="settings-billing-box-line mod-icon">
        <iconsvg icon="material-help" /> If you want to change your payment method or change billing cycle you will need to first cancel current subscription and then resubscribe on <router-link to="/upgrade">ggather.com/upgrade</router-link> with new information.</p>

      <p class="settings-billing-box-line mod-icon">
        <iconsvg icon="material-help" />You won't lose any remaining time you have on GGather Plus when canceling or resubscribing. Canceling subscription means that your GGather Plus simply won't be automatically extended in next billing cycles. And in case of resubscribing - billing for new subscriptions will always start after the end of any active current subscription. </p>
      -->
    </div>










    <!-- only for previous braintree customers -->
    <div v-if="$store.state.user.premium_paid_through">


      <hr>


      <!--
      <div v-if="braintreeCustomerPaidThrough"  key="bcpaidthrough">
        <hr>
        <div class="settings-billing-box">

          <p class="settings-billing-box-line">
            Your GGather Plus account is
            <span v-if="$store.state.user.is_premium" class="green-bold"> active</span><span v-else class="red-bold"> inactive</span>.
          </p>

          <p class="settings-billing-box-line">
            Your GGather Plus account is paid through
            <span> {{ new Date(braintreeCustomerPaidThrough).toString() }}</span>.
          </p>


        </div>
      </div> -->



      <button type="button" @click.prevent="oldBillingDataClick"  class="settings-billing-show-history-btn" :disabled="braintreeCustomerLoading" >
        <span v-if="showBraintreeBillingData">Hide</span><span v-else>Show</span> previous payment processor (braintreepayments.com) billing history
      </button>







      <div  v-if="showBraintreeBillingData">



        <span v-if="braintreeCustomerLoading" key="bcloading">
          <hr>
          <spinner kind="dots" dark="true"></spinner> &nbsp;&nbsp; Loading previous payment processor billing history. This might take some time.
        </span>


        <span v-else-if="!braintreeCustomer" key="bcno">
          <hr>
          No previous subscriptions &amp; payments history.
        </span>


        <span v-else key="bcyes"  class="settings-billing-content-lists">

          <hr>

          <div v-if="braintreeCustomer.subscriptions">
            <h4>Subscriptions History</h4>
            <p v-if="!braintreeCustomer.subscriptions.length">No Subscriptions.</p>
            <ul>
              <li class="settings-billing-plain-list-item" v-for="(sub, index) in this.braintreeCustomer.subscriptions" :key="index">

                Subscription {{sub.plan_name}}
                <span>Status: {{sub.status}}</span>
                <span>Created: {{sub.created_at}}</span>
                <span>Current Billing Cycle: {{sub.current_billing_cycle}}</span>
                <span>Paid Through: {{sub.paid_through_date}}</span>
                <span>First Billing Date: {{sub.first_billing_date}}</span>

                <span key="ifA" v-if="(sub.status.toLowerCase()!=='canceled')">Next Billing Date: {{sub.next_billing_date}}</span>
                <span key="ifB" v-if="(sub.status.toLowerCase()!=='canceled')">Next Billing Amount: {{sub.next_bill_amount}}</span>


              </li>
            </ul>
          </div>







          <!--

          <hr>



          <div v-if="braintreeCustomer.credit_cards">
            <h4>My Payment Methods (Credit Cards)</h4>
            <p v-if="!braintreeCustomer.credit_cards.length">No Credit Cards.</p>
            <ul>
              <li v-for="(ccard, index) in this.braintreeCustomer.credit_cards" :key="index">
                <div>
                  <pmethod
                    type="card"
                    :defaultmethod="ccard.default ? 'Yes' : 'No'"
                    :subtype="ccard.card_type"
                    :added="new Date(ccard.created_at).toLocaleDateString('uk')"
                    :last4="ccard.last_4"
                    :expired="ccard.expired ? 'Yes' : 'No'"
                  />
                </div>
              </li>
            </ul>
          </div>


          <div v-if="braintreeCustomer.credit_cards">
            <h4>My Payment Methods (Credit Cards)</h4>
            <p v-if="!braintreeCustomer.credit_cards.length">No Credit Cards.</p>
            <ul>
              <li v-for="(ccard, index) in this.braintreeCustomer.credit_cards" :key="index">
                Credit Card
                <span>Default Payment Method: {{ccard.default ? 'Yes' : 'No'}}</span>
                <span>Created: {{ccard.created_at}}</span>
                <span>Type: {{ccard.card_type}}</span>
                <span>Expired: {{ccard.expired ? 'Yes' : 'No'}}</span>
                <span>Last Digits: &bull;&bull;{{ccard.last_4.slice(-2)}}</span>
              </li>
            </ul>
          </div>


          <div v-if="braintreeCustomer.paypal_accounts">
            <h4>My Payment Methods (PayPal Accounts)</h4>
            <p v-if="!braintreeCustomer.paypal_accounts.length">No PayPal Accounts.</p>
            <ul>
              <li v-for="(ppacc, index) in this.braintreeCustomer.paypal_accounts" :key="index">
                <div>
                  <pmethod
                    type="paypal"
                    :defaultmethod="ppacc.default ? 'Yes' : 'No'"
                    :added="new Date(ppacc.created_at).toLocaleDateString('uk')"
                    :email="ppacc.email"
                  />
                </div>
              </li>
            </ul>
          </div>


          <div v-if="braintreeCustomer.paypal_accounts">
            <h4>My Payment Methods (PayPal Accounts)</h4>
            <p v-if="!braintreeCustomer.paypal_accounts.length">No PayPal Accounts.</p>
            <ul>
              <li v-for="(ppacc, index) in this.braintreeCustomer.paypal_accounts" :key="index">
                PayPal Account
                <span>Default Payment Method: {{ppacc.default ? 'Yes' : 'No'}}</span>
                <span>Created: {{ppacc.created_at}}</span>
                <span>Email: {{ppacc.email}}</span>
              </li>
            </ul>
          </div>

          -->




          <hr>

          <div v-if="braintreeCustomer.transactions">
            <h4>Transactions History</h4>
            <p v-if="!braintreeCustomer.transactions.length">No Transactions.</p>
            <ul>
              <li class="settings-billing-plain-list-item"  v-for="(trans, index) in this.braintreeCustomer.transactions" :key="index">

                Transaction ({{trans.payment_instrument_type}})
                <span>Status: {{trans.status}}</span>
                <span>Created: {{trans.created_at}}</span>
                <span>Amount: {{trans.amount}} {{trans.currency_iso_code}}</span>
                <span>Paid for Subscription: {{trans.plan_name ? trans.plan_name : 'No'}}</span>
                <span v-if="trans.credit_card_details && trans.credit_card_details.last_4">Credit Card Last Digits: &bull;&bull;{{trans.credit_card_details.last_4.slice(-2)}}</span>
                <span v-if="trans.paypal_details && trans.paypal_details.payer_email">PayPal Email: {{trans.paypal_details.payer_email}}</span>
              </li>
            </ul>
          </div>


        </span>



      </div>

    </div>






  </div>















</div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .settings-billing {
    font-size: 15px;
  }





















    .settings-billing-pricing-status-wrapper {
      padding: 0;
    }







    .settings-billing-content {
      font-size: 15px;

      hr {
        background: rgba(black,.05);
        margin: 20px 0;
        width: 100%;
        height: 1px;
        display: block;
      }
    }


    .settings-billing-box {
      background: darken(white,5);
      border-radius: 4px;
      padding: 20px 20px;
      margin-top: 20px;
      padding-top: 10px;

      &.mod-success {
        background: rgba($clr-green-100, .5);
      }

      &.mod-error {
        background: rgba($clr-red-100, .5);
      }

      &.mod-welcome {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }


    .settings-billing-box-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      color: rgba(black,.9);

    }

    .settings-billing-box-line {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      color: rgba(black,.85);
      margin-top: 10px;

      &.mod-first {
        font-weight: 400;
        margin-top: 10px;
      }


      &.mod-icon {
        position: relative;
        padding-left: 22px;
      }


      svg {
        display: inline-block;
        vertical-align: baseline;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 1px;
        left: 0;
        fill: rgba(black,.8);

      }

      a {
        text-decoration: underline;
      }

      .green-bold {
        color: green;
        font-weight: 600;
      }

      .red-bold {
        color: red;
        font-weight: 600;
      }

    }




    .settings-billing-controls-wrapper {
      margin-top: 15px;

      &.mod-upgrade-only {
        margin-top: 0px;
      }
    }


    .settings-billing-control {
      display: block;
      background: darken(white,5);
      border: 1px solid darken(white,5);
      border-bottom: 0;
      color: darken(white, 90);
      padding: 15px 20px;
      text-align: left;
      font-size: 15px;
      font-weight: 500;
      border-radius: 0px;
      width: 100%;
      text-align: left;
      margin-top: 0px;
      transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;

      &:hover {
        background: darken(white,7);
        //box-shadow: 0 1px 6px 0px rgba(black,.2);
      }

      &.mod-detail {
        background: white;
      }

      &.mod-loading {
        background: white;
        border: 1px solid darken(white,5);
        border-radius: 4px;
      }

      &.mod-upgrade {
        border-radius: 4px;
      }

      &.mod-first {
        border-radius: 4px 4px 0 0;
      }

      &.mod-last {
        border-radius: 0 0 4px 4px;
        border: 1px solid darken(white,5);
      }

    }

    .settings-billing-show-history-btn {
      display: block;
      background: $clr-ggather;
      color: rgba(white,10);
      padding: 10px 20px;
      text-align: left;
      font-size: 15px;
      font-weight: 500;
      border-radius: 3px;
      width: 100%;
      text-align: center;
    }




    .settings-billing-content-lists {

      > div {
        margin-top: 30px;
        margin-bottom: 20px;
        &:first-of-type {
          margin-top: 15px;
        }

        h4 {
          font-size: 18px;
          font-weight: 500;
          margin-left: 1px;
        }

        p {
          font-size: 15px;
          color: rgba(black,.6);
        }

        ul {
          margin-bottom: 30px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        ul, li {
          list-style-type: none;
        }

        li {

        }



      }

    }

    .settings-billing-plain-list-item {
      font-size: 15px;
      background: white;
      background: rgba($clr-ggather,.05);
      border: 1px solid rgba($clr-ggather,.1);
      border-left-width: 2px;
      border-left-color: rgba($clr-ggather,.5);
      margin: 15px 0;
      border-radius: 8px;
      padding: 10px 15px;
      color: rgba(darken($clr-ggather,25),.9);
      font-weight: 500;

      span {
        display: block;
        font-size: 14px;
        color: rgba(darken($clr-ggather,20),.6);
        font-weight: 400;

      }
    }


    .settings-billing-cancel-btn {
      border: 0;
      box-shadow: 0;
      appearance: none;
      background: none;
      background: rgba($clr-ggather,.8);
      padding: 2px 6px;
      color: rgba(white,.9);
      border-radius: 3px;
      margin-top: 5px;

      &.mod-white {
        background: white;
        color: rgba($clr-ggather,.8);
      }

      &.mod-red {
        background: $clr-red-500;
      }

    }



    .settings-billing-cancel-dialog {
      background: rgba($clr-ggather,.8);
      color: rgba(white,.9);
      padding: 5px 15px;
      border-radius: 5px;
      max-width: 420px;
      margin-top: 5px;
      padding-bottom: 15px;
      position: relative;

      hr {
        border: 0;
        background: none;
        width: 100%;
        height: 1px;
        background: rgba(white,.2);
        display: block;
        margin: 15px 0;
      }

      span {
        color: rgba(white,.9);
        display: block;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      label {
        color: rgba(white,.9);
        display: block;
        margin-bottom: 5px;
      }

      input[type="radio"],
      input[type="checkbox"] {
        margin-top: 3px;

      }

      textarea {
        margin-top: 3px;
        display: block;
        width: 100%;
        background: rgba(white,.15);
        outline-color: white;
        border-radius: 3px;
        border: 1px solid rgba(white,.2);
        color: rgba(white,.9);
        padding: 2px 6px;

        &::placeholder {
          color: rgba(white,.4);
        }
      }

      input {
        margin-bottom: 5px;
      }

    }


    .settings-billing-cancel-loading.settings-billing-cancel-loading {
      border-radius: 5px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba($clr-ggather,.95);
      display: block;
      z-index: 5;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 10px;

    }











</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .settings-billing-content hr {
    background: darken(white,70);
  }

  .settings-billing-plain-list-item {
    background: darken(white,70);
    color: rgba(white,.8);
    span {
      color: rgba(white,.8);
    }
  }

  .settings-billing-box {
    background-color: darken(white,70);
    border-color: darken(white,60);
    color: rgba(white,.8);
    p {
      color: rgba(white,.8);
    }
    & svg path {
      fill: rgba(white,.6);
    }
  }



  .settings-billing-control {
    background-color: darken(white,70);
    border-color: darken(white,60);
    color: darken(white, 10);
    &:hover {
      background: darken(white,60);
    }
    &.mod-detail {
      background-color: darken(white,70);
    }
    &.mod-loading {
      background-color: darken(white,70);
      border-color: darken(white,60);
    }
    &.mod-upgrade {
    }
    &.mod-first {
    }
    &.mod-last {
      border-color: darken(white,60);
    }
  }



}
</style>
