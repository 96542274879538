<script>
import marked from 'marked';
export default {

  props: [
    'comment',
    'item',
  ],

  data: function(){
    return {
      editMode: false,
      contentValue: '',
    }
  },

  computed: {
    compiledContent: function(){
      var output = marked(this.comment.content, {
        gfm: true,
        tables: false,
        smartLists: true,
        smartypants: true,
        sanitize: true
      });
      output = output.replace('<a href', '<a target="_blank" rel="noreferrer noopener nofollow" href'); // open links in new tab and add nofollow
      return output;
    },
  },

  mounted: function(){
  },

  methods: {

    reportURLComment: function(){
      this.$axios({
        url: this.$store.state.api + '/report-urlcomment/',
        method: 'post',
        data: {
          pk: this.comment.pk,
        },
      }).then(function(response){
        this.$alertify.alert('Annotation was reported. Thank you for making this community better!');
      }.bind(this), function(error){
      }.bind(this));
    },


    report: function(){

      this.$alertify.reset();
      this.$alertify.okBtn('Report').confirm('Please report only public annotations that are spam or offensive in any other way. <br /> Are you sure you want to report this annotation?', function(){
        this.reportURLComment();
      }.bind(this));

      this.$alertify.reset();

    },



    editURLComment: function(event){

      if (event.shiftKey) return;
      event.preventDefault();

      if (!this.contentValue) {
        this.$alertify.alert('You need to provide annotation content...'); return;
      }

      if (this.comment.is_public && ((!this.contentValue) || (this.contentValue.length < 5)) ) {
        this.$alertify.alert('Public annotation must have at least 5 characters.'); return;
      }

      this.$axios({
        url: this.$store.state.api + '/edit-urlcomment/',
        method: 'post',
        data: {
          pk: this.comment.pk,
          content: this.contentValue,
        },
      }).then(function(response){
        this._disableEditMode ? window.removeEventListener('click', this._disableEditMode, true) : null;
        this.editMode = false;
        this.comment.content = response.data.content; // todo: commit to state
      }.bind(this), function(error){
        this.$alertify.alert(error.response && error.response.data ? error.response.data : 'Something went wrong...');
      }.bind(this));
    },


    editToggle: function(){
      this._disableEditMode ? window.removeEventListener('click', this._disableEditMode, true) : null;

      this.contentValue = this.contentValue ? this.contentValue : this.comment.content;
      this.editMode = !this.editMode;

      this._disableEditMode = function(event){
        if (event.target.parentElement.getAttribute('comment-edit-button')!==null) return;
        if (event.target.parentElement.parentElement.getAttribute('comment-edit-button')!==null) return;
        if (event.target.getAttribute('comment-edit-button')!==null) return;
        if (event.target.getAttribute('comment-content-textarea')!==null) return;
        this._disableEditMode ? window.removeEventListener('click', this._disableEditMode, true) : null;
        this.editMode = false;
      }.bind(this);

      window.addEventListener('click', this._disableEditMode, true);

    },


    deleteURLComment: function(){
      this.$axios({
        url: this.$store.state.api + '/delete-urlcomment/',
        method: 'post',
        data: {
          pk: this.comment.pk,
        },
      }).then(function(response){
        this.$emit('deleted');
      }.bind(this), function(error){
      }.bind(this));
    },


    deleteConfirm: function(){

      this.$alertify.reset();
      this.$alertify.okBtn('Delete').confirm('Are you sure you want to delete this annotation?', function(){
        this.deleteURLComment();
      }.bind(this));
      this.$alertify.reset();

    },

  },

};
</script>


<template>
  <div class="comment" :class="{'mod-edit-mode': editMode, 'mod-private': !comment.is_public}">
    <div class="comment-inner">



      <div class="comment-heading">


        <router-link v-if="comment.is_public" :to="'/'+comment.owner.username" class="commentuser">
          <div class="comment-user-image" :style="{backgroundImage: 'url('+comment.owner.profile_image+')'}"></div>
          <div class="comment-user-name">{{comment.owner.username}}</div>
        </router-link>

        <div class="comment-posted-time" :title="comment.date_created">
          {{comment.is_public ? '' : 'Added'}} {{comment.date_created|dfFormatDistanceFromNowOrNow}}
        </div>

        <div class="comment-controls">

          <button class="comment-btn mod-link" type="button" v-tooltip="'<strong>This annotation is for</strong><br/> '+comment.url">
            <iconsvg icon="material-link2"></iconsvg>
          </button>

          <button v-if="comment.is_public && ($store.state.user.pk !== comment.owner.pk)" class="comment-btn mod-report" type="button" @click="report" v-tooltip="'Report Annotation'">
            <iconsvg icon="material-high-priority"></iconsvg>
          </button>

          <button v-if="($store.state.user.pk === comment.owner.pk)" comment-edit-button class="comment-btn mod-edit" type="button" @click="editToggle" v-tooltip="'Edit Annotation'">
            <iconsvg icon="material-pencil"></iconsvg>
          </button>

          <button v-if="($store.state.user.pk === comment.owner.pk)" class="comment-btn mod-delete" type="button" @click="deleteConfirm" v-tooltip="'Delete Annotation'">
            <iconsvg icon="material-delete"></iconsvg>
          </button>

        </div>


      </div>



      <textarea @keypress.enter="editURLComment" comment-content-textarea v-autosize v-if="editMode" rows="1" class="comment-content" v-model="contentValue"  :maxlength="this.comment.is_public ? 999 : 4999"></textarea>


      <div v-else>

        <div v-if="comment.is_public" class="comment-content">
          {{comment.content}}
        </div>

        <div v-else class="comment-content" v-html="compiledContent"> </div>

      </div>



    </div>


    <!--

    <div class="comment-url-line" v-tooltip="'<strong>This annotation is for URL</strong><br/> '+comment.url">
      <div class="comment-url-line-inner">{{comment.url}}</div>
    </div>-->


  </div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .comment {

    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;

  }

    .comment-url-line {
      display: block;

      height: 0;
      overflow: hidden;

      html.mod-transitions-enabled & {
        transition: height 200ms ease-in-out, padding 200ms ease-in-out, margin 200ms ease-in-out, border 200ms ease-in-out;
      }

      .comment:hover & {
        height: 24px;
      }

    }

      .comment-url-line-inner {

        display: block;
        font-size: 10px;
        background: darken(white,10);
        color: rgba(black,.5);
        font-weight: 500;
        white-space: nowrap;

        height: 22px;

        margin-top: 2px;
        padding: 3px 10px;
        border-radius: 2px;

        border: 1px solid  darken(white,10);
        border-top:    1px solid darken(white,15);
        border-right:  1px solid darken(white,17);
        border-left:   1px solid darken(white,17);
        border-bottom: 1px solid darken(white,19);

      }



  .comment-inner {
    display: block;
    background: white;
    border-radius: 3px;
    font-size: 12px;
    border-bottom: 1px solid darken(white,6);

    border-top:    1px solid darken(white,5);
    border-right:  1px solid darken(white,7);
    border-left:   1px solid darken(white,7);
    border-bottom: 1px solid darken(white,9);


    .comment.mod-private & {
      border-left: 4px solid darken(white,20);
    }
  }



    .comment-heading {
      position: relative;
      padding: 9px 10px 5px 10px;
      border-bottom: 1px solid darken(white,3);

      .comment.mod-edit-mode & {
        border-bottom-color: lighten($clr-ggather,38);
      }
    }

      .comment-user {
        display: inline-block;
        vertical-align: baseline;
      }

        .comment-user-image {
          width: 14px;
          height: 14px;
          background-position: 50%;
          background-size: cover;
          border-radius: 50px;
          display: inline-block;
          margin-right: 2px;
          vertical-align: top;
          margin-top: 1px;
        }

        .comment-user-name {
          display: inline-block;
          font-size: 13px;
          font-weight: 500;
          color: rgba(black,.8);
          vertical-align: baseline;
        }

      .comment-posted-time {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: rgba(black,.5);
        vertical-align: baseline;
      }


      .comment-controls {
        position: absolute;
        top: 2px;
        right: 2px;
        border-radius: 5px;
        padding: 3px 5px 3px 5px;
        background: rgba(white,1);
        display: flex;

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        opacity: 0;

        .comment:hover & {
          opacity: 1;
        }
      }


      .comment-btn {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-left: 1px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        /deep/ {
          svg {
            display: block;
            width: 15px;
            height: 15px;

            path {
              fill: black;
            }
          }
        }

        &.mod-delete {
          /deep/ svg {
            width: 17px;
            height: 17px;
          }
        }

        &.mod-link {
          outline: none;
          cursor: default;
          /deep/ svg {
            width: 18px;
            height: 18px;
            transform: translateY(1px) rotateZ(-45deg);
          }
        }

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        opacity: .3;

        &:hover, &:focus {
          opacity: 1;
        }

      }


    .comment-content {
      display: block;
      width: 100%;
      resize: none;
      outline: none;
      padding-top: 6px;
      padding: 10px 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(black,.8);
      line-height: 1.2;
      background: darken(white,0);

      /deep/ {



        p {
          font-size: 14px;
        }

        a {

          color: $clr-ggather;
          &:hover {
            background: rgba($clr-ggather,.1);
            //text-decoration: underline;
          }

          img {
            border: 2px solid $clr-ggather;
          }

        }

        iframe {
          display: block;
          max-width: 100%;
          max-height: 200px;
          border: 2px solid $clr-ggather;
          border-radius: 2px;
        }

        img {
          display: block;
          max-width: 100%;
          max-height: 200px;
          border-radius: 2px;
        }



        ol {
          list-style-position: inside;
        }

        ul {
          list-style-position: inside;
        }

        /deep/ blockquote {
          border-left: 2px solid darken(white,10);
          padding-left: 5px;
        }

      }

    }



    textarea.comment-content {
      background: lighten($clr-ggather,40);
    }



</style>







<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .comment {
  }

    .comment-url-line {

      .comment:hover & {
      }

    }

    .comment-url-line-inner {
      background: darken(white,60);
      color: rgba(white,.5);
      border-color: darken(white,50);
    }



  .comment-inner {
    background: darken(white,60);
    color: rgba(white,.5);
    border-color: darken(white,50);


    .comment.mod-private & {
      border-left: 4px solid darken(white,40);
    }
  }



    .comment-heading {
      border-bottom: 1px solid darken(white,60);

      .comment.mod-edit-mode & {
        border-bottom-color: darken(white,60);
      }
    }

      .comment-user {
      }

        .comment-user-image {
        }

        .comment-user-name {
          color: rgba(white,.8);
        }

      .comment-posted-time {
        color: rgba(white,.5);
      }


      .comment-controls {
        background: darken(white,60);



        .comment:hover & {
        }
      }


      .comment-btn {

        /deep/ {
          svg {
            path {
              fill: white;
            }
          }
        }

        &.mod-delete {
          /deep/ svg {
          }
        }

        &.mod-link {
          /deep/ svg {
          }
        }


        &:hover, &:focus {
        }

      }


    .comment-content {
      color: rgba(white,.8);
      background: darken(white,70);

      /deep/ {

        p {
          font-size: 14px;
        }

        a {
          color: $clr-ggather;
          &:hover {
            background: rgba($clr-ggather,.1);
          }

          img {
            border: 2px solid $clr-ggather;
          }

        }

        iframe {
          border: 2px solid $clr-ggather;
        }


        /deep/ blockquote {
          border-left: 2px solid darken(white,10);
        }

      }

    }



    textarea.comment-content {
      background: darken(white,50);
    }


}
</style>
