<script>
export default {

  props: {
    img: null,
    inverted: Boolean,
    illustration: Boolean,
    secondaryFn: Function,
    hidePrimary: Boolean,
  },

};
</script>


<template>
<div class="landing-hero" :class="{'mod-illustration': illustration, 'mod-inverted': inverted}">

  <div v-if="img" class="landing-hero-bg-img-wrapper">
    <img class="landing-hero-bg-img" :src="img" />
  </div>

  <div class="landing-hero-front">

    <div class="landing-hero-front-inner" v-bar>
      <div class="landing-hero-front-inner-inner">
        <div class="landing-hero-front-inner-inner-inner">



          <div class="landing-hero-front-text">
            <div v-if="$slots.title" class="landing-hero-front-title"><slot name="title"></slot></div>
            <div v-if="$slots.title2" class="landing-hero-front-title2"><slot name="title2"></slot></div>
            <div class="landing-hero-front-image"><img :src="img"></div>
            <div v-if="$slots.subtitle" class="landing-hero-front-subtitle"><slot name="subtitle"></slot></div>
            <div v-if="$slots.description" class="landing-hero-front-description"><slot name="description"></slot></div>
          </div>



          <div class="landing-hero-btns">

            <div class="landing-hero-btn-wrapper" v-if="!$props.hidePrimary">
              <button  type="button" @click.prevent="$store.commit('dialogbox', {kind: 'signinup', subkind: 'signup'})" class="landing-hero-btn mod-icon mod-primary">
                <iconsvg icon="add-user-male-iosglyph" /> Sign Up For Free
              </button>
            </div>

            <div class="landing-hero-btn-wrapper"  v-if="$slots.altcta">
              <slot name="altcta"></slot>
            </div>

            <div class="landing-hero-btn-wrapper" v-if="secondaryFn">
              <button type="button"  @click.prevent="secondaryFn" class="landing-hero-btn mod-secondary">
                <slot name="secondary"></slot>
                <iconsvg icon="material-forward" />
              </button>
            </div>

          </div>




        </div>
      </div>
    </div>

  </div>

</div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";


  .landing-hero {
    display: block;
    width: 100%;
    height: 100%;

    opacity: 1;

    &.herotransition-enter-active, &.herotransition-leave-active {
      transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
      transform-origin: 100% 50%;
      transform-origin: 50% 50%;
      transform: none;
      opacity: 1;
    }

    &.herotransition-enter{
      transform: perspective(100vw) rotateY(10deg);
      opacity: 0;
    }

    &.herotransition-leave-to {
      transform: perspective(100vw) rotateY(-10deg);
      opacity: 0;

    }

    //@media(max-width:960px){
    //  height: auto;
    //}

  }

  .landing-hero-bg-img-wrapper {
    position: absolute;
    top: 50%;
    top: calc(50% - 15px);
    right: 0;
    z-index: 2;
    transform-origin: 50% 50%;
    transform: translateY(-50%) translateX(10%) perspective(2000px) rotateY(-32deg);
    transform: translateY(-50%) translateX(5%) perspective(150vw) rotateY(-15deg);


    transform: translateY(-50%) translateX(10%) perspective(150vw) rotateY(-0deg);

    //box-shadow: 0 0px 0px 2px rgba(black,.2);

    opacity: 1;

    //&::before {
    //  content: '';
    //  position: absolute;
    //  bottom: -40px;
    //  left: 0;
    //  width: 100%;
    //  height: 50px;
    //  background: rgba(black, .2);
    //  transform: perspective(150vw) rotateX(-45deg);
    //}

    .landing-hero.mod-inverted & {
      right: auto;
      left: 0;
      transform: translateY(-50%) translateX(-2%) perspective(150vw) rotateY(-0deg);
    }


    .landing-hero.mod-illustration & {
      transform: translateY(-50%) translateX(0%) perspective(150vw) rotateY(-0deg);
    }


    .landing-hero.mod-inverted.mod-illustration & {
      transform: translateY(-50%) translateX(0%) perspective(150vw) rotateY(-0deg);
    }


    @media(max-width:960px){
      display: none;
    }


  }

    .landing-hero-bg-img {
      //display: block;
      //width: 100%;
      //height: 100%;
      width: auto;
      max-width: 50vw;
      height: auto;
      max-height: 80vh;
      height: auto;
      border-radius: 4px;

    }



  .landing-hero-front {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 3;
    display: flex;
    //align-content: center;
    //align-items: center;
    width: 60vw;


    flex-direction: column;
    justify-content: center;
    //background: rgba($clr-ggather,1);
    //box-shadow: 0 0 200px 200px rgba($clr-ggather,1);


    padding-top: 65px;
    padding-bottom: 81px;

    .landing-hero.mod-inverted & {
      margin-left: auto;
    }


    @media(max-width:960px){
      width: 100%;
      //height: auto;
      background: rgba($clr-ggather,.95);
    }

    @media(max-width:960px){
      padding-top: 50px;
    }
  }


  // vuebar el1
    .landing-hero-front-inner {
      overflow: hidden;
      position: relative;
      display: block;
        height: 100%;
        //top: -9px;
      //height: 100%;
      //@media(max-width:960px){
      //  top: 0;
      //  height: auto;
      //}

    }



    // vuebar el2
    .landing-hero-front-inner-inner {
      overflow: auto;
        //height: 100%;
        //max-height: 50vh;
        //max-height: calc(100vh - 145px);
      display: block;
      display: flex !important;
      height: 100%;
      //@media(max-width:960px){
      //  height: auto;
      //  max-height: none;
      //}


    }

      .landing-hero-front-inner-inner-inner {

        display: block;
        margin: auto;
        width: 100%;

        padding: 70px;

        padding-right: 50px;
        padding-right: 10vw;



        @media(max-width:1440px){
        }

        @media(max-width:960px){
          padding-top: 70px;
          padding-bottom: 70px;
          padding-left: 70px;
        }

        @media(max-width:540px){
          padding: 20px;
          padding-top: 70px;
        }



        .landing-hero.mod-inverted & {
          padding-right: 70px;
          padding-left: 50px;
          padding-left: 12vw;

          @media(max-width:1440px){
          }

          @media(max-width:960px){
            padding-top: 70px;
            padding-bottom: 70px;
            padding-right: 70px;
          }

          @media(max-width:540px){
            padding: 20px;
            padding-top: 70px;
          }
        }




      }


      .landing-hero-front-text {
        display: block;


        /deep/ em {
          font-style: normal;
          text-decoration: underline;
          text-decoration-skip-ink: auto;
        }

        /deep/ a {
          text-decoration: underline;
          text-decoration-skip-ink: auto;
          //color: rgba(white,.7);
        }
      }


      .landing-hero-front-image {

        display: none;
        margin-top: 30px;
        margin-bottom: 30px;

        img {
          height: auto;
          max-width: 100%;
          border-radius: 3px;
        }
        @media(max-width:960px){
          display: block;
        }

      }



        .landing-hero-front-title {
          font-size: 36px;
          line-height: 1.23;
          font-weight: 500;
          color: rgba(white,1);

          @media(max-width:1440px){
            font-size: 32px;
          }

          @media(max-width:960px){
            font-size: 28px;
          }

          @media(max-width:540px){
            font-size: 26px;
          }

          strong {
            font-weight: 700;
          }

        }

        .landing-hero-front-title2 {
          font-size: 32px;
          line-height: 1.25;
          font-weight: 500;
          color: rgba(white,1);


          @media(max-width:1440px){
            font-size: 30px;
          }

          @media(max-width:960px){
            font-size: 28px;
          }

          @media(max-width:540px){
            font-size: 26px;
          }

        }

        .landing-hero-front-subtitle {
          font-size: 22px;
          line-height: 1.25;
          font-weight: 400;
          color: rgba(white,.7);
          margin-top: 20px;


          @media(max-width:1440px){
            font-size: 20px;
          }

          @media(max-width:960px){
            font-size: 18px;
          }

          @media(max-width:540px){
            font-size: 17px;
          }


        }

        .landing-hero-front-description {
          font-size: 16px;
          line-height: 1.37;
          font-weight: 400;
          color: rgba(white,.6);
          margin-top: 20px;

          @media(max-width:1440px){
            font-size: 16px;
          }

          @media(max-width:960px){
            font-size: 15px;
          }

          @media(max-width:540px){
            font-size: 14px;
          }


          ul {
            list-style-type: none;
          }

          li {
            margin-top: 5px;
            list-style-type: none;
            position: relative;
            padding-left: 30px;
            //&::before {
            //  position: absolute;
            //  z-index: 2;
            //  content: 'c';
            //  top: 0;
            //  left: 0;
            //}


            span {

            }

            /deep/ svg {
              position: absolute;
              z-index: 2;
              top: 0;
              left: 0;
              path { fill: rgba(white,.6); }
            }
          }
        }








    .landing-hero-btns {
      display: block;
      margin-top: 10px;
      //display: flex;
      //justify-content: space-between;
      //align-items: center;
      //align-content: center;
      //flex-wrap: wrap;

      @media(max-width:1440px){
      }

      @media(max-width:1200px){
      }

      @media(max-width:960px){
      }

      @media(max-width:540px){
      }
    }


      .landing-hero-btn-wrapper {
        display: inline-block;
        margin-top: 22px;
        margin-right: 25px;
        @media(max-width:1200px){
          margin-right: 0;
          display: block;
        }

        @media(max-width:960px){
          margin-right: 0;
          display: block;
          //margin-top: 15px;
          margin-right: 0;

        }
      }



      .landing-hero-btn {
        background: rgba(white,.9);
        //border: 1px solid rgba;
        padding: 9px 25px;
        border-radius: 2px;
        font-size: 17px;
        line-height: 1.37;
        font-weight: 500;
        color: rgba(darken($clr-ggather,5),.95);
        position: relative;
        display: inline-block;
        text-align: left;


        @media(max-width:1440px){
          font-size: 16px;
        }


        @media(max-width:960px){
          font-size: 15px;
          display: block;
          width: 100%;
          padding: 12px 25px;
        }

        @media(max-width:540px){
          font-size: 14px;
        }


        outline: none;

        span {
          vertical-align: baseline;
          margin-left: 3px;
          //display: block;
          opacity: .8;
          font-size: 13px;
        }

        html.mod-transitions-enabled & {
          transition: color 150ms ease-in-out, background 150ms ease-in-out, box-shadow 150ms ease-in-out;
        }

        &:hover, &:focus {
          color: rgba($clr-ggather,.9999);
          background: rgba(white,.9999);
          box-shadow: 0 2px 15px 0 rgba(black,.08), 0 2px 25px 0 rgba(black,.08);
        }


        &.mod-icon {
          padding-left: 55px;
          svg {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
          }

        }


        &.mod-primary {
          /deep/ svg {
            path{ fill: rgba($clr-ggather,.99); }
          }
        }


        &.mod-secondary {
          background: rgba(white,.08);
          color: rgba(white,.7);
          font-weight: 400;
          padding-right: 55px;

          &:hover, &:focus {
            background: rgba(white,.1);
            color: rgba(white,.99);
            box-shadow: 0 2px 15px 0 rgba(black,.05), 0 2px 25px 0 rgba(black,.05);
          }

          /deep/ svg {
            display: inline-block;
            width: 15px;
            height: 15px;
            vertical-align: middle;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            path {
              fill: rgba(white,.7);
            }
          }

        }


      }







</style>





<style lang="scss">

  .landing-hero-front {


    .vb > .vb-dragger {
        z-index: 5;
        width: 12px;
        right: 50px;
        right: 7vw;




        .landing-hero.mod-inverted & {
          right: 0;

        }


        @media(max-width:1440px){
        }

        @media(max-width:960px){
          right: 0px;
        }

        @media(max-width:540px){
          right: 0px;
        }

    }



    .vb > .vb-dragger > .vb-dragger-styler {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: rotate3d(0,0,0,0);
        transform: rotate3d(0,0,0,0);
        -webkit-transition:
            background-color 100ms ease-out,
            margin 100ms ease-out,
            height 100ms ease-out;
        transition:
            background-color 100ms ease-out,
            margin 100ms ease-out,
            height 100ms ease-out;
        background-color: rgba(white,.2);
        margin: 5px 5px 5px 0;
        border-radius: 20px;
        height: calc(100% - 10px);
        display: block;
    }

    .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
        background-color: rgba(white,.3);
    }

    .vb > .vb-dragger:hover > .vb-dragger-styler {
        background-color: rgba(white,.5);
        margin: 0px;
        height: 100%;
    }

    .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
        background-color: rgba(white,.5);
        margin: 0px;
        height: 100%;
    }

    .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
        background-color: rgba(white,.5);
    }


  }

</style>
