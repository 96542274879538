<script>
export default {

  name: 'settings-data',

  data: function(){
    return {

      cleanupConfirm: false,
      cleanupUsername: '',
      cleanupLoading: false,
      cleanupStatus: null,
      cleanupErrorData: null,
      cleanupSuccessData: null,

      deleteConfirm: false,
      deleteUsername: '',
      deleteStatus: null,
      deleteErrorData: null,
      deleteSuccessData: null,

    };
  },

  computed: {


  },

  watch: {
  },

  mounted: function(){
  },

  updated: function(){
  },

  methods: {

    cleanupBtnClick: function(){
      this.$refs.cleanupSection.scrollIntoView({behavior: 'smooth'});
    },

    deleteBtnClick: function(){
      this.$refs.deleteSection.scrollIntoView({behavior: 'smooth'});
    },

    cleanupAccount: function(){
      if (!this.cleanupConfirm) {
        window.alert('You need to confirm cleanup..'); return;
      }

      if (this.cleanupUsername !== this.$store.state.user.username) {
        window.alert('Provided username is wrong..'); return;
      };


      this.cleanupStatus = 'loading';

      this.$axios({
        url: this.$store.state.api + '/cleanup-account/',
        method: 'post',
        data: {
          username: this.cleanupUsername,
        },
      }).then(function(response){
        this.cleanupStatus = 'success';
        this.cleanupSuccessData = response.data;
        alert('Your account was successfully cleaned up. The page will now reload.');
        window.location.reload(1);
      }.bind(this), function(error){
        this.cleanupStatus = 'error';
        this.cleanupErrorData = error && error.response && error.response.data ? JSON.stringify(error.response.data) : 'Network Error';
      }.bind(this));
    },


    deleteAccount: function(){

      if (!this.deleteConfirm) {
        window.alert('You need to confirm deletion..'); return;
      }

      if (this.deleteUsername !== this.$store.state.user.username) {
        window.alert('Provided username is wrong..'); return;
      };

      this.deleteStatus = 'loading';

      this.$axios({
        url: this.$store.state.api + '/delete-account/',
        method: 'post',
        data: {
          username: this.deleteUsername,
        },
      }).then(function(response){
        this.deleteStatus = 'success';
        this.deleteSuccessData = response.data;
        alert('Your account was successfully deleted. The page will now reload.');
        window.location.reload(1);
      }.bind(this), function(error){
        this.deleteStatus = 'error';
        this.deleteErrorData = error && error.response && error.response.data ? JSON.stringify(error.response.data) : 'Network Error';
      }.bind(this));
    },


  },

};
</script>


<template>
<div class="settings-data" @mouseleave="deleteConfirm=false;cleanupConfirm=false;">


  <!--
  <div class="settings-tab-title mod-first">Data Control</div>
  <div class="settings-tab-content">
    <p>Username: {{$store.state.user.username}}</p>
    <p>First Name: {{$store.state.user.first_name}}</p>
    <p>Last Name: {{$store.state.user.last_name}}</p>
    <p>Email: {{$store.state.user.email}}</p>
    <p>Public URL: {{$store.state.user.public_url}}</p>
    <p>UX Default Tag Category Name: {{$store.state.user.ux.default_tagcategory_name}}</p>
    <p>UX Feed Last View: {{$store.state.user.ux.feed_last_view}}</p>
  </div> -->

  <!-- <div class="settings-tab-title">Local Storage Data</div>
  <div class="settings-tab-content">

    <p>Transitions Enabled: {{$store.state.transitionsEnabled}}</p>
    <p>Viewer Expand Level: {{$store.state.viewerExpandLevel}}</p>
  </div>-->



  <div class="settings-data-section mod-first mod-info">

    <h3 class="settings-data-title">Your Data</h3>
    <div class="settings-data-content">


      <p>See summary on how we protect and store your data.</p>

      <div class="settings-input-control">
        <div>
          <a :href="$store.state.hq+'/transparency'" class="common-button  mod-margin-top-10" >GGather Transparency & Data Control →</a>
        </div>
      </div>

      <!--
      <div class="settings-input-control">
        <div>
          <button type="button" class="common-button  mod-margin-top-10" @click.prevent="cleanupBtnClick">Cleanup Account</button>
          <button type="button" class="common-button  mod-margin-top-10" @click.prevent="deleteBtnClick">Delete Account</button>
        </div>
      </div>
      -->


    </div>

  </div>


  <br />


  <div class="settings-data-section mod-warning" ref="cleanupSection">

    <h3 class="settings-data-title">Cleanup Account</h3>
    <div class="settings-data-content">


      <p><strong>Account cleanup is an irreversible action and we won't be able to restore your data.</strong> Please use this option with caution.</p>

      <ul>
        <li>Account cleanup will remove all of the bookmarks, tags, tag subscriptions, tags groups and annotations (comments) from your account. </li>
        <li>It will not remove your account or your user data. It will not prohibit you from using your account in any capacity. It will not remove any paid subscriptions.</li>
      </ul>

      <br>

      <div class="settings-input-control">
        <input type="text" v-model="cleanupUsername" placeholder="Confirm your username">

        <button type="button" class="common-button" v-if="!cleanupConfirm" @click.prevent="cleanupConfirm=true" @mouseleave="cleanupConfirm=false">
          Cleanup Account
        </button>

        <button type="button" class="common-button" v-else-if="cleanupConfirm" @click.prevent="cleanupAccount" @mouseleave="cleanupConfirm=false">
          Click Again to Confirm
        </button>

      </div>


      <br>
      <br>

      <p v-if="cleanupStatus==='loading'"><strong>Status:</strong> Loading... It might take a while...</p>
      <p v-else-if="cleanupStatus==='error'"><strong>Error:</strong> {{cleanupErrorData}}</p>


    </div>

  </div>


  <br>

  <div class="settings-data-section mod-danger" ref="deleteSection">

    <h3 class="settings-data-title">Delete Account</h3>
    <div class="settings-data-content">
      <p><strong>Deleting account is an irreversible action and we won't be able to restore your account.</strong> Please use this option with caution.</p>

      <ul>
        <li>Your GGather data and account will be completely wiped out from active database. We won't be able to retrieve it.</li>
        <li>We will still have some of your data in our database backups. That data is stored securely and is not shared with anyone or any third party and will be eventually deleted. That data will be only touched/accessed if we need to restore it in whole in case of failure of our primary active database.</li>
        <li>You will be able to create new GGather account later using the same email and username.</li>
        <li>Any data related to paid subscriptions or payments (like email, first name, last name, username, etc.) will NOT be removed if you've previously bought paid subscription. We need to hold on to that data for some time for legal and tax purposes.</li>
        <li>You might still receive promotional emails from us because we're using 3rd party service for those (emailoctopus.com). If that happens, you can unsubscribe using unsubscribe button in any received promotional email.</li>
      </ul>


      <br>

      <div class="settings-input-control">
        <input type="text" v-model="deleteUsername" placeholder="Confirm your username">

        <button type="button" class="common-button" v-if="!deleteConfirm" @click.prevent="deleteConfirm=true" @mouseleave="deleteConfirm=false">
          Delete Account
        </button>

        <button type="button" class="common-button" v-else-if="deleteConfirm" @click.prevent="deleteAccount" @mouseleave="deleteConfirm=false">
          Click Again to Confirm
        </button>


      </div>


      <br>
      <br>

      <p v-if="deleteStatus==='loading'"><strong>Status:</strong> Loading... It might take a while...</p>
      <p v-else-if="deleteStatus==='error'"><strong>Error:</strong> {{deleteErrorData}}</p>



    </div>

  </div>

</div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .settings-data {
    font-size: 14px;
    p {
      font-size: 14px;
    }

    ul {
      list-style-position: inside;
    }

    ul ul {
      margin-left: 20px;
    }

    li {
      margin-top: 5px;
    }

    a:not(.common-button) {
      text-decoration: underline;
    }

    & /deep/ svg,
    svg {
      width: 18px;
      height: 18px;
      display: inline;
      fill: darken(white,40);
    }
  }



  .settings-data-section {
    margin-top: 20px;

    &.mod-first {
      margin-top: 0;
    }

    &.mod-info {
    }

    &.mod-warning {
      padding: 10px;
      border-radius: 6px;
      box-shadow: 0 0 0 3px orange;
      background: rgba(orange,.05);
    }

    &.mod-danger {
      padding: 10px;
      border-radius: 6px;
      box-shadow: 0 0 0 3px red;
      background: rgba(red,.05);
    }
  }

  .settings-data-title {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid darken(white,10);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }







</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .settings-data-title {
    border-bottom: 1px solid darken(white,70);
  }


}
</style>
