<script>
export default {

  name: 'pmethod',

  props: [
    'added',
    'type',
    'subtype',
    'holder',
    'email',
    'last4',
    'expired',
    'defaultmethod',
    'ccv',
  ],

  data: function(){
    return {

    }
  },


  methods: {

  },

};
</script>


<template>
<div class="pmethod">
<div class="pmethod-inner">

  <div class="pmethod-top">

    <div v-if="type==='card'" class="pmethod-title">Credit Card</div>
    <div v-if="type==='paypal'" class="pmethod-title">PayPal</div>

    <!--
    <div class="pmethod-logo" >
      <svg xmlns="http://www.w3.org/2000/svg" class="card__logo" width="140" height="43" viewBox="0 0 175.7 53.9"><path class="visa" fill="#FFF" d="M61.9 53.1l8.9-52.2h14.2l-8.9 52.2zm65.8-50.9c-2.8-1.1-7.2-2.2-12.7-2.2-14.1 0-24 7.1-24 17.2-.1 7.5 7.1 11.7 12.5 14.2 5.5 2.6 7.4 4.2 7.4 6.5 0 3.5-4.4 5.1-8.5 5.1-5.7 0-8.7-.8-13.4-2.7l-2-.9-2 11.7c3.3 1.5 9.5 2.7 15.9 2.8 15 0 24.7-7 24.8-17.8.1-5.9-3.7-10.5-11.9-14.2-5-2.4-8-4-8-6.5 0-2.2 2.6-4.5 8.1-4.5 4.7-.1 8 .9 10.6 2l1.3.6 1.9-11.3M164.2 1h-11c-3.4 0-6 .9-7.5 4.3l-21.1 47.8h14.9s2.4-6.4 3-7.8h18.2c.4 1.8 1.7 7.8 1.7 7.8h13.2l-11.4-52.1m-17.5 33.6c1.2-3 5.7-14.6 5.7-14.6-.1.1 1.2-3 1.9-5l1 4.5s2.7 12.5 3.3 15.1h-11.9zm-96.7-33.7l-14 35.6-1.5-7.2c-2.5-8.3-10.6-17.4-19.6-21.9l12.7 45.7h15.1l22.4-52.2h-15.1"/><path fill="#F7A600" d="M23.1.9h-22.9l-.2 1.1c17.9 4.3 29.7 14.8 34.6 27.3l-5-24c-.9-3.3-3.4-4.3-6.5-4.4"/></svg>
    </div> -->


    <div v-if="type==='card'" class="pmethod-subtype">{{subtype}}</div>

    <div v-if="type==='paypal' && email" class="pmethod-subtype-email">{{email}}</div>


  </div>

  <div class="pmethod-bottom">


    <div v-if="holder" class="pmethod-card-holder">
      <div class="pmethod-label">Holder</div>
      <div class="pmethod-card-value mod-small">{{holder}}</div>
    </div>

    <div v-if="last4" class="pmethod-card-number">
      <div class="pmethod-label">Number</div>
      <div class="pmethod-card-value mod-big">**** **** **** {{last4}}</div>
    </div>


    <div class="pmethod-bottom-bottom">

      <div class="pmethod-added">
        <div class="pmethod-label">Added</div>
        <div class="pmethod-card-value mod-small">{{added}}</div>
      </div>

      <div v-if="defaultmethod" class="pmethod-default">
        <div class="pmethod-label">Default</div>
        <div class="pmethod-card-value mod-small">{{defaultmethod}}</div>
      </div>


      <div v-if="expired" class="pmethod-expiration">
        <div class="pmethod-label">Expired</div>
        <div class="pmethod-card-value mod-small">{{expired}}</div>
      </div>



        <!--
      <div v-if="email" class="pmethod-email">
        <div class="pmethod-label">Email</div>
        <div class="pmethod-card-value mod-small">{{email}}</div>
      </div>-->




      <div v-if="ccv" class="pmethod-ccv">
        <div class="pmethod-label">CCV</div>
        <div class="pmethod-card-value mod-small">{{ccv}}</div>
      </div>

    </div>

  </div>

</div>
</div>
</template>




<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .pmethod {
    margin-top: 10px;
    width: 100%;
    max-width: 400px;
  }


  .pmethod-inner {
    background-color: lighten(#03111a,1);
    background-color: $clr-ggather-very-dark;
    border-radius: 8px;
    display: block;
    padding: 21px 25px 23px 20px;
    font-size: 0;
  }


  .pmethod-label {
    font-size: 13px;
    font-weight: 400;
    color: rgba(white,.7);
    line-height: 1.2;
  }

  .pmethod-card-value {
    font-size: 14px;
    color: rgba(white,.9);
    line-height: 1.2;
    //font-family: 'Roboto Mono', monospace, monospace;

    &.mod-big {
      padding-top: 2px;
      font-size: 20px;
      font-weight: 400;
    }

    &.mod-small {
      padding-top: 4px;
      font-size: 14px;
      font-weight: 300;
    }

  }


  .pmethod-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }


    .pmethod-title {
      font-size: 18px;
      font-weight: 600;
      color: rgba(white,.9);
      line-height: 1;
      padding-top: 2px;
    }

    .pmethod-subtype {
      font-size: 14px;
      font-weight: 500;
      color: rgba(white,.6);
      line-height: 1;
      padding-top: 5px;
    }

    .pmethod-subtype-email {
      font-size: 14px;
      font-weight: 500;
      color: rgba(white,.6);
      line-height: 1;
      padding-top: 5px;
    }

    .pmethod-logo {
      background-position: 50%;
      background-size: contain;
      //width: 50px;
      //height: 40px;

      svg {
        width: auto;
        height: 21px;
      }

    }


  .pmethod-bottom {
    padding-top: 10px;
  }


    .pmethod-card-holder {
      padding-top: 20px;
    }


    .pmethod-card-number {
      padding-top: 20px;
    }



    .pmethod-bottom-bottom {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

      .pmethod-added {
        display: inline-block;
        width: 40%;
      }

      .pmethod-email {
        display: inline-block;
        width: 40%;
        text-align: right;
      }

      .pmethod-expiration {
        display: inline-block;
        width: 30%;
      }

      .pmethod-default {
        display: inline-block;
        width: 30%;
      }

      .pmethod-ccv {
        display: inline-block;
        width: 12%;
        text-align: right;
      }


</style>
