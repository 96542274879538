<script>
import _find from 'lodash/find';
export default {

  data: function(){
    return {
    }
  },

  computed: {

    mainKind: function(){
      if (this.$route.meta.kind==='all' && !this.$route.query.untagged) return 'all';
      if (this.$route.meta.kind==='all' && this.$route.query.untagged) return 'untagged';
      if (this.$route.meta.kind==='search') return 'search';
      if (this.$route.meta.kind==='tagpage') return 'tagpage';
    },

    contentTitle: function(){
      if (this.$route.meta.kind==='all' && !this.$route.query.untagged) return 'All';
      if (this.$route.meta.kind==='all' && this.$route.query.untagged) return 'Untagged';
      if (this.$route.meta.kind==='search') return this.$route.query.query;
      if (this.$route.meta.kind==='tagpage') {
        var params = this.$route.params.tagslug;
        // get tag title from state tags list
        var tagFromStateTags = _find(this.$store.state.tags, {slug: params.tagslug, owner_username: params.username});
        if (tagFromStateTags) return tagFromStateTags.name;
        // get tag title from tagPageMeta
        if (this.$store.state.tagPageMeta) return this.$store.state.tagPageMeta.name;
        // else fallback to url param
        return this.$route.params.tagslug;

      }
      return '...';
    },

    tagPageContentTitleTitle: function(){
      return `(Click to edit) ${this.contentTitle}`;
    },

    contentItemsCount: function(){
      if (!this.$store.state.itemsMeta) return '-';
      if (this.$store.state.itemsMeta) return this.$store.state.itemsMeta.count;
      return '';
    },

    tagVisibility: function(){
      if (!this.$store.state.tagPageMeta) return null;
      return this.$store.state.tagPageMeta.visibility;
    },

    showMarkSpinner: function(){
      return this.$store.state.itemsLoading;
    },

  },

  watch: {
  },

  methods: {

    contentTitleClick: function(){
      this.$store.commit('dialogbox', {kind: 'tagbox', subkind: 'edit', tag: this.$store.state.tagPageMeta});
    },

    clickMenuButton: function(){
      this.$store.commit('titlebarMenuEnabled', !this.$store.state.titlebarMenuEnabled);
    },

  },

};
</script>


<template>
<div class="titlebarheading">

  <div class="titlebarheading-content">


    <div class="titlebarheading-section">

      <div v-if="showMarkSpinner" class="titlebarheading-mark-wrapper">
        <div v-if="showMarkSpinner" class="titlebarheading-mark-spinner">
          <spinner2 :size="14" :line-size="2" :speed="0.4" line-fg-color="gray" line-bg-color="transparent" />
        </div>
      </div>
      <div v-if="!showMarkSpinner" class="titlebarheading-mark-wrapper">
        <div v-if="mainKind==='all' || mainKind==='untagged'" class="titlebarheading-mark-icon">
          <iconsvg icon="oneem-layers" />
        </div>
        <div v-if="mainKind==='search'" class="titlebarheading-mark-icon">
          <iconsvg icon="oneem-search" />
        </div>
        <div v-if="mainKind==='tagpage' && tagVisibility===1" class="titlebarheading-mark-icon" title="This tag is private.">
          <iconsvg icon="oneem-lock" />
        </div>
        <div v-if="mainKind==='tagpage' && tagVisibility===2" class="titlebarheading-mark-icon" title="This tag is unlisted.">
          <iconsvg icon="oneem-visible" />
        </div>
        <div v-if="mainKind==='tagpage' && tagVisibility===3" class="titlebarheading-mark-icon" title="This tag is public.">
          <iconsvg icon="oneem-globe" />
        </div>
      </div>

    </div>

    <div v-if="mainKind==='tagpage'" class="titlebarheading-section  mod-title">
      <button type="button" @click.prevent="contentTitleClick" :title="tagPageContentTitleTitle" class="titlebarheading-title">{{contentTitle}}</button>
    </div>

    <div v-else class="titlebarheading-section mod-title">
      <div class="titlebarheading-title">{{contentTitle}}</div>
    </div>



    <div class="titlebarheading-section">
      <div class="titlebarheading-detail" :class="{'mod-loading': $store.state.itemsLoading}" title="Total number of items">{{contentItemsCount}}</div>
    </div>


    <div class="titlebarheading-section mod-menu">
      <button type="button" class="titlebarheading-menu-btn" @click.prevent="clickMenuButton">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
          <path d="M 10 2 C 8.6983906 2 7.5978609 2.8387486 7.1835938 4 L 2 4 L 2 6 L 7.1835938 6 C 7.5978609 7.1612514 8.6983906 8 10 8 C 11.654 8 13 6.654 13 5 C 13 3.346 11.654 2 10 2 z M 15 4 L 15 6 L 22 6 L 22 4 L 15 4 z M 15 9 C 13.698391 9 12.597861 9.8387486 12.183594 11 L 2 11 L 2 13 L 12.183594 13 C 12.597861 14.161251 13.698391 15 15 15 C 16.654 15 18 13.654 18 12 C 18 10.346 16.654 9 15 9 z M 20 11 L 20 13 L 22 13 L 22 11 L 20 11 z M 7 16 C 5.6983906 16 4.5978609 16.838749 4.1835938 18 L 2 18 L 2 20 L 4.1835938 20 C 4.5978609 21.161251 5.6983906 22 7 22 C 8.654 22 10 20.654 10 19 C 10 17.346 8.654 16 7 16 z M 12 18 L 12 20 L 22 20 L 22 18 L 12 18 z"></path>
        </svg>
      </button>
    </div>

  </div>





</div>
</template>


<style lang="scss" scoped>
@import "../../../common/style/_variables.scss";

  .titlebarheading {

  }

  .titlebarheading-content {
    background-color: white;
    border: 1px solid rgba(black,.10);
    border-radius: 3px;
    display: block;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

    .titlebarheading-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      vertical-align: middle;
      border-left: 1px solid rgba(black,.1);

      &:first-of-type {
        border-left: none;
      }

      &.mod-menu {
        display: none;
      }

    }



      .titlebarheading-menu-btn {
        text-align: center;
        display: block;
        margin: 0 auto;
        padding: 5px 8px;
        & svg {
          margin: 0 auto;
          width: 16px;
          height: 16px;
          fill: rgba(black,1);
          opacity: .7;
        }
      }


      .titlebarheading-mark-wrapper {
        display: block;
        position: relative;
        min-width: 30px;
        text-align: center;
      }

      .titlebarheading-mark-spinner {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
      }

      .titlebarheading-mark-icon {
        text-align: center;
        display: block;
        margin: 0 auto;
        padding: 4px 6px 4px 7px;
        & svg {
          margin: 0 auto;
          width: 16px;
          height: 16px;
          fill: rgba(black,.7);
        }
      }


      .titlebarheading-circle {
        width: 10px;
        height: 10px;
        border-radius: 30px;
        background: red;
      }


      .titlebarheading-title {
        padding: 5px 10px 4px 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        color: rgba(black,.8);
        outline: none;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        @media (max-width: 700px){
          font-size: 11px;
        }
        @media (max-width: 420px){
          max-width: 100px;
        }

      }

      .titlebarheading-detail {
        padding: 4px 8px;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.2;
        color: rgba(black,.6);
        min-width: 30px;
        text-align: center;

        &.mod-loading {
          opacity: .5;
        }
      }





</style>







<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .titlebarheading-content {
    background-color: darken(white,72);
    border-color: darken(white,68);
  }

  .titlebarheading-section {
    border-color: darken(white,68);
  }




  .titlebarheading-mark-spinner {
    background-color: darken(white,72);

  }

  .titlebarheading-mark-icon {
    & svg {
      fill: rgba(white,.7);
    }
  }



  .titlebarheading-title {
    color: rgba(white,.8);
  }

  .titlebarheading-detail {
    color: rgba(white,.6);
  }



  .titlebarheading-menu-btn {
    & svg {
      fill: rgba(white,1);
    }
  }



}
</style>
