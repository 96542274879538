<script>
import _find from 'lodash/find';
export default {


  props: [
    'context',
  ],

  data: function(){
    return {

      rating: null,
      ratingHover: null,

      itemLayoutPopoverOpen: false,
      itemLayout: {
          text: 'Panels',
          value: 'panel',
      },
      itemLayoutOptions: [
        {
            text: 'List',
            value: 'list',
            //icon: 'menu',
        },
        {
            text: 'Panels',
            value: 'panel',
            //icon: 'category',
        },
        {
            text: 'Panels / Two Columns',
            value: 'panel-multiple',
            //icon: 'category',
        },
        /*
        {
            text: 'Compact List',
            value: 'list-compact',
            //icon: 'menu',
        },
        */
        {
            text: 'Grid',
            value: 'grid',
            //icon: 'grid',
        },
        {
            text: 'Grid / Uniform',
            value: 'grid-fixed',
            //icon: 'grid',
        },
        {
            text: 'Cards',
            value: 'card',
            //icon: 'news',
        },
        {
            text: 'Cards / Uniform',
            value: 'card-fixed',
            //icon: 'grid',
        },
        {
            text: 'Cards / Small',
            value: 'card-small',
            //icon: 'news',
        },
        {
            text: 'Cards / Small / Uniform',
            value: 'card-fixed-small',
            //icon: 'news',
        },
      ],

      orderingPopoverOpen: false,
      ordering: null,
      orderingOptions: [
        {
          text: 'Newest First',
          value: 'newest',
        },
        {
          text: 'Oldest First',
          value: 'oldest',
        },
        {
          text: 'Rated First',
          value: 'rating-desc',
        },
        /*
        {
          text: 'Sort By Site',
          value: 'url',
        },
        {
          text: 'Sort By Popularity',
          value: 'click_views',
        }
        */
      ],


      subtagsPopoverOpen: false,
      subtag: null,
      subtagsLoading: false,
      subtagsTags: [],

    };
  },

  computed: {

    visible: function(){
      var kind = this.$route.meta.kind;
      if (kind==='feed') return true;
      if (kind==='all') return true;
      if (kind==='search') return true;
      if (kind==='profilepage' && this.$store.state.profilePageMeta) return true;
      if (kind==='tagpage' && this.$store.state.tagPageMeta) return true;
    },


    currentlyOpenedTag: function(){
      if (this.$route.meta.kind!=='tagpage') return null;
      var params = this.$route.params.tagslug;
      // get tag  from state tags list
      var tagFromStateTags = _find(this.$store.state.tags, {slug: params.tagslug, owner_username: params.username});
      if (tagFromStateTags) return tagFromStateTags;
      // get tag from tagPageMeta
      if (this.$store.state.tagPageMeta) return this.$store.state.tagPageMeta;
      //
      return null;
    },


    visibleSelectMode: function(){
      var kind = this.$route.meta.kind;
      if (kind==='search') return false;
      return this.userOwnPage;
    },

    visibleHearts: function(){
      var kind = this.$route.meta.kind;
      if (kind==='search') return false;
      return true;
    },

    visibleOrdering: function(){
      var kind = this.$route.meta.kind;
      if (kind==='search') return false;
      return true;
    },


    userOwnPage: function(){
      var kind = this.$route.meta.kind;
      if (kind==='feed') return;
      var tagPageMeta = this.$store.state.tagPageMeta;
      var user = this.$store.state.user;
      if (this.$route.meta.kind === 'all') return true;
      else return (tagPageMeta && tagPageMeta.owner) && (tagPageMeta.owner.pk === user.pk);
      return false;
    },

    atLeastOneItemAvailable: function(){
      return this.$store.state.items && this.$store.state.items.length;
    },

    orderingFrontLabel: function(){
      return this.ordering ? this.ordering.text : 'Newest First';
    },

    subtagsFrontLabel: function(){
      return 'Filter by additional tag';
    },

    itemLayoutFrontLabel: function(){
      if (this.$store.state.activeDefaultItemLayout) {
        return _find(this.itemLayoutOptions, {value: this.$store.state.activeDefaultItemLayout}).text;
      }
      else {
        return this.itemLayout.text;
      }
    },

  },

  watch: {
    '$route.query': function(){
      this.syncOrdering();
      this.syncRating();
      this.syncSubtag();
    },
    itemLayout: function(val){
      this.syncItemLayout();
    },
    'subtagsPopoverOpen': function(val){
      if (!val) {
        this.subtagsTags = [];
      }
    },
    '$route.path': function(){
      this.resetFilters();
    },
  },

  created: function(){
    this.syncOrdering();
    this.syncRating();
    this.syncSubtag();
    this.syncItemLayout(1);
  },




  methods: {



    maybeShowProFeatureBox: function(layoutValue){
      var userIsPro = this.$store.state.user.is_premium;
      //var userIsPro = false;
      var proLayouts = [
        'panel-multiple',
        'grid',
        'grid-fixed',
        'card',
        'card-fixed',
        'card-small',
        'card-fixed-small',
      ];
      if (!userIsPro && (proLayouts.indexOf(layoutValue) > -1)) {
        this.itemLayoutPopoverOpen = false;
        this.$store.commit('dialogbox', {kind: 'profeature', subkind: 'itemlayout', featureCode: layoutValue});
        return true;
      }
      return false;
    },

    getQueryCopy: function(){
      return JSON.parse(JSON.stringify(this.$route.query));
    },





    syncRating: function(){
      var query = this.getQueryCopy();
      if (Object.keys(query).length) {
        this.rating = query.rating;
      } else {
        this.rating = null;
      }
    },


    changeRating: function(){
      var query = this.getQueryCopy();
      this.rating ? (query.rating = this.rating) : delete query.rating;
      this.$router.replace({query: query});
    },




    orderingSelectFrontClick: function(){
      this.orderingPopoverOpen = true;
    },

    orderingOptionClick: function(optionObject){
      //this.orderingPopoverOpen = false;
      this.$nextTick(function(){
        // Set Item Layout
        this.ordering = JSON.parse(JSON.stringify(optionObject));
        this.changeOrdering();
      });
    },





    syncSubtag: function(){
      var query = this.getQueryCopy();
      if (Object.keys(query).length && query.subtag) {
        this.subtag = query.subtag;
      } else {
        this.subtag = null;
      }
    },

    subtagOptionClick: function(tag){
      if (!this.$store.state.user.is_premium) {
        this.$store.commit('dialogbox', {kind: 'profeature', featureCode: 'subtags-filter'});
        this.subtagsPopoverOpen = false;
        return;
      }

      this.$nextTick(function(){
        this.subtag = tag.slug;
        this.changeSubtag();
      });
    },

    subtagsPremiumNotifClick: function(){
      this.$store.commit('dialogbox', {kind: 'profeature', featureCode: 'subtags-filter'});
      this.subtagsPopoverOpen = false;
    },

    changeSubtag: function(){
      var query = this.getQueryCopy();
      this.subtag ? (query.subtag = this.subtag) : delete query.subtag;
      this.$router.replace({query: query});
    },




    syncOrdering: function(){
      var query = this.getQueryCopy();
      if (Object.keys(query).length && query.ordering) {
        this.ordering = _find(this.orderingOptions, {'value': query.ordering});
      } else {
        this.ordering = null;
      }
    },



    changeOrdering: function(){
      var query = this.getQueryCopy();
      this.ordering ? (query.ordering = this.ordering.value) : delete query.ordering;
      this.$router.replace({query: query});
    },



    itemLayoutSelected: function(option){
      return this.$store.getters.visibleItemLayout === option.value;
      //return this.itemLayout.value === this.option.value;
    },

    itemLayoutSelectFrontClick: function(){
      this.itemLayoutPopoverOpen = true;
    },


    itemLayoutOptionClick: function(optionObject){
      this.itemLayoutPopoverOpen = false;

      var proFeatureBoxShowed = this.maybeShowProFeatureBox(optionObject.value);
      if (proFeatureBoxShowed) return;


      this.$nextTick(function(){
        // Clear Active Default Item Layout
        if (this.$store.state.activeDefaultItemLayout) this.$store.commit('activeDefaultItemLayout', null);
        // Set Item Layout
        this.itemLayout = JSON.parse(JSON.stringify(optionObject));
      });
    },



    syncItemLayout: function(init){
      if (init) {
        var itemLayout = _find(this.itemLayoutOptions, ['value', this.$store.state.itemLayout]);
        this.itemLayout = JSON.parse(JSON.stringify(itemLayout));
      } else {
        this.$store.commit('itemLayout', this.itemLayout.value);
      }
    },


    ratingHeartClick: function(ratingIndex){
      if (this.rating === ratingIndex) {
        this.rating = null;
      } else {
        this.rating = ratingIndex;
      }
      this.changeRating();
    },




    subtagsSelectFrontClick: function(){
      this.subtagsPopoverOpen = true;
      this.getSubtags();
    },







    resetFilters: function(){
      this.ordering = null;
      this.changeOrdering();
      this.rating = null;
      this.changeRating();
      this.subtag = null;
      this.changeSubtag();

      this.orderingPopoverOpen = false;
      this.subtagsPopoverOpen = false;

    },


    selectModeToggle: function(){

      //if (!this.$store.state.user.is_premium) {
      //  this.$store.commit('dialogbox', {kind: 'profeature', subkind: 'batch-edit', featureCode: 'batch-edit'});
      //  return;
      //}

      this.$store.commit('titlebarMenuEnabled', false);

      if (this.$store.state.itemsSelectMode) {
        this.$store.commit('itemsSelectMode', false);
        return;
      }

      //var firstItem = this.$store.state.items[0].pk;
      //this.$store.commit('selectItem', firstItem);
      if (!this.atLeastOneItemAvailable) return;
      this.$store.commit('itemsSelectMode', true);
    },





    getSubtags: function(){
      var data = {};
      this.subtagsLoading = true;
      this.$axios({
        url: this.$store.state.api + '/get-subtags/',
        method: 'get',
        params: {
          slug: this.$route.params.tagslug,
        },
      }).then(function(response){
        this.subtagsTags = response.data.subtags;
        this.subtagsLoading = false;
      }.bind(this), function(error){
        this.subtagsLoading = false;
      }.bind(this));
    },


    editTag: function(tagPk, kind, value){
      var data = {};
      data['pk'] = tagPk;
      data[kind] = value;
      this.$axios({
        url: this.$store.state.api + '/edit-tag/',
        method: 'post',
        data: data,
      }).then(function(response){
        this.$store.commit('updateTag', {pk: response.data.pk, item_layout: response.data.item_layout});

        if (this.$store.state.tagPageMeta && this.$store.state.tagPageMeta.pk === tagPk) {
          this.$store.commit('updateTagPageMeta', {item_layout: response.data.item_layout});
        }

      }.bind(this), function(error){
        alert('[itemstoolbox/editTag]. Something went wrong while updating tag. Please contact us if this problem persists.');
      }.bind(this));
    },



    tagDefaultLayoutIconClick: function(val){
      var proFeatureBoxShowed = this.maybeShowProFeatureBox(val);
      if (proFeatureBoxShowed) return;


      // remove if the same item_layout is clicked again
      if (this.currentlyOpenedTag.item_layout === val) {
        this.editTag(this.currentlyOpenedTag.pk, 'item_layout', '');
        return;
      }
      // otherwise just set it
      this.editTag(this.currentlyOpenedTag.pk, 'item_layout', val);
    },



    tagDefaultLayoutIconTitle: function(val){
      // layout not default
      if (this.currentlyOpenedTag.item_layout !== val) {
        return `Click to select this layout as a default layout for currently opened tag.`;
      }
      if (this.currentlyOpenedTag.item_layout === val) {
        return `This layout was set as a default layout for currently opened tag. Click this again to remove.`;
      }
    },

    clickMenuButton: function(){
      this.$store.commit('titlebarMenuEnabled', !this.$store.state.titlebarMenuEnabled);
    },
  },

};
</script>


<template>
<div class="itemstoolbox"  :class="{'mod-menu-enabled': this.$store.state.titlebarMenuEnabled}"  v-if="visible">


  <div class="itemstoolbox-items-wrapper">



    <div class="itemstoolbox-menu-items">


      <div class="itemstoolbox-item  mod-item-layout">



        <button ref="itemLayoutSelectFrontElement" type="button" class="itemstoolbox-item-select-front mod-layout" @click.prevent="itemLayoutSelectFrontClick" :title="itemLayoutFrontLabel">

          <div class="itemstoolbox-item-select-front-icon mod-layout">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 26 26"><path d="M 7 5 C 6.449219 5 6 5.449219 6 6 L 6 20 C 6 20.550781 6.449219 21 7 21 L 10 21 C 10.550781 21 11 20.550781 11 20 L 11 6 C 11 5.449219 10.550781 5 10 5 Z M 16 5 C 15.449219 5 15 5.449219 15 6 L 15 20 C 15 20.550781 15.449219 21 16 21 L 19 21 C 19.550781 21 20 20.550781 20 20 L 20 6 C 20 5.449219 19.550781 5 19 5 Z "></path></svg>
          </div>

          <!-- {{itemLayoutFrontLabel}}-->

          <div class="itemstoolbox-item-select-front-arrow">
            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path></svg>
          </div>

        </button>


        <Popover
          v-if="itemLayoutPopoverOpen"
          @close="itemLayoutPopoverOpen=false"
          :popover-style="{boxShadow: '0 1px 7px 0 rgba(0,0,0,.25)', borderRadius:'4px', marginTop:'4px'}"
          :anchor-el="$refs.itemLayoutSelectFrontElement"
          :transform-origin="{vertical: 'top', horizontal: 'left'}"
          :anchor-origin="{vertical: 'bottom', horizontal: 'left'}"
        >
          <div class="itemstoolbox-item-select-options-wrapper">

            <div v-for="option in itemLayoutOptions" :key="option.value" class="itemstoolbox-tag-layout-option-wrapper">
              <button type="button"
                :class="{'itemstoolbox-item-select-option': true, 'mod-selected': itemLayoutSelected(option)}"
                @click.prevent="itemLayoutOptionClick(option)"
              >
                {{option.text}}
              </button>
              <button type="button"
                v-if="$route.meta.kind==='tagpage'"
                class="itemstoolbox-tag-layout-btn"
                :class="{'mod-active': currentlyOpenedTag.item_layout===option.value}"
                @click.prevent="tagDefaultLayoutIconClick(option.value)"
                :title="tagDefaultLayoutIconTitle(option.value)"
              >
                <iconsvg v-if="currentlyOpenedTag.item_layout===option.value" icon="material-price-tag" />
                <iconsvg v-else icon="material-price-tag-outlined" />
              </button>
            </div>


            <div class="itemstoolbox-item-select-option-divider"></div>



            <button
              class="itemstoolbox-item-select-option-toggle"
              type="button"
              @click.prevent="$store.commit('itemLayoutFavs', !$store.state.itemLayoutFavs)"
            >
              <svg v-if="$store.state.itemLayoutFavs" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z M 16.292969 8.2929688 L 10 14.585938 L 7.7070312 12.292969 L 6.2929688 13.707031 L 10 17.414062 L 17.707031 9.7070312 L 16.292969 8.2929688 z"></path>
              </svg>
              <svg v-if="!$store.state.itemLayoutFavs" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 5 z" ></path>
              </svg>
              <span>Favicons</span>
            </button>


          </div>
        </Popover>

      </div>





      <div class="itemstoolbox-item"  v-if="visibleOrdering">


        <button ref="orderingSelectFrontElement" type="button" class="itemstoolbox-item-select-front" @click.prevent="orderingSelectFrontClick" :title="orderingFrontLabel">


          <div class="itemstoolbox-item-select-front-icon mod-order">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 26 26"><path d="M 2 0 C 0.898438 0 0 0.898438 0 2 L 9 12 L 17 12 L 26 2 C 26 0.898438 25.101563 0 24 0 Z M 9 13 L 9 21.40625 C 9 22.507813 9.898438 23.789063 11 24.1875 L 15 25.8125 C 16.101563 26.210938 17 25.695313 17 24.59375 L 17 13 Z "></path></svg>
          </div>

          <!-- {{orderingFrontLabel}}  -->

          <div v-if="ordering || rating" class="itemstoolbox-item-select-front-dot">
            <span></span>
          </div>

          <div class="itemstoolbox-item-select-front-arrow">
            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path></svg>
          </div>

        </button>


        <Popover
          v-if="orderingPopoverOpen"
          @close="orderingPopoverOpen=false"
          :popover-style="{boxShadow: '0 1px 7px 0 rgba(0,0,0,.25)', borderRadius:'4px', marginTop:'4px'}"
          :anchor-el="$refs.orderingSelectFrontElement"
          :transform-origin="{vertical: 'top', horizontal: 'left'}"
          :anchor-origin="{vertical: 'bottom', horizontal: 'left'}"
        >
          <div class="itemstoolbox-item-select-options-wrapper">


            <div v-if="visibleHearts" class="itemstoolbox-hearts">
              <button type="button" v-for="i in 5" class="itemstoolbox-heart" :title="'Show links only with rating of '+i" @click="ratingHeartClick(i)" @mouseenter="ratingHover=i" @mouseleave="ratingHover=null"
              :class="{'mod-selected': i==rating, 'mod-hover': i==ratingHover, 'mod-selected-lesser': i<=rating}" >

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17">
                <path d="M12,20c0,0-9-7.111-9-11.111C3,6.222,5.25,4,7.95,4C9.525,4,11.1,5.139,12,6.25C12.9,5.139,14.25,4,16.05,4 C18.75,4,21,6.222,21,8.889C21,12.889,12,20,12,20z"/></path></svg>

              </button>
            </div>

            <div class="itemstoolbox-item-select-option-divider"></div>

            <button
              v-for="option in orderingOptions"
              :key="option.value"
              :class="{'itemstoolbox-item-select-option': true, 'mod-selected': ordering && (ordering.value === option.value)}"
              type="button"
              @click.prevent="orderingOptionClick(option)"
            >
              {{option.text}}
            </button>


            <div class="itemstoolbox-item-select-option-divider"></div>

            <button
              type="button"
              :class="{'itemstoolbox-item-select-option': true, 'mod-selected': false}"
              @click.prevent="resetFilters"
            >
              Reset Filters &amp; Sorting
            </button>

          </div>
        </Popover>


      </div>






      <div class="itemstoolbox-item" >


        <button ref="subtagsSelectFrontElement" type="button" class="itemstoolbox-item-select-front" :class="{'mod-disabled': $route.meta.kind!=='tagpage'}" @click.prevent="subtagsSelectFrontClick" :title="subtagsFrontLabel">

          <div class="itemstoolbox-item-select-front-icon mod-subtags">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
              <path d="M 10.050781 3.9902344 A 1.0001 1.0001 0 0 0 9.3632812 4.2929688 L 1.5859375 12.070312 C 0.81445377 12.841796 0.81323703 14.124057 1.5839844 14.896484 C 1.5850844 14.897584 1.5848378 14.899291 1.5859375 14.900391 L 6.2851562 19.699219 A 1.0001 1.0001 0 1 0 7.7148438 18.300781 L 3.0078125 13.492188 A 1.0001 1.0001 0 0 0 3 13.484375 L 10.777344 5.7070312 A 1.0001 1.0001 0 0 0 10.050781 3.9902344 z M 15.611328 4 C 15.081328 4 14.572266 4.2109375 14.197266 4.5859375 L 6.6191406 12.164062 C 5.7941406 12.989062 5.7941406 14.329297 6.6191406 15.154297 L 10.845703 19.380859 C 11.670703 20.205859 13.010937 20.205859 13.835938 19.380859 L 21.414062 11.802734 C 21.789062 11.427734 22 10.918672 22 10.388672 L 22 6 C 22 4.895 21.105 4 20 4 L 15.611328 4 z M 19 6 C 19.552 6 20 6.448 20 7 C 20 7.552 19.552 8 19 8 C 18.448 8 18 7.552 18 7 C 18 6.448 18.448 6 19 6 z">
              </path>
            </svg>
          </div>


          <div v-if="subtag" class="itemstoolbox-item-select-front-dot">
            <span></span>
          </div>

          <div class="itemstoolbox-item-select-front-arrow">
            <svg width="24" height="24" viewBox="0 0 24 24"><path d="M 7.4296875 9.5 L 5.9296875 11 L 12 17.070312 L 18.070312 11 L 16.570312 9.5 L 12 14.070312 L 7.4296875 9.5 z"></path></svg>
          </div>

        </button>






        <Popover
          v-if="subtagsPopoverOpen"
          @close="subtagsPopoverOpen=false"
          :popover-style="{boxShadow: '0 1px 7px 0 rgba(0,0,0,.25)', borderRadius:'4px', marginTop:'4px'}"
          :anchor-el="$refs.subtagsSelectFrontElement"
          :transform-origin="{vertical: 'top', horizontal: 'left'}"
          :anchor-origin="{vertical: 'bottom', horizontal: 'left'}"
        >
          <div class="itemstoolbox-item-select-options-wrapper">

            <button type="button" @click.prevent="subtagsPremiumNotifClick" v-if="!$store.state.user.is_premium" class="itemstoolbox-item-select-options-empty mod-paidfeat">
              <strong>Only for GGather Plus</strong>
              <span>Additionally filter bookmarks in this tag using other tags that were assigned to those bookmarks.</span>
            </button>


            <div v-if="!subtagsLoading && (!subtagsTags || !subtagsTags.length)" class="itemstoolbox-item-select-options-empty">
              <strong>No Subtags</strong>
              <span>No other tags are assigned to bookmarks in this tag.</span>
            </div>


            <div v-if="subtagsLoading" class="itemstoolbox-item-select-options-spinner-wrapper">
              <spinner2
                :size="18"
                :line-size="2"
                :speed="0.3"
                line-fg-color="gray"
                line-bg-color="transparent"
              />
            </div>

            <div class="itemstoolbox-item-select-options-scrollable-wrapper  common-light-scrollbar">

              <button
                v-for="tag in subtagsTags"
                :key="tag.pk"
                :class="{'itemstoolbox-item-select-option': true, 'mod-selected': subtag===tag.slug}"
                type="button"
                @click.prevent="subtagOptionClick(tag)"
              >
                {{tag.name}}
              </button>
            </div>


            <div v-if="$store.state.user.is_premium"  class="itemstoolbox-item-select-option-divider"></div>

            <button
              v-if="$store.state.user.is_premium"
              type="button"
              :class="{'itemstoolbox-item-select-option': true, 'mod-selected': false}"
              @click.prevent="resetFilters"
            >
              Reset Filters &amp; Sorting
            </button>

          </div>
        </Popover>


      </div>




      <div class="itemstoolbox-item mod-select" v-if="visibleSelectMode">
        <button type="button" @click="selectModeToggle" class="itemstoolbox-btn" :class="{'mod-disabled': !atLeastOneItemAvailable}">
          Select
        </button>
      </div>


    </div>



    <div class="itemstoolbox-item mod-menu" v-if="visibleSelectMode">
      <button type="button" class="itemstoolbox-btn  mod-menu" @click.stop="clickMenuButton">
        <div class="itemstoolbox-btn-icon  mod-menu">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
            <path d="M 10 2 C 8.6983906 2 7.5978609 2.8387486 7.1835938 4 L 2 4 L 2 6 L 7.1835938 6 C 7.5978609 7.1612514 8.6983906 8 10 8 C 11.654 8 13 6.654 13 5 C 13 3.346 11.654 2 10 2 z M 15 4 L 15 6 L 22 6 L 22 4 L 15 4 z M 15 9 C 13.698391 9 12.597861 9.8387486 12.183594 11 L 2 11 L 2 13 L 12.183594 13 C 12.597861 14.161251 13.698391 15 15 15 C 16.654 15 18 13.654 18 12 C 18 10.346 16.654 9 15 9 z M 20 11 L 20 13 L 22 13 L 22 11 L 20 11 z M 7 16 C 5.6983906 16 4.5978609 16.838749 4.1835938 18 L 2 18 L 2 20 L 4.1835938 20 C 4.5978609 21.161251 5.6983906 22 7 22 C 8.654 22 10 20.654 10 19 C 10 17.346 8.654 16 7 16 z M 12 18 L 12 20 L 22 20 L 22 18 L 12 18 z"></path>
          </svg>
        </div>
      </button>
    </div>







  </div>



</div>
</template>










<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .itemstoolbox {

  }




  .itemstoolbox-items-wrapper {
    display: inline-block;
    //padding-right: 15px;
    //border-right: 1px solid #eee;
  }


  .itemstoolbox-menu-items {
    @media (max-width: 700px) {

      position: fixed;
      z-index: 200;
      top: 40px;
      right: 10px;
      padding: 5px 5px 0 0;


      background: white;
      border: 1px solid darken(white,10);
      box-shadow: 0 2px 15px 0px rgba(black,.07);

      border-radius: 8px;


      display: none;
      .itemstoolbox.mod-menu-enabled & {
        display: inline-block;
      }
    }
  }



  .itemstoolbox-item {
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
    position: relative;

    &.mod-menu {
      display: none;
      @media (max-width: 700px) {
        display: inline-block;
      }
    }

    &.mod-select {
      display: inline-block;
      @media (max-width: 700px) {
        display: none;
      }
    }
  }




  .itemstoolbox-reset-filters {
    background-color: #aaa;
    color: white;
    border-radius: 3px;
    border: 1px solid #999;
    display: inline-block;
    margin-right: 0px;
    padding: 0 5px;
    height: 21px;
    outline: none;
    font-size: 12px;
    line-height: 1;

    background-color: white;
    border: 1px solid rgba(black,.15);
    color: rgba(black,.9);

    & > .icon::before {
      font-size: 9px;
    }


    opacity: .3;
    &.mod-enabled {
      opacity: 1;
    }

    /deep/ svg {
      width: 11px;
      height: 11px;
    }
  }


  /*
  .itemstoolbox-btn {
    display: inline-block;
    padding: 2px 15px;
    border: 1px solid rgba(black,.2);
    color: rgba(black,.4);
    font-size: 11px;
    font-weight: 500;
    border-radius: 3px;
    //outline-color: rgba(black,.4);
    outline: none;
    background-color: white;

    & > .icon {
      &::before {
        line-height: 1.4;
      }
    }

    &.mod-active {
      border-color: rgba(black,1);
      color: rgba(white,1);
      background-color: rgba(black,.8);
    }
  }
  */


  .itemstoolbox-divider {
    margin: 0 5px;
    margin-top: 3px;
    margin-left: 10px;
    display: inline-block;
    width: 1px;
    height: 15px;
    background: rgba(black,.1);
  }


  .itemstoolbox-btn {
    color: white;
    border-radius: 3px;
    display: inline-block;
    margin-right: 0px;
    padding: 0 9px;
    height: 21px;
    outline: none;
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
    background-color: white;
    border: 1px solid rgba(black,.10);
    color: rgba(black,.9);

    & > .icon::before {
      font-size: 9px;
    }

    &.mod-disabled {
      opacity: .5;
      cursor: default;
    }

    @media (max-width: 700px) {
      height: 26px;
      padding: 0 10px;
    }

  }

  .itemstoolbox-btn-icon {
    margin-right: 2px;
    position: relative;
    top: -1px;

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      fill: rgba(black,.5);
      transform: translateX(1px) scale(1.5);
    }

  }






  .itemstoolbox-item-select-front {

    color: white;
    border-radius: 3px;
    display: inline-block;
    margin-right: 0px;
    padding: 0 9px;
    height: 21px;
    font-weight: 500;
    font-size: 11px;
    text-align: left;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    //width: 110px;
    padding-right: 21px;
    position: relative;
    line-height: 1;
    background-color: white;
    border: 1px solid rgba(black,.10);
    color: rgba(black,.9);


    &.mod-disabled {
      opacity: .5;
      cursor: default;
      pointer-events: none;
    }

    @media (max-width: 700px) {
      height: 26px;

      display: none;
      .itemstoolbox.mod-menu-enabled & {
        display: block;
      }
    }



  }


    .itemstoolbox-item-select-front-icon {
      margin-right: 2px;
      position: relative;
      top: -1px;
      svg {
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        fill: rgba(black,.5);
        transform: scale(1.1);
      }
      &.mod-layout svg {
        transform: scale(1.7) rotateZ(90deg);
      }
      &.mod-subtags svg {
        transform: scale(1.5);
      }
    }



    .itemstoolbox-item-select-front-dot {
      position: absolute;
      z-index: 8;
      top: 5px;
      right: 7px;
      @media (max-width: 700px) {
        top: 7px;
      }
      span {
        background: darken(white,50);
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 100px;
      }
    }



    .itemstoolbox-item-select-front-arrow {
      position: absolute;
      z-index: 5;
      top: 1px;
      right: 4px;
      @media (max-width: 700px) {
        top: 3px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
      path {
        fill: rgba(black,.4);
      }

      html.mod-transitions-enabled & path {
        transition: 100ms fill ease-out;
      }

      .itemstoolbox-item-select-front:hover & {
        path {
          fill: rgba(black,.8);
        }
      }



    }




  .itemstoolbox-item-select-options-wrapper {
    background: darken(white,1);
    line-height: 1;
    width: 170px;
    display: block;
    padding: 4px 0;
  }


  .itemstoolbox-item-select-options-scrollable-wrapper {
    max-height: 300px;
    overflow: auto;
    @media (max-height: 420px) {
      max-height: 150px;
    }
  }

  .itemstoolbox-item-select-option {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    color: rgba(black,.8);
    line-height: 1.2;
    padding: 5px 9px;
    padding-right: 16px;
    text-align: left;
    outline: none;

    html.mod-transitions-enabled & {
      transition: 100ms color ease-out, 100ms background-color ease-out;
    }

    &.mod-selected {
      font-weight: 600;
      color: rgba(black,1);
      background: rgba(black,.06);
    }

    &:hover {
      background: rgba(black,.03);
      color: rgba(black,1);
    }

  }


  @keyframes iisosw-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .itemstoolbox-item-select-options-spinner-wrapper {
    padding: 10px;
    opacity: 0;
    animation-name: iisosw-fade-in;
    animation-duration: 1000ms;
    animation-delay: 1000ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }




  .itemstoolbox-item-select-options-empty {
    background: transparent;
    display: block;
    text-align: left;
    //border: 1px dashed rgba(black,.2);
    font-size: 11px;
    line-height: 1.3;
    font-weight: 600;
    color: rgba(black,.8);
    border-radius: 6px;
    padding: 6px 9px;
    margin: 0px 0px;
    strong {
      display: block;
      font-weight: 600;
      color: rgba(black,.8);
    }
    span {
      display: block;
      margin-top: 2px;
      font-weight: 400;
      color: rgba(black,.6);
      font-size: 11px;
    }
    a {
      text-decoration: underline;
    }

    &.mod-paidfeat {
      border-radius: 0;
      margin-bottom: 5px;
      border-bottom: 1px dashed rgba(black,.2);
    }
  }




  .itemstoolbox-item-select-option-toggle {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
    color: rgba(black,.8);
    line-height: 1.2;
    padding: 5px 8px;
    padding-top: 2px;
    padding-bottom: 5px;
    //margin-bottom: 4px;
    text-align: left;
    //border-bottom: 1px solid darken(white,10);
    outline: none;

    svg {
      display: inline;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      fill: rgba(black,.8);
    }

    span {
      display: inline;
      vertical-align: middle;
    }

    &.mod-tag-default {
      border-top: 1px solid darken(white,10);
      font-weight: 600;
      padding-top: 8px;
    }

  }



  .itemstoolbox-item-select-option-divider {
    margin: 6px 0;
    margin-top: 2px;
    background-color: darken(white,8);
    height: 1px;
    display: block;
    width: 100%;
  }



  .itemstoolbox-item-select-option-button {
    display: block;
    font-weight: 600;
    font-size: 11px;
    color: rgba(black,.9);
    background: white;
    border-radius: 3px;
    border: 1px solid darken(white,10);
    margin: 0 8px;
    margin-bottom: 4px;
    line-height: 1.2;
    padding: 3px 4px;
    padding-right: 2px;
    text-align: left;
    outline: none;

    &.mod-disabled {
      border-color: darken(white,5);
      color: rgba(black,.3);
      pointer-events: none;
      cursor: default;
    }
  }






  .itemstoolbox-tag-layout-option-wrapper {
    display: block;
    position: relative;
  }

  .itemstoolbox-tag-layout-btn {
    position: absolute;
    z-index: 5;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 3px;
    padding-right: 6px;
    opacity: .2;
    outline: none;
    & svg {
      width: 16px;
      height: 16px;
      fill: darken(white,100);
    }
    &:hover {
      opacity: .8;
    }
    &.mod-active {
      opacity: .6;
    }
    &.mod-active:hover {
      opacity: .9;
    }
  }




















  .itemstoolbox-hearts {
    border-radius: 3px;
    height: 25px;
    padding-left: 7px;
    padding-top: 4px;
    padding-right: 4px;

    html.mod-transitions-enabled & {
      transition: background-color 300ms ease-out, border-color 300ms ease-out;
    }


  }




  .itemstoolbox-heart {
    //border: 1px solid #eee;
    display: inline-block;
    outline: none;
    min-height: 0;

    html.mod-transitions-enabled & {
      transition: background-color 100ms ease-out;
    }

    html.mod-transitions-enabled svg {
      transition: opacity 100ms ease-out;
    }

    svg {
      stroke: rgba(black,1);
      fill: rgba(black,0);
      opacity: .7;
    }


    &:hover svg {
      opacity: 1;
    }

    &.mod-hover svg {
      opacity: 1;
    }



    &.mod-selected-lesser svg {
      fill: rgba(black,1);
    }

    &.mod-selected svg {
      fill: rgba(black,1);
    }

  }


</style>






<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .itemstoolbox-menu-items {
    @media (max-width: 700px) {
      background-color: darken(white,77);
      border-color: darken(white,73);
    }
  }






  .itemstoolbox-item-select-front {
    background-color: darken(white,72);
    border-color: darken(white,68);
    color: rgba(white,.8);
  }


  .itemstoolbox-item-select-front-icon svg {
    fill: rgba(white,.7);
  }



  .itemstoolbox-item-select-front-dot {
    span {
      background: darken(white,30);
    }
  }

  .itemstoolbox-item-select-front-arrow path {
    fill: rgba(white,.4);
  }


  .itemstoolbox-item-select-options-wrapper {
    background-color: darken(white,72);
  }

  .itemstoolbox-item-select-options-empty {
    border-color: rgba(white,.2);
    color: rgba(white,.9);
    strong {
      color: rgba(white,.9);
    }
    span {
      color: rgba(white,.6);
    }
  }

  .itemstoolbox-item-select-option {
    color: rgba(white,.85);
    &.mod-selected {
      background: rgba(white,.1);
      font-weight: 600;
      color: rgba(white,1);
    }
    &:hover {
      background: rgba(white,.05);
      color: rgba(white,1);
    }
  }






  .itemstoolbox-item-select-option-toggle {
    color: rgba(white,.8);
    border-color: darken(white,65);
    svg {
      fill: rgba(white,.8);
    }
  }

  .itemstoolbox-item-select-option-divider {
    background-color: darken(white,65);
  }


  .itemstoolbox-btn {
    background-color: darken(white,72);
    border-color: darken(white,68);
    color: rgba(white,.8);

  }

  .itemstoolbox-btn-icon {
    svg {
      fill: rgba(white,.5);
    }
  }



  .itemstoolbox-tag-layout-btn {
    & svg {
      fill: darken(white,0);
    }
  }


  .itemstoolbox-heart svg {
    stroke: rgba(white,1);
  }

  .itemstoolbox-heart.mod-selected-lesser svg {
    fill: rgba(white,1);
  }
  .itemstoolbox-heart.mod-selected svg {
    fill: rgba(white,1);
  }






}
</style>
