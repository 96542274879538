<script>
import qs from 'qs';
export default {

  // http://stackoverflow.com/questions/18327314/how-to-allow-http-content-within-an-iframe-on-a-https-site

  props: [
    'url',
    'item',
    'proxy',
  ],

  data: function(){
    return {
      loadingSpinner: true,
      deferIframe: true,
      iframeBlocked: false,
      iframeError: false,
      httpBlocked: false,
      iframeSRC: null,
      parsedURL: null,
      iframeFullSize: true,
    }
  },

  computed: {
    itemIsImage: function(){
      var contentType = this.item.urldata.headers['Content-Type'];
      if (!contentType) { return false; }
      var contentTypeLower = contentType.toLowerCase();
      return (
        contentTypeLower.includes('image') ||
        contentTypeLower.includes('png') ||
        contentTypeLower.includes('jpeg') ||
        contentTypeLower.includes('jpg') ||
        contentTypeLower.includes('svg') ||
        contentTypeLower.includes('gif')
      );
    },


    // dont remember how exactly the iframeblocked & httpblocked were used to generate embeds
    // so I just extracted the same code only for use in notice... hackish...
    noticeData: function(){
      var xfo = (this.item.urldata.headers['X-Frame-Options']+'').toUpperCase();
      var isDENY = xfo.indexOf('DENY') > -1;
      var isSAMEORIGIN = xfo.indexOf('SAMEORIGIN') > -1;
      return {
        iframeBlocked: isDENY || isSAMEORIGIN,
        httpBlocked: this.parsedURL.protocol == 'http:',
      }
    },


  },

  watch: {
    '$store.state.viewer.item': function(newVal, oldVal){
      if (newVal!=oldVal) {
        this.changeItem();
      }
    },
    iframeBlocked: function(val){
      this.switchToProxy();
    },
    httpBlocked: function(val){
      this.switchToProxy();
    },
  },

  created: function(){
    this.parseURL();
    this.extractIframeSRC();
    setTimeout(function() {
      this.deferIframe = false;
    }.bind(this), 200);
    setTimeout(function() {
      this.loadingSpinner = false;
    }.bind(this), this.proxy ? 1400 : 800);
  },

  methods: {

    switchToProxy: function(){
      this.$store.commit('updateViewerKind', 'proxy');
    },

    iframeLoad: function(){
      this.loadingSpinner = false;
    },

    changeItem: function(){

      this.iframeFullSize = true;
      this.deferIframe = true;
      setTimeout(function() {
        this.deferIframe = false;
      }.bind(this), 200);

      this.parseURL();
      this.extractIframeSRC();
      this.loadingSpinner = true;

      setTimeout(function() {
        this.loadingSpinner = false;
      }.bind(this), this.proxy ? 1400 : 800);
    },


    parseURL: function(){
      var parsedURL = new URL(this.url);
      this.parsedURL = {
        hash: parsedURL.hash,
        host: parsedURL.host,
        hostname: parsedURL.hostname,
        href: parsedURL.href,
        origin: parsedURL.origin,
        password: parsedURL.password,
        pathname: parsedURL.pathname,
        port: parsedURL.port,
        protocol: parsedURL.protocol,
        search: parsedURL.search,
        username: parsedURL.username,
      }
    },

    extractIframeSRC: function(){
      this.iframeSRC = null;
      this.iframeBlocked = false;
      this.httpBlocked = false;
      var host = this.parsedURL.host;
      var pathname = this.parsedURL.pathname;

      if (this.proxy) this.nodemateProxy();

      else if (host.includes('youtube.com'))    this.youtube();
      else if (host.includes('soundcloud.com')) this.soundcloud();
      else if (host.includes('vimeo.com'))      this.vimeo();
      else if (host.includes('instagram.com'))  this.instagram();
      else if (host.includes('codepen.io'))     this.codepen();

      else if ( (host==='www.facebook.com') || (host==='facebook.com') ) {
        if (pathname.includes('/videos/'))      this.facebookVideo();
        if (pathname.includes('/posts/'))       this.facebookPost();
      }

      else {
        var xfo = (this.item.urldata.headers['X-Frame-Options']+'').toUpperCase();
        var isDENY = xfo.indexOf('DENY') > -1;
        var isSAMEORIGIN = xfo.indexOf('SAMEORIGIN') > -1;
        this.iframeBlocked = isDENY || isSAMEORIGIN;
        this.httpBlocked = this.parsedURL.protocol == 'http:';
        this.generic();
      }

    },



    nodemateProxy: function(){
      this.iframeSRC = 'https://nodemate.ggather.com/proxy?url=' + this.parsedURL.href;
      //this.iframeSRC = 'http://127.0.0.1:9700/proxy?url=' + this.parsedURL.href;
    },


    youtube: function(){
      var vidID = qs.parse(this.parsedURL.search);
      var vidID = vidID['?v'] || vidID['v'];
      this.iframeSRC = (
        'https://www.youtube-nocookie.com/embed/' + vidID +
        '?rel=0&amp;showinfo=0&autoplay=1'
      );
    },

    soundcloud: function(){
      this.iframeSRC = (
        'https://w.soundcloud.com/player/?url=' + this.parsedURL.href +
        '&amp;auto_play=true&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true'
      );
    },

    vimeo: function(){
      this.iframeSRC = 'https://player.vimeo.com/video' + this.parsedURL.pathname + '?autoplay=1&byline=0&portrait=0';
    },

    instagram: function(){
      this.iframeSRC = this.parsedURL.href + 'embed';
    },

    facebookVideo: function(){
      this.iframeFullSize = false;
      this.iframeSRC = 'https://www.facebook.com/plugins/video.php?href=' + this.parsedURL.href +
      '&show_text=0&autoplay=0';
    },

    facebookPost: function(){
      this.iframeFullSize = false;
      this.iframeSRC = 'https://www.facebook.com/plugins/post.php?href=' + this.parsedURL.href +
      '&show_text=1';
    },

    codepen: function(){
      var embedBase = this.parsedURL.href.replace('/pen/', '/embed/');
      this.iframeSRC = embedBase + '?width=600&height=600&theme-id=0&default-tab=result&embed-version=2';
    },

    generic: function(parsedURL){
      this.iframeSRC = this.parsedURL.href;
    },


  },

};
</script>


<template>
<div class="embedel" :class="{'mod-item-is-image': itemIsImage}">



  <embedimg v-if="itemIsImage" :item="item" :url="url" key="'embedimg'+url"></embedimg>




  <div v-else-if="(!iframeBlocked) && (!httpBlocked)" :class="['embedel-inner  mod-proxy-wrapper ']">

    <div v-if="proxy"  class="embedel-notice-wrapper">
      <div class="embedel-notice">

        <div>
          <span class="icon icon-visible-filled"></span>
          <span>You're viewing copy of the original website because</span>
          <span v-if="noticeData.iframeBlocked"> it doesn't allow framing. </span>
          <span v-else-if="noticeData.httpBlocked"> it doesn't use HTTPS. </span>
          <span>It may not work properly.</span>
          <a :href="url" target="_blank">
            <span>Open In New Tab</span>
          </a>
        </div>


      </div>
    </div>

    <iframe v-if="!deferIframe" @load="iframeLoad" @error="iframeError==true" width="300" height="500" allowfullscreen frameborder="0" scrolling="yes" :src="iframeSRC" class="embedel-iframe"
    :class="{'mod-full-size': iframeFullSize}"
    sandbox="allow-scripts allow-forms allow-pointer-lock allow-popups allow-orientation-lock allow-same-origin"
    ></iframe>

    <div v-if="loadingSpinner" class="embedel-spinner">
      <div class="embedel-spinner-inner">
        <spinner kind="wave" dark="true"></spinner>
      </div>
    </div>

  </div>







  <div v-else-if="iframeBlocked" class="embedel-inner  mod-center">
    <div class="embedel-not-allowed">
      <!-- <div class="emoji">(✖⌒✖)</div> -->
      <!-- <div class="emoji">✖</div> -->
      <div><!-- The <strong>{{parsedURL.host}}</strong> --> This website doesn't allow to view it on other websites via iframe. </div>
      <div class="embedel-btns-wrapper">
        <a :href="url" target="_blank" class="embedel-btn  mod-open-link">Open Link <span class="icon icon-external-link"></span></a>
        <button type="button" @click.prevent="$store.commit('viewer', {kind: 'proxy', item: item})" class="embedel-btn">Proxy <!-- <div class="embedel-beta">beta</div> --></button>
        <button type="button" @click.prevent="$store.commit('viewer', {kind: 'read', item: item})" class="embedel-btn">Reader <!-- <div class="embedel-beta">beta</div> --></button>
      </div>
      <div class="embedel-tip"><strong>Tip:</strong> You can middle or CTRL (&#8984;) click the link in library to open it in new tab.</div>
    </div>
  </div>

  <div v-else-if="httpBlocked" class="embedel-inner  mod-center">
    <div class="embedel-not-allowed">
      <!-- <div class="emoji">(✖⌒✖)</div> -->
      <!-- <div class="emoji">✖</div> -->
      <div><!--  Website <strong>{{parsedURL.host}}</strong>--> This link can't be viewed because it uses <em>HTTP</em> instead of <em>HTTPS</em>.</div>
      <div class="embedel-btns-wrapper">
        <a :href="url" target="_blank" class="embedel-btn  mod-open-link">Open Link <span class="icon icon-external-link"></span></a>
        <button type="button" @click.prevent="$store.commit('viewer', {kind: 'proxy', item: item})" class="embedel-btn">Proxy <!-- <div class="embedel-beta">beta</div> --></button>
        <button type="button" @click.prevent="$store.commit('viewer', {kind: 'read', item: item})" class="embedel-btn">Reader <!-- <div class="embedel-beta">beta</div> --></button>
      </div>
      <!-- <div><a class="embedel-direct-link" :href="url" target="_blank">Try using https on {{parsedURL.href}}</a></div> -->
      <div class="embedel-tip"><strong>Tip #1:</strong> You can middle or CTRL (&#8984;) click the link in library to open it in new tab.</div>
      <div class="embedel-tip"><strong>Tip #2:</strong> You can try changing this bookmark URL so it begins with <em>https://</em> <br>but have in mind some websites don't have it yet implemented and may not work.</div>
    </div>
  </div>

  <div v-else-if="iframeError" class="embedel-inner  mod-center">
    <div class="embedel-not-allowed">
      <!-- <div class="emoji">(✖⌒✖)</div> -->
      <!-- <div class="emoji">✖</div> -->
      <div>Something went wrong while trying to load <strong>{{parsedURL.host}}</strong>.</div>
      <div class="embedel-btns-wrapper">
        <a :href="url" target="_blank" class="embedel-btn  mod-open-link">Open Link <span class="icon icon-external-link"></span></a>
        <button type="button" @click.prevent="$store.commit('viewer', {kind: 'proxy', item: item})" class="embedel-btn">Proxy <!-- <div class="embedel-beta">beta</div> --></button>
        <button type="button" @click.prevent="$store.commit('viewer', {kind: 'read', item: item})" class="embedel-btn">Reader <!-- <div class="embedel-beta">beta</div> --></button>
      </div>
      <div class="embedel-tip"><strong>Tip:</strong> You can middle or CTRL (&#8984;) click the link in library to open it in new tab.</div>
    </div>
  </div>


</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .embedel {
    width: 100%;
    height: 100%;
    position: relative;
    background: white;

    overflow-x: hidden;
    overflow-y: auto;

    &.mod-item-is-image {
      background: transparent;
    }
  }




  .embedel-inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    background: darken(white,3);


    &.mod-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.mod-proxy-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

    }

  }


  .embedel-iframe {
    display: block;
    width: auto;
    margin: auto;
    background: white;

    &.mod-full-size {
      width: 100%;
      height: 100%;
    }

  }


  .embedel-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .embedel-spinner-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }



  .embedel-not-allowed {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 400;
    color: rgba(black,.7);
    text-align: center;
    //width: 100%;

    & > .emoji {
      font-size: 30px;
      margin-bottom: 5px;
      color: rgba(black,.5);
      //text-shadow: 0 2px 0 rgba(white,1);
      font-family: monospace, monospace;
    }

    & strong {
      font-weight: 500;
      color: rgba(black,.9);
    }

    & em {
      font-style: normal;
      //color: rgba(black,.7);
      //position: relative;
      //&::after {
      //    content: '';
      //    position: absolute;
      //    bottom: 0;
      //    left: 0;
      //    right: 0;
      //    height: 2px;
      //    display: block;
      //    background-color: #eee;
      //    z-index: 0;
      //}
    }

  }

  .embedel-btns-wrapper {
    margin-bottom: 28px;
    margin-top: 15px;
  }

  .embedel-btn {
    display: inline-block;
    margin-top: 10px;
    margin-right: 20px;
    padding: 5px 17px;
    padding-top: 7px;
    border: 1px solid rgba($clr-ggather,.2);
    border-radius: 2px;
    font-size: 15px;
    background-color: white;
    font-weight: 500;
    color: rgba($clr-ggather,1);

    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px rgba($clr-ggather, 0.15);
    }

    &:last-child {
      margin-right: 0;
    }

    & > .icon {
      margin-left: 5px;
      font-size: 11px;
      display: inline-block;
      transform: rotate3d(0,0,0,0);
    }
    & > .icon::before {
      line-height: 1.4;
    }

    &.mod-open-link {
      border: 1px solid rgba($clr-ggather,1);
      background-color: rgba($clr-ggather,1);
      color: white;
    }
  }

  .embedel-tip {
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    max-width: 500px;
    display: block;
    margin: 10px auto 3px auto;
    font-size: 12px;
    padding: 5px 10px;
    background-color: lighten(#eee,3);
    color: darken(white,55);
  }

  .embedel-beta {
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    margin-left: 5px;
    text-transform: uppercase;
    padding: 2px 2px;
    background-color: lighten(#eee,3);
  }


    .embedel-notice-wrapper {
      display: block;
      width: 100%;
    }

    .embedel-notice {
      display: block;
      margin: 0;
      background: darken(white,4);
      border-bottom: 1px solid darken(white,8);
      display: flex;
      justify-content: center;
      text-align: center;

      & > div {
        display: inline-block;
        color: rgba(black,.7);
        padding: 5px 20px 4px 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
        vertical-align: middle;
        span {
          vertical-align: middle;
        }
        a {
          display: inline-block;
          margin-left: 2px;
          background: rgba(black,.05);
          border: 1px solid rgba(black,.1);
          padding: 0px 5px 1px 5px;
          border-radius: 3px;
          vertical-align: middle;

          position: relative;


          html.mod-transitions-enabled & {
            transition: border 100ms ease-in-out, background 100ms ease-in-out;
          }

          &:hover {
            background: rgba(black,.1);
            border: 1px solid rgba(black,.2);
          }
        }
      }

      & .icon::before {
        line-height: 1.2;
        margin-right: 4px;
        margin-left: 1px;
      }

      > a {
        min-width: 160px;
        text-align: right;
        //text-decoration: underline;
        //color: $clr-ggather;
        //border-left: 1px solid #eee;
        line-height: 1.2;
        color: rgba(black,.6);
        font-size: 13px;
        padding-right: 25px;
        padding-left: 25px;
        display: inline-block;
        font-weight: 400;
        vertical-align: middle;

        html.mod-transitions-enabled & {
          transition: background 100ms ease-in-out, color 100ms ease-in-out;
        }

        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;

        & > span {
          display: block;
          vertical-align: middle;
        }

        &:hover {
          background: darken($clr-ggather,5);
          color: rgba(white,.8);
          //text-decoration: underline;
        }
      }

    }



</style>
















<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .embedel {
    background-color: darken(white, 75);
    //border-color: darken(white,68);
    &.mod-item-is-image {
      background: transparent;
    }
  }


  .embedel-inner {
    background-color: darken(white, 75);
  }




  .embedel-not-allowed {
    color: rgba(white,.5);
    font-weight: 300;
  }


  .embedel-tip {
    background-color: lighten(black,20);
    border-color: lighten(black,17);
    color: lighten(black,50);

    > strong {
      color: lighten(black,50);
    }
  }

  .embedel-btn {
    background-color: lighten(black,20);
    border-color: lighten(black,17);
    color: lighten(black,50);
  }

  .embedel-btn.mod-open-link {
    background-color: #3079f4;
    color: white;
    border-color: darken(#3079f4, 10);
  }


  .embedel-notice-wrapper {
    background-color: darken(white, 70);
    border-color: darken(white,65);
  }

  .embedel-notice {
    color: rgba(white,.9);
    background-color: darken(white, 70);
    border-color: darken(white,65);
    > div,
    > div a,
    > div span {
      color: rgba(white,.9);
      border-color: darken(white,65);
    }
  }



}

</style>
