<script>
import GeoPattern from 'geopattern';
export default {

  name: 'page-profile',

  head: {
    title: function(){
      return {
        inner: this.pageTitle,
      };
    },
  },

  data: function(){
    return {
      pageTitle: this.$route.params.username+'',
      profile: null,
      profileLoaded: false,
      blockTagsSliderRender: true,
      blockItemsComponentRender: true,
    };
  },

  mounted: function(){
    this.getUserProfile(1);
  },

  beforeDestroy: function(){
    this.clearGeopattern();
  },

  watch: {
    '$route.params': function(newVal, oldVal){
      if (oldVal && (newVal.username != oldVal.username)) {
        this.getUserProfile();
      }
    },
  },

  methods: {

    geopattern: function(){
      this.$nextTick(function(){

        if ((!this.profile.cover_image) && this.$refs.coverImageGeopattern) {
          var pattern = GeoPattern.generate(this.profile.username, {baseColor: '#111'});
          this.$refs.coverImageGeopattern.style.backgroundImage = pattern.toDataUrl();
        }

      });
    },

    clearGeopattern: function(){
      if ((!this.profile.cover_image) && this.$refs.coverImageGeopattern) {
        this.$refs.coverImageGeopattern.style.backgroundImage = '';
      }
    },


    unblockRenders: function(){
      setTimeout(function () {
        this.blockTagsSliderRender = false;
      }.bind(this), 500);
      setTimeout(function () {
        this.blockItemsComponentRender = false;
      }.bind(this), 1000);
    },

    getUserProfile: function(trackView){


      this.profile = null;
      this.profileLoaded = false;
      this.tagsHidden = false;
      this.$store.commit('profilePageMeta', null);

      this.$axios({
        url: this.$store.state.api + '/profile-page/',
        method: 'get',
        params: {
          username: this.$route.params.username,
        },
      }).then(function(response){
        this.profile = response.data;
        this.profileLoaded = true;
        this.$store.commit('profilePageMeta', response.data);
        this.changePageTitle();
        this.geopattern();
        trackView ? this.trackView() : null;
        this.unblockRenders();
      }.bind(this), function(error){
        this.$router.replace('/'+error.response.status);
      }.bind(this));

    },


    trackView: function(){

      this.$nextTick(function(){

        this.$axios({
          url: this.$store.state.api + '/track-view/',
          method: 'get',
          params: {
            kind: 'user',
            userpk: this.profile.pk,
          },
        }).then(function(response){
        }.bind(this), function(error){
        }.bind(this));

      });

    },



    changePageTitle: function(){
      if (this.profile.first_name || this.profile.last_name) {
        this.pageTitle = (
          this.profile.first_name +
          ' ' +
          this.profile.last_name +
          ' (' +
          this.profile.username +
          ')'
        );
        this.$emit('updateHead');
      }
    },


  },

};
</script>


<template>
<div class="router-page">
<div class="profile" :class="{'mod-loading': !profile}">


  <!-- User Header Wrapper -->
  <div class="userheader-wrapper">



    <!-- Loading User Header -->
    <div v-if="!profile" class="userheader-mock">
    </div>



    <!-- User Header -->
    <div v-if="profile" class="userheader"
    :style="{backgroundImage: (profile.cover_image ? 'url(\''+profile.cover_image+'\')' : ''), }" >

      <div v-if="!profile.cover_image" class="userheader-geopattern" ref="coverImageGeopattern"></div>




      <div class="userheader-inner">
        <div class="userheader-inner-inner">



            <!--
          <div class="userheader-top-btns">

            <a target="_blank" v-if="$store.state.user.is_staff" class="userheader-seld-edit-button" :href="$store.state.apiHost + '/log/'+profile.pk+'?appuser='+$store.state.user.pk">Log  <span class="icon icon-external-link"></span></a>

            <a target="_blank" v-if="$store.state.user.username==profile.username" class="userheader-seld-edit-button" :href="$store.state.apiHost + '/account/appearance/?appuser='+$store.state.user.pk">Change Appearance  <span class="icon icon-external-link"></span></a>

            <router-link  class="userheader-seld-edit-button" to="/settings" v-if="$store.state.user.username==profile.username">Change Appearance
            </router-link>


          </div>-->



          <div v-if="profile.profile_image" class="userheader-avatar">
            <img :src="profile.profile_image">
          </div>
          <div v-else class="userheader-avatar  is-blank">
            <img src="img/blank-user-male-padding.png">
          </div>



          <div class="userheader-text-wrapper">

            <div class="userheader-username">{{profile.username}}
              <a v-if="profile.is_premium" href="/upgrade" target="_blank" rel="noopener noreferrer" class="userheader-premium-badge" title="This user is on Pro plan">PLUS</a>
            </div>

            <div v-if="profile.first_name || profile.last_name || profile.bio" class="userheader-additional-info">

              <div v-if="profile.first_name || profile.last_name" class="userheader-full-name">
                {{profile.first_name}} {{profile.last_name}}
                <template v-if="profile.bio"> &bull; </template>
              </div>

              <div v-if="profile.bio" class="userheader-bio">{{profile.bio}}</div>

            </div>




            <div class="userheader-bottom-wrapper">



              <div class="userheader-subs-btns">

                <button type="button" class="userheader-detail mod-sub mod-btn" @click.prevent="$store.commit('dialogbox', {kind: 'followsbox', subkind: 'out', profile: profile})">
                  <span>{{profile.first_name ? profile.first_name : profile.username}} subscribes to {{profile.tag_followed_count}}  <!--  <span class="icon icon-gender-neutral-user-filled"></span>--> tag{{profile.tag_followed_count==1?'':'s'}}</span>
                </button>


                <button type="button" class="userheader-detail mod-sub mod-btn" @click.prevent="$store.commit('dialogbox', {kind: 'followsbox', subkind: 'in', profile: profile})">
                  <span> {{profile.tag_followers_count}} <!--  <span class="icon icon-gender-neutral-user-filled"></span>--> {{profile.first_name ? profile.first_name : profile.username}} tag{{profile.tag_followers_count==1?'':'s'}} are subscribed to
                  </span>
                </button>




              </div>


              <div>

                <div v-if="profile.current_location" class="userheader-detail">
                  Lives in {{profile.current_location}}
                </div>

                <!--
                <div class="userheader-detail">
                  Last active {{last_active|naturaltime}}
                </div>
                -->



                <a target="_blank" :href="profile.website" v-if="profile.website" class="userheader-detail" title="User website">
                  <span>

                    <span class="icon icon-link"></span>
                    <span class="userheader-website" >
                      {{profile.website}}
                    </span>

                  </span>

                </a>


              </div>

            </div>



          </div>


        </div>

      </div>
    </div>
    <!-- / User Header -->


  </div>
  <!-- User Header Wrapper -->





  <div class="profile-content">



    <div class="profile-section mod-tags">

      <div class="profile-section-heading mod-tags">
        <div class="profile-tags-title">Public tags
        <template v-if="profile && profile.profile_page_tags">({{ profile.profile_page_tags.length }})</template>
        </div>
      </div>

      <div class="profile-tags-slider-wrapper  mod-tagcards" :class="{'mod-loading': blockTagsSliderRender ||  (!profile) || (!profile.profile_page_tags)}">

        <div v-if="profile && profile.profile_page_tags && (!profile.profile_page_tags.length)" class="profile-tags-empty">No tags...</div>

        <span v-if="blockTagsSliderRender || (!profile) || (!profile.profile_page_tags)">
          <!-- <spinner kind="circle" dark="true"></spinner> -->
        </span>

        <div v-else class="profile-tags-slider  swiper-container   common-light-scrollbar" ref="profileTagsSlider">
          <div class="profile-tags-slider-inner  swiper-wrapper">
            <div v-for="tag in profile.profile_page_tags" class="profile-tags-slider-item  swiper-slide">
              <tagcard :tag="tag"></tagcard>
            </div>
          </div>
        </div>

        <!--
        <button type="button" class="profile-tags-slider-arrow mod-profilepage mod-next"></button>
        <button type="button" class="profile-tags-slider-arrow mod-profilepage mod-prev"></button>-->
      </div>


    </div>






    <div class="profile-section mod-links">

      <div class="profile-section-heading">
        <div class="profile-items-title">Public links
          <template v-if="profile">({{ profile.items_count }})</template>
        </div>
        <!--
        <div class="profile-items-toolbox-wrapper">
          <itemstoolbox v-if="profile" context="profilepage"></itemstoolbox>
        </div> -->
      </div>

      <div class="profile-items">
        <template v-if="profileLoaded && !blockItemsComponentRender">
          <items spinner="true" context="profilepage" :username="profile.username"></items>
        </template>
      </div>

    </div>




  </div>



</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .router-page {
    padding-top: 0;
  }


  .profile {
    position: relative;
  }


  .profile-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    .profile.mod-loading & {
      padding-top: 340px;
    }
  }


  .profile-section {
  }


  .profile-section-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    //border-bottom: 1px solid #eee;
    margin-right: 20px;
    padding-bottom: 5px;
    margin-left: 20px;
    min-height: 30px;
    margin-top: 35px;

    &.mod-tags {
      justify-content: flex-start;
      //align-items: baseline;
      margin-top: 30px;
      border-bottom: none;
    }
  }







  .profile-tags-slider-wrapper {
    //background-color: white;
    //border: 1px solid #eee;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
    margin-left: 20px;
    white-space: nowrap;
    position: relative;
    background-color: #ddd;


    &.mod-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eee;
    }

    &.mod-loading.mod-usercards {
      min-height: 185px;
    }

    &.mod-loading.mod-tagcards {
      min-height: 162px;
    }


  }

  .profile-tags-slider {
    width: auto;
    height: 100%;
    display: block;
    padding-left: 0px;
    padding-right: 0px;

    overflow-x: auto;
  }

  .profile-tags-slider-item {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    width: auto;

    opacity: .9;
    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-in-out;
    }
    &:hover {
      opacity: 1;
    }
  }


  .profile-tags-slider-arrow {
    display: inline-block;
    position: absolute;
    top: -35px;
    right: 0;
    height: 35px;
    width: 30px;
    z-index: 10;
    outline: none;
    //background-color: white;
    //background-color: #f7f7f7;

    &::before {
      font-family: 'ggather';
      content: '';
      font-size: 18px;
      position: relative;
    }

    &.mod-prev {
      //left: 0px;
      right: 30px;

      &::before {
        content: '\e00c';
        left: -2px;
      }
    }

    &.mod-next {
      //right: 0px;

      &::before {
        content: '\e053';
        right: -2px;
      }
    }

    html.mod-transitions-enabled & {
      transition: color 70ms ease-in-out;
    }
    color: rgba(#333,.5);
    &:hover {
      color: rgba(#333,1);
    }

    &.swiper-button-disabled {
      color: rgba(#333,.1);
    }

  }






    .profile-tags-empty {
      padding-top: 10px;
      color: #888;
      font-size: 14px;
      padding-top: 15px;
      color: #888;
      padding-top: 15px;
      color: #bababa;
      border: 1px solid #dadada;
      background-color: #eee;
      border-radius: 3px;
      padding: 10px 20px;
    }










    .profile-tags-title {
      font-size: 15px;
      font-weight: 400;
      color: #888;
      line-height: 1;
      display: inline-block;
    }

    .profile-tags {
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0;
      margin-bottom: 20px;
      overflow: hidden;
      height: 50px;

      &.mod-expanded {
        height: auto;
      }
    }



    .profile-tag {
      background-color: white;
      padding: 5px 10px;
      border-radius: 1px;
      border: 1px solid #eee;
      margin-right: 5px;
      display: inline-block;
      margin-bottom: 25px;
      .profile-tags.mod-expanded & {
        margin-bottom: 5px;
      }
    }


    .profile-tags-expand-btn {
      margin-left: 20px;
      outline: none;
      //background-color: white;
      line-height: 1;
      color: #888;
      border-radius: 2px;
      //font-weight: 500;
      font-size: 13px;
      //padding: 3px 6px;

      & > .icon::before {
        line-height: 1;
      }
    }



    .profile-items-title {
      font-size: 15px;
      font-weight: 400;
      //padding-left: 20px;
      color: #888;
      line-height: 1;
      display: inline-block;
    }



    .profile-items-toolbox-wrapper {
    }




  .profile-items {
    padding: 20px;
    padding-top: 15px;
    padding-top: 0px;
  }














  .userheader-wrapper {
    //padding: 20px;
    //padding-bottom: 0;
  }


  .userheader-mock {
    display: block;
    background-color: #eee;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 340px;
    width: auto;
    z-index: 10;
    opacity: 1;




  }




  .userheader {
    display: block;
    background-size: cover;
    background-position: 50%;
    background-color: #333;
    background-color: darken($clr-ggather, 30);

    //border-radius: 3px;

    overflow: hidden;

    position: relative;
  }

    .userheader-geopattern {
      background-size: 1000px;
      background-position: 0% 100% ;
      background-repeat: repeat;
      position: absolute;
      opacity: .3;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border-radius: 3px;
      overflow: hidden;
    }




    .userheader-inner {
      //border-radius: 3px;
      background-image: linear-gradient(to bottom, rgba(black,.2), rgba(black,.7));
      position: relative;
      z-index: 5;

    }


    .userheader-inner-inner {
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      padding-top: 150px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;

    }


    .userheader-avatar {
      display: inline-block;
      width: 150px;
      height: 150px;
      overflow: hidden;
      margin: 20px 30px;
      margin-left: 0;
      margin-top: 0;
      vertical-align: middle;

      img {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #eee;
        border-radius: 300px;
      }


      &.is-blank {
        img {
          background-color: #ddd;
        }
      }

    }



    .userheader-top-btns {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 0;
    }

    .userheader-seld-edit-button {
      padding: 8px 20px 7px 20px;
      font-weight: 500;
      font-size: 14px;
      background-color: white;
      border-radius: 2px;
      display: inline-block;
      margin-left: 10px;

      html.mod-transitions-enabled & {
        transition: opacity 100ms ease-out;
      }

      color: #333;
      opacity: .6;

      .userheader-inner:hover & {
        opacity: .8;

        &:hover {
          opacity: 1;
        }
      }

      &:hover,
      &:focus {
        opacity: 1;
      }



      & .icon {
        display: inline-block;
        margin-left: 4px;
        font-size: 12px;
        &::before {
          line-height: 1.2;
        }
      }
    }



    .userheader-text-wrapper {
      display: inline-block;
      vertical-align: middle;
      padding-bottom: 30px;
      width: 70%;
      width: calc(100% - 180px);
    }


      .userheader-username {
        color: white;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.2;
      }



      .userheader-premium-badge {
        display: inline-block;
        background-color: $clr-ggather;
        color: white;
        border-radius: 2px;
        padding: 5px 6px 3px 6px;
        font-size: 12px;
        margin-left: 2px;
        line-height: 1;
      }




      .userheader-additional-info {
        height: 40px;
        overflow: hidden;
      }


        .userheader-full-name {
          color: white;
          display: inline;
          font-size: 15px;
          opacity: .8;
        }


        .userheader-bio {
          color: white;
          display: inline;
          font-size: 15px;
          opacity: .8;
        }






      .userheader-bottom-wrapper {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        @media (max-width:1100px){
          display: block;
        }
      }


        .userheader-follow-btn {
          display: inline-block;
          color: rgba(white,.8);
          padding: 5px 10px;
          border: 1px solid rgba(white,.2);
          border-radius: 2px;
          vertical-align: baseline;
          background-color: rgba(white,.15);
          outline: none;

          .icon {
            display: none;
          }

          &.is-followed {
            border-color: rgba(white,.1);
            background-color: rgba(white,.0);
            .icon {
              display: inline;
            }
          }
        }


        .userheader-subs-btns {
          display: inline-block;
          @media (max-width:1100px){
            display: block;
            width: 100%;
            padding-top: 5px;
            padding-bottom: 10px;
          }
        }

        .userheader-detail {
          color: rgba(white,.8);
          display: inline-block;
          margin-left: 30px;
          font-size: 13px;
          vertical-align: baseline;
          position: relative;
          font-weight: 300;

          @media (max-width:1100px){
            margin-left: 0;
            margin-right: 20px;
          }

          &.mod-btn {
            outline: none;
            margin-left: 6px;
            &::before {
              content: '';
              position: absolute;
              z-index: 0;
              top: -2px;
              left: -6px;
              right: -6px;
              bottom: -2px;
              border: 1px solid rgba(white,.1);
              background-color: rgba(white,.1);
              border-radius: 2px;
              html.mod-transitions-enabled & {
                transition: background-color 100ms ease-out;
              }
              cursor: pointer;
            }
            &:hover {
              &::before {
                background-color: rgba(white,.15);
              }
            }

            & > span {
              position: relative;
              z-index: 5;
            }
          }

          &.mod-btn {


            //&::before {
            //    content: '';
            //    position: absolute;
            //    z-index: 0;
            //    left: 0px;
            //    right: 0px;
            //    bottom: 2px;
            //    height: 2px;
            //    background-color: rgba(white,.3);
            //}
            //&:hover {
            //    &::before {
            //        background-color: rgba(white,.5);
            //    }
            //}
          }

          &.mod-sub {
            display: block;
            //min-width: 240px;
            text-align: left;
            margin-bottom: 7px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }


        .userheader-website {
        }





  @media screen and (max-width: 730px) {


    .userheader-inner {
    }

    .userheader-avatar {
      //display: block;
      //width: 100%;
    }
    .userheader-text-wrapper {
      display: block;
      width: 100%;
      padding-bottom: 30px;
    }

    .userheader-detail  {
      padding-left: 0;
      display: block;
      margin-top: 5px;
    }


  }


</style>











<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .profile-tags-slider-wrapper.mod-loading {
    background-color: lighten($clr-theme-dark,10);
  }

  .userheader-mock {
    background-color: lighten($clr-theme-dark,10);
  }



  .profile-tags-slider-wrapper  {
    background: darken(white,60);
  }

  .profile-tags-empty {
    background-color: darken(white,80);
    border-color: darken(white,70);
  }



}
</style>
