<script>
export default {

  props: {
    focusOnMounted: {
      type: Boolean,
    }
  },

  data: function(){
    return {
      id: this.generateID(),
    };
  },


  render: function(createElement) {
    return createElement('div', {attrs: {id: this.id, tabindex: '-1'}, ref: 'main'}, this.$slots.default);
  },


  mounted: function(){
    /*
      https://vuejs.org/v2/api/#mounted
      Note that mounted does not guarantee that all child components have also been mounted. If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted:
    */
    this.portalElement();
    this.focusOnMounted ? this.focusElement() : null;
  },


  updated: function(){
    this.portalElement();
  },


  beforeDestroy: function(){
    this.removeElement();
  },


  methods: {


    getElement: function(){
      return this.$refs.main;
    },


    portalElement: function(){
      var el = this.getElement();
      if (!el) return;
      // we need to check if it already has body as parent
      // because v-model element lose focus on input
      if (el.parentElement!==document.body) {
        document.body.appendChild(el)
      }
    },


    removeElement: function(){
      var el = this.getElement();
      el ? el.remove() : null;
    },


    focusElement: function(){
      this.$nextTick(function(){
        var el = this.getElement();
        var originalWindowScroll = {
          x: window.scrollX,
          y: window.scrollY,
        }
        if (el) {
          el.focus();
          window.scrollTo(originalWindowScroll.x, originalWindowScroll.y);
        }
      });
    },


    generateID: function(){
      var uuid = function(){
        //https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
      };
      return 'portal-' + uuid();
    },


  },

};
</script>
