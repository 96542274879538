<script>
import contains from 'dom-helpers/contains';
import Fuse from 'fuse.js';
import _merge from 'lodash/merge';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
export default {

  props: [
    'urlbookmark',
    'instantSaveDisabled',

  ],

  data: function(){
    return {

      newTagValue: '',
      newTagLoading: false,

      tagsFilterValue: '',
      tagsFilterFocused: false,
      tagsFiltersOpen: false,
      tagsFiltersTab: 3,

      tagsSelected: [],

    }
  },


  computed: {


    // no tags at al...
    tagsLoading: function(){
      if (this.$store.state.tags===null) return true;
      return false;
    },

    // no tags at all...
    tagsEmpty: function(){
      if (this.$store.state.tags===null) return false;
      if (this.$store.state.tags && (!this.$store.state.tags.length)) return true;
      return false;
    },


    tagsFilteredCount: function(){
      if (this.$store.state.tags===null) return null;
      if (this.$store.state.tags && (!this.$store.state.tags.length)) return null;
      if (!this.$store.getters.tagsTree) return null;
      if (!this.tagsFilterValue) return null;
      if (!this.tagsFiltered) return null;
      return this.tagsFiltered.length;
    },



    tagsFilterExpanded: function(){
      if (this.tagsFiltersOpen) return true;
      if (this.tagsFilterFocused) return true;
      return !!this.tagsFilterValue;
    },

    //urlbookmarkFlatTagsPks: function(){
    //  if (!this.urlbookmark) return false;
    //  if (!this.urlbookmark.tags) return false;
    //  var tagsCopy = _cloneDeep(this.urlbookmark.tags);
    //  return tagsCopy.map(function(tag, i){
    //    return tag.pk;
    //  }.bind(this));
    //},




    tagsFiltered: function(){

      if (!this.tagsFilterValue) return null;
      if (!this.$store.state.tags) return null;
      if (this.$store.state.tags && (!this.$store.state.tags.length)) return [];
      var output = [];
      var tagsClone = _cloneDeep(this.$store.state.tags);

      var colors = {default: 1, brown: 2, red: 3, orange: 4, green: 5, teal: 6, blue: 7, pink: 8, purple: 9};
      var filterValue = this.tagsFilterValue.trim();
      var specialFilterEnabled = this.tagsFilterValue.trim().slice(0,1) === ':';
      var specialFilterValue = this.tagsFilterValue.trim().slice(1);
      var fuzzySearchPKs = [];
      var storeTags = this.$store.state.tags;

      // fuzzy search...
      if ((filterValue) && (!specialFilterEnabled)) {

        //var tagsClone = _cloneDeep(context.state.tags);
        var fuse = new Fuse(storeTags, {
          keys: ['name'],
          threshold: 0.2,
          //distance: 1000,
        })
        var searchedTags = fuse.search(filterValue);
        fuzzySearchPKs = searchedTags.map(function(result, index){
          return result.item.pk;
        }.bind(this));

      }



      // filters...
      for (var tag of tagsClone) {

        // filter the tags...
        var visible = false;

        // show all...
        if (filterValue==='' || filterValue===':') {
          visible = true;
        }

        // fuzzy search...
        else if (!specialFilterEnabled) {
          visible = !!(fuzzySearchPKs.indexOf(tag.pk) > -1);
        }

        // other special filters...
        else if (specialFilterEnabled) {

          // color
          if (Object.keys(colors).indexOf(specialFilterValue) > -1) {
            visible = tag.color === colors[specialFilterValue];
          }

          // favorite
          else if (specialFilterValue==='fav') {
            visible = !!tag.favorite;
          }
          // private
          else if (specialFilterValue === 'priv') {
            visible = tag.visibility === 1;
          }
          // unlisted
          else if (specialFilterValue === 'un') {
            visible = tag.visibility === 2;
          }
          // public
          else if (specialFilterValue === 'pub') {
            visible = tag.visibility === 3;
          }


          // selected...
          else if (specialFilterValue === 'sel') {
            visible = this.tagsSelected.indexOf(tag.pk) > -1;
          }

          // recently used
          else if (specialFilterValue === 'rec') {
            visible = !!tag.recently_used;
          }
          // parent tag
          else if (specialFilterValue.slice(0,6) === 'parent') {
            var parentSlug = specialFilterValue.slice(7);
            var parentTagFoundBySlug = _find(storeTags, {slug: parentSlug});
            if (parentTagFoundBySlug && parentTagFoundBySlug.pk === tag.parent) {
              visible = true;
            }
            else if (tag.slug===parentSlug) {
              visible = true;
            }
          }
          // parent tag pk
          //else if (!isNaN(parseInt(specialFilterValue))) {
          //  //visible = tag.pk == filterValue;
          //  visible = true;
          //}
          // first letter
          else if (specialFilterValue.length===1) {
            var tagFirstLowerLetter = tag.name.trim().toLowerCase().slice(0,1);
            if (specialFilterValue==='#') {
              visible = /\W|_|\d/gi.test(tagFirstLowerLetter);
            }
            else {
              visible = tagFirstLowerLetter === specialFilterValue.toLowerCase();
            }
          }

        }


        if (visible && (output.indexOf(tag.pk) === -1)) output.push(tag.pk);

        //tag.__visible = visible;
      }


      return output;
    },









  },


  watch: {

    '$store.state.tags': {
      immediate: true,
      handler: function(newVal, oldVal){
        // if they're loaded...
        if ( (!oldVal) && newVal ) this.selectTagFromCurrentTagPage();
      },
    },

    'urlbookmark.tags': function(){
      this.syncTagsSelected();
    },

    'tagsFilterValue': function(newVal){
      if (this.tagsFiltersOpen) {
        this.tagsFiltersOpen = false;
      }
    },

  },



  mounted: function(){
    document.addEventListener('click', this.savetagsBodyClick, false);
    this.syncTagsSelected();
  },


  beforeDestroy: function(){
    document.removeEventListener('click', this.savetagsBodyClick, false);
  },

  methods: {



    savetagsBodyClick: function(event){
      if (!this.$refs.sectionTagsFilters) return;
      if (!this.$refs.sectionHeader) return;

      var clickedInsideSectionTagsFilters = contains(this.$refs.sectionTagsFilters, event.target);
      var clickedInsideSectionHeader = contains(this.$refs.sectionHeader, event.target);

      if ( (!clickedInsideSectionTagsFilters) && (!clickedInsideSectionHeader)) {
        this.tagsFiltersOpen = false;
      }
    },


    syncTagsSelected: function(){
      if (!this.urlbookmark) {
        this.tagsSelected = [];
      };
      if (!this.urlbookmark.tags) {
        this.tagsSelected = [];
      };
      var tagsSelected = [];
      if (this.urlbookmark && this.urlbookmark.tags) {
        for (var tag of this.urlbookmark.tags) {
          tagsSelected.push(tag.pk);
        }
      }
      this.tagsSelected = tagsSelected;
    },


    tagSelectEvent: function(tagPk){
      if (this.tagsSelected.indexOf(tagPk) === -1) {
        this.tagsSelected.push(tagPk);
        this.editURLBookmarkTag('add', tagPk);
      }

      // add to parent automatically... maybe make it optional?
      /*
      var tag = _find(this.$store.state.tags, {pk: tagPk});
      if (tag && tag.parent) {
        var tagParent = _find(this.$store.state.tags, {pk: tag.parent});

        if (this.tagsSelected.indexOf(tagParent.pk) === -1) {
          this.tagsSelected.push(tagParent.pk);
        }
        this.editURLBookmarkTag('add', tagParent.pk);
      }
      */

    },

    tagDeselectEvent: function(tagPk){
      var tagIndex = this.tagsSelected.indexOf(tagPk);
      if (tagIndex > -1) {
        this.tagsSelected.splice(tagIndex, 1);
        this.editURLBookmarkTag('remove', tagPk);
      }
    },




    editURLBookmarkTag: function(action, tagPk){
      this.$axios({
        url: this.$store.state.api + '/edit-urlbookmark-tag/',
        method: 'post',
        data: {
          url: this.urlbookmark.url,
          action: action,
          tag_pk: tagPk,
          return_urlbookmark: true,
        },
      }).then(function(response){
        if (response.data.urlbookmark) this.$store.commit('replaceItemTags', response.data.urlbookmark);
        this.$emit('edit', {ubEditIndicator: false, ubEditFinished: true});
        if (action==='add') this.maybeRemoveFromUntagged();
      }.bind(this));
    },



    maybeRemoveFromUntagged: function(){
      if ((this.$route.meta.kind==='all') && this.$route.query.untagged) {
        this.$store.commit('deleteItem', {pk: this.urlbookmark.pk});
      }
    },



    selectTagFromCurrentTagPage: function(){
      if (this.$route.meta.kind!='tagpage') return false;
      if (this.$route.params.username!=this.$store.state.user.username) return false;
      var tag = _find(this.$store.state.tags, ['slug', this.$route.params.tagslug]);

      this.$nextTick(function(){
        if (this.tagsSelected.indexOf(tag.pk) === -1) {
          this.tagsSelected.push(tag.pk);
          this.editURLBookmarkTag('add', tag.pk);
        }
      });

    },








    tagsFilterFocus: function(){
      this.tagsFilterFocused = 1;
      this.tagsFiltersOpen = 1;
    },

    tagsFilterBlur: function(){
      this.tagsFilterFocused = 0;
    },

    tagsFilterClick: function(){
      //if (this.tagsFilterFocused) {
        this.tagsFiltersOpen = 1;
      //}
    },

    tagsFiltersClose: function(){
      this.tagsFiltersOpen = 0;
    },

    tagsFiltersTabChange: function(requestedTab){
      this.tagsFiltersTab = requestedTab;
    },

    tagsFiltersFilter: function(event){

      if (event.type==='firstletter') {
        this.tagsFilterValue = ':' + event.value;
      }
      else if (event.type==='color') {
        this.tagsFilterValue = ':' + event.value;
      }
      else if (event.type==='favorite') {
        this.tagsFilterValue = ':fav';
      }
      else if (event.type==='private') {
        this.tagsFilterValue = ':priv';
      }
      else if (event.type==='unlisted') {
        this.tagsFilterValue = ':un';
      }
      else if (event.type==='public') {
        this.tagsFilterValue = ':pub';
      }
      else if (event.type==='selected') {
        this.tagsFilterValue = ':sel';
      }
      else if (event.type==='recent') {
        this.tagsFilterValue = ':rec';
      }
      else if (event.type==='parent') {
        this.tagsFilterValue = ':parent:' + event.value;
      }
      else if (event.type==='all') {
        this.tagsFilterValue = '';
      }
      else {
        this.tagsFilterValue = ':' + event.type + ':' + event.value;
      }


      if (this.tagsFiltersOpen) {
        this.tagsFiltersOpen = false;
      }

    },

    clearTagsFilter: function(){
      if (!this.tagsFilterValue) return;
      this.tagsFilterValue = '';

      //this.$nextTick(function(){
      //  this.tagsFiltersOpen = true;
      //});
    },


    collapseOrUncollapse: function(){
      this.$store.commit('sidebarCollapse', !this.$store.state.sidebarCollapse);
    },




    clearNewTag: function(){
      if (!this.newTagValue) return;
      this.newTagValue = '';
    },



    addNewTag: function(){

      if(!this.newTagValue) return;

      this.newTagLoading = true;

      this.$store.dispatch('apiNewTag', {
        name: this.newTagValue,
        onSuccess: function(response){
          this.newTagValue = '';
          this.newTagLoading = false;
        }.bind(this),
        onError: function(error){
          this.newTagLoading = false;
        }.bind(this),

      });

    },







  },


};
</script>



<template>
  <div class="savetags">

    <div class="savetags-section mod-header"  ref="sectionHeader">

      <div class="savetags-section-title">Tags</div>


      <div class="savetags-tags-filter" :class="{'mod-expanded': tagsFilterExpanded}">

        <form action="" @submit.prevent="null"  class="savetags-tags-filter-input-wrapper">

          <input class="savetags-tags-filter-input" type="text" placeholder="" ref="tagsFilterInput" v-model="tagsFilterValue" @focus="tagsFilterFocus" @blur="tagsFilterBlur" @click="tagsFilterClick"  />

          <div class="savetags-tags-filter-icon">
            <iconsvg icon="w10-search" />
          </div>

          <button v-if="tagsFilterValue" class="savetags-tags-filter-clear" type="button" @click.prevent="clearTagsFilter">
            <iconsvg icon="material-delete" />
          </button>

        </form>

      </div>



    </div>


    <div v-if="tagsFiltersOpen" class="savetags-section mod-tags-filters" ref="sectionTagsFilters">

      <div class="savetags-tags-filters-wrapper">
        <tagsfilters
          context="savetags"
          @filter="tagsFiltersFilter"
          @close="tagsFiltersClose"
          @tabchange="tagsFiltersTabChange"
          :tab="tagsFiltersTab"
        />
      </div>

    </div>

    <div class="savetags-section mod-tags">



      <div v-if="tagsFilteredCount===0" key="savetags-list-filtered-empty" class="savetags-list-empty">
        <strong>No matching tags.</strong>
        <span>It seems like you don't have any tags that match the selected filter.</span>
      </div>

      <!--
      <div v-else-if="tagsFilteredCount>0" key="savetags-list-tags-are-filtered" class="savetags-list-empty">
        <strong v-if="tagsFilteredCount===1">{{tagsFilteredCount}} tag matches your filter.</strong>
        <strong v-else>{{tagsFilteredCount}} tags match your filter.</strong>
        <span>While in filter mode you can't add new tags or use drag and drop interactions.</span>
      </div>  -->

      <div v-if="tagsLoading" key="savetags-list-loading" class="savetags-list-loading">
        <spinner kind="dots" dark="true" />
      </div>

      <div v-else-if="tagsEmpty" key="savetags-list-empty" class="savetags-list-empty">
        <strong>You don't have any tags yet.</strong>
        <span>Create your first tag using box below. Tags will be also imported when using <router-link to="/settings/import" @click.native="$store.commit('viewer', 0)">bookmarks import</router-link>.</span>
      </div>



      <savetagstag
        v-for="tag in $store.getters.tagsTree"
        :key="'savetags-'+tag.pk"
        :tag="tag"
        :parent="true"
        :urlbookmark="urlbookmark"
        :tags-selected="tagsSelected"
        :tags-filtered="tagsFiltered"
        :tags-filter-value="tagsFilterValue"
        @select="tagSelectEvent"
        @deselect="tagDeselectEvent"
      />


    </div>



    <div v-if="($store.state.tags!==null) && (!this.tagsFilterValue)" class="savetags-section mod-new-tag" key="savetags-section-mod-new-tag">

      <form action="" @submit.prevent="addNewTag" class="savetags-new-tag">
        <input class="savetags-new-tag-input" type="text" placeholder="New Tag..." v-model="newTagValue" >
        <span key="newtagaddicon" v-if="!newTagLoading" class="savetags-new-tag-icon">
          <iconsvg icon="material-add" />
        </span>
        <button key="newtagclear" v-if="newTagValue" class="savetags-new-tag-clear" type="button" @click.prevent="clearNewTag">
          <iconsvg icon="material-delete" />
        </button>
        <div key="newtagspinner" v-if="newTagLoading" class="savetags-new-tag-spinner">
          <div>
            <spinner2 :size="18" :line-size="2" :speed="0.8" line-fg-color="black" line-bg-color="transparent" />
          </div>
        </div>
      </form>

    </div>



  </div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .savetags {
    background: white;
    border: 1px solid darken(#eee,1);
    border-top: none;
    border-bottom: none;
  }




  .savetags-section {
    padding-left: 15px;
    padding-right: 15px;

    &.mod-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding-bottom: 9px;
      padding-top: 10px;
    }


    &.mod-tags-filters {
      padding-bottom: 3px;
      padding-top: 1px;
    }

    &.mod-tags {
      //min-height: 300px;
      padding-bottom: 10px;
    }

    &.mod-new-tag {
      padding-bottom: 15px;
    }

  }




    .savetags-section-title {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.2;
      color: rgba(black,.5);
      padding-top: 7px;
    }



    .savetags-tags-filter {

    }




    .savetags-tags-filters-wrapper {
    }











      .savetags-list-empty {
        background: transparent;
        display: block;
        border: 1px dashed rgba(black,.2);
        font-size: 13px;
        line-height: 1.3;
        font-weight: 400;
        color: rgba(black,.8);
        border-radius: 6px;
        padding: 8px 10px;
        margin-top: 8px;

        strong {
          display: block;
          font-weight: 500;
          color: rgba(black,.8);
        }

        span {
          display: block;
          margin-top: 3px;
          font-weight: 400;
          color: rgba(black,.6);
          font-size: 12px;

        }

        a {
          text-decoration: underline;
        }
      }












      .savetags-tags-filter {
        position: relative;
      }

      .savetags-tags-filter-input-wrapper {
        display: block;
        width: 100%;
        position: relative;
        top: 4px;


      }

        .savetags-tags-filter-input {

          display: block;
          appearance: none;
          background: transparent;
          color: rgba(black,.5);
          font-size: 11px;
          font-weight: 500;
          line-height: 1.2;
          border-radius: 4px;
          text-align: left;
          outline: none;

          position: relative;
          z-index: 2;
          padding: 5px 8px 5px 8px;

          &::placeholder {
            color: rgba(black,.5);
          }


          width: 160px;
          padding-left: 27px;
          padding-right: 24px;
          background: rgba(black,.05);
          color: rgba(black,.9);


          html.mod-transitions-enabled & {
            transition: background 170ms ease-in-out, padding 170ms ease-in-out, width 170ms ease-in-out;
          }


          &:hover {
            background: rgba(black,.10);
          }

          .savetags-tags-filter.mod-expanded &,
          &:focus {
            background: rgba(black,.15);
            //box-shadow: 0 0 0 2px rgba(black,.10);
          }

          // expanded...
          /*
            background: rgba(black,.12);
          */


        }


        .savetags-tags-filter-icon {
          top: 50%;
          left: 6px;
          transform: translateY(-55%);
          position: absolute;
          pointer-events: none;
          z-index: 3;


          & /deep/ svg {
            width: 16px;
            height: 16px;
            path {
              fill: rgba(black,.5);
            }
          }

          .savetags-tags-filter.mod-expanded & /deep/ svg path {
            fill: rgba(black,.9);
          }

        }


        .savetags-tags-filter-clear {
          top: 50%;
          right: 0px;
          transform: translateY(-53%);
          position: absolute;
          outline-color: rgba(black,.2);
          z-index: 4;
          padding: 4px;
          padding-left: 4px;
          & /deep/ svg {
            width: 15px;
            height: 15px;
            path {
              fill: rgba(black,.5);
            }
          }
        }


        .savetags-tags-filter-input:focus + .savetags-tags-filter-icon /deep/ svg path {
          fill: rgba(black,.9);
        }
























      .savetags-new-tag {
        display: block;
        width: 100%;
        position: relative;
      }


        .savetags-new-tag-input {

          appearance: none;
          width: 100%;
          display: block;
          background: rgba(black,.0);
          color: rgba(black,.4);
          font-size: 12px;
          font-weight: 400;
          line-height: 1.4;
          border-radius: 3px;
          text-align: left;
          outline: none;

          position: relative;
          z-index: 2;
          padding: 6px 12px 6px 12px;
          padding-right: 28px;
          padding-left: 31px;

          &::placeholder {
            color: rgba(black,.5);
          }

          &:hover {
            background: rgba(black,.05);
            color: rgba(black,.9);
            //box-shadow: 0 0 0 2px rgba(black,.10);
          }

          &:focus {
            background: rgba(black,.1);
            color: rgba(black,.9);
            //box-shadow: 0 0 0 2px rgba(black,.10);
          }
        }


        .savetags-new-tag-icon {
          top: 50%;
          left: 6px;
          transform: translateY(-50%);
          position: absolute;
          pointer-events: none;
          z-index: 3;
          & svg {
            width: 18px;
            height: 18px;
          }
          & /deep/ svg path {
            fill: rgba(black,.4);
          }
        }


        .savetags-new-tag-clear {
          top: 50%;
          right: 6px;
          transform: translateY(-50%);
          appearance: none;
          position: absolute;
          outline-color: rgba(black,.2);
          z-index: 4;
          & svg {
            width: 16px;
            height: 16px;
            path {
              fill: rgba(black,.5);
            }
          }
        }


        .savetags-new-tag-input:focus + .savetags-new-tag-icon {
          & svg path {
            fill: rgba(black,.9);
          }
        }


        .savetags-new-tag-spinner {
          position: absolute;
          z-index: 20;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: center;
          background: rgba(black,.2);
          border-radius: 4px;

          > div {
            top: 50%;
            left: 6px;
            transform: translateY(-55%);
            position: absolute;
            z-index: 21;
          }

        }







</style>


<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .savetags {
    background-color: darken(white,68);
    border-color: darken(white,63);
  }

  .savetags-section-title {
    color: rgba(white,.5);
  }

  .savetags-list-empty {
    border-color: rgba(white,.2);
    color: rgba(white,.8);
    strong {
      color: rgba(white,.8);
    }
    span {
      color: rgba(white,.6);
    }
  }




  .savetags-tags-filter-input {
    background: rgba(white,.05);
    color: rgba(white,.9);

    &::placeholder {
      color: rgba(white,.5);
    }

    &:hover {
      background: rgba(white,.10);
    }

    .savetags-tags-filter.mod-expanded &,
    &:focus {
      background: rgba(white,.15);
    }
  }


  .savetags-tags-filter-icon {

    & svg {
      path {
        fill: rgba(white,.5);
      }
    }

    .savetags-tags-filter.mod-expanded & svg path {
      fill: rgba(white,.9);
    }

  }


  .savetags-tags-filter-clear {
    outline-color: rgba(white,.2);
    & svg {
      path {
        fill: rgba(white,.5);
      }
    }
  }


  .savetags-tags-filter-input:focus + .savetags-tags-filter-icon {
    & svg path {
      fill: rgba(white,.9);
    }
  }



















  .savetags-new-tag-input {
    background: rgba(white,.0);
    color: rgba(white,.4);

    &::placeholder {
      color: rgba(white,.5);
    }

    &:hover {
      background: rgba(white,.05);
      color: rgba(white,.9);
    }

    &:focus {
      background: rgba(white,.1);
      color: rgba(white,.9);
    }
  }


  .savetags-new-tag-icon {
    & svg {
      path {
        fill: rgba(white,.4);
      }
    }
  }


  .savetags-new-tag-clear {
    outline-color: rgba(white,.2);
    & svg {
      path {
        fill: rgba(white,.5);
      }
    }
  }


  .savetags-new-tag-input:focus + .savetags-new-tag-icon {
    & svg path {
      fill: rgba(white,.9);
    }
  }


  .savetags-new-tag-spinner {
    background: rgba(white,.2);
  }













}
</style>
