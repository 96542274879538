<script>
export default {

  name: 'pricingfaq',


  data: function(){
    return {
      faqExpanded: false,
    };
  },

  created: function(){
  },

  mounted: function(){
  },


  beforeDestroy: function(){
  },



  methods: {


  },


};
</script>


<template>
<section class="pricingfaq">


  <h2 class="pricingfaq-title">Frequently Asked Questions</h2>

  <div class="pricingfaq-content-wrapper">
    <div class="pricingfaq-content">


      <div>
        <h4>Is the payment form secure? Do you store payment information?</h4>
        <p>
          GGather uses <a href="https://paddle.com" target="_blank" rel="noopener noreferrer">Paddle.com</a> to securely handle payments. Payment informations are transferred through secure TLS 1.2 protocol. GGather and Paddle does not store any customer's full credit or debit card details. Paddle is PCI compliant and adheres to the Payment Card Industry Data Security Standard.
        </p>
        <p>
          <a href="https://paddle.com/taxes-fraud-compliance/" target="_blank" rel="noopener noreferrer">Read more about how Paddle handles security, compliance and anti-fraud.</a>
        </p>
      </div>

      <div>
        <h4>Can I cancel subscription?</h4>
        <p>You can easily and quickly cancel your paid subscription from your GGather billing settings. When you cancel your paid plan, you will remain on that plan until the end of your billing cycle, at the end of which you will not be billed again.</p>
      </div>


      <div>
        <h4>Do you give refunds?</h4>
        <p>Refunds are decided on a case by case basis by Paddle.com and depend on the individual policies of the companies Paddle works with. Paddle reserves the right to refund payments which are incorrectly billed, duplicates or that could be considered fraudulent.</p>
      </div>

      <div>
        <h4>Why my checkout doesn't load?</h4>
        <p>It can be for various reasons. Payment form may be blocked by your personal or your company firewall, programs installed on your system, extensions installed in your browser (especially ad-blockers) or you're using outdated browser that doesn't support TLS 1.2 protocol version needed for secure checkout. Please contact us if you have problems with payment form.</p>
      </div>


      <template v-if="faqExpanded">


        <div>
          <h4>What payment methods are accepted?</h4>
          <p>Currently Paypal and credit card payments are supported by Paddle.com. Paddle is securely processing payments on behalf of GGather as a Merchant of Record.</p>
        </div>

        <div>
          <h4>Where will the subscription money go?</h4>
          <p>GGather is young and independent business, and at this point in time all of the revenue will be put back into infrastructure (servers), maintenance, new features development and marketing of GGather. Portion of it will also go into food and rent for the GGather founder - so he won't go homeless or starve to death. It's hard to develop without electricity.</p>
        </div>



        <div>

        <div>
          <h4>Can I support GGather in any other way than paid account?</h4>
          <p>Yes! There's a <a target="_blank" rel="noreferrer noopener" :href="$store.state.hq+'/kb/help-ggather-growth'">special page</a> with more info about it. </p>
        </div>

        <div>
          <h4>Are these plans &amp; pricing final?</h4>
          <p>GGather is still young &amp; experimenting with pricing and features so no promises on that. The pricing and plans probably won't stay in current form. You will be informed if something will change that would affect you.</p>
        </div>

        <div>
          <h4>Do you offer free trial.</h4>
          <p>GGather doesn't have any free trial yet. GGather has a lot of free functionality already that should allow you to decide if you like it. There's possibility that it might be added in future.</p>
        </div>

        <div>
          <h4>Who is behind GGather?</h4>
          <p>While I might sometimes use "we" form throughout the site, the GGather is (mostly) one man show right now. And that one man would be me. And by me, I mean <a target="_blank" rel="noreferrer noopener" href="//serafin.io">Dominik Serafin</a>. Hello!</p>
        </div>

        <div>
          <h4>How can I contact you?</h4>
          <p>If it's something related to GGather - the fastest and easiest route to do that would be using <a target="_blank" rel="noreferrer noopener" href="/contact"> contact form in contact page</a>. </p>
        </div>

        <h4>Why the 2000 bookmarks import limit?</h4>

        <p><a target="_blank" rel="noreferrer noopener"  href="https://www.urbandictionary.com/define.php?term=tl%3Bdr">TL;DR;</a> It's because of backup &amp; maintenance costs and the limit will be removed in future.</p>

        <p>Importing was free in the beginning but a lot of people imported huge number of bookmarks (5K, 10K, 15K and  sometimes even more than 30K+). And all of the time these people used only the free plan. And if it have stayed this way then GGather would need to seek outside funding or would simply had to shutdown - because with such great number of bookmarks the backup and storage costs were making it hard to continue. Especially because GGather puts priority on data security - which means there are multiple backup solutions setup in case something went wrong. And it essentially multiples GGather needs for data storage multiple times.</p>

        <p>This is only temporary solution, though. Importing will be once again for free, but it will come after some pricing &amp; infrastructure changes that will make backup, storage and maintenance costs more bearable.</p>

      </div>


      </template>

    </div>

    <button class="pricingfaq-more-btn"  v-if="!faqExpanded" @click="faqExpanded=1">
      Show more answers &amp; questions...
    </button>


  </div>





</section>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .pricingfaq {
    background: #F6F6F6;
    max-width: 640px;
    padding: 30px;
    border-radius: 8px;
    //box-shadow: 0 2px 10px 0px rgba(black,.13);
    border: 1px solid darken(white,12);
    margin-top: 20px;

    @media (max-width: 670px) {
      margin-top: 5px;
      padding: 10px;
    }
  }


  .pricingfaq-title {
    font-size: 27px;
    font-weight: 700;
    line-height: 1.2;
    color: darken(white,80);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 670px) {
      font-size: 24px;
      margin-top: 20px;
    }
  }

  .pricingfaq-content-wrapper {

  }

  .pricingfaq-content {

    > div {

    }

    h4 {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.2;
      color: darken(white,90);
      margin-top: 25px;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.3;
      color: darken(white,80);
      margin-top: 5px;
    }

    a {
      text-decoration: underline;
    }
  }

  .pricingfaq-more-btn {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    color: darken(white,90);
    margin-top: 25px;
    text-decoration: underline;

  }

</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .pricingfaq {
    //box-shadow: 0 2px 10px 0px rgba(white,.3);
    background-color: darken(white,80);
    border-color: darken(white,71);
  }

  .pricingfaq-title {
    color: darken(white,10);
  }

  .pricingfaq-content {
    h4 {
      color: darken(white,15);
    }
    p {
      color: darken(white,40);
    }
  }

  .pricingfaq-more-btn {
    color: darken(white,10);
    margin-top: 25px;
  }

}
</style>
