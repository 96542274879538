<script>
export default {

  data: function(){
    return {
    }
  },

  created: function(){
  },

  mounted: function(){
  },

  updated: function(){
  },

  methods: {
    close: function(){
      this.$store.commit('publishInviteModal', 0);
    },
  },

};
</script>


<template>
<transition name="publishinvite-fade" :duration="$store.state.transitionsEnabled ? 200 : 0">
<div class="publishinvite" v-if="$store.state.publishInviteModal">

  <div class="publishinvite-background"></div>

  <div class="publishinvite-foreground-outer common-light-scrollbar"  @click="close" >
    <div class="publishinvite-foreground" @click.stop  >

      <div class="publishinvite-top-title">
        Public Tags
      </div>

      <button type="button" class="publishinvite-close-btn" @click.prevent="close">
        Close <span class="icon icon-delete"></span>
      </button>



      <div class="publishinvite-content">
        <div class="publishinvite-content-inner">

          <div class="publishinvite-header">

            <button type="button" class="publishinvite-title">
              Public Tags
            </button>

          </div>

          <div class="publishinvite-content-content">

            <p>This option is no longer available for new users.</p>

            <p>GGather will soon remove some of the public bookmarking features to focus better on the private bookmarking aspect.</p>

            <p>You can still (and will be able to in future) to set the tags to the "Unlisted" variant.</p>


          </div>

        </div>
      </div>



    </div>
  </div>
</div>
</transition>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";






  .publishinvite {
    position: relative;
    z-index: 120;
  }

    .publishinvite-background {
      position: fixed;
      background-color: rgba(28, 27, 27, 0.95);
      background-color: rgba(14, 21, 31, 0.97);
      background-color: rgba( darken(white, 5), .98);
      //background-color: rgba(240, 240, 240, 0.98);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      opacity: 1;

      .publishinvite-fade-enter-active & {
        html.mod-transitions-enabled & {
          transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
        }
      }
      .publishinvite-fade-leave-active & {
        html.mod-transitions-enabled & {
          transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
        }
      }

      .publishinvite-fade-enter &, .publishinvite-fade-leave-to & {
        opacity: .0;
      }

      .publishinvite-fade-enter & {
      }

      .publishinvite-fade-leave-to & {
      }
    }


    .publishinvite-foreground-outer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      //overflow-y: scroll;
      display: flex;
      padding: 50px 10px;
      //justify-content: space-around;
      //align-items: center;
      //&::before {
      //    content: '';
      //}
      //&::after {
      //    content: '';
      //}
    }


    .publishinvite-foreground {
      z-index: 60;
      display: block;
      margin: auto;
      position: relative;

      opacity: 1;

      .publishinvite-fade-enter-active & {
        html.mod-transitions-enabled & {
          transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        }
      }
      .publishinvite-fade-leave-active & {
        html.mod-transitions-enabled & {
          transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        }
      }

      .publishinvite-fade-enter &, .publishinvite-fade-leave-to & {
        opacity: .0;
      }

      .publishinvite-fade-enter & {
        transform: translateY(-20px);
      }

      .publishinvite-fade-leave-to & {
        transform: scale(0.9);
        //transform: translateY(50px);
      }




    }

      .publishinvite-foreground-inner {
      }


    .publishinvite-top-title {
      position: absolute;
      top: -20px;
      left: 0;
      color: rgba(black,.6);
      padding-left: 1px;
      font-size: 12px;
    }


    .publishinvite-close-btn {
      position: absolute;
      top: -20px;
      right: 0;
      padding-right: 1px;
      color: rgba(black,.3);
      font-size: 12px;
      &:hover {
        color: rgba(black,.9);
      }
    }












            .publishinvite-content {
              background-color: lighten(#eee,4);
              border-radius: 2px;
              width: 100vw;
              border: 1px solid darken(white,10);
              //box-shadow: 0 2px 5px -1px rgba(black,.05);
              box-shadow: 0 2px 5px 0 rgba(black, .03), 0 2px 30px 5px rgba(black, .02);
              html.mod-transitions-enabled & {
                transition: height 200ms ease-in-out;
              }
              max-width: 400px;

              overflow: hidden;
              transform: rotate3d(0,0,0,0);
              backface-visibility: hidden;
            }



            .publishinvite-content-inner {
              padding: 0 20px 15px 20px;
            }


            .publishinvite-header {
              margin-bottom: 10px;
              text-align: left;
              border-bottom: 1px solid #eee;
              margin-bottom: 20px;

              //padding: 0 5px;
            }

            .publishinvite-title {
              display: inline-block;
              outline: none;
              font-size: 18px;
              color: rgba(black,.3);
              font-weight: 500;
              padding-top: 20px;
              padding-bottom: 15px;

              border-right: 1px solid #eee;
              padding-right: 20px;
              margin-right: 20px;

              border-right: none;
              padding-right: 0;
              margin-right: 0;

              color: rgba($clr-ggather,.9);
              font-weight: 600;
            }



            .publishinvite-content-content {
              p {
                font-size: 15px;
                line-height: 1.2;
                margin-bottom: 10px;
              }
              a {
                text-decoration: underline;
                color: $clr-ggather;
              }
            }


</style>




<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



    .publishinvite-background {
      background-color: rgba( darken(white, 70), .98);

    }


    .publishinvite-top-title {
      color: rgba(white,.6);
    }


    .publishinvite-close-btn {
      color: rgba(white,.3);
      &:hover {
        color: rgba(white,.9);
      }
    }



    .publishinvite-content {
      background-color: darken(white,80);
      border-color: darken(white,70);
      //box-shadow: 0 2px 5px 0 rgba(black, .03), 0 2px 30px 5px rgba(black, .02);
    }


    .publishinvite-header {
      border-color: darken(white,70);
    }

    .publishinvite-title {
      border-color: darken(white,70);
      color: rgba(white,.9);
    }



    .publishinvite-content-content {
      color: rgba(white,.6);
      a {
        color: white;
      }
    }



}

</style>
