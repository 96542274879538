<script>
import __ from '../../../common/script/dashdash.js';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _merge from 'lodash/merge';
import dfFormatDistance from 'date-fns/formatDistance';
import dfFormatDistanceStrict from 'date-fns/formatDistanceStrict';
export default {

  props: [
    'subkind',
  ],

  data: function(){
    return {
      notifications: [],
      loading: true,
      page: 1,
      hasNextPage: true,
      count: 0,
    }
  },


  watch: {
    '$store.state.activityVisible': function(val){
      this.resetNotifications();
      if (val) {
        this.setupWindowClickEvent(1);
        this.getNotifications();
      } else {
        this.setupWindowClickEvent(0);
      }
    },

    '$store.state.viewer': function(){
      this.close();
    },
    '$store.state.sidebarCollapse': function(){
      this.close();
    },
    '$store.state.sidebarVisible': function(){
      this.close();
    },
    '$store.state.userMenu': function(){
      this.close();
    },
    '$store.state.eventReadAllNotifs': function(){
      this.readAll();
    },
  },



  methods: {

    relativeDateFormat: function(raw){
      //console.warn('l', dfFormatDistanceStrict(new Date(), new Date(), {includeSeconds: false, addSuffix: true, roundingMethod}));
      return dfFormatDistance(new Date(raw), new Date(), {includeSeconds: true, addSuffix: true});
    },

    resetNotifications: function(){
      this.page = 1;
      this.hasNextPage = true;
      this.notifications = [];
    },

    close: function(){
      this.$store.state.activityVisible ? this.$store.commit('activityVisible', 0) : null;
    },

    closeAndOpenUserMenu: function(){
      this.$store.state.activityVisible ? this.$store.commit('activityVisible', 0) : null;
      setTimeout(function() {
        this.$store.commit('userMenu', true);
      }.bind(this), 1);
    },

    stopPropagation: function(event){
      event.stopPropagation();
    },



    setupWindowClickEvent: function(init){
      var self = this;

      var handler = ( this._windowClickHandler ? this._windowClickHandler :
        this._windowClickHandler = function(event){
          self.close();
        }
      );

      if (init) {
        window.addEventListener('click', handler, false);
      } else {
        window.removeEventListener('click', handler, false);
      }
    },

    readNotification: function(pk){
      var self = this;

      var notificationObject = _find(this.notifications, {'pk': pk});
      if (notificationObject.is_read != true) {
        notificationObject.is_read = true;
      }

      this.$axios({
        url: this.$store.state.api + '/read-notif/',
        method: 'post',
        data: {
          pk: pk,
        },
      }).then(function(response){
        this.$store.dispatch('getUnreadNotifs');
      }.bind(this));


    },

    readAll: function(){
      _forEach(this.notifications, function(value, key){
        value.is_read = true;
      }.bind(this));
    },


    updateNotifications: function(data){


      // add new items
      _forEach(data.items, function(value, key){

        // if the item is not already in notifications then just add it
        if ( !_find(this.notifications, ['pk', value.pk]) ) {
          this.notifications.push(value);
        }

      }.bind(this));


      // update items
      data.items.forEach(function(el){
        var state_item = _find(this.notifications, ['pk', el.pk]);
        var state_item_index = _findIndex(this.notifications, ['pk', el.pk]);
        var request_item = el;
        var updated_item = _merge(state_item, request_item);
        this.notifications.splice(state_item_index, 1, updated_item);
      }.bind(this));

      // sort notifications by pk
      this.notifications.sort(function(a, b){
        if (a.pk > b.pk) {
          return -1;
        } else {
          return 1;
        }
        return 0;
      });

      // all & unread counts
      this.count = data.count;

      // is there a next page?
      if (!data.has_next_page) {
        this.hasNextPage = false;
      }


    },


    getNotifications: function(){
      if (!this.$store.state.user.is_authenticated) return false;

      this.loading = true;

      this.$axios({
        url: this.$store.state.api + '/get-notifs/',
        method: 'get',
        params: {
          page: this.page,
        },
      }).then(function(response){
        this.loading = false;
        this.updateNotifications(response.data);
      }.bind(this));

    },

    loadMore: function(){
      this.page++;
      this.getNotifications();
    },


  }



};
</script>


<template>
<div class="activity" v-if="this.$store.state.activityVisible" @click.stop>
<!-- <div class="activity-arrrow"></div> -->

  <div class="activity-top">
    <button type="button" class="activity-top-btn mod-back" @click.prevent="closeAndOpenUserMenu">↩</button>
    <h3 class="activity-top-title">Notifications</h3>
    <div class="activity-top-subtitle" v-if="this.$store.state.unreadNotifs">{{this.$store.state.unreadNotifs}} Unread</div>
    <button type="button" class="activity-top-btn" @click.prevent="$store.dispatch('readAllNotifs')">Read All</button>
  </div>

  <div class="activity-inner"  v-bar="{preventParentScroll: true}">
  <div class="activity-inner-inner">



    <!-- Topbar Notif -->
    <!-- <transition name="topbar-notif-fade"> -->
    <div class="activity-notif" >

      <!--
      <div class="activity-notif-arrow-outer">
        <div class="activity-notif-arrow"></div>
      </div>  -->

      <div class="activity-notif-inner">
      <div class="activity-notif-inner-inner">


        <div class="activity-notif-item-outer-outer" v-for="n in notifications" :key="n.pk" :class="{'mod-read': n.is_read}" >



          <div class="activity-notif-icon-wrapper">



            <div v-if="n.event_type==200" class="activity-notif-icon-image" :style="{backgroundImage: 'url('+ n.related_tagfollow.user_following_profile_image +')'}">
            </div>


            <span v-else-if="n.event_type==201" class="activity-notif-icon"
            v-tooltip="'New link in subscribed tag'">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path d="M 11 3 L 11 11 L 3 11 L 3 13 L 11 13 L 11 21 L 13 21 L 13 13 L 21 13 L 21 11 L 13 11 L 13 3 L 11 3 z"></path></svg>
            </span>

            <span v-else-if="n.event_type==800" class="activity-notif-icon"
            v-tooltip="'GGather notification'">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24" >
                <path d="M11,16.4l-4.7-4.7l1.4-1.4l3.3,3.3l8.4-8.4C17.5,3.3,14.9,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 c0-1.9-0.5-3.6-1.4-5.1L11,16.4z"></path>
              </svg>
            </span>

            <span v-else-if="n.event_type==801" class="activity-notif-icon"
            v-tooltip="'Message from administration'">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24" >
                <path d="M20,3H4C2.9,3,2.01,3.9,2.01,5L2,23l4-4h14c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M11,7h2v2h-2V7z M11,11h2v4h-2V11z"></path>
              </svg>
            </span>

            <span v-else class="activity-notif-icon"
            v-tooltip="'General notification'">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path d="M20,3H4C2.9,3,2.01,3.9,2.01,5L2,23l4-4h14c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M9,12H7v-2h2V12z M13,12h-2v-2h2V12z M17,12h-2v-2h2V12z"></path>
              </svg>
            </span>




            <div v-if="n.event_type==200" class="activity-notif-subicon" v-tooltip="'Tag subscribed notification'">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path d="M12.172,3c0,0-9.279,9.279-9.586,9.586c-0.781,0.781-0.781,2.047,0,2.828c0.918,0.918,4.828,4.828,6,6 c0.781,0.781,2.047,0.781,2.828,0C11.721,21.107,21,11.828,21,11.828V3H12.172z M17.5,8C16.672,8,16,7.328,16,6.5S16.672,5,17.5,5 S19,5.672,19,6.5S18.328,8,17.5,8z"></path>
              </svg>
            </div>


          </div>


          <div class="activity-notif-item-outer">


            <div class="activity-notif-infobar">

            {{relativeDateFormat(n.date_created)}}

            <button type="button" class="activity-notif-read-btn" title="Mark as read"  @click.prevent="readNotification(n.pk)" :disabled="n.is_read">
              <div class="activity-notif-read-circle"></div>
              {{ n.is_read ? '&#10003;' : 'Read' }}
            </button>

            </div>



            <div class="activity-notif-item-content" >



              <!-- EVENT TYPE 200 -->
              <router-link v-if="n.event_type==200"
              :to="'/' + n.related_tagfollow.user_following_username"
              class="activity-notif-item"
              @click.native="readNotification(n.pk)"
              >

                <div class="activity-notif-heading">
                  <div class="activity-notif-title">{{n.related_tagfollow.user_following_real_name ? n.related_tagfollow.user_following_real_name : n.related_tagfollow.user_following_username}}
                  is now subscribing your tag {{n.related_tagfollow.tag_followed_name}}</div>
                </div>

                <div class="activity-notif-description">Click to see his profile</div>
              </router-link>


              <!-- OTHER EVENT TYPES -->
              <a v-else
              :href="n.custom_url"
              class="activity-notif-item"
              @click="readNotification(n.pk)"
              target="_blank"
              >

                <div class="activity-notif-heading">
                  <div class="activity-notif-title">{{n.custom_title}}</div>
                </div>

                <div class="activity-notif-description" v-if="n.custom_description"
                >{{n.custom_description}}
                </div>
                <div>
                <div class="activity-notif-image" v-if="n.custom_image_url">
                  <img :src="n.custom_image_url">
                </div>
                </div>
              </a>




            </div>



          </div>

        </div>



        <div class="activity-notif-loader-wrapper" v-if="loading">
          <spinner kind="dots" dark="true"></spinner>
        </div>

        <div class="activity-notif-empty-state" v-else-if="!notifications.length">No Notifications</div>


        <button type="button" class="activity-notif-load-more" @click.prevent="loadMore" v-if="notifications.length && (hasNextPage) && (!loading)">Load older notifications...</button>





      </div>
      </div>
    </div>
    <!-- </transition> -->
    <!-- / Topbar Notif -->




  </div>
  </div>
</div>
</template>

<style lang="scss">



  .activity {

    .vb > .vb-dragger {
      z-index: 2;
      width: 10px;
      right: 0;
      z-index: 150;
    }

    .vb > .vb-dragger > .vb-dragger-styler {

      html.mod-transitions-enabled & {
        transition:
          background-color 200ms ease-out,
          margin 200ms ease-out,
          height 200ms ease-out,
          border-radius 200ms ease-out;
      }

      backface-visibility: hidden;
      transform: rotate3d(0,0,0,0);
      background-color: rgba(200, 200, 200, .0);
      margin: 2px 3px 2px 0;
      border-radius: 222px;
      height: calc(100% - 4px);
      display: block;
    }

    .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .2);
    }

    .vb > .vb-dragger:hover > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .5);
      margin: 0px;
      height: 100%;
      border-radius: 1px;
    }

    .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .5);
      margin: 0px;
      height: 100%;
      border-radius: 1px;
    }

    .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .5);
    }

  }



</style>

<style lang="scss" scoped>




  @import "../../../common/style/_variables.scss";


  .activity {
    background-color: lighten(#eee,4);
    background-color: white;
    width: auto;
    display: block;
    //min-height: 300px;
    //border: 1px solid darken(white,7);
    box-shadow: 0 2px 15px 0px rgba(black,.07);
    html.mod-transitions-enabled & {
      transition: height 200ms ease-in-out;
    }

    overflow: hidden;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;

    width: 340px;

    border-radius: 8px;
    position: fixed;
    top: 40px;
    left: 226px;

    z-index: 200;

    border: 1px solid darken(white,10);

    touch-action: manipulation;
    -webkit-overflow-scrolling: auto;


    @media (max-width: 700px){
      //width: auto;
      width: 280px;
      left: 10px;
    }

    html.mod-page-without-sidebar & {
      left: auto;
      right: 10px;
    }

  }


  .activity-top {
    padding: 19px 25px 17px 70px;
    border-bottom: 1px solid darken(white,7);
    position: relative;
  }


    .activity-top-title {
      display: inline;
      font-size: 15px;
      line-height: 1.4;
      font-weight: 600;
      color: rgba(black,.85);
      vertical-align: baseline;

    }


    .activity-top-subtitle {
      display: inline;
      font-size: 11px;
      font-weight: 400;
      color: rgba(black,.5);
      vertical-align: baseline;
      margin-left: 5px;
    }



    .activity-top-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      text-transform: uppercase;


      font-size: 11px;
      border: 1px solid darken(white,6);
      color: rgba(black,.9);
      background-color: rgba(white,.6);
      font-weight: 400;
      padding: 3px 10px 2px 10px;
      margin-left: 12px;
      border-radius: 50px;
      display: inline-block;


      outline: none;

      background-color: $clr-ggather;
      color: rgba(white,.85);
      border: 1px solid darken($clr-ggather, 2);

      background-color: darken(white,10);
      color: rgba(black,.85);
      border: 1px solid darken(white,10);

      html.mod-transitions-enabled & {
        transition: border-color 100ms ease-out, background-color 100ms ease-out, color 100ms ease-out;
      }

      &.mod-back {
        background-color: darken(white,10);
        color: rgba(black,.85);
        border: 1px solid darken(white,10);
        right: auto;
        left: 7px;
      }

      &:hover {
        background-color: white;
        color: rgba(white,1);

        background-color: $clr-ggather;
        color: white;
        border: 1px solid darken($clr-ggather, 2);
      }



    }


  .activity-inner {
    padding: 0;
    height: 500px;
    width: 100%;

    @media (max-height: 650px){
      height: 80vh;
      height: calc(100vh - 120px);
    }

    @media (max-width: 650px){
      width: 100%;
    }


  }

  .activity-inner-inner {
  }





  .activity-notif-wrapper {
    //padding-top: 80px;
  }


  .activity-notif {
    display: block;
    width: auto;
    height: 100%;
  }


    .activity-notif-arrow-outer {
      position: relative;
      font-size: 0;
      width: 0;
      height: 0;
      left: 19px;
      z-index: 5;
    }


    .activity-notif-arrow {

      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 7px;


      border-width: 0 7px 7px 7px;
      border-color: transparent transparent darken(#eee,5) transparent;
      top: -6px;
      left: calc(50% - 7px);
      margin-top: 0;
      margin-bottom: 0;

    }


    .activity-notif-inner {
      //box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      //background-color: white;
      border-radius: 0px;
      overflow: hidden;

      z-index: 10;

      padding-top: 0px;

      padding-left: 0px;
    }

    .activity-notif-inner-inner {
      margin: 0 auto;
      border-radius: 0px;
      overflow: hidden;
    }

    .activity-notif-item-outer-outer {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      border-bottom: 1px solid darken(white,5);


      //border-left: 4px solid lighten(#4889f5,5);

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0px;
        bottom: 10px;
        border-radius: 10px;
        width: 3px;
        background-color: rgba( lighten(#4889f5,5) , .5);

        display: none;
      }

      &.mod-read {
        &::before {
          background-color: transparent;
        }



        //border-left-color: #eee;
      }


      &:first-of-type {
        padding-top: 15px;
        &::before {
          top: 10px;
        }
      }

    }


    .activity-notif-item-outer {
      display: block;
      padding-left: 50px;
      min-height: 50px;
    }




    .activity-notif-icon-wrapper {
      margin-right: 10px;
      display: inline-block;
      background-color: rgba(#4889f5,1);
      color: white;
      border: 1px solid rgba(#4889f5,1);
      border-radius: 40px;
      width: 40px;
      height: 40px;
      background-size: cover;
      background-position: 50% 50%;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      position: absolute;
      top: 15px;
      left: 15px;


      .activity-notif-item-outer-outer.mod-read & {
        //opacity: .4;
        background-color: #ccc;
        color: white;
        border-color:  #ccc;
      }

      .activity-notif-item-outer-outer:first-of-type & {
        top: 15px;
      }

    }



      .activity-notif-icon {

        & /deep/ svg {
          width: 24px;
          height: 24px;
          opacity: 1;
          fill: white;
        }

      }


      .activity-notif-icon-image {
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 50px;
        background-color: #ddd;
        top: 0;
        left: 0;
        position: relative;
        height: 40px;
        width: 40px;
        .activity-notif-item-outer-outer.mod-read & {
        }
      }




      .activity-notif-subicon {
        border-radius: 40px;
        background-size: cover;
        background-position: 50% 50%;
        color: white;
        background-color:   rgba(#4889f5,1);
        width:  20px;
        height: 20px;
        position: absolute;
        bottom: -5px;
        right: -5px;
        border: 1px solid   rgba(#4889f5,1);

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        & /deep/ svg {
          width: 14px;
          height: 14px;
          opacity: .9;
          fill: white;
        }

        .activity-notif-item-outer-outer.mod-read & {
          //opacity: .7;
          background-color: #ddd;
          border-color:  #ddd;
          color: white;
          & /deep/ svg {
            fill: $clr-ggather;
          }

        }
      }







      .activity-notif-infobar {
        font-size: 11px;
        color: #bbb;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 5px;
        display: block;
      }




      .activity-notif-item-content {
        position: relative;
        display: block;

        border-radius: 2px;
        //border: 1px solid #eee;

        //background-color: white;

        &:first-of-type {
          //border-top: none;
        }
        &:last-of-type {
          //border-bottom: 1px solid #eee;
        }

        &:hover {
          //background-color: lighten(#eee,5.5);
        }


      }




        .activity-notif-item {
          display: block;
          //padding: 20px 20px 18px 20px;
          outline: none;
        }




        .activity-notif-heading {
          display: flex;
          justify-content: space-between;
        }


        .activity-notif-read-btn {
          display: inline-block;
          margin-left: 5px;
        }

        .activity-notif-read-circle {
          //position: absolute;
          //top: 4px;
          //left: 0;
          width: 10px;
          height: 10px;
          margin-left: 2px;
          margin-top: 0px;
          overflow: hidden;
          display: inline-block;
          font-size: 0;
          z-index: 10;
          background-color: rgba($clr-light-blue-500,.8);
          background-color: #4889f5;
          border-radius: 50px;

          margin-right: 2px;
          cursor: default;
          outline: none;

          .activity-notif-item-outer-outer.mod-read & {
            background-color: #ddd;
          }

        }

        .activity-notif-title {
          font-size: 13px;
          color: #333;
          font-weight: 600;
          line-height: 1.2;
          //padding-left: 15px;
          display: block;

          .activity-notif-item-outer-outer.mod-read & {
            //padding-left: 0;
          }
        }

        .activity-notif-description {
          font-size: 12px;
          color: #777;
          font-weight: 400;
          display: block;
          margin-top: 3px;
          margin-bottom: 2px;
        }


        .activity-notif-image {
          display: inline-block;
          //max-height: 200px;
          overflow: hidden;
          border-radius: 2px;
          font-size: 0;

          max-width: 100%;
          margin-top: 6px;
          //margin-bottom: 5px;

          img {
            max-width: 100%;
          }
        }




        .activity-notif-empty-state {
          padding: 30px 12px;
          font-size: 12px;
          color: #777;
          text-align: center;
        }


        .activity-notif-load-more {
          outline: none;
          padding: 20px;
          font-size: 14px;
          font-weight: 500;
          display: block;
          border-radius: 4px;
          border: 1px solid #eee;
          margin: 15px 15px 15px 15px;
          background-color: white;
          background-color: darken(white,2);
          //border-left: 2px solid #eee;
          width: calc(100% - 30px);
          color: rgba(black,.8);
          color: rgba($clr-ggather, .9);
          text-align: left;
          text-align: center;
        }



        .activity-notif-loader-wrapper {
          padding-top: 49px;
          padding-bottom: 49px;
          display: block;
          text-align: center;
        }


  @media screen and (max-width: 1442px) {}



  @media screen and (max-width: 1026px) {}

  @media screen and (max-width: 730px) {



  }






</style>




















<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .activity {
    background-color: darken(white,85);
    border-color: darken(white,80);
    //box-shadow: 0 2px 15px 0px rgba(black,.07);
  }


  .activity-top {
    border-color: darken(white,80);
  }


    .activity-top-title {
      color: rgba(white,.85);
    }


    .activity-top-subtitle {
      color: rgba(white,.5);
    }



    .activity-top-btn {
      background-color: $clr-ggather;
      color: rgba(white,.85);
      border: 1px solid darken($clr-ggather, 2);

      background-color: darken(white,70);
      color: rgba(white,.85);
      border: 1px solid darken(white, 65);


      &:hover {
        background-color: white;
        color: rgba(white,1);

        background-color: $clr-ggather;
        color: white;
        border: 1px solid darken($clr-ggather, 2);
      }



    }








    .activity-notif-arrow {
      border-color: transparent transparent darken(#eee,5) transparent;
    }


    .activity-notif-item-outer-outer {
      background-color: darken(white,85);
      border-color: darken(white,80);

      &::before {
        background-color: darken(white,70);
      }

    }




  .activity-notif-icon-wrapper {
    background-color: darken(white,75);
    border-color: darken(white,65);
  }
  .activity-notif-item-outer-outer.mod-read .activity-notif-icon-wrapper {
    background-color: darken(white,72);
    border-color: darken(white,68);
  }

  .activity-notif-icon /deep/ svg {
    fill: white;
  }







  .activity-notif-title {
    color: rgba(white,.9);
  }

  .activity-notif-description {
    color: rgba(white,.7);
  }


  .activity-notif-empty-state {
    color: rgba(white,.7);
  }


  .activity-notif-load-more {
    background-color: darken(white,80);
    border-color: darken(white,75);
    color: rgba(white,.7);
  }







}
</style>
