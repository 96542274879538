<script>
export default {

  props: [
    'viewer',
  ],

  data: function(){
    return {

      privateComments: null,
      publicComments: null,

      newPrivateCommentValue: null,
      newPublicCommentValue: null,

      saves: null,

    }
  },

  computed: {
    item: function(){
      return this.$store.state.viewer.item;
    },

  },

  mounted: function(){
    this.getURLComments();
    this.getSaves();
  },


  methods: {

    getSaves: function(){
      this.$axios({
        url: this.$store.state.api + '/get-saves/',
        method: 'get',
        params: {
          url: this.item.url,
        },
      }).then(function(response){
        this.saves = response.data.saves;
      }.bind(this), function(error){
      }.bind(this));
    },


    getURLComments: function(){
      this.$axios({
        url: this.$store.state.api + '/get-urlcomments/',
        method: 'get',
        params: {
          url: this.item.url,
        },
      }).then(function(response){

        this.privateComments = response.data.private_comments;
        this.publicComments = response.data.public_comments;

        this.$store.commit('updateItem', {
          pk: this.item.pk,
          comments_count: {
            private: response.data.private_comments_count,
            public: response.data.public_comments_count,
            total: response.data.total_comments_count,
          }
        });

      }.bind(this), function(error){
      }.bind(this));
    },



    postURLComment: function(isPublic, event){

      if (event.shiftKey) return;
      event.preventDefault();

      var contentValue = isPublic ? this.newPublicCommentValue : this.newPrivateCommentValue;

      if (!contentValue) {
        this.$alertify.alert('You need to provide annotation content...'); return;
      }

      if (isPublic && ((!this.newPublicCommentValue) || (this.newPublicCommentValue.length < 5)) ) {
        this.$alertify.alert('Public annotation must have at least 5 characters.'); return;
      }

      this.$axios({
        url: this.$store.state.api + '/post-urlcomment/',
        method: 'post',
        data: {
          url: this.item.url,
          content: contentValue,
          is_public: isPublic,
        },
      }).then(function(response){
        this.getURLComments();
      }.bind(this), function(error){
        this.$alertify.alert(error.response && error.response.data ? error.response.data : 'Something went wrong...');
      }.bind(this));

      isPublic ? this.newPublicCommentValue = null : this.newPrivateCommentValue = null;

    },


    showPrivateAnnotProFeature: function(event){
      this.$store.commit('dialogbox', {kind: 'profeature', subkind: 'private-annotations', featureCode: 'private-annotations' });
    },



  },

};
</script>


<template>
  <div class="comments ">

    <div class="comments-heading">
      <div class="comments-heading-title">Link Annotations &amp; Public Saves</div>
    </div>

    <div class="comments-content  common-light-scrollbar">

      <div class="comments-section">

        <div class="comments-section-title mod-private">
          <span>
          Private Annotations</span>

          <span class="comments-private-user-image" v-tooltip="'These annotations are visible only to you. <br /> They are marked with gray left border.'"
          :style="{backgroundImage: 'url('+$store.state.user.profile_image+')'}"></span>
          <!--
          <iconsvg icon="lock-iosglyph" v-tooltip="'These annotations are visible only to you. <br /> They are marked with darker left border.'"></iconsvg>-->
        </div>

        <div class="comments-section-content">

          <div v-if="$store.state.user.is_premium" class="comments-input-box mod-private">
            <textarea v-autosize  cols="3"  placeholder="Add Private Annotation" v-model="newPrivateCommentValue" @keypress.enter="postURLComment(0, $event)" maxlength="4999"></textarea>

            <a href="https://en.wikipedia.org/wiki/Markdown#Example" target="_blank" class="comments-markdown-icon" v-tooltip="'<strong>Markdown is supported.</strong><br /> Use Shift+Enter to go to next line.  <br />Use Enter to submit annotation. '"><iconsvg icon="markdown-filled"></iconsvg></a>
            <!--
            <button type="button" @click="postURLComment">Submit</button>-->
          </div>

          <a v-else href="/upgrade" class="comments-required-box"  @click.stop.prevent="showPrivateAnnotProFeature">
            Private annotations support Markdown and can be added by <span v-if="$store.state.user.is_anonymous">registered</span> users with GGather Plus account.
          </a>


          <span v-if="privateComments!==null">
            <comment
              v-for="comment in privateComments"
              :key="'comment'+comment.pk"
              :item="item"
              :comment="comment"
              @deleted="getURLComments"
            />
          </span>

          <span v-if="privateComments===null" class="comments-spinner">
            <spinner kind="circle" size="2" :dark="true"></spinner>
          </span>

        </div>



      </div>


      <div class="comments-section-separator"></div>


      <div class="comments-section">

        <div class="comments-section-title mod-public">
          <span>
          Public Annotations</span>
          <iconsvg icon="globe-iosglyph" v-tooltip="'These annotations are visible to everyone.'"></iconsvg>
        </div>


        <div v-if="$store.state.user.is_authenticated" class="comments-input-box">
          <textarea v-autosize cols="3" placeholder="Add Public Annotation"  v-model="newPublicCommentValue"  @keypress.enter="postURLComment(1, $event)" maxlength="999"></textarea>

          <!--
          <div class="comments-markdown-icon" v-tooltip="'Partial Markdown format is supported.'"><iconsvg icon="markdown-filled"></iconsvg></div> -->
          <!--
          <button type="button">Submit</button>-->
        </div>


        <button type="button" v-else class="comments-required-box">
          You need to sign in to add public annotation.
        </button>

        <span v-if="publicComments!==null">
          <comment
            v-for="comment in publicComments"
            :key="'comment'+comment.pk"
            :item="item"
            :comment="comment"
            @deleted="getURLComments"
          />
        </span>

        <span v-if="publicComments===null" class="comments-spinner">
          <spinner kind="circle" size="2" :dark="true"></spinner>
        </span>


      </div>



      <div class="comments-section-separator"></div>


      <div class="comments-section">

        <div class="comments-section-title mod-public">
          <span>
          Publicly Saved By</span>
          <iconsvg icon="people-iosglyph" v-tooltip="'People that also saved this link to public tag(s).'"></iconsvg>
        </div>

        <div class="comments-saves-wrapper">


          <button type="button"  v-if="(saves) && (!saves.length)" @click.stop.prevent="$store.commit('publishInviteModal', 1)" class="comments-save-empty">Be the first to save this link publicly by adding it to public tag(s) &mdash; invite only feature for now.</button>

          <span v-else-if="saves!==null">
            <div v-for="save in saves" :key="'savesuser'+save.user.pk" class="comments-save">
              <router-link :to="'/'+save.user.username" class="comments-save-front">
                <div class="comments-save-image" :style="{backgroundImage: 'url('+save.user.profile_image+')'}"></div>
                <div>
                  <div v-if="save.user.first_name || save.user.last_name" class="comments-save-title">{{save.user.first_name}} {{save.user.last_name}}</div>
                  <div v-else class="comments-save-title">{{save.user.username}}</div>
                  <div v-if="save.user.bio" class="comments-save-subtitle">{{save.user.bio}}</div>
                </div>
              </router-link>
              <div class="comments-save-tags">
                <!--  <div class="comments-save-tags-label">Saved This Link With These Tag(s)</div>-->
                <router-link v-for="tag in save.tags" :key="'savessavetag'+tag.pk" class="comments-save-tag" :class="['mod-color-'+tag.color,]" :to="'/'+save.user.username+'/'+tag.slug"  >{{tag.name}}</router-link>
              </div>
            </div>
          </span>


          <span v-if="saves===null" class="comments-save-spinner">
            <spinner kind="circle" size="2" :dark="true"></spinner>
          </span>


        </div>
      </div>




    </div>




  </div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .comments {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 100;
  }


  .comments-heading {
    height: 55px;
    padding-top: 20px;
    padding-left: 20px;
    border-bottom: 1px solid darken(white,6);
    background: white;
  }

    .comments-heading-title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
      vertical-align: top;
      line-height: 1;
      @media (max-width: 1000px) {
        font-size: 13px;
      }
    }

  .comments-content {
    overflow: auto;
    height: calc(100% - 55px);
    background: darken(white,5);
    background: darken(white,3);

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: scrollbar;

    &::-webkit-scrollbar-thumb {
      background-color: darken(#dedede,2);
    }


    &::-webkit-scrollbar-thumb:hover {
      background-color: darken(#dedede,10);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: darken(#dedede,15);
    }

    &::-webkit-scrollbar-track {
      background-color: #f7f7f7;
      border-left: 1px solid #f7f7f7;
    }

  }



  .comments-section-separator {
    margin: 20px 0 40px 0;
    width: 100%;
    height: 1px;
    display: block;
    background: darken(white,7);
  }

  .comments-section {
    padding-bottom: 10px;
  }

  .comments-section-title {
    font-size: 15px;
    font-weight: 600;
    color: rgba(black, .7);
    vertical-align: top;
    line-height: 1;
    padding: 0 20px 0 21px;
    margin-top: 30px;
    margin-bottom: 10px;
    //background: darken(white,0);
    //border-top: 1px solid darken(white,6);
    //border-bottom: 1px solid darken(white,6);

    display: flex;
    justify-content: space-between;


    svg {
      display: inline-block;
      width: 17px;
      height: 17px;
      //position: relative;
      margin-right: 0px;
      margin-top: -2px;

      /deep/ path {
        fill: rgba(black,.3);
      }

    }


    &.mod-public svg {
      width: 18px;
      height: 18px;
      margin-top: -2px;
      margin-right: 0px;
    }


  }


  .comments-private-user-image {
    display: inline-block;
    width: 17px;
    height: 17px;
    background-size: cover;
    backface-visibility: hidden;
    transform: rotate3d(0,0,0);
    background-position: 50%;
    background-color: #eee;

    border-radius: 100px;
    margin-right: 0px;
    margin-top: -2px;
  }









    .comments-input-box {
      padding-left: 10px;
      padding-right: 10px;
      position: relative;

      textarea {

        outline: none;
        display: block;
        background: white;
        width: 100%;
        overflow: hidden;
        padding: 10px;
        border-radius: 3px;
        color: rgba(black,.9);

        font-size: 13px;

        resize: none;

        &::placeholder {
          color: rgba(black,.4);
        }


        border-top:    1px solid darken(white,5);
        border-right:  1px solid darken(white,7);
        border-left:   1px solid darken(white,7);
        border-bottom: 1px solid darken(white,9);


        html.mod-transitions-enabled & {
          transition: box-shadow 130ms ease-out;
        }

        &:focus {
          box-shadow: 0 2px 5px 0 rgba(black,.03), 0 2px 10px 0 rgba(black,.03);
        }



      }


      &.mod-private textarea {
        border-left: 4px solid darken(white,20);
      }



      button {
        display: block;
        background: white;

        border:    1px solid darken(white,9);

        color: rgba(black,.7);
        border-radius: 3px;
        padding: 4px 10px;
        margin-top: 5px;
        font-size: 12px;
        //margin-left: auto;


        html.mod-transitions-enabled & {
          transition: color 100ms ease-out, background-color 70ms ease-out, border-color 70ms ease-out, box-shadow 130ms ease-out;
        }

        &:hover {

          background: $clr-ggather;
          color: rgba(white,.9);

        }
      }
    }




      .comments-markdown-icon {
        position: absolute;
        top: 8px;
        right: 23px;

        /deep/ {
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: rgba(black,.2);
            }
          }
        }


        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        //&:hover {
        //  /deep/ svg path {
        //    fill: rgba(black,.5);
        //  }
        //}


        .comments-input-box textarea:focus ~ & {
          opacity: .3;
          pointer-events: none;
        }
      }





      .comments-required-box {
        display: block;
        border-top:    1px solid darken($clr-ggather,3);
        border-right:  1px solid darken($clr-ggather,5);
        border-left:   1px solid darken($clr-ggather,5);
        border-bottom: 1px solid darken($clr-ggather,7);
        background: $clr-ggather;
        padding: 9px 12px;
        margin: 10px;
        line-height: 1.3;
        font-size: 13px;
        color: rgba(white,.93);
        border-radius: 2px;
        width: 100%;
        width: calc(100% - 20px);
        text-align: left;
      }



    .comments-spinner {
      display: block;
      padding: 10px 20px;
      text-align: center;
    }






  .comments-saves-wrapper {
    padding-bottom: 50px;
  }

  .comments-save-empty {
    display: block;
    margin: 10px;

    font-size: 13px;
    line-height: 1.2;
    font-weight: 500;
    color: rgba(black,.6);
    border-radius: 2px;
    font-weight: 500;
    border: 1px solid darken(#eee,8);
    background-color: #eee;
    padding: 10px 12px;
  }

  .comments-save-spinner {
    display: block;
    padding: 10px 20px;
    text-align: center;
  }



  .comments-save {
    display: block;
    position: relative;
    padding: 8px 25px 8px 15px;
    border-top: 1px solid darken(white,5);

    &:last-of-type {
      border-bottom: 1px solid darken(white,5);
    }


    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      z-index: 0;
      //border-radius: 3px;
      background: white;
      border-top:    1px solid darken(white,8);
      //border-right:  1px solid darken(white,12);
      //border-left:   1px solid darken(white,12);
      border-bottom: 1px solid darken(white,15);
      box-shadow: 0 1px 10px 0 rgba(black,.1);
      opacity: 0;
    }

    &:hover {
      border-top-color: transparent;
      padding-bottom: 5px;
      &::before {
        opacity: 1;
      }

      & + .comments-save {
        border-top-color: transparent;
      }
    }



  }


  .comments-save-front {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 2;
  }

    .comments-save-image {
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      display: inline-block;
      border-radius: 300px;
      background-color: darken(white,8);
      background-size: cover;
      background-position: 50%;
      margin-right: 12px;
      align-self: flex-start;

      border-top:    1px solid darken(white,8);
      border-right:  1px solid darken(white,12);
      border-left:   1px solid darken(white,12);
      border-bottom: 1px solid darken(white,15);
    }

    .comments-save-text {
      display: inline-block;
    }

      .comments-save-title {
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        color: rgba(black,.8);

        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }

        .comments-save-front:hover & {
          color: $clr-ggather;
        }
      }


      .comments-save-subtitle {
        display: block;
        font-size: 12px;
        line-height: 1.2;
        font-weight: 400;
        color: rgba(black,.6);
      }





    .comments-save-tags {
      margin-top: 8px;
      position: relative;
      z-index: 1;

      display: none;
      .comments-save:hover & {
        display: block;
      }

    }



    .comments-save-tags-label {
      display: block;
      font-size: 12px;
      line-height: 1.2;
      font-weight: 500;
      color: rgba(black,.5);
      padding-bottom: 4px;
      padding-top: 4px;
      text-transform: uppercase;
    }

      .comments-save-tag {
        display: inline-block;
        border: 1px solid #eee;
        border-radius: 2px;
        padding: 1px 4px 1px 4px;
        font-weight: 400;
        font-size: 12px;
        color: rgba(white,.9);
        outline: none;
        margin-right: 4px;
        margin-bottom: 4px;
        position: relative;
        overflow: hidden;

        html.mod-transitions-enabled & {
          transition: background-color 50ms ease-out, border-color 60ms ease-out, color 60ms ease-out, opacity 150ms ease-out, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
        }


        & > .text {
          font-size: 12px;
          font-weight: 400;
          vertical-align: baseline;
          display: inline-block;
        }



        &.mod-color-1 { background-color: darken($clr-tag-1, 2);  border-color: darken($clr-tag-1, 3); }
        &.mod-color-2 { background-color: darken($clr-tag-2, 2);  border-color: darken($clr-tag-2, 3); }
        &.mod-color-3 { background-color: darken($clr-tag-3, 2);  border-color: darken($clr-tag-3, 3); }
        &.mod-color-4 { background-color: darken($clr-tag-4, 2);  border-color: darken($clr-tag-4, 3); }
        &.mod-color-5 { background-color: darken($clr-tag-5, 2);  border-color: darken($clr-tag-5, 3); }
        &.mod-color-6 { background-color: darken($clr-tag-6, 2);  border-color: darken($clr-tag-6, 3); }
        &.mod-color-7 { background-color: darken($clr-tag-7, 2);  border-color: darken($clr-tag-7, 3); }
        &.mod-color-8 { background-color: darken($clr-tag-8, 2);  border-color: darken($clr-tag-8, 3); }
        &.mod-color-9 { background-color: darken($clr-tag-9, 2);  border-color: darken($clr-tag-9, 3); }

        //&.mod-color-1 > .text { color: rgba( white ,.7); }
        //&.mod-color-2 > .text { color: rgba( white ,.7); }
        //&.mod-color-3 > .text { color: rgba( white ,.7); }
        //&.mod-color-4 > .text { color: rgba( white ,.7); }
        //&.mod-color-5 > .text { color: rgba( white ,.7); }
        //&.mod-color-6 > .text { color: rgba( white ,.7); }
        //&.mod-color-7 > .text { color: rgba( white ,.7); }
        //&.mod-color-8 > .text { color: rgba( white ,.7); }
        //&.mod-color-9 > .text { color: rgba( white ,.7); }





      }




</style>





<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .comments-content {
    background-color: darken(white, 75);

    &::-webkit-scrollbar-thumb {
      background-color: darken(white,50);
    }


    &::-webkit-scrollbar-thumb:hover {
      background-color: darken(white,45);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: darken(white,40);
    }

    &::-webkit-scrollbar-track {
      background-color:  darken(white,75);
      border-left: 1px solid  darken(white,75);
    }

  }



  .comments-heading {
    background-color: darken(white, 80);
    border-color: darken(white, 83);
  }
  .comments-heading-title {
    color: rgba(white,.9);
  }



  .comments-section-title {
    color: rgba(white,.9);
    svg {
      /deep/ path {
        fill: rgba(white,.3);
      }
    }
    &.mod-public svg {
    }
  }



  .comments-section-separator {
    background: darken(white,70);
  }



  .comments-private-user-image {
    background-color: darken(white,50);
  }









  .comments-input-box {

    textarea {
      background: darken(white,60);
      border-color: darken(white,50);
      color: rgba(white,.9);
      &::placeholder {
        color: rgba(white,.4);
      }
      &:focus {
        box-shadow: 0 2px 5px 0 rgba(white,.03), 0 2px 10px 0 rgba(white,.03);
      }
    }


    &.mod-private textarea {
      border-color: darken(white,50);
    }



    button {
      background: darken(white,60);
      border-color: darken(white,50);
      color: rgba(white,.7);

      &:hover {
        background: darken(white,60);
        color: rgba(white,.9);
      }
    }
  }




  .comments-markdown-icon {

    & /deep/ svg {
      path {
        fill: rgba(white,.3);
      }
    }

  }





    .comments-required-box {
      border-color: darken(white,70);
      background: darken(white,60);
      color: rgba(white,.93);
    }



    .comments-spinner {
    }





  .comments-save-empty {
    color: rgba(white,.6);
    border-color: darken(white,60);
    background-color: darken(white,70);
  }

  .comments-save-spinner {
  }



  .comments-save {
    border-top: 1px solid darken(white,5);

    &:last-of-type {
      border-bottom: 1px solid darken(white,5);
    }


    &::before {
      background: white;
      border-top:    1px solid darken(white,8);
      border-bottom: 1px solid darken(white,15);
      box-shadow: 0 1px 10px 0 rgba(black,.1);
    }

    &:hover {
      border-top-color: transparent;
      & + .comments-save {
        border-top-color: transparent;
      }
    }



  }



  .comments-save-image {
    background-color: darken(white,70);
    border-color: darken(white,60);
  }



  .comments-save-title {
    color: rgba(white,.8);
    .comments-save-front:hover & {
      color: white;
    }
  }


  .comments-save-subtitle {
    color: rgba(white,.6);
  }



  .comments-save-tags-label {
    color: rgba(white,.5);
  }

  .comments-save-tag {
    border-color: darken(white,60);
    color: rgba(white,.9);

    /*
    & > .text {
    }
    &.mod-color-1 { background-color: darken($clr-tag-1, 2);  border-color: darken($clr-tag-1, 3); }
    &.mod-color-2 { background-color: darken($clr-tag-2, 2);  border-color: darken($clr-tag-2, 3); }
    &.mod-color-3 { background-color: darken($clr-tag-3, 2);  border-color: darken($clr-tag-3, 3); }
    &.mod-color-4 { background-color: darken($clr-tag-4, 2);  border-color: darken($clr-tag-4, 3); }
    &.mod-color-5 { background-color: darken($clr-tag-5, 2);  border-color: darken($clr-tag-5, 3); }
    &.mod-color-6 { background-color: darken($clr-tag-6, 2);  border-color: darken($clr-tag-6, 3); }
    &.mod-color-7 { background-color: darken($clr-tag-7, 2);  border-color: darken($clr-tag-7, 3); }
    &.mod-color-8 { background-color: darken($clr-tag-8, 2);  border-color: darken($clr-tag-8, 3); }
    &.mod-color-9 { background-color: darken($clr-tag-9, 2);  border-color: darken($clr-tag-9, 3); }
    */
  }






  .comments-save-empty {
    color: rgba(white,.6);
    border: 1px solid darken(white,60);
    background-color: darken(white,70);
  }

  .comments-save {
    border-top: 1px solid darken(white,60);
    background-color: darken(white,70);

    &:last-of-type {
      border-bottom: 1px solid darken(white,60);
    }

    &::before {
      background: darken(white,70);
      border-top:    1px solid darken(white,60);
      border-bottom: 1px solid darken(white,60);
      box-shadow: none;
      opacity: 0;
    }


    &:hover {
      background-color: darken(white,70);
      border-top-color: darken(white,60);
      & + .comments-save {
        border-top-color: darken(white,60);
      }
    }

  }










}

</style>
