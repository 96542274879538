<script>
export default {

  name: 'pricinginfo',


  data: function(){
    return {
    };
  },

  created: function(){
  },

  mounted: function(){
  },


  beforeDestroy: function(){
  },



  methods: {

    scrollToUpgradeContainer: function(){
      document.querySelector('#upgrade-account').scrollIntoView({behavior: 'smooth'});
    },

  },


};
</script>


<template>
<section class="pricinginfo">


  <h2 class="pricinginfo-title">
    <span class="pricinginfo-logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438 668"><path fill="#000000" class="cls-1" d="M376.29,0h-33a60.31,60.31,0,0,0-54.49,34.46l-35,73.69a14.65,14.65,0,0,0,13.23,20.92h71.62c19.15,0,29.42,22.53,16.85,37-69.92,80.39-190.26,216-251.8,287-5.68,6.56-16.18-.2-12.36-8C120.5,385.68,180.81,268,209.8,208.45a7.42,7.42,0,0,0-6.68-10.67H115c-17.16,0-26.44-20.1-15.32-33.16l99-116.32C214.94,29.28,201.42,0,176.42,0H61.71A61.31,61.31,0,0,0,.4,61.31V636.42c0,29.36,36.28,43.16,55.79,21.23L173.86,525.41a60.42,60.42,0,0,1,90.28,0L381.81,657.65c19.52,21.93,55.79,8.13,55.79-21.23V61.31A61.31,61.31,0,0,0,376.29,0Z"/></svg>
    </span>
    <span class="pricinginfo-appname">GGather </span>
    <span class="pricinginfo-title-label">Plans &amp; Pricing</span>
  </h2>

  <p class="pricinginfo-subtitle">While GGather can be used for free with many features, it also has an additional paid plan for people that like to manage their bookmarks with extra possibilites.</p>

  <p class="pricinginfo-subtitle2">
    <span>GGather is an independent and bootstrapped passion project and every paid subscription is a tremendous help in maintaining and growing it in every aspect.</span>
  </p>

  <div class="pricinginfo-compare">

    <div class="pricinginfo-compare-header">

      <div class="pricinginfo-compare-header-item mod-first">
        <strong>Feature</strong>
      </div>

      <div class="pricinginfo-compare-header-item mod-second">
        <span><strong>GGather</strong> Free</span>

        <span v-if="$store.state.user.is_authenticated && (!$store.state.user.is_premium)" class="pricinginfo-compare-header-label mod-status" title="Your GGather account is currently on this plan">Current Plan</span>


      </div>

      <div class="pricinginfo-compare-header-item mod-third">
        <span><strong>GGather</strong> Plus</span>

        <button key="upgradenow" v-if="!$store.state.user.is_premium" class="pricinginfo-compare-header-label mod-cta" type="button" @click.prevent="scrollToUpgradeContainer">Upgrade Now</button>

        <span key="currentplanplus" v-else class="pricinginfo-compare-header-label mod-status" >Current Plan</span>

      </div>

    </div>

    <ul class="pricinginfo-compare-items">


      <pricingitem free paid first
        label="Google Chrome extension"
        tooltip="Google Chrome Extension that allows to very quickly save any website using various methods. It also allows to quickly open your full library without going to GGather.com web application."
      />

      <pricingitem free paid
        label="Clean &amp; intuitive user experience"
      />

      <pricingitem free paid
        label="Light &amp; dark user interface theme"
      />

      <pricingitem free paid
        label="Advanced bookmark customization"
        tooltip="You can edit everything about your bookmark like title, description, URL, thumbnail, notes, etc."
      />

      <pricingitem free paid
        label="Basic searching, sorting &amp; filtering"
      />

      <pricingitem free paid
        label="Bookmarks select mode"
        tooltip="Ability to select multiple or all bookmarks and remove or open them in new tabs."
      />

      <pricingitem free paid
        label="Tags customization, nesting &amp; filtering"
      />

      <pricingitem free paid
        label="Private &amp; unlisted tags"
        tooltip="There are 3 types of tags. Private (accessible only for owner), unlisted (accessible by anyone if they know the URL), public (invite only, public tags are featured in GGather discover &amp; social sections)."
      />

      <pricingitem free paid
        label="Subscribing to tags"
        tooltip="Ability to subscribe to other people unlisted &amp; public tags."
      />
      <!--
      tooltip="<img src='//unsplash.it/500/500' width='500' height='500' /> Lorem Ipsum "
      tooltip-image-icon -->

      <pricingitem free paid
        label="Discover &amp; social sections"
      />


      <pricingitem free paid
        label="Unlimited export"
        tooltip="You can export your bookmarks both in HTML and JSON format."
      />

      <pricingitem      paid
        label="Unlimited import"
        tooltip="Free accounts can import only up to 2000 bookmarks."
      />


      <pricingitem      paid
        label="Subtag filtering"
        tooltip="Ability to additionally filter bookmarks in tag pages using other tags that were assigned to those bookmarks."
      />

      <pricingitem      paid
        label="5 additional link layouts"
        tooltip="Different link layouts determine how bookmarks look in your library."
      />

      <pricingitem free paid
        label="Public bookmark annotations"
      />

      <pricingitem      paid
        label="Private bookmark annotations"
      />

      <pricingitem free paid last
        label="&amp; more constantly under work!"
      />


    </ul>

  </div>

</section>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .pricinginfo {
    background: #F6F6F6;
    max-width: 640px;
    padding: 30px;
    border-radius: 8px;
    //box-shadow: 0 2px 10px 0px rgba(black,.13);
    border: 1px solid darken(white,12);

    @media (max-width: 670px) {
      margin-top: 5px;
      padding: 10px;
    }
  }



  .pricinginfo-title {
    text-align: center;

    margin-top: 10px;
    margin-bottom: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media (max-width: 670px) {
      display: block;
      margin-top: 20px;
    }
  }

  .pricinginfo-logo {
    margin-right: 12px;
    margin-bottom: 4px;

    svg {
      width: 24px;
      height: auto;
    }
    svg path {
      fill: $clr-ggather;
    }

    @media (max-width: 670px) {
      position: relative;
      top: -5px;
      margin-right: 6px;
      svg {
        width: 22px;
      }
    }
  }

  .pricinginfo-appname {
    margin-right: 10px;

    font-size: 27px;
    font-weight: 700;
    line-height: 1.2;
    color: $clr-ggather;

    @media (max-width: 670px) {
      font-size: 24px;
    }

  }

  .pricinginfo-title-label {
    font-size: 27px;
    font-weight: 700;
    line-height: 1.2;
    color: darken(white,80);

    @media (max-width: 670px) {
      font-size: 24px;
      display: block;
    }
  }


  .pricinginfo-subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    color: darken(white,85);
    text-align: center;

    margin-bottom: 10px;
    margin-top: 10px;

    @media (max-width: 670px) {
      font-size: 13px;
    }
  }

  .pricinginfo-subtitle2 {

    margin-top: 10px;
    margin-bottom: 14px;
    margin-left: 5px;
    margin-right: 5px;

    position: relative;

    span {
      display: block;
      position: relative;
      z-index: 2;
      padding: 6px 15px;
      font-size: 13px;
      font-weight: 400;
      text-align: center;
      line-height: 1.4;
      color: rgba(white,1);

      @media (max-width: 670px) {
        font-size: 12px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //transform: skewX(-4deg);
      background-color: #E28F26;
      border-radius: 4px;
    }

  }





  .pricinginfo-compare {
    padding-top: 10px;
  }


  .pricinginfo-compare-items {

  }

  .pricinginfo-compare-header {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
  }


  .pricinginfo-compare-header-item {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1;
    color: darken(white,90);
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;

    > span:first-of-type {
      display: block;
    }

    &.mod-first {
      width: calc(100% - 240px);
      padding-left: 5px;
    }

    &.mod-second {
      width: 120px;
      text-align: center;
    }

    &.mod-third {
      width: 120px;
      text-align: center;
      background: rgba($clr-ggather, .05);
      color: $clr-ggather;
      border-radius: 4px 4px 0 0;
    }

    strong {
      font-weight: 600;
    }





    @media (max-width: 670px) {

      &.mod-first {
        width: calc(100% - 140px);
      }

      &.mod-second {
        width: 70px;
      }

      &.mod-third {
        width: 70px;
      }

    }




  }



  .pricinginfo-compare-header-label {
    appearance: none;
    display: inline-block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 4px;
    border: 1px solid rgba(black,.1);
    border-radius: 3px;
    font-size: 11px;
    line-height: 1.2;
    font-weight: 400;
    padding: 3px 6px;
    outline: none;


    &.mod-status {

    }

    &.mod-cta {
      background: rgba($clr-ggather, 1);
      border-color: $clr-ggather;
      color: rgba(white,.9);
    }


    @media (max-width: 670px) {
      font-size: 10px;
      padding: 2px 4px;
    }

  }






</style>









<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .pricinginfo {
    background-color: darken(white,80);
    border-color: darken(white,71);
  }

  .pricinginfo-title-label {
    color: darken(white,10);
    font-weight: 600;
  }

  .pricinginfo-subtitle {
    color: darken(white,20);
    font-weight: 400;
  }

  .pricinginfo-compare-header-item {
    color: rgba(white,.7);
  }

  .pricinginfo-compare-header-label {
    border-color: rgba(white,.15);
  }

  .pricinginfo-compare-header-item.mod-third {
    background: rgba($clr-ggather,.2);
    border-color: #354f7b;
  }

}
</style>
