<script>
export default {

  props: [
    'context',
  ],

  data: function(){
    return {
    };
  },


  computed: {

  },

  watch: {
  },

  created: function(){
  },


  methods: {


  },

};
</script>


<template>
<div class="socialtabs-wrapper" :class="['mod-context-'+context]">


  <div class="socialtabs-back-btn-wrapper">
    <router-link to="/all" class="socialtabs-back-btn">
      ← Back to My Library
    </router-link>
  </div>



  <div class="socialtabs-tabs">
    <router-link to="/following" class="socialtabs-tab"
    :class="{'mod-active': context=='followed', 'mod-disabled': !$store.state.user.is_authenticated}">
    Following</router-link>
    <router-link to="/feed" class="socialtabs-tab"
    :class="{'mod-active': context=='feed', 'mod-disabled': !$store.state.user.is_authenticated}">
    Feed</router-link>
    <router-link to="/discover" class="socialtabs-tab"
    :class="{'mod-active': context=='discover'}">
    Discover</router-link>
  </div>


  <div class="socialtabs-depr-wrapper">
    <p class="socialtabs-depr">Social bookmarking features will be removed soon and GGather will focus more on the private bookmarking aspect.</p>
  </div>

</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .socialtabs-wrapper {
    padding: 20px 20px 0 20px;


    @media (max-width: 800px) {
      padding-top: 10px;
      padding-bottom: 0px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .socialtabs-back-btn-wrapper {
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }


  .socialtabs-back-btn {
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: block;
    text-align: left;
    outline: none;
    margin-top: 10px;
    width: 100%;
    color: darken(white,90);

  }

  .socialtabs-depr-wrapper {
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .socialtabs-depr {
    padding: 20px 15px 18px 15px;
    border-radius: 4px;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    line-height: 1.4;
    background: orange;
    border: 1px solid darken(orange,7);
    color: darken(orange,50);
    letter-spacing: 0.3px;
  }

  .socialtabs-tabs {
    font-size: 0;
    display: flex;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    position: relative;
    z-index: 20;
    border: 1px solid darken(white,10);
  }

  .socialtabs-tab {
    appearance: none;
    display: block;
    border-left: 1px solid darken(white,6);
    display: inline-block;
    width: 50%;

    padding: 20px 20px 19px 20px;
    text-align: center;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.4;
    background: darken(white,0);
    color: darken(white,80);

    &:first-of-type {
      border-radius: 4px 0px  0px 4px;
      border-left: none;
    }

    &:last-of-type {
      border-radius:  0px 4px 4px 0px;
    }


    &.mod-active {
      background:  darken(white,80);
      color: rgba(white,.9);

      &:first-of-type {
        border-right-color:  darken(white,5);
      }

      &:last-of-type {
        border-left-color:  darken(white,5);
      }

    }


    &.mod-disabled {
      opacity: .4;
      pointer-events: none;
    }

    @media (max-width: 800px) {
      font-size: 14px;
      padding: 10px 10px 9px 10px;
    }
  }


</style>






<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .socialtabs-back-btn {
    color: darken(white,20);
  }


  .socialtabs-depr {
    background: darken(orange,10);
    border: 1px solid darken(orange,5);
    color: lighten(orange,50);
    font-weight: 500;
  }



  .socialtabs-tabs {
    border-color: darken(white,63);
  }

  .socialtabs-tab {
    background: darken(white,70);
    border-color: darken(white,63);
    color: darken(white,10);


    &.mod-active {
      background: darken(white,63);
      color: rgba(white,.9);
    }
  }

}
</style>
