<script>
import contains from 'dom-helpers/contains';
export default {

  data: function(){
    return {
      navigationExpanded: false,

      searchValue: '',

      newTagValue: '',
      newTagLoading: false,

      tagsFilterFocused: false,

      tagsFiltersOpen: false,
      tagsFiltersTab: 3,

    }
  },


  computed: {

    tagsFilterExpanded: function(){
      if (this.tagsFiltersOpen) return true;
      if (this.tagsFilterFocused) return true;
      return !!this.tagsFilter;
    },

    tagsFilter: {
      get: function(){
        return String(this.$store.state.tagsFilter);
      },
      set: function(value) {
        this.$store.commit('tagsFilter', String(value));
      },
    },

  },

  watch: {

    '$route.meta.kind': function(newVal, oldVal){
      if (newVal!=='search') {
        this.searchValue = '';
      }
    },

    '$store.state.tagsFilter': function(newVal){
      if (this.tagsFiltersOpen) {
        this.tagsFiltersOpen = false;
      }
    },

  },



  mounted: function(){
    this.searchValue = this.$route.query.query || ''
    document.addEventListener('click', this.sbarBodyClick, false);
  },


  beforeDestroy: function(){
    document.removeEventListener('click', this.sbarBodyClick, false);
  },

  methods: {

    sbarBodyClick: function(event){
      var clickedInsideSection = contains(this.$refs.sectionTagsFilters, event.target);
      if (!clickedInsideSection) {
        this.tagsFiltersOpen = false;
      }
    },



    addLinkClick: function(){
      if ( this.$store.state.viewer && (this.$store.state.viewer.kind == 'newlink') ) {
        this.$store.commit('viewer', null);
      } else {
        this.$store.commit('viewer', {item: null, kind: 'newlink'});
      }
    },








    applySearch: function(){
      if (!this.searchValue) return;
      this.$router.push({
        path: '/search',
        query: {
          'query': this.searchValue,
        } ,
      });
    },

    clearSearch: function(){
      if (!this.searchValue) return;
      this.searchValue = '';
      if (this.$route.meta.kind==='search') this.$router.push('/all');
    },







    clearNewTag: function(){
      if (!this.newTagValue) return;
      this.newTagValue = '';
    },



    addNewTag: function(){
      if(!this.newTagValue) return;
      this.newTagLoading = true;
      this.$store.dispatch('apiNewTag', {
        name: this.newTagValue,
        onSuccess: function(response){
          this.newTagValue = '';
          this.newTagLoading = false;
        }.bind(this),
        onError: function(error){
          this.newTagLoading = false;
        }.bind(this),
      });
    },










    tagsFilterFocus: function(){
      this.tagsFilterFocused = 1;
      this.tagsFiltersOpen = 1;
    },

    tagsFilterBlur: function(){
      this.tagsFilterFocused = 0;
    },

    tagsFilterClick: function(){
      //if (this.tagsFilterFocused) {
        this.tagsFiltersOpen = 1;
      //}
    },

    tagsFiltersClose: function(){
      this.tagsFiltersOpen = 0;
    },

    tagsFiltersTabChange: function(requestedTab){
      this.tagsFiltersTab = requestedTab;
    },

    tagsFiltersFilter: function(event){

      if (event.type==='firstletter') {
        this.tagsFilter = ':' + event.value;
      }
      else if (event.type==='color') {
        this.tagsFilter = ':' + event.value;
      }
      else if (event.type==='favorite') {
        this.tagsFilter = ':fav';
      }
      else if (event.type==='private') {
        this.tagsFilter = ':priv';
      }
      else if (event.type==='unlisted') {
        this.tagsFilter = ':un';
      }
      else if (event.type==='public') {
        this.tagsFilter = ':pub';
      }
      else if (event.type==='all') {
        this.tagsFilter = '';
      }
      else if (event.type==='recent') {
        this.tagsFilter = ':rec';
      }
      else if (event.type==='parent') {
        this.tagsFilter = ':parent:' + event.value;
      }
      else {
        this.tagsFilter = ':' + event.type + ':' + event.value;
      }


      if (this.tagsFiltersOpen) {
        this.tagsFiltersOpen = false;
      }

    },

    clearTagsFilter: function(){
      if (!this.tagsFilter) return;
      this.tagsFilter = '';

      //this.$nextTick(function(){
      //  this.tagsFiltersOpen = true;
      //});
    },


    collapseOrUncollapse: function(){
      this.$store.commit('sidebarCollapse', !this.$store.state.sidebarCollapse);
      this.$store.commit('sidebarVisible', false);
    },


  },

};
</script>


<template>

  <div
    class="sbar"
    :class="{
      'mod-collapsed': $store.state.sidebarCollapse,
      'mod-open': $store.state.sidebarVisible,
      'mod-navigation-expanded': this.navigationExpanded,

    }"
  >


    <div class="sbar-overlay" @click.stop.prevent="$store.commit('sidebarVisible', 0)"></div>

    <div class="sbar-bar">

      <sbar-grip />

      <div class="sbar-inner">

        <topbar :sidebar-context="true" />

        <div v-if="$store.state.sidebarStatus" class="sbar-status" @click="$store.commit('sidebarStatus', '')">
          <div class="sbar-status-content">
            {{$store.state.sidebarStatus}}
          </div>
        </div>

        <div v-if="$store.state.sidebarSpinner" class="sbar-spinner">
          <div class="sbar-spinner-content">
            <spinner kind="dots" />
          </div>
        </div>

        <div class="sbar-scrollable">


          <div class="sbar-section mod-nav">

            <!--
            <div class="sbar-section-heading">
              <h3 class="sbar-section-title">Navigation</h3>
            </div>  -->



            <div class="sbar-nav-row">
              <form action="" @submit.prevent="applySearch" class="sbar-nav-search">
                <input class="sbar-nav-search-input" type="text" placeholder="Search Links..." v-model="searchValue" autofocus>
                <span class="sbar-nav-search-icon">
                  <iconsvg icon="w10-search" />
                </span>
                <button v-if="searchValue" class="sbar-nav-search-clear" type="button" @click.prevent="clearSearch">
                  <iconsvg icon="material-delete" />
                </button>
              </form>
            </div>


            <div class="sbar-nav-row">

              <router-link
                to="/all"
                :class="['sbar-nav-button mod-all']"
              >
                <span>All Links</span>
              </router-link>

              <router-link
                @click.native.stop.prevent
                to="/all?untagged=true"
                :class="['sbar-nav-button mod-untagged']"
              >
                <span>Untagged</span>
              </router-link>

              <button
                type="button"
                :class="['sbar-nav-button mod-add']"
                @click.prevent="addLinkClick"
              >
                <iconsvg icon="material-add" />
              </button>


            </div>




            <div class="sbar-nav-row  mod-collapse" v-if="navigationExpanded">
              <button
                type="button"
                :class="['sbar-nav-button mod-collapse']"
                @click.prevent="collapseOrUncollapse"
              >
                <iconsvg icon="enter" />
                <span v-if="$store.state.sidebarCollapse">Static Sidebar</span>
                <span v-else>Overlay Sidebar</span>
              </button>
            </div>



            <!-- to implement in future... -->
            <!--

            <div class="sbar-nav-row">

              <router-link
                to="/all?reminder=true"
                :class="['sbar-nav-button mod-reminders']"
              >
                <span>23 Reminders</span>
              </router-link>

              <router-link
                to="/rediscover"
                :class="['sbar-nav-button mod-rediscover']"
              >
                <span>Rediscover</span>
              </router-link>

              <router-link
                to="/all?source=phone"
                :class="['sbar-nav-button mod-phone']"
              >
                <iconsvg icon="material-add" />
              </router-link>

            </div>-->



            <!--
            <div class="sbar-nav-row mod-arrow">
              <button title="Navigation Expand/Collapse" type="button" class="sbar-nav-expand-btn" @click.prevent="navigationExpanded=!navigationExpanded">
                <iconsvg icon="expand-arrow-filled" />
                <span>Additional Navigation</span>
              </button>
            </div>
             -->


          </div>


          <div class="sbar-sections-tags">


            <div v-if="$store.state.sidebarSpinner" class="sbartags-tags-mask" key="sbartags-tags-mask"></div>



            <div v-if="$store.getters.favoriteTags && $store.getters.favoriteTags.length" class="sbar-section mod-favtags" key="sbartags-section-favtags">

              <div class="sbar-section-heading">
                <h3 class="sbar-section-title">Favorite Tags</h3>
              </div>

              <div class="sbar-section-favtags-wrapper">

                <!--
                <div v-if="$store.getters.favoriteTags===null" key="asddasasd" class="">
                  <spinner kind="dots" />
                </div> -->

                <sbar-favtag
                  v-if="$store.getters.favoriteTags && $store.getters.favoriteTags.length"
                  v-for="favtag in $store.getters.favoriteTags"
                  :key="('favtag'+favtag.pk)"
                  :favtag="favtag"
                />

              </div>

            </div>




            <div class="sbar-section mod-tags-filters" ref="sectionTagsFilters" key="sbartags-section-tags-filters">


              <div class="sbar-section-heading" @click="$refs.tagsFilterInput.focus()">

                <h3 class="sbar-section-title">All Tags</h3>

                <div class="sbar-tags-filter" :class="{'mod-expanded': tagsFilterExpanded}">

                  <form action="" @submit.prevent="null"  class="sbar-tags-filter-input-wrapper">

                    <input class="sbar-tags-filter-input" type="text" placeholder="" ref="tagsFilterInput" v-model="tagsFilter" @focus="tagsFilterFocus" @blur="tagsFilterBlur" @click="tagsFilterClick"  />

                    <div class="sbar-tags-filter-icon">
                      <iconsvg icon="w10-search" />
                    </div>

                    <button v-if="tagsFilter" class="sbar-tags-filter-clear" type="button" @click.prevent="clearTagsFilter">
                      <iconsvg icon="material-delete" />
                    </button>

                  </form>

                </div>
              </div>

              <div v-if="tagsFiltersOpen" class="sbar-tags-filters-wrapper">
                <tagsfilters
                  context="sbar"
                  @filter="tagsFiltersFilter"
                  @close="tagsFiltersClose"
                  @tabchange="tagsFiltersTabChange"
                  :tab="tagsFiltersTab"
                />
              </div>

            </div>



            <div class="sbar-section mod-tags" key="sbartags-section-tags" >
              <sbar-tags key="sbar-tags" />
            </div>




            <div v-if="($store.state.tags!==null) && (!$store.state.tagsFilter)" class="sbar-section mod-new-tag" key="sbartags-section-new-tag">

              <form action="" @submit.prevent="addNewTag" class="sbar-new-tag">
                <input class="sbar-new-tag-input" type="text" placeholder="New Tag..." v-model="newTagValue" >
                <span key="newtagaddicon" v-if="!newTagLoading"  class="sbar-new-tag-icon">
                  <iconsvg icon="material-add" />
                </span>
                <button key="newtagclear" v-if="newTagValue" class="sbar-new-tag-clear" type="button" @click.prevent="clearNewTag">
                  <iconsvg icon="material-delete" />
                </button>
                <div key="newtagspinner" v-if="newTagLoading" class="sbar-new-tag-spinner">
                  <div>
                    <spinner2 :size="18" :line-size="2" :speed="0.8" line-fg-color="white" line-bg-color="transparent" />
                  </div>
                </div>
              </form>

            </div>




          </div>



        </div>

      </div>
    </div>

  </div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  $clr-sbar: darken(white,2);

  $scrollbar-track-color: $clr-sbar;
  $scrollbar-bar-color: darken(white,10);


  .sbar {

  }


  .sbar-overlay {
    background-color: rgba( darken(white,85), 0.8);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 52;

    display: none;

    .sbar.mod-collapsed.mod-open & {
      display: block;
    }


    @media(max-width: 900px) {
      display: none;
      .sbar.mod-open & {
        display: block;
      }
    }


  }

  .sbar-bar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 52;

    width: 280px;

    background: $clr-sbar;
    border-right: 1px solid darken($clr-sbar,8);
    //box-shadow: 0 0 20px 0 rgba(black,.06), 0 0 5px 0 rgba(black,.04);

    html.mod-transitions-enabled & {
      transition: left 150ms ease-in-out;
    }

    .sbar.mod-collapsed & {
      //transform: translateX(-280px);
      left: -280px;
    }


    .sbar.mod-open & {
      //transform: translateX(0px);
      left: -0px;
    }


    html.mod-final-sidebar-collapse & {
      //transform: translateX(-280px);
      left: -280px;
      .sbar.mod-open & {
        //transform: translateX(0px);
        left: -0px;
      }
    }

  }

  .sbar-inner {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;

    padding-top: 45px;
  }




  .sbar-spinner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //top: 0;
    z-index: 32;
  }



  .sbar-spinner-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 33;
    text-align: center;
    background: $clr-sbar;
    background: linear-gradient(to top, rgba($clr-sbar,1), rgba($clr-sbar,.0));
    padding: 30px 15px;
    padding-top: 120px;
  }


  .sbar-status {

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    //top: 0;
    z-index: 34;
  }

  .sbar-status-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 35;
    text-align: center;
    background: $clr-sbar;
    padding: 30px 15px;
    font-size: 12px;
    color: rgba(white,.9);
    line-height: 1.2;
    font-weight: 400;
    box-shadow: 0 0 20px 0 rgba(black,.08), 0 0 5px 0 rgba(black,.5);
  }



  .sbartags-tags-mask {
    position: absolute;
    z-index: 31;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, .0);
  }


  .sbar-scrollable {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    //overflow-y: scroll;


    &::-webkit-scrollbar {
      width: 15px;
      height: 12px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 0px;
      background-color: $scrollbar-track-color;
      border-left: 1px solid darken($scrollbar-track-color,0);
    }

    &::-webkit-scrollbar-button {
      display: none;
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darken($scrollbar-bar-color,0);
      background-clip: padding-box;
      border: 3px solid rgba(white,0);
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-radius: 10px;
      min-height: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: darken($scrollbar-bar-color, 0);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: darken($scrollbar-bar-color,25);
    }



  }





    .sbar-sections-tags {
      position: relative;
      padding-top: 10px;
      padding-bottom: 40px;
    }








    .sbar-section {

      &.mod-nav {
        padding: 15px;
        padding-top: 18px;
        padding-bottom: 0;
      }

      &.mod-favtags {
        padding-top: 0px;
      }

      &.mod-tags-filters {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      &.mod-tags {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      &.mod-new-tag {
        padding: 15px;
        padding-top: 12px;
      }

    }

    .sbar-section.mod-favtags + .sbar-section.mod-tags-filters {
      margin-top: 10px;
    }






    .sbar-section-heading {
      padding-left: 15px;
      padding-top: 10px;

      .sbar-section.mod-nav & {
        padding-top: 20px;
        padding-left: 0;
      }

      .sbar-section.mod-tags-filters & {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        align-content: flex-end;
        cursor: text;
      }

      .sbar-section.mod-tags & {
      }

    }



    .sbar-section-title {
      color: rgba(black,.5);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.2;
      text-transform: uppercase;


    }

    .sbar-section-favtags-wrapper {
      font-size: 0;
      padding-left: 5px;
    }
















      .sbar-nav-row {
        display: flex;

        @media (max-width: 900px){
          &.mod-arrow {
            display: none;
          }
          &.mod-collapse {
            display: none;
          }

        }

      }



        .sbar-nav-button {
          position: relative;
          display: inline-block;
          background-color: white;
          border: 1px solid darken(white,10);
          color: rgba(black,.7);
          font-size: 12px;
          font-weight: 500;
          line-height: 1.2;
          border-radius: 4px;
          text-align: left;

          width: 100%;

          margin-left: 5px;
          margin-right: 5px;
          margin-top: 10px;
          outline: none;
          white-space: nowrap;

          overflow: hidden;
          text-overflow: ellipsis;


          html.mod-transitions-enabled & {
            transition: all 70ms ease-out;
          }

          &:hover {
            background: rgba(black,.05);
          }


          &:focus {
            //box-shadow: 0 0 0 2px rgba($clr-ggather,.10);
          }

          & svg {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            margin: auto;
            path {
              fill: rgba(black,.8);
            }
          }

          span {
            display: inline-block;
            padding: 9px 11px 8px 11px;
          }

          svg + span {
            padding-left: 38px;
          }


          &.mod-all {
            margin-left: 0;
          }

          &.mod-add {
            margin-right: 0;
            text-align: center;
            flex: 0 0 35px;
            & svg {
              position: static;
              top: auto;
              left: auto;
              transform: none;
            }
          }

          &.mod-phone {
            margin-right: 0;
            text-align: center;
            flex: 0 0 35px;
          }

          &.mod-collapse {
            margin-left: 0;
            margin-right: 0;
            & svg {
              left: 12px;
              width: 17px;
              height: 17px;
              display: block;
              transform: rotateY(180deg) translateY(-50%) translateX(-2px);
              path { fill: rgba(black,1) }
            }
            .sbar.mod-collapsed & {
              & svg {
                display: block;
                transform: rotateY(0deg) translateY(-50%) translateX(-1px);
              }
            }
          }

          &.mod-exact-route-active {
            background-color: rgba(black,.8);
            color: rgba(white, 1);
            & svg path {
              fill: rgba(white, 1);
            }
          }


        }



        .sbar-nav-expand-btn {
          display: inline-block;
          width: 100%;
          text-align: center;
          padding-top: 5px;
          padding-bottom: 5px;
          min-height: 0;
          line-height: 0;
          height: 24px;
          //width: 60px;
          overflow: hidden;
          margin: 0 auto;
          margin-top: 3px;
          outline: none;
          position: relative;
          left: 3px;

          span {
            position: relative;
            top: 1px;
            display: inline-block;
            vertical-align: top;
            font-size: 11px;
            width: 0px;
            line-height: 1.2;
            color: rgba(black,1);
            font-weight: 400;
            text-transform: uppercase;
            white-space: nowrap;
            opacity: 0;
            overflow: hidden;

            html.mod-transitions-enabled & {
              transition: width 200ms ease-in-out 300ms, opacity 200ms ease-in-out 300ms;
            }
          }

          & svg {
            display: inline-block;
            width: 13px;
            height: 13px;
            vertical-align: top;
            margin: auto;
            transform: rotateX(0deg);
            fill: rgba(black,1);
            opacity: .3;
            html.mod-transitions-enabled & {
              transition: opacity 200ms ease-in-out 200ms;
            }
          }

          &:hover span {
            opacity: .4;
            width: 136px;
          }

          &:hover svg {
            opacity: .6;
          }

          .sbar.mod-navigation-expanded & svg {
            transform: rotateX(-180deg);
          }

        }






        .sbar-nav-search {
          display: block;
          width: 100%;
          position: relative;
          //margin-top: 10px;
        }


          .sbar-nav-search-input {

            width: 100%;
            display: block;
            background-color: white;
            border: 1px solid darken(white,10);
            color: rgba(black,.6);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.2;
            border-radius: 4px;
            text-align: left;
            outline: none;
            appearance: none;

            position: relative;
            z-index: 2;
            padding: 9px 11px;
            padding-left: 37px;
            padding-right: 30px;

            &::placeholder {
              color: rgba(black,.6);
            }

            &:focus {
              background-color: white;
              color: rgba(black,.6);
              //box-shadow: 0 0 0 2px rgba(black,.10);
            }
          }


          .sbar-nav-search-icon {
            top: 50%;
            left: 12px;
            transform: translateY(-55%);
            position: absolute;
            pointer-events: none;
            z-index: 3;
            opacity: .7;
            & svg {
              width: 18px;
              height: 18px;
              path {
                fill: rgba(black,1);
              }
            }
          }


          .sbar-nav-search-clear {
            top: 50%;
            right: 10px;
            transform: translateY(-53%);
            position: absolute;
            outline-color: rgba(black,.2);
            z-index: 4;
            opacity: .5;
            & svg {
              width: 16px;
              height: 16px;
              path {
                fill: rgba(black,1);
              }
            }
          }


          .sbar-nav-search-input:focus + .sbar-nav-search-icon {
            opacity: .9;
          }










        .sbar-new-tag {
          display: block;
          width: 100%;
          position: relative;
        }


          .sbar-new-tag-input {

            appearance: none;
            width: 100%;
            display: block;
            background: white;
            border: 1px solid darken(white,12);
            color: rgba(black,.5);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.2;
            border-radius: 3px;
            text-align: left;
            outline: none;

            position: relative;
            z-index: 2;
            padding: 8px 12px 7px 12px;
            padding-right: 28px;
            padding-left: 28px;

            &::placeholder {
              color: rgba(black,.5);
            }

            &:focus {
              background-color: darken(white,3);
              border-color: darken(white,15);
              color: rgba(black,.9);
              //box-shadow: 0 0 0 2px rgba(black,.10);
            }
          }


          .sbar-new-tag-icon {
            top: 50%;
            left: 6px;
            transform: translateY(-55%);
            position: absolute;
            pointer-events: none;
            z-index: 3;
            & svg {
              width: 18px;
              height: 18px;
              path {
                fill: rgba(black,.4);
              }
            }
          }


          .sbar-new-tag-clear {
            top: 50%;
            right: 10px;
            transform: translateY(-53%);
            position: absolute;
            appearance: none;
            outline-color: rgba(black,.2);
            z-index: 4;
            & svg {
              width: 16px;
              height: 16px;
              path {
                fill: rgba(black,.5);
              }
            }
          }


          .sbar-new-tag-input:focus + .sbar-new-tag-icon {
             & svg path {
              fill: rgba(black,.8);
            }
          }



          .sbar-new-tag-spinner {
            position: absolute;
            z-index: 20;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            background: rgba(black,.4);
            border-radius: 4px;

            > div {
              top: 50%;
              left: 6px;
              transform: translateY(-55%);
              position: absolute;
              z-index: 21;
            }

          }






























        .sbar-tags-filter {

        }

        .sbar-tags-filter-input-wrapper {
          display: block;
          width: 100%;
          position: relative;
          top: 4px;


        }


          .sbar-tags-filter-input {

            display: block;
            appearance: none;
            background: transparent;
            color: rgba(black,.5);
            font-size: 11px;
            font-weight: 500;
            line-height: 1.2;
            border-radius: 3px;
            text-align: left;
            outline: none;

            position: relative;
            z-index: 2;
            padding: 5px 8px 5px 8px;

            &::placeholder {
              color: rgba(black,.5);
            }


            width: 0px;
            padding-left: 22px;
            padding-right: 0px;


            html.mod-transitions-enabled & {
              transition: background 170ms ease-in-out, padding 170ms ease-in-out, width 170ms ease-in-out;
            }


            &:hover {
              background: rgba(white,.1);
              padding-right: 5px;
            }

            .sbar-tags-filter.mod-expanded &,
            &:focus {
              width: 160px;
              padding-left: 27px;
              padding-right: 24px;
              background: rgba(black,.2);
              color: rgba(black,.9);
              //box-shadow: 0 0 0 2px rgba(black,.10);
            }

            // expanded...
            /*
              background: rgba(white,.12);
            */


          }


          .sbar-tags-filter-icon {
            top: 50%;
            left: 6px;
            transform: translateY(-55%);
            position: absolute;
            pointer-events: none;
            z-index: 3;


            & svg {
              width: 16px;
              height: 16px;
              path {
                fill: rgba(black,.5);
              }
            }

            .sbar-tags-filter.mod-expanded & svg path {
              fill: rgba(black,.9);
            }

          }


          .sbar-tags-filter-clear {
            top: 50%;
            right: 0px;
            transform: translateY(-53%);
            position: absolute;
            outline-color: rgba(black,.2);
            z-index: 4;
            padding: 4px;
            padding-left: 4px;
            & svg {
              width: 15px;
              height: 15px;
              path {
                fill: rgba(black,.5);
              }
            }
          }


          .sbar-tags-filter-input:focus + .sbar-tags-filter-icon-icon {
             & svg path {
              fill: rgba(black,.9);
            }
          }






          .sbar-tags-filters-wrapper {
            padding: 14px 15px 0 15px;
            //box-shadow: 0px 1px 5px 0 rgba(black,.15), 0px 1px 35px 0 rgba(black,.25), 0px 10px 55px 0 rgba(black,.25);
            //border-radius: 4px;
          }














</style>











<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  $clr-dark-sbar: darken(white,85);

  $scrollbar-dark-track-color: $clr-dark-sbar;
  $scrollbar-dark-bar-color: darken(white,75);



  .sbar-overlay {
    background: rgba(black, .7);
  }

  .sbar-bar {
    background: $clr-dark-sbar;
    border-right: 1px solid lighten($clr-dark-sbar,5);
  }


  .sbar-spinner-content {
    background: $clr-dark-sbar;
    background: linear-gradient(to top, rgba($clr-dark-sbar,1), rgba($clr-dark-sbar,.0));
  }

  .sbar-status-content {
    background: $clr-dark-sbar;
  }


  .sbar-scrollable::-webkit-scrollbar-track {
    background-color: $scrollbar-dark-track-color;
    border-left: 1px solid lighten($scrollbar-dark-track-color,0);
  }
  .sbar-scrollable::-webkit-scrollbar-button {
  }
  .sbar-scrollable::-webkit-scrollbar-thumb {
    background-color: lighten($scrollbar-dark-bar-color,0);
  }
  .sbar-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: lighten($scrollbar-dark-bar-color, 0);
  }
  .sbar-scrollable::-webkit-scrollbar-thumb:active {
    background-color: lighten($scrollbar-dark-bar-color,25);
  }

  .sbar-section-title {
    color: rgba(white,.4);
  }


  .sbar-nav-button {
    color: rgba(white,.7);
    background: rgba(white, .15);
    border-color: rgba(white, .1);
    & svg path {
      fill: rgba(white, .7);
    }
    &:hover {
      background: rgba(white,.23);
    }
    &.mod-exact-route-active {
      background-color: rgba(white,.8);
      box-shadow: 0 0 0 1px rgba(white,.1) inset;
      color: rgba(black, 1);
      & svg path {
        fill: rgba(black, 1);
      }
    }
  }



  .sbar-nav-expand-btn span {
    color: rgba(white,1);
  }

  .sbar-nav-expand-btn svg {
    fill: rgba(white,1);
  }




  .sbar-nav-search-input {
    background: rgba(white, .15);
    border-color: rgba(white, .1);
    color: rgba(white,.6);
    &::placeholder {
      color: rgba(white,.6);
    }
    &:focus {
      background: rgba(white, .25);
      color: rgba(white,.6);
    }
  }


  .sbar-nav-search-icon {
    & svg path  {
      fill: rgba(white,1);
    }
  }

  .sbar-nav-search-clear {
    outline-color: rgba(white,.2);
    & svg path {
      fill: rgba(white,1);
    }
  }



  .sbar-new-tag-input {
    background: darken(white,72);
    border: 1px solid darken(white,65);
    color: rgba(white,.8);
  }

  .sbar-new-tag-input::placeholder {
    color: rgba(white,.5);
  }

  .sbar-new-tag-input:focus {
    background-color: darken(white,64);
    border-color: darken(white,57);
  }

  .sbar-new-tag-icon svg path {
    fill: rgba(white,.4);
  }




  .sbar-new-tag-input:focus + .sbar-new-tag-icon svg path {
    fill: rgba(white,.8);
  }


  .sbar-new-tag-clear {
    outline-color: rgba(white,.2);
  }
  .sbar-new-tag-clear svg path {
    fill: rgba(white,.5);
  }

  .sbar-new-tag-spinner {
    background: rgba(white,.4);
  }
















  .sbar-tags-filter-input {
    color: rgba(white,.5);
  }
  .sbar-tags-filter-input::placeholder {
    color: rgba(white,.5);
  }
  .sbar-tags-filter-input:hover {
    background-color: rgba(white,.1);
  }
  .sbar-tags-filter.mod-expanded .sbar-tags-filter-input,
  .sbar-tags-filter-input:focus {
    background-color: rgba(white,.3);
    color: rgba(white,.9);
  }



  .sbar-tags-filter-icon svg path {
    fill: rgba(white,.5);
  }

  .sbar-tags-filter.mod-expanded .sbar-tags-filter-icon svg path {
    fill: rgba(white,.9);
  }


  .sbar-tags-filter-clear {
    outline-color: rgba(white,.2);
  }

  .sbar-tags-filter-clear svg path {
    fill: rgba(white,.5);
  }

  .sbar-tags-filter-input:focus + .sbar-tags-filter-icon-icon svg path {
    fill: rgba(white,.9);
  }








}
</style>
