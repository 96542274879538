<script>
export default {

  data: function(){
    return {
      chromeActiveTabURL: null,
      inLibrary: false,
      statusLoading: true,
    };
  },


  computed: {

    enabled: function(){
      if (this.$store.state.user.is_anonymous) return false;
      if (this.$store.state.chrome) return true;
      return false;
    },

    visible: function(){
      var viewerNotOpened = (!this.$route.meta.viewer) && (!this.$store.state.viewer);
      var sidebarNotOpened = !this.$store.state.sidebarVisible;
      var routeKind = this.$route.meta.kind;
      var specificPage = (routeKind==='all') || (routeKind==='tagpage');
      return viewerNotOpened && sidebarNotOpened && specificPage;
    },

    tagName: function(){
      if (this.$route.meta.kind !== 'tagpage') { return false; }
      if (this.$store.state.tagPageMeta && this.$store.state.tagPageMeta.name) {
        return this.$store.state.tagPageMeta.name;
      }
      return this.$route.params.tagslug;
    },

  },

  watch: {
    'chromeActiveTabURL': function(){
      this.getURLStatus();
    },
    '$store.state.tagPageMeta': function(){
      this.$forceUpdate();
    },
  },

  created: function(){
    this.getChromeActiveTabURL();
  },


  methods: {

    getChromeActiveTabURL: function(){
      if (!this.$store.state.chrome){ return false;}
      this.$extension.getActiveTabUrl(function(url){
        this.chromeActiveTabURL = url;
      }.bind(this));
    },

    getURLStatus: function(){
      this.statusLoading = true;
      this.$axios({
        url: this.$store.state.api + '/get-url-status/',
        method: 'get',
        params: {
          url: this.chromeActiveTabURL,
        },
      }).then(function(response){
        this.statusLoading = false;
        this.inLibrary = true;
      }.bind(this), function(error){
        this.statusLoading = false;
        if (error.response.status==404) {this.inLibrary = false;}
      }.bind(this));
    },

    saveCurrentPage: function(){
      this.$store.commit('viewer', {kind: 'chromebtn'});
    },

  },

};
</script>


<template>
<div v-if="enabled && visible" class="savebtn">
  <div class="savebtn-inner">
    <button type="button" class="savebtn-btn" @click.prevent="saveCurrentPage">

      <span class="savebtn-top">

        <span class="icon icon-lightning-bolt-filled"></span>

        <span v-if="statusLoading">
          <span class="savebtn-top-label">Save/Edit</span>
          <span class="savebtn-top-url">{{chromeActiveTabURL}}</span>
        </span>

        <span v-else>
          <span class="savebtn-top-label">{{inLibrary ? 'Edit ' : 'Save '}}</span>
          <span class="savebtn-top-url">{{chromeActiveTabURL}}</span>
        </span>

      </span>

      <span v-if="tagName" class="savebtn-bottom" >

        <span  :title="tagName">
          In "<span class="savebtn-tagname">{{tagName}}</span>"
        </span>

      </span>


    </button>
  </div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .savebtn {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    z-index: 100;
  }

    .savebtn-inner {
    }

    .savebtn-btn {
      //box-shadow: 0 2px 5px 0 rgba(black,.2), 0 2px 5px 0 rgba(black,.2);
      box-shadow: 0 0 0 2px rgba(darken($clr-ggather, 25),.1);
      box-shadow: 0 2px 5px 0 rgba(0, 26, 70, 0.44);
      background-color: $clr-ggather;
      border: 1px solid darken($clr-ggather, 4);
      border-radius: 2px;
      padding: 16px 20px 13px 19px;
      outline: none;
      text-align: left;
      width: 100%;

      //width: 180px;
    }


      .savebtn-top {
        display: block;
        color: #111;
        color: rgba(white,.95);
        font-weight: 300;
        line-height: 1;
        font-size: 15px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        & > .icon {
          display: inline-block;
          margin-right: 5px;
        }
        & > .icon::before {
          line-height: .9;
        }
      }

      .savebtn-top-label {
        font-weight: 500;
        margin-right: 2px;
      }

      .savebtn-top-url {
        font-weight: 300;
        opacity: .8;
      }

      .savebtn-bottom {
        display: block;
        font-size: 13px;
        color: rgba(white,.9);
        font-weight: 600;
        padding-left: 24px;
        margin-top: 5px;

      }

        .savebtn-tagname {
          font-weight: 400;
          color: rgba(white,.9);
          display: inline-block;
          white-space: nowrap;
          overflow-x: hidden;
          //max-width: 100px;
          text-overflow: ellipsis;
        }


</style>
