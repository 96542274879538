import tippy, {sticky} from 'tippy.js';

var VueTippy = {

  install: function(Vue, options){






    // set global reference
    //window._vueTippyInstances = window._vueTippyInstances ? window._vueTippyInstances : [];




    var extractConfig = function(el, binding, vnode, oldVnode){
      // extract config
      var bv = binding.value ? binding.value : {};
      var defaults = {
        content: (typeof bv === 'string') ? bv : '...',
        placement: 'top',
      }
      var config = {
        content: bv.content ? bv.content : defaults.content,
        placement: bv.placement ? bv.placement : defaults.placement,
      }
      return config;

    };









    var init = function(el, binding, vnode, oldVnode){

      // set tippy id
      //var tippyId = (Object.keys(window._vueTippyInstances).length + 1);
      //console.warn(tippyId);

      // config
      var config = extractConfig(el, binding, vnode, oldVnode);

      // set content
      //el.setAttribute('title', config.content); // old config from tippy@2

      var tippyOptions = {

        //trigger: 'manual',
        trigger: 'mouseenter focus',
        placement: config.placement,
        animation: false,
        duration: [0, 0],
        //distance: 20, // old config from tippy@2
        delay: [150, 0],
        //arrowSize: 'small', // old config from tippy@2
        inertia: false,
        //flip: true, // old config from tippy@2
        //dynamicTitle: true, // old config from tippy@2
        //followCursor: true,
        hideOnClick: true,
        //performance: true, // old config from tippy@2
        arrow: true,
        interactive: false,
        interactiveBorder: 10,
        //theme: 'dark',


        allowHTML: false, // XSS on bookmark notes!
        content: config.content,

        //sticky: true,
        //plugins: [sticky],

        popperOptions: {
          strategy: 'fixed',
        },
      };

      el._vueTippyInstance = tippy(el, tippyOptions);
      //window._vueTippyInstances.push(el._vueTippyInstance);


    };
















    var update = function(el, binding, vnode, oldVnode){

      // config
      var config = extractConfig(el, binding, vnode, oldVnode);

      if (el._vueTippyInstance) el._vueTippyInstance.setContent(config.content);

      /*
      // set content
      el.setAttribute('title', config.content);
      if (el._vueTippyInstance && el._vueTippyInstance.getPopperElement) {
        var popper = el._vueTippyInstance.getPopperElement(el);
        el._vueTippyInstance.update(popper);
      }
      */

    };






    var destroy = function(el, binding, vnode, oldVnode){
      /*
      //console.warn( window._vueTippyInstances.indexOf(el._vueTippyInstance) );
      if (el._vueTippyInstance && el._vueTippyInstance.destroyAll) {
        el._vueTippyInstance.destroyAll();
      }
      delete el._vueTippyInstance;
      //delete window._vueTippyInstances[el._vueTippyInstance];
      */

      if (el._vueTippyInstance) el._vueTippyInstance.destroy();
      delete el._vueTippyInstance;
    };

















    Vue.directive('tooltip', {
      //bind: function(el, binding, vnode, oldVnode){
      //},
      inserted: function(el, binding, vnode, oldVnode){
        destroy(el, binding, vnode, oldVnode);
        Vue.nextTick(function(){
          init(el, binding, vnode, oldVnode);
        });
      },
      //update: function(el, binding, vnode, oldVnode){
      //},
      componentUpdated: function(el, binding, vnode, oldVnode){
        Vue.nextTick(function(){
          update(el, binding, vnode, oldVnode);
        });
      },
      unbind: function(el, binding, vnode, oldVnode){
        destroy(el, binding, vnode, oldVnode);
      },
    });







  },
};

export default VueTippy;
