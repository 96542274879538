<script>
export default {

  name: 'page-followed',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },

  data: function(){
    return {
      followedUsers: null,
    }
  },

  mounted: function(){
    this.getFlTagsUsers();
  },

  beforeDestroy: function(){
  },

  methods: {

    getFlTagsUsers: function(){
      this.$axios({
        url: this.$store.state.api + '/get-fl-tags-users/',
        method: 'get',
      }).then(function(response){
        this.followedUsers = response.data.users;
      }.bind(this), function(error){
      }.bind(this));
    },

  },


};
</script>


<template>
<div class="router-page" v-if="$store.getters.userIsAuthenticated">

  <socialtabs context="followed" />

  <div class="followed">

    <div class="followed-empty" v-if="followedUsers===null">
      Loading users &amp; tags...
    </div>

    <div class="followed-empty" v-if="followedUsers && (!followedUsers.length)">
      You don't subscribe to any tags yet...
    </div>


    <div v-for="user in followedUsers" class="followed-user" :key="user.pk">



      <div class="followed-section">

        <h3 class="followed-section-title">
          <router-link :to="'/'+user.username">
            <span class="followed-big-icon mod-profilepic" :style="{backgroundImage: 'url('+ user.profile_image+')'}"></span>
            <strong>{{user.username}}</strong>
            <span v-if="user.first_name || user.last_name">({{user.first_name}} {{user.last_name}})</span>
          </router-link>
        </h3>

        <div class="followed-slider-wrapper  mod-tagcards" :class="{'mod-loading': false}">

          <!--
          <spinner v-if="!popularTags" kind="wave" dark="true" :size="1"></spinner>  -->

          <div class="followed-slider   common-light-scrollbar">
            <div class="followed-slider-inner">
              <div v-for="tag in user.followed_tags" class="followed-slider-item">
                <tagcard :tag="tag"></tagcard>
              </div>
            </div>
          </div>


        </div>
      </div>


          <!--
            <router-link :to="'/'+user.username">
              <h2 class="followed-user-title">
                <img :src="user.profile_image">
                <strong>{{user.username}}</strong>
                <span v-if="user.first_name || user.last_name">({{user.first_name}} {{user.last_name}})</span>
              </h2>
            </router-link>

            <ul class="followed-tag-list">
              <li v-for="tag in user.followed_tags" :key="tag.pk" :class="['mod-color-'+tag.color]">
                <router-link :to="'/'+tag.owner.username+'/'+tag.slug" >{{tag.name}}</router-link>

              </li>
            </ul>-->


    </div>


  </div>



</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .followed {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .followed-user {
    padding: 20px 0;
  }

  .followed-tag-list {
    font-size: 12px;
    list-style-type: none;
    padding-top: 5px;

    li {
      list-style-type: none;

      a {
        display: block;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
        color: rgba(white,.9);
        margin-top: 5px;
        padding: 6px 12px;
        border-radius: 8px;
      }
      &.mod-color-1 a {background: rgba(lighten($clr-tag-1,20), 1);}
      &.mod-color-2 a {background: rgba($clr-tag-2, 1);}
      &.mod-color-3 a {background: rgba($clr-tag-3, 1);}
      &.mod-color-4 a {background: rgba($clr-tag-4, 1);}
      &.mod-color-5 a {background: rgba($clr-tag-5, 1);}
      &.mod-color-6 a {background: rgba($clr-tag-6, 1);}
      &.mod-color-7 a {background: rgba($clr-tag-7, 1);}
      &.mod-color-8 a {background: rgba($clr-tag-8, 1);}
      &.mod-color-9 a {background: rgba($clr-tag-9, 1);}

    }
  }


  .followed-user-title {
    position: relative;
    padding-left: 40px;
    padding-top: 3px;

    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    color: rgba(black,.9);

    img {
      width: 30px;
      height: 30px;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
    }

    strong {
      font-weight: 600;
      color: rgba(black,.9);
    }

    span {
      color: rgba(black,.6);
      font-weight: 400;
    }

  }




  .followed-empty {

    color: #888;
    font-size: 14px;
    padding: 15px 20px;
    margin-top: 40px;
    border-radius: 3px;

    color: darken(#bababa,4);
    border: 1px dashed darken(white,12);
    background-color: darken(white,0);

  }







































  .followed-section {
    display: block;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .followed-section-title {
    font-weight: 600;
    font-size: 17px;
    line-height: .8;
    opacity: .7;
    margin-left: 0px;
    margin-right: 0px;
    //border-bottom: 1px solid darken(#eee,5);

    & > .icon {
      margin-right: 2px;
    }
    & > .icon::before {
      line-height: 1;
    }

    & > .icon-help {
      display: inline-block;
      margin-left: 3px;
      font-size: 12px;
      opacity: .6;
    }
    & > .icon-help::before {
      line-height: .7;
    }

    span {
      opacity: .3;
    }


    @media (max-width: 500px){
      font-size: 12px;
      line-height: 1.3;
    }
  }

  .followed-slider-wrapper {
     // background-color: white;
    //border: 1px solid #eee;
    border-radius: 4px;
    padding: 5px;
    margin-right: 0px;
    white-space: nowrap;
    position: relative;
    background-color: #ddd;


    &.mod-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ddd;

      /*
      &::before {
        border-radius: 3px;
        position: absolute;
        content: '';
        top: 5px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(red,.7);
      }
      */
    }

    &.mod-loading.mod-usercards {
      min-height: 207px;
    }

    &.mod-loading.mod-tagcards {
      min-height: 162px;
    }


  }

  .followed-slider {
    width: auto;
    height: 100%;
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: auto;
  }

  .followed-slider-item {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    width: auto;

    opacity: .9;
    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-in-out;
    }
    &:hover {
      opacity: 1;
    }
  }






  .followed-big-icon.followed-big-icon.followed-big-icon {
    background-color: #ccc;
    width: 25px;
    display: inline-block;
    height: 25px;
    border-radius: 20px;
    color: #111;
    text-align: center;
    line-height: 20px;
    vertical-align: top;
    margin-right: 4px;
    position: relative;
    top: -6px;
    background-size: cover;
    background-position: 50% 50%;
    box-shadow: 0 0 0 1px darken(white,8);
    &::before {
      line-height: 26px;
    }


    &.mod-profilepic {
      opacity: 1;
    }

  }











</style>


<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .followed-empty {
    background-color: darken(white,80);
    border-color: darken(white,70);
  }


  .followed-section-title {
    color: rgba(white,.9);
  }

  .followed-slider-wrapper {
    background: darken(white,60);
  }

}
</style>
