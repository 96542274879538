<script>
export default {

  props: [

    'item',
    'context',
    'itemLayout',

    'isHighlighted',
    'selected',
    'unseen',
    'tagsPopoverOpen',
    'noTags',
    'followedTagsFirst',

    'title',
    'description',
    'titleThumbSlice',
    'thumbnail',
    'thumbnailHeight',
    'thumbnailWidth',

    'selectOverlayClick',
    'linkClick',
    'linkStandardClick',

    'itemThumbLoad',

  ],

}
</script>



<template>
<div
  class="item item-list"
  :class="{'mod-compact': itemLayout=='list-compact', 'mod-selected': selected}"
>

  <button v-if="$store.state.itemsSelectMode" type="button" class="item-select-overlay" @click="selectOverlayClick"></button>

  <div v-if="unseen" class="item-unseen-notification  mod-list" v-tooltip="'New since your last visit. <br>Added ' + $dfFormatDistanceFromNow(item.date_created) + '.'"></div>


  <a :href="item.url" type="button" class="item-list-link"  @click="linkStandardClick" @mouseup="linkClick" rel="noopener noreferrer nofollow" target="_blank">


    <div class="item-list-favicon" :style="{backgroundImage: 'url(\'https://s2.googleusercontent.com/s2/favicons?domain_url=' + item.url +'\')'}"> </div>


    <div class="item-list-title" :title="title"> {{title}} </div>



    <div class="item-list-rating" v-if="item.rating" >
      <itemrate
        :item-layout="itemLayout"
        :item-rating="item.rating"
      />
    </div>


    <div class="item-list-note" v-if="item.owner_notes">
      <itemnote
        :item-layout="itemLayout"
        :item-owner-username="item.owner.username"
        :item-owner-profile-image="item.owner.profile_image"
        :item-owner-notes="item.owner_notes"
      />
    </div>






    <div class="item-list-description" :title="description">{{description}}</div>



  </a>



  <itemnav
    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :selected="selected"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"
  />


</div>
</template>































<style lang="scss">

  @import "../../../common/style/_variables.scss";


  $clr-light-green-600: green;
  $clr-red: red;
  $clr-grey-200: grey;
  $clr-light-blue-400: blue;
  $clr-orange: orange;



  /*------------------------------------*\
    Layout - List
  \*------------------------------------*/

  .item-list {
    font-size: 0;
    display: block;
    text-align: left;
    background-color: white;
    position: relative;

    .item-outer:first-of-type & {
      border-top:    1px solid darken(white,8);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    border-right:  1px solid darken(white,8);
    border-left:   1px solid darken(white,8);
    border-bottom: 1px solid darken(white,5);

    .item-outer:last-of-type & {
      border-bottom:    1px solid darken(white,8);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

  }






    .item-list-link {
      display: flex;
      vertical-align: top;
      padding: 5px 10px 5px 10px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      background-color: white;

      .item-outer:first-of-type & {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      .item-outer:last-of-type & {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }


      @media (max-width: 700px) {
        display: block;
        white-space: normal;
        padding-top: 7px;
        padding-bottom: 7px;
      }

    }

    .item-list-favicon {
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      margin-right: 10px;
      opacity: .8;
      vertical-align: middle;
      .item-list:hover & {
        opacity: 1;
      }

      display: none;
      // OUT OF SCOPE SELECTOR
      .items.mod-show-favicon & {
        display: inline-block;
      }
    }

    .item-list-title {
      display: inline-block;
      vertical-align: middle;
      margin-top: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      color: #333;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 8px;
      width: 30%;
      flex: 0 0 30%;

      @media (max-width: 1100px) {
        width: 40%;
        flex: 0 0 40%;
      }

      @media (max-width: 700px) {
        width: 80%;
        width: calc(100% - 40px);
      }

      .item-list:hover & {
        color: black;
      }
    }



    .item-list-description {
      display: inline-block;
      vertical-align: top;
      margin-top: 4px;
      line-height: 1;
      font-size: 13px;
      opacity: .4;
      //width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: 700px) {
        width: 100%;
        margin-top: 5px;
      }
    }



    .item-list-rating {
      position: relative;
      opacity: 1;
      display: inline-block;
      margin-right: 5px;
      width: 70px;
      flex: 0 0 70px;
      @media (max-width: 700px) {
        margin-top: 5px;
      }
    }



    .item-list-note {
      position: relative;
      display: inline-block;
      margin-right: 10px;
      max-width: 20%;
      @media (max-width: 700px) {
        margin-top: 5px;
        max-width: 80%;
        max-width: calc(100% - 90px);
      }
    }




</style>











<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {









  .item.item-list.item-list {
    background-color: lighten($clr-theme-dark,11);
    border-top-color: lighten($clr-theme-dark,13);
    border-right-color: lighten($clr-theme-dark,15);
    border-left-color: lighten($clr-theme-dark,15);
    border-bottom-color:  lighten($clr-theme-dark,17);
  }


  .item-list-link {
    background-color: lighten($clr-theme-dark,11);
  }



  .item-list-title {
    color: rgba(white,.5);
  }

  .item-list-description {
    color: rgba(white,.6);
  }



}
</style>
