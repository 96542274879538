<script>
export default {

  props: {

    disabled: {
      type: Boolean,
      default: false,
    },

    tag: {
      type: String,
      default: 'button',
    },

    type: {
      type: String,
      default: 'button',
    },

    variant: {
      type: String,
      default: 'text',
    },

  },

  computed: {


  },

};
</script>

<template>
  <component
    :is="tag"
    :type="type"
    :disabled="disabled"
    :class="[
      'btn',
      'mod-variant-'+variant,
      {
        'mod-disabled': disabled,
      }
    ]"
  ><slot></slot>
  </component>
</template>

<style lang="scss" scoped>

  $clr-ggather: #3079f4;

  $clr-btn: $clr-ggather;
  $clr-btn-very-light: #d1e2ff;

  .btn {
    min-width: 0;
    min-height: 0;
    display: inline-block;
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    outline: none;
    font-size: inherit;
    line-height: 1.2;
    font-weight: 600;
    color: rgba($clr-btn,1);
    padding: 6px 12px;
    margin: 0;
    margin-top: 6px;
    margin-right: 3px;
    border-radius: 5px;
    text-transform: uppercase;

    transition: all 100ms ease-in-out;
  }

  .btn:hover {
    background: $clr-btn-very-light;
    //box-shadow: 0 1px 15px 0 rgba(darken($clr-btn,15),.15);
  }

  .btn:active {
    background: darken($clr-btn-very-light,5);
    //box-shadow: 0 1px 10px 0 rgba(darken($clr-btn,15),.11);
  }

  .btn:focus {
    //box-shadow: 0 0 0 1px $clr-btn-very-light;
  }

  .btn:hover:focus {
    //box-shadow: 0 1px 15px 0 rgba(darken($clr-btn,15),.15), 0 0 0 1px $clr-btn-very-light;
  }

  .btn:active:focus {
    //box-shadow: 0 1px 10px 0 rgba(darken($clr-btn,15),.11), 0 0 0 1px $clr-btn-very-light;
  }




  // disabled
  .btn.mod-disabled {
    background: darken(white,5);
    border-color: darken(white,5);
    color: darken(white,30);
  }



  // outlined
  .btn.mod-variant-outlined {
    border: 1px solid $clr-btn-very-light;
  }

  .btn.mod-variant-outlined:hover {
    background: $clr-btn-very-light;
    border-color: $clr-btn-very-light;
  }

  .btn.mod-variant-outlined:active {
    background: darken($clr-btn-very-light,5);
    border-color: darken($clr-btn-very-light,5);
  }

  .btn.mod-variant-outlined.mod-disabled {
    background: darken(white,5);
    border-color: darken(white,15);
    color: darken(white,30);
  }







  // raised
  .btn.mod-variant-raised {
    border: 1px solid $clr-btn;
    background: $clr-btn;
    color: rgba(white,1);
    font-weight: 500;
  }

  .btn.mod-variant-raised:hover {
    background: darken($clr-btn,6);
  }

  .btn.mod-variant-raised:active {
    background: darken($clr-btn,12);
  }


  .btn.mod-variant-raised.mod-disabled {
    background: darken(white,40);
    border-color: darken(white,40);
    color: darken(white,10);
  }





</style>
