<script>
export default {

  name: 'pricingitem',

  props: {

    'last': {
      type: Boolean,
    },

    'first': {
      type: Boolean,
    },


    'free': {
      type: Boolean
    },

    'paid': {
      type: Boolean,
    },

    'label': {
      type: String,
    },

    'tooltip': {
      type: String,
    },

    'tooltipImageIcon': {
      type: Boolean,
    },


  },

  data: function(){
    return {
      tooltipOpen: false,
    };
  },

};
</script>


<template>
<li class="pricingitem" :class="{'mod-free-enabled': free, 'mod-paid-enabled': paid, 'mod-last': last, 'mod-first': first}">

  <span class="pricingitem-label-wrapper">
    <span class="pricingitem-label">{{label}}</span>
    <span class="pricingitem-tooltip" v-if="tooltip" ref="tooltipElement" @click.prevent="tooltipOpen=true">

      <svg v-if="!tooltipImageIcon" key="tooltipHelpIcon" class="pricingitem-icon-help"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" version="1.1" width="32px" height="32px">
        <path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 16 10 C 13.800781 10 12 11.800781 12 14 L 14 14 C 14 12.882813 14.882813 12 16 12 C 17.117188 12 18 12.882813 18 14 C 18 14.765625 17.507813 15.445313 16.78125 15.6875 L 16.375 15.8125 C 15.558594 16.082031 15 16.863281 15 17.71875 L 15 19 L 17 19 L 17 17.71875 L 17.40625 17.59375 C 18.945313 17.082031 20 15.621094 20 14 C 20 11.800781 18.199219 10 16 10 Z M 15 20 L 15 22 L 17 22 L 17 20 Z "/>
      </svg>


      <svg v-else key="tooltipImageIcon" class="pricingitem-icon-image"  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" version="1.1" width="24px" height="24px">
        <path d="M 0 2 L 0 22 L 24 22 L 24 2 Z M 2 4 L 22 4 L 22 20 L 2 20 Z M 17 7 C 15.894531 7 15 7.894531 15 9 C 15 10.105469 15.894531 11 17 11 C 18.105469 11 19 10.105469 19 9 C 19 7.894531 18.105469 7 17 7 Z M 7.875 9.78125 L 4 12.53125 L 4 18 L 20 18 L 20 14.84375 L 15.9375 12.375 L 12.53125 13.90625 Z "/>
      </svg>



    </span>
  </span>

  <span class="pricingitem-check mod-free" >
    <svg v-if="free" key="checkfree" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
      <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z ">
      </path>
    </svg>
  </span>

  <span  class="pricingitem-check mod-paid" >
    <svg v-if="paid" key="checkpaid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
      <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z ">
      </path>
    </svg>
  </span>



  <Popover
    v-if="tooltipOpen"
    @close="tooltipOpen=false"
    :popover-style="{boxShadow: '0 1px 7px 0 rgba(0,0,0,.25)', borderRadius:'4px', marginBottom:'0px'}"
    :anchor-el="$refs.tooltipElement"
    :transform-origin="{vertical: 'bottom', horizontal: 'center'}"
    :anchor-origin="{vertical: 'top', horizontal: 'center'}"
  >
    <span class="pricingitem-tooltip-content" v-html="tooltip"></span>
  </Popover>






</li>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .pricingitem {
    display: flex;


    &:hover {
      background-color: darken(white,7);
    }

    &.mod-first {
      border-radius: 0;
    }

    &.mod-last {
      border-radius: 0 0 4px 4px;
    }

  }




  .pricingitem-label-wrapper {
    display: block;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 4px 0;
    border-top: 1px solid darken(white, 12);
    width: calc(100% - 240px);

    @media (max-width: 670px) {
      width: calc(100% - 140px);
    }
  }


  .pricingitem-label {


    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: darken(white,100);
    padding-left: 5px;
    @media (max-width: 670px) {
      font-size: 11px;
    }
  }



  .pricingitem-tooltip {
    cursor: pointer;
  }


  .pricingitem-icon-help,
  .pricingitem-icon-image {
    width: 19px;
    height: 19px;
    margin-left: 3px;
    path {
      fill: darken(white,30);
      transition: fill 100ms ease-in-out;
    }
    .pricingitem-tooltip:hover & path {
      fill: darken(white,60);
    }
  }

  .pricingitem-icon-image {
    width: 17px;
    height: 17px;
    margin-left: 6px;
  }

  .pricingitem-tooltip-content {
    background: white;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: darken(white,100);
    display: block;
    padding: 7px 10px;
    max-width: 300px;

    /deep/ img {
      display: block;
      max-width: 100%;
      height: auto;
      width: auto;
      max-height: 300px;
      border-radius: 4px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  .pricingitem-check {
    display: block;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 4px 0;
    border-top: 1px solid darken(white, 12);
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: rgba(white,0);
      }
    }

    .pricingitem.mod-last &.mod-paid {
      border-radius: 0 0 4px 4px;
    }



    &.mod-free {
      width: 120px;
      justify-content: center;
      text-align: center;
    }


    &.mod-paid {
      width: 120px;
      justify-content: center;
      text-align: center;
      background: rgba($clr-ggather, .05);
      border-color: #d2dcec;
    }


    .pricingitem.mod-free-enabled &.mod-free {
      svg path {
        fill: darken(white,70);
      }
    }

    .pricingitem.mod-paid-enabled &.mod-paid {
      svg path {
        fill: rgba($clr-ggather,1);
      }
    }



    @media (max-width: 670px) {

      &.mod-free {
        width: 70px;
      }

      &.mod-paid {
        width: 70px;
      }

    }

  }

</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .pricingitem {
    &:hover {
      background-color: darken(white,75);
    }
  }

  .pricingitem-label {
    color: rgba(white,.8);
  }

  .pricingitem-check.mod-free {
    border-color: darken(white,75);
    & svg path {
      fill: rgba(white,.9);
    }
  }

  .pricingitem-check.mod-paid {
    background: rgba($clr-ggather,.2);
    border-color: #354f7b;
  }

  .pricingitem-label-wrapper {
    border-color: darken(white,75);
  }


}
</style>
