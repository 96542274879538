<script>
//import { Draggable } from '@shopify/draggable';
import Draggable from '@shopify/draggable/lib/draggable';
import Droppable from '@shopify/draggable/lib/droppable';
import Sortable from '@shopify/draggable/lib/sortable';
import Snappable from '@shopify/draggable/lib/plugins/snappable';
import Collidable from '@shopify/draggable/lib/plugins/collidable';
import {closest} from '@shopify/draggable/lib/utils';
import _find from 'lodash/find';

export default {


  data: function(){
    return {

      draggedTagPk: null,
      overTagPk: null,
      overNestPk: null,

      // when dragged tag is over bookmark...
      overItemPk: null,

    }
  },

  computed: {

    draggableEnabled: function(){
      if (this.$store.state.tagsFilter) return false;
      if (this.$store.state.sidebarSpinner) return false;
      if (this.$store.state.tags && this.$store.state.tags.length) return true;
      return true;
    },


    // no tags at al...
    tagsLoading: function(){
      if (this.$store.state.tags===null) return true;
      return false;
    },

    // no tags at all...
    tagsEmpty: function(){
      if (this.$store.state.tags===null) return false;
      if (this.$store.state.tags && (!this.$store.state.tags.length)) return true;
      return false;
    },


    tagsFilteredCount: function(){

      if (this.$store.state.tags===null) return null;
      if (this.$store.state.tags && (!this.$store.state.tags.length)) return null;
      if (!this.$store.getters.tagsTree) return null;
      if (!this.$store.state.tagsFilter) return null;


      var count = 0;

      for (var tag of this.$store.getters.tagsTree) {

        if (tag.__visible) {
          count++;
        }

        if (tag.children) {
          for (var childrenTag of tag.children) {
            if (childrenTag.__visible) {
              count++;
            }
          }
        }

      }

      return count;

    },



  },

  watch: {

    draggableEnabled: {
      immediate: false,
      handler: function(newVal){
        if (newVal === true) this.initializeOrRefreshDraggableInstance();
        else this.destroyDraggableInstance();
      },
    },

    '$store.state.tags': {
      immediate: false,
      handler: function(newVal, oldVal){
        // when tags load for first time...
        if ((!oldVal) && newVal) this.initializeOrRefreshDraggableInstance();
      },
    },

  },


  mounted: function(){
    if (this.$store.state.tags && (!this.$_draggableInstance)) this.initializeOrRefreshDraggableInstance();
  },

  updated: function(){
  },


  beforeDestroy: function(){
    this.destroyDraggableInstance();
  },

  methods: {


    addClass: function(el, className){
      if (el.classList)
        el.classList.add(className);
      else
        el.className += ' ' + className;
    },

    removeClass: function(el, className){
      if (el.classList)
        el.classList.remove(className);
      else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },




    addTagToLink: function(itemPK, tagPK){
      var itemURL = _find(this.$store.state.items, {pk: parseInt(itemPK)}).url;
      var tag = _find(this.$store.state.tags, {pk: parseInt(tagPK)});
      this.$axios({
        url: this.$store.state.api + '/edit-urlbookmark-tag/',
        method: 'post',
        data: {
          tag_pk: tagPK,
          url: itemURL,
          action: 'add',
        },
      }).then(function(response){
        this.$alertify.log('Link was added to ' + tag.name + '.')
      }.bind(this));

      // remove from untagged ...
      if ((this.$route.meta.kind=='all') && this.$route.query.untagged) {
        this.$store.commit('deleteItem', {pk: parseInt(itemPK)});
      }

    },





    // we have to use addClass/removeClass outside of Vue because of draggable.js and to not
    // interrupt dragging state by rerendering vue component
    // disable dropping, e.g. when user tries to drop tag with children into another parent tag
    checkIfDraggedTagHasChildrenAndApplyClassAccordingly: function(){

      if (!this.$refs.sbartags) return;

      if (!this.draggedTagPk) {
        this.removeClass(this.$refs.sbartags, 'mod-dragged-tag-has-children');
        return;
      }

      var hasChildren = !!( _find(this.$store.state.tags, {parent: this.draggedTagPk}) );

      if (hasChildren) {
        this.addClass(this.$refs.sbartags, 'mod-dragged-tag-has-children');
      }
      else {
        this.removeClass(this.$refs.sbartags, 'mod-dragged-tag-has-children');
      }

    },



    destroyDraggableInstance: function(){
      if (this.$_draggableInstance && this.$_draggableInstance.destroy) {
        this.$_draggableInstance.destroy();
        this.$_draggableInstance = void 0;
      }
    },


    initializeOrRefreshDraggableInstance: function(){
      this.destroyDraggableInstance();
      this.$nextTick(function(){
        if (!this.draggableEnabled) return;
        if (!this.$refs.list) return;

        var containers = [this.$refs.list];

        var options = {
          //plugins: [Collidable],
          //collidables: '.app-content, .sbar-section.mod-nav, .sbar-section.mod-favorite, .topbar, .titlebar',
          draggable: '[draggable-item]',
          delay: this.$store.state.deviceMobile ? 600 : 300,
          mirror: {
            constrainDimensions: true,
            //cursorOffsetX: -10,
            //cursorOffsetY: -10,
            //xAxis: false,
            appendTo: window.document.body,
          },
          scrollable: {
            speed: 12,
            sensitivity: 12,
          },
        };

        this.$_draggableInstance = new Draggable(containers, options);
        this.setupDraggableEventListeners();


      });

    },

    setupDraggableEventListeners: function(){
      var di = this.$_draggableInstance;
      di.on('drag:start', this.draggableDragStart.bind(this));
      di.on('drag:move', this.draggableDragMove.bind(this));
      di.on('drag:over', this.draggableDragOver.bind(this));
      di.on('drag:stop', this.draggableDragStop.bind(this));
      di.on('drag:out:container', this.draggableDragOutContainer.bind(this));
      di.on('drag:over:container', this.draggableDragOverContainer.bind(this));
    },

    draggableDragStart: function(event){
      //console.warn('list', 'drag:start', event);
      //this.clearScheduleSyncTags();

      this.draggedTagPk = parseInt( event.originalSource.getAttribute('tagpk') );

      this.checkIfDraggedTagHasChildrenAndApplyClassAccordingly();
    },


    draggableDragMove: function(event){

      var sensorTarget = event.data.sensorEvent.data.target;
      var targetNest = closest(sensorTarget, '[nestpk]');

      if (targetNest) {
        this.overNestPk = parseInt(targetNest.getAttribute('nestpk'));
      } else {
        this.overNestPk = null;
      }


      // somewhat hackish solution... cleanup the class on all items that have 'mod-dragged-tag-over' class
      var allItemOutersWithDraggedOverClass = document.querySelectorAll('.item-outer.mod-dragged-tag-over');
      for (var itemOuterWithClass of allItemOutersWithDraggedOverClass) {
        this.removeClass(itemOuterWithClass, 'mod-dragged-tag-over');
      }

      // detect being over bookmark...
      var targetItemOuter = closest(sensorTarget, '.item-outer');
      if (targetItemOuter) {
        var itemPk = parseInt( targetItemOuter.getAttribute('item-pk') );
        this.overItemPk = itemPk;
        this.addClass(targetItemOuter, 'mod-dragged-tag-over');
      } else {
        this.overItemPk = null;

      }

    },

    draggableDragOver: function(event){
      this.overTagPk = parseInt( event.over.getAttribute('tagpk') );
    },

    draggableDragOverContainer: function(event){
    },

    draggableDragOutContainer: function(event){
      this.overTagPk = null;
    },

    draggableDragStop: function(event){

      // drag stopped over bookmark...
      if (this.overItemPk) {
        this.addTagToLink(this.overItemPk, this.draggedTagPk);
        this.overItemPk = null;
        // somewhat hackish solution... cleanup the class on all items that have 'mod-dragged-tag-over' class
        var allItemOutersWithDraggedOverClass = document.querySelectorAll('.item-outer.mod-dragged-tag-over');
        for (var itemOuterWithClass of allItemOutersWithDraggedOverClass) {
          this.removeClass(itemOuterWithClass, 'mod-dragged-tag-over');
        }
        return;
      }



      if (!this.overTagPk) {
        // spilled...
        return;
      }

      // nest tag...
      if (this.overNestPk) {
        this.setTagParent();
      }

      // sort tag...
      else if (this.overTagPk) {
        this.moveTag();
      }


      this.draggedTagPk = null;
      this.overTagPk = null;
      this.overNestPk = null;

      this.checkIfDraggedTagHasChildrenAndApplyClassAccordingly();

    },


    listMouseDown(){
      //this.postponeScheduleSyncTags();
    },


    setTagParent: function(){
      //this.$store.commit('moveTag', {pk: this.draggedTagPk, belowNest: this.overNestPk});
      this.$store.commit('nestTag', {pk: this.draggedTagPk, parent: this.overNestPk});
      //this.scheduleSyncTags();
      this.$store.dispatch('apiSyncTags');
    },


    moveTag: function(){
      this.$store.commit('moveTag', {pk: this.draggedTagPk, above: this.overTagPk});
      //this.scheduleSyncTags();
      this.$store.dispatch('apiSyncTags');
    },



    //postponeScheduleSyncTags(){
    //  if (!this.$_syncTagsTimeout) return;
    //  clearTimeout(this.$_syncTagsTimeout);
    //  this.$_syncTagsTimeout = void 0;
    //  this.scheduleSyncTags();
    //},

    //clearScheduleSyncTags(){
    //  if (!this.$_syncTagsTimeout) return;
    //  clearTimeout(this.$_syncTagsTimeout);
    //  this.$_syncTagsTimeout = void 0;
    //},

    //scheduleSyncTags(){
    //  this.clearScheduleSyncTags();
    //  this.$_syncTagsTimeout = setTimeout(function () {
    //    this.$_syncTagsTimeout = void 0;
    //    this.$store.dispatch('apiSyncTags');
    //  }.bind(this), 1000);
    //},




















  },

};
</script>


<template>
  <div class="sbartags" ref="sbartags" key="sbartags">

    <!--
    <div class="sbartags-debug">
      <ul>
        <li v-for="(t, i) in $store.state.tags" :key="'debugtag'+t.pk">
          <span>D/{{t.display_order}} ... </span>
          <span>I/{{i}} ... </span>
          <span><strong>{{t.pk}}/{{t.slug}}</strong></span>
          <span>P/{{t.parent}}</span>
        </li>
      </ul>
    </div>
    -->


    <div v-if="tagsFilteredCount===0" key="sbartags-list-filtered-empty" class="sbartags-list-empty">
      <strong>No matching tags.</strong>
      <span>It seems like you don't have any tags that match the selected filter.</span>
    </div>

    <div v-else-if="tagsFilteredCount>0" key="sbartags-list-tags-are-filtered" class="sbartags-list-empty">

      <strong v-if="tagsFilteredCount===1">{{tagsFilteredCount}} tag matches your filter.</strong>
      <strong v-else>{{tagsFilteredCount}} tags match your filter.</strong>

      <span>While in filter mode you can't add new tags, collapse tags or use drag and drop interactions.</span>
    </div>




    <div v-if="tagsLoading" key="sbartags-list-loading" class="sbartags-list-loading">
      <spinner kind="dots" />
    </div>

    <div v-else-if="tagsEmpty" key="sbartags-list-empty" class="sbartags-list-empty">
      <strong>You don't have any tags yet.</strong>
      <span>Create your first tag using box below. Tags will be also imported when using <router-link to="/settings/import">bookmarks import</router-link>.</span>
    </div>

    <div v-else key="sbartags-list" class="sbartags-list" ref="list" @mousedown="listMouseDown">

      <sbar-tag
        v-for="tag in $store.getters.tagsTree"
        :key="'sbartag'+tag.pk"
        :is-parent="true"
        :tag="tag"
        :mirror-over="overTagPk==tag.pk"
        :mirror-over-nest="overNestPk==tag.pk"
      />

    </div>


  </div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .sbartags {
    padding-top: 5px;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
  }

  .sbartags-list-loading {
  }

  .sbartags-list-empty {
    background: transparent;
    display: block;
    border: 1px dashed rgba(black,.2);
    font-size: 13px;
    line-height: 1.3;
    font-weight: 400;
    color: rgba(black,.8);
    border-radius: 6px;
    padding: 8px 10px;
    margin-top: 8px;

    strong {
      display: block;
      font-weight: 500;
      color: rgba(black,.8);
    }

    span {
      display: block;
      margin-top: 3px;
      font-weight: 400;
      color: rgba(black,.6);
      font-size: 12px;

    }

    a {
      text-decoration: underline;
    }
  }




  .sbartags-debug {
    position: fixed;
    box-shadow: 0 2px 15px 0 rgba(black,.5);
    border-radius: 0 6px 6px 0;
    width: 600px;
    left: 280px;
    top: 55px;
    bottom: 0px;
    background: white;
    padding: 5px;
    z-index: 10000;
    overflow-x: hidden;
    overflow-y: auto;
    ul, li {
      list-style-type: none;
    }
    li {
      background: rgba(black,.1);
      color: rgba(black,.9);
      padding: 3px 6px;
      font-size: 12px;
      line-height: 1.2;
      border-radius: 4px;
      margin-bottom: 4px;
      font-weight: 300;
      span {
        display: inline-block;
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span:first-of-type {
        width: 80px;
      }
      span:nth-of-type(2) {
        width: 80px;
      }
      span:last-of-type {
        width: 80px;
      }
      strong {
        color: rgba(black,.99);
        font-weight: 500;
        //text-decoration: underline;
      }
    }
  }

  .sbartags-list {
    outline: none;
  }


</style>







<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .sbartags-list-empty {
    border: 1px dashed rgba(white,.2);
    color: rgba(white,.8);
    strong {
      color: rgba(white,.8);
    }
    span {
      color: rgba(white,.6);
    }
  }

}
</style>
