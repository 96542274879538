<script>
export default {

  props: [
    'value',
  ],

  data: function(){
    return {
      rating: JSON.parse(JSON.stringify(this.value)),
      ratingHover: null,
    }
  },


  watch: {
    rating: function(val){
      this.$emit('input', val);
    },
  },


  methods: {

    select: function(i){

      if (this.rating && (this.rating == i)) {
        this.rating = null;
      } else {
        this.rating = i;
      }
    },

  },


};
</script>

<template>
<div class="saverating">

  <div class="saverating-rate-wrapper">

    <div class="saverating-rate-label">
      Rating
      <span class="saverating-help" v-tooltip="' <br>Click second time to remove rating.<br><br>You can use this to rate your bookmarks. This will later allow you to search for bookmarks using special filter. <br><br>Quality can mean different things to different people so use this feature as you want. You can e.g. rate link usefulness or design. Or you can just mark your favorite websites that way. It\'s up to you how you will use this feature. <br><br>'">(?)</span>
    </div>

    <div class="saverating-rate-input">

      <div v-for="i in 5" class="saverating-star" :class="['mod-star-'+i, {'mod-selected': i<=rating, 'mod-hover': i<=ratingHover}]" @click="select(i)" @mouseenter="ratingHover=i" @mouseleave="ratingHover=null">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="26" height="26">
          <path d="M12,20c0,0-9-7.111-9-11.111C3,6.222,5.25,4,7.95,4C9.525,4,11.1,5.139,12,6.25C12.9,5.139,14.25,4,16.05,4 C18.75,4,21,6.222,21,8.889C21,12.889,12,20,12,20z"/>
        </svg>

        <div class="saverating-label">{{i}}</div>

      </div>

      <!--
      <button v-if="rating" type="button" @click="rating=null" class="saverating-rate-reset" v-tooltip="'Remove rating'">
        <span class="icon icon-delete"></span>
      </button> -->

    </div>


  </div>


</div>
</template>


<style lang="scss" scoped>


  @import "../../../common/style/_variables.scss";

  .saverating {
  }


  .saverating-rate-wrapper {
    //display: flex;
    //border: 1px solid lighten(#eee,1);
    //background-color: white;
    //border-bottom: 1px solid  darken(#eee,3);
    border-radius: 0px;
    padding: 15px 10px;

    border: 1px solid darken(#eee,1);
    background: white;
    //border-top: none;
  }


    .saverating-rate-label {
      //width: 70px;
      //min-width: 70px;
      font-weight: 600;
      font-size: 13px;
      line-height: 1.4;
      padding: 5px 0;
      padding-bottom: 2px;
      //background-color: darken(white,.5);
      //border-right: 1px solid #eee;
      padding-top: 0px;
      padding-left: 10px;
      color: rgba(black,.5);
      display: block;
    }



    .saverating-rate-input {
      opacity: .8;
      font-size: 14px;
      display: block;
      width: 100%;
      max-width: none;
      min-width: 0;
      min-height: 0;
      overflow: hidden;
      padding-left: 3px;
      padding-bottom: 5px;
      outline: none;
      resize: none;
    }

    .saverating-rate-reset {
      background-color: lighten(#eee,4);
      padding-left: 5px;
      padding-right: 5px;
      color: #777;
      font-size: 13px;
      border: 1px solid #eee;
      border-radius: 2px;
      margin-left: 5px;
      outline: none;
      display: inline-block;
      & .icon::before {
        line-height: 23px;
      }
    }

    .saverating-help {
      opacity: .2;
      position: relative;
      top: -3px;
      margin-left: 3px;
      html.mod-transitions-enabled & {
        transition: opacity 70ms ease-out, color 70ms ease-out;
      }

      &:hover {
        opacity: .7;
      }

      & > .icon::before {
        font-size: 12px;
      }
    }


    @keyframes saverating-star-selected {
      0% {
        transform: scale(1);
      }

      25% {
        transform: scale(1);
      }

      75% {
        transform: scale(1);
      }

      100% {
        transform: scale(1);
      }
    }

    .saverating-star {
      cursor: pointer;
      display: inline-block;
      opacity: 1;
      margin-left: 5px;
      position: relative;

      html.mod-transitions-enabled & {
        transition: transform 200ms ease-out;
      }

      transform: scale(1);



      svg {
        opacity: .25;
        html.mod-transitions-enabled & {
          transition: fill 200ms ease-out;
        }
        fill: transparent;
        fill: rgba($clr-red-a100,.2);
        fill: darken(#eee,0);
        stroke: $clr-red-a700;
        stroke: #333;
        stroke-width: 1px;
      }


      &:hover {
      }

      &.mod-hover {
        transform: scale(1);
        svg {
          fill: rgba($clr-red-a100,.7);
          stroke: $clr-red-a700;
        }
      }

      &:nth-of-type(1) svg { transition-delay: 00ms }
      &:nth-of-type(2) svg { transition-delay: 30ms }
      &:nth-of-type(3) svg { transition-delay: 60ms }
      &:nth-of-type(4) svg { transition-delay: 120ms }
      &:nth-of-type(5) svg { transition-delay: 150ms }



      &.mod-selected {
        animation-name: saverating-star-selected;
        animation-duration: 300ms;
        animation-timing-function: ease-in-out;
        transform: scale(1);

        svg {
          opacity: 1;
          fill: rgba($clr-red-a700,.9);
          stroke: darken($clr-red-a700,3);
        }
      }

      &:nth-of-type(1).mod-selected { animation-delay: 00ms }
      &:nth-of-type(2).mod-selected { animation-delay: 70ms }
      &:nth-of-type(3).mod-selected { animation-delay: 140ms }
      &:nth-of-type(4).mod-selected { animation-delay: 210ms }
      &:nth-of-type(5).mod-selected { animation-delay: 280ms }


    }


      .saverating-label {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 9px;
        font-weight: 500;
        color: rgba(black,.5);
        line-height: 1.2;
        transform: translate(-50%, -50%);

        .saverating-star.mod-selected & {
          color: rgba(white,.5);
          font-weight: 400;
        }



        display: none;
      }


</style>



<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .saverating-rate-wrapper {
    background-color: darken(white,68);
    border-color: darken(white,63);
  }

  .saverating-rate-label {
    color: rgba(white,.5);
  }


}
</style>
