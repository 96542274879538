<script>
import _clone from 'lodash/clone';
export default {

  methods: {
    sidebarBtnClick: function(){
      if (this.$store.state.user.is_anonymous) {
        this.$router.push('/');
      } else {
        this.$store.commit('sidebarVisible', !this.$store.state.sidebarVisible);
      }
    },
  },

};
</script>


<template>
<div class="sidebarbtn">
  <div class="sidebarbtn-inner">

    <div class="sidebarbtn-logo">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438 668"><path fill="#000000" class="cls-1" d="M376.29,0h-33a60.31,60.31,0,0,0-54.49,34.46l-35,73.69a14.65,14.65,0,0,0,13.23,20.92h71.62c19.15,0,29.42,22.53,16.85,37-69.92,80.39-190.26,216-251.8,287-5.68,6.56-16.18-.2-12.36-8C120.5,385.68,180.81,268,209.8,208.45a7.42,7.42,0,0,0-6.68-10.67H115c-17.16,0-26.44-20.1-15.32-33.16l99-116.32C214.94,29.28,201.42,0,176.42,0H61.71A61.31,61.31,0,0,0,.4,61.31V636.42c0,29.36,36.28,43.16,55.79,21.23L173.86,525.41a60.42,60.42,0,0,1,90.28,0L381.81,657.65c19.52,21.93,55.79,8.13,55.79-21.23V61.31A61.31,61.31,0,0,0,376.29,0Z"/></svg>
    </div>

    <button  type="button" class="sidebarbtn-hamburger" :class="{'mod-active':  $store.state.sidebarVisible, 'mod-disabled': (!$store.state.user.is_authenticated) || (!$store.getters.pageWithSidebar)}" @click.prevent="sidebarBtnClick">
      <svg x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
        <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z">
        </path>
      </svg>
    </button>

  </div>
</div>
</template>



<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";




  .sidebarbtn {
    display: inline-block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 62;
    height: 44px;

    html.mod-transitions-enabled & {
      transition: transform 300ms ease-in-out;
    }
  }


  .sidebarbtn-inner {
    position: relative;
    height: 100%;
  }


  .sidebarbtn-hamburger {
    font-size: 18px;
    outline: none;
    position: relative;
    color: rgba(white,.65);
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    //padding: 9px 13px 9px 43px;

    padding-left: 40px;
    padding-right: 15px;


    &::before {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(black,.1);
      //border: 1px solid rgba(black,.03);
      z-index: 1;
      //border-radius: 4px;
      content: '';
      opacity: 0;
      html.mod-transitions-enabled & {
        transition: opacity 100ms ease-in-out;
      }
    }

    html.mod-transitions-enabled & > svg {
      transition: opacity 100ms ease-in-out, path 100ms ease-out, transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.2);
    }


    & > svg {
      display: inline-block;
      position: relative;
      z-index: 3;
      fill: rgba(black,1);
      width: 24px;
      height: 24px;
      opacity: .4;
    }

    //html.mod-topbar-color-applied & > svg {
    //  fill: rgba(white,1);
    //}


    //html.mod-topbar-color-applied &::before {
    //  background-color: rgba(white,.2);
    //  border: 1px solid rgba(white,.1);
    //}


    &:hover {
      //&::before {
      //  opacity: .5;
      //}
      & > svg {
        opacity: .8;
      }
    }

    //&.mod-active {
    //  &::before {
    //    opacity: 1;
    //  }
    //  & > svg {
    //    opacity: .8;
    //  }
    //}


    &.mod-disabled.mod-disabled.mod-disabled.mod-disabled {
      color: rgba(white,.1);
      //pointer-events: none;
      &::before {
        opacity: 0;
      }
      & > svg {
        opacity: .1;
      }
    }



  }


  .sidebarbtn-logo {

    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%) translateY(0.5px);

    & > svg {
      width: 13px;
      display: inline-block;
      position: relative;
      z-index: 5;
      transform-origin: 50% 50%;
      transform: scale(1.00);
    }
    html.mod-transitions-enabled & > svg {
      transition:  transform 150ms cubic-bezier(0.175, 0.885, 0.32, 2);
    }


    & > svg > path {
      fill: $clr-ggather;
    }

    //html.mod-topbar-color-applied & > svg > path {
    //  fill: rgba(white,.9);
    //}

    html.mod-transitions-enabled & > svg > path {
      transition: fill 100ms;
    }


  }


</style>




<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .sidebarbtn-hamburger::before {
    background: rgba(white,.1);
    border-color: rgba(white,.06);
  }

  .sidebarbtn-hamburger > svg {
    fill: rgba(white,1);
  }
  //&.mod-topbar-color-applied .sidebarbtn-hamburger > svg {
  //  fill: rgba(white,1);
  //}

  .sidebarbtn-logo > svg > path {
    fill: rgba(white,.9);
  }

}
</style>
