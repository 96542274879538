<script>
export default {

  props: [
    'viewer',
  ],

  data: function(){
    return {
      extraPopoverOpen: false,
    }
  },

  computed: {

    itemCommentsCount: function(){
      var item = this.$store.state.viewer.item;
      if (item && item.comments_count) {
        return item.comments_count;
      }
    },

    showAnnoCounts: function(){
      var item = this.$store.state.viewer.item;
      return item && item.comments_count && item.comments_count.total;
    },

    annotationsTitleContent: function(){
      if (!this.itemCommentsCount || !this.itemCommentsCount.total) return 'Link Annotations & Public Saves';
      return `${this.itemCommentsCount.private} private annotation(s). ${this.itemCommentsCount.public} public annotation(s).`;
    },

    itemOwnedByLoggedUser: function(){
      var item = this.$store.state.viewer ? this.$store.state.viewer.item : null;
      if (item && (item.owner.pk === this.$store.state.user.pk)) return true;
      return false;
    },

  },

  watch: {
    '$store.state.viewer.item.comments_count': {
      deep: true,
      handler: function(){
        this.$forceUpdate();
      },
    },
  },

  methods: {

    itemClick: function(kind){
      this.extraPopoverOpen = false;
      this.$store.commit('viewer', {kind: kind, item: this.viewer.item});
    },

    annotationsToggleClick: function(){
      this.extraPopoverOpen = false;
      this.$store.commit('viewerComments', !this.$store.state.viewerComments);
    },

  },


};
</script>


<template>
<div class="viewernav">
  <div class="viewernav-content">

    <button type="button"
      @click.prevent="itemClick('view')"
      class="viewernav-btn mod-view"
      :class="{'mod-active': viewer.kind=='view' || viewer.kind=='proxy'}"
    >
      View
    </button>


    <button type="button"
      @click.prevent="itemClick('read')"
      class="viewernav-btn mod-read"
      :class="{'mod-active': viewer.kind=='read'}"
    >
      Read
    </button>


    <button type="button"
      @click.prevent="itemClick('edit')"
      class="viewernav-btn mod-edit"
      :class="{'mod-active': viewer.kind=='edit', 'mod-disabled': !itemOwnedByLoggedUser}"
    >
      Edit
    </button>


    <button type="button" ref="buttonMoreElement"
      @click.prevent="extraPopoverOpen=!extraPopoverOpen"
      class="viewernav-btn mod-more"
      :class="{'mod-active': extraPopoverOpen}"
    >
      <iconsvg icon="more-iosglyph"/>
    </button>



    <button type="button"
      @click.prevent="annotationsToggleClick"
      class="viewernav-btn mod-anno"
      :class="{'mod-active': $store.getters.finalViewerComments}"
      :title="annotationsTitleContent"
    >
      <span v-if="showAnnoCounts" class="viewernav-anno-counts" >
        <span>{{itemCommentsCount.private < 100 ? itemCommentsCount.private : '∞'}}</span>
        <span>{{itemCommentsCount.public < 100 ? itemCommentsCount.public : '∞'}}</span>
      </span>
      <iconsvg icon="comments-iosglyph"/>
    </button>





    <Popover
      v-if="extraPopoverOpen"
      @close="extraPopoverOpen=false"
      :popover-style="{boxShadow: '0 1px 7px 0 rgba(0,0,0,.1)', borderRadius:'4px', marginTop:'3px'}"
      :anchor-el="$refs.buttonMoreElement"
      :transform-origin="{vertical: 'top', horizontal: 'left'}"
      :anchor-origin="{vertical: 'bottom', horizontal: 'left'}"
    >
      <div class="viewernav-extra-content">

        <button type="button" @click.prevent="itemClick('view')" class="viewernav-extra-btn mod-view">
          View
        </button>

        <button type="button" @click.prevent="itemClick('read')" class="viewernav-extra-btn mod-read">
          Read
        </button>

        <button type="button" @click.prevent="itemClick('edit')" class="viewernav-extra-btn mod-edit"
          :class="{'mod-disabled': !itemOwnedByLoggedUser}"
        >
          Edit
        </button>

        <button type="button" @click.prevent="itemClick('detail')" class="viewernav-extra-btn mod-details"
          :class="{'mod-disabled': !itemOwnedByLoggedUser}"
        >
          Details
        </button>

        <button type="button" @click.prevent="annotationsToggleClick" class="viewernav-extra-btn mod-anno">
          Annotations
        </button>

      </div>
    </Popover>

  </div>
</div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .viewernav {
    margin-right: 6px;
  }


  .viewernav-content {
    display: flex;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;

    // border around whole thing
    /*
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //box-shadow: 0 0 0 1px red inset;
      border: 1px solid darken(white,14);
      border-radius: 3px;
    }
    */
  }


  .viewernav-btn {
    display: inline-block;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    padding: 8px 10px 7px 10px;
    color: darken(white,60);
    border: 1px solid darken(white,12);
    background: darken(white,3);
    outline: none;
    margin-left: -1px;

    & svg {
      width: 16px;
      height: 16px;
      fill: darken(white,50);
    }

    &.mod-view {
      margin-left: 0px;
      border-radius: 4px 0 0 4px;
    }

    &.mod-read {}
    &.mod-edit {}

    &.mod-more {
    }

    &.mod-anno {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      color: darken(white,95);
      & svg {
        fill: darken(white, 70);
      }
    }

    &.mod-active {
      background-color: white;
      color: darken(white,100);
      position: relative;
      z-index: 8;
      border: 1px solid darken(white,20);
      //border-radius: 3px;
      & svg {
        fill: darken(white, 80);
      }
    }

    &.mod-disabled {
      opacity: .5;
      pointer-events: none;
    }

    @media (max-width: 900px) {
      &.mod-view {
        display: none;
      }

      &.mod-read {
        display: none;
      }
      &.mod-edit {
        display: none;
      }
      &.mod-more {
        border-radius: 3px 0 0 3px;
        margin-left: 0px;
      }
      &.mod-anno {
      }
    }




  }




  .viewernav-anno-counts {
    position: absolute;
    top: -4px;
    right: -3px;
    z-index: 10;
    border-radius: 3px;
    background: white;
    border: 1px solid darken(white,20);
    font-weight: 500;
    font-size: 0;
    line-height: 1;
    color: darken(white,60);
    white-space: nowrap;
    text-align: center;

    span {
      font-size: 9px;
      display: inline-block;
      padding: 1px 2px;
    }

    span:first-of-type {
      border-right: 1px solid darken(white,20);
    }

  }


  .viewernav-extra-content {
    display: block;
    background: white;
    border-radius: 4px;
    padding: 6px 0;
    border: 1px solid darken(white,18);
  }

  .viewernav-extra-btn {
    display: block;
    width: 100%;
    outline: none;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    color: darken(white,80);
    padding: 6px 24px 6px 12px;

    &:hover {
      color: darken(white,95);
      background: darken(white,4);
    }

    display: none;
    &.mod-details {
      display: block;
    }



    &.mod-disabled {
      opacity: .5;
      pointer-events: none;
    }

    @media (max-width: 900px) {
      &.mod-view {
        display: block;
      }
      &.mod-read {
        display: block;
      }
      &.mod-edit {
        display: block;
      }
      &.mod-details {
        display: block;
      }
      &.mod-anno {
      }
    }

  }

</style>






<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .viewernav-btn {

    background-color: darken(white,72);
    border-color: darken(white,68);
    color: darken(white,15);

    & svg {
      fill: darken(white,15);
    }

    &:hover {
      color: darken(white,5);
      & svg {
        fill: darken(white,5);
      }
    }

    &.mod-active {
      background-color: darken(white,64);
      border-color: darken(white,60);
      & svg {
        fill: darken(white,5);
      }
    }

  }


  .viewernav-extra-content {
    background-color: darken(white,72);
    border-color: darken(white,68);
  }

  .viewernav-extra-btn {
    color: darken(white,10);
    &:hover {
      color: darken(white,15);
      background-color: darken(white,68);
    }
  }




  .viewernav-anno-counts {
    background: darken(white,60);
    border: 1px solid darken(white,50);
    color: darken(white,20);

    span:first-of-type {
      border-right: 1px solid darken(white,50);
    }

  }



}
</style>
