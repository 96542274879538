var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'viewer',
  'mod-expand-'+_vm.expandLevel,
  _vm.viewer ? 'mod-kind-'+_vm.viewer.kind : '',
  {
    'mod-forced-expand-level': _vm.forcedExpandLevel,
    'mod-comments-visible': _vm.$store.getters.finalViewerComments,
  } ]},[(_vm.viewer)?_c('div',{staticClass:"viewer-background",on:{"!click":function($event){return _vm.closeViewerFromBackgroundClick($event)}}}):_vm._e(),_vm._v(" "),(_vm.viewer)?_c('div',{staticClass:"viewer-foreground-1",on:{"!click":function($event){return _vm.closeViewerFromBackgroundClick($event)}}},[_c('div',{staticClass:"viewer-foreground-2"},[_c('div',{ref:"mainWrapper",staticClass:"viewer-main-wrapper"},[_c('div',{staticClass:"viewer-top-wrapper"},[_c('viewertop',{attrs:{"viewer":_vm.viewer,"expand-level":_vm.expandLevel,"forced-expand-level":_vm.forcedExpandLevel},on:{"clickExpandToggle":_vm.toggleExpandLevel,"clickExpandForce":function($event){_vm.forcedExpandLevel=0},"clickClose":_vm.closeViewer}})],1),_vm._v(" "),(_vm.viewer.kind=='view')?_c('div',{staticClass:"viewer-content  mod-view  common-light-scrollbar"},[_c('embedel',{key:"'embedel'+viewer.item.url",attrs:{"url":_vm.viewer.item.url,"item":_vm.viewer.item}})],1):(_vm.viewer.kind=='proxy')?_c('div',{staticClass:"viewer-content  mod-proxy  common-light-scrollbar"},[_c('embedel',{key:"'embedelproxy'+viewer.item.url",attrs:{"url":_vm.viewer.item.url,"item":_vm.viewer.item,"proxy":true}})],1):(_vm.viewer.kind=='read')?_c('div',{staticClass:"viewer-content mod-read  common-light-scrollbar"},[_c('reader',{attrs:{"url":_vm.viewer.item.url}})],1):(_vm.viewer.kind=='detail')?_c('div',{staticClass:"viewer-content  mod-detail  common-light-scrollbar"},[_c('itemdetails',{attrs:{"item":_vm.viewer.item}})],1):(_vm.saveURLVisible)?_c('div',{staticClass:"viewer-content  mod-saveurl  common-light-scrollbar"},[_c('saveurl',{attrs:{"viewer-kind":_vm.viewer.kind,"viewer-item":_vm.viewer.item}})],1):_vm._e()]),_vm._v(" "),(_vm.$store.getters.finalViewerComments && _vm.viewer.item)?_c('div',{ref:"commentsWrapper",staticClass:"viewer-comments-wrapper"},[_c('div',{staticClass:"viewer-comments-arrow"}),_vm._v(" "),_c('div',{staticClass:"viewer-comments-wrapper-inner"},[_c('comments')],1)]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }