import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/store.js';
import routes from './routes.js';

Vue.use(Router);

let router = new Router({
  //scrollBehavior: () => ({x:0,y:0}),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'mod-route-active',
  linkExactActiveClass: 'mod-exact-route-active',
})


export default router;
