<script>
import _find from 'lodash/find';
export default {

  name: 'page-tag',

  head: {
    title: function(){
      return {
        inner: this.pageTitle,
      };
    },
  },

  data: function(){
    return {
      pageTitle: '# '+this.$route.params.tagslug+'',
    };
  },


  computed: {
    tag: function(){
      return this.$store.state.tagPageMeta;
    },
  },


  watch: {
    '$store.state.tagPageMeta': function(newVal, oldVal){
      this.changePageTitle();
      this.setActiveDefaultItemLayout();
      if (newVal && oldVal && newVal.pk !== oldVal.pk) {
        this.scrollWindowToTop();
        this.trackView();
      }
      if (!oldVal && !!newVal) {
        this.scrollWindowToTop();
        this.trackView();
      }
    },
    '$store.state.eventFollowOrUnfollowTag': function(){
      this.getTag();
    },
  },


  methods: {


    updateTagOnStateChange: function(){

      // this method updates tag in tag page when
      // it was changed inside tagbox

      // check if tag available...
      if ((!this.tag) || (!this.tag.pk)) return;

      // get tag from state
      var tagFromState = _find(this.$store.state.tags, ['pk', this.tag.pk]);

      // detect deletion
      if (this.tag && (!tagFromState) && (this.tag.owner.pk == this.$store.state.user.pk) ) {
        this.$router.push('/all'); return false;
      }

      // if not tag from state
      if (!tagFromState) return;

      // if slug was changed...
      if (this.tag.slug != tagFromState.slug) {
        this.$router.push('/' + this.tag.owner.username + '/' + tagFromState.slug);
        return false;
      }

      // update tag only if these changes occured
      if (
        (this.tag.name        != tagFromState.name) ||
        (this.tag.description != tagFromState.description) ||
        (this.tag.color       != tagFromState.color) ||
        (this.tag.page_views  != tagFromState.page_views) ||
        (this.tag.visibility  != tagFromState.visibility)
      ) {
        this.getTag();
      }


    },



    getTag: function(trackView){
      this.$axios({
        url: this.$store.state.api + '/tag-page/',
        method: 'get',
        params: {
          slug: this.$route.params.tagslug,
          username: this.$route.params.username,
        },
      }).then(function(response){
        this.$store.commit('tagPageMeta', response.data);
      }.bind(this), function(error){
        alert('error' + error.response.status);
      }.bind(this));
    },




    trackView: function(){
      this.$nextTick(function(){
        this.$axios({
          url: this.$store.state.api + '/track-view/',
          method: 'get',
          params: {
            kind: 'tag',
            tagpk: this.tag.pk,
          },
        }).then(function(response){
        }.bind(this), function(error){
        }.bind(this));
      });
    },


    changePageTitle: function(){
      this.pageTitle = '# '+this.tag.name;
      this.$emit('updateHead');
    },


    setActiveDefaultItemLayout: function(){
      this.$store.commit('activeDefaultItemLayout', this.tag.item_layout);
    },


    scrollWindowToTop: function(){
      window.scrollTo(0,0);
    },





  },


};
</script>


<template>
<div class="router-page">
<div class="tagpage">


  <!-- Subscribe Bar -->
  <!--
  <div class="tagpage-sub"  v-if="tag && $store.state.user && (tag.owner.pk !== $store.state.user.pk) && (!tag.is_followed)">

    <div class="tagpage-sub-text">
      Subscribe to this tag to get
    </div>

    <button class="tagpage-sub-btn" type="button">Subscribe</button>
  </div> -->

  <!-- User Bar -->
    <!--
  <div v-if="tag && (tag.owner.username!==$store.state.user.username)" class="userbar  has-tags-visible">



    {% if requested_userplus.cover_image %}
    style="background-image:url('{{requested_userplus.cover_image.url}}');"
    {% else %}
    data-geopattern-string="{{requested_user.username}}" js-userbar-geopattern
    {% endif %} -->





    <!--
    <router-link :to="'/'+tag.owner.username" class="userbar__user">


      <button class="userbar__view-profile-btn">View Profile</button>



      <div class="userbar__user-inner">


        <div class="userbar__avatar">
          <img :src="tag.owner.profile_image">
        </div>

        <div v-if="!tag.owner.profile_image" class="userbar__avatar is-blank">
          <img src="img/blank-user-male-padding.png">
        </div>




        <div class="userbar__text-wrapper">

          <div class="userbar__top-text">Public tag by</div>

          <div class="userbar__username">
            {{tag.owner.username}}
            <span v-if="tag.owner.is_premium" class="userbar__premium-badge">PRO</span>
          </div>

        </div>

      </div>

    </router-link>
    -->





    <!--
    <div class="userbar__other-tags">
      <div class="userbar__other-tags-inner">


        <div class="userbar__other-tags-controls">
          <button type="button" class="userbar__other-tags-btn is-prev disabled" js-user-tags-sly-prev-btn>
            <span class="icon icon-back"></span>
          </button>
          <button type="button" class="userbar__other-tags-btn is-next" js-user-tags-sly-next-btn>
            <span class="icon icon-forward"></span>
          </button>
        </div>

        <div class="userbar__other-tags-frame" js-user-tags-sly-frame>
          <div class="userbar__other-tags-slidee" js-user-tags-sly-slidee>

            {% for utag in user_tags %}

              <div class="userbar__other-tags-item" js-user-tags-sly-item>
                <a class="tag is-color-{{utag.color}} in-userbar {% if tag.pk is utag.pk %}is-active{% endif %}" href="{% url 'tag_page' utag.owner.username utag.slug %}">
                {% if utag.is_private %}
                  <span class="icon icon-lock-filled" title="This tag is private"></span>
                {% endif %}
                {{utag.name}}</a>
              </div>

            {% endfor %}

          </div>
        </div>
      </div>
    </div>



  </div>
  -->
  <!-- / User Bar -->






  <!-- Tag Header -->
  <!--  v-if="tag && (tag.owner.username!==$store.state.user.username)"  -->

  <!--
  <div v-if="tag" class="tagheader" :class="'is-color-'+tag.color">

    <div class="tagheader__name">
      {{tag.name}}
    </div>

    <div class="tagheader__description" v-if="tag.description">{{tag.description}}</div>

    <div class="tagheader__bottom-wrapper">



      <template v-if="$store.state.user.username != tag.owner.username">

        <a v-if="$store.state.user.is_anonymous" href="{% url 'account_signup' %}" target="_blank" class="tagheader__follow-btn">
          <span class="icon icon-checkmark"></span>
          <span>Create account to subscribe</span>
        </a>

         -->




        <!--
        <button v-if="false" class="tagheader__follow-btn  is-followed" type="button" title="Click to unscubscribe">
          <span class="icon icon-checkmark"></span>
          <span js-follow-btn-text>Subscribed</span>
        </button>

        <button v-if="true" class="tagheader__follow-btn" type="button"  title="Click to subscribe">
          <span class="icon icon-checkmark"></span>
          <span js-follow-btn-text>Subscribe</span>
        </button>  -->

      <!--

      </template>



      <div v-if="tag.is_private" class="tagheader__detail">
        <span class="icon icon-lock-filled" title="This tag is private"></span>
        This tag is private
      </div>-->




      <!--
      <div class="tagheader__detail">
        {{followers_count}} Subscriber{{followers_count|pluralize}}
      </div> -->

      <!--

      <div class="tagheader__detail">
        {{tag.page_views}} View{{tag.page_views==1 ? '' : 's'}}
      </div>
 -->


      <!--
      {% if last_addition %}
      <div class="tagheader__detail">
        {#<span class="icon icon-time-span"></span>#}
        Last addition was {{last_addition|naturaltime}}
      </div>
      {% endif %}  -->

  <!--

    </div>

  </div> -->
  <!-- / Tag Header -->



  <ctabar></ctabar>



  <div class="tagpage-items">

    <items context="tagpage"></items>

  </div>

</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .tagpage {
    //padding: 20px 20px 20px 20px;
    //padding-top: 50px;

  }

  .tagpage-items {
  }


  .tagpage-sub {
    margin: 20px;
    margin-bottom: 0;
    border: 1px solid #eee;
    padding: 5px;
    background-color: rgba(white,.9);

  }

  .tagpage-sub-text {

  }
  .tagpage-sub-btn {
    background-color: white;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 5px 10px;
  }


  .userbar {
    //border-radius: 2px 2px 0 0;
    display: block;
    //margin-top: 20px;
    //margin-left: 1px;
    //margin-right: 1px;
    //margin-bottom: 10px;
    outline: none;
    html.mod-transitions-enabled & {
      transition: margin-bottom 150ms ease-out 400ms, box-shadow 100ms ease-out 400ms, border-radius 100ms ease-out 400ms;
    }


    &.has-tags-visible,
    &:hover {
      html.mod-transitions-enabled & {
        transition: margin-bottom 150ms ease-out 150ms, box-shadow 100ms ease-out 150ms, border-radius 100ms ease-out 150ms;
      }
      //margin-bottom: 5px;
      //border-radius: 2px;
    }
  }



  .userbar.has-tags-visible + .tagheader,
  .userbar:hover + .tagheader {
    html.mod-transitions-enabled & {
      transition: border-radius 600ms;
    }
    //border-radius: 2px;
  }





    .userbar__user {
      outline: none;
      display: block;
      //border-radius: 2px 2px 0 0;
      background-size: cover;
      background-position: 50%;
      position: relative;
      box-shadow: 0 0 0px 0 rgba(black,.1);
      background-image: linear-gradient(to bottom, rgba(0,0,0,.4), rgba(0,0,0,.6));

      html.mod-transitions-enabled & {
        transition: margin-bottom 150ms ease-out 400ms, box-shadow 200ms ease-out 400ms, border-radius 200ms ease-out 400ms;
      }

      &:hover {
        box-shadow: 0 0 15px 0 rgba(black,.3);
      }

      .userbar.has-tags-visible &,
      .userbar:hover & {
        html.mod-transitions-enabled & {
          transition: margin-bottom 150ms ease-out 150ms, box-shadow 200ms ease-out 150ms, border-radius 200ms ease-out 150ms;
        }
        //border-radius: 2px;
      }
    }

    .userbar__user-inner {
      //border-radius: 2px 2px 0 0;
      display: block;
      background-color: rgba(#333,.7);
      padding: 2px 0;

      .userbar.has-tags-visible &,
      .userbar:hover & {
        border-radius: 2px;
      }
    }





      .userbar__view-profile-btn {
        position: absolute;
        display: block;
        padding: 10px 30px;
        font-size: 13px;
        font-weight: 500;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        z-index: 3;
        opacity: 0;
        color: rgba(white,1);


        background-color: rgba(blue,.9);
        border: 1px solid rgba(blue,1);


        background-color: rgba(white,1);
        border: 1px solid rgba(white,1);
        color: rgba(#333,1);

        border-radius: 2px;
        outline: none;
        html.mod-transitions-enabled & {
          transition: opacity 150ms ease-out 400ms;
        }

        .userbar:hover & {
          html.mod-transitions-enabled & {
            transition: opacity 150ms ease-out 100ms, box-shadow 100ms ease-out 100ms;
          }

          opacity: .9;

          &:hover {
            html.mod-transitions-enabled & {
              transition: opacity 100ms ease-out 0, box-shadow 100ms ease-out 0;
            }
            opacity: 1;
          }
        }


      }





    .userbar__other-tags {
      display: block;
      height: 0;
      margin-top: 0;
      html.mod-transitions-enabled & {
        transition: height 200ms ease-out 400ms, margin-top 300ms ease-out 400ms;
      }
      position: relative;

      .userbar.has-tags-visible &,
      .userbar:hover & {
        height: 32px;
        margin-top: 5px;
        html.mod-transitions-enabled & {
          transition: height 200ms ease-out 150ms, margin-top 200ms ease-out 150ms;
        }
      }
    }




      .userbar__other-tags-inner {
        display: block;
        border: none;
        height: 100%;
        width: 100%;
        color: white;
        font-size: 0;
      }

        .userbar__other-tags-controls {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;
          html.mod-transitions-enabled & {
            transition: top 300ms ease-out 400ms, bottom 300ms ease-out 400ms;
          }

          .userbar:hover &,
          .userbar.has-tags-visible & {
            html.mod-transitions-enabled & {
              transition: top 200ms ease-out 150ms, bottom 200ms ease-out 150ms;
            }
            top: -5px;
            bottom: -5px;
          }
        }

          .userbar__other-tags-btn  {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 50px;
            text-align: center;
            outline: none;
            z-index: 80;

            opacity: .7;
            html.mod-transitions-enabled & {
              transition: opacity 100ms ease-in-out;
            }

            &.is-prev {
              left: 0;
              padding-right: 20px;
              background-image: linear-gradient(to left, rgba(black,.0), rgba(black,.5));
            }


            &.is-next {
              right: 0;
              padding-left: 20px;
              background-image: linear-gradient(to right, rgba(black,.0), rgba(black,.5));
            }

            .icon {

              &::before {
                color: rgba(white,1);
                line-height: 1;
                font-size: 17px;
              }
            }

            &:hover {
              opacity: 1;
            }

            &.disabled {
              opacity: 0;
              pointer-events: none;
            }
          }


        .userbar__other-tags-frame {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          white-space: nowrap;
        }

          .userbar__other-tags-slidee {
            //cursor: grab;

            //&:active,
            //&.dragged {
            //    cursor: grabbing;
            //}
          }

            .userbar__other-tags-item {
              display: inline-block;
              //margin-left: 5px;
              &::before {
                content: '';
                font-size: 0;
                width: 5px;
                display: inline-block;
              }
              &:first-child::before {
                content: none;
              }


              //cursor: grab;
              //.userbar__other-tags-slidee:active &,
              //.userbar__other-tags-slidee.dragged & {
              //    a {
              //        cursor: grabbing;
              //    }
              //    cursor: grabbing;
              //}



            }








    .userbar__avatar {
      display: inline-block;
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin: 10px;
      margin-left: 20px;
      vertical-align: middle;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 300px;
      }


      &.is-blank {
        img {
          background-color: #ddd;
        }
      }

    }




    .userbar__text-wrapper {
      display: inline-block;
      vertical-align: middle;
    }


      .userbar__username {
        color: rgba(white,.9);
        font-size: 17px;
        font-weight: 600;
        line-height: 1.2;
        margin-top: 2px;
        display: inline-block;
      }



      .userbar__premium-badge {
        display: inline-block;
        background-color: $clr-ggather;
        color: white;
        border-radius: 2px;
        padding: 2px 5px;
        font-size: 9px;
        margin-left: 2px;
      }



      .userbar__top-text {
        color: white;
        display: block;
        font-size: 14px;
        opacity: .8;
        line-height: 1;
      }



      .userbar__additional-info {
        overflow: hidden;
      }


        .userbar__full-name {
          color: white;
          display: inline-block;
          font-size: 14px;
          opacity: .8;
          line-height: 1;
        }


        .userbar__bio {
          color: white;
          display: inline-block;
          font-size: 14px;
          opacity: .8;
          line-height: 1;
        }






        .userbar__follow-btn {
          display: inline-block;
          color: rgba(white,.8);
          padding: 3px 8px 4px 8px;
          margin-left: 5px;
          line-height: 1;
          font-size: 13px;
          border: 1px solid rgba(white,.1);
          border-radius: 2px;
          vertical-align: baseline;
        }






















  .tagheader {


    padding: 15px 20px;
    //border-radius: 0 0 2px 2px;
    background-color: lightblue;
    margin-bottom: 15px;

    &.is-color-1 { background-color: rgba($clr-tag-1, 1);  }
    &.is-color-2 { background-color: rgba($clr-tag-2, 1);  }
    &.is-color-3 { background-color: rgba($clr-tag-3, 1);  }
    &.is-color-4 { background-color: rgba($clr-tag-4, 1);  }
    &.is-color-5 { background-color: rgba($clr-tag-5, 1);  }
    &.is-color-6 { background-color: rgba($clr-tag-6, 1);  }
    &.is-color-7 { background-color: rgba($clr-tag-7, 1);  }
    &.is-color-8 { background-color: rgba($clr-tag-8, 1);  }
  }




    .tagheader__name {
      color: white;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
      vertical-align: baseline;
      display: inline-block;

      .icon::before {
        line-height: 0.95;
      }
    }



    .tagheader__description {

      color: white;
      display: inline-block;
      font-size: 15px;
      vertical-align: baseline;
      line-height: 1.2;
      opacity: .8;
      margin-left: 15px;

    }




    .tagheader__bottom-wrapper {
      margin-top: 5px;

    }



      .tagheader__share-btn {
        display: inline-block;
        color: rgba(white,.8);
        padding: 5px 10px;
        border: 1px solid rgba(white,.2);
        border-radius: 2px;
        vertical-align: baseline;
      }


      .tagheader__follow-btn {
        display: inline-block;
        color: rgba(white,.8);
        padding: 5px 10px;
        border: 1px solid rgba(white,.2);
        border-radius: 2px;
        vertical-align: baseline;
        margin-right: 20px;
        background-color: rgba(white,.15);
        outline: none;

        .icon {
           display: none;
        }

        &.is-followed {
          border-color: rgba(white,.1);
          background-color: rgba(white,.0);
          .icon {
            display: inline;
          }
        }

      }

      .tagheader__detail {
        color: rgba(white,.5);
        display: inline-block;
        padding-right: 15px;
        font-size: 14px;
        font-weight: 400;
        vertical-align: baseline;

        & > .icon::before {
          line-height: 1.2;
        }
      }





  @media screen and (max-width: 730px) {


    .tagheader__follow-btn {
      display: block;
      margin-bottom: 10px;
    }

    .tagheader__detail  {
      padding-left: 0;
      display: block;
      margin-top: 5px;
    }

  }










</style>
