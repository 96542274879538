<script>
import GeoPattern from 'geopattern';
export default {

  props: [
    'user',
  ],

  mounted: function(){
    this.geopattern();
  },

  beforeDestroy: function(){
    this.clearGeopattern();
  },

  methods: {

    geopattern: function(){
      this.$nextTick(function(){

        if ((!this.user.cover_image) && this.$refs.coverImageGeopattern) {
          var pattern = GeoPattern.generate(this.user.username, {baseColor: '#111'});
          this.$refs.coverImageGeopattern.style.backgroundImage = pattern.toDataUrl();
        }

      });
    },

    clearGeopattern: function(){
      if ((!this.user.cover_image) && this.$refs.coverImageGeopattern) {
        this.$refs.coverImageGeopattern.style.backgroundImage = '';
      }
    },

  },


};
</script>


<template>
<router-link :to="'/'+user.username" class="usercard" :style="{backgroundImage: 'url('+ user.cover_image +')'}"
>

  <div v-if="!user.cover_image" class="usercard-geopattern" ref="coverImageGeopattern"></div>

  <div class="usercard-inner">


    <div class="usercard-header">

      <div class="usercard-avatar">
        <img :src="user.profile_image">
      </div>

      <div class="usercard-title">
        <div class="usercard-title-inner">

          <template v-if="user.first_name || user.last_name">
            {{ user.first_name }} {{ user.last_name}} <div class="usercard-username">{{ user.username }}</div>
          </template>

          <template v-else>
            {{user.username}}
          </template>

          <!--<span v-if="user.is_premium" class="usercard-premium-badge">PRO</span>  -->

        </div>

      </div>

    </div>

    <div class="usercard-description">
      {{user.bio}}
    </div>

    <div class="usercard-details-wrapper">


      <!--
      <div class="usercard-detail">
        <div class="icon icon-gps-searching"></div>
        <div class="text">Joined {{user.date_joined}}</div>
      </div> -->


      <div class="usercard-detail">
        <div class="icon icon-sheets-filled"></div>
        <div class="text">{{user.items_count}} links and {{user.tags_count}} tags</div>
      </div>


      <div class="usercard-detail">
        <div class="icon icon-user-filled"></div>
        <div class="text">{{user.tag_follows_count}} subscribers</div>
      </div>

    </div>


  </div>
</router-link>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";







  /*------------------------------------*\
    usercard
  \*------------------------------------*/



  .usercard {

    width: 300px;
    height: 175px;
    //border: 1px solid #111;
    border-radius: 3px;
    color: white;
    overflow: hidden;

    background-color: #333;
    background-position: 50%;
    background-size: cover;

    display: inline-block;
    font-size: 15px;

    position: relative;
    //margin-right: 5px;
    //margin-bottom: 5px;

  }

    .usercard-geopattern {
      background-size: cover;
      background-position: 0% 100% ;
      background-repeat: repeat;
      position: absolute;
      opacity: .8;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border-radius: 3px;
      overflow: hidden;
    }



    .usercard-inner {
      padding: 15px 15px;
      background-image: linear-gradient(to top, rgba(#111,.5), rgba(#111,.9));
      height: 100%;
      position: relative;
      z-index: 5;
    }





      .usercard-header {
        display: block;
        height: 30px;
      }



      .usercard-avatar {
        width: 27px;
        height: 27px;
        display: inline-block;
        overflow: hidden;
        border-radius: 100px;
        background-color: #eee;


        img {
          width: 100%;
          height: 100%;
        }
      }




      .usercard-title {
        display: inline-block;
        white-space: nowrap;
        font-size: 17px;
        line-height: 1;
        font-weight: 600;
        margin-left: 5px;
        height: 100%;
      }


      .usercard-title-inner {
        height: 100%;
        display: flex;

        flex-flow: column;
        justify-content: center;
      }

      .usercard-badge {
        &::before {
        }
      }



      .usercard-username {
        display: block;
        color: rgba(white,.6);
        font-size: 12px;
        font-weight: 300;
        line-height: 1.1;
      }


      .usercard-premium-badge {
        display: inline-block;
        background-color: $clr-ggather;
        color: white;
        border-radius: 2px;
        padding: 2px 5px;
        font-size: 10px;
        margin-left: 2px;
      }





      .usercard-description {
        font-size: 13px;
        display: block;
        line-height: 1.1;
        margin-top: 10px;
        overflow: hidden;
        height: 87px;
        opacity: .7;
        white-space: normal;
      }


      .usercard-details-wrapper {
        margin-top: 5px;
        opacity: .6;
        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }
        display: flex;
        justify-content: space-between;

        .usercard:hover & {
          opacity: 1;
        }
      }

        .usercard-detail {
          font-size: 13px;
          display: block;
          padding-right: 5px;



          .icon {
            display: inline;
            color: rgba(white,.8);
            font-size: 10px;
            margin-right: 4px;
            margin-top: 3px;

            &::before {
              line-height: 1.4;
            }
          }

          .text {
            display: inline-block;
            opacity: .6;
          }


        }



      .usercard-footer {
        display: block;
        opacity: .6;
        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }
        .usercard:hover & {
          opacity: 1;
        }
      }


        .usercard-cta-btn {
          display: inline-block;
          margin-top: 10px;
          font-size: 14px;
          color: rgba(white,.8);
          border-radius: 3px;
          padding: 3px 10px 5px 10px;
          border: 1px solid rgba(white,.2);
        }









</style>
