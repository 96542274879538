<script>
export default {

  props: [
    'viewer',
    'expandLevel',
    'forcedExpandLevel',
  ],


  data: function(){
    return {
    }
  },



  computed: {

    viewerNavVisible: function(){
      var viewer = this.$route.meta.viewer || this.$store.state.viewer;
      return ['view', 'edit', 'proxy', 'read', 'detail'].indexOf(viewer.kind) > -1;
    },

  },


  methods: {





  },


};
</script>


<template>
<div class="viewertop">


  <div class="viewer-topbar-section  mod-left">



    <div v-if="(viewer.kind=='savepage') || (viewer.kind=='urlsave') || (viewer.kind=='chromesave') || (viewer.kind=='chromebtn')"
      class="viewer-heading" >

      <div class="viewer-heading-inner">

        <div class="viewer-title">

          <!--  <img class="viewer-title-ggather-logo" src="../../../common/img/ggather-logo-big-blue.png">-->

          <div class="viewer-title-logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438 668"><path fill="#000000" class="cls-1" d="M376.29,0h-33a60.31,60.31,0,0,0-54.49,34.46l-35,73.69a14.65,14.65,0,0,0,13.23,20.92h71.62c19.15,0,29.42,22.53,16.85,37-69.92,80.39-190.26,216-251.8,287-5.68,6.56-16.18-.2-12.36-8C120.5,385.68,180.81,268,209.8,208.45a7.42,7.42,0,0,0-6.68-10.67H115c-17.16,0-26.44-20.1-15.32-33.16l99-116.32C214.94,29.28,201.42,0,176.42,0H61.71A61.31,61.31,0,0,0,.4,61.31V636.42c0,29.36,36.28,43.16,55.79,21.23L173.86,525.41a60.42,60.42,0,0,1,90.28,0L381.81,657.65c19.52,21.93,55.79,8.13,55.79-21.23V61.31A61.31,61.31,0,0,0,376.29,0Z"/></svg>
          </div>


          <div class="viewer-title-image  mod-profile-image" :style="{backgroundImage: 'url('+ $store.state.user.profile_image+')'}"></div>

          <div class="viewer-title-text">
            {{$store.state.user.username}}
          </div>

        </div>

      </div>

    </div>



    <div v-if="(viewer.kind!='newlink') && (viewer.kind!='chromebtn') && (viewer.kind!='urlsave') && (viewer.kind!='savepage') && (viewer.kind!='chromesave')"
      class="viewer-heading" >

      <a :href="viewer.item.url" target="_blank" class="viewer-heading-inner  mod-link" :title="'(Open link in new tab) ' + (viewer.item.title ? viewer.item.title : viewer.item.urldata.title)">

        <div class="viewer-title">
          <div class="viewer-title-image" :style="{backgroundImage: 'url(\'https://s2.googleusercontent.com/s2/favicons?domain_url=' + viewer.item.url +'\')'}"></div>

          <div class="viewer-title-text" v-if="viewer.item.title || viewer.item.urldata.title">
            {{viewer.item.title ? viewer.item.title : viewer.item.urldata.title}}
          </div>

          <div class="viewer-title-text" v-else>
            {{viewer.item.url}}
          </div>


        </div>

        <div class="viewer-link">
          <div class="viewer-link-url">
          {{viewer.item.url}}
          </div>
        </div>

      </a>

    </div>






  </div>




  <div class="viewer-topbar-section  mod-right">


    <div class="viewer-nav-wrapper" v-if="viewerNavVisible">
      <viewernav :viewer="viewer" />
    </div>


    <div class="viewer-tab-btns-wrapper  mod-controls ">
      <div class="viewer-tab-btns-section">

        <!--

        <button v-if="(!$store.state.chrome) && forcedExpandLevel" type="button" class="viewer-tab-btn-forced-expand" @click.prevent="$emit('clickExpandForced')">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
            <path d="M 6 8 L 2 12 L 6 16 L 6 13 L 18 13 L 18 16 L 22 12 L 18 8 L 18 11 L 6 11 L 6 8 z"></path>
          </svg>
        </button>-->


        <button v-if="!$store.state.chrome && viewerNavVisible"  type="button" class="viewer-tab-btn  mod-expand-toggle" :class="{'mod-disabled': false}" @click.prevent="$emit('clickExpandToggle')">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
            <path d="M 15 3 L 17.292969 5.2929688 L 5.2929688 17.292969 L 3 15 L 3 21 L 9 21 L 6.7070312 18.707031 L 18.707031 6.7070312 L 21 9 L 21 3 L 15 3 z"></path>
          </svg>
        </button>




        <button type="button" class="viewer-tab-btn  mod-close" @click.prevent="$emit('clickClose')">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
            <path d="M 15.125 12.28125 L 12.28125 15.125 L 22.21875 25 L 12.28125 34.875 L 15.125 37.71875 L 25.0625 27.84375 L 35 37.71875 L 37.8125 34.875 L 27.90625 25 L 37.8125 15.125 L 35 12.28125 L 25.0625 22.15625 Z "></path>
          </svg>
        </button>


      </div>
    </div>



  </div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .viewertop {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: space-between;
    //justify-content: center;
    align-content: center;
  }


  .viewer-topbar-section {
    display: inline-block;

    &.mod-left {
      text-align: left;
      //outline: 1px solid blue;
      width: calc(100% - 330px);
    }

    &.mod-right {
      text-align: right;
      width: 320px;
      padding-top: 11px;
      padding-right: 10px;
    }

    @media (max-width: 900px) {
      &.mod-left {
        width: calc(100% - 150px);
      }
      &.mod-right {
        width: 140px;
      }
    }
  }



.viewer-heading {
  padding-top: 20px;
  padding-left: 20px;
  text-align: center;
  white-space: nowrap;
  //max-width: 210px;
  line-height: 1;
  overflow: hidden;
  display: inline-block;
  text-align: left;
  //border: 1px solid #eee;

  position: relative;
  width: calc(100% - 450px);

  display: block;
  width: auto;

  //outline: 1px solid red;

  &::before {
    content: '';
    background-image: linear-gradient(to right, rgba(darken(white,3),.0), rgba(darken(white,3),1));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20px;
  }

}


  .viewer-heading-inner {
    display: inline-block;

    html.mod-transitions-enabled & {
      transition: transform 250ms ease-in-out;
    }

    &.mod-link:hover {
      padding-bottom: 5px;
      transform: translateY(-6px);
    }

  }



  .viewer-title {
    font-size: 0;
  }

    .viewer-title-image {
      display: inline-block;
      vertical-align: top;
      margin-top: -1px;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      vertical-align: top;
      line-height: 1;

      &.mod-profile-image {
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 3px;
      }
    }

    .viewer-title-text {
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: rgba(black,.9);
      vertical-align: top;
      line-height: 1;
      @media (max-width: 900px) {
        font-size: 13px;
        padding-top: 1px;
      }
    }

    .viewer-title-ggather-logo {
      width: 22px;
      position: relative;
      top: -1px;
      margin-right: 10px;
      padding-right: 5px;
      border-right: 1px solid #eee;
    }


    .viewer-title-logo {
      display: inline-block;
      position: relative;
      top: -2px;
      margin-right: 10px;
      padding-right: 5px;
      //border-right: 1px solid #eee;
      //border-left: 1px solid #eee;
      //border-right: 1px solid #eee;
      //background-color: darken(white,2);
      text-align: center;
      border-radius: 2px;
      & > svg {

        width: 12px;
        display: inline-block;
        position: relative;
        z-index: 5;
        html.mod-transitions-enabled & {
          transition:  transform 150ms cubic-bezier(0.175, 0.885, 0.32, 2);
        }
        transform-origin: 50% 50%;
        transform: scale(1.00);
      }


      & > svg > path {
        fill: #4889f5;
        fill: #888;
        fill: #ccc;
        fill: #aaa;
        fill: $clr-ggather;

        html.mod-transitions-enabled & {
          transition: fill 100ms;
        }
      }
    }


  .viewer-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: rgba(black,.5);
    line-height: 1;
  }

    .viewer-link {
      white-space: nowrap;
      //display: none;
    }


      .viewer-link-url {
        font-size: 12px;
        font-weight: 400;
        color: rgba(black,.5);
        line-height: 1;
        display: block;
        opacity: 0;
        //pointer-events: none;
        //display: none;

        transform: translateY(5px);

        html.mod-transitions-enabled & {
          transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
        }
        .viewer-heading-inner.mod-link:hover & {
          opacity: 1;
          transform: translateY(0);
          //pointer-events: auto;
          //display: block;
        }

        @media (max-width: 900px) {
          font-size: 10px;
        }
      }









  .viewer-nav-wrapper {
    display: inline-block;
    vertical-align: middle;
  }



  .viewer-tab-btns-wrapper {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    //outline: 1px solid green;


    &.mod-controls {
      display: inline-block;
    }
  }

    .viewer-tab-btns-section {
      display: inline-block;
    }

    .viewer-tab-btn {
      position: relative;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      padding: 7px 10px 6px 10px;
      line-height: 1;
      //background-color: white;
      user-select: none;
      //border-left: 1px solid #eee;
      //border-top: 1px solid #eee;
      //border-bottom: 1px solid #eee;
      outline: none;
      color: #999;
      border: 1px solid transparent;
      border-radius: 3px;
      margin-right: 2px;


      html.mod-transitions-enabled & {
        transition: color 70ms ease-out, background-color 70ms ease-out, border-color 70ms ease-out;
      }

      & {
        svg {
          line-height: 1;
          width: 18px;
          height: 18px;
        }
        svg path {
          html.mod-transitions-enabled & {
            transition: fill 70ms ease-out;
          }
          fill:  #999;
        }
      }


      &.mod-close.mod-close {
        padding-left: 7px;
        padding-right: 7px;
        svg {
          transform: scale(1.3);
        }
      }


      &.mod-expand-toggle.mod-expand-toggle {
        padding-left: 7px;
        padding-right: 7px;
      }




      &.mod-disabled {
        opacity: .3;
        pointer-events: none;
      }



      &:hover {
        background-color: white;
        border-color: darken(white,14);
        /deep/ svg path {
          fill: darken(white,80);
        }
      }


      &.mod-active {
        font-weight: 400;
        background-color: #eee;
        border-color: darken(#eee,4);
        border-color: $clr-ggather;
        background-color: $clr-ggather;
        color: white;
        //border-right: 1px solid #eee;
        //& + .viewer-tab-btn {
        //    border-left: none;
        //}
      }


    }



    @media (max-width: 900px) {

      .viewer-tab-btn.viewer-tab-btn.viewer-tab-btn.mod-expand-toggle {
        display: none;
      }
    }








  .viewer-tab-btn-forced-expand {
    font-size: 14px;
    font-weight: 400;
    outline: none;
    //outline: 1px solid red;
    color: #999;
    border: 1px solid transparent;
    html.mod-transitions-enabled & {
      transition: color 70ms ease-out, background-color 70ms ease-out, border-color 70ms ease-out;
    }
    background-color: white;
    width: 51px;
    height: 27px;
    position: absolute;
    top: 14px;
    right: 51px;
    z-index: 50;
    opacity: 1;
    border-radius: 2px;

    &:hover {
      //background-color: #333;
      background-color: white;
      border-color: #eee;
      //color: rgba(white,.8);
    }

    svg {
      width: 24px;
      height: 24px;
      display: inline-block;
      fill: $clr-ggather;
    }
  }


</style>









<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .viewer-heading::before {
    background-image: linear-gradient(to right, rgba(darken(white, 80), 0), darken(white, 80));
  }

  .viewer-title-text {
    color: rgba(white,.6);
    font-weight: 400;
  }

  .viewer-link-url {
    color: rgba(white,.3);
    font-weight: 300;
  }

  .viewer-tab-btn:hover {
    background-color: darken(white, 80);
    border-color: darken(white, 70);
    svg path {
      fill: darken(white,30);
    }
  }

  .viewer-tab-btn.mod-active {
    background-color: #3079f4;
  }


}

</style>
