<script>
export default {

  props: {
    favtag: {
      type: Object,
      required: true,
    },
  },

  data: function(){
    return {

      menuOpen: false,

    }
  },

  computed: {
    routeActive: function(){
      return this.favtag.url_path === this.$route.path;
    },
  },

  watch: {


  },

  methods: {





  },

};
</script>


<template>
  <div :class="[
    'sbarfavtag',
    'mod-color-'+favtag.color,
    {
      'mod-route-active': routeActive,
    },
  ]" >

    <router-link :to="favtag.url_path" class="sbarfavtag-link" draggable="false" @contextmenu.native.stop.prevent="menuOpen=!menuOpen">

      <div class="sbarfavtag-icon">
        <svg viewBox="0 0 24 24" version="1.1" width="24" height="24">
          <path d="M16.5,3C13.605,3,12,5.09,12,5.09S10.395,3,7.5,3C4.462,3,2,5.462,2,8.5c0,4.171,4.912,8.213,6.281,9.49 C9.858,19.46,12,21.35,12,21.35s2.142-1.89,3.719-3.36C17.088,16.713,22,12.671,22,8.5C22,5.462,19.538,3,16.5,3z"/>
        </svg>
      </div>

      <div class="sbarfavtag-name" :title="favtag.name">{{favtag.name}}</div>

    </router-link>





    <button ref="menuAnchor" class="sbarfavtag-control-btn mod-menu" @click="menuOpen=true">
      <iconsvg icon="more-filled" />
    </button>

    <Popover
      v-if="menuOpen"
      @close="menuOpen=false"
      :popover-style="{boxShadow: '0 2px 5px 0 rgba(0,0,0,.2)', borderRadius:'3px'}"
      :anchor-el="$refs.menuAnchor"
      :transform-origin="{vertical: 'top', horizontal: 'left'}"
      :anchor-origin="{vertical: 'top', horizontal: 'right'}"
    >
      <sbar-tag-menu
        @close="menuOpen=false"
        :tag="favtag"
      />
    </Popover>


  </div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .sbarfavtag {
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 43%;
    width: calc(50% - 18px);
    position: relative;

    user-select: none; // do not allow user to select natively


  }




  .sbarfavtag-link {

    padding: 7px 11px 6px 25px;

    position: relative;
    z-index: 5;

    display: block;
    width: 100%;


    background-color: white;
    border: 1px solid darken(white,10);

    html.mod-transitions-enabled & {
      transition: all 70ms ease-out;
    }

    border-radius: 3px;

    &:hover {
      background: rgba(white,.25);
    }


    .sbarfavtag.mod-color-1 &.mod-route-active {background: rgba(lighten($clr-tag-1,20), 1);}
    .sbarfavtag.mod-color-2 &.mod-route-active {background: rgba($clr-tag-2, 1);}
    .sbarfavtag.mod-color-3 &.mod-route-active {background: rgba($clr-tag-3, 1);}
    .sbarfavtag.mod-color-4 &.mod-route-active {background: rgba($clr-tag-4, 1);}
    .sbarfavtag.mod-color-5 &.mod-route-active {background: rgba($clr-tag-5, 1);}
    .sbarfavtag.mod-color-6 &.mod-route-active {background: rgba($clr-tag-6, 1);}
    .sbarfavtag.mod-color-7 &.mod-route-active {background: rgba($clr-tag-7, 1);}
    .sbarfavtag.mod-color-8 &.mod-route-active {background: rgba($clr-tag-8, 1);}
    .sbarfavtag.mod-color-9 &.mod-route-active {background: rgba($clr-tag-9, 1);}




    &.mod-route-active {
      background: rgba(white,.3);
    }


  }









  .sbarfavtag-icon {
    position: absolute;
    top: 6px;
    bottom: 0px;
    left: 6px;

    /deep/ svg {
      width: 14px;
      height: 14px;
    }

    /deep/ svg path {
      fill: rgba(white,.3);
    }

  }




  .sbarfavtag.mod-color-1 .sbarfavtag-icon /deep/ path {fill: rgba(lighten($clr-tag-1,20), .75);}
  .sbarfavtag.mod-color-2 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-2, .7);}
  .sbarfavtag.mod-color-3 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-3, .7);}
  .sbarfavtag.mod-color-4 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-4, .75);}
  .sbarfavtag.mod-color-5 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-5, .7);}
  .sbarfavtag.mod-color-6 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-6, .7);}
  .sbarfavtag.mod-color-7 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-7, .7);}
  .sbarfavtag.mod-color-8 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-8, .7);}
  .sbarfavtag.mod-color-9 .sbarfavtag-icon /deep/ path {fill: rgba($clr-tag-9, .7);}



  .sbarfavtag .sbarfavtag-link.mod-route-active .sbarfavtag-icon /deep/ path {fill: rgba(white,.7);}















  .sbarfavtag-name {
    display: block;
    width: 100%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(black,.6);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;

    /*
    .sbarfavtag.mod-color-1 & {color: rgba($clr-tag-1, 1);}
    .sbarfavtag.mod-color-2 & {color: rgba($clr-tag-2, 1);}
    .sbarfavtag.mod-color-3 & {color: rgba($clr-tag-3, 1);}
    .sbarfavtag.mod-color-4 & {color: rgba($clr-tag-4, 1);}
    .sbarfavtag.mod-color-5 & {color: rgba($clr-tag-5, 1);}
    .sbarfavtag.mod-color-6 & {color: rgba($clr-tag-6, 1);}
    .sbarfavtag.mod-color-7 & {color: rgba($clr-tag-7, 1);}
    .sbarfavtag.mod-color-8 & {color: rgba($clr-tag-8, 1);}
    .sbarfavtag.mod-color-9 & {color: rgba($clr-tag-9, 1);}
    */

    .sbarfavtag .sbarfavtag-link.mod-route-active & {
      color: rgba(white,1);
    }




  }




  .sbarfavtag-control-btn {
    position: absolute;
    bottom: 4px;
    left: 7px;
    z-index: 10;
    outline-color: rgba(white,.5);
    display: none;


    & svg {
      width: 18px;
      height: 18px;
      opacity: .3;
      fill: rgba(black,1);
    }

    .sbarfavtag.mod-route-active & svg {
      fill: rgba(white,1);
    }


    .sbarfavtag:hover & {
      display: block;
    }


    .sbarfavtag:hover & svg {
      opacity: .3;
    }

    .sbarfavtag:hover &:hover svg {
      opacity: .7;
    }

  }




</style>







<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .sbarfavtag-link {
    background: rgba(white, .15);
    border-color: rgba(white,.1);
    &:hover {
      background: rgba(white,.25);
    }
  }

  .sbarfavtag-name {
    color: rgba(white,.6);
  }
  .sbarfavtag .sbarfavtag-link.mod-route-active .sbarfavtag-name {
    color: rgba(white,1);
  }


  .sbarfavtag-control-btn {
    & svg {
      fill: rgba(white,1);
    }
  }


}
</style>
