<script>
import __ from '../../../common/script/dashdash.js';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _uniqBy from 'lodash/uniqBy';
export default {

  name: 'page-import-html-old',


  // Might be useful:
  // https://github.com/nwjs/chromium.src/blob/151b8e5d39b7edcf9eed78ebedf3b4eae9f93d28/chrome/utility/importer/bookmark_html_reader.h
  // This too:
  // http://kb.mozillazine.org/Bookmarks.html

  data: function(){
    return {

      showInstructions: true,

      validFilePresent: false,

      bookmarks: [],
      bookmarksToImport: [],

      batchImportProgress: 0,
      batchImportFinished: 0,

      exportFileSource: null,

      error: null,
    };
  },



  methods: {

    checkForPremiumAccount: function(){

      var userIsPro = this.$store.state.user.is_premium;
      //var userIsPro = false;

      if (this.bookmarks.length>2000 && !userIsPro) {

        this.$store.commit('dialogbox', {
          kind: 'profeature',
          subkind: 'import-limit',
          featureCode: 'import-limit',
        });

        return false;
      };

      return true;

    },


    importBatchURLs: function(){

      if (!this.checkForPremiumAccount()) {
        return;
      }

      this.batchImportProgress = true;

      var onlySelectedLinks = this.bookmarks.filter(function(el, i, arr){
        return this.bookmarksToImport.indexOf(i) > -1;
      }.bind(this));


      var links = onlySelectedLinks.map(function(el, i, arr){
        el.importProgress = true;
        return {
          url: el.url,
          title: el.title,
          tags: el.tags,
        };
      });


      links = (links && links.length) ? JSON.stringify(links) : '';

      this.$axios({
        url: this.$store.state.api + '/batch-import/',
        method: 'post',
        timeout: 600000,
        data: {
          links: links,
        },
      }).then(function(response){
        this.importBatchSuccess(response.data);
      }.bind(this), function(error){
        this.$alertify.alert('There was an error while importing URLs. Please reload the page and try again.');
      }.bind(this));

    },


    importBatchSuccess: function(data){

      this.batchImportProgress = false;
      this.batchImportFinished = true;

      this.$alertify.alert('<strong>First step is done. <br> We\'ve successfully imported your links.</strong> <br><br> We\'re now generating their preview data like description, thumbnail & etc. behind the scenes. <br><br> This may take a couple of minutes depending on how many bookmarks you\'ve imported and during that process you may see bookmarks without thumbnail or description, but that will gradually change.<br><br> You may already start organizing your library or exploring other people profiles & libraries.');

      this.setStatus(data);
      this.$store.dispatch('getCatsTags', 1);
    },



    setStatus: function(links){
      links.forEach(function(link, i, arr){
        var bookmark = _find(this.bookmarks, {url: link.url});
        if (bookmark) {
          bookmark.importProgress = false;
          bookmark.importFinished = true;
          bookmark.importAdded = link.added;
          bookmark.importError = link.error;
          bookmark.importExists = link.exists;
        }
      }.bind(this));
    },





    extractData: function(parsedHtml){
      var self = this;

      self.bookmarks = [];
      self.bookmarksToImport = [];

      // get source of exported file...
      if (parsedHtml.title == 'Pocket Export') { this.exportFileSource = 'pocket'; }

      var allAs = parsedHtml.body.querySelectorAll('a');

      __.each(allAs, function(urlEl, index) {


        // get href and title
        var aHref = __.attr(urlEl, 'href');
        var aTitle = urlEl.innerText;

        // do check...
        var passing = true;
        if ( !self.checkUrl(aHref) ) {
          passing = false;
        }

        // check for duplicates
        if ( _find(self.bookmarks, {url: aHref}) ) {
          passing = false;
        }

        if (passing) {

          // start constructing bookmark object...
          var bookmark = {
            title: aTitle,
            url: aHref,
            tags: [],

            importProgress: null, // for later
            importFinished: null, // for later
            importAdded: null, // for later
            importExists: null, // for later
            importError: null, // for later

          };


          // loop <DT> tags
          var urlDts = __.parents(urlEl, 'dt');
          __.each(urlDts, function(urlDtEl, index) {
            bookmark.tags.push( {
              name: __.text(urlDtEl.querySelector('h3')),
              color: 1,
            });
          });

          // account for firefox <DD> descriptions
          var urlDds = __.parents(urlEl, 'dd');
          __.each(urlDds, function(urlDdEl, index) {
            var cats = [];
            var dtEl = __.prev(urlDdEl, 'dt');

            if (dtEl.querySelector) {
              bookmark.tags.push( {
                name: __.text(dtEl.querySelector('h3')),
                color: 1,
              });
            }
          });



          // nest order
          bookmark.tags.reverse();


          // delete empty entries
          _remove(bookmark.tags, {name: ''});
          // delete if value is 'Bookmarks bar' (chrome)
          _remove(bookmark.tags, {name: 'Bookmarks bar'});
          _remove(bookmark.tags, {name: 'Bookmarks Bar'});
          // delete if value is 'Bookmarks Toolbar' (firefox)
          _remove(bookmark.tags, {name: 'Bookmarks Toolbar'});
          // delete if value is 'Favorites' (safari and maybe ie/edge)
          _remove(bookmark.tags, {name: 'Favorites'});
          // delete if value is 'Mozilla Firefox' (firefox)
          _remove(bookmark.tags, {name: 'Mozilla Firefox'});
          // delete if value is 'Other Bookmarks' (firefox)
          _remove(bookmark.tags, {name: 'Other Bookmarks'});
          // ie thing?
          _remove(bookmark.tags, {name: 'Links'});


          // add firefox tags of bookmark
          var firefoxTags = __.attr(urlEl, 'tags');
          if (firefoxTags) {
            firefoxTags = firefoxTags.split(',');
            var firefoxTagsArray = [];
            firefoxTags.forEach(function(el, index){
              firefoxTagsArray.push({
                name: el,
                color: 1,
              });
            });
            bookmark.tags = bookmark.tags.concat(firefoxTagsArray);
          }





          // if pocket is source then cleanup title because pocket title is always url
          if (self.exportFileSource == 'pocket') {
            bookmark.title = null;
          }


          // add import tag
          if (self.exportFileSource) {

            // export file source title
            var efst = self.exportFileSource.split('-');
            var efstTagName = '';
            efst.forEach(function(el, i){
              efstTagName = efstTagName + (el.charAt(0).toUpperCase() + el.slice(1)) + ' ';
            });

            bookmark.tags.unshift({ name: efstTagName + ' Import', color: 1 });
          } else {
            bookmark.tags.unshift({ name: 'HTML Import', color: 1 });
          }



          // remove duplicates of tags
          bookmark.tags = _uniqBy(bookmark.tags, 'name');


          // push proper bookmark object
          self.bookmarks.push(bookmark);


        }




      });



      self.selectAllBookmarks();
      self.checkForPremiumAccount();

    },



    selectAllBookmarks: function(){
      var indexes = [];
      this.bookmarks.forEach(function(el, i, arr){
        indexes.push(i);
      }.bind(this));
      this.bookmarksToImport = indexes;
    },





    parseFile: function(event){
      var self = this;

      this.showInstructions = false;
      this.exportFileSource = null;
      this.validFilePresent = false;
      this.error = null;

      var cleanedResult;
      var parsedResult;

      var file = event.target.files[0]

      if (!self.checkFile(file)) {
        return false;
      }


      // get export source
      if (file.name == 'raindrop.io.html') { this.exportFileSource = 'raindrop'; }
      if (file.name == 'ril_export.html') { this.exportFileSource = 'pocket'; }
      if (file.name == 'instapaper-export.html') { this.exportFileSource = 'instapaper'; }
      if (file.name == 'GoogleBookmarks.html') { this.exportFileSource = 'google-bookmarks'; }


      this.validFilePresent = true;

      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");

      reader.onload = function(e){

        cleanedResult = e.target.result.trim(); // trimming...
        cleanedResult = cleanedResult.replace(/(\r\n|\r|\n)/gm,''); // cleaning new lines...
        cleanedResult = cleanedResult.replace('<!DOCTYPE NETSCAPE-Bookmark-file-1>', '');
        cleanedResult = '<div>' + cleanedResult + '</div>'; // this is here to ensure there's only one root element...

        parsedResult = __.parseHTML( cleanedResult );
        self.extractData(parsedResult);
      }

      reader.onerror = function(e){
        self.error = 'Couldn\'t parse file.';
      }

    },






    checkUrl: function(url){
      if (!url.toLowerCase) {
        return false;
      }

      var url = url.toLowerCase();

      if (
        (url.substring(0,7) !== 'http://') &&
        (url.substring(0,8) !== 'https://')
      ){
        return false;
      }

      return true;
    },




    checkFile: function(file){

      if (!file) {
        this.error = null;
        this.bookmarks = [];
        this.bookmarksToImport = [];
        return false;
      }

      var filename = file.name.toLowerCase();

      // check for extension
      if (
        (filename.slice(-5) !== '.html') &&
        (filename.slice(-4) !== '.htm')
      ){
        this.bookmarks = [];
        this.bookmarksToImport = [];
        this.error = 'Incorrect file type. It must be an .html or .htm file. Choose another file.';
        return false;
      }

      // check for mime type
      if ( file.type !== 'text/html' ) {
        this.bookmarks = [];
        this.bookmarksToImport = [];
        this.error = 'Incorrect file type. It must be an .html or .htm file. Choose another file.';
        return false;
      }

      return true;
    },






  },
};
</script>





<template>
<div class="importhtmlold">

  <router-link to="/settings/import" class="importhtmlold-getback">Return to new import page</router-link>

  <!--
  <div class="importhtmlold-note">


     <strong>This importer is work in progress </strong> &mdash; it may be unstable on large bookmarks files (with more than 500 bookmarks). It will soon be replaced by new &amp; more robust importer that can handle more than 10k bookmarks.  <br> Please bear with us.

  </div> -->

  <div class="importhtmlold-controls-wrapper">

    <div class="importhtmlold-controls">

      <div class="importhtmlold-file-wrapper" v-if="(!batchImportProgress) && (!batchImportFinished)">
        <div class="importhtmlold-file-label" v-if="!bookmarks.length">Please choose .htm or .html file to start importing.</div>
        <input type="file" @change="parseFile($event)">
      </div>

      <div class="importhtmlold-error" v-if="error">{{error}}</div>

    </div>

    <button v-if="bookmarks.length" @click="importBatchURLs" class="common-btn mod-dark"
    :disabled="(batchImportProgress==true) || (batchImportFinished===true)">

      <template v-if="(!batchImportProgress) && (!batchImportFinished)">
        <span class="icon icon-download"></span> Import
      </template>

      <template v-else-if="batchImportProgress">
        <spinner kind="dots"></spinner> &nbsp; Importing...
      </template>

      <template v-else-if="batchImportFinished">
        Importing is finished. You may now navigate to other pages.
      </template>

    </button>


  </div>







  <div class="importhtmlold-plist"  :class="{'is-empty': !bookmarks.length}">



    <!-- Item -->
    <div v-for="(bookmark, index) in bookmarks">

      <div v-if="index<100" class="importhtmlold-plist-item  is-nowrap" :key="bookmark.url+index" tabindex="0"
      :class="{'is-success': bookmark.importAdded, 'is-existing': bookmark.importExists, 'is-error': bookmark.importError}">


        <div class="importhtmlold-side">



          <div v-if="(!batchImportFinished) && (!bookmark.importError) && (!bookmark.importExists) && (!bookmark.importAdded) && (!bookmark.importProgress)"
          class="importhtmlold-input-check  is-blank" >
            <!--
            <input :id="'bookmark'+index" type="checkbox" v-model="bookmarksToImport" :value="index">
            <label :for="'bookmark'+index" title="Include in import" js-tip ></label> -->


            <span class="icon icon-checkmark"></span>
          </div>


          <div v-else-if="bookmark.importProgress"  class="importhtmlold-bookmark-wait">
            <span class="icon icon-hourglass"></span>
          </div>


          <div v-else-if="(!bookmark.importProgress) && bookmark.importAdded" class="importhtmlold-bookmark-success" v-tooltip="'Link successfully added.'">
            <span class="icon icon-checkmark-filled"></span>
          </div>


          <div v-else-if="(!bookmark.importProgress) && bookmark.importExists" class="importhtmlold-bookmark-warning" v-tooltip="'You already had this link in your GGather library.'">
            <span class="icon icon-info"></span>
          </div>


          <div v-else-if="(!bookmark.importProgress) && bookmark.importError" class="importhtmlold-bookmark-error" v-tooltip="'There was an error while importing this link.'">
            <span class="icon icon-error"></span>
          </div>



        </div>

        <div class="importhtmlold-item-content">

          <div class="importhtmlold-plist-content">

            <span class="importhtmlold-url-wrapper">
              <img :src="'https://s2.googleusercontent.com/s2/favicons?domain_url=' + bookmark.url" class="importhtmlold-favicon">
              <a target="_blank" :href="bookmark.url" class="importhtmlold-url">{{bookmark.title ? bookmark.title : 'Untitled Bookmark'}}</a>
            </span>

          </div>

          <div class="importhtmlold-tags" v-if="bookmark.tags.length">
            Will be added with tag{{bookmark.tags.length>1 ? 's' : ''}} &ndash;
            <span class="importhtmlold-tag" :class="'is-color-'+category.color" v-for="category in bookmark.tags">
              <span v-if="category.is_private" class="icon icon-lock-1"></span> {{category.name}}
            </span>
          </div>

        </div>




      </div>

      <div v-else-if="index==100">

        <br><br><br>
        <h2>And {{ (bookmarks.length-100) }} more...</h2>
        <!-- <p>{{bookmark.title}} ({{bookmark.url}})</p> -->
      </div>

    </div>
    <!-- / Item -->



  </div>






  <div class="importhtmlold-instructions"  v-if="showInstructions">


    <div class="importhtmlold-instruction-section"><div>


      <h4><span class="icon icon-cloud-filled"></span> Exporting bookmarks from other services</h4>

      <p>Almost all of the bookmarking apps and services have option to export your bookmarks to .html format which makes possible to switch to GGather without hassle.</p>

      <p>
        <ul>
          <li><a target="_blank" href="https://getpocket.com/export">Pocket <span class="icon icon-forward"></span> Export HTML file</a></li>
          <li><a target="_blank" href="https://www.instapaper.com/user">Instapaper <span class="icon icon-forward"></span> Download .HTML file</a></li>
          <li><a target="_blank" href="http://raindrop.io/app/#/settings/export">Raindrop <span class="icon icon-forward"></span> Send email</a></li>
          <li><a target="_blank" href="https://www.dropmark.com/support/tips/export-your-data/">Dropmark</a></li>
          <li><a target="_blank" href="https://helpdesk.xmarks.com/bookmark-manager-basics/import-and-export/">Xmarks</a></li>
          <li><a target="_blank" href="https://pinboard.in/howto/#export">Pinboard</a></li>
          <li><a target="_blank" href="https://www.google.com/bookmarks/">Google Bookmarks</a></li>
          <li>And more...</li>


        </ul>
      </p>


    </div></div>
    <div class="importhtmlold-instruction-section"><div>

    <h4><span class="icon icon-chrome-filled"></span> Exporting bookmarks from Google Chrome</h4>

    <p>
      <ol>
        <li>Go to your bookmarks manager (CTRL+SHIFT+B or ⌘+OPTION+B)</li>
        <li>Open "Organize" dropdown</li>
        <li>Click "Export bookmarks to HTML file..."</li>
      </ol>
    </p>


    </div></div>
    <div class="importhtmlold-instruction-section"><div>


    <h4><span class="icon icon-firefox-filled"></span> Exporting bookmarks from Mozilla Firefox</h4>

    <p>
      <ol>
        <li>Go to your bookmarks library (CTRL+SHIFT+B or ⌘+OPTION+B)</li>
        <li>Open "Import and Backup" dropdown</li>
        <li>Click "Export Bookmarks to HTML..."</li>
      </ol>
    </p>


    </div></div>
    <div class="importhtmlold-instruction-section"><div>


    <h4><span class="icon icon-compass-filled"></span> Exporting bookmarks from Safari</h4>

    <p>
      <ol>
        <li>Open your "File" menu</li>
        <li>Click "Export Bookmarks..."</li>
      </ol>
    </p>



    </div></div>
    <div class="importhtmlold-instruction-section"><div>



    <h4><span class="icon icon-internet-explorer-filled"></span> Exporting bookmarks from Internet Explorer 11</h4>

    <p>
      <ol>
        <li>Open your favorites (button with star icon)</li>
        <li>Open dropdown right next to "Add to favorites"</li>
        <li>Click "Import and export"</li>
        <li>Select "Export to a file"</li>
        <li>In next step select "Favorites"</li>
        <li>In next step select which bookmarks folders you want to export</li>
        <li>In final step choose folder where you want your file and click on "Export"</li>
      </ol>
    </p>




    </div></div>
    <div class="importhtmlold-instruction-section"><div>



    <h4><span class="icon icon-info-filled"></span> Note about Microsoft Edge &amp; Opera</h4>

    <p>Unfortunately these browsers do not support exporting bookmarks natively - although in the case of Opera there's a possibility to use 3rd party browser plugin.</p>

    </div></div>



  </div>






</div>
</template>




<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .importhtmlold-getback.importhtmlold-getback.importhtmlold-getback {
    display: block;
    padding: 21px 25px 20px 25px;
    color: rgba(white,1);
    background: orange;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 25px;
    border-radius: 3px;
    font-size: 17px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: 0 5px 15px 0 rgba(black,.2);

    &:hover,&:focus {
      box-shadow: 0 5px 25px 0 rgba(black,.3);
    }


    html.mod-transitions-enabled & {
      transition: all 200ms ease-out;
    }

  }

  .importhtmlold {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;


    h1 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1.2;
      .icon::before {
        line-height: 1.2;
      }
    }

    h2 {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1.2;
      .icon::before {
        line-height: 1.2;
      }
    }


    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 10px;
      line-height: 1.2;
      .icon::before {
        line-height: 1.2;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 7px;
      line-height: 1.2;
      .icon::before {
        line-height: 1.2;
      }
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 7px;
      line-height: 1.2;
      .icon::before {
        line-height: 1.2;
      }
    }


    h6 {
      font-size: 17px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 7px;
      line-height: 1.2;
      .icon::before {
        line-height: 1.2;
      }
    }


    p {
      font-size: 15px;
      margin-top: 20px;
    }

    ul, ol {
      margin-top: 10px;
      margin-left: 30px;
      font-weight: 400;

      ul, ol {
        margin-top: 0;
      }
    }

    li {
      font-size: 15px;
      font-weight: 400;
    }

    section {
      margin-bottom: 100px;
    }

    a {
      color: $clr-ggather;
    }

    hr {
      display: block;
      width: 100px;
      margin-top: 30px;
      margin-bottom: 30px;
      background-color: #eee;
      height: 1px;
    }


  }

  .importhtmlold-note {
    margin-bottom: 30px;
  }


  .importhtmlold-instructions {
    //margin-top: 15px;
    padding-top: 0px;
    //border-top: 1px solid #eee;
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    //align-items: stretch;
    //align-content: stretch;
    //justify-content: stretch;
  }

  .importhtmlold-instruction-section {
    background-color: white;
    display: inline-block;
    width: 100%;
    //width: 50%;
    //width: calc(50% - 20px);
    padding: 20px;
    //margin-right: 20px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid #eee;

    //min-height: 200px;

    h4 {
      margin: 0;
    }
  }

  .importhtmlold-import-status {

    border-radius: 2px;
    display: inline-block;
    overflow: hidden;
    font-weight: 500;

    padding: 8px 15px 7px 15px;
    background-color: $clr-light-blue-100;
    border: 1px solid $clr-light-blue-200;
    color: $clr-light-blue-800;
    font-size: 14px;

    position: fixed;
    top: 6px;
    right: 6px;
    z-index: 200;

    &.is-finished {
      background-color: $clr-light-green-100;
      border: 1px solid $clr-light-green-200;
      color: $clr-light-green-800;
    }

  }


  .importhtmlold-file-wrapper {
    background-color: $clr-ggather;
    background-color: darken(white,65);
    border: 1px solid darken($clr-ggather,5);
    border: 1px solid darken(white,68);
    border-radius: 3px;
    padding: 12px 20px 10px 10px;
    padding: 22px 35px 20px 25px;

    .importhtmlold-file-label {
      font-weight: 500;
      color: white;
      line-height: 1;
      padding-bottom: 5px;
    }

    input[type="file"]{
      color: white;
    }

  }


  .importhtmlold-json-textarea {
    border: 1px solid rgba(white,.2);
    border-radius: 2px;
    color: rgba(white,.9);
    padding: 5px 10px;

    &:focus {
      background-color: rgba(white,.1);
    }
  }


  .importhtmlold-controls-wrapper {
    //min-height: 90px;
  }

  .importhtmlold-controls {

    display: block;
  }



  .importhtmlold-plist {
    margin-bottom: 100px;
    margin-top: 15px;
    padding-top: 10px;
    //border-top: 1px solid #eee;

    &.is-empty {
      display: none;
    }


    &.is-nowrap {
      white-space: nowrap;
    }
  }



    .importhtmlold-plist-item {

      background-color: lighten(#eee,5);
      border: 1px solid #eee;
      border-radius: 2px;

      display: block;
      border-bottom: 1px solid #eee;
      overflow: hidden;
      outline: none;
      min-height: 33px;
      margin-bottom: 10px;

      font-size: 0;

      position: relative;


      &:focus {
        background-color: lighten(#eee,4.5);
      }


      &.is-success {
        background-color: $clr-light-green-100;
        border-color: $clr-light-green-200;
      }

      &.is-existing {
        background-color: $clr-amber-100;
        border-color: $clr-amber-200;
      }

      &.is-error {
        background-color: $clr-red-100;
        border-color: $clr-red-200;
      }

      //&:last-child {
      //    border-bottom: none;
      //}

    }





      .importhtmlold-plist-content {
        display: inline-block;
        padding: 7px 10px 5px 10px;
        font-size: 14px;
        vertical-align: middle;
        width: 100%;
      }


      .importhtmlold-plist-sep {
        display: inline-block;
        min-height: 32px;
        width: 1px;
        background-color: #eee;

      }


      .importhtmlold-tags {
        display: inline-block;
        padding: 7px 10px 5px 7px;
        font-size: 14px;
        vertical-align: middle;
        width: 100%;
        border-top: 1px solid #eee;
        color: #626262;
        font-size: 12px;

        .importhtmlold-plist-item.is-success & {
          border-color: $clr-light-green-200;
        }
        .importhtmlold-plist-item.is-existing & {
          border-color: $clr-amber-200;
        }
        .importhtmlold-plist-item.is-error & {
          border-color: $clr-red-200;
        }

      }





      .importhtmlold-tag {
        font-size: 11px;
        display: inline-block;
        border: 1px solid rgba(black,.04);
        background-color: rgba(black,.04);
        border-radius: 2px;
        padding: 1px 2px;
        line-height: 1;
        margin-right: 2px;
        margin-bottom: 2px;
        color: rgba(black,.9);
      }




    .importhtmlold-side {
      display: inline-block;
      height: 100%;
      width: 50px;
      font-size: 14px;
      border-right: 1px solid #eee;
      //padding-top: 7px;
      padding-right: 5px;
      padding-left: 5px;

      //padding-top: 24px;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;


      .importhtmlold-plist-item.is-success & {
        border-color: $clr-light-green-200;
      }
      .importhtmlold-plist-item.is-existing & {
        border-color: $clr-amber-200;
      }
      .importhtmlold-plist-item.is-error & {
        border-color: $clr-red-200;
      }

    }



      .importhtmlold-bottom-status-wrapper {
        display: block;
        font-size: 0;
        line-height: 0;
      }

      .importhtmlold-preview-spinner {
        display: block;
        margin-top: 5px;
      }







      .importhtmlold-url-wrapper {
        display: inline-block;
        margin-top: 2px;
        width: 100%;
        overflow: hidden;
      }


    .importhtmlold-item-content {
      display: inline-block;
      width: calc(100% - 50px);
      margin-left: 50px;
      font-size: 14px;
    }




    .importhtmlold-url.importhtmlold-url {
      font-weight: 500;
      display: inline-block;
      color: #03a9f4;
      max-width: calc(100% - 30px);
      overflow: hidden;
    }


    .importhtmlold-favicon {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 5px;
    }


    .importhtmlold-error {
      background-color: #eee;
      border-radius: 2px;
      display: block;
      margin-top: 10px;
      //margin-left: 10px;
      overflow: hidden;
      font-weight: 500;

      padding: 8px 15px 7px 15px;
      background-color: $clr-red-100;
      border: 1px solid $clr-red-200;
      border-color: lighten($clr-red-200, 8);
      color: $clr-red-800;
      font-size: 14px;
    }



    .importhtmlold-success {
      background-color: #eee;
      border-radius: 2px;
      display: inline-block;
      margin-top: 10px;
      overflow: hidden;
      font-weight: 500;

      padding: 8px 15px 7px 15px;
      background-color: $clr-light-green-100;
      border: 1px solid $clr-light-green-200;
      color: $clr-light-green-800;
      font-size: 14px;
    }



    .importhtmlold-bookmark-wait {
      .icon {
        font-size: 18px;
        color: $clr-light-blue-500;

        &::before {
          line-height: 1.4;
        }
      }
    }


    .importhtmlold-bookmark-success {
      .icon {
        font-size: 18px;
        color: $clr-light-green-500;

        &::before {
          line-height: 1.4;
        }
      }
    }

    .importhtmlold-bookmark-warning {
      .icon {
        font-size: 18px;
        color: $clr-amber-500;

        &::before {
          line-height: 1.4;
        }
      }
    }
    .importhtmlold-bookmark-error {
      .icon {
        font-size: 18px;
        color: $clr-red-500;

        &::before {
          line-height: 1.4;
        }
      }
    }













    .importhtmlold-input-check {

      display: inline-block;

      input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        cursor: pointer;
      }

      label {
        color: rgba($clr-light-blue,.7);
        cursor: pointer;
        position: relative;
        padding-left: 24px;
        user-select: none;

        //background
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 1px;
          left: 1px;
          width: 15px;
          height: 15px;
          background: rgba($clr-light-blue,.2);
          border-radius: 1px;
          html.mod-transitions-enabled & {
            transition: background-color 80ms ease-out;
          }
        }

        //checkmark
        &::after {
          //@include ggather-font;
          font-family: 'ggather';
          line-height: 1;
          content: '\e018';
          display: inline-block;
          position: absolute;
          font-size: 11px;
          top: 3px;
          left: 3px;
          opacity: 0;
          html.mod-transitions-enabled & {
            transition: opacity 80ms ease-out;
          }
        }

      }

      input:checked + label {
        color: rgba(white,.9);
        font-weight: 500;

        &::before {
          background: rgba($clr-light-blue,.9);
        }

        &::after {
          opacity: 1;
        }

      }


      input:disabled + label {
        cursor: default;
        opacity: .5;
      }



      &.is-blank {
        label {
          padding-left: 16px;
        }
      }


    }



    @media (max-width: 1100px) {

      .importhtmlold-instructions {
        display: block;
      }
      .importhtmlold-instruction-section {
        display: block;
        width: auto;
        margin-right: 0;
        min-height: 0;
      }
    }




</style>




<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .importhtmlold-instruction-section {
    background-color: darken(white,70);
    border-color: darken(white,60);
    color: rgba(white,.8);
    p {
      color: rgba(white,.8);
    }
    & /deep/ svg path {
      fill: rgba(white,.6);
    }
  }


}
</style>
