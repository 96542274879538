<script>
export default {


  props: [
    'url',
    'urlbookmark',
    'urldata',
    'loading',
  ],


  data: function(){
    return {
      fieldValues: {
        title: null,
        description: null,
        url: null,
      },
      fieldEdit: null,
    }
  },


  computed: {

    visibleTitle: function(){
      if (this.urlbookmark && this.urlbookmark.title) {
        return this.urlbookmark.title;
      }
      else if (this.urldata && this.urldata.title) {
        return this.urldata.title;
      }
      else { return ''; }
    },

    visibleDescription: function(){
      if (this.urlbookmark && this.urlbookmark.description) {
        return this.urlbookmark.description;
      }
      else if (this.urldata && this.urldata.description) {
        return this.urldata.description;
      }
      else { return ''; }
    },

    visibleURL: function(){
      if (this.urlbookmark && this.urlbookmark.url) {
        return this.urlbookmark.url;
      }
      else if (this.urldata && this.urldata.url) {
        return this.urldata.url;
      }
      else if (this.url) {
        return this.url;
      }
      else { return ''; }
    },


    placeholderTitle: function(){
      if (this.urldata && this.urldata.title) {
        return this.urldata.title;
      } else { return ''; }
    },

    placeholderDescription: function(){
      if (this.urldata && this.urldata.description) {
        return this.urldata.description;
      } else { return ''; }
    },

    placeholderURL: function(){
      if (this.urldata && this.urldata.url) {
        return this.urldata.url;
      }
      else if (this.url) {
        return this.url;
      }
      else { return ''; }
    },

  },


  watch: {

    urlbookmark: function(){
      this.resetFieldValues();
    },

  },


  created: function(){
    this.resetFieldValues();
  },



  methods: {

    resetFieldValues: function(){

      if (this.urlbookmark && this.urlbookmark.title) {
        this.fieldValues.title = this.urlbookmark.title;
      } else if (this.urldata && this.urldata.title) {
        this.fieldValues.title = this.urldata.title;
      } else {
        this.fieldValues.title = '';
      }

      if (this.urlbookmark && this.urlbookmark.description) {
        this.fieldValues.description = this.urlbookmark.description;
      } else if (this.urldata && this.urldata.description) {
        this.fieldValues.description = this.urldata.description;
      } else {
        this.fieldValues.description = '';
      }

      if (this.urlbookmark && this.urlbookmark.url) {
        this.fieldValues.url = this.urlbookmark.url;
      } else if (this.urldata && this.urldata.url) {
        this.fieldValues.url = this.urldata.url;
      } else {
        this.fieldValues.url = this.url;
      }


    },


    cancelFieldEdit: function(){
      this.fieldEdit = null;
      this.resetFieldValues();
    },


    openFieldEdit: function(kind){

      this.fieldEdit = kind;

      if (false) {} // noop

      else if (kind=='title') {
        this.$nextTick(function(){ this.$refs.fieldTitle.focus() });
      }

      else if (kind=='description') {
        this.$nextTick(function(){ this.$refs.fieldDescription.focus() });
      }

      else if (kind=='url') {
        this.$nextTick(function(){ this.$refs.fieldURL.focus() });
      }

    },




    resetToDefault: function(kind){

      if (false) {} // noop

      else if (kind=='title') {
        this.fieldValues.title = '';
      }
      else if (kind=='description') {
        this.fieldValues.description = '';
      }

      this.emitEdit(kind);
    },



    emitEdit: function(kind){

      // if nothing changed...
      if (kind=='title' && (this.fieldValues.title===this.visibleTitle)) {
        this.fieldEdit = null;
        return;
      };
      if (kind=='description' && (this.fieldValues.description===this.visibleDescription)) {
        this.fieldEdit = null;
        return;
      };
      if (kind=='url' && (this.fieldValues.url===this.visibleURL)) {
        this.fieldEdit = null;
        return;
      };


      // else...
      this.$emit('edit', {
        kind: kind,
        value: this.fieldValues[kind],
      });
      this.fieldEdit = null;

    },


  }

};
</script>


<template>
<div class="savepreview-preview">


  <!-- Thumbnail -->
  <div class="savepreview-thumbnail">
    <div v-if="loading" class="savepreview-loading-pulser mod-thumbnail"><div></div></div>
    <savethumb v-else
      :url="url"
      :urlbookmark="urlbookmark"
      :urldata="urldata"
    />
  </div>


  <div class="savepreview-next-to-thumbnail">








    <!-- Title -->
    <div class="savepreview-field-wrapper mod-title">


      <!-- Preview... -->

      <div v-if="loading" class="savepreview-loading-pulser"><div></div></div>

      <textarea v-autosize v-if="fieldEdit=='title'" ref="fieldTitle" rows="1" class="savepreview-field mod-title" :class="{'mod-edit': fieldEdit=='title'}" maxlength="300" v-model="fieldValues.title" :placeholder="placeholderTitle" @focus="$refs.fieldTitle.setSelectionRange(0, 999999)"
      @keypress.enter.prevent="emitEdit('title')" @keyup.esc.prevent="cancelFieldEdit"></textarea>

      <div v-else class="savepreview-field mod-title" :class="{'mod-no-title': !visibleTitle}">{{visibleTitle ? visibleTitle : 'No Title'}}</div>


      <!-- Edit... -->

      <button v-if="urlbookmark && (!fieldEdit)" type="button" v-show="!loading"
      class="savepreview-field-edit  mod-title" @click.prevent="openFieldEdit('title')">
        <span class="text">Edit Title</span>
        <span class="icon icon-edit-filled"></span>
      </button>

      <div v-if="urlbookmark && (fieldEdit=='title')" class="savepreview-field-btns">
        <button class="savepreview-field-btn" type="button" @click.prevent="resetToDefault('title')" v-tooltip="'Reset to default value...'">Reset</button>
        <button class="savepreview-field-btn" type="button" @click.prevent="cancelFieldEdit">Cancel</button>
        <button class="savepreview-field-btn" type="button" @click.prevent="emitEdit('title')">Save</button>
      </div>

    </div>





    <!-- Description -->
    <div class="savepreview-field-wrapper">

      <!-- Preview... -->

      <div v-if="loading" class="savepreview-loading-pulser"><div></div></div>

      <textarea v-autosize v-if="fieldEdit=='description'" ref="fieldDescription" rows="1" class="savepreview-field" :class="{'mod-edit': fieldEdit=='description'}" v-model="fieldValues.description" maxlength="300" :placeholder="placeholderDescription" @focus="$refs.fieldDescription.setSelectionRange(0, 999999)"
      @keypress.enter.prevent="emitEdit('description')" @keyup.esc.prevent="cancelFieldEdit" ></textarea>

      <div v-else class="savepreview-field  mod-description" :class="{'mod-no-description': !visibleDescription}">{{visibleDescription ? visibleDescription : 'No Description'}}</div>


      <!-- Edit... -->

      <button v-if="urlbookmark && (!fieldEdit)" type="button" v-show="!loading"
      class="savepreview-field-edit mod-description" @click.prevent="openFieldEdit('description')">
        <span class="text">Edit Description</span>
        <span class="icon icon-edit-filled"></span>
      </button>

      <div v-if="urlbookmark && (fieldEdit=='description')" class="savepreview-field-btns">
        <button class="savepreview-field-btn" type="button" @click.prevent="resetToDefault('description')" v-tooltip="'Reset to default value...'">Reset</button>
        <button class="savepreview-field-btn" type="button" @click.prevent="cancelFieldEdit">Cancel</button>
        <button class="savepreview-field-btn" type="button" @click.prevent="emitEdit('description')">Save</button>
      </div>


    </div>






    <!-- URL -->
    <div class="savepreview-field-wrapper mod-url"
    :class="{'mod-editing': fieldEdit=='url'}">

      <!-- Preview... -->

      <div v-if="loading" class="savepreview-loading-pulser"><div></div></div>

      <span v-else>

        <div class="savepreview-url-favicon" v-if="fieldEdit!='url'">
          <img :src="'https://s2.googleusercontent.com/s2/favicons?domain_url=' + visibleURL">
        </div>

        <textarea v-autosize v-if="fieldEdit=='url'" ref="fieldURL" rows="1" class="savepreview-field  mod-new-url" required :class="{'mod-edit': fieldEdit=='url'}" v-model="fieldValues.url" maxlength="4000" :placeholder="placeholderURL"
        @keypress.enter.prevent="emitEdit('url')" @keyup.esc.prevent="cancelFieldEdit"></textarea>

        <a v-else :href="visibleURL" target="_blank" class="savepreview-url">{{visibleURL}}</a>


        <!-- Edit... -->

        <button v-if="urlbookmark && (!fieldEdit)" type="button" v-show="!loading"
        class="savepreview-field-edit  mod-url" @click.prevent="openFieldEdit('url')">
          <span class="text">Change URL</span>
          <span class="icon icon-edit-filled"></span>
        </button>

        <div v-if="urlbookmark && (fieldEdit=='url')" class="savepreview-field-btns">
          <button class="savepreview-field-btn" type="button" @click.prevent="cancelFieldEdit">Cancel</button>
          <button class="savepreview-field-btn" type="button" @click.prevent="emitEdit('url')">Save</button>
        </div>


      </span>

    </div>













  </div>




</div>
</template>


<style lang="scss" scoped>


  @import "../../../common/style/_variables.scss";



  .savepreview-preview {
    font-size: 0;
    //border-top: 1px solid darken(#eee,1);
    border: 1px solid darken(#eee,1);
    border-left: none;
    border-bottom: none;
    border-top: none;
    background-color: white;
    border-radius: 0;
    overflow: hidden;

    display: flex;
    align-items: stretch;
    align-content: stretch;

    @media (max-width: 800px) {
      display: block;
    }

  }



      .savepreview-thumbnail {
        border-radius: 2px 0 0 2px;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        width: 230px;
        background-color: #111;
        //border-right: 1px solid darken(#eee,2);
        //padding: 5px;
        //height: 150px;
        //height: 100%;


        //img {
        //    position: absolute;
        //    top: 50%;
        //    transform: translateY(-50%);
        //    display: block;
        //    width: 100%;
        //}

        @media (max-width: 800px) {
          display: block;
          width: auto;
          height: 200px;
        }


      }




    .savepreview-next-to-thumbnail {
      display: inline-block;
      width: 70%;
      width: calc(100% - 230px);
      padding-left: 10px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 2px;

      @media (max-width: 800px) {
        display: block;
        width: auto;
        border-left: 1px solid darken(#eee,1);

      }

    }






    .savepreview-field-wrapper {
      position: relative;
      margin-bottom: 5px;
      //min-height: 20px;

      &.mod-url {
        white-space: nowrap;
        overflow: hidden;
      }

    }

      .savepreview-field.savepreview-field {
        font-weight: 400;
        font-size: 14px;
        display: block;
        line-height: 1.15;
        width: 100%;
        max-width: none;
        min-width: 0;
        padding: 5px 10px 3px 10px;
        //min-height: 30px;
        border-radius: 2px;
        resize: none;
        border-radius: 2px;
        border: 1px solid transparent;
        outline: none;
        //overflow: hidden;
        //background-color: rgba(black,.04);

        //&:hover {
        //    background-color: rgba(black,.06);
        //}


        &.mod-title {
          font-size: 18px;
          font-weight: 500;
          //max-height: 44px;
          overflow: hidden;
        }

        &.mod-new-url {
          display: inline-block;
          font-size: 12px;
          //min-height: 0;
          padding: 7px 9px;
          padding-left: 39px;
        }

        &.mod-description {
          color: rgba(black,.4);
          //max-height: 37px;
          overflow: hidden;
        }

        &.mod-no-description {
          font-style: italic;
          color: rgba(black,.5);
        }

        &.mod-no-title {
          font-style: italic;
          color: rgba(black,.5);
        }


        &.mod-edit {
          background-color: rgba(black,.01);
          border-color: rgba(black,.04);
          padding: 5px 10px;
          //max-height: none;
          overflow: auto;

          &:focus {
            background-color: rgba(black,.04);
            border-color: rgba(black,.08);
          }
        }


        &::placeholder {
          color: rgba(black,.1);
        }


      }





      .savepreview-field-edit {
        position: absolute;
        display: inline-block;
        top: 2px;
        right: 0;
        z-index: 20;
        padding: 2px 8px;
        padding-right: 5px;
        padding-left: 4px;
        font-size: 11px;
        border-radius: 2px;
        //border: 1px solid transparent;
        color: rgba(#111,.2);
        background-color: white;
        opacity: 1;
        outline: none;
        white-space: nowrap;

        transform: rotate3d(0,0,0,0);
        backface-visibility: hidden;

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out, color 100ms ease-out, background-color 100ms ease-out;
        }

        //pointer-events: none;
        //user-select: none;

        &.mod-title {
          //top: 4px;
        }

        &.mod-description {
          //top: 3px;
        }

        &.mod-url {
          //top: 3px;
        }


        & > .icon::before {
          line-height: 1.4;
        }

        & > .text {

          html.mod-transitions-enabled & {
            transition: width 100ms ease-in-out;
          }
          //display: none;
          margin-right: 0px;
          width: 0px;
          display: inline-block;
          overflow: hidden;
        }



        .savepreview-field-wrapper:hover & {
        //.savepreview-field:focus + & {
          background-color: #777;
          color: white;
          padding-left: 8px;
          //border-color: darken(#777, 5);

          box-shadow: 0 1px 3px 0 rgba(black,.4);

          & > .text {
            margin-right: 5px;
          }

          &.mod-title > .text {
            width: 48px;
          }
          &.mod-description > .text {
            width: 83px;
          }
          &.mod-url > .text {
            width: 66px;
          }

          &:hover {
            background-color: #333;
            border-color: darken(#333, 5);
          }
        }

        .savepreview-field.mod-edit + & {
          & > .text {
          }
        }

      }



      .savepreview-field-btns {
        display: block;
        position: relative;
        text-align: right;
      }

      .savepreview-field-btn {
        display: inline-block;

        text-transform: uppercase;

        font-size: 10px;
        background-color: #333;
        border: 1px solid #111;
        outline: none;
        color: rgba(white,.6);
        font-weight: 500;
        padding: 1px 4px;
        border-radius: 2px;
        z-index: 10;

        margin-left: 5px;
        margin-top: 5px;
        //box-shadow: 0 1px 3px 0 rgba(black,.4);

        &:hover {
          color: rgba(white,.9);
        }
      }



      .savepreview-url-favicon {
        display: inline-block;
        z-index: 10;
        position: absolute;
        left: 12px;
        top: 6px;
        pointer-events: none;

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        opacity: .3;
        .savepreview-field-wrapper:hover & {
          opacity: 1;
        }

      }


      .savepreview-url {
        display: inline-block;
        font-size: 12px;
        padding: 6px 10px;
        padding-left: 37px;
        font-weight: 400;
        color: rgba(black,1);
        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out;
        }

        opacity: .3;
        .savepreview-field-wrapper:hover & {
          opacity: 1;
        }
      }











    /*
    @keyframes savepreview-loading-pulser-pulse {
      0% {
        left: -200%;
      }
      100% {
        left: 200%;
      }
    }

    @keyframes savepreview-loading-pulser-pulse-thumbnail {
      0% {
        left: -300%;
      }
      100% {
        left: 300%;
      }
    }
    */


    .savepreview-loading-pulser {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;

      & > div {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: darken(white,10);
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0px;
          height: 100%;
          background-color: darken(white,15);
          //box-shadow: 0 0 200px 100px darken(white,15);

          //animation-name: savepreview-loading-pulser-pulse;
          //animation-duration: 1000ms;
          //animation-fill-mode: forwards;
          //animation-iteration-count: infinite;
          //animation-timing-function: linear;
        }
      }

      &.mod-thumbnail {
        & > div::before {
          //animation-name: savepreview-loading-pulser-pulse-thumbnail;
          //animation-delay: -150ms;
        }
      }

    }










</style>


<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .savepreview-preview {
    background-color: darken(white,68);
    border-color: darken(white,63);
  }



  .savepreview-field.savepreview-field.mod-title {
    color: rgba(white,.9);
  }
  .savepreview-field.savepreview-field.mod-description {
    color: rgba(white,.5);
  }

  .savepreview-field.savepreview-field.mod-edit {
    background-color: rgba(white,.1);
    border-color: rgba(white,.1);
    color: rgba(white,.5);
    &::selection {
      background: rgba(white,.5);
    }
  }

  .savepreview-field-edit {
    background-color: darken(white,68);
  }

  .savepreview-url {
    color: rgba(white,.9);
  }






  .savepreview-loading-pulser {

    & > div {
      background-color: darken(white,60);

      &::before {
        background-color: darken(white,65);
        //box-shadow: 0 0 200px 100px darken(white,65);
      }
    }

  }









}
</style>
