<script>
export default {

  name: 'page-upgrade',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },


};
</script>

<template>
<div class="page-upgrade">


  <div v-if="$store.state.user.is_authenticated" class="page-upgrade-back-btn-wrapper">
    <router-link to="/all" class="page-upgrade-back-btn">
      ← Back to My Library
    </router-link>
  </div>



  <div class="page-upgrade-pricing-wrapper">
    <pricingwrapper />
  </div>

</div>
</template>

<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";
  .page-upgrade {
    font-size: 20px;
    color: black;
    padding: 0 20px 20px 20px;
  }
  .page-upgrade-pricing-wrapper {
    margin: 0 auto;
  }
  .page-upgrade-back-btn-wrapper {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    margin-top: 25px;
    @media (max-width: 670px) {
      padding: 0 5px;
      max-width: 650px;
      margin-bottom: 10px;
    }
  }


  .page-upgrade-back-btn {
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: block;
    text-align: left;
    outline: none;
    width: 100%;
    color: darken(white,90);

  }

</style>


<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .page-upgrade-back-btn {
    color: darken(white,20);
  }

}
</style>
