// https://github.com/DominikSerafin/vuebar/blob/development/vuebar.js

function getNativeScrollbarSize(container) {
  var container = container ? container : document.body;

  var fullWidth = 0;
  var barWidth = 0;

  var wrapper = document.createElement('div');
  var child = document.createElement('div');

  wrapper.style.display = 'block';
  wrapper.style.boxSizing = 'content-box';
  wrapper.style.position = 'absolute';
  wrapper.style.pointerEvents = 'none';
  wrapper.style.opacity = '0';
  wrapper.style.bottom = '0';
  wrapper.style.right = '0';
  wrapper.style.width = '100px';
  wrapper.style.height = '50px';
  wrapper.style.overflow = 'hidden';

  child.style.height = '0px';

  wrapper.appendChild(child);
  container.appendChild(wrapper);

  fullWidth = child.offsetWidth;

  wrapper.style.overflowY = 'scroll';

  // fix for safari https://github.com/DominikSerafin/vuebar/pull/45
  child.style.width = '100%';
  child.style.height = '100px';

  barWidth = fullWidth - child.offsetWidth;

  container.removeChild(wrapper);

  return barWidth;
}


export default getNativeScrollbarSize;
