<script>
import Vue from 'vue';
export default {

  name: 'page-starship',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },

  data: function(){
    return {
      starship: null,
      popoverText: false,
      popoverAdvanced: false,


      popoverMouse: {
        show: false,
        anchor: null,
      }

    };
  },

  /*
  beforeRouteEnter: function(to, from, next){

    Vue.axios({
      url: 'https://swapi.co/api/starships/9/',
      method: 'get',
    }).then(function(response){
      next(function(vm){
        vm.starship = response.data;
      });
    }.bind(this));

  },
  */

  mounted: function(){


  },


  methods: {

    getStarship: function(){
      this.$axios({
        url: 'https://swapi.co/api/starships/9/',
        method: 'get',
        headers: this.$axios.default.headers,
      }).then(function(response){
        this.starship = response.data;
      }.bind(this));
    },


    popoverMouseToggle: function(event){
      this.popoverMouse.show = true;
      this.popoverMouse.anchor = event;
    },


    popoverChildClick: function(){
      alert(this.starship);
    },

  }

};
</script>


<template>
<div class="router-page">
<div class="debug">


  <div>


    <router-link to="/https://www.youtube.com/watch?v=pB9SsI6jZtI&lol=true#hahaha" class="common-btn">
      <span class="text">https://www.youtube.com/watch?v=pB9SsI6jZtI&lol=true#hahaha</span>
    </router-link>


    <router-link to="/youtube.com/watch?v=pB9SsI6jZtI&lol=true#hahaha" class="common-btn">
      <span class="text">youtube.com/watch?v=pB9SsI6jZtI&lol=true#hahaha</span>
    </router-link>


    <router-link to="/https://www.youtube.com/" class="common-btn">
      <span class="text">https://www.youtube.com/</span>
    </router-link>

    <router-link to="/www.youtube.com" class="common-btn">
      <span class="text">www.youtube.com</span>
    </router-link>

    <router-link to="/youtube.com" class="common-btn">
      <span class="text">youtube.com</span>
    </router-link>


    <router-link to="/kjsdsak" class="common-btn">
      <span class="text">kjsdsak</span>
    </router-link>


    <router-link to="/23451" class="common-btn">
      <span class="text">23451</span>
    </router-link>

  </div>

  <br><br>



  <button type="button"  class="common-btn" @click.prevent="getStarship">Get Starship</button>

  <div class="starship" v-if="starship">
    <h1>Name</h1>
    <p>{{starship.name}}</p>

    <h1>Model</h1>
    <p>{{starship.model}}</p>

    <h1>Manufacturer</h1>
    <p>{{starship.manufacturer}}</p>

    <h1>Starship Class</h1>
    <p>{{starship.starship_class}}</p>

    <h1>URL</h1>
    <p>{{starship.url}}</p>
  </div>


  <br><br>

  <button type="button"  class="common-btn" @click.prevent="popoverText=!popoverText">Show/Hide Text Popover</button>

  <popover v-if="popoverText">Hello this is popover</popover>






  <br><br>

  <button type="button"  class="common-btn" @click.prevent="popoverAdvanced=!popoverAdvanced">Show/Hide Advanced Popover</button>

  <popover v-if="popoverAdvanced">
    <div>
      <button @click="popoverChildClick">Test those events boi</button>
    </div>
  </popover>






  <br><br>



  <button type="button"  class="common-btn" @click.prevent="popoverMouseToggle">Show/Hide Mouse Popover</button>

  <popover v-if="popoverMouse.show" :anchor="popoverMouse.anchor">
    <div>
      <button @click="popoverChildClick">Test those events boi</button>
    </div>
  </popover>





  <br><br>
  <br><br>


  <portal>I should be appended to body!</portal>




</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .debug {
    padding: 20px;
    padding-top: 55px;
    font-size: 16px;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    margin-bottom: 20px;
  }

  .scrollbar-test-1a {
    vertical-align: top;
    width: 400px;
    height: 150px;
    display: inline-block;
    font-size: 15px;
    background-color: orange;
  }

  .scrollbar-test-1b {
    vertical-align: top;
    width: 600px;
    height: 150px;
    display: inline-block;
    font-size: 15px;
    background-color: green;
  }

  .scrollbar-test-1c {
    vertical-align: top;
    width: 50%;
    height: 300px;
    display: inline-block;
    font-size: 15px;
    background-color: lightblue;
  }

</style>


<style lang="scss">

  .starship {
    background: darken(white,15);
    margin: 20px 0;
    padding: 20px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1.2;
  }

  .starship-dragger {
    position: absolute;
    width: 13px;
    z-index: 3;
    right: 0;

    &::before {
      content: '';
      position: absolute;
      border-radius: 20px;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      background-color: rgba(black,.2);
    }

    &.mod-dragging {

      &::before {
        background-color: rgba(black,.4);
      }
      //outline: 1px solid red;
    }

    &.mod-visible {
      outline: 1px solid red;
    }

    &.mod-scrolling-delayed {
      &::before {
        background-color: rgba(black,.4);
      }
    }
  }

</style>
