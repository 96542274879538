<script>
export default {

  props: [
    'itemLayout',
    'itemOwnerUsername',
    'itemOwnerProfileImage',
    'itemOwnerNotes',
  ],

}


</script>



<template>
<div
  :class="['itemnote', 'mod-item-layout-'+itemLayout]"
  v-tooltip="'<strong>'+ itemOwnerUsername +' note</strong><br>' + itemOwnerNotes"
  js-tip
>
  <!-- :style="{backgroundImage: `url(${itemOwnerProfileImage})`}" -->
  <!-- <div class="itemnote-avatar"> <img :src="itemOwnerProfileImage" /></div> -->
  <div class="itemnote-text">{{itemOwnerNotes}}</div>
</div>
</template>



<style lang="scss">
@import "../../../common/style/_variables.scss";

  .itemnote {
    background-color: #fff0d0;
    background-position: 0 0;
    background-size: 16px 16px;
    background-repeat: no-repeat;

    display: block;
    width: 100%;
    position: relative;
    opacity: 1;
    border: 1px solid darken( desaturate(#fff0d0, 30) ,10);
    border-radius: 3px;
    position: relative;
    font-size: 0;

  }

  .itemnote-text {
    display: inline-block;
    vertical-align: middle;
    color: #865e09;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    padding: 2px 4px 2px 4px;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  /*
  .itemnote-avatar {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    width: 14px;
    height: 14px;
    border-radius: 30px;
    opacity: .7;
    margin-right: 5px;
    border: 1px solid darken( desaturate(#fff0d0, 30) ,30);
    img {
      width: 100%;
      height: 100%;
    }
  }
  */





</style>



<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .itemnote {
    background-color: darken(white,72);
    border-color: darken(white,68);
  }

  .itemnote-text {
    color: #dd9f1e;
  }

  /*
  .itemnote-avatar {
    border-color: darken(white,68);
  }
  */

}
</style>
