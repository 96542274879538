<script>
export default {


  props: [
    'subkind',
  ],


  computed: {
    loginTwitterUrl: function(){
      return `${this.$store.state.apiHost}/account/twitter/login/?process=login`;
    },
    loginFacebookUrl: function(){
      return `${this.$store.state.apiHost}/account/facebook/login/?process=login`;
    },
    loginGoogleUrl: function(){
      return `${this.$store.state.apiHost}/account/google/login/?process=login`;
    },
    redirectAfterAuth: function(){
      var cookie = this.$cookie.get('redirectAfterAuth');
      return cookie ? decodeURIComponent(cookie) : null;
    },
  },


  data: function(){
    return {

      signin: {
        username: null,
        password: null,
      },

      signup: {
        username: null,
        email: null,
        password: null,
        consent: false,
      },

      signupProgress: false,
      signupErrors: null,

      signinErrors: null,

    }
  },


  watch: {
    subkind: function(){
      this.signupErrors = null;
      this.signinErrors = null;
    },
  },



  methods: {


    createAccount: function(){

      this.signupErrors = null;
      this.signupProgress = true;

      this.$axios({
        url: this.$store.state.api + '/create-account/',
        method: 'post',
        data: this.signup,
      })
      .then(function(response){
        this.signupProgress = false;
        this.$store.dispatch('explicitTokenLogin', response.data.token);
      }.bind(this), function(error){
        this.signupProgress = false;
        this.signupErrors = error.response.data;
      }.bind(this));

    },



    signIn: function(){
      this.signinErrors = null;
      var _this = this;
      this.$store.dispatch('signinUser', {
        username: this.signin.username,
        password: this.signin.password,
        onSuccess: function(success){

          _this.$store.commit('dialogbox', null);

          /*
          _this.$store.commit('dialogbox', null);
          // redirect to chrome extension settings if chrome extension
          if ( _this.$store.state.chrome ) {
            _this.$router.push('/settings/extension');
          }

          else {

            _this.$alertify.log('<span class=\'icon icon-checkmark\'></span> Signed in as '+ _this.signin.username +'');
            _this.$router.push('/all');
          }
          */

        },
        onError: function(error){
          _this.signinErrors = error.response ? error.response.data : true;
        },
      });
    },


    changeView: function(subkind){

      if (this.$store.state.dialogbox) {
        this.$store.commit('dialogbox', {kind: 'signinup', subkind: subkind});
      }

      if (this.$route.meta.dialogbox) {

        if (subkind=='signup') {
          this.$router.push({path:'/create-account', query: this.$route.query});
        }

        if (subkind=='signin') {
          this.$router.push({path:'/sign-in', query: this.$route.query});
        }

      }

    },



    popupCenter: function(url, title, w, h) {var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;var left = ((width / 2) - (w / 2)) + dualScreenLeft;var top = ((height / 2) - (h / 2)) + dualScreenTop;var newWindow = window.open(url, title, 'status=no,directories=no,location=no,resizable=no,menubar=no,toolbar=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);if (window.focus) {newWindow.focus();}
    },


    socialBtnClick: function(url, $event){
      $event.preventDefault();
      this.popupCenter(url, 'GGatherSocialLogin', '520', '600');
    },



  }


};
</script>


<template>
<div class="signinup">


  <div class="signinup-inner" v-if="$store.state.user.is_authenticated"  ref="signinupInner">
    <div class="signinup-other-message">
      <div class="signinup-other-message-icon">
        <span class="icon icon-unlock-filled"></span>
      </div>
      <div class="signinup-other-message-text">You're logged in.</div>


      <div>
        <router-link to="/all" class="signinup-other-message-btn" @click.native="$store.commit('dialogbox', null)">Open your library</router-link>
      </div>


    </div>
  </div>



  <!--
  <div class="signinup-inner" v-else-if="$store.state.chrome"  ref="signinupInner">
    <div class="signinup-other-message">
      <div class="signinup-other-message-icon">
        <span class="icon icon-lock-filled"></span>
      </div>

      <div class="signinup-other-message-text">Authenticate on GGather.com</div>

      <div>
        <div class="signinup-other-message-infotext">Create an account or sign in on GGather.com to be automatically authenticated inside GGather extension.</div>
          <a target="_blank"  :href="$store.state.webapp + '/create-account'" class="signinup-other-message-btn">Go to GGather.com</a>
      </div>

    </div>
  </div>-->



  <div v-else class="signinup-inner"  ref="signinupInner">



    <div class="signinup-header">



      <button type="button" class="signinup-title" :class="{'mod-active': subkind=='signin'}"
      @click.prevent="changeView('signin')">
      Sign In</button>

      <button type="button" class="signinup-title" :class="{'mod-active': subkind=='signup'}"
      @click.prevent="changeView('signup')">
      Create Account</button>


    </div>


    <div class="signinup-content">


      <div v-if="subkind=='signin'">




        <div class="signinup-email-form-wrapper">


          <!-- <div class="signinup-subtitle">With email or username</div> -->
          <form @submit.prevent="signIn" class="signinup-form">



            <div>
              <input class="signinup-input  mod-no-margin" type="text" placeholder="Email or username" v-model="signin.username">
            </div>

            <div>
              <input class="signinup-input" :class="{'mod-has-value': !!signin.password}" type="password" placeholder="Password" v-model="signin.password">
            </div>



            <div v-if="signinErrors" class="signinup-error">
              <div v-for="(errors, key) in signinErrors" v-if="errors.length">

                <template v-if="key!='non_field_errors'">
                  <strong class="signinup-error-title">{{key}}</strong> &ndash; <span v-for="error in errors">{{error}} </span>
                </template>

                <template v-if="key=='non_field_errors'">
                  <strong class="signinup-error-title">Authorization</strong> &ndash; <span v-for="error in errors">{{error}} </span>
                </template>

              </div>
            </div>


            <div class="signinup-btns-wrapper  mod-signin">


              <button class="signinup-btn mod-main" type="submit">Sign In</button>

              <a :href="$store.state.apiHost + '/account/password/reset/'" target="_blank" class="signinup-btn mod-recovery">
                Account Recovery
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 32 32"><path d="M 18 5 L 18 7 L 23.5625 7 L 11.28125 19.28125 L 12.71875 20.71875 L 25 8.4375 L 25 14 L 27 14 L 27 5 Z M 5 9 L 5 27 L 23 27 L 23 14 L 21 16 L 21 25 L 7 25 L 7 11 L 16 11 L 18 9 Z "></path>
                </svg>
              </a>

            </div>

          </form>


        </div>


        <div class="signinup-social-wrapper">
          <div class="signinup-subtitle">Social sign in (legacy accounts)</div>

          <div class="signinup-social-wrapper-btns">

            <button type="button" class="signinup-btn mod-twitter" @click="socialBtnClick(loginTwitterUrl, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
              </svg>
              <span>Twitter</span>
            </button>

            <!-- <button type="button" class="signinup-btn mod-facebook" @click="socialBtnClick(loginFacebookUrl, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
                <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"></path>
              </svg>
              <span>Facebook</span>
            </button> -->

            <button type="button" class="signinup-btn mod-google" @click="socialBtnClick(loginGoogleUrl, $event)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1" width="50" height="50">
                <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z "/>
              </svg>
              <span>Google</span>
            </button>


          </div>

        </div>



      </div>


      <div v-if="subkind=='signup'">

        <!--
        <div class="signinup-social-wrapper">
          <div class="signinup-subtitle">Instantly with social network</div>

          <div class="signinup-social-wrapper-btns">

            <a class="signinup-btn mod-twitter" :href="$store.state.apiHost+'/account/twitter/login/?process=login'" @click="socialBtnClick">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
              </svg>
              <span>Twitter</span>
            </a>

            <a class="signinup-btn mod-facebook" :href="$store.state.apiHost+'/account/facebook/login/?process=login'" @click="socialBtnClick">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 30 30">
                <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"></path>
              </svg>
              <span>Facebook</span>
            </a>

            <a class="signinup-btn mod-google" :href="$store.state.apiHost+'/account/google/login/?process=login'" @click="socialBtnClick">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1" width="50" height="50">
                <path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z "/>
              </svg>
              <span>Google</span>
            </a>

          </div>
        </div>

        <div class="signinup-small-text">We won't ever post anything without your approval.</div>
        -->


        <div class="signinup-email-form-wrapper">


          <!-- <div class="signinup-subtitle">With email</div> -->
          <form @submit.prevent="createAccount" class="signinup-form">



            <div>
              <input class="signinup-input  mod-no-margin" type="text" placeholder="Email" v-model="signup.email">
            </div>

            <div v-show="signup.email && (signup.email.length > 5)">
              <input class="signinup-input" type="text" placeholder="Username" v-model="signup.username">
            </div>

            <div v-show="signup.email && (signup.email.length > 5)">
              <input class="signinup-input" :class="{'mod-has-value': !!signup.password}" type="password" placeholder="Password" v-model="signup.password">
            </div>



            <label class="signinup-terms-checkbox">
              <input type="checkbox" value="on" v-model="signup.consent" />
              <span>
                I agree to GGather <a target="_blank" :href="$store.state.hq+'/legal/terms-of-use'">Terms of Use</a>, <a target="_blank" :href="$store.state.hq+'/legal/privacy-policy'">Privacy Policy</a> and <a target="_blank" :href="$store.state.hq+'/legal/cookies-policy'">Cookies Policy</a>.
              </span>
            </label>



            <div v-if="signupErrors && parseInt(signupErrors.count)" class="signinup-error">
              <div v-for="(errors, key) in signupErrors"  v-if="errors.length && (key!='count')">
                <strong class="signinup-error-title">{{key}}</strong> &ndash; <span v-for="error in errors">{{error}} </span>
              </div>
            </div>


            <div class="signinup-btns-wrapper  mod-signup">

              <button class="signinup-btn mod-main" type="submit">
                <template v-if="signupProgress">Creating Account...</template>
                <template v-else>Create Account</template>
              </button>


            </div>


          </form>

        </div>

      </div>





    </div>



    <div class="signinup-redirect-info" v-if="redirectAfterAuth">
      <div>
        You will be redirected to <span>{{$store.state.webapp}}{{redirectAfterAuth}}</span>
      </div>
    </div>



  </div>



</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .signinup {
    background-color: lighten(#eee,4);
    border-radius: 6px;
    width: 380px;
    //min-height: 300px;
    //border: 1px solid darken(white,10);
    //box-shadow: 0 2px 5px -1px rgba(black,.05);
    box-shadow: 0 2px 5px 0 rgba(black, .03), 0 2px 30px 5px rgba(black, .02);
    html.mod-transitions-enabled & {
      transition: height 200ms ease-in-out;
    }

    overflow: hidden;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;

    @media(max-width: 540px) {
      width: 300px;
    }
  }

  .signinup-inner {
  }


  .signinup-other-message {
    padding: 25px 20px 25px 20px;
    display: block;
    text-align: center;
  }
  .signinup-other-message-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    font-size: 34px;
    position: relative;
    top: -4px;
  }
  .signinup-other-message-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.2;
    color: #777;
  }

  .signinup-other-message-btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    line-height: 1.2;
    margin: 15px 5px 10px 5px;
    color: #777;
    border: 1px solid darken($clr-ggather,3);
    background: $clr-ggather;
    color: rgba(white,1);
    padding: 6px 12px;
    border-radius: 3px;
  }

  .signinup-other-message-infotext {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    line-height: 1.2;
    margin: 10px 5px;
    color: #777;
  }




  .signinup-content {
    padding: 0 30px 25px 30px;
  }




  .signinup-redirect-info {
    display: block;
    vertical-align: middle;
    font-size: 13px;
    line-height: 1.3;
    color: darken(#ffdc79,40);
    margin: 10px 30px;
    margin-top: 0px;
    margin-bottom: 25px;

    padding: 9px 11px;
    border: 1px solid darken(#ffdc79,3);

    background: #ffdc79;

    border-radius: 3px;

    span {
      margin-top: 3px;
      //background: darken(white,5);
      display: block;
      font-weight: 500;
      color: darken(#ffdc79,45);
    }
  }





  .signinup-header {
    text-align: left;
    //padding: 0 5px;
    font-size: 0;
    border-bottom: 1px solid lighten(#eee,1);
  }

  .signinup-title {
    display: inline-block;
    outline: none;
    font-size: 17px;
    color: rgba(black,.2);
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 15px;

    border-right: 1px solid lighten(#eee,1);
    padding-left: 25px;
    padding-right: 25px;

    &:last-of-type {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    &.mod-active {
      color: rgba($clr-ggather,.9);
      font-weight: 600;
      cursor: default;
    }

  }

  .signinup-subtitle {
    font-size: 11px;
    font-weight: 600;
    color: rgba(black,.6);
    line-height: 1;
    margin-top: 20px;
  }

  .signinup-error {
    background-color: lighten(orangered, 35);
    border: 1px solid lighten(orangered, 30);
    border-radius: 3px;
    padding: 5px 10px;
    text-align: left;
    font-size: 11px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: darken(orangered, 40);
  }

    .signinup-error-title {
      text-transform: capitalize;
      font-weight: 500;
    }

  .signinup-form {
    text-align: center;
    margin-top: 5px;
  }

  .signinup-input {
    background-color: rgba(black,.07);
    background-color: rgba(white,.4);
    border: 1px solid darken(#eee,5);
    margin-top: 5px;
    padding: 7px 10px 6px 10px;
    font-size: 15px;
    line-height: 1.3;
    border-radius: 3px;
    width: 100%;
    outline: none;

    &.mod-no-margin {
      margin-top: 0;
    }

    &:focus {
      //background-color: rgba(black,.08);
      background-color: rgba(white,.6);
      border-color: darken(#eee,15);
    }

    &.mod-has-value[type="password"] {
      font-family: 'Consolas', monospace, sans-serif;
    }

    html.mod-device-mobile & {
      font-size: 16px; // so iphone wont zoom on input focus
    }
  }


  .signinup-terms-checkbox {
    display: block;
    background-color: rgba(black,.07);
    background-color: rgba(white,.4);
    border: 1px solid darken(#eee,5);
    margin-top: 5px;
    padding: 7px 8px 6px 8px;
    border-radius: 3px;

    cursor: pointer;

    font-size: 12px;
    line-height: 1.3;
    text-align: left;
    color: rgba(black,.5);
    margin-top: 5px;
    font-weight: 500;
    color: rgba(black,.6);

    a {
      color: lighten($clr-ggather, 10);

    }
    a:hover {
      color: lighten($clr-ggather, 0);
      text-decoration: underline;
    }

    position: relative;
    padding-left: 32px;

    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 8px;
      left: 9px;
      appearance: checkbox;
      border-radius: 3px;
      border: 1px solid #333;
    }


    &:hover,
    &:focus {
      //background-color: rgba(black,.08);
      background-color: rgba(white,.6);
      border-color: darken(#eee,15);
    }

  }

  .signinup-social-wrapper {
    margin-top: 20px;
    border-top: 1px solid #eee;
    padding-top: 0px;
    font-size: 0;
    display: block;
  }

  .signinup-social-wrapper-btns {
    display: flex;
    justify-content: flex-start;

    @media(max-width: 540px) {
      display: block;
    }
  }

  .signinup-email-form-wrapper {
    margin-top: 25px;
    display: block;
  }


  .signinup-btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 10px;

    &.mod-signin {
      margin-top: 5px;
    }

    &.mod-signup {
      margin-top: 7px;
      margin-bottom: 0px;
    }

  }




  .signinup-btn {
    display: inline-block;
    color: rgba(black,.9);
    font-weight: 500;
    margin-top: 5px;
    border-radius: 3px;
    border: 1px solid darken(#eee,3);
    padding: 5px 10px 4px 10px;
    background-color: white;
    vertical-align: middle;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px rgba(black, 0.1);
    }


    svg {
      display: inline-block;
      vertical-align: middle;
      width: 19px;
      height: 19px;
      fill: white;
      position: relative;
      top: -1px;
      margin-right: 2px;
    }

    &.mod-recovery {
      border-color: transparent;
      background-color: transparent;
      font-weight: 400;
      font-size: 11px;
      color: rgba(black,.7);
    }
    &.mod-recovery svg {
      width: 18px;
      height: 18px;
      fill: rgba(black,.5);
      margin-left: 2px;
      margin-right: 0;
      top: -2px;
    }

    &.mod-facebook {
      background-color: #3b5998;
      color: white;
      font-size: 15px;
      margin-right: 5px;
      border: none;
      white-space: nowrap;
    }


    &.mod-facebook svg {
      top: -1px;
        transform: scale(1.05);
    }


    &.mod-twitter {
      background-color: #1da1f2;
      color: white;
      font-size: 15px;
      margin-right: 5px;
      border: none;
      padding-left: 12px;
      padding-right: 12px;
      white-space: nowrap;
      width: 100%;
    }

    &.mod-google {
      background-color: #ea4335;
      color: white;
      font-size: 15px;
      border: none;
      white-space: nowrap;
      width: 100%;
    }


    &.mod-google svg {
      transform: scale(0.9);
    }

    &.mod-main {
      font-weight: 500;
    }

    @media(max-width: 540px) {
      display: block;
      margin-right: 0;
      &.mod-twitter,
      &.mod-google,
      &.mod-facebook {
        margin-right: 0;
      }
    }

  }


  .signinup-small-text {
    font-size: 11px;
    line-height: 1.2;
    text-align: left;
    color: rgba(black,.5);
    margin-top: 5px;

    &.mod-strong {
      font-weight: 500;
      color: rgba(black,.6);
    }

  }

</style>
