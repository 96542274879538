<script>
export default {

  props: [
    'subkind',
    'featureCode'
  ],


  computed: {
    featureName: function(){
      switch (this.featureCode) {
        case 'panel-multiple':
          return 'Two Column Panels Link Layout'; break;
        case 'grid':
          return 'Grid Link Layout'; break;
        case 'grid-fixed':
          return 'Uniform Grid Link Layout'; break;
        case 'card':
          return 'Card Link Layout'; break;
        case 'card-fixed':
          return 'Uniform Cards Link Layout'; break;
        case 'card-small':
          return 'Small Cards Link Layout'; break;
        case 'card-fixed-small':
          return 'Small Uniform Cards Link Layout'; break;
        case 'import-limit':
          return 'ability to import more than 2000 bookmarks'; break;
        case 'private-annotations':
          return 'ability to create private annotations with markdown support'; break;
        case 'batch-edit':
          return 'ability to edit, open & delete multiple bookmarks at the same time'; break;
        case 'subtags-filter':
          return 'ability to additionally filter bookmarks in tag pages using other tags that were assigned to those bookmarks'; break;
        default:
          return 'this feature'; break;
      }
    },

    featureImage: function(){
      try {
        var output = 'url('+ require('../../../common/img/profeature/profeature-'+this.featureCode+'.jpg').default +')';
      } catch(e) {
        var output = '';
      }
      return output;
    },

  },



};
</script>


<template>
<div class="profeature">
<div class="profeature-inner" ref="profeatureInner">

  <div class="profeature-top">



    <div class="profeature-ggather-logo">

      <!--  <img src="../../../common/img/ggather-logo-big-blue-outline.png">-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438 668"><path fill="#FFFFFF" class="cls-1" d="M376.29,0h-33a60.31,60.31,0,0,0-54.49,34.46l-35,73.69a14.65,14.65,0,0,0,13.23,20.92h71.62c19.15,0,29.42,22.53,16.85,37-69.92,80.39-190.26,216-251.8,287-5.68,6.56-16.18-.2-12.36-8C120.5,385.68,180.81,268,209.8,208.45a7.42,7.42,0,0,0-6.68-10.67H115c-17.16,0-26.44-20.1-15.32-33.16l99-116.32C214.94,29.28,201.42,0,176.42,0H61.71A61.31,61.31,0,0,0,.4,61.31V636.42c0,29.36,36.28,43.16,55.79,21.23L173.86,525.41a60.42,60.42,0,0,1,90.28,0L381.81,657.65c19.52,21.93,55.79,8.13,55.79-21.23V61.31A61.31,61.31,0,0,0,376.29,0Z"/></svg>

    </div>



    <!-- <span class="icon icon-star-filled"></span> --> You've just discovered GGather Plus feature!
  </div>

  <div :style="{backgroundImage: featureImage}" class="profeature-img" ></div>

  <div class="profeature-bottom">


    <div class="profeature-cta-text">

      Get <strong>{{featureName}}</strong> and other useful features for only $3 per month.

      <!-- <div class="small">That's the price of one cup of coffee.</div> -->
    </div>


    <div class="profeature-btns" v-if="$store.state.user.is_authenticated">

      <router-link to="/upgrade" @click.native="$store.commit('dialogbox', null)" class="profeature-cta-btn">
        Upgrade To Plus ⚡
      </router-link>


    </div>

    <div class="profeature-btns" v-else>

      <button type="button"  class="profeature-cta-btn" @click.stop.prevent="$store.commit('dialogbox', {kind: 'signinup', subkind: 'signup'})">Create Account</button>

      <!--
      <router-link to="/upgrade" class="profeature-cta-btn  mod-pricing" @click.native.capture="$store.commit('dialogbox', null)">See Plan Details</router-link> -->

      <a href="/upgrade" class="profeature-cta-btn  mod-pricing">See Plan Details</a>


    </div>


  </div>

</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .profeature {
    background-color: lighten(#eee,4);
    border-radius: 6px;
    width: 100%;
    max-width: 380px;
    //min-height: 300px;
    //border: 1px solid darken(white,10);
    //box-shadow: 0 2px 5px -1px rgba(black,.05);
    box-shadow: 0 2px 5px 0 rgba(black, .03), 0 2px 30px 5px rgba(black, .02);
    html.mod-transitions-enabled & {
      transition: height 200ms ease-in-out;
    }

    overflow: hidden;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;
  }

  .profeature-inner {
    position: relative;
  }


    .profeature-top {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.3;
      padding: 17px 20px 14px 20px;
      text-align: center;
      color: rgba(white,.95);
      //background-color: orange;
      //border-radius: 2px;
      color: $clr-ggather;
      margin: 5px;


      & > .icon {
        display: inline-block;
        margin-right: 3px;
      }

      & > .icon::before {
        line-height: 1.35;
      }
    }

    .profeature-ggather-logo {
      width: 16px;
      margin-right: 4px;
      display: inline-block;
      //position: absolute;
      //top: 8px;
      //left: 10px;
      & > img {
        max-width: 100%;
      }
      & > svg {
        width: 11px;
        display: inline-block;
        path { fill: $clr-ggather; }
      }
    }

    .profeature-img {
      height: 250px;
      display: block;
      background-size: cover;
      background-position: 50%;
      background-position: 50% 0%;
      background-color: #eee;
      border-radius: 0px;
      margin: 0px;
      border-top: 1px solid darken(white,9);
      border-bottom: 1px solid darken(white,9);

      html.mod-transitions-enabled & {
        transition: background-position 3000ms ease-out;
      }

      &:hover {
        background-position: 50% 100%;
      }
    }



    .profeature-bottom {
      padding: 20px 20px 25px 20px;
    }

    .profeature-cta-text {
      font-size: 14px;
      color: rgba(black,.8);
      font-weight: 400;
      display: block;

      & > .small {
        font-size: 12px;
        margin-top: 10px;
      }

      & > strong {
        font-weight: 500;
        color: rgba(black,.95);
        color: $clr-ggather;;
      }


      & > strong.price {
        color: rgba(black,.95);
      }

    }


    .profeature-btns {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    .profeature-cta-btn {
      display: inline-block;
      background-color: yellowgreen;
      background-color: $clr-ggather;
      color: white;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      border-radius: 3px;
      color: rgba(white,.95);
      padding: 12px 20px 12px 20px;

      &.mod-pricing {
        background-color: transparent;
        color: rgba(black,.6);
        font-weight: 400;
        font-size: 13px;
        line-height: 1.7;
        padding-left: 0px;
        padding-right: 5px;
      }

      & > .icon{
        display: inline-block;
        margin-right: 3px;
      }

      & > .icon::before {
        line-height: 1.36;
      }
    }




</style>
