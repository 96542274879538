import autosize from 'autosize';

var VueAutosize = {
  install: function(Vue, options){

    Vue.directive('autosize', {

      bind: function(el, binding, vnode, oldVnode){
        if (el.nodeName != 'TEXTAREA') {
          Vue.util.warn('Vue-Autosize can only be used on <textarea> elements.');
          return false;
        }
        autosize(el);
      },

      inserted: function(el, binding, vnode, oldVnode){
        autosize.update(el);
      },

      update: function(el, binding, vnode, oldVnode){
        autosize.update(el);
      },

      componentUpdated: function(el, binding, vnode, oldVnode){
        autosize.update(el);
      },

      unbind: function(el, binding, vnode, oldVnode){
        //console.log('UNBIND!!!');
        autosize.destroy(el);
      },

    });

  },
};

export default VueAutosize;
