<script>
import __ from '../common/script/dashdash.js';
import _throttle from 'lodash/throttle';
export default {

  name: 'app',

  data: function(){
    return {
    }
  },

  computed: {
    dialogbox: function(){
      return this.$route.meta.dialogbox || this.$store.state.dialogbox;
    },
    viewer: function(){
      return this.$route.meta.viewer || this.$store.state.viewer;
    },
  },

  created: function(){
    this.$store.dispatch('appCreated');
  },

  mounted: function(){
    this.$store.dispatch('appMounted');
    this.notifsInterval();
    this.scrollListener();
    //this.loadAdSense();
  },

  beforeDestroy: function(){
    this.scrollListener('destroy');
  },

  watch: {
    '$route': function(val){
      this.setChromeLastPage();
      //this.showRouteChangeLog(val);
    },
    '$route.path': function(){
      this.scrollToTop();
      this.$alertify.reset();
    },
  },

  methods: {
    showRouteChangeLog: function(route){
      //var routeObject = {route: JSON.parse(JSON.stringify(route))};
      console.log(
        `%c route change %c ${route.fullPath} %c ${JSON.stringify(window.location)}`,
        'background:#41b883; padding: 0px; border-radius: 3px 0 0 3px; color: #fff',
        'background:#555; padding: 0px; border-radius: 0 3px 3px 0; color: #fff',
        'background:transparent',
      )
    },

    setChromeLastPage: function(){
      if (this.$store.state.chrome) {
        this.$store.commit('chromeLastPage', this.$route.fullPath);
      }
    },

    loadAdSense: function(){
      var script = document.createElement('script');
      script.async = true;
      script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      window.document.body.appendChild(script);
      //(window.adsbygoogle = window.adsbygoogle || []).push({});
    },

    scrollListener: function(action){
      if (action == 'destroy') {
        window.removeEventListener('scroll', this.scrollHandler, 1);
        return false;
      }
      window.addEventListener('scroll', this.scrollHandler, 1);
    },

    scrollHandler: _throttle(function(event){
      var scrolled = window.pageYOffset >= 1;
      if (this.$store.state.contentScrolled !== scrolled) {
        this.$store.commit('contentScrolled', scrolled);
      }
    }, 50),

    scrollToTop: function(){
      // timeout because there's a transition when changing router page
      setTimeout(function() {
        document.body.scrollTop = 0;
      }.bind(this), (this.$store.state.transitionsEnabled?300:0) );

      // animated scroll to top
      //if (window.scrollY != 0) {
      //    setTimeout(function() {
      //        window.scrollTo(0, window.scrollY-30);
      //        this.scrollToTop();
      //    }.bind(this), 100);
      //}
    },

    notifsInterval: function(){
      this._notifsInterval = setInterval(function(){
        this.$store.dispatch('getUnreadNotifs');
      }.bind(this), (1000*60)*5)
    },
  },
};
</script>

<template>
<div class="app">

  <htmlclass />

  <loadscreen v-if="!$store.state.user" />

  <div v-if="$store.state.user">

    <div class="app-universe">
      <div class="app-galaxy">

        <titlebar v-if="!$store.state.itemsSelectMode" />

        <selectbar v-if="$store.state.itemsSelectMode" />

        <div class="app-content">
          <router-view />
        </div>
      </div>

    </div>

    <sidebarbtn v-if="$route.path!=='/' && $store.getters.pageWithSidebar && $store.getters.finalSidebarCollapse" />

    <sbar v-if="$store.state.user.is_authenticated && $store.getters.pageWithSidebar" />

    <topbar v-if="$route.path!=='/' && !$store.getters.pageWithSidebar" />

    <savebtn />

    <jumpnotif />

    <activity />

    <dialogbox />

    <publishinvite />

    <viewer />

    <usermenu />

  </div>

</div>
</template>



<style lang="scss">
  @import url('https://dmfqrs09fhty6.cloudfront.net/webfont/inter-ui/3-2/web/inter-ui-swap.css');
  @import "../common/style/_variables.scss";
  @import "../common/style/normalize-plus.min.css";
  @import "../common/style/_alertify-ggather.scss";
  // @import "../common/style/_tippy-ggather.scss";
  @import "../common/style/_tippy-6-ggather.scss";
  @import "../common/style/_yue-ggather.scss";
  @import "../common/style/_common.scss";
  @import "../common/style/_textpage.scss";

  :root:root {
    background-color: white;
    background-color: #fafafa;
    background-color: #f5f5f5;
    background-color: darken(white,6);
    //color: #333;
    font-weight: 400;
    font-size: 100%;
    font-size: 15px;
    min-height: 100%;

    //font-feature-settings: 'kern' 0, 'onum' 0, 'liga' 0; // kern is defaultly enabled and it sometimes bugs out font render on chrome
    //overflow-x: hidden;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: scrollbar;

    text-rendering: optimizeSpeed;

    &.mod-loaded {
      overflow-y: scroll;
    }

    &.mod-page-landing {
      overflow: auto;
    }

    &.mod-dialogbox-opened,
    &.mod-viewer-open {
      overflow: hidden;
    }

    &.mod-route-meta-kind-dialogbox {
      overflow: hidden;
    }
  }

  :root:root ::selection {
    background: #777;
    color: white;
  }

  /*
  html.mod-build-chrome,
  html.mod-build-devchrome {
    width: 650px;
    height: 600px;
  }
  */

  body {
    min-height: 100%;
    font-size: 100%;
    font-family: '-apple-system', 'BlinkMacSystemFont',  'Inter UI', 'SF Pro Text', 'SF UI Text', 'SFUI', 'San Francisco', 'Roboto', 'Arial', 'Segoe UI', 'Roboto', sans-serif;
    text-rendering: optimizeSpeed;
    font-feature-settings: "calt" 1, "kern" 1, "liga" 1;
  }

  /*
  @supports (font-variation-settings: normal) {
    body {
      font-family: 'Inter UI var alt', sans-serif;
    }
  }
  */

  /*
  html.mod-build-chrome body,
  html.mod-build-devchrome body {
    overflow-y: scroll;
    width: 650px;
    height: 600px;
  }
  */

  html.mod-page-landing body {
    overflow: auto;
  }

  .router-page {
    display: block;
    width: auto;
    height: auto;
    backface-visibility: hidden;
    transform-origin: center 15%;
  }

  .app-content-wrapper-vue-scrollbar {

    .vb > .vb-dragger {
      z-index: 2;
      width: 10px;
      right: 0;
      z-index: 150;
    }

    .vb > .vb-dragger > .vb-dragger-styler {

      html.mod-transitions-enabled & {
        transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out,
          border-radius 100ms ease-out;
      }

      backface-visibility: hidden;
      transform: rotate3d(0,0,0,0);
      background-color: rgba(200, 200, 200, .6);
      margin: 2px 3px 2px 0;
      border-radius: 222px;
      height: calc(100% - 4px);
      display: block;
    }

    .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .6);
    }

    .vb > .vb-dragger:hover > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .9);
      margin: 0px;
      height: 100%;
      border-radius: 1px;
    }

    .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .9);
      margin: 0px;
      height: 100%;
      border-radius: 1px;
    }

    .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .9);
    }

  }

</style>



<style lang="scss" scoped>

  @import "../common/style/_variables.scss";

  .app {
    min-height: 100%;
    min-height: 100vh;
    position: relative;

    html.mod-dialogbox-opened &,
    html.mod-viewer-open & {
      overflow-y: scroll;
    }
  }

  .app-universe {
    font-size: 0;
    //display: flex;
    //justify-content: space-between;
    min-height: 100%;
    position: relative;
    //overflow: hidden;
    width: 100%;
    html.mod-transitions-enabled & {
      transition: width 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    html.mod-viewer-expand-level-1 & {
      width: calc(50% + 150px);
    }
    /*
    html.mod-chrome.mod-chrome & {
      width: 100%;
    }
    */
    html.mod-dialogbox-opened & {
      //filter: blur(4px);
    }
  }

  .app-galaxy {
    display: inline-block;
    width: calc(100% - 280px);
    margin-left: 280px;
    //height: 100%;
    //height: calc(100% - 55px);
    //overflow: hidden;
    position: relative;
    z-index: 50;

    //html.mod-transitions-enabled & {
    //  transition: margin 500ms ease-out 200ms, width 500ms ease-out 200ms;
    //}

    html.mod-final-sidebar-collapse & {
      width: calc(100% - 0px);
      margin-left: 0;
    }

    //html.mod-viewer-expand-level-1 & {
    //    width: calc(100%);
    //}

    html.mod-page-without-sidebar &,
    html.mod-route-meta-kind-undefined &,
    html.mod-route-meta-kind-landing &,
    html.mod-route-meta-kind-error &,
    html.mod-route-meta-kind-notifications &,
    html.mod-route-meta-kind-page &,
    html.mod-route-meta-kind-settings &,
    html.mod-route-meta-kind-payment &,
    html.mod-route-meta-kind-feed &,
    html.mod-route-meta-kind-discover &,
    html.mod-route-meta-kind-profilepage & {
      width: calc(100% - 0px);
      margin-left: 0;
    }


  }

  .app-content {
    //height: 100%;
    display: block;
    position: relative;
    backface-visibility: hidden;
    transform: rotate3d(0,0,0,0) translateY(0px);
    //padding-top: 55px;

    //html.mod-transitions-enabled & {
    //  transition: margin-top 100ms ease-in-out, transform 100ms ease-in-out, padding 300ms ease-in-out;
    //}


    html.mod-titlebar-visible & {
      //margin-top: 50px;
      transform: rotate3d(0,0,0,0) translateY(0px);
      //height: calc(100% - 55px);
    }

    html.mod-page-without-sidebar & {
      padding-top: 45px;
    }

    html.mod-page-landing & {
      padding-top: 0;
    }
  }


</style>


<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {
  color-scheme: dark;
  color: black;

  body {
    color-scheme: dark;
    color: black;
  }

  & {
    background-color: darken(white,90);
  }

}
</style>
