<script>
export default {

  props: [
    'url',
  ],

  data: function(){
    return {

      content: null,

    };
  },

  created: function(){
    this.getURLText();
  },

  beforeDestroy: function(){

  },

  methods: {

    cancelGetURLText: function(){
      this._getURLTextRequest ? this._getURLTextRequest.cancel() : null;
    },

    getURLText: function(cancel){

      this.cancelGetURLText();
      var CancelToken = this.$axios.CancelToken;
      var source = CancelToken.source();
      this._getURLTextRequest = source;

      this.$axios({
        cancelToken: source.token,
        url: this.$store.state.api + '/get-url-text/',
        method: 'get',
        params: {
          url: this.url,
        },
      }).then(function(response){
        this.content = response.data;
      }.bind(this));

    },
  }

};
</script>


<template>
<div class="reader  common-light-scrollbar">

  <div class="reader-notice-wrapper">
  <div class="reader-notice"><span class="icon icon-help"></span> This is work-in-progress implementation of GGather Reader. Style customizations and parsing improvements are planned.</div>
  </div>

  <div v-if="content" class="reader-content  yue">
    <h1 v-if="content.title && content.html">{{content.title}}</h1>
    <div v-html="content.html"></div>
  </div>



  <div class="reader-content-status" v-if="(!content) || (content && (!content.html))">

    <spinner2 v-if="!content" :size="32" :line-size="2" :speed="0.4" line-fg-color="gray" line-bg-color="transparent" />

    <div v-if="content && (!content.html)" class="reader-empty">
      We couldn't parse content of this link into a readable format.
    </div>

  </div>



</div>
</template>

<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .reader {
    //max-height: calc(100vh - 55px);
    height: 100%;
    overflow: auto;
    background: white;
  }

  .reader-notice-wrapper {
    display: block;
    text-align: center;
    overflow: auto;
    //border-bottom: 1px solid #eee;
    //padding-bottom: 5px;
    //padding-top: 5px;
  }

  .reader-notice {
    display: block;
    color: #555;
    padding: 5px 10px;
    font-size: 13px;
    text-align: center;
    border-bottom: 1px solid #eee;

    //border-radius: 2px;
    //width: 100%;
    //width: calc(100% - 10px);

    margin: 0;
    //margin-left: 5px;
    //margin-right: 5px;
    //max-width: 600px;
    background-color: lighten(#eee,3);

    & > .icon::before {
      line-height: 1.35;
    }

  }



  .reader-empty {
    font-size: 14px;
    font-weight: 500;
    color: #888;
    background-color: darken(white,3);
    border: 1px solid darken(white,5);
    border-radius: 2px;
    padding: 50px 20px;
    //font-style: italic;
  }


  .reader-content-status {
    display: block;
    text-align: center;
    padding: 50px 100px 200px 100px;
  }

</style>


<style lang="scss">


  .reader-content.reader-content {

    padding: 60px 100px 60px 100px;
    //font-size: 15px;
    //font-weight: 400;
    display: block;
    margin: 0 auto;
    max-width: 800px;

    @media (max-width: 900px) {
      padding: 40px 20px 40px 20px;
    }

    /*
    & h1 {
      font-size: 25px;
      font-weight: 700;
      margin-top: 30px;
    }

    & h2 {
      font-size: 20px;
      font-weight: 600;
      margin-top: 30px;
    }

    & h3 {
      font-size: 18px;
      font-weight: 600;
      margin-top: 30px;
    }

    & h4 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }

    & h5 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }

    & h5 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }

    & p {
      font-size: 15px;
      font-weight: 400;
      margin-top: 15px;
    }

    & a {
      //font-size: 15px;
      //font-weight: 400;
      text-decoration: underline;
    }

    & ul, & ol {
      margin: 5px 0;
      margin-left: 40px;
    }

    & code {
      background-color: #eee;
    }

    & pre {
      background-color: #eee;
      margin: 5px 0;
      display: block;
    }

    & img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      margin: 15px 0;
    }
    */


  }
</style>





<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .reader {
    background-color: darken(white, 75);
  }




  .reader-empty {
    background-color: darken(white, 70);
    border-color: darken(white, 60);
    color: rgba(white,.9);
  }


  .reader-notice-wrapper {
    background-color: darken(white, 70);
    border-color: darken(white,65);
  }

  .reader-notice {
    color: rgba(white,.9);
    background-color: darken(white, 70);
    border-color: darken(white,65);
  }


}

</style>
