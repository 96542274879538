<script>
export default {


  data: function(){
    return {
      latestUpdate: null,
      dismissedUpdate: null,
    }
  },

  computed: {
    showUpdateNotice: function(){
      if (!this.latestUpdate) return false;
      if (this.latestUpdate.path === this.dismissedUpdate) return false;
      return true;
    }
  },

  mounted: function(){
    this.syncDismissedUpdateCoookieIntoState();
    this.getUdates();
  },


  methods: {
    getUdates: function(){
      fetch(this.$store.state.hq + '/latest-update?days=30', {method: 'GET'})
        .then(response => response.ok ? response.json() : null)
        .then(data => this.latestUpdate = data)
    },
    syncDismissedUpdateCoookieIntoState: function() {
      const cookie = this.$cookie.get('ggather_dismissed_update');
      if (!cookie) return;
      this.dismissedUpdate = cookie;
    },
    closeUpdate: function(){
      this.$cookie.set('ggather_dismissed_update', this.latestUpdate.path, {expires: '31D'});
      this.syncDismissedUpdateCoookieIntoState();
    },
  },

};
</script>


<template>

  <div v-if="showUpdateNotice" class="updatenotice">
    <a class="updatenotice-link" :href="$store.state.hq + latestUpdate.path" :title="latestUpdate.title" target="_blank"><iconsvg icon="material-rounded-notification" /> New Update!</a>
    <button class="updatenotice-close" type="button" @click.stop.prevent="closeUpdate"><iconsvg icon="material-delete" /></button>
  </div>

</template>

<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";



  .updatenotice {
    display: flex;
    align-items: stretch;

    margin-right: 10px;
    margin-top: 10px;

    background: $clr-ggather;
    border: $clr-ggather;
    border-radius: 999px;
    overflow: hidden;
  }


  .updatenotice-link {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(white,1);
    font-weight: 500;
    padding: 4px 8px 3px 8px;
    background: rgba(white,.0);
    position: relative;

    &::after {
      position: absolute;
      right: 0;
      top: 5px;
      bottom: 5px;
      content: '';
      width: 1px;
      background: rgba(white,.2);
      opacity: 1;
      html.mod-transitions-enabled & {
        transition: opacity 100ms;
      }
    }

    html.mod-transitions-enabled & {
      transition: border-color 100ms, background-color 100ms;
    }
    svg {
      fill: rgba(white,1);
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
    .updatenotice:hover &::after {
      opacity: 0;
    }
    &:hover {
      background: rgba(white,.2);
    }
  }


  .updatenotice-close {
    padding: 0 7px 0 4px;
    background: rgba(white,.0);
    html.mod-transitions-enabled & {
      transition: all 100ms;
    }
    svg {
      html.mod-transitions-enabled & {
        transition: all 100ms;
      }
      fill: rgba(white,.5);
      width: 16px;
      height: 16px;
    }
    &:hover {
      background: rgba(white,.2);
      svg {
      fill: rgba(white,1);
      }
    }

  }


</style>



<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .updatenotice {
    box-shadow: 0 0 0 1px rgba(white,.2) inset;
  }

}
</style>
