<script>
export default {

  name: 'page-about',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },

  data: function(){
    return {
    };
  },

};
</script>


<template>
<div class="router-page">
<div class="textpage">


  <h1>About</h1>


  <hr>


  <h2>Sections</h2>

  <h3><span class="icon icon-folder-filled"></span> What's the "My Library" section?</h3>
  <p>That's your links library. From it you can see and organize all of your private and public bookmarks.</p>


  <h3><span class="icon icon-user-group-man-man-filled"></span> What's the "Social" section?</h3>
  <p>It's where you can see feed of all your subscribed tags bookmarks in one place.</p>

  <h3><span class="icon icon-binoculars-filled"></span> What's the "Discover" section?</h3>
  <p>Inside it you'll see most interesting tags and people on GGather. You can then check their pages and/or follow them.</p>

  <hr>

  <h2>Contact</h2>

  <p>For any questions please go to to <router-link to="/contact">contact page</router-link>.</p>


</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


</style>
