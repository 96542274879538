<script>
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import randomMaterialColor from '../../../common/script/random-material-color.js';
export default {

  /*

    https://logo.clearbit.com
    https://multitool.serafin.io/sschrome/
    url upload
    file upload

  */

  props: [
    'url',
    'urlbookmark',
    'urldata',
  ],

  data: function(){
    return {
      thumbnails: [],
      overlaysForceVisible: false,
      status: null,

      imgLoading: false,

      urlPop: false,
      urlPopMouseEvent: null,

      urlPopValue: '',


    }
  },

  computed: {

    visibleThumbnail: function(){
      return _find(this.thumbnails, {visible: true});
    },

    visibleThumbnailIndex: function(){
      return _findIndex(this.thumbnails, {visible: true});
    },

    selfThumbnailAvailable: function(){
      return _find(this.thumbnails, {kind: 'self'});
    },

    nextButtonAvailable: function(){
      return this.visibleThumbnailIndex+1 < this.thumbnails.length;
    },

    prevButtonAvailable: function(){
      return this.visibleThumbnailIndex > 0;
    },

    confirmButtonAvailable: function(){
      var worn = this.urlbookmark.thumbnail_worn;
      if (false) {} // noop
      else if (worn==='blank') {
        return this.visibleThumbnail.kind!=='blank';
      }
      else if (worn==='self') {
        return this.visibleThumbnail.kind!=='self';
      }
      else if (worn==='urldata') {
        return this.visibleThumbnail.kind!=='urldata';
      }
      else if (this.urldata.thumbnail){
        return this.visibleThumbnail.kind!=='urldata';
      }
      else if (!this.urldata.thumbnail){
        return this.visibleThumbnail.kind!=='blank';
      }
      return true;
      /*
      var worn = this.urlbookmark.thumbnail_worn;


      if (this.visibleThumbnail.kind==='htmlimage') return true;
      if (this.visibleThumbnail.kind!==worn) return true;
      return false;*/
    },

    visibleTitle: function(){
      if (this.urlbookmark && this.urlbookmark.title) {
        return this.urlbookmark.title;
      }
      else if (this.urldata && this.urldata.title) {
        return this.urldata.title;
      }
      else {
        return '';
      }
    },

    blankThumbColor: function(){
      var url = this.urlbookmark ? this.urlbookmark.url : (this.urldata ? this.urldata.url : this.url);
      var parsedURL = new URL(url);
      var defaultShade = '500';
      var colors = [
        ['red', defaultShade],
        ['pink', defaultShade],
        ['purple', defaultShade],
        ['deep-purple', defaultShade],
        ['indigo', defaultShade],
        ['blue', defaultShade],
        ['light-blue', defaultShade],
        ['cyan', defaultShade],
        ['teal', defaultShade],
        ['green', defaultShade],
        ['light-green', defaultShade],
        //['lime', defaultShade],
        //['yellow', defaultShade],
        ['amber', defaultShade],
        ['orange', defaultShade],
        ['deep-orange', defaultShade],
        ['brown', defaultShade],
        //['grey', defaultShade],
        ['blue-grey', defaultShade],
      ];
      var color = randomMaterialColor({seed: parsedURL.host, colors: colors});
      return color;
    },


    titleThumbSlice: function(){
      var len = 2;

      try {
        var url = this.urlbookmark ? this.urlbookmark.url : this.urldata ? this.urldata.url : this.url;
        var parsedURL = new URL(this.url);
      } catch(e) {
        var parsedURL = null;
      }

      if (this.visibleTitle) {
        return this.visibleTitle.slice(0, len)
      }

      else if(parsedURL){
        return (parsedURL.host.slice(0, 4)==='www.') ? parsedURL.host.slice(4, 4+len) : parsedURL.host.slice(0, len);
      }

      else {
        return '?';
      }

    },

  },


  created: function(){
    this.reloadThumbnails();
  },


  updated: function(){
  },


  watch: {

    urldata: {
      deep: true,
      handler: function(){
        this.reloadThumbnails();
      },
    },

    urlbookmark: {
      deep: true,
      handler: function(){
        this.reloadThumbnails();
      },
    },

  },

  methods: {


    showUrlPop: function(event){
      this.urlPopValue = '';
      this.urlPopMouseEvent = event;
      this.urlPop = true;
      this.$nextTick(function(){
        this.$refs.urlPopInput ? this.$refs.urlPopInput.focus() : null;
      });
    },

    hideUrlPop: function(){
      this.urlPopValue = '';
      this.urlPopMouseEvent = false;
      this.urlPop = false;
      this.hideOverlaysDelayed();
    },


    generateFromUrlPop: function(){
      if (!this.urlPopValue) {
        this.showStatus('No URL provided. Cancelled. ');
        this.hideUrlPop();
        return;
      }
      this.editURLBookmarkThumb();
      this.hideUrlPop();
    },


    reloadThumbnails: function(){

      this.thumbnails = [];


      // if we don't have access yet to urlbookmark or urldata...
      if (!this.urldata) {
        this.thumbnails.push({
          kind: 'blank',
          source: '',
          visible: true,
        });
        return;
      }
      else if (!this.urlbookmark) {
        this.thumbnails.push({
          kind: this.urldata.thumbnail ? 'urldata' : 'blank',
          source: this.urldata.thumbnail ? this.urldata.thumbnail : '',
          visible: true,
        });
        return;
      }



      // if we have access to urlbookmark...

      var worn = this.urlbookmark.thumbnail_worn;

      // generate thumbnails

      this.thumbnails.push({
        kind: 'blank',
        visible: false,
        source: '',
      });

      this.urldata.thumbnail ? this.thumbnails.push({
        kind: 'urldata',
        visible: false,
        source: this.urldata.thumbnail,
      }) : null;

      this.urlbookmark.thumbnail ? this.thumbnails.push({
        kind: 'self',
        visible: false,
        source: this.urlbookmark.thumbnail,
      }) : null;


      if (this.urldata.html_icons) {
        for (var k in this.urldata.html_icons) {
          var icon = this.urldata.html_icons[k];
          if (k=='shortcut') continue;
          if (k=='default') continue;
          if (k=='default_16') continue;
          if (k=='default_32') continue;
          if (!this.validateUrl(icon)) continue;
          if (icon) {
            this.thumbnails.push({
              kind: 'htmlicon',
              visible: false,
              source: icon,
            });
          }
        }
      }

      if (this.urldata.html_og && this.urldata.html_og.image && this.urldata.html_og.image.length) {
        var ogImage = this.urldata.html_og.image[0];
        if (this.validateUrl(ogImage)) {
          this.thumbnails.push({
            kind: 'htmlimage',
            visible: false,
            source: ogImage.url ? ogImage.url : ogImage.url_additional,
          })
        };
      }



      this.urldata.html_images ? this.urldata.html_images.forEach(function(img, i){
        if (img && this.validateUrl(img)) {
          this.thumbnails.push({
            kind: 'htmlimage',
            visible: false,
            source: img,
          });
        }
      }.bind(this)) : null;







      // set active

      var kind = '';
      if (false) {} // noop
      else if (worn==='blank') {  kind = 'blank' }
      else if (worn==='self') { kind = 'self' }
      else if (worn==='urldata') { kind = 'urldata'  }
      else {
        kind = this.urldata.thumbnail ? 'urldata' : 'blank';
      }

      // rare or probably non-existent case
      if (worn==='self' && (!this.urlbookmark.thumbnail)) {
        kind = this.urldata.thumbnail ? 'urldata' : 'blank';
      }


      var thumb = _find(this.thumbnails, {kind: kind});
      thumb.visible = true;

    },





    nextThumbnail: function(){
      var index = _findIndex(this.thumbnails, {visible: true});
      this.$set(this.thumbnails[index], 'visible', false);
      this.thumbnails[index+1] ? index++ : index=0;
      this.$set(this.thumbnails[index], 'visible', true);

      //this.status = 'Loading Image...';
      this.imgLoading = true;
    },


    prevThumbnail: function(){
      var index = _findIndex(this.thumbnails, {visible: true});
      this.$set(this.thumbnails[index], 'visible', false);
      this.thumbnails[index-1] ? index-- : index=0;
      this.$set(this.thumbnails[index], 'visible', true);
    },


    confirmThumbnail: function(){
      this.editURLBookmarkThumb();
    },




    showStatus: function(msg, delay){
      this.status = msg;
      setTimeout(function () {
        this.status = null;
      }.bind(this), delay ? delay : 2000);
    },





    inputFileChange: function(event){
      var input = this.$refs.inputFile;
      this.editURLBookmarkThumb(input.files[0]);
    },

    inputFileLabelClick: function(event){
      //console.warn('inputFileLabelClick');
      //this.$refs.inputFile.click();
    },

    inputFileInputClick: function(event){
      //console.warn('inputFileInputClick');
    },


    //setThumbnailAfterUpload: function(urlbookmark){
    //  //_find(this.thumbnails, {visible: true}).visible = false;
    //  //this.thumbnails.splice(2, 0, {
    //  //  kind: 'self',
    //  //  source: urlbookmark.thumbnail,
    //  //  visible: true,
    //  //});
    //},





    trackUploadProgress: function(progressEvent, label){
      var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

      if (label=='upload') {
        this.status = 'Uploading image... ' + percentCompleted + '%';
      }

      else {
        this.status = 'Setting image... ' + percentCompleted + '%';
      }

    },




    createFormData: function(file){

      var formData = new FormData();

      formData.append('url', this.url);

      // sanitize image url a lil bit
      var image_url = this.visibleThumbnail.source;
      image_url && (image_url.slice(0,2) === '//') ? image_url = ('https:' + image_url) : image_url;


      if (this.urlPopValue) {
        formData.append('image_url', this.urlPopValue);
        formData.append('thumbnail_worn', 'self');
        this.urlPopValue = '';
      }

      else if (file) {
        formData.append('image_file', file, file.name);
        formData.append('thumbnail_worn', 'self');
      }

      else if (this.visibleThumbnail.kind==='htmlicon') {
        formData.append('image_url', image_url);
        formData.append('thumbnail_worn', 'self');
      }

      else if (this.visibleThumbnail.kind==='htmlimage') {
        formData.append('image_url', image_url);
        formData.append('thumbnail_worn', 'self');
      }

      else {
        formData.append('thumbnail_worn', this.visibleThumbnail.kind);
      }

      return formData;

    },





    editURLBookmarkThumb: function(file){


      this.$axios({

        url: this.$store.state.api + '/edit-urlbookmark-thumb/',
        method: 'post',
        data: this.createFormData(file),
        onUploadProgress: function(progressEvent){
          if (file) {
            this.trackUploadProgress(progressEvent, 'upload');
          } else {
            this.trackUploadProgress(progressEvent);
          }
        }.bind(this),

      }).then(function(response){
        this.$store.commit('updateItem', response.data.urlbookmark);

        this.status = null;
        file ? this.showStatus('Upload complete.') : this.showStatus('Thumbnail set successfully!');

        this.$store.commit('eventSaveThumbChange', response.data.urlbookmark);
        this.reloadThumbnails();
        //file ? this.setThumbnailAfterUpload(response.data.urlbookmark) : null;
      }.bind(this), function(error){
        this.showStatus('Error! It was logged and we will work on it.');
      }.bind(this));


    },


    //takeScreenshot: function(){
    //  this.activeThumbnail = null;
    //  setTimeout(function() {
    //    this.activeThumbnail = 'https://multitool.serafin.io/sschrome/?url=' + this.url;
    //  }.bind(this), 10);
    //},





    validateUrl: function(url){
      if (!url) return false;
    	if (typeof url !== 'string') return false;
      url = url.toLowerCase();

      var isAbsolute = false;
      var inThumbnails = false;
      var isSvg = false;

      // check absolute
      isAbsolute = (url.slice(0, 2) === '//') || (url.slice(0, 7) === 'http://') || (url.slice(0, 8) === 'https://');

      // check in thumbnails
      this.thumbnails.forEach(function(thumb, i){
        if (thumb.source.toLowerCase() === url) {
          inThumbnails = true;
        }
      });

      // check svg
      isSvg = url.slice(-4) === '.svg';

      return isAbsolute && (!inThumbnails) && (!isSvg);

    },





    hideOverlaysDelayed: function(){
      this.$_overlaysForceVisibleTimeout = setTimeout(function(){
        this.overlaysForceVisible = false;
      }.bind(this), 1000);
    },


    containerMouseEnter: function(){
      this.$_overlaysForceVisibleTimeout ? clearTimeout(this.$_overlaysForceVisibleTimeout) : null;
      this.overlaysForceVisible = true;
    },


    containerMouseLeave: function(){

      if (!this.urlPop) {
        this.hideOverlaysDelayed();
      }

      //setTimeout(function () {
        //this.previewThumbnailIndex = null;
        //this.beforeUploadThumbnail = null;
      //}.bind(this), 200);

      //this.showStatus('Thumbnail not confirmed. Resetted to original...', 2000);


    },


    imgLoad: function(){
      //this.status = '';
      this.imgLoading = false;
    },

    imgLoadError: function(){
      //this.status = '';
      this.imgLoading = false;
    },

  },




};
</script>



<template>
<div class="savethumb" :class="{'mod-status-active': status, 'mod-overlays-force-visible': overlaysForceVisible}" @mouseenter="containerMouseEnter" @mouseleave="containerMouseLeave">


  <div v-if="visibleThumbnail.kind=='blank'"
  class="savethumb-image mod-blank" :style="{ backgroundColor: blankThumbColor }">
    <div>{{titleThumbSlice}}</div>
  </div>

  <div v-else class="savethumb-image">
    <div v-if="imgLoading" class="savethumb-image-spinner"><spinner kind="wave"></spinner></div>
    <img :src="visibleThumbnail.source" @load="imgLoad" @error="imgLoadError">
  </div>



  <span v-if="urlbookmark" class="savethumb-overlays-wrapper">




    <div class="savethumb-overlays mod-top">


      <!-- <button type="button" class="savethumb-btn" @click.prevent="takeScreenshot">SS</button>  -->

      <!--<button type="button" class="savethumb-btn" v-tooltip="'Reset to default thumbnail'"
      @click.prevent=""> <iconsvg icon="material-delete" /></button> -->

      <span>

        <div class="savethumb-label" v-tooltip="'Type of Thumbnail'">
          <span v-if="visibleThumbnail.kind=='blank'">No Image</span>
          <span v-if="visibleThumbnail.kind=='self'">Your Upload</span>
          <span v-if="visibleThumbnail.kind=='urldata'">Website Image</span>
          <span v-if="visibleThumbnail.kind=='htmlicon'">Website Icon</span>
          <span v-if="visibleThumbnail.kind=='htmlimage'">Content Image</span>
        </div>

        <div class="savethumb-label  mod-count"  v-tooltip="'Available Thumbnails'">
          <span>{{visibleThumbnailIndex+1}}/{{thumbnails.length}}</span>
        </div>

      </span>


      <span>

        <button type="button" class="savethumb-btn mod-pseudo-disabled" v-tooltip="'Take Website Screenshot (Planned)'" @click.prevent=""><iconsvg icon="camera-iosglyph" /></button>


        <button type="button" class="savethumb-btn" v-tooltip="'Generate from URL'" @click.prevent="showUrlPop"><iconsvg icon="download-from-the-cloud-iosglyph" /></button>


        <savethumburlpop v-if="urlPop" :mouse-event="urlPopMouseEvent" :on-request-close="hideUrlPop">
          <div class="savethumb-urlpop">
            <span>Generate Thumbnail from URL</span>
            <input type="url" placeholder="Image URL" v-model="urlPopValue" autofocus ref="urlPopInput">
            <button class="mod-primary" type="button" @click="generateFromUrlPop">Generate</button>
            <button type="button" @click="hideUrlPop">Cancel</button>
          </div>
        </savethumburlpop>


        <label @click="inputFileLabelClick" :for="'savethumb-input-file-'+urlbookmark.pk" class="savethumb-btn mod-upload" v-tooltip="selfThumbnailAvailable ? 'Upload New Thumbnail ' : 'Upload Thumbnail'"><iconsvg icon="upload-iosglyph" /></label>

        <!-- Hidden Upload Input -->
        <input type="file" class="savethumb-input-file" :id="'savethumb-input-file-'+urlbookmark.pk" ref="inputFile" @change="inputFileChange" @click="inputFileInputClick" />



      </span>


    </div>




    <div v-if="urlbookmark" class="savethumb-overlays">

      <span>

        <button type="button" class="savethumb-btn" :class="{'mod-disabled': !prevButtonAvailable}" @click.prevent="prevThumbnail"><iconsvg icon="material-back"/></button>

      </span>


      <span>

        <button type="button" class="savethumb-btn mod-confirm"  :class="{'mod-disabled': !confirmButtonAvailable}"
        v-tooltip="'Use this thumbnail'" @click.prevent="confirmThumbnail"><iconsvg icon="material-checkmark" /></button>




      </span>

      <span>


        <button type="button" class="savethumb-btn"  :class="{'mod-disabled': !nextButtonAvailable}" @click.prevent="nextThumbnail"><iconsvg icon="material-forward" /></button>


      </span>


    </div>




    <div v-if="status" class="savethumb-status">
      <div>{{status}}</div>
    </div>





  </span>




</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .savethumb {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    user-select: none;
  }


        .savethumb-image {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: 50% 50%;
          position: relative;

          display: flex;

          z-index: 21;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
            background: darken(white,4);
          }

          &.mod-blank {
            display: block;
            position: relative;
            background: darken(white,85);

            background-size: 100%;
            background-position: 50% 50%;
            background-image: linear-gradient(145deg, rgba(white,.1), rgba(black,.12));

            & > div {
              pointer-events: none;
              user-select: none;
              position: absolute;
              z-index: 3;
              top: 38%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 50px;
              line-height: 0;
              font-weight: 600;
              white-space: nowrap;
              color: rgba(white,.7);
              text-transform: uppercase;
              top: 49%;
            }
          }

        }



        .savethumb-image-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: -0% -0%;
          transform: scale(1) translate(-50%, -50%);
          background: rgba(black,.25);
          padding: 10px;
          pointer-events: none;

          box-shadow: 0 0 150px 75px rgba(black,.28);
          border-radius: 100px;
          z-index: 22;
        }



    .savethumb-overlays-wrapper {
      z-index: 24;
    }


    .savethumb-overlays {
      display: block;
      position: absolute;
      bottom: 0px;
      left: 50%;
      z-index: 24;
      width: 100%;
      //outline: 1px solid red;
      text-align: left;
      display: flex;
      justify-content: space-between;
      padding: 0 5px 8px 5px;
      transform: translateX(-50%);





      opacity: 0;
      html.mod-transitions-enabled & {
        transition: opacity 100ms ease-in;
      }
      .savethumb:hover & {
        opacity: 1;
      }


      .savethumb.savethumb.savethumb.mod-overlays-force-visible & {
        opacity: 1;
      }

      .savethumb.savethumb.savethumb.mod-status-active & {
        opacity: 0;
      }


      &::before {
        position: absolute;
        //top: 50%;
        //top: calc(50% + 5px);
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 90px;
        content: '';
        display: block;
        background: linear-gradient(to bottom, rgba(#111,.0), rgba(#111,.9));
        opacity: .6;
        z-index: 2;

      }


      &.mod-top {
        bottom: auto;
        top: 0;
        padding: 8px 5px 0px 5px;

        &::before {
          bottom: auto;
          top: 0;
          height: 90px;
          opacity: .6;
          background: linear-gradient(to top, rgba(#111,.0), rgba(#111,.9));

        }
      }




      //@media (max-width: 800px) {
      //  opacity: 1;
      //}


    }



      .savethumb-label {
        position: relative;
        z-index: 26;
        margin-left: 2px;
        text-align: left;
        margin-top: 0px;
        font-size: 10px;
        font-weight: 400;
        //text-shadow: 0 0 10px rgba(black,.9), 0 0 5px rgba(black,.9);
        background-color: rgba(white,.7);
        color: rgba(#111,.8);
        //border: 1px solid rgba(white,.1);
        padding: 5px 10px;
        line-height: 1.1;

        font-size: 11px;
        display: block;
        vertical-align: baseline;
        font-weight: 600;



        border-radius: 2px;
        border-radius: 222px;

        &.mod-count {
          display: inline-block;
          font-weight: 600;
          font-size: 10px;
          color: rgba(#111,.6);
          margin-top: 3px;
          padding: 2px 10px 3px 10px;
        }

      }





      .savethumb-btn {
        display: inline-block;
        border-radius: 100px;
        background-color: rgba(white,.7);
        color: rgba(#111,.6);
        padding: 4px;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        margin: 0 2px 0px 2px;
        user-select: none;


        position: relative;
        z-index: 4;


        box-shadow: 0 1px 15px 0 rgba(#111,.2);

        outline: none;
        backface-visibility: hidden;

        &.mod-square-padding {
          padding: 3px;
        }


        &.mod-upload {
          //margin-left: 8px;
        }

        &.mod-confirm {
         // margin-left: 8px;
          padding: 3px;
          /deep/ svg {
            width: 17px;
            height: 17px;
          }
        }

        /deep/ svg {
          width: 15px;
          height: 15px;

          path {
            fill: rgba(#111,.6);
          }
        }


        html.mod-transitions-enabled & {
          transition: color 100ms ease-in, background-color  100ms ease-in;
          /deep/ svg path {
            transition: fill  100ms ease-in;
          }
        }




        &:hover {
          color: rgba(#111,.95);
          background-color: rgba(white,1);
          /deep/ svg path {
            fill: rgba(#111,.95);
          }
        }



        &.mod-disabled.mod-disabled {
          opacity: .3;
          pointer-events: none;
          cursor: default;
        }

        &.mod-pseudo-disabled.mod-pseudo-disabled {
          opacity: .3;
          cursor: default;
        }

      }




      .savethumb-input-file {

        // visually hidden
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px; width: 1px;
        margin: -1px; padding: 0; border: 0;
      }






      .savethumb-urlpop {
        width: 300px;
        background: white;
        padding: 10px;
        font-size: 14px;
        border-radius: 2px;
        box-shadow: 0 1px 5px rgba(black,.1), 0 1px 15px rgba(black,.1), 0 1px 25px rgba(black,.15);
        text-align: left;
        font-size: 0;

        span {
          display: block;
          font-size: 12px;
          font-weight: 600;
          color: rgba(black,.6);
          //border-bottom: 1px solid rgba(black,.04);
          padding-bottom: 5px;
          margin-bottom: 1px;
        }

        input {
          display: block;
          width: 100%;
          border-radius: 2px;
          padding: 5px 8px;
          font-size: 14px;
          outline: none;
          border: 1px solid darken(white,5);

          &:focus {
            background: rgba($clr-ggather, .08);
            border-color: rgba($clr-ggather, .2);
          }
        }

        button {
          display: inline-block;
          padding: 3px 6px;
          font-size: 13px;
          font-weight: 500;
          margin-top: 7px;
          margin-right: 7px;
          border-radius: 2px;

          background: rgba(black, .08);
          color: rgba(black,.6);

          &.mod-primary {
            background: rgba($clr-ggather, .8);
            color: rgba(white,.9);
          }
        }

      }







      .savethumb-status {

        /*
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        width: calc(100% - 10px);
        */

        position: absolute;
        z-index: 30;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        background-color: rgba(#111,.5);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        backface-visibility: hidden;

        div {
          padding: 10px 20px;
          font-size: 12px;
          line-height: 1.1;
          font-weight: 400;
          border-radius: 2px;
          background-color: rgba(white,.95);
          color: rgba(#111,.95);
          box-shadow: 0 1px 10px 0 rgba(#111,.3), 0 0px 35px 0 rgba(#111,.3);
        }

      }





</style>
