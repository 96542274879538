import axios from 'axios';
import Qs from 'qs';

var VueAxios = {
  install: function(Vue, options){

    //var configuredAxios = axios.create();

    axios.defaults.timeout = 1000 * 30;
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

    axios.defaults.transformRequest = [function(data, headers, c, d){

      // json
      var searchKey = 'content-type';
      var headerContentType = (
        headers[Object.keys(headers).find(key => key.toLowerCase() === searchKey.toLowerCase())]
      );
      if (headerContentType && (headerContentType.toLowerCase().indexOf('application/json') > -1)) {
        return JSON.stringify(data);
      }

      // FormData
      if (data && data.constructor && (data.constructor.name == 'FormData')){
        return data;
      }

      // raw object
      else if (data && (typeof data == 'object')) {
        return Qs.stringify(data, {arrayFormat: 'brackets'});
      }

      // anything else
      return data;

    }];


    //axios.defaults.transformResponse = [function(data){
    //    //console.log(data);
    //    //console.warn('--- Error caught through Axios ----');
    //    //console.error(error);
    //    //console.dir(error);
    //    //console.warn('--- /Error caught through Axios ----');
    //    return data;
    //}];

    var logError = function(error){
      if (error.response && (error.response.status!==404)) console.error(error);
    };



    axios.interceptors.response.use(

      // good response
      function(response){
        return response;
      },

      // bad response
      function(error){
        //if (error.response.status == 401) {
        //    alert('Error 401. You aren\'t authenticated to perform that action.');
        //}
        logError(error);
        return Promise.reject(error);
      }

    );



    //axios.defaults.paramsSerializer = function(params){
    //    return Qs.stringify(params, {arrayFormat: 'brackets'});
    //};

    Vue.axios = axios;
    Vue.prototype.$axios = axios;

  },
};

export default VueAxios;
