<script>
export default {

  data: function(){
    return {
      topTitle: null,
    }
  },


  computed: {
    dialogbox: function(){
      return this.$route.meta.dialogbox || this.$store.state.dialogbox;
    },
  },

  watch: {
    'dialogbox.kind': function(){
      this.topTitle = null;
    },
    '$store.state.eventCloseDialogbox': function(){
      this.closeDialogbox();
    },


    //'$route': function(newVal, oldVal){

      // close dialogbox when clicking on subcribers, etc.
      // and also on browser history navigation (prev/next page)

      //console.dir(JSON.stringify(newVal));
      //console.dir(JSON.stringify(oldVal));

      //if (newVal.meta.kind!=oldVal.meta.kind) {
      //  this.closeDialogbox();
      //  //console.warn('close!');
      //}
    //},

  },


  mounted: function(){
    this.globalKeyup(1);
  },

  beforeDestroy: function(){
    this.globalKeyup(0);
  },




  methods: {


    keyupHandler: function(event){

      var KEY_ESC = event.which == 27;

      // dont handle when in input or textarea but allow radios and checkboxes
      if (event.target.tagName == 'INPUT' || event.target.tagName == 'TEXTAREA') {
        if ( (event.target.type != 'radio') && (event.target.type != 'checkbox') ) {
          return false;
        }
      }

      if (KEY_ESC) {
        this.closeDialogbox();
        return false;
      }
    },



    globalKeyup: function(init){

      if (!init) {
        this._windowKeyupDialogbox ? window.removeEventListener('keyup', this._windowKeyupDialogbox) : '';
        return false;
      }

      var keyupHandler = this.keyupHandler;
      this._windowKeyupDialogbox = function(event){
        keyupHandler(event);
      }

      window.addEventListener('keyup', this._windowKeyupDialogbox);

    },



    closeAfterEditTag: function(){
      // if it is then just wait for promise to finish
      // and then give couple of ms before closing viewer
      this.$store.state.trackedRequests.editTag.then(function(response){
        setTimeout(function() {
          this.closeDialogbox();
        }.bind(this), 300);
      }.bind(this), function(error){
        setTimeout(function() {
          this.closeDialogbox();
        }.bind(this), 300);
      }.bind(this));
    },


    closeAfterEditCat: function(){
      // same situation as with closeAfterEditTag
      this.$store.state.trackedRequests.editCat.then(function(response){
        setTimeout(function() {
          this.closeDialogbox();
        }.bind(this), 300);
      }.bind(this), function(error){
        setTimeout(function() {
          this.closeDialogbox();
        }.bind(this), 300);
      }.bind(this));
    },


    closeDialogbox: function(event, fromBackground){


      // special case for signinup...
      var signinupStore = this.$store.state.dialogbox && (this.$store.state.dialogbox.kind==='signinup');
      var signinupRoute = this.$route.meta.dialogbox && (this.$route.meta.dialogbox.kind==='signinup');
      if (fromBackground && (signinupStore || signinupRoute)) {
        return false;
      }


      // check if tag edit tag request is processing
      if (this.$store.state.trackedRequests.editTag) {
        this.closeAfterEditTag(); return false;
      }

      // check if tag edit category request is processing
      if (this.$store.state.trackedRequests.editCat) {
        this.closeAfterEditCat(); return false;
      }

      this.topTitle = null;

      if (this.$store.state.dialogbox) {
        this.$store.commit('dialogbox', false);
      }

      if (this.$route.meta.dialogbox) {
        this.$router.push('/');
      }


    },

  }

};
</script>


<template>
<div class="dialogbox" v-if="dialogbox">

  <div class="dialogbox-background"  @click.prevent="closeDialogbox($event, 1)"></div>

  <div class="dialogbox-foreground-outer " @click.prevent="closeDialogbox($event, 1)">
    <div class="dialogbox-foreground" @click.stop  >

      <div class="dialogbox-top-title">
        {{topTitle}}
      </div>

      <button type="button" class="dialogbox-close-btn" @click.prevent="closeDialogbox">
        Close
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
          <path d="M 5.7070312 4.2929688 L 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 L 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 L 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 L 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 z"></path>
        </svg>
      </button>

      <signinup v-model="topTitle" v-if="dialogbox.kind=='signinup'" :subkind="dialogbox.subkind">
      </signinup>

      <tagbox v-if="dialogbox.kind=='tagbox'" v-model="topTitle"
      :initial-subkind="dialogbox.subkind" :initial-tag="dialogbox.tag">
      </tagbox>

      <followsbox v-if="dialogbox.kind=='followsbox'" v-model="topTitle" kind="profile"
      :initial-subkind="dialogbox.subkind" :initial-profile="dialogbox.profile">
      </followsbox>

      <profeature v-if="dialogbox.kind=='profeature'" :initial-subkind="dialogbox.subkind" :feature-code="dialogbox.featureCode"></profeature>

    </div>
  </div>


</div>
</template>



<style lang="scss">

  @import "../../../common/style/_variables.scss";


  .dialogbox {


    .vb > .vb-dragger {
      z-index: 2;
      width: 10px;
      right: 0;
      z-index: 150;
    }

    .vb > .vb-dragger > .vb-dragger-styler {
      html.mod-transitions-enabled & {
        transition:
          background-color 200ms ease-out,
          margin 200ms ease-out,
          height 200ms ease-out,
          border-radius 200ms ease-out;
      }
      backface-visibility: hidden;
      transform: rotate3d(0,0,0,0);
      background-color: rgba(200, 200, 200, .0);
      margin: 2px 3px 2px 0;
      border-radius: 222px;
      height: calc(100% - 4px);
      display: block;
    }

    .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .2);
    }

    .vb > .vb-dragger:hover > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .5);
      margin: 0px;
      height: 100%;
      border-radius: 1px;
    }

    .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .5);
      margin: 0px;
      height: 100%;
      border-radius: 1px;
    }

    .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
      background-color: rgba(200, 200, 200, .5);
    }

  }


</style>

<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .dialogbox {
    position: relative;
    z-index: 110;
  }

    .dialogbox-background {
      position: fixed;
      background-color: rgba(28, 27, 27, 0.95);
      background-color: rgba(14, 21, 31, 0.97);
      //background-color: rgba( darken(white, 15), .98);
      //background-color: rgba(240, 240, 240, 0.98);
      //background-color: rgba( darken(white,85), 0.65);
      background-color: rgba( darken(white,85), 0.95);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      opacity: 1;

    }


    .dialogbox-foreground-outer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-y: scroll;
      display: flex;
      padding: 50px 10px;
      //justify-content: space-around;
      //align-items: center;
      //&::before {
      //    content: '';
      //}
      //&::after {
      //    content: '';
      //}
    }


    .dialogbox-foreground {
      z-index: 60;
      display: block;
      margin: auto;
      position: relative;

      opacity: 1;


    }

      .dialogbox-foreground-inner {
      }


    .dialogbox-top-title {
      position: absolute;
      top: -20px;
      left: 0;
      color: rgba(white,1);
      padding-left: 1px;
      font-size: 12px;
      opacity: .8;
      font-weight: 500;
      max-width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }


    .dialogbox-close-btn {
      position: absolute;
      top: -20px;
      right: 0;
      padding-right: 1px;
      color: rgba(white,1);
      font-size: 12px;
      outline: none;
      opacity: .5;

      > svg {
        fill: rgba(white,1);
        width: 16px;
        height: 16px;
        display: inline-block;
      }

      &:focus,
      &:hover {
        opacity: .7;
      }

    }


</style>















<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .dialogbox-background {
    background-color: rgba(28, 27, 27, 0.95);
  }

  .dialogbox-top-title {
  }

  .dialogbox-close-btn {
  }

}
</style>
