<script>
export default {

  props: [

    'item',
    'context',
    'itemLayout',

    'selected',
    'tagsPopoverOpen',
    'noTags',

  ],


  methods: {

    tagsBtnClick: function(){
      if (this.noTags) return;

      //showTagsPopover($refs.tagsBtn)
      this.$parent.$parent.toggleTagsPopover();
    },

    tagsBtnMouseEnter: function(){
      //showTagsPopover($refs.tagsBtn)
      //this.$parent.$parent.toggleTagsPopover();
    },

    editBtnClick: function(){
      this.$parent.$parent.editLink();
    },

    ratingBtnClick: function(){
      this.$parent.$parent.editLink();
    },

    resaveBtnClick: function(){
      this.$parent.$parent.resaveLink();
    },

  },

}


</script>



<template>
<div class="item-nav-wrapper" v-if="!$store.state.itemsSelectMode"  :class="{'mod-force-visible': tagsPopoverOpen}">


  <!--
  <button type="button" class="item-nav-btn mod-open" @click.stop.prevent @mouseup="linkClick" >
    <span class="icon icon-visible-filled"></span>
  </button>  -->



  <!--
  <a :href="item.url" @click="trackClick" class="item-nav-btn mod-link" v-tooltip="'Open in new tab'" target="_blank">
    <span class="icon icon-uncheck-all-filled"></span>
  </a> -->


  <!--
  <button v-if="item.rating" type="button" class="item-nav-btn mod-rating" @click.stop.prevent="ratingBtnClick">

    <span v-for="r in parseInt(item.rating)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
      <path fill="white" d="M12,20c0,0-9-7.111-9-11.111C3,6.222,5.25,4,7.95,4C9.525,4,11.1,5.139,12,6.25C12.9,5.139,14.25,4,16.05,4 C18.75,4,21,6.222,21,8.889C21,12.889,12,20,12,20z"/>
    </svg>
    </span>


    <span  v-for="r in (item.rating ? 5-parseInt(item.rating) : 5)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
      <path fill="transparent" stroke="white" stroke-width="1px" d="M12,20c0,0-9-7.111-9-11.111C3,6.222,5.25,4,7.95,4C9.525,4,11.1,5.139,12,6.25C12.9,5.139,14.25,4,16.05,4 C18.75,4,21,6.222,21,8.889C21,12.889,12,20,12,20z"/>
    </svg>
    </span>

  </button> -->


  <button type="button" item-nav-btn class="item-nav-btn mod-tags" :class="{'mod-no-tags': noTags}" ref="tagsBtn"
  :title="item.tags.length + ' tag' + (item.tags.length==1 ? '' : 's')" @click.prevent="tagsBtnClick" @mouseenter="tagsBtnMouseEnter">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24" >
      <path d="M 14.783203 4 L 6.6191406 12.164062 C 5.7941406 12.989062 5.7941406 14.329297 6.6191406 15.154297 L 10.845703 19.380859 C 11.670703 20.205859 13.010938 20.205859 13.835938 19.380859 L 22 11.216797 L 22 4 L 14.783203 4 z M 9.3632812 4.2929688 L 1.5859375 12.070312 C 0.81335302 12.842897 0.81188633 14.128319 1.5859375 14.900391 L 6.9492188 20.263672 L 8.3632812 18.849609 L 3 13.484375 L 10.777344 5.7070312 L 9.3632812 4.2929688 z M 19 6 C 19.552 6 20 6.448 20 7 C 20 7.552 19.552 8 19 8 C 18.448 8 18 7.552 18 7 C 18 6.448 18.448 6 19 6 z"></path>
    </svg>
    <span>{{item.tags.length}}</span>

  </button>


  <button v-if="$store.state.user && (item.owner.pk !== $store.state.user.pk)" v-tooltip="'Add to my library'" type="button" class="item-nav-btn mod-resave" @click.stop.prevent="resaveBtnClick">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24" >
      <path d="M 11 3 L 11 11 L 3 11 L 3 13 L 11 13 L 11 21 L 13 21 L 13 13 L 21 13 L 21 11 L 13 11 L 13 3 L 11 3 z"></path>
    </svg>
  </button>


  <button v-if="$store.state.user && (item.owner.pk === $store.state.user.pk)" type="button" class="item-nav-btn mod-edit" @click.stop.prevent="editBtnClick">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  width="50" height="50" viewBox="0 0 24 24">
      <path d="M 18.414062 2 C 18.158188 2 17.902031 2.0974687 17.707031 2.2929688 L 16 4 L 20 8 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.925594 2.0974687 18.669937 2 18.414062 2 z M 14.5 5.5 L 5 15 C 5 15 6.005 15.005 6.5 15.5 C 6.995 15.995 6.984375 16.984375 6.984375 16.984375 C 6.984375 16.984375 8.004 17.004 8.5 17.5 C 8.996 17.996 9 19 9 19 L 18.5 9.5 L 14.5 5.5 z M 3.6699219 17 L 3 21 L 7 20.330078 L 3.6699219 17 z"></path>
    </svg>
  </button>

  <!--
  <div class="item-nav-btn mod-select" :class="{'mod-selected': selected}">
    <label class="item-nav-select-checkbox">
      <input type="checkbox" v-model="selected" />
      <span></span>
    </label>
  </div> -->

</div>
</template>































<style lang="scss">

  @import "../../../common/style/_variables.scss";


  $clr-light-green-600: green;
  $clr-red: red;
  $clr-grey-200: grey;
  $clr-light-blue-400: blue;
  $clr-orange: orange;





  /*------------------------------------*\
    Item Nav
  \*------------------------------------*/

  .item-nav-wrapper {
    display: inline-block;
    position: absolute;
    overflow: hidden;
    top: 0px;
    right: 0px;
    width: auto;
    padding-top: 5px;
    padding-right: 1px;
    padding-left: 5px;
    padding-bottom: 5px;
    text-align: right;

    .item-outer.mod-feed & {
      padding-right: 5px;
    }

    .item-outer.mod-list & {
      padding-top: 3px;
      padding-right: 0px;
      padding-left: 2px;
      padding-bottom: 2px;
    }

    .item-outer.mod-panel & {
    }

    .item-outer.mod-panel-multiple & {
    }

    .item-outer.mod-grid & {
    }

    .item-outer.mod-grid-fixed & {
    }

    .item-outer.mod-card-fixed & {
    }

    .item-outer.mod-card & {
    }

    .item-outer.mod-card-small & {
    }

    .item-outer.mod-card-fixed-small & {
    }

  }



    .item-nav-btn {
      display: inline-block;
      padding: 4px 8px 4px 8px;
      font-size: 12px;
      font-weight: 500;
      position: relative;
      z-index: 101;

      background-color: darken(white,70);
      border: 1px solid darken(white,65);
      color: #eee;
      border-radius: 4px;
      outline: none;
      text-align: center;
      vertical-align: top;
      line-height: 1;
      opacity: 0;

      min-width: 50px;

      html.mod-transitions-enabled & {
        transition: opacity 150ms ease-out, background-color 100ms ease-out, border-color 100ms ease-out, transform 100ms ease-out;
      }


      svg {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        fill: white;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .item-outer.mod-list & {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .item-outer.mod-card-small & {
        padding-left: 10px;
        padding-right: 10px;
      }

      .item-outer.mod-card-fixed-small & {
        padding-left: 10px;
        padding-right: 10px;
      }

      &.mod-open {
        margin-right: 4px;
      }

      &.mod-link {
        padding-top: 4px;
        margin-right: 4px;
        position: relative;
        min-width: 47px;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.mod-rating {
        margin-right: 4px;
        font-size: 0;
      }


      &.mod-tags {
        margin-right: 4px;
      }


      &.mod-resave {
        margin-right: 4px;
      }


      &.mod-edit {
        margin-right: 4px;
      }


      &.mod-select {
        width: 47px;
      }

      .mod-force-visible &,
      .item:hover & {
        opacity: .95;
      }


      .mod-force-visible &:hover,
      .item:hover &:hover {
        transition-delay: 0ms;
        opacity: 1;
      }

      .mod-force-visible &.mod-no-tags.mod-no-tags.mod-no-tags,
      .item:hover &.mod-no-tags.mod-no-tags.mod-no-tags {
        opacity: .5;
      }

    }




      .item-nav-select-checkbox {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        input {
          cursor: pointer;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
        }

        span {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          &::before {
            content: '\e018';
            font-family: 'ggather';
            display: block;
            font-size: 10px;

            line-height: 17px;
            //box-shadow: 0 1px 5px 0 rgba(black,1);
            width: 17px;
            height: 17px;
            color: rgba($clr-ggather,.5);
            background: white;
            //border: 1px solid darken(white,99);
            border-radius: 22px;
            margin-top: 9px;
            margin-left: 15px;

            html.mod-transitions-enabled & {
              transition: border 200ms ease-in-out, background-color 200ms ease-in-out;
            }
          }
        }

        input:checked + span::before {
          background: $clr-ggather;
          border: 1px solid darken($clr-ggather,10);
          color: white;
        }

      }













</style>











<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .item-nav-btn {
    background-color: darken(white,70);
    border-color: darken(white,65);
  }


}
</style>
