<script>
export default {

  props: [

    'item',
    'context',
    'itemLayout',

    'isHighlighted',
    'selected',
    'unseen',
    'tagsPopoverOpen',
    'noTags',
    'followedTagsFirst',

    'title',
    'description',
    'titleThumbSlice',
    'thumbnail',
    'thumbnailHeight',
    'thumbnailWidth',
    'thumbnailColor',

    'selectOverlayClick',
    'linkClick',
    'linkStandardClick',

    'itemThumbLoad',

  ],

}
</script>





<template>
<div class="item item-feed" >

  <itemnav

    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :selected="selected"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"

  />



  <a class="item-feed-wrapper" :href="item.url" @click="linkStandardClick" @mouseup="linkClick" rel="noopener noreferrer nofollow" target="_blank">

    <div v-if="unseen" class="item-unseen-notification  mod-feed" v-tooltip="'New since your last visit. <br>Added ' + $dfFormatDistanceFromNow(item.date_created) + '.'"></div>




    <div v-if="thumbnail" class="item-feed-thumbnail" :style="{ backgroundImage: 'url(' + thumbnail + ')' }">
    </div>

    <div v-if="!thumbnail" class="item-feed-thumbnail mod-blank" :style="{ backgroundColor: thumbnailColor }">
      <div>{{titleThumbSlice}}</div>
    </div>


    <div class="item-feed-text">


      <div class="item-feed-title-description-note">


      <div class="item-feed-title" :title="title">{{title}}</div>

      <div class="item-feed-description" :title="description">{{description}}</div>

      <div class="item-feed-note" v-if="item.owner_notes">
        <itemnote
          :item-layout="itemLayout"
          :item-owner-username="item.owner.username"
          :item-owner-profile-image="item.owner.profile_image"
          :item-owner-notes="item.owner_notes"
        />
      </div>


      <div class="item-feed-rating" v-if="item.rating" >
        <itemrate
          :item-layout="itemLayout"
          :item-rating="item.rating"
        />
      </div>

      </div>


      <!--
      <div class="item-feed-url-wrapper">

        <div class="item-feed-favicon" :style="{backgroundImage: 'url(\'https://s2.googleusercontent.com/s2/favicons?domain_url=' + item.url +'\')'}"></div>

        <div class="item-feed-url">{{item.url}}</div>

      </div> -->



    </div>






  </a>


  <div class="item-feed-meta-wrapper">


    <router-link v-tooltip="'Link added by ' + item.owner.username" :to="'/'+item.owner.username" class="item-feed-meta-user-profile-image" :style="{backgroundImage: 'url('+ item.owner.profile_image +')'}"></router-link>


    <div class="item-feed-meta-addition">
      <router-link :to="'/'+item.owner.username" class="item-feed-meta-username">{{item.owner.username}}</router-link>
      {{item.date_created|dfFormatDistanceFromNow}}
     </div>



    <div class="item-feed-meta-rating-and-tags">




      <div class="item-feed-meta-tags">
        <router-link :to="'/'+tag.owner.username+'/'+tag.slug" class="item-feed-tag" v-for="(tag, index) in followedTagsFirst" :key="'item'+item.pk+'feedtag'+tag.pk" :class="['mod-color-'+tag.color, {'mod-is-followed': tag.is_followed}]"
        v-tooltip="('<strong>' + tag.name + '</strong> by ' + tag.owner.username) + (tag.is_followed ? '<br>  You\'re subscribed to this tag' : '')">
          <span class="text">
            {{tag.name}}
          </span>
        </router-link>
      </div>

    </div>



  </div>



</div>
</template>































<style lang="scss">

  @import "../../../common/style/_variables.scss";


  $clr-light-green-600: green;
  $clr-red: red;
  $clr-grey-200: grey;
  $clr-light-blue-400: blue;
  $clr-orange: orange;



  /*------------------------------------*\
    Layout - Feed
  \*------------------------------------*/

  .item-feed {
    display: block;
    font-size: 0;
    position: relative;

    overflow: hidden;
  }



    .item-feed-wrapper {
      display: block;
      width: 100%;
      //width: calc(100% - 180px);

      background-color: white;
      border-radius: 4px 4px 0 0;
      //min-height: 125px;

      //padding: 5px;
      padding-left: 160px;


      border-top:    1px solid darken(white,10);
      border-right:  1px solid darken(white,10);
      border-left:   1px solid darken(white,10);
      //border-bottom: 1px solid darken(white,6);


      //@media (max-width: 700px) {
        padding-left: 0;
      //}
    }

      .item-feed-text {
        display: block;
        width:  100%;
        position: relative;
        overflow: hidden;
        //width: calc(100% - 200px);

        position: relative;

        html.mod-transitions-enabled & {
          transition: max-height 1000ms ease-out;
        }

      }


      .item-feed-title-description-note {
        height: 75px;
        overflow: hidden;
        display: block;
        width: 100%;
        height: auto;
        padding-bottom: 15px;
      }

      .item-feed-title {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: rgba(black,.8);
        line-height: 1.1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-top: 17px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .item-feed-description {
        display: block;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        color: rgba(black,.6);
        margin-top: 5px;
        line-height: 1.2;
        max-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-top: 0px;
        padding-left: 15px;
        padding-right: 15px;

      }

      .item-feed-rating {
        position: relative;
        opacity: 1;
        display: block;
        padding: 10px 14px 0 14px;
      }

      .item-feed-note {
        position: relative;
        opacity: 1;
        display: block;
        padding: 10px 14px 0 14px;
      }



      .item-feed-url-wrapper {
        position: relative;
        opacity: 1;
        padding-left: 34px;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 2px;
        padding-bottom: 15px;
      }


      .item-feed-favicon {
        display: inline-block;
        width: 12px;
        height: 12px;
        opacity: .5;
        position: absolute;
        top: 6px;
        left: 15px;
        background-size: contain;


        html.mod-transitions-enabled & {
          transition: opacity 250ms ease-out;
        }
        .item-feed-wrapper:hover & {
          opacity: 1;
        }


        //@media (max-width: 700px) {
          top: 2px;
        //}
      }

      .item-feed-url {
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
        color: rgba(black,.4);
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;

        html.mod-transitions-enabled & {
          transition: color 250ms ease-out;
        }
        .item-feed-wrapper:hover & {
          color: rgba(black,.7);
        }
      }




      .item-feed-thumbnail {
        display: inline-block;
        width: 160px;
        height: 100px;
        background-size: cover;
        background-position: 50%;
        border-radius: 4px 0 0 0;

        border-right: 1px solid darken(white,6);
        background-color: darken(white,2);

        position: absolute;
        top: 1px;
        left: 1px;

        &.mod-blank {
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 64px;
          line-height: 0;
          font-weight: 600;
          white-space: nowrap;
          color: rgba(white,.7);
          text-transform: uppercase;

          background-size: 100%;
          background-position: 50% 50%;
          background-image: linear-gradient(145deg, rgba(white,.1), rgba(black,.12));
        }

        //@media (max-width: 700px) {
          position: static;
          display: block;
          width: 100%;
          height: 100px;
          border: none;
          border-bottom: 1px solid darken(white,6);
        //}

        height: 200px;

      }
























    .item-feed-meta-wrapper {
      //width: 180px;
      display: inline-block;
      position: relative;
      background-color: darken(white,0);
      //min-height: 125px;
      position: relative;

      display: block;

      border-radius: 0 0 5px 5px;

      padding: 8px 7px 4px 7px;

      border-top:    1px solid darken(white,6);
      border-right:  1px solid darken(white,10);
      border-left:   1px solid darken(white,10);
      border-bottom: 1px solid darken(white,10);

      //box-shadow: 0 -2px 5px 0 rgba(black, 0.06);

      //@media (max-width: 700px) {
        padding-left: 15px;
        padding-right: 15px;
      //}
    }

      .item-feed-meta-user-profile-image {
        width: 16px;
        height: 16px;
        display: inline-block;
        background-size: cover;
        background-position: 50%;
        overflow: hidden;
        border-radius: 100px;
        background-color: white;
        margin-right: 8px;
        box-shadow: 0 1px 5px 0 rgba(black,.15);
        z-index: 20;
        opacity: .95;
        html.mod-transitions-enabled & {
          transition: box-shadow 200ms ease-out, opacity 200ms ease-out;
        }

        &:hover {
          opacity: 1;
          box-shadow: 0 2px 5px 0 rgba(black,.2);
        }
      }

      .item-feed-meta-username {
        font-size: 12px;
        font-weight: 600;
        color: rgba(black,.7);
        display: inline-block;
        vertical-align: baseline;
        margin-right: 2px;
      }


      .item-feed-meta-addition {
        font-size: 11px;
        line-height: 1.2;
        padding-right: 10px;
        color: rgba(black,.4);
        padding-top: 3px;
        padding-bottom: 3px;

        position: relative;
        top: -1px;

        display: inline-block;

        //&::before {
        //    content: '';
        //    position: absolute;
        //    top: 28px;
        //    left: -1px;
        //    right: 30px;
        //    height: 1px;
        //    background-color: darken(white,5);
        //    display: block;
        //}


      }



      .item-feed-meta-rating-and-tags {
        overflow: hidden;
        display: block;
        position: relative;
        display: inline-block;
      }


      .item-feed-meta-tags {
        font-size: 13px;
        display: block;
        overflow: hidden;
      }

        .item-feed-tag {
          display: block;
          display: inline-block;
          font-size: 11px;
          padding: 2px 6px;
          color: white;
          border-radius: 2px;
          margin-right: 5px;
          line-height: 1.2;

          margin-top: 3px;
          font-weight: 500;
          position: relative;
          top: -3px;

          white-space: nowrap;


          & > .text {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
          }


          &.mod-color-1 { color: rgba($clr-tag-1,.9); background-color: rgba($clr-tag-1,.16);  }
          &.mod-color-2 { color: rgba($clr-tag-2,.9); background-color: rgba($clr-tag-2,.16);  }
          &.mod-color-3 { color: rgba($clr-tag-3,.9); background-color: rgba($clr-tag-3,.16);  }
          &.mod-color-4 { color: rgba($clr-tag-4,.9); background-color: rgba($clr-tag-4,.16);  }
          &.mod-color-5 { color: rgba($clr-tag-5,.9); background-color: rgba($clr-tag-5,.16);  }
          &.mod-color-6 { color: rgba($clr-tag-6,.9); background-color: rgba($clr-tag-6,.16);  }
          &.mod-color-7 { color: rgba($clr-tag-7,.9); background-color: rgba($clr-tag-7,.16);  }
          &.mod-color-8 { color: rgba($clr-tag-8,.9); background-color: rgba($clr-tag-8,.16);  }
          &.mod-color-9 { color: rgba($clr-tag-9,.9); background-color: rgba($clr-tag-9,.16);  }

          html.mod-transitions-enabled & {
            transition: opacity 200ms ease-out;
          }


          opacity: .8;


          &.mod-is-followed {
          }




          &:hover {
            opacity: 1;
          }

        }


















</style>














<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .item-feed-meta-wrapper {
    background-color: darken(white,83);
    border-color: darken(white,77);
  }

  .item-feed-meta-rating-and-tags::after {
    background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0), #1e1e1e);
  }

  .item-feed-meta-rating-and-note::after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #1e1e1e);
  }


  .item-feed-wrapper {
    background-color: darken(white,83);
    border-color: darken(white,77);
  }

  .item-feed-url-wrapper {
    border-color: darken(white,77);
  }


  .item-feed-url {
    color: darken(white,55);
  }

  .item-feed-thumbnail {
    background-color: darken(white,79);
    border-color: darken(white,77);
  }
  .item-feed-thumbnail.mod-blank {
    border-color: darken(white,77);
    //color: rgba(white,.5);
    //font-weight: 600;
  }


  .item-feed-text::after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), lighten($clr-theme-dark,13));
  }


  .item-feed-meta-username {
    color: darken(white,25);
  }

  .item-feed-meta-username {
    color: darken(white,25);
  }

  .item-feed-meta-addition {
    color: darken(white,35);
  }

  .item-feed-title {
    color: darken(white,25);
  }

  .item-feed-description {
    color: darken(white,45);
  }






}
</style>
