<script>
export default {

  name: 'page-items-feed',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },


  mounted: function(){
    this.$store.dispatch('getFollowedTagsUsers', 1);
  },

  beforeDestroy: function(){
    this.$store.commit('unseenItemsCountZero');
  },

  methods: {

  },


};
</script>


<template>
<div class="router-page" v-if="$store.getters.userIsAuthenticated">

  <socialtabs context="feed" />

  <div class="items-feed">
    <items context="feed"></items>
  </div>

</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .items-feed {
    margin-top: -20px;
  }





</style>
