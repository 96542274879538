
// "DashDash" custom helpers, mostly replacements for jquery




var DashDash = function(arg){
  return {
    arg: arg,
    on: on,
    off: off,
  }
};





function on(eventName, selector, callback){
  this.arg.addEventListener(eventName, function(e){
    if (e.target && e.target.matches(selector)) {
      callback.call(this, e);
    }
  }, {
    passive: false,
    capture: true,
    once: false,
  });
}


function off(eventName){
  var evList = getEventListeners(this.arg)[eventName];
  if (!evList.length) {return false};
  for(var key in evList){
    var ev = evList[key];
    this.arg.removeEventListener(ev.type, ev.listener, {
      passive: ev.passive,
      capture: ev.useCapture,
      once: ev.once
    });
  }
  return true;
}





// https://stackoverflow.com/a/5306832
DashDash.moveIndex = function(arr, oldIndex, newIndex){
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length;
    while ((k--) + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};



DashDash.parseHTML = function(str){
  var doc = document.implementation.createHTMLDocument();
  doc.body.innerHTML = str;
  return doc;
};

DashDash.each = function(arr, fn){
  return Array.prototype.forEach.call(arr, fn);
};


DashDash.parents = function(el, selector){

  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Setup parents array
  var parents = [];

  // Get matching parent elements
  for ( ; el && el !== document; el = el.parentNode ) {

    // Add matching parents to array
    if ( selector && el.matches) {

      if ( el.matches( selector ) ) {
        parents.push( el );
      }
    } else {
      parents.push( el );
    }

  }

  return parents;

};




DashDash.filterTag = function(el, tagName){
  return el.nodeName.toLowerCase() == tagName;
}



DashDash.prev = function(el, tagName){
  var siblings = [];

  while (el = el.previousSibling) {

    if (tagName) {

      if ( DashDash.filterTag(el, tagName) ) {
        return el;
      }

    } else {
      siblings.push(el);
    }
  }

  return siblings;
};



DashDash.text = function(el){
  return (el && el.innerText) ? el.innerText : '';
};


DashDash.attr = function(el, name){
  return el && el.attributes && el.attributes[name] ? el.attributes[name].value : '';
};





DashDash.hasClass = function(el, className) {
  return el.classList ? el.classList.contains(className) : new RegExp('\\b'+ className+'\\b').test(el.className);
}


DashDash.addClass = function(el, className) {
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ' ' + className;
}


DashDash.removeClass = function(el, className) {
  if (el.classList) el.classList.remove(className);
  else el.className = el.className.replace(new RegExp('\\b'+ className+'\\b', 'g'), '');
}





var __ = DashDash;


window.__ = __;

export default __;
