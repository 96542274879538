<script>
import contains from 'dom-helpers/contains';
export default {

  data: function(){
    return {
    }
  },

  computed: {
    visible: function(){
      return this.$store.getters.titlebarVisible;
    },
    contentVisible: function(){
      if (this.$route.meta.kind !== 'tagpage') return true;
      else if (this.$store.state.tagPageMeta) return true;
      else return false;
    },
  },

  watch: {
  },

  mounted: function(){
    document.addEventListener('click', this.titlebarBodyClick, false);
  },

  beforeDestroy: function(){
    document.removeEventListener('click', this.titlebarBodyClick, false);
  },

  methods: {


    titlebarBodyClick: function(event){
      if (!this.$refs.titlebarRoot) return;
      var clickedInsideSection = contains(this.$refs.titlebarRoot, event.target);
      if (!clickedInsideSection) this.$store.commit('titlebarMenuEnabled', false);
    },

  },


};
</script>


<template>
<div v-if="visible" class="titlebar" ref="titlebarRoot" :class="[{'mod-scrolled': $store.state.contentScrolled, 'mod-sidebar-collapse': $store.getters.finalSidebarCollapse, 'mod-page-with-sidebar': $store.getters.pageWithSidebar}]">
  <div class="titlebar-inner" v-if="contentVisible">


    <div class="titlebar-content" v-if="$route.meta.kind==='tagpage' || $route.meta.kind==='all' || $route.meta.kind==='search'">
      <div class="titlebar-content-inner">

        <div class="titlebar-heading">
          <titlebarheading />
        </div>

        <div v-if="$route.meta.kind !== 'search'" class="titlebar-itemstoolbox">
          <itemstoolbox :context="$route.meta.kind" />
        </div>

      </div>
    </div>


  </div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";



  .titlebar {
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    z-index: 40;
    //background-color: rgba(252,252,252,1);
    //background: rgba(white,1);
    //backdrop-filter: blur(2px);
    //border-bottom: 1px solid darken(white,10);
    transform: rorate3d(0,0,0,0);
    backface-visibility: hidden;

    background: rgba(darken(white,6),.8);
    backdrop-filter: blur(3px);

    //&.mod-scrolled {
    //  margin-top: 0;
    //  margin-left: 0;
    //  margin-right: 0;
    //  border-radius: 0;
    //  border-bottom-color: darken($clr-ggather,10);
    //  border-color: transparent;
    //  box-shadow: 0 2px 5px 0 rgba(black, .09), 0 2px 20px 5px rgba(black, .09);
    //}



    html.mod-transitions-enabled & {
      transition: background-color 100ms ease-in, border-color 100ms ease-in, transform 300ms ease-in-out;
    }


  }



  .titlebar-inner {
    opacity: 1;
    padding-left: 0px;

    .titlebar.mod-page-with-sidebar & {
      padding-left: 280px;
    }

    .titlebar.mod-page-with-sidebar.mod-sidebar-collapse & {
      padding-left: 0;
    }

  }




  .titlebar-content {
    display: block;
  }

  .titlebar-content-inner {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    max-width: 1200px;
    margin: 0 auto;
  }


   .titlebar-heading {
    margin-top: 8px;
    position: relative;
    z-index: 20;

    @media (max-width: 700px){
      margin-top: 9px;
    }

  }

  .titlebar-heading {
    padding-left: 20px;
    @media (max-width: 900px){
      padding-left: 80px;
    }
  }

  html.mod-page-with-sidebar.mod-final-sidebar-collapse .titlebar-heading {
    @media (max-width: 1212px){
      padding-left: 80px;
    }
  }

  // hack as hell
  @for $i from 1330 through 1212 {
    html.mod-page-with-sidebar.mod-final-sidebar-collapse .titlebar-heading {
      @media (min-width: #{$i}px) and (max-width: #{$i}px){
        padding-left: #{(1373 - $i) / 2}px;
      }
    }
  }


  .titlebar-itemstoolbox {
    display: inline-block;
    text-align: left;
    padding-top: 12px;
    padding-right: 20px;

    html.mod-transitions-enabled & {
      transition: opacity 200ms ease-in-out;
    }

    @media (max-width: 900px){
      padding-right: 10px;
    }


    @media (max-width: 700px){
      padding-top: 9px;
    }
  }




</style>










<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .titlebar {
    //background-color: darken(white,85);
    //border-color: darken(white,80);

    background: rgba(darken(white,90),.8);
    backdrop-filter: blur(3px);
  }

  .titlebar.mod-scrolled {
    //box-shadow: 0 2px 5px 0 rgba(black, .2), 0 2px 20px 5px rgba(black, .3);
  }




}
</style>
