<script>
export default {
  props: [
    'kind',
    'dark',
    'size',
  ]
};
</script>


<template>
<div class="loader" :class="'size-'+(this.size?this.size:2)">

  <div v-if="(!kind) || kind=='circle'">
    <div class="loader-circle" :class="{'mod-dark': dark}"></div>
  </div>

  <div v-else-if="kind=='wave'">
    <div class="loader-wave"  :class="{'mod-dark': dark}"></div>
  </div>

  <div v-else-if="kind=='dots'">
    <div class="loader-dots-1" :class="{'mod-dark': dark}">
      <div class="loader-dots-2">
        <div class="loader-dots-3">
          <div class="loader-dots-dot"></div>
          <div class="loader-dots-dot"></div>
          <div class="loader-dots-dot"></div>
          <div class="loader-dots-dot"></div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>






<style lang="scss" scoped>


  @import "../../../common/style/_variables.scss";

  .loader {
    display: inline-block;
  }



  $loader-circle-size: 12px;
  $loader-circle-thickness: 1px;
  $loader-circle-clr-fg-light: rgba(white, .5);
  $loader-circle-clr-fg-dark: rgba(black, .5);
  $loader-circle-clr-bg-light: rgba(white, .1);
  $loader-circle-clr-bg-dark: rgba(black, .1);

  @keyframes loader-circle-spin {
    0% {
    transform: rotate(0deg);
    }
    100% {
    transform: rotate(360deg);
    }
  }

  .loader-circle {
    animation: loader-circle-spin 1000ms infinite linear;
    width: $loader-circle-size;
    height: $loader-circle-size;
    border-width: $loader-circle-thickness;
    border-style: solid;
    border-radius: $loader-circle-size;
    border-color: $loader-circle-clr-bg-light;
    border-top-color: $loader-circle-clr-bg-light;
    &.mod-dark {
      border-color: $loader-circle-clr-fg-dark;
      border-top-color: $loader-circle-clr-bg-dark;
    }
  }


  .loader.size-1  .loader-circle {
    width: $loader-circle-size;
    height: $loader-circle-size;
    border-width: $loader-circle-thickness;
  }

  .loader.size-2  .loader-circle {
    width: $loader-circle-size * 2;
    height: $loader-circle-size * 2;
    border-width: $loader-circle-thickness * 2;
    border-radius: $loader-circle-size * 2;
  }


  .loader.size-3  .loader-circle {
    width: $loader-circle-size * 3;
    height: $loader-circle-size * 3;
    border-width: $loader-circle-thickness * 3;
    border-radius: $loader-circle-size * 3;
  }










  $loader-wave-clr-light: rgba(white, .4);
  $loader-wave-clr-dark: rgba(black, .4);

  @keyframes loader-wave-after {
    from {
      transform: scale(0.5, 0.5);
      opacity: 0;
    }
    to {
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  @keyframes loader-wave-before {
    from {
      transform: scale(1, 1);
      opacity: 1;
    }
    to {
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
  }


  .loader-wave {
    width: 20px;
    height: 20px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    opacity: 1;

    &::after,
    &::before {
      content: '';
      border: 1px $loader-wave-clr-light solid;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
    }


    &:before {
      transform: scale(1, 1);
      opacity: 1;
      animation: loader-wave-before 0.6s infinite linear;
    }

    &:after {
      transform: scale(0, 0);
      opacity: 0;
      animation: loader-wave-after 0.6s infinite linear;
    }


    &.mod-dark {
      &::after,
      &::before {
        border-color: $loader-wave-clr-dark;
      }
    }

  }


  .loader.size-2 .loader-wave {

    width: 40px;
    height: 40px;
    &::after,
    &::before {
      border-width: 1px;
    }

  }











  $loader-dots-clr-light: rgba(white, .4);
  $loader-dots-clr-dark: rgba(black, .4);

  @keyframes loader-dots {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(30px);
    }
  }


  .loader-dots-1 {
    position: relative;
    display: block;
    min-height: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }


    .loader-dots-2 {
      position: absolute;
      top: 0;
      left: -6px;
      right: 0;
      bottom: 0;
      z-index: 10;
    }


      .loader-dots-3 {
        position: absolute;
        z-index: 5;
        left: 50%;
        top: 50%;
        top: calc(50% - 2px);
        transform: translate(-50%,-50%) scale(1);
        opacity: 1;

      }



        .loader-dots-dot {
          position: absolute;
          padding: 3px;
          border-radius: 50%;
          animation: loader-dots 600ms ease-in-out 0s infinite;
          opacity: 0;
          &:nth-of-type(1) { animation-delay: -300ms; }
          &:nth-of-type(2) { animation-delay: -150ms; }
          &:nth-of-type(3) { animation-delay:  000ms; }
          &:nth-of-type(4) { animation-delay:  150ms; }

          background-color: $loader-dots-clr-light;
          .loader-dots-1.mod-dark & {
            background-color: $loader-dots-clr-dark;
          }
        }













</style>
