<script>
export default {

  name: 'pricingwrapper',


  data: function(){
    return {
    };
  },

  computed: {

    showPricingStatus: function(){
      if (!this.$store.state.user.is_authenticated) return false;

      if (this.$store.state.user.paddle_sub_status === 'active') return true;
      if (this.$store.state.user.paddle_sub_status === 'past_due') return true;

      var paddlePaidTroughInPast = new Date(this.$store.state.user.paddle_sub_paid_through) < new Date();
      if (!paddlePaidTroughInPast && (this.$store.state.user.paddle_sub_status === 'deleted')) return true;

      var braintreePaidThroughInPast = new Date(this.$store.state.user.premium_paid_through) < new Date();
      if (!braintreePaidThroughInPast) return true;

      return false;
    },

    showPricingMgmt: function(){
      if (!this.$store.state.user.mgmt_premium_until) return false;
      return new Date(this.$store.state.user.mgmt_premium_until) > new Date();
    },

  },

  created: function(){
  },

  mounted: function(){
  },


  beforeDestroy: function(){
  },



  methods: {


  },


};
</script>


<template>
<div class="pricingwrapper" >

  <div class="pricingwrapper-front" >

    <div class="pricingwrapper-front-aligner"></div>
    <div class="pricingwrapper-front-aligned">

      <div class="pricingwrapper-content" ref="content">

        <pricingmgmt context="upgrade" v-if="showPricingMgmt" key="pricingmgmgmt" />
        <pricingstatus context="upgrade" v-if="showPricingStatus" key="pricingstatus" />
        <pricinginfo />
        <pricingup />
        <pricingfaq />

      </div>

    </div>

  </div>

</div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .pricingwrapper {

  }

  .pricingwrapper-front {
    box-sizing: border-box;
    text-align: center;
    font-size: 0;
  }

  .pricingwrapper-front-aligner {
    box-sizing: border-box;
    display: inline-block;
    width: 0px;
    height: 100%;
    vertical-align: middle;
  }

  .pricingwrapper-front-aligned {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    z-index: 220;
    padding: 20px 20px 40px 20px;
    @media (max-width: 670px) {
      padding: 5px;
    }
  }


  .pricingwrapper-content {
  }

</style>
