<script>
export default {

  props: [
    'onRequestClose',
    'mouseEvent',
  ],

  data: function(){
    return {
      positionY: 0,
      positionX: 0,
    };
  },


  created: function(){
    this.calculatePosition();
  },

  mounted: function(){
    this.calculatePosition();
  },

  computed: {

    styleObject: function(){
      return {
        top: this.positionY + 'px',
        left: this.positionX + 'px',
      }
    },

  },


  methods: {

    windowWidth: function(){
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    },

    windowHeight: function(){
      return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    },

    calculatePosition: function(){
      var e = this.mouseEvent;
      this.positionY = e.clientY;
      this.positionX = e.clientX;

      this.positionY += 1;
      this.positionX += 1;

      var content = this.$refs.content;
      if (content) {


        // when content would be over the window
        if ((this.positionX + content.clientWidth) > this.windowWidth()) {
          this.positionX = (this.windowWidth() - content.clientWidth - 25);
        }


        //this.positionX = this.positionX - (content.clientWidth/2);


      }
    },

  }

}
</script>


<template>
<portal>
  <div>
    <div class="savethumburlpop-backdrop" @click="onRequestClose"></div>
    <div ref="content" class="savethumburlpop-content" :style="styleObject"><slot></slot></div>
  </div>
</portal>
</template>


<style lang="scss" scoped>

  .savethumburlpop-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black,.5);
    z-index: 2009;
  }

  .savethumburlpop-content {
    position: fixed;
    z-index: 2010;
  }

</style>
