<script>
export default {

  name: 'page-contact',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },

  data: function(){
    return {
      submitErrors: null,
      submitSuccess: null,
      submitLoading: null,
      form: {
        kind: '',
        message: '',
        author_email: '',
        author_name: '',
      },
    }
  },


  methods: {

    resetForm: function(){
      this.submitErrors = null;
      this.submitSuccess = null;
      this.submitLoading = null;
      this.form = {
        kind: '',
        message: '',
        author_email: '',
        author_name: '',
      };
    },

    contactMessage: function(){
      this.submitSuccess = null;
      this.submitErrors = null;
      this.submitLoading = true;
      this.$axios({
        url: this.$store.state.api + '/contact-message/',
        method: 'post',
        data: {
          kind: this.form.kind,
          message: this.form.message,
          author_email: this.form.author_email,
          author_name: this.form.author_name,
        },
      }).then(function(response){
        this.submitSuccess = true;
        this.submitLoading = false;
      }.bind(this), function(error){
        this.submitLoading = false;
        if (error.response && error.response.data && (error.response.data.constructor === Array)) {
          this.submitErrors = error.response.data;
        } else {
          this.submitErrors = ['Something went wrong... Network error.']
        }
      }.bind(this));
    },


  }



};
</script>


<template>
<div class="router-page">
<div class="textpage" style="padding-top: 0px;">

  <div class="contact">


    <div v-if="$store.state.user.is_authenticated" class="contact-back-btn-wrapper">
      <router-link to="/all" class="contact-back-btn">
        ← Back to My Library
      </router-link>
    </div>



    <div class="contact-description">
      <h2>Contact </h2>
      <p>Send email to <a href="mailto:contact@ggather.com">contact@ggather.com</a> or use the form below.</p>
    </div>

    <!--
    <div class="contact-description">
      <h2>We'd love to hear your feedback, complaints, compliments or anything else about GGather.</h2>
      <p>Due to large amount of messages we get - we cant promise we will be able to reply to all of the inquiries - but all will be read and considered. <strong>Of course any messages about important things like billing, your data, etc. will be always replied to.</strong>  </p>
      <p>If you have different messages of different type &mdash; e.g. one is feedback, and other one is complaint &mdash; consider submitting them in multiple messages. It will help us a lot while sorting, prioritizing and replying to them.</p>
      <p>If you are having problems using this contact form please send your message to contact@ggather.com. But have in mind it will have much lower priority than equal message sent using this contact form.</p>
    </div>
     -->



    <div class="contact-form-wrapper">


      <div v-if="submitSuccess" class="contact-form-overlay">
        <div>
          <p>Successfully submitted!</p>
          <p><button type="button"  class="contact-button mod-small" @click="resetForm">New Message</button></p>
        </div>
      </div>

      <div v-if="submitLoading" class="contact-form-overlay">
        <div>
          <p>Submitting message...</p>
        </div>
      </div>


      <form @submit.prevent="contactMessage" class="contact-form">


        <div class="contact-input-group">
          <label for="kind" class="contact-input-label">Message Category</label>
          <div class="contact-input-control">
            <div class="contact-btngroup">
              <!--
              <button class="contact-btngroup-btn" :class="{'mod-active': form.kind == 'question'}" @click="form.kind = 'question'" type="button">Question</button>
              -->
              <button class="contact-btngroup-btn" :class="{'mod-active': form.kind == 'feedback'}" @click="form.kind = 'feedback'" type="button">Feedback</button>
              <button class="contact-btngroup-btn" :class="{'mod-active': form.kind == 'issue'}" @click="form.kind = 'issue'" type="button">Issue</button>
              <button class="contact-btngroup-btn" :class="{'mod-active': form.kind == 'payments'}" @click="form.kind = 'payments'" type="button">Payments</button>
              <button class="contact-btngroup-btn" :class="{'mod-active': form.kind == 'other'}" @click="form.kind = 'other'" type="button">Other</button>
            </div>
          </div>
        </div>


        <!--
        <div class="contact-input-group">
         -->

          <!--
          <label for="kind" class="contact-input-label">Message Type</label>
          <div class="contact-input-control">
            <select id="kind" v-model="form.kind" required>
              <option disabled selected value="">Select...</option>
              <option value="feedback">Feedback</option>
              <option value="question">Question</option>
              <option value="money">Payments</option>
              <option value="praise">Praise</option>
              <option value="security">Vulnerability</option>
              <option value="complaint">Complaint</option>
              <option value="business">Business</option>
              <option value="other">Other</option>
            </select>
          </div>
           -->


          <!--
          <div v-if="form.kind==='feedback'" class="contact-form-kind-description">
            <span>
              <p>It's very important that GGather is build with collaboration with its users.</p>
              <p>Every feedback and opinion matters and will be taken highly into consideration when improving and building future of GGather.</p>
            </span>
          </div>
           -->

          <!--
          <div v-if="form.kind==='money'" class="contact-form-kind-description">
            <span>
              <p>GGather is a "bootstrapped" venture. Every single subscription counts and will go into growing and maintaining GGather.</p>
              <p>At this point in time all payments are final and non-refundable. You can cancel your subscription in your billing settings.</p>
            </span>
          </div>
           -->

          <!--
          <div v-if="form.kind==='question'" class="contact-form-kind-description">
            <span>
              <p>GGather doesn't have any onboarding or help section yet. So it's very understandable that you might have some questions.</p>
              <p>Ask away and we'll answer to the best ability!</p>
            </span>
          </div>-->

          <!--
          <div v-if="form.kind==='praise'" class="contact-form-kind-description">
            <span>
              <p>Is GGather doing something that makes you happy?</p>
            </span>
          </div>
           -->

          <!--
          <div v-if="form.kind==='complaint'" class="contact-form-kind-description">
            <span>
              <p>If GGather made you angry or dissapointed in some way - let us know.</p>
            </span>
          </div>
           -->

          <!--
          <div v-if="form.kind==='business'" class="contact-form-kind-description">
            <span>
              <p>I'm open for any business and collaboration opportunities with any companies or indie hackers.</p>
            </span>
          </div> -->

          <!--
          <div v-if="form.kind==='security'" class="contact-form-kind-description">
            <span>
              <p>Security is one of the top priorities for GGather. If you have found any vulnerability, please let us know.</p>
              <p>Unfortunately we can't offer any big bounties right now. But if the reported issue was major then we'll try to compensate you in some way.</p>
            </span>
          </div>
           -->

          <!--
          <div v-if="form.kind==='other'" class="contact-form-kind-description">
            <span>
              <p>Your message doesn't quite fit the other message types? We're curious what it might be!</p>
            </span>
          </div>
           -->

        <!--
        </div>
         -->


        <div class="contact-input-group">
          <label for="message" class="contact-input-label">
            Your
            <span v-if="form.kind==='feedback'">Feedback</span>
            <span v-else-if="form.kind==='question'">Question</span>
            <span v-else-if="form.kind==='issue'">Issue</span>
            <span v-else>Message</span>
          </label>
          <div class="contact-input-control">
            <textarea id="message" v-model="form.message" rows="5" maxlength="10000" required></textarea>
          </div>
        </div>



        <div v-if="$store.state.user.is_anonymous" class="contact-input-group">
          <label for="author_email" class="contact-input-label">Your Email</label>
          <div class="contact-input-control">
            <input id="author_email" type="email" v-model="form.author_email" maxlength="100" required>
          </div>
        </div>

        <div v-if="$store.state.user.is_anonymous" class="contact-input-group">
          <label for="author_name" class="contact-input-label">Your Name</label>
          <div class="contact-input-control">
            <input id="author_name" type="text" v-model="form.author_name" maxlength="100" required>
          </div>
        </div>

        <div v-if="submitErrors && submitErrors.length" class="contact-form-errors">
          <p v-for="(error, index) in submitErrors" :key="error + '' + index">{{error}}</p>
        </div>

        <div class="contact-input-group">
          <div class="contact-input-control">
            <button type="submit" class="contact-button">Send Message</button>
          </div>
        </div>

      </form>
    </div>




  </div>

</div>
</div>
</template>


<style lang="scss" scoped>



  @import "../../../common/style/_variables.scss";


  .contact {

    max-width: 600px;
    margin: 0 auto;

    padding-top: 25px;
  }

  .contact-back-btn-wrapper {
  }


  .contact-back-btn {
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: block;
    text-align: left;
    outline: none;
    width: 100%;
    color: darken(white,90);

  }


  .contact-description {

    padding-top: 30px;
    margin-bottom: 25px;

    h2 {
      display: block;
      margin: 0;
    }
    p {
      display: block;
      margin: 0;
      margin-top: 5px;
      font-size: 15px;
      color: rgba(black,.7);
    }
  }

  /*
  .contact-description p a[href*="mailto"] {
    color: rgba(black,.7);
  }
  */



  .contact-form-wrapper {
    display: block;
    position: relative;
  }

    .contact-form-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(whitesmoke, .8);
      background: white;
      border: 1px solid darken(white,10);
      border-radius: 4px;
      padding: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      &,
      & p
      {
        line-height: 1.3;
        font-size: 15px;
        font-weight: 400;
        color: darken($clr-ggather,10);
      }

      p {
        margin: 0;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }




    }

    .contact-form {

    }



    .contact-form-errors {
      background: rgba($clr-red,.2);
      border: 1px solid rgba($clr-red,.2);
      padding: 12px 18px;
      border-radius: 4px;
      margin-top: 20px;

      &,
      & p
      {
        line-height: 1.3;
        font-size: 15px;
        font-weight: 400;
        color: darken($clr-red,10);
      }

      p {
        margin: 0;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

    }



    .contact-form-kind-description {
      margin-bottom: 5px;
      background: rgba($clr-ggather,.08);
      border: 1px solid  rgba($clr-ggather,.08);
      padding: 12px 18px;
      border-radius: 4px;
      margin-top: 5px;

      &,
      & p
      {
        line-height: 1.3;
        font-size: 15px;
        font-weight: 400;
        color: darken($clr-ggather,10);
      }

      p {
        margin: 0;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

    }





  .contact-input-group {
    margin-top: 20px;


    &.mod-disabled {
      opacity: .5;
      pointer-events: none;
      user-select: none;
    }

    @media (max-width: 900px){
      display: block;

    }
  }

    .contact-input-label {
      display: block;
      font-size: 15px;
      font-weight: 500;
      color: rgba(black,.9);
      margin-bottom: 5px;


      @media (max-width: 900px){
        display: block;
        width: auto;

      }
    }


    .contact-input-control {
      display: block;
      width: auto;

      input[type='checkbox'] {

      }

      textarea,
      select,
      input[type='url'],
      input[type='search'],
      input[type='password'],
      input[type='email'],
      input[type='text'] {

        appearance: none;
        font-size: 15px;
        border: 1px solid darken(white,15);
        background: white;
        width: 100%;
        font-weight: 400;
        //max-width: 300px;
        padding: 12px 18px;
        border-radius: 4px;
        color: rgba(black,.9);
        line-height: 1.3;
        //font-family: monospace, monospace;
      }


      select {
        appearance: unset;
        //appearance: none;
      }

      textarea {
        resize: vertical;
      }


      img {
        width: 100%;
        max-width: 100%;
        display: block;
        border: 1px solid darken(white,10);
        border-radius: 4px;
      }


      img.profile {
        //border-radius: 1000px;
        max-width: 300px;
        margin-bottom: 10px;
        background: darken(white,7);
      }

      img.cover {
        max-width: 300px;
        margin-bottom: 10px;
      }


      input.hidden {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px; width: 1px;
        margin: -1px; padding: 0; border: 0;
      }



      input,
      textarea,
      select {
        outline: none;



        html.mod-transitions-enabled & {
          transition: background-color 100ms ease-out, border-color 100ms ease-out;
        }

        &:hover {
          border-color: darken(white,15);
        }

        &:focus {
          border-color: $clr-ggather;
          background-color: lighten($clr-ggather,40);
          //background-color: darken(white,4);
          //box-shadow: 0 0 0 2px rgba($clr-ggather,.2);
        }
      }

    }



    .contact-input-info {
      display: block;
      font-size: 12px;
      padding: 10px 15px;
      border: 1px solid darken(white,8);
      width: 100%;
      line-height: 1.1;
      max-width: 300px;
      margin-top: 10px;
      border-radius: 4px;


      padding-left: 55px;

      position: relative;


      &::before {
        content: '\e0f1';
        content: '\e066';
        content: '\e0d1';
        font-family: 'ggather';
        font-size: 26px;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        opacity: .5;
      }

      &.mod-warning {
        color: darken($clr-orange-900, 0);
        background-color: lighten($clr-orange-a100, 10);
        border-color: lighten($clr-orange-a100, 1);
      }
    }


  .contact-btngroup {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 1px;
    //border-radius: 4px;
    //overflow: hidden;
    //border: 1px solid darken(white,15);
  }

  .contact-btngroup-btn {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    background: white;
    color: rgba(black,.9);
    font-size: 15px;
    display: block;
    box-shadow: 0 0 0 1px darken(white,15);
    padding: 12px 18px;
    line-height: 1;
    text-align: left;
    outline: none;

    border-radius: 0px;
    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    html.mod-transitions-enabled & {
      transition: box-shadow 100ms ease-out, color 100ms ease-out, background-color 100ms ease-out, border-color 100ms ease-out;
    }
    &:hover {
      background-color: darken(white,5);
    }
    &:focus {
      background-color: darken(white,5);
    }
    &.mod-active {
      background: $clr-ggather;
      color: rgba(white,.9);
      //box-shadow: 0 0 0 1px darken($clr-ggather,20);
      //z-index: 2;
      //position: relative;
    }
  }



  .contact-checkbox-desc {
    font-size: 13px;
    color: rgba(black,.6);
    display: block;
    margin-top: 2px;
    font-weight: 400;
    line-height: 1;

    img {
      width: auto;
      max-width: 100%;
      margin: 7px 0;
      border-radius: 4px;
      border: 1px solid darken(white,10);
      height: auto;
    }
  }

  .contact-checkbox {
    margin-top: 4px;
    display: inline-block;
    position: relative;
    //max-width: 300px;
    //pointer-events: none;

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      clip: rect(0,0,0,0);
    }

    label {
      cursor: pointer;
      position: relative;
      display: inline-block;
      color: rgba(black,.7);
      user-select: none;

      padding-left: 25px;

      font-weight: 500;
      font-size: 15px;
      line-height: 1.2;

      //outline-color: gray;
      outline: none;


      //background
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        //top: 50%;
        //transform: translateY(-50%);
        top: 0px;
        left: 0px;
        width: 16px;
        height: 16px;
        background: rgba(black,.08);
        border: 1px solid rgba(black,.08);
        border-radius: 4px;
        html.mod-transitions-enabled & {
          transition: background-color 80ms ease-out, border-color 80ms ease-out;
        }
      }


    }

    input:checked + label {
      color: rgba(black,.9);
      //font-weight: 500;

      &::before {
        background: rgba(black,.9);
        border-color: rgba(black,.9);
      }

      &::after {
        opacity: 1;
      }

    }

    input:disabled + label {
      cursor: default;
      opacity: .8;
    }



  }



  .contact-button {
    background: $clr-ggather;
    color: rgba(white,.9);
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    margin-top: 0px;
    border: 1px solid darken($clr-ggather,5);
    padding: 12px 18px;
    line-height: 1;
    border-radius: 4px;
    text-align: left;
    outline: none;

    html.mod-transitions-enabled & {
      transition: background-color 100ms ease-out, border-color 100ms ease-out;
    }

    &:hover {
      border-color: darken($clr-ggather,7);
      background-color: darken($clr-ggather,5);
    }

    &:focus {
      border-color: darken($clr-ggather,7);
      background-color: darken($clr-ggather,5);
    }

    &.mod-small {
      padding: 6px 12px;
    }

  }


</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .contact-back-btn {
    color: darken(white,20);
  }

  .contact-description  h2 {
    color: darken(white,5);
  }

  .contact-description p {
    color: darken(white,25);
  }

  .contact hr {
    background-color: darken(white,80);
  }

  .contact-input-label {
    color: darken(white,25);
  }

  .contact-form-kind-description {
    background-color: darken(white,80);
  }

}
</style>
