<script>
export default {

  data: function(){
    return {
    }
  },

  computed: {
    title: function(){
      if (this.$route.meta.kind==='all' && this.$route.query.untagged==='1') {
        return 'Untagged';
      }
      else if (this.$route.meta.kind==='all') {
        return 'All';
      }
      else if (this.$route.meta.kind==='tagpage') {
        return this.$store.state.tagPageMeta ? this.$store.state.tagPageMeta.name : 'UNDEFINED';
      }
    },
    atLeastOneItemSelected: function(){
      return this.$store.state.itemsSelected && this.$store.state.itemsSelected.length;
    },
    allItemsSelected: function(){
      var allItemsCount = this.$store.state.itemsMeta ? this.$store.state.itemsMeta.count : null;
      if (this.$store.state.itemsSelected === '*') {
        return true;
      } else if (allItemsCount) {
        return this.$store.state.itemsSelected.length == allItemsCount;
      }
      return false;
    },
  },

  watch: {
    '$route': function(){
      this.disableSelectMode();
    },
    '$store.state.itemsSelectMode': function(){
      this.$store.commit('viewer', 0);
    },
  },

  methods: {

    disableSelectMode: function(){
      this.$store.commit('unselectAllItems');
      this.$store.commit('itemsSelectMode', false);
    },





    openLinksInNewTabsFn: function(urlsToOpen){
      var vm = this;
      this.$extension.openLinksInNewTabs(urlsToOpen, function(response){

        console.warn(response);
        if (typeof response === 'undefined') {
          vm.$alertify.alert('Due to browser security limitations you need to have installed and enabled GGather extension to use this feature from GGather web application.<br/></br> <a href="https://chrome.google.com/webstore/detail/'+vm.$store.state.chromeExtensionId+'" target="_blank">Get GGather Chrome Extension</a>');
        }

      });
    },


    openSelected: function(){
      if (!this.atLeastOneItemSelected) return;
      if (this.$store.state.itemsSelected==='*') return;

      var urlsToOpen = [];

      this.$store.state.items.forEach(function(el, i){
        if (this.$store.state.itemsSelected.indexOf(el.pk)>-1) {
          urlsToOpen.push(el.url);
        }
      }.bind(this));

      if (urlsToOpen.length > 5) {
        this.$alertify.confirm('Are you sure you want to open '+urlsToOpen.length+' tabs?', function(){
          this.openLinksInNewTabsFn(urlsToOpen);
        }.bind(this));
      } else {
        this.openLinksInNewTabsFn(urlsToOpen);
      }

    },



    deleteSelected: function(){
      if (!this.atLeastOneItemSelected) return;
      this.$alertify.reset();
      this.$alertify.okBtn('delete').confirm(this.confirmMessage('delete'), function(){

        var count = this.dataCount();
        var variety = this.dataVariety();
        var tagpage_pk = this.dataTagPagePk();
        var selected = this.dataSelectedItems();

        this.$axios({
          url: this.$store.state.api + '/batch-delete-ub/',
          method: 'post',
          data: {
            count: count,
            variety: variety,
            tagpage_pk: tagpage_pk,
            selected: JSON.stringify(selected),
          },
        }).then(function(response){

          if (variety==='selected') {
            this.$store.commit('deleteItems', selected);
          } else if ((variety==='tagpage') || (variety==='untagged') || (variety==='all')) {
            this.$store.commit('deleteAllItems');
          }

          this.$store.commit('unselectAllItems');

          this.$store.commit('eventBatchDeleted');

        }.bind(this), function(error){
          //this.$store.commit('unselectAllItems');

          this.$alertify.alert('Something went wrong while deleting bookmarks... <br/><br/>The error was logged, we were notified and we will work on it.');

        }.bind(this));

      }.bind(this));
      this.$alertify.reset();
    },




    editSelected: function(){
      if (!this.atLeastOneItemSelected) return;
      this.$alertify.reset();
      this.$alertify.okBtn('delete').confirm(this.confirmMessage('edit'), function(){

        var count = this.dataCount();
        var variety = this.dataVariety();
        var tagpage_pk = this.dataTagPagePk();
        var selected = this.dataSelectedItems();

        this.$axios({
          url: this.$store.state.api + '/batch-edit-ub/',
          method: 'post',
          data: {
            count: count,
            action: 'tag',
            variety: variety,
            tagpage_pk: tagpage_pk,
            selected: JSON.stringify(selected),
          },
        }).then(function(response){
          this.$store.commit('unselectAllItems');
          this.$store.commit('eventBatchEdited');
        }.bind(this), function(error){
          //this.$store.commit('unselectAllItems');
          this.$alertify.alert('Something went wrong while editing bookmarks... <br/><br/>The error was logged, we were notified and we will work on it.');
        }.bind(this));

      }.bind(this));
      this.$alertify.reset();
    },





    dataCount: function(){
      var variety = this.dataVariety();
      if (variety==='selected') {
        return this.$store.state.itemsSelected.length;
      }
      else {
        return this.$store.state.itemsMeta.count;
      }
    },

    dataTagPagePk: function(){
      if (this.$route.meta.kind==='tagpage') {
        return this.$store.state.tagPageMeta ? this.$store.state.tagPageMeta.pk : null;
      }
    },

    dataSelectedItems: function(raw){
      return this.$store.state.itemsSelected;
    },

    dataVariety: function(){
      if (this.$store.state.itemsSelected==='*') {
        if (this.$route.meta.kind==='all' && this.$route.query.untagged==='1') {
          return 'untagged';
        }
        else if (this.$route.meta.kind==='all') {
          return 'all';
        }
        else if (this.$route.meta.kind==='tagpage') {
          return 'tagpage'
        }
      }
      else {
        return 'selected';
      }
    },

    confirmMessage: function(action){

      var variety = this.dataVariety();
      var selected = this.$store.state.itemsSelected;
      var selectedLength = selected.length;

      var msg = 'Are you sure you want to '+action+' ';

      // all in all
      if (selected==='*' && variety==='all') {
        msg += ' all your bookmarks ('+this.$store.state.itemsMeta.count+') from your whole library?';
      }

      // all in untagged
      else if (selected==='*' && variety==='untagged') {
        msg += ' all your untagged bookmarks?';
      }

      // all in tag page
      else if (selected==='*' && variety==='tagpage') {
        var tagName = this.$store.state.tagPageMeta.name;
        msg += ' all your bookmarks in "'+tagName+'" tag? <br/><br/>They will be deleted completely, even if they were assigned to other tags too.';
      }

      // selected only
      else {
        if (selectedLength===1) {
          msg += ' this single bookmark?';
        } else {
          msg += ' these '+ selectedLength + ' selected bookmarks?';
        }
      }

      msg += ' <br/><br/><strong>You won\'t be able to undo this!</strong>';
      return msg;
    },





  },

};
</script>


<template>
<div
  v-if="$store.state.itemsSelectMode"
  class="selectbar"
  :class="[{'mod-scrolled': $store.state.contentScrolled, 'mod-sidebar-collapse': $store.getters.finalSidebarCollapse, 'mod-page-with-sidebar': $store.getters.pageWithSidebar}]"
>

  <div class="selectbar-inner">

    <div class="selectbar-inner-inner">

      <div class="selectbar-section">

        <div class="selectbar-heading">

          <div class="selectbar-title">
            {{title}}
          </div>


          <div class="selectbar-subtitle" v-if="$store.state.itemsSelected==='*'">
            {{$store.state.itemsMeta.count}} Selected
          </div>
          <div class="selectbar-subtitle" v-else>
            {{$store.state.itemsSelected.length}} <span>Selected</span>
          </div>

        </div>

      </div>


      <div class="selectbar-section">

        <div class="selectbar-controls">

          <button type="button" class="selectbar-btn" :class="{'mod-disabled': ($store.state.itemsSelected==='*') || (!atLeastOneItemSelected)}"  @click.prevent="openSelected">
            <iconsvg v-tooltip="'Open All In New Tabs'" icon="external-link-filled"></iconsvg>
            <span>Open</span>
          </button>

          <!--
          <button type="button" class="selectbar-btn" :class="{'mod-disabled': !atLeastOneItemSelected}"  @click.prevent="editSelected">
            Tag Links
          </button>
          -->


          <!--
          <button type="button" class="selectbar-btn mod-disabled  mod-edit" v-tooltip="'This feature is coming soon...'">
            Edit
          </button>
          -->

          <button type="button" class="selectbar-btn" :class="{'mod-disabled': !atLeastOneItemSelected}"  @click.prevent="deleteSelected">
            <iconsvg v-tooltip="'Delete'" icon="delete-bin-filled"></iconsvg>
            <span>Delete</span>
          </button>

          <!--
          <button v-tooltip="'More actions like changing note and ratings on selected multiple links is planned...'" type="button" class="selectbar-btn" @click.stop.prevent>
            Other...</button>-->

          <div class="selectbar-divider"></div>


          <button type="button" class="selectbar-btn" :class="{'mod-disabled': allItemsSelected}"  @click.prevent="$store.commit('selectAllItems')">
            <iconsvg  v-tooltip="'Select All'" icon="check-all-filled"></iconsvg>
            <span>Select All</span>
          </button>

          <button type="button" class="selectbar-btn" :class="{'mod-disabled': !atLeastOneItemSelected}"  @click.prevent="$store.commit('unselectAllItems')">
            <iconsvg v-tooltip="'Deselect All'" icon="uncheck-all-filled"></iconsvg>
            <span>Deselect All</span>
          </button>



          <div class="selectbar-divider"></div>


          <button type="button" class="selectbar-btn"   @click.prevent="disableSelectMode">
            <iconsvg v-tooltip="'Disable Select Mode'" icon="delete"></iconsvg>
            <span>Select</span>
          </button>

          <!--
          <button type="button" @click.prevent="disableSelectMode" class="selectbar-closebtn">
            <iconsvg icon="delete" />
          </button> -->


        </div>

      </div>

    </div>

  </div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";



  .selectbar {
    //background-color: rgba(252,252,252,1);
    //background-color: $clr-ggather;
    //border-bottom: 1px solid transparent;
    //border-bottom: 1px solid darken($clr-ggather,5);

    background: rgba(darken(white,6),.8);
    backdrop-filter: blur(3px);


    height: 45px;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;

    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 3px;

    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;

    z-index: 50;

    transform: rorate3d(0,0,0,0);
    backface-visibility: hidden;



  }

  .selectbar-inner {
    padding-left: 0px;
    html.mod-transitions-enabled & {
      transition: padding 300ms ease-out;
    }
    .selectbar.mod-page-with-sidebar & {
      padding-left: 280px;
    }
    .selectbar.mod-page-with-sidebar.mod-sidebar-collapse & {
      padding-left: 0;
    }
  }


  .selectbar-inner-inner {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 900px) {
      padding: 0 10px;
      padding-left: 80px;
    }
  }


  .selectbar-section {
  }


    .selectbar-heading {
      white-space: nowrap;
      padding-top: 16px;
    }

    .selectbar-heading {
      padding-left: 0;
      @media (max-width: 900px){
        padding-left: 60px;
      }
    }

    html.mod-page-with-sidebar.mod-final-sidebar-collapse .selectbar-heading {
      @media (max-width: 1212px){
        padding-left: 60px;
      }
      @media (max-width: 900px){
        padding-left: 0px;
      }
    }

    // hack as hell
    @for $i from 1330 through 1212 {
      html.mod-page-with-sidebar.mod-final-sidebar-collapse .selectbar-heading {
        @media (min-width: #{$i}px) and (max-width: #{$i}px){
          padding-left: #{(1333 - $i) / 2}px;
        }
      }
    }


    .selectbar-title {
      display: inline-block;
      vertical-align: middle;
      color: rgba(black,.9);
      font-size: 15px;
      font-weight: 600;
      line-height: 1;

      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 1100px) {
        max-width: 120px;
        font-size: 14px;
      }


    }


      .selectbar-subtitle {
        display: inline-block;
        vertical-align: middle;
        color: rgba(black,.6);
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        padding-left: 15px;

        @media (max-width: 500px) {
          padding-left: 0;
          & > span {
            display: none;
          }
        }

      }





    .selectbar-controls {
      display: inline-block;
      margin-top: 12px;
      text-align: left;
    }


    .selectbar-divider {
      margin: 0 5px;
      margin-top: 3px;
      margin-left: 10px;
      display: inline-block;
      width: 1px;
      height: 15px;
      background: rgba(black,.1);
    }


      .selectbar-btn {
        color: white;
        border-radius: 4px;
        display: inline-block;
        margin-right: 0px;
        padding: 0 9px;
        height: 21px;
        outline: none;
        font-weight: 500;
        font-size: 11px;
        line-height: 1;
        margin-bottom: 5px;

        //background-color: rgba(white,.1);
        //border: 1px solid rgba(white,.15);
        //color: rgba(white,.7);

        margin-left: 5px;

        & > .icon::before {
          font-size: 9px;
        }


        //background-color: white;
        //border: 1px solid rgba(black,.10);
        //color: rgba(black,.9);

        background-color: rgba(white,.85);
        border: 1px solid rgba($clr-ggather,.3);
        color: rgba($clr-ggather,.9);


        &.mod-disabled {
          opacity: .5;
          cursor: default;
        }



        & /deep/ svg {
          display: none;

          width: 14px;
          height: 14px;
          path  {
            fill: rgba($clr-ggather,.9);
          }
        }

        & > span {
          display: block;
        }

        @media (max-width: 1100px){

          & /deep/ svg {
            display: block;
          }

          & > span {
            display: none;
          }


          &.mod-edit {
            display: none;
          }
        }

      }




      .selectbar-closebtn {
        background-color: #aaa;
        color: white;
        border-radius: 2px;
        border: 1px solid #999;
        display: inline-block;
        margin-right: 0px;
        padding: 0 5px;
        height: 21px;
        outline: none;
        font-size: 12px;
        line-height: 1;

        //background-color: rgba(white,.1);
        //border: 1px solid rgba(white,.15);
        //color: rgba(white,.7);

        margin-left: 5px;

        & > .icon::before {
          font-size: 9px;
        }


        //background-color: white;
        //border: 1px solid rgba(black,.15);
        //color: rgba(black,.9);

        background-color: rgba(white,.9);
        border: 1px solid rgba(white,.1);
        color: rgba($clr-ggather,.9);

        /deep/ svg {
          width: 11px;
          height: 11px;
        }
      }



</style>


<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .selectbar {
    background: rgba(darken(white,90),.8);
    backdrop-filter: blur(3px);
  }

  .selectbar-title {
    color: rgba(white,.9);
  }

  .selectbar-subtitle {
    color: rgba(white,.6);
  }

  .selectbar-divider {
    background: rgba(white,.15);
  }

}
</style>
