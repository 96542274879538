<script>
export default {

  props: {
    title: {
      type: String,
    },
    tabIndex: {
      type: String,
    },
    activeTab: {
      type: String,
    },
    changeTabFn: {
      type: Function,
    },
    big: {
      type: Boolean,
    },
    wide: {
      type: Boolean,
    },
  },

};
</script>


<template>
  <button draggable="false" @mouseup.prevent="changeTabFn(tabIndex)" type="button" class="landing-nav-btn" :id="'landing-nav-'+tabIndex"
  :class="{'mod-active': activeTab===tabIndex, 'mod-big': big, 'mod-wide': wide}" :js-landing-nav-btn="tabIndex">
    <span class="landing-nav-btn-inner">
      <span class="landing-nav-btn-text" v-html="title"></span>
      <span class="landing-nav-btn-progress"></span>
    </span>
  </button>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";


  .landing-nav-btn {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    outline: none;
    white-space: normal;
    transition: margin 100ms ease-in-out, width 100ms ease-in-out, height 100ms ease-in-out, padding 100ms ease-in-out, transform 100ms ease-in-out;
    transform-origin: bottom center;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
    //border-right: 1px solid rgba(white,.05);

    //background: rgba(white,.1);


    //&:last-of-type {
    //  padding-right: 5px;
    //}
    //&:last-of-type {
    //  border-right: none;
    //}


    //&.mod-active {
    //  background: rgba(white,.07);
    //  color: rgba(white,.9);
    //}



    //&:hover {
    //  transform: scale(1.1);
    //  padding-left: 5px;
    //  padding-right: 5px;
    //  //width: 120px;
    //  //height: 60px;
    //  //padding: 0 20px;
    //  &:last-of-type {
    //    padding-right: 10px;
    //  }
    //}

  }


    .landing-nav-btn-inner {
      display: block;
      width: 115px;
      min-width: 100px;
      height: 100%;
      height: 50px;
      position: relative;
      z-index: 10;
      transition: transform 150ms ease-in-out, opacity 350ms ease-in-out;

      .landing-nav-btn.mod-big & {
        width: 120px;
        height: 60px;
      }

      .landing-nav-btn.mod-wide & {
        width: 130px;
      }

      //opacity: 0;
      //transform: translateY(100%);

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 1px;
        right: 0;
        bottom: 0;
        width: calc(100% - 2px);
        height: calc(100% - 0px);
        z-index: 1;
        background: rgba(white,.06);
        border-radius: 2px;
        transition: background 100ms ease-in-out, transform 150ms ease-in-out;
      }

      .landing-nav-btn.mod-active &::before {
        background: rgba(white,.20);
      }

      .landing-nav-btn.mod-flash &::before {
        background: rgba(white,.30);
        //transform: scale(0.95);
      }

      //.landing-nav-btn.mod-active & {
      //  transform: translateY(0%);
      //  opacity: 1;
      //}
      .landing-nav-btn.mod-flash & {
        transform: translateY(-12px);
        box-shadow: 0 3px 25px 0 rgba(black,.1);
      }

      .landing-nav-btn:hover & {
        transform: translateY(-4px);
        //box-shadow: 0 3px 25px 0 rgba(black,.1);
      }


      .landing-nav.mod-touching & {
        pointer-events: none;
      }

    }


    .landing-nav-btn-text {
      display: block;
      width: 100%;
      padding: 0 10px;
      left: 0;
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      color: rgba(white,.6);
      transform-origin: 50% 50%;
      transition: color 100ms ease-in-out, transform 100ms linear 0ms;

      .landing-nav-btn.mod-big & {
        font-size: 14px;
      }

      .landing-nav-btn.mod-active & {
        color: rgba(white,.75);
      }

      .landing-nav-btn.mod-flash & {
        color: rgba(white,.8);
        transform: translateY(-50%);
      }
    }

    /*
    .landing-nav-btn-progress {
      position: absolute;
      top: -0px;
      left: -0px;
      right: -0px;
      height: 3px;

      &::before {
        content: '';
        background: rgba(white,.1);
        height: 100%;
        width: 0%;
        display: block;
        transition: width 400ms ease-in-out;
      }


    }


    .landing-nav-btn:hover .landing-nav-btn-progress::before {
      width: 100%;
    }
    */

</style>
