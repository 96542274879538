import dfLocaleEnGB from 'date-fns/locale/en-GB';
import dfFormat from 'date-fns/format';
import dfFormatDistance from 'date-fns/formatDistance';
import dfFormatDistanceStrict from 'date-fns/formatDistanceStrict';
import dfDifferenceInSeconds from 'date-fns/differenceInSeconds';

var VueDateFns = {
  install: function(Vue, options){




    Vue.$dfFormatA = Vue.prototype.$dfFormatA = function(value, args){
      return dfFormat(new Date(value), 'dd.MM.yyy, HH:mm', {locale: dfLocaleEnGB});
    };

    Vue.$dfFormatC = Vue.prototype.$dfFormatC = function(value, args){
      return dfFormat(new Date(value), 'yyyy-MM-dd HH:mm:ss', {locale: dfLocaleEnGB});
    };

    Vue.$dfFormatD = Vue.prototype.$dfFormatD = function(value, args){
      return dfFormat(new Date(value), 'do MMMM yyyy', {locale: dfLocaleEnGB});
    };

    Vue.$dfFormatDistanceFromNow = Vue.prototype.$dfFormatDistanceFromNow = function(value, args){
      return dfFormatDistance(new Date(value), new Date(), {includeSeconds: true, addSuffix: true});
    };

    Vue.$dfFormatDistanceFromNowOrNow = Vue.prototype.$dfFormatDistanceFromNowOrNow = function(value, args){
      if (dfDifferenceInSeconds(new Date(), new Date(value)) <= 5) {
        return 'just now';
      }
      return dfFormatDistance(new Date(value), new Date(), {includeSeconds: true, addSuffix: true});
    };



    Vue.filter('dfFormatA', function(value, args) {
      return Vue.$dfFormatA(value, args);
    });

    Vue.filter('dfFormatC', function(value, args) {
      return Vue.$dfFormatC(value, args);
    });

    Vue.filter('dfFormatD', function(value, args) {
      return Vue.$dfFormatD(value, args);
    });

    Vue.filter('dfFormatDistanceFromNow', function(value, args) {
      return Vue.$dfFormatDistanceFromNow(value, args);
    });

    Vue.filter('dfFormatDistanceFromNowOrNow', function(value, args) {
      return Vue.$dfFormatDistanceFromNowOrNow(value, args);
    })








  },
};

export default VueDateFns;
