<script>
export default {

  name: 'page-items-search',

  head: {
    title: function(){
      return {
        inner: this.$route.meta.title,
      };
    },
  },


};
</script>


<template>
<div class="router-page" v-if="$store.getters.userIsAuthenticated">
<div class="items-search">

  <items context="search"></items>

</div>
</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";

  .items-search {
  }

</style>
