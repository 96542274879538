<script>
import __ from '../../../common/script/dashdash.js';
import contains from 'dom-helpers/contains';
export default {

  data: function(){
    return {
      forcedExpandLevel: false,
    }
  },

  computed: {
    viewer: function(){
      return this.$route.meta.viewer || this.$store.state.viewer;
    },
    expandLevel: function(){
      try { var expandLevel = this.$store.state.viewerExpandLevel; } catch (e) {};
      if (expandLevel) { return expandLevel };
    },
    saveURLVisible: function(){
      var viewer = this.$route.meta.viewer || this.$store.state.viewer;
      if (!viewer) {return false;}
      return ( (viewer.kind=='resave') ||
           (viewer.kind=='edit')       ||
           (viewer.kind=='newlink')    ||
           (viewer.kind=='urlsave')    ||
           (viewer.kind=='chromesave') ||
           (viewer.kind=='chromebtn')  ||
           (viewer.kind=='savepage')
      );
    },

  },

  created: function(){
    this.chromeExtensionViewerHide();
    this.forcedExpandLevelCheck();
  },

  mounted: function(){
    this.globalKeyup(1);
  },

  beforeUpdate: function(){
  },

  beforeDestroy: function(){
    this.globalKeyup(0);
  },

  watch: {
    'viewer': function(val){
      if (window.innerWidth < 700) {
        this.$store.commit('viewerComments', false);
      }
    },
    'viewer.kind': function(){
      this.forcedExpandLevelCheck();
    },
    forcedExpandLevel: function(){
    },
  },

  methods: {


    forcedExpandLevelCheck: function(){
      if (!this.viewer) { return false;}
      if ( (this.viewer.kind=='savepage') || (this.viewer.kind=='urlsave') ) {
        this.forcedExpandLevel = true;
      }
    },

    globalKeyup: function(init){

      if (!init) {
        this._windowKeyupViewer ? window.removeEventListener('keyup', this._windowKeyupViewer) : '';
        return false;
      }

      var keyupHandler = this.keyupHandler;
      this._windowKeyupViewer = function(event){
        keyupHandler(event);
      }

      window.addEventListener('keyup', this._windowKeyupViewer);

    },


    keyupHandler: function(event){

      var KEY_ESC = event.which == 27;

      // dont handle when in input or textarea but allow radios and checkboxes
      if (event.target.tagName == 'INPUT' || event.target.tagName == 'TEXTAREA') {
        if ( (event.target.type != 'radio') && (event.target.type != 'checkbox') ) {
          return false;
        }
      }

      if (KEY_ESC) {
        this.closeViewer();
        return false;
      }
    },


    closeViewerFromBackgroundClick: function(event){
      //console.warn(`closeViewerFromBackgroundClick`);
      //console.warn(`this.$refs.mainWrapper:`);
      //console.log(this.$refs.mainWrapper);
      //console.warn(`event.target:`);
      //console.log(event.target);
      //console.warn(`contains:`);
      //console.log( contains(this.$refs.mainWrapper, event.target) );
      //return;

      if (this.$refs.mainWrapper && event) {
        var clickInsideMainWrapper = contains(this.$refs.mainWrapper, event.target);
        if (clickInsideMainWrapper) return;
      }
      if (this.$refs.commentsWrapper && event) {
        var clickInsideCommentsWrapper = contains(this.$refs.commentsWrapper, event.target);
        if (clickInsideCommentsWrapper) return;
      }

      this.closeViewer();
    },


    closeViewer: function(){

      // check if bookmark edit request is processing
      if (this.$store.state.trackedRequests.editURLBookmark) {

        // if it is then just wait for promise to finish
        // and then give couple of ms before closing viewer

        this.$store.state.trackedRequests.editURLBookmark.then(function(response){

          setTimeout(function() {
            this.closeViewer();
          }.bind(this), 300);

        }.bind(this), function(error){

          setTimeout(function() {
            this.closeViewer();
          }.bind(this), 300);

        }.bind(this));

        return false;

      }



      // if there aren't any requests in progress then just close viewer
      this.forcedExpandLevel = false;

      if (this.$store.state.viewer) {
        this.$store.commit('viewer', false);
      }
      if (this.$route.meta.viewer) {

        if (this.$store.state.user && this.$store.state.user.is_authenticated) {
          this.$router.push('/all');
        } else {
          this.$router.push('/');
        }

      }



    },


    /*
    setExpandLevel: function(direction){

      this.forcedExpandLevel = false;
      if (direction=='left') {
        if (this.expandLevel >= 4) {
          return false;
        }
        this.$store.commit('viewerExpandLevel', this.expandLevel + 1);
      }
      if (direction=='right') {
        if (this.expandLevel <= 2) { // expand level 1 is reserver for side view
          return false;
        }
        this.$store.commit('viewerExpandLevel', this.expandLevel - 1);
      }
      if (direction) {
        return false;
      }
      if (this.expandLevel == 1) {
        this.$store.commit('viewerExpandLevel', 2);
      }
      else if (this.expandLevel == 2) {
        this.$store.commit('viewerExpandLevel', 3);
      }
      else if (this.expandLevel == 3){
        this.$store.commit('viewerExpandLevel', 4);
      }
      else if (this.expandLevel == 4){
        this.$store.commit('viewerExpandLevel', 1);
      }
    },
    */



    toggleExpandLevel: function(direction){
      this.forcedExpandLevel = false;
           if (this.expandLevel === 1) this.$store.commit('viewerExpandLevel', 2);
      else if (this.expandLevel === 2) this.$store.commit('viewerExpandLevel', 3);
      else if (this.expandLevel === 3) this.$store.commit('viewerExpandLevel', 4);
      else if (this.expandLevel === 4) this.$store.commit('viewerExpandLevel', 2);
      // expand level 1 is reserved for side view
    },



    chromeExtensionViewerHide: function(){

      // it's rather hack fix, but it works...

      // don't open if chrome instant save option is off
      // and the environment is chrome extension
      // and the kind is 'newlink' with subkind 'urlsave'
      // (so when the extension icon is clicked)

      // if there's no meta.viewer then don't disable
      if ( (!this.$route.meta) || (!this.$route.meta.viewer) ) {
        return false;
      }

      // check kind and subkind
      if (this.viewer.kind=='chromesave') {

        // check if env is chrome and "on open save"
        if ( this.$store.state.chrome && (!this.$store.state.chromeOpenSave) ) {

          // return true to disable viewer
          this.closeViewer();
          return true;

        }

      }

      // and don't disable in other cases
      return false;

    }


  }

};
</script>


<template>
<div :class="[
  'viewer',
  'mod-expand-'+expandLevel,
  viewer ? 'mod-kind-'+viewer.kind : '',
  {
    'mod-forced-expand-level': forcedExpandLevel,
    'mod-comments-visible': $store.getters.finalViewerComments,
  },
]">


  <div v-if="viewer" class="viewer-background" @click.capture="closeViewerFromBackgroundClick"></div>



  <div v-if="viewer" class="viewer-foreground-1" @click.capture="closeViewerFromBackgroundClick">

    <div class="viewer-foreground-2">

      <!-- Main Wrapper - Next To Comments -->
      <div class="viewer-main-wrapper" ref="mainWrapper" >

        <!--  Topbar  -->
        <div class="viewer-top-wrapper">
          <viewertop
            :viewer="viewer"
            :expand-level="expandLevel"
            :forced-expand-level="forcedExpandLevel"

            @clickExpandToggle="toggleExpandLevel"
            @clickExpandForce="forcedExpandLevel=0"
            @clickClose="closeViewer"
          />
        </div>

        <!--  Content  -->
        <div v-if="viewer.kind=='view'" class="viewer-content  mod-view  common-light-scrollbar">
          <embedel :url="viewer.item.url" :item="viewer.item" key="'embedel'+viewer.item.url"></embedel>
        </div>
        <div v-else-if="viewer.kind=='proxy'" class="viewer-content  mod-proxy  common-light-scrollbar">
          <embedel :url="viewer.item.url" :item="viewer.item" key="'embedelproxy'+viewer.item.url" :proxy="true"></embedel>
        </div>
        <div v-else-if="viewer.kind=='read'" class="viewer-content mod-read  common-light-scrollbar">
          <reader :url="viewer.item.url"></reader>
        </div>
        <div v-else-if="viewer.kind=='detail'" class="viewer-content  mod-detail  common-light-scrollbar">
          <itemdetails :item="viewer.item"></itemdetails>
        </div>
        <div v-else-if="saveURLVisible" class="viewer-content  mod-saveurl  common-light-scrollbar">
          <saveurl :viewer-kind="viewer.kind" :viewer-item="viewer.item"></saveurl>
        </div>

      </div>

      <!-- Comments -->
      <div v-if="$store.getters.finalViewerComments && viewer.item" class="viewer-comments-wrapper" ref="commentsWrapper">
        <div class="viewer-comments-arrow"></div>
        <div class="viewer-comments-wrapper-inner">
          <comments />
        </div>
      </div>

    </div>

  </div>

</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  $easing: cubic-bezier(0.445, 0.05, 0.55, 0.95);


  .viewer {
    backface-visibility: hidden;
    transform: rotate3d(0,0,0);
    position: relative;
    z-index: 70;

    html.mod-dialogbox-opened & {
      //filter: blur(4px);
    }
  }





  .viewer-background {
    position: fixed;
    background-color: rgba(28, 27, 27, 0.98);
    background-color: rgba( darken(white, 15), .98);
    //background-color: rgba(240, 240, 240, 0.90);
    background-color: rgba( darken(white,85), 0.95);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backface-visibility: hidden;
    //transform: rotate3d(0,0,0) translateX(0%);
    transform: rotate3d(0,0,0);

    //box-shadow: 0px 0 300px 300px rgba(28, 27, 27, 0.95);

    opacity: 1;
  }







  .viewer-foreground-1  {

    //html.mod-transitions-enabled & {
    //  transition: opacity 150ms $easing 50ms, transform 150ms $easing;
    //}

    display: block;
    transform: rotate3d(0,0,0);
    backface-visibility: hidden;
    transform: scale(1);

    z-index: 80;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

  }




  .viewer-foreground-2 {
    position: fixed;
    z-index: 90;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    padding: 20px;


    html.mod-transitions-enabled & {
      transition: padding 150ms $easing;
    }

    .viewer.mod-expand-4 & {
      padding: 0px;
    }

    @media (max-width: 900px) {

      &,
      .viewer.mod-expand-1 &,
      .viewer.mod-expand-2 &,
      .viewer.mod-expand-3 &,
      .viewer.mod-expand-4 & {
        padding: 0;
      }
    }



  }




    .viewer-main-wrapper {
      display: inline-block;
      border-radius: 6px;
      overflow: hidden;
      //border: 1px solid darken(white,11);
      box-shadow: -4px 0 50px 0 rgba(black,.1);
      width: 900px;

      html.mod-transitions-enabled & {
        transition: width 150ms $easing, border-radius 150ms $easing;
      }


      margin-right: 0px;

      .viewer.mod-comments-visible & {
      }

      .viewer.mod-expand-1 & {
        // reserved
      }


      .viewer.mod-expand-2 & {
        width: 900px;
      }

      .viewer.mod-expand-3 & {
        width: 1200px;
      }

      .viewer.mod-expand-4 & {

        html.mod-transitions-enabled & {
          transition: width 150ms $easing, margin 150ms $easing, border-radius 150ms $easing;
        }

        border: none;
        width: 100%;
        margin-right: 0px;
        border-radius: 0;
      }




      @media (max-width: 900px) {

        &,
        .viewer.mod-expand-1 &,
        .viewer.mod-expand-2 &,
        .viewer.mod-expand-3 &,
        .viewer.mod-expand-4 & {
          border-radius: 0;
        }
      }



    }





    .viewer-comments-wrapper {
      position: relative;
      display: inline-block;
      min-width: 350px;
      max-width: 350px;
      //flex: 350px 350px 350px;
      height: 100%;
      margin-left: 15px;

      html.mod-transitions-enabled & {
        transition: border 150ms $easing, width 150ms $easing, margin 150ms $easing;
      }


      .viewer.mod-expand-4 & {
        margin-left: 0px;
        border-left: 1px solid darken(white,6);
      }


      @media (max-width: 1200px) {
        min-width: 300px;
        max-width: 300px;
      }

      @media (max-width: 1000px) {
        min-width: 250px;
        max-width: 250px;
      }

      @media (max-width: 900px) {

        margin-left: 0px;
        border-left: 1px solid darken(white,6);
      }


      @media (max-width: 700px) {

        display: block;
        margin: 0;
        position: absolute;
        top: 72px;
        min-width: none;
        max-width: none;
        left: 10px;
        right: 10px;
        height: 80%;
        height: calc(100% - 82px);
        z-index: 50;

        box-shadow: 0 0px 200px 0  rgba(black,.5), 0 0px 50px 0  rgba(black,.3);

        top: 55px;
        left: 0px;
        right: 0px;
        height: calc(100% - 55px);

      }



    }

      .viewer-comments-wrapper-inner {

        border-radius: 6px;
        height: 100%;
        width: 100%;
        display: block;
        overflow: hidden;
        //border: 1px solid darken(white,12);
        box-shadow: 4px 0 50px 0 rgba(black,.1);

        html.mod-transitions-enabled & {
          transition: border-radius 150ms $easing;
        }

        .viewer.mod-expand-4 & {
          border-radius: 0;
          border: none;
        }


        @media (max-width: 900px) {

          border-radius: 0;
        }

      }


      .viewer-comments-arrow {
        position: absolute;
        top: 19px;
        left: -8px;
        z-index: 90;

        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid transparent;


        html.mod-transitions-enabled & {
          transition: border 150ms $easing;
        }

        .viewer.mod-expand-4 & {
          left: -8px;
          border-right: 8px solid darken(white,18);
        }


        &::before {
          content: '';
          position: absolute;
          top: -8px;
          left: 1px;
          z-index: 1;
          width: 0px;
          height: 0px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid white;

        }


        @media (max-width: 900px) {

          left: -8px;
          border-right: 8px solid darken(white,18);
        }


        @media (max-width: 700px) {
          border: none;

          top: -8px;
          left: auto;
          right: 62px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid darken(white,15);

          &::before {
            content: '';
            position: absolute;
            top: -7px;
            left: -8px;
            z-index: 1;
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid white;
          }

          .viewer.mod-expand-4 & {
            left: auto;
            right: 58px;
            top: -8px;
          }

        }


      }






      .viewer-content {
        display: block;
        height: 100%;
        height: calc(100% - 55px);

        max-height: calc(100vh - 55px);
        overflow: auto;

        position: relative;
        z-index: 40;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: scrollbar;

        &.mod-saveurl {
          overflow-y: scroll;
          background-color: darken(white,3);
        }
      }



      .viewer-top-wrapper {
        height: 55px;
        border-bottom: 1px solid darken(white,8);
        background-color: darken(white,4);
        font-size: 0;
        position: relative;
        z-index: 50;

        .viewer.mod-kind-edit & {
          border-bottom-color: darken(white,10);
          box-shadow : 0 0 30px 0 rgba(black,.1);
        }
      }






</style>





















<style lang="scss">
@import "../../../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {


  .viewer.mod-kind-edit .viewer-top-wrapper, .viewer-top-wrapper {
    background-color: darken(white, 80);
    border-color: darken(white, 83);
  }


  .viewer-content {
    background-color: darken(white, 75);
  }




  .viewer-comments-wrapper {
    border-color: darken(white, 83);
  }


  .viewer-comments-arrow {
    border-right-color: darken(white, 70);


    .viewer.mod-expand-4 & {
      border-right-color: darken(white, 70);
    }


    &::before {
      border-right-color: darken(white, 70);
    }


    @media (max-width: 900px) {
      border-right-color: darken(white, 70);
    }


    @media (max-width: 700px) {
      border-right-color: transparent;
      border-bottom-color: darken(white, 70);

      &::before {
        border-right-color: transparent;
        border-bottom-color: darken(white, 70);
      }

    }


  }












}

</style>
