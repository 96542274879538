<script>
export default {

  name: 'pricingup',


  data: function(){
    return {

      paddleSetupFinished: false,

      selectedPlan: '',
      inlineCheckoutVisible: false,
      inlineCheckoutInited: false,

      alreadyScrolledOnceToCheckout: false,

      checkoutData: null,
      checkoutDataLoading: false,
      checkoutSuccess: false,

    };
  },


  computed: {


    allowCheckout: function(){

      if (!this.$store.state.user.is_authenticated) return false;


      if (this.$store.state.BUILD!=='devwebapp' && this.$store.state.BUILD!=='webapp') return false;


      // if user mgmt_premium_until, always priority
      if (this.$store.state.user.mgmt_premium_until) {
        if (new Date(this.$store.state.user.mgmt_premium_until) > new Date()) return false;
      };


      // if user past_due
      if (this.$store.state.user.paddle_sub_status === 'past_due') return false;

      // if user is not premium
      if (!this.$store.state.user.is_premium) return true;

      // if user is premium - check if 'braintree premium' is active
      var braintreeSubActive = false;
      if (this.$store.state.user.premium_paid_through) {
        braintreeSubActive = new Date(this.$store.state.user.premium_paid_through) > new Date();
      }

      // ... and check if 'paddle premium' is active
      var paddleSubActive = false;
      if (this.$store.state.user.paddle_sub_paid_through) {
        paddleSubActive = new Date(this.$store.state.user.paddle_sub_paid_through) > new Date();
      }

      // if both braintree and paddle are not active, show...
      if (!braintreeSubActive && !paddleSubActive) return true;

      // if braintree is active but paddle not, show to allow user to renew sub...
      if (braintreeSubActive && !paddleSubActive) return true;

      // if paddle is active but braintree not... don't show...
      if (braintreeSubActive && !paddleSubActive) return false;

      // any other case... dont show
      return false;

    },



    readableRescheduleDate: function(){
      if (!this.checkoutData) return '';
      if (!this.checkoutData.reschedule_date) return '';
      return ' Your first payment date after resubscription: ' + this.$dfFormatD(this.checkoutData.reschedule_date) + '.' ;
    },

    braintreePaidThroughInPast: function(){
      if (!this.$store.state.user.premium_paid_through) return false;
      return new Date(this.$store.state.user.premium_paid_through) < new Date();
    },

    showPreviousSubNotif: function(){
      if (this.$store.state.user.paddle_sub_paid_through) return false;
      if (this.$store.state.user.premium_paid_through && (!this.braintreePaidThroughInPast)) return true;
      return false;
    },





  },



  created: function(){
  },

  mounted: function(){
    if (!this.$store.state.user.is_authenticated) return;
    if (this.allowCheckout) {
      this.fetchUserCheckoutData(() => {
        this.loadPaddleSDK(() => {
          this.paddleSetup();
        });
      });
    }
  },


  beforeDestroy: function(){
  },


  methods: {


    loadPaddleSDK: function(cb){
      if(window.Paddle) {
        cb();
        return; // don't load it second time...
      }

      // load it...
      var src = '//cdn.paddle.com/paddle/paddle.js';
      var script = document.createElement('script');
      script.setAttribute('src', src);
      document.body.appendChild(script);
      script.onload = function(){
        cb();
      };
      script.onerror = function(){
        window.alert('Loading our payment processor library has failed. Please try refreshing the page and contact us if this problem persists.');
      };
    },



    fetchUserCheckoutData: function(cb){
      this.checkoutDataLoading = true;
      this.$axios({
        url: this.$store.state.api + '/user-checkout-data/',
        method: 'get',
      }).then(function(response){
        this.checkoutData = response.data.user;
        this.checkoutDataLoading = false;
        this.$nextTick(function(){
          cb();
        });
      }.bind(this), function(error){
        window.alert('Something went wrong while getting checkout data. Please try refreshing the page and contact us if this problem persists.');
        //window.location.reload(true);
      }.bind(this));
    },




    paddleSetup: function(){
      if (!window.Paddle) window.alert('Loading our payment processor library has failed (paddleSetup). Please try refreshing the page and contact us if this problem persists.');
      //console.warn('Paddle.Setup');
      var vm = this;
      Paddle.Setup({
        vendor: parseInt( vm.checkoutData.paddle_vendor_id ),
        debug: this.$store.state.NODE_ENV === 'development',
        eventCallback: function(eventData) {

          // eventData = {
          //   event: "Checkout.Loaded",
          //   eventData: { ... },
          //   checkoutData: { ... },
          //   campaignData: { ... },
          // }
          // Checkout.Complete may not always fire - because Paddle A/B tests

          //console.warn(eventData.event, eventData);

          console.debug('[pricingup.vue debug] Paddle.Setup → eventData.event', eventData.event);

          var eventName = eventData.event;

          if (eventName === 'Checkout.PaymentComplete' || eventName === 'Checkout.Complete') {
            vm.checkoutSuccess = true;
            vm.$nextTick(function(){
              vm.checkoutSuccess = true;
            });
            setTimeout(() => {
              vm.checkoutSuccess = true;
            }, 50);
          }


        },
      });

      this.$nextTick(function(){
        this.paddleSetupFinished = true;
      });
    },


    openPaddleCheckout: function(){
      if (!window.Paddle) window.alert('Loading our payment processor library has failed (openPaddleCheckout). Please try refreshing the page and contact us if this problem persists.');
      var vm = this;
      var user = this.$store.state.user;
      var options = {
        //title: '' // overrides product title
        //message: '' // under the product name
        locale: 'en',
        passthrough: JSON.stringify({
          user_pk: user.pk,
          user_paddle_secure_hash: vm.checkoutData.user_paddle_secure_hash,
        }),
        email: user.email,
        allowQuantity: false,
        marketingConsent: true, // need email...
        disableLogout: true, // Disable the ability for a user to logout/change email once on the checkout.
        method: 'inline',
        frameTarget: 'pricingup-checkout-frame-target', // The className of your checkout <div>
        frameInitialHeight: 390,
        // default frameStyle: z-index: 99999; display: block; background-color: transparent; border: 0px none transparent; overflow-x: hidden; overflow-y: auto; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%;
        frameStyle: 'position:static; display:block; background:transparent; border:none; overflow-x:hidden; overflow-y:auto; visibility:visible; margin:0; padding: 0; -webkit-tap-highlight-color:transparent; width:100%; height:100%;',
        successCallback: function(){
          vm.checkoutSuccess = true;
          vm.$nextTick(function(){
            vm.checkoutSuccess = true;
          });
        },

      };


      if (vm.checkoutData.reschedule_paddle_payment) {
        options.price = '0.0';
      }

      if (this.selectedPlan === 'monthly') {
        options.product = vm.checkoutData.paddle_monthly_product_id;
        options.auth = vm.checkoutData.reschedule_monthly_price_auth;
      }

      if (this.selectedPlan === 'yearly') {
        options.product = vm.checkoutData.paddle_yearly_product_id;
        options.auth = vm.checkoutData.reschedule_yearly_price_auth;
      }

      if (!this.selectedPlan) {
        window.alert('Error: pricingup component selectedPlan state property is not set.');
      }

      //console.warn('Paddle.Checkout.open', options);

      Paddle.Checkout.open(options);
    },




    upgradeClick(kind){
      if (this.selectedPlan === kind) {
        this.scrollToCheckout(1);
        return;
      };

      this.inlineCheckoutInited = true;
      this.inlineCheckoutVisible = false;
      this.selectedPlan = kind;
      this.openPaddleCheckout();

      setTimeout(() => {
        this.scrollToCheckout();
      }, 300);

      setTimeout(() => {
        this.inlineCheckoutVisible = true;
      }, 2000);

    },




    scrollToCheckout: function(force){
      if (!force && this.alreadyScrolledOnceToCheckout) return;
      document.querySelector('.pricingup-checkout').scrollIntoView({behavior: 'smooth'});
      this.alreadyScrolledOnceToCheckout = true;
    },


    refreshPage: function(){
      location.reload(true);
    },


  },

};
</script>


<template>
<section class="pricingup" id="upgrade-account">

  <div v-if="!allowCheckout" key="notallowedcheckoutmsg">

    <div v-if="!$store.state.user.is_authenticated">
      <h2 class="pricingup-title">
        <a href="/create-account" @click.stop.prevent="$store.commit('dialogbox', {kind: 'signinup', subkind: 'signup'})">
          Create Account
        </a>
        or
        <a href="/sign-in" @click.stop.prevent="$store.commit('dialogbox', {kind: 'signinup', subkind: 'signin'})">
          Sign In
        </a>
      </h2>
    </div>





    <div v-else-if="$store.state.BUILD!=='devwebapp' && $store.state.BUILD!=='webapp'">
      <h2 class="pricingup-title">Go to GGather Web App to Subscribe</h2>
      <p class="pricingup-no-checkout-msg">You can upgrade account by going to <a :href="`${this.$store.state.webapp}/upgrade`" target="_blank">{{this.$store.state.webapp}}/upgrade</a>.</p>
    </div>



    <div v-else-if="$store.state.user.paddle_sub_status==='past_due'">
      <h2 class="pricingup-title" >Your Subscription is Past Due</h2>
      <p class="pricingup-no-checkout-msg">You can manage your subscription in your <router-link to="/settings/billing">billing settings</router-link>.</p>
    </div>


    <div v-else>
      <h2  class="pricingup-title">Your Account is on GGather Plus</h2>
      <p class="pricingup-no-checkout-msg">You can manage your subscription in your <router-link to="/settings/billing">billing settings</router-link>.</p>
    </div>


  </div>


  <div v-if="allowCheckout" key="allowedcheckout">


    <h2 class="pricingup-title">Upgrade to GGather Plus</h2>

    <p class="pricingup-subtitle">Prices are in USD and already include VAT (or equivalent) when applicable. Easy cancellation.</p>


    <div class="pricingup-notif" key="previoustCustomerNotif2" v-if="showPreviousSubNotif">
      <div class="pricingup-notif-header">
        <div class="pricingup-notif-header-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
            <path d="M 12 3.0292969 C 11.436813 3.0292969 10.873869 3.2917399 10.558594 3.8164062 L 1.7617188 18.451172 C 1.1134854 19.529186 1.94287 21 3.2011719 21 L 20.796875 21 C 22.054805 21 22.886515 19.529186 22.238281 18.451172 L 13.441406 3.8164062 C 13.126131 3.29174 12.563187 3.0292969 12 3.0292969 z M 12 5.2988281 L 20.236328 19 L 3.7636719 19 L 12 5.2988281 z M 11 9 L 11 14 L 13 14 L 13 9 L 11 9 z M 11 16 L 11 18 L 13 18 L 13 16 L 11 16 z" />
          </svg>
        </div>
        <p class="pricingup-notif-header-title">

          <span><strong>As a previous GGather Pro subscriber you will be billed again only after your current subscription end date (with 1 additional month for free as mentioned earlier).</strong></span>

          <span>{{readableRescheduleDate}}</span>

        </p>

      </div>
    </div>



    <div class="pricingup-upgrade-buttons-wrapper">

      <button :disabled="checkoutSuccess || checkoutDataLoading" type="button" class="pricingup-upgrade-button mod-monthly" :class="{'mod-selected': selectedPlan==='monthly'}" @click="upgradeClick('monthly')">
        <span>Monthly Billing</span>
        <span>$4 per month</span>
      </button>


      <button :disabled="checkoutSuccess || checkoutDataLoading" type="button" class="pricingup-upgrade-button mod-yearly" :class="{'mod-selected': selectedPlan==='yearly'}" @click="upgradeClick('yearly')">
        <span>
          Yearly Billing
          <span class="pricingup-recommend-label">
            <span>Recommended &ndash; Save $12</span>
          </span>
        </span>
        <span>
          $36 per year <span class="pricingup-price-equal-label">= $3 per month</span>
        </span>
      </button>

    </div>


    <div class="pricingup-checkout" :class="['mod-plan-'+selectedPlan, {'mod-inited': inlineCheckoutInited, 'mod-loaded': inlineCheckoutVisible}]"  >


      <div v-if="checkoutSuccess" key="checkout-success" class="pricingup-success">
        <div class="pricingup-success-inner">
          <div class="pricingup-success-content">

            <div class="pricingup-success-icon">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                <path style="fill:#43A047;" d="M14.043,24.129l2.828-2.828l7.797,7.797l-2.832,2.828L14.043,24.129z"></path>
                <path style="fill:#43A047;" d="M18.934,29.012l12.039-12.039l2.984,2.984L21.918,32L18.934,29.012z"></path>
                <path style="fill:#8BC34A;" d="M22.119,39.88l-0.47,3.984C22.418,43.955,23.207,44,23.997,44v-4	C23.361,40,22.736,39.952,22.119,39.88z"></path>
                <path style="fill:#DCEDC8;" d="M18.627,8.944L17.282,5.16c-1.402,0.493-2.747,1.155-4.013,1.957l2.164,3.386	C16.429,9.869,17.499,9.347,18.627,8.944z"></path>
                <path style="fill:#C5E1A5;" d="M12.645,12.732L9.799,9.917c-1.044,1.053-1.982,2.229-2.771,3.495l3.41,2.129	C11.072,14.525,11.805,13.578,12.645,12.732z"></path>
                <path style="fill:#AED581;" d="M8.9,18.749l-3.797-1.315c-0.485,1.398-0.822,2.862-0.979,4.346l3.981,0.439	C8.238,21.015,8.514,19.857,8.9,18.749z"></path>
                <path style="fill:#9CCC65;" d="M8.106,25.79l-3.982,0.451c0.156,1.476,0.493,2.94,0.987,4.35l3.793-1.328	C8.517,28.154,8.24,26.995,8.106,25.79z"></path>
                <path style="fill:#8BC34A;" d="M10.449,32.479l-3.41,2.129c0.789,1.266,1.727,2.434,2.78,3.495l2.834-2.826	C11.816,34.435,11.084,33.491,10.449,32.479z"></path>
                <path style="fill:#8BC34A;" d="M15.44,37.502l-2.15,3.394c1.254,0.798,2.603,1.451,4.013,1.957l1.347-3.789	C17.515,38.661,16.44,38.137,15.44,37.502z"></path>
                <path style="fill:#8BC34A;" d="M23.997,8V4c-0.781,0-1.571,0.045-2.348,0.136l0.466,3.985C22.733,8.048,23.359,8,23.997,8z"></path>
                <path style="fill:#8BC34A;" d="M23.997,4v4C23.998,8,23.999,8,24,8c8.837,0,16,7.163,16,16s-7.163,16-16,16	c-0.001,0-0.002,0-0.003,0v4c11.028,0,20-8.972,20-20S35.025,4,23.997,4z"></path>
              </svg>
            </div>

            <div class="pricingup-success-text">
              <p class="pricingup-success-title">Your order was processed successfully. Thank you!</p>

              <p class="pricingup-success-subtitle"><span v-if="!checkoutData.reschedule_paddle_payment">Your account should be upgraded in the next couple of seconds.</span> Please refresh this page for changes to be visible.</p>

              <p class="pricingup-success-cta-wrapper">
                <button type="button" class="pricingup-success-cta" @click.prevent="refreshPage">Refresh Page</button>
              </p>
              <p class="pricingup-success-info">Please <a href="/contact" target="_blank" rel="noopener noreferrer">contact us</a> if you encounter any problems in upgrade activation.</p>
            </div>



          </div>
        </div>
      </div>

      <div class="pricingup-checkout-arrow">
        <div class="pricingup-checkout-arrow-inner">
          <div></div>
          <div></div>
        </div>
      </div>

      <div class="pricingup-checkout-spinner">
        <spinner2 :size="42" :line-size="2" :speed="0.4" line-fg-color="#555" line-bg-color="transparent" />
      </div>


      <div class="pricingup-checkout-frame-wrapper">
        <div class="pricingup-checkout-frame-target-wrapper">
          <div class="pricingup-checkout-frame-target">
            <!-- paddle inline checkout gets appended here -->
          </div>
        </div>
      </div>

    </div>



    <div class="pricingup-footer" :class="{'mod-inited': inlineCheckoutInited, 'mod-loaded': inlineCheckoutVisible}">
      <div class="pricingup-footer-inner">


        <div class="pricingup-footer-text">
          <p>All payments are processed by <a href="https://paddle.com" target="_blank" rel="noopener noreferrer">Paddle.com</a>.</p>
          <p>Paddle is our secure &amp; PCI compliant Merchant of Record.</p>
          <p>Upgrading means you accept GGather <a :href="$store.state.hq+'/legal/terms-of-use'" target="_blank" rel="noopener noreferrer">terms</a> and <a :href="$store.state.hq+'/legal/privacy-policy'" target="_blank" rel="noopener noreferrer">privacy policy</a>.</p>
        </div>

        <div class="pricingup-footer-methods">
          <pricingbadges />
        </div>

      </div>
    </div>



  </div>


</section>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .pricingup {
    background: #F6F6F6;
    max-width: 640px;
    padding: 30px;
    border-radius: 8px;
    //box-shadow: 0 2px 10px 0px rgba(black,.13);
    border: 1px solid darken(white,12);
    margin-top: 20px;

    @media (max-width: 670px) {
      margin-top: 5px;
      padding: 10px;
    }
  }



  .pricingup-title {
    font-size: 27px;
    font-weight: 700;
    line-height: 1.2;
    color: darken(white,80);
    text-align: center;

    margin-top: 10px;
    margin-bottom: 10px;

    a {
      text-decoration: underline;
    }

    @media (max-width: 670px) {
      font-size: 24px;
      margin-top: 20px;
    }
  }



  .pricingup-subtitle {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    color: darken(white,90);
    text-align: center;

    margin-bottom: 22px;
    margin-top: 10px;
  }


  .pricingup-no-checkout-msg {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.2;
    color: darken(white,90);
    text-align: center;

    margin-bottom: 10px;
    margin-top: 10px;

    a {
      text-decoration: underline;
    }
  }




    .pricingup-notif {
      text-align: left;
      outline: none;
      background: lighten(#E28F26, 35);
      border-radius: 4px;
      padding: 18px 20px;
      margin-top: -10px;
      margin-bottom: 15px;
      @media (max-width: 670px) {
        padding: 15px 15px;
      }
    }

      .pricingup-notif-header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        @media (max-width: 670px) {
          display: block;
        }
      }

        .pricingup-notif-header-icon {
          margin-top: 6px;
          svg {
            width: 24px;
            height: 24px;
            path {
              fill: darken(#E28F26, 32);
            }
          }
          @media (max-width: 670px) {
            svg {
            }
          }
        }

        .pricingup-notif-header-title {
          font-size: 13px;
          font-weight: 400;
          line-height: 1.3;
          padding-left: 15px;
          color: darken( #E28F26, 34);

          strong {
            font-weight: 600;
          }

          span {
            display: block;
            margin-top: 5px;
          }

          @media (max-width: 670px) {
            font-size: 12px;
            padding-left: 0;
            padding-top: 10px;
          }

        }



        .pricingup-notif-header-p {
          font-size: 13px;
          font-weight: 400;
          line-height: 1.3;
          padding-left: 15px;
          color: darken( #E28F26, 34);
          margin-top: 5px;

          strong {
            font-weight: 600;
          }
          @media (max-width: 670px) {
            font-size: 12px;
          }
        }


  .pricingup-upgrade-buttons-wrapper {
    display: flex;
    align-items: stretch;
    align-content: center;
  }


  .pricingup-upgrade-button {
    background: $clr-ggather;
    padding: 20px 10px;
    width: fit-content;
    display: inline-block;
    border-radius: 4px;
    outline: none;

    transition: box-shadow 100ms ease-in-out;

    box-shadow: 0 2px 12px 0 rgba(0,0,0,.0);

    // guide line to center the arrow...
    /*
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 100%;
      background: red;
      left: 50%;
      transform: translateX(-50%);
    }
    */

    &.mod-monthly {
      margin-right: 10px;
      width: 35%;
    }

    &.mod-yearly {
      width: 65%;
    }

    > span {
      display: block;
    }

    > span:nth-of-type(1) {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
      color: rgba(white,1);
    }

    > span:nth-of-type(2) {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.2;
      color: rgba(white,.9);
      margin-top: 10px;
    }

    &:hover,
    &.mod-selected {
      box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
    }


    @media (max-width: 670px) {
      padding: 10px 10px;

      &.mod-monthly {
        margin-right: 5px;
      }

      > span:nth-of-type(1) {
        font-size: 13px;
      }

      > span:nth-of-type(2) {
        font-size: 11px;
        margin-top: 5px;
      }

    }


  }

  .pricingup-recommend-label {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    vertical-align: baseline;
    color: rgba(white,1);

    > span {
      position: relative;
      z-index: 2;
      vertical-align: baseline;
      padding: 1px 5px 1px 5px;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -2px;
      //transform: skewX(-4deg);
      background-color: #E28F26;
      background-color: rgba(white,.2);
      border-radius: 3px;
    }

    @media (max-width: 670px) {
      display: block;
      width: fit-content;
      margin: 0 auto;
      margin-top: 5px;
      font-size: 11px;
    }


  }

  .pricingup-price-equal-label {
    font-size: 12px;
    font-weight: 400;
    vertical-align: middle;
    color: rgba(white,.6);
    @media (max-width: 670px) {
      font-size: 10px;
    }
  }





  .pricingup-checkout {

    height: 0px;
    //overflow: hidden;
    position: relative;

    transition: height 600ms ease-in-out;


    &.mod-inited {
      height: 390px;
    }

    @media (max-width: 670px) {
      &.mod-inited {
        height: 480px;
      }
    }

  }















  .pricingup-success {
    position: absolute;
    top: 14px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: white;
    border-radius: 4px;

  }


  .pricingup-success-inner {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
  }

    .pricingup-success-content {
      position: absolute;
      top: 50%;
      width: 100%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      font-size: 15px;
      text-align: center;
    }




      .pricingup-success-icon {
        display: block;
        svg {
          width: 80px;
          height: 80px;
        }
      }

      .pricingup-success-text {
        display: block;
        padding: 0 30px;
      }

      .pricingup-success-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        color: rgba(black, .9);
        margin-top: 20px;
      }

      .pricingup-success-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        color: rgba(black, .8);
        margin-top: 10px;
      }




      .pricingup-success-cta-wrapper {
        margin-top: 10px;
      }

      .pricingup-success-cta {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: rgba(black, .8);
        margin-top: 5px;
        padding: 6px 12px;
        background: rgba(black,.10);
        border-radius: 4px;
        transition: background 100ms ease-in-out;
        &:hover {
          background: rgba(black,.15);
        }

      }

      .pricingup-success-info {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.4;
        color: rgba(black, .7);
        max-width: 240px;
        display: block;
        margin: 0 auto;
        margin-top: 15px;
        a {
          text-decoration: underline;
          font-weight: 500;
        }
      }











  .pricingup-checkout-arrow {
    position: absolute;
    top: 8px;
    left: 50%;

    z-index: 7;
    pointer-events: none;

    opacity: 0;

    transition: left 200ms ease-in-out, opacity 200ms ease-in-out 200ms;


    .pricingup-checkout.mod-plan-monthly & {
      left: 15.5%;
    }

    .pricingup-checkout.mod-plan-yearly & {
      left: 66.5%;
    }


    .pricingup-checkout.mod-inited & {
      opacity: 1;
    }

  }



    .pricingup-checkout-arrow-inner {
      position: relative;

      > div:first-of-type {
        display: block;
        position: absolute;
        z-index: 8;
        background: white;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        transform:  rotateZ(45deg);
        box-shadow: 0 0px 10px 0 rgba(0,0,0,.15);
        border-radius: 3px;
      }

      > div:last-of-type {
        display: block;
        position: absolute;
        z-index: 9;
        background: white;
        top: 6px;
        left: -7px;
        width: 30px;
        height: 15px;
        pointer-events: none;
      }
    }


  .pricingup-checkout-spinner {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-in-out 0ms;


    .pricingup-checkout.mod-inited & {
      transition: opacity 200ms ease-in-out 200ms;
      opacity: 1;
    }

    .pricingup-checkout.mod-loaded & {
      transition: opacity 200ms ease-in-out 0ms;
      opacity: 0;
    }
  }



  .pricingup-checkout-frame-wrapper {
    height: 100%;
    padding-top: 14px;


  }

  .pricingup-checkout-frame-target-wrapper {
    height: 100%;
    border-radius: 4px 4px 4px 4px;
    background: white;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,.22);
  }

  .pricingup-checkout-frame-target {
    height: 100%;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    padding-top: 5px;
    padding-bottom: 5px;

    .pricingup-checkout.mod-loaded & {
      opacity: 1;
    }

  }

















  .pricingup-footer {
    height: 78px;
    overflow: hidden;
    opacity: 1;
    transition: opacity 200ms ease-in-out, height 200ms ease-in-out;
    &.mod-inited  {
      opacity: 0.2;
      height: 0px;
      &:hover {
        opacity: 1;
      }
    }

    @media (max-width: 670px) {
      height: auto;
    }
  }

  .pricingup-footer-inner {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 15px;

    @media (max-width: 670px) {
      display: block;
    }
  }

  .pricingup-footer-text {
    text-align: center;
    margin-right: 10px;
    p {
      font-size: 11px;
      font-weight: 400;
      line-height: 1.3;
      color: darken(white,100);
      text-align: center;
    }

    p:nth-of-type(1) {
      font-weight: 500;
      a {
      }

    }

    p:nth-of-type(2) {
      color: darken(white,60);
      font-size: 10px;
    }

    p:nth-of-type(3) {
      color: darken(white,60);
      font-size: 10px;

    }


    a {
      text-decoration: underline;
    }
  }

  .pricingup-footer-methods {
    text-align: center;
    white-space: nowrap;
    display: block;

    @media (max-width: 670px) {
      margin-top: 10px;
    }
  }










</style>





<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .pricingup {
    background-color: darken(white,80);
    border-color: darken(white,71);
  }

  .pricingup-title {
    color: darken(white,10);
  }

  .pricingup-subtitle {
    color: darken(white,20);
  }

  .pricingup-footer-text {
    p {
      color: darken(white,30);
    }
    p:first-of-type {
      color: darken(white,10);
    }

  }

  .pricingup-no-checkout-msg {
    color: darken(white,20);
  }

}
</style>
