<script>
export default {

  name: 'pricingmgmt',


  props: [
    'context',
  ],

  data: function(){
    return {
    };
  },

  computed: {

    readableMgmtPremiumUntil: function(){
      if (!this.$store.state.user.mgmt_premium_until) return '';
      return this.$dfFormatD(this.$store.state.user.mgmt_premium_until);
    },

  },

  created: function(){
  },

  mounted: function(){
  },


  beforeDestroy: function(){
  },



  methods: {

  },


};
</script>


<template>
<div class="pricingmgmt" :class="['mod-context-'+context]">
  <div class="pricingmgmt-content">
    Your account is on sponsored (added by GGather administrator) GGather Plus plan until {{readableMgmtPremiumUntil}}.
  </div>
</div>
</template>


<style lang="scss" scoped>
  @import "../../../common/style/_variables.scss";

  .pricingmgmt {
    background: #F6F6F6;
    max-width: 640px;
    width: 100%;
    border-radius: 8px;
    //box-shadow: 0 2px 10px 0px rgba(black,.13);
    border: 1px solid darken(white,12);
    margin-bottom: 20px;
    padding: 20px;

    &.mod-context-settings {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 15px;
      max-width: none;
      width: 100%;
      background: darken(white,5);
      box-shadow: none;
      padding: 15px;
      border-radius: 4px;
    }


    @media (max-width: 670px) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      margin-bottom: 5px;
      padding: 10px;
      &.mod-context-settings {
      }
    }


  }

  .pricingmgmt-content {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    color: darken(white,95);
  }




</style>



<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {

  .pricingmgmt {
    background-color: darken(white,80);
    border-color: darken(white,73);

    &.mod-context-settings {
      background-color: darken(white,70);
      border-color: darken(white,64);
    }

  }

  .pricingmgmt-content {
    color: darken(white,15);
  }




}
</style>
