<script>
export default {

  computed: {
    visible: function(){
      return this.$store.state.jumpnotif;
    },


    tagName: function(){
      if (this.$route.meta.kind !== 'tagpage') { return false; }

      if (this.$store.state.tagPageMeta && this.$store.state.tagPageMeta.name) {
        return this.$store.state.tagPageMeta.name;
      }
      return this.$route.params.tagslug;
    },
  },


  methods: {
    close: function(){
      this.$store.commit('jumpnotif', 0);
    },
  },


};
</script>

<template>
<transition name="jumpnotif-anim" :duration="$store.state.transitionsEnabled ? 300 : 0">
<div v-show="visible" class="jumpnotif" @click="close">
  <div class="jumpnotif-background" @click="close"></div>
  <div class="jumpnotif-inner" @click="close" >
    <div class="jumpnotif-content" @click="close">

      <div class="jumpnotif-icon">
        <iconsvg icon="sheets-filled" />
      </div>

      <div class="jumpnotif-text">Link Added</div>

      <div class="jumpnotif-tagname-wrapper">

        <span v-if="tagName" :title="tagName">
          To "<span class="jumpnotif-tagname">{{tagName}}</span>"
        </span>

        <span v-else>
        </span>

      </div>

      <div class="jumpnotif-subtext">You can now rate, note and edit this link</div>

      <div class="jumpnotif-subsubtext">Click to dismiss</div>

    </div>
  </div>
</div>
</transition>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  $easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .jumpnotif  {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: auto;
    height: 100%;
    //background-color: rgba(black,.2);


  }


  .jumpnotif-background {
    background-color: rgba(#111,.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: auto;
    height: 100%;
    z-index: 900;

    will-change: transform, opacity;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;

    .jumpnotif.jumpnotif-anim-enter-active & {
      html.mod-transitions-enabled & {
        transition: transform 0ms ease-out, opacity 0ms ease-out;
      }
    }
    .jumpnotif.jumpnotif-anim-leave-active & {
      html.mod-transitions-enabled & {
        transition: transform 150ms ease-out, opacity 150ms ease-out;
      }
    }

    .jumpnotif.jumpnotif-anim-enter & , .jumpnotif.jumpnotif-anim-leave-to & {
    }

    .jumpnotif.jumpnotif-anim-enter & {
    }

    .jumpnotif.jumpnotif-anim-leave-to & {
      opacity: .0;
    }

  }

  .jumpnotif-inner {
    display: block;
    margin: auto;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 1100;


    will-change: transform, opacity;
    transform: rotate3d(0,0,0,0);
    backface-visibility: hidden;

    opacity: 1;


    .jumpnotif.jumpnotif-anim-enter-active & {
      html.mod-transitions-enabled & {
        transition: transform 000ms $easeOutBack, opacity 000ms $easeOutBack;
      }
    }
    .jumpnotif.jumpnotif-anim-leave-active & {
      html.mod-transitions-enabled & {
        transition: transform 200ms $easeOutBack, opacity 200ms $easeOutBack;
      }
    }

    .jumpnotif.jumpnotif-anim-enter & , .jumpnotif.jumpnotif-anim-leave-to & {
      opacity: .0;
    }

    .jumpnotif.jumpnotif-anim-enter & {
      transform: scale(0);
    }

    .jumpnotif.jumpnotif-anim-leave-to & {
      transform: scale(0);
    }


  }

  .jumpnotif-content {
    display: block;
    margin: auto;
    width: 200px;
    height: 200px;
    border-radius: 500px;
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column;
    padding-bottom: 8px;
    position: relative;

    // ggather color...
    background-color: $clr-ggather;
    border: 1px solid darken($clr-ggather, 4);
    box-shadow: 0 5px 25px -5px rgba( darken($clr-ggather, 30) , .35);

    // green color...
    background-color: darken(yellowgreen, 2);
    border: 1px solid darken(yellowgreen, 6);
    box-shadow: 0 5px 25px -5px rgba( darken(yellowgreen, 30) , .35);

  }


    .jumpnotif-icon {
      display: block;
      text-align: center;

      & /deep/ svg {
        margin: 0 auto;
        width: 80px;
        height: 80px;
        fill: white;
        line-height: 1;
      }
    }

    .jumpnotif-text {
      margin-top: 0px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      color: rgba(white,1);
    }

    .jumpnotif-tagname-wrapper {
      font-size: 14px;
      font-weight: 400;
      color: rgba(white,1);
      max-width: 150px;
      margin: 0 auto;
      margin-top: 3px;
      line-height: 1.2;
    }

      .jumpnotif-tagname {
        display: inline-block;
        white-space: nowrap;
        overflow-x: hidden;
        max-width: 110px;
        text-overflow: ellipsis;
      }

    .jumpnotif-subtext {
      font-size: 12px;
      font-weight: 400;
      color: rgba(white,.7);
      max-width: 150px;
      margin: 0 auto;
      margin-top: 5px;
      line-height: 1.2;
      margin-bottom: 5px;
    }

    .jumpnotif-subsubtext {
      font-size: 11px;
      font-weight: 400;
      color: rgba(white,.45);
      max-width: 150px;
      margin: 0 auto;
      margin-top: 7px;
      line-height: 1.2;

      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);

    }

</style>
