var polyfillRemove = function(){

  if (!Element.prototype.remove) {

    Element.prototype.remove = function() {
      this.parentElement.removeChild(this);
    }

  }

  if ( (!NodeList.prototype.remove) && (!HTMLCollection.prototype.remove) ) {

    NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
      for(var i = this.length - 1; i >= 0; i--) {
        if(this[i] && this[i].parentElement) {
          this[i].parentElement.removeChild(this[i]);
        }
      }
    }

  }



};

module.exports = polyfillRemove();
