<script>
import _debounce from 'lodash/debounce';
import _includes from 'lodash/includes';
export default {

  props: {

    /* Viewer Kinds:
      "edit" ⇄ edit button on link
      "urlsave" ⇄ "ggather.com/" + link
      "newlink" ⇄ add new link button
      "resave" ⇄ from feed & non-owner links
      "savepage" ⇄ "/save/" page (popup & bookmarklet)
      "chromebtn" ⇄ chrome extension button manual
      "chromesave" ⇄ chrome extension icon instant
    */

    viewerKind: {
      required: true,
      type: String,
    },

    viewerItem: {
      required: false,
      type: Object,
    },

  },

  data: function(){
    return {

      instantSaveDisabled: false,

      url: null,
      urlNew: null,
      urlInvalid: null,

      urlbookmark: null,
      urldata: null,

      // ub = urlbookmark
      ubGetting: false,
      ubGetFinished: false,
      ubAdding: false,
      ubAddFinished: false,
      ubEditFinished: false,
      ubEditIndicator: false,

      // ud = urldata
      udGetting: false,
      udGetFinished: false,
      udGetError: false,

    }
  },

  computed: {

    mainURLDisabled: function(){
      return ((this.viewerKind=='edit')   ||
          (this.viewerKind=='savepage')   ||
          (this.viewerKind=='chromesave') ||
          (this.viewerKind=='resave')     ||
          (this.viewerKind=='chromebtn')
      );
    },

    instantSave: function(){
      if (this.instantSaveDisabled) { return false };
      return ((this.viewerKind=='resave')  ||
          (this.viewerKind=='urlsave')     ||
          (this.viewerKind=='chromesave')  ||
          (this.viewerKind=='savepage')    ||
          (this.viewerKind=='chromebtn')
      );
    },

  },



  watch: {

    viewerKind: function(newVal){
      this.resetData();
      this.initURL();
      this.getURLBookmark();
    },

    viewerItem: function(newVal, oldVal){

      // mostly the case of new link addition...
      if ((!oldVal) || (!oldVal.url)) return;

      // mostly the case of urlbookmark edit
      if (newVal && (newVal.url===oldVal.url)) return;

      if (this.viewerKind==='newlink') return;

      this.resetData();
      this.initURL();
      this.getURLBookmark();

    },

    '$store.state.eventSaveThumbChange': function(e){
      this.changeFromSavethumb(e.payload);
    },

  },



  created: function(){
    this.initURL();
    this.getURLBookmark();
  },



  beforeDestroy: function(){
    this.cancelGetURLBookmark();
    this.cancelGetURLData();
  },


  methods: {

    setURLBookmarkData: function(data){
      this.urlbookmark = {
        url: data.url,
        pk: data.pk,
        title: data.title,
        description: data.description,
        owner_notes: data.owner_notes,
        date_created: data.date_created,
        date_updated: data.date_updated,
        tags: data.tags,
        rating: data.rating,
        thumbnail: data.thumbnail,
        thumbnail_worn: data.thumbnail_worn,
      }
      this.setURLDataData(data);
    },

    setURLDataData: function(data){
      this.urldata = {
        url: data.urldata.url,
        title: data.urldata.title,
        description: data.urldata.description,
        thumbnail: data.urldata.thumbnail,
        html_images: data.urldata.html_images,
        html_icons: data.urldata.html_icons,
        html_og: data.urldata.html_og,
        html_meta: data.urldata.html_meta,
        headers: data.urldata.headers,
        is_webpage: data.urldata.is_webpage,
      }
    },


    resetData: function(omit){
      var omit = omit ? omit.omit : {};

      omit.instantSaveDisabled ? null : this.instantSaveDisabled = false;

      omit.url ? null : this.url = null;
      omit.urlInvalid ? null : this.urlInvalid = null;

      omit.urlbookmark ? null : this.urlbookmark = null;
      omit.urldata ? null : this.urldata = null;

      // ub = urlbookmark
      omit.ubGetting ? null : this.ubGetting = false;
      omit.ubGetFinished ? null : this.ubGetFinished = false;
      omit.ubAdding ? null : this.ubAdding = false;
      omit.ubAddFinished ? null : this.ubAddFinished = false;
      omit.ubEditFinished ? null : this.ubEditFinished = false;
      omit.ubEditIndicator ? null : this.ubEditIndicator = false;

      // ud = urldata
      omit.udGetting ? null : this.udGetting = false;
      omit.udGetFinished ? null : this.udGetFinished = false;
      omit.udGetError ? null : this.udGetError = false;

    },




    initURL: function(){

      if (false) {} // noop

      else if (this.viewerKind=='urlsave') {
        this.url = this.$route.fullPath.substring(1) + ''; }

      else if (this.viewerKind=='chromebtn') {
        this.setURLFromChromeExtensionTab(); }

      else if (this.viewerKind=='chromesave') {
        this.setURLFromChromeExtensionTab(); }

      else if ((this.viewerKind=='savepage') && (this.$route.query.fronturl)) {
        this.url = this.$route.query.fronturl; }

      else if ((this.viewerKind=='savepage') && (this.$route.query.popupurl)) {
        this.url = this.$route.query.popupurl; }

      else if ((this.viewerKind=='savepage') && (this.$route.query.bookmarkleturl)) {
        this.url = this.$route.query.bookmarkleturl; }

      else if (this.viewerKind=='edit') {
        this.url = this.viewerItem.url; }

      else if (this.viewerKind=='resave') {
        this.url = this.viewerItem.url; }

      else if (this.viewerKind=='newlink') {
      }

      else {}

      this.validateBasicURL();

    },






    instantSaveHandler: function(){
      this.$nextTick(function(){
        if (this.instantSave) { this.addURLBookmark(); }
      });
    },




    validateBasicURL: function(){
      if (!this.url) {
        this.urlInvalid = false;
        return false;
      }

      /*
      try {
        new URL(this.url)
      } catch (TypeError) {
        this.urlInvalid = true;
      }

      if (this.url.indexOf('.') === -1) {
        this.urlInvalid = true;
      }

      if (this.url.indexOf('.') === (this.url.length - 1)) {
        this.urlInvalid = true;
      }

      if (this.url.indexOf('.') === (this.url.length - 2)) {
        this.urlInvalid = true;
      }


      if (this.url.slice(0,8).indexOf('//') === -1) {
        this.urlInvalid = true;
      }

      if ((this.url.indexOf('/') !== 5) && (this.url.indexOf('/') !== 6)) {
        this.urlInvalid = true;
      }

      */

      // the validator below allows for urls without http:// or https://
      this.urlInvalid = (
        !(
          _includes(this.url, '.') &&
          ( this.url.indexOf('.') !== (this.url.length - 1) ) &&
          ( this.url.indexOf('.') !== (this.url.length - 2) )
        )
        || this.url.indexOf(' ') > -1
      );

      return;

    },






    setURLFromChromeExtensionTab: function(){
      // get url from chrome tab
      // good to know: chrome extension opens app with "/index.html" path
      this.$extension.getActiveTabUrl(function(url){
        this.resetData();
        this.url = url;
        this.validateBasicURL();
        this.urlInvalid ? null : this.getURLBookmark();
      }.bind(this));
    },





    mainURLFocus: function(){
      this.$refs.url.setSelectionRange(0, 999999);
    },





    urlInputHandler: function(event){
      this.resetData({omit: {url:1}});
      this.instantSaveDisabled = true;
      this.validateBasicURL();
      this.urlInputHandlerDebounced();
    },




    urlInputHandlerDebounced: _debounce(function(){
      (!this.urlInvalid) ? this.getURLBookmark() : null;
    }, 1000),





    addItemToItems: function(itemData){
      if  (
          (
          this.$route.meta.kind=='tagpage' && (this.$route.params.username==this.$store.state.user.username)
          ) ||
          this.$route.meta.kind=='all'
        )

      {
        this.$store.commit('addItemAsFirst', itemData);
      }
    },




    setURLDataOnStateItem: function(URLData){
      this.$store.commit('replaceItemURLData', {pk: this.urlbookmark.pk, urldata: URLData});
    },




    setEditIndicator: function(event){
      this.ubEditFinished = event.ubEditFinished;
      this.ubEditIndicator = event.ubEditIndicator;
    },





    flashJumpnotif: function(){
      if (!this.instantSave) { return false; }
      this.$store.commit('jumpnotif', 1);
      setTimeout(function() {
        if (this.$store.state.jumpnotif) {
          this.$store.commit('jumpnotif', 0);
        }
      }.bind(this), 3000);
    },




    sendMessageToChromeExtension: function(data, responseCallback){
      if (!this.$store.state.chrome){return false;}
      this.$extension.sendMessage(data);
    },





    logSubtype: function(data){
      // 1 → WEBAPP-MANUAL, 2 → WEBAPP-RESAVE, 3 → WEBAPP-URL, 50 → BOOKMARKLET,
      // 100 → CHROME-EXT, 101 → CHROME-EXT-INSTANT
      var viewer = this.$route.meta.viewer || this.$store.state.viewer;
      if (viewer.kind=='newlink') { return 1; }
      if (viewer.kind=='edit') { return 2; }
      if (viewer.kind=='resave') {  return 3; }
      if (viewer.kind=='urlsave') { return 4; }
      if (viewer.kind=='chromebtn') {  return 100; }
      if (viewer.kind=='chromesave') {  return 101; }
      if (viewer.kind=='savepage') {
        if (this.$route.query.fronturl) { return 4; }
        if (this.$route.query.bookmarkleturl) { return 50; }
        if (this.$route.query.popupurl) { return 51; }
      }
      console.error('"logSubtype" haven\'t returned anything...');
    },




    editFromSavepreview: function(event){

      if (false) {} // noop

      else if (event.kind==='title') {
        this.urlbookmark.title = event.value;
        this.editURLBookmark('title');
      }

      else if (event.kind==='description') {
        this.urlbookmark.description = event.value;
        this.editURLBookmark('description');
      }

      else if (event.kind==='url') {
        this.urlNew = event.value;
        this.editURLBookmark('url');
      }
    },



    changeFromSavethumb: function(newUrlbookmarkData){
      var urlbookmark = JSON.parse(JSON.stringify(newUrlbookmarkData));
      this.urlbookmark.thumbnail = urlbookmark.thumbnail;
      this.urlbookmark.thumbnail_worn = urlbookmark.thumbnail_worn;
    },




    cancelGetURLBookmark: function(){
      this._getURLBookmarkRequest ? this._getURLBookmarkRequest.cancel() : null;
    },




    getURLBookmark: function(){

      var url = this.url ? this.url : (this.urldata ? this.urldata.url : false)
      if (!url) return false;

      this.ubGetting = true;
      this.ubGetFinished = false;

      this.cancelGetURLBookmark();
      var CancelToken = this.$axios.CancelToken;
      var source = CancelToken.source();
      this._getURLBookmarkRequest = source;

      this.$axios({
        cancelToken: source.token,
        url: this.$store.state.api + '/get-urlbookmark/',
        method: 'get',
        params: {
          url: url,
        },
      }).then(function(response){
        this.ubGetting = false;
        this.ubGetFinished = true;
        this.setURLBookmarkData(response.data);

      }.bind(this), function(error){
        if (!error.response) return false;

        if (error.status!=404) {
          this.ubGetting = false;
          this.ubGetFinished = true;
          this.getURLData();
          this.instantSaveHandler();
        } else {
          this.$alertify.error('Something went wrong while getting link...');
        }


      }.bind(this));


    },





    addURLBookmark: function(){

      this.ubAdding = false;
      this.ubAddFinished = true;

      this.$axios({
        url: this.$store.state.api + '/add-urlbookmark/',
        method: 'post',
        data: {
          url: this.url,
          log_subtype: this.logSubtype(),
        },
      }).then(function(response){
        this.ubAdding = false;
        this.ubAddFinished = true;
        if (response.data.code=='exists'){
          this.instantSaveDisabled = true;
          this.getURLBookmark();
          return;
        }
        this.flashJumpnotif();
        this.instantSaveDisabled = true;
        this.setURLBookmarkData(response.data);
        this.addItemToItems(response.data);
        this.$store.commit('updateViewerItem', response.data);
        this.sendMessageToChromeExtension({code: 'urladded', url: this.url});

        //this.$store.commit('updateViewerKind', 'edit');
        //this.$alertify.success('Link was added');
      }.bind(this), function(error){
        this.ubAdding = false;
        this.ubAddFinished = true;
        if (error.response && error.response.data) this.$alertify.alert('<strong>Error</strong> <br />'+error.response.data);
        else this.$alertify.error('Something went wrong while adding link...');
      }.bind(this));

    },




    editURLBookmark: /*_debounce(*/function(kind, value){

      this.ubEditIndicator = true;
      this.ubEditFinished = false;

      var url = this.urlbookmark ? this.urlbookmark.url : (this.urldata ? this.urldata.url : this.url);

      var data = { url: url }

      if (kind=='url') { data['url_new'] = this.urlNew; }
      if (kind=='title') { data['title'] = this.urlbookmark.title; }
      if (kind=='description') { data['description'] = this.urlbookmark.description; }
      if (kind=='rating') { data['rating'] = this.urlbookmark.rating == null ? 0 : this.urlbookmark.rating; }
      if (kind=='owner_notes') { data['owner_notes'] = this.urlbookmark.owner_notes; }

      var request = this.$axios({
        url: this.$store.state.api + '/edit-urlbookmark/',
        method: 'post',
        data: data,
      }).then(function(response){

        this.ubEditIndicator = false;
        this.ubEditFinished = true;

        // url was changed
        if (response.data.url && ( this.url !== response.data.url ) ) {
          //this.resetData({omit:{url:1,ubGetFinished:1}});
          this.resetData();
          this.urlNew = null;
          this.url = response.data.url;
          //this.setURLBookmarkData(response.data);
          this.getURLBookmark();
          this.getURLData();
        }

        // url was not changed
        else {
          this.setURLBookmarkData(response.data);
        }

        this.$store.commit('updateViewerItem', response.data);
        this.$store.commit('replaceItem', response.data);
        this.$store.commit('deleteTrackedRequest', {id: 'editURLBookmark'});
        //this.$alertify.success('Edited successfully');

      }.bind(this), function(error){

        this.ubEditIndicator = false;
        this.ubEditFinished = false;

        this.$store.commit('deleteTrackedRequest', {id: 'editURLBookmark'});
        if (error.response.data && error.response.data.code === 'already-exists') {
          this.$alertify.alert('Can\'t change URL. Bookmark with this URL already exists.');
        } else {
          this.$alertify.error('Something went wrong while editing bookmark...');
        }

      }.bind(this));

      this.$store.commit('addTrackedRequest', {id: 'editURLBookmark', request: request});

    }/*, 100)*/,



    notesInputHandler: _debounce(function(){
      this.editURLBookmark('owner_notes');
    }, 1000),





    deleteURLBookmark: function(){

      var url = this.urlbookmark ? this.urlbookmark.url : (this.urldata ? this.urldata.url : this.url);

      this.$axios({
        url: this.$store.state.api + '/delete-urlbookmark/',
        method: 'post',
        data: {
          url: url,
        },
      }).then(function(response){
        this.$store.commit('deleteItem', {pk: this.urlbookmark.pk});
        //this.$store.commit('updateViewerKind', 'newlink');
        this.resetData({omit:{url:1}});
        this.instantSaveDisabled = true;
        this.sendMessageToChromeExtension({code: 'urldeleted', url: url});
        this.getURLBookmark();
        //this.$alertify.log('Link was removed');
      }.bind(this));

    },










    cancelGetURLData: function(){
      this._getURLDataRequest ? this._getURLDataRequest.cancel() : null;
    },



    getURLData: function(){

      this.udGetting = true;
      this.udGetError = false;
      this.udGetFinished = false;

      this.cancelGetURLData();
      var CancelToken = this.$axios.CancelToken;
      var source = CancelToken.source();
      this._getURLDataRequest = source;

      this.$axios({
        cancelToken: source.token,
        url: this.$store.state.api + '/get-urldata/',
        method: 'get',
        params: {
          url: this.url,
        },
      }).then(function(response){

        this.udGetting = false;
        this.udGetFinished = true;
        this.udGetError = false

        this.setURLDataData(response.data);
        this.urlbookmark ? this.setURLDataOnStateItem(response.data.urldata) : null;

      }.bind(this), function(error){

        if (!error.response) { return false };
        this.udGetting = false;
        this.udGetFinished = true;
        this.udGetError = true;

        //this.$alertify.error('Something went wrong while getting link data...');

      }.bind(this));

    },









  }

};
</script>




















<template>
<div class="saveurl">
<div class="saveurl-inner">
<div class="saveurl-inner-inner">


  <!-- saveurl-url-main-wrapper -->
  <div class="saveurl-url-main-wrapper">



    <div class="saveurl-url-wrapper" :class="{'mod-invalid': urlInvalid, 'mod-valid': (!urlInvalid) && url, 'mod-disabled': mainURLDisabled}">

      <!-- Main URL Input -->
      <textarea v-autosize ref="url" rows="1" class="saveurl-url" maxlength="4000" placeholder="Link URL" v-model.trim="url" @input="urlInputHandler" @focus="mainURLFocus" @keydown.prevent.enter required></textarea>

      <!-- Favicon -->
      <div class="saveurl-url-favicon" :style="{backgroundImage: 'url(https://s2.googleusercontent.com/s2/favicons?domain_url='+ (url ? url : 'http://example.com/') +')'}"></div>

      <!-- User Image -->
      <div class="saveurl-url-user-image" :style="{backgroundImage: 'url('+$store.state.user.profile_image+')'}" v-tooltip="'Logged in as '+$store.state.user.username"></div>

    </div>





  </div>
  <!-- / saveurl-url-main-wrapper -->


  <!-- saveurl-url-content-all-wrapper -->
  <div class="saveurl-url-content-all-wrapper">
  <div class="saveurl-url-arrow" v-if="url"></div>



  <!-- saveurl-url-content-wrapper -->
  <div class="saveurl-url-content-wrapper"
  v-if="url && urlInvalid">


    <!-- URL Invalid Error -->
    <div class="saveurl-url-info mod-invalid-url" v-if="urlInvalid">
      Invalid URL
    </div>

  </div>
  <!-- / saveurl-url-content-wrapper -->






  <!-- saveurl-url-content-wrapper -->
  <div class="saveurl-url-content-wrapper  mod-spinner"
   v-if="url && (!urlInvalid) && (!ubGetFinished)">


    <div class="saveurl-url-spinner-wrapper">
      <spinner2 :size="32" :line-size="2" :speed="0.4" line-fg-color="gray" line-bg-color="transparent" />
    </div>

  </div>
  <!-- / saveurl-url-content-wrapper -->







  <!-- saveurl-url-content-wrapper -->
  <div class="saveurl-url-content-wrapper"
   v-if="url && (!urlInvalid) && ubGetFinished && (!ubGetting)">






    <div class="saveurl-url-under"  :class="{'mod-saved': urlbookmark}">


      <div class="saveurl-url-info-two-cols" v-if="urlbookmark">

        <!-- Update Info Spinner -->
        <div v-if="ubEditIndicator" class="saveurl-url-info  mod-editing">
          <spinner kind="circle" dark="true"></spinner>
        </div>

        <!-- Update Info Success -->
        <div v-if="ubEditFinished && urlbookmark.date_updated"  class="saveurl-url-info  mod-editing-success">
          Updated just now
        </div>


        <!-- Info BTN -->
        <div class="saveurl-url-info  mod-in-library" :title="urlbookmark.date_created">
          Saved {{urlbookmark.date_created|dfFormatDistanceFromNowOrNow}}
        </div>


        <!-- Delete Link BTN -->
        <button type="button" @click.prevent="deleteURLBookmark" class="saveurl-url-info mod-delete">
          Remove
        </button>

      </div>




      <!-- Save Link BTN -->
      <button type="button" v-if="!urlbookmark"
      @click.prevent.stop="addURLBookmark" class="saveurl-url-info mod-save">
        Save Link {{udGetting ? ' Now' : ''}}
      </button>



    </div>






    <div class="saveurl-box mod-preview">
      <div class="saveurl-box-inner">

        <!-- Preview -->
        <savepreview
          :url="url"
          :urlbookmark="urlbookmark"
          :urldata="urldata"
          :loading="udGetting"
          @edit="editFromSavepreview"
        />
        <!-- / Preview -->

      </div>

      <!-- Preview Error -->
      <div class="saveurl-url-info mod-no-preview" v-if="url && udGetError">
        Preview for this URL couldn't be loaded. You can still save this bookmark and edit it.
      </div>

    </div>





    <div v-if="urlbookmark">


      <!-- Rating -->
      <div class="saveurl-box   mod-rating" >
        <div class="saveurl-box-inner">
          <saverating value="rating" v-model="urlbookmark.rating" @input="editURLBookmark('rating')"></saverating>
        </div>
      </div>



      <!-- Note -->
      <div class="saveurl-box mod-notes">
        <div class="saveurl-box-inner">


          <div class="saveurl-notes-wrapper">
            <textarea v-autosize id="itemnotes" rows="2" class="saveurl-notes-input" :class="{'mod-empty': !urlbookmark.owner_notes}" maxlength="300" v-model.trim="urlbookmark.owner_notes" autofocus="autofocus" placeholder="Note" @change="editURLBookmark('owner_notes')" @keydown.prevent.enter  @keyup.prevent.enter="editURLBookmark('owner_notes')"></textarea>
          </div>

        </div>
      </div>



      <!-- Tags -->
      <div class="saveurl-box mod-tags">
        <div class="saveurl-box-inner">

          <savetags
            :instant-save-disabled="instantSaveDisabled"
            :urlbookmark="urlbookmark"
            @edit="setEditIndicator"
          />

        </div>
      </div>



    </div>



  </div>
  <!-- / saveurl-url-content-wrapper -->


  </div>
  <!-- /saveurl-url-content-all-wrapper -->


</div>
</div>
</div>
</template>




























<style lang="scss" scoped>


  @import "../../../common/style/_variables.scss";

  .saveurl {
    display: block;
    //height: 100%;
    //overflow: auto;

  }


    .saveurl-inner {
      height: 100%;
      display: block;
      display: flex;
      justify-content: center;
      align-content: center;
    }



    .saveurl-inner-inner {
      width: 100%;
      max-width: 640px;
      padding-bottom: 20px;

    }





  .saveurl-url-main-wrapper {
    display: block;
    overflow: hidden;
    border-radius: 2px 2px 0 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;

    @media (max-width: 650px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }



    .saveurl-url-wrapper {
      display: flex;
      position: relative;

      &.mod-disabled {
      }
    }

    .saveurl-url {
      font-weight: 300;
      font-size: 15px;
      width: 100%;
      display: block;
      line-height: 1.1;
      flex: 1;
      padding: 9px 15px 11px 15px;
      padding-left: 48px;
      padding-right: 48px;
      background-position: 20px 50%, calc(100% - 20px) 50%;
      background-size: 16px 16px;

      overflow: hidden;
      word-wrap: break-word;
      outline: none;
      html.mod-transitions-enabled & {
        transition: border-color 100ms ease-in-out, background-color 150ms ease-in-out, box-shadow 100ms ease-out;
      }

      outline: none;
      resize: none;

      border-width: 1px;
      border-radius: 3px;
      margin-bottom: 10px;

      background-color: white;
      border-color: darken(#eee,4);

      color: #333;

      &:hover {
      }

      &:focus {
        //box-shadow: 0 1px 7px 0 rgba(black,.25);
        //border-bottom-color: darken(#eee,8);
      }



      .saveurl-url-wrapper.mod-invalid & {
        background-color: lighten(orangered, 10);
        border-color: darken(orangered, 5);
        margin-bottom: 10px;
      }



      .saveurl-url-wrapper.mod-valid & {
        border-bottom-width: 2px;
        background-color: #fafafa;
        background-color: white;
        border-color: darken(#f3f3f3,5);
        border-bottom-width: 1px;
        margin-bottom: 10px;
        color: #aaa;
        html.mod-transitions-enabled & {
          transition: color 100ms ease-out;
        }
        &:hover {
          color: #888;
        }
        &:focus {
          color: #333;
        }
      }



      .saveurl-url-wrapper.mod-disabled & {
        pointer-events: none;
        user-select: none;
        background-color: darken(white,9);
        border-color: darken(white,12);
        color: darken(white,70);
      }

    }




    .saveurl-url-favicon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-82%);
      left: 21px;
      z-index: 120;
      background-size: contain;
      background-position: 50% 50%;
      overflow: hidden;
      opacity: .3;
      pointer-events: none;

      html.mod-transitions-enabled & {
        transition: opacity 100ms ease-out;
      }

      .saveurl-url-wrapper.mod-valid .saveurl-url:hover ~ & {
        opacity: .4;
      }
      .saveurl-url-wrapper.mod-valid .saveurl-url:focus ~ & {
        opacity: 1;
      }

    }


    .saveurl-url-user-image {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-79%);
      right: 21px;
      z-index: 20;
      background-size: contain;
      background-position: 50% 50%;
      border-radius: 3px;
      overflow: hidden;
      background-color: #eee;

      opacity: .3;
      html.mod-transitions-enabled & {
        transition: opacity 100ms ease-out;
      }

      .saveurl-url-wrapper.mod-valid .saveurl-url:hover ~ & {
        opacity: .4;
      }
      .saveurl-url-wrapper.mod-valid .saveurl-url:focus ~ & {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }






    .saveurl-url-under {
      position: relative;
      border-radius: 3px 3px 0 0;

      //&::before {
      //    position: absolute;
      //    background-color: lighten(#eee,1);
      //    bottom: 0;
      //    left: -20px;
      //    right: -20px;
      //    height: 1px;
      //    display: none;
      //}

      //&.mod-saved::before {
      //    content: '';
      //    display: block;
      //}

      &.mod-saved {
        background-color: darken(white,3);
        border: 1px solid darken(white,10);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 20px;
        padding-left: 20px;
      }

    }



    @keyframes saveurl-mod-editing-success {
      0% {
        transform: scale(1.1);
        //box-shadow: 0 0 15px 0 rgba(black,.2);
      }

      50% {
        transform: scale(0.9);
      }

      100% {
        transform: scale(1);
        //box-shadow: 0 0 0px 0 rgba(black,.0);
      }
    }


    .saveurl-url-info {

      display: inline-block;
      width: 100%;
      background-color: yellowgreen;
      color: rgba(black,.7);
      font-size: 14px;
      position: relative;
      text-align: center;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 15px 9px 15px;
      font-weight: 400;
      border-radius: 2px;
      text-align: center;

      border: 1px solid darken(yellowgreen,3);


      //border-bottom: 1px solid darken(darken(yellowgreen,2),1);
      //border-left: 1px solid lighten(darken(yellowgreen,2),2);
      //border-right: 1px solid lighten(darken(yellowgreen,2),2);
      //border-top: 1px solid lighten(darken(yellowgreen,2),3);

      border-right: none;
      outline: none;

      line-height: 1.2;


      &.mod-save {
        padding-top: 16px;
        padding-bottom: 16px;
        font-weight: 600;
        padding-left: 20px;
        color: white;
        border-radius: 2px 2px 0 0;
      }

      &.mod-invalid-url {
        color: darken(orangered, 30);
        background-color: lighten(orangered,10);
        border: 1px solid lighten(orangered,5);
        //margin-bottom: 20px;
      }

      &.mod-no-preview {
        background-color: white;
        font-size: 12px;
        color: orange;
        border-radius: 2px;
        border: 1px solid #eee;
        border-bottom: none;
        background-color: #fff7e9;
      }


      &.mod-delete {
        color: darken(orangered, 30);
        background-color: lighten(orangered,10);
        width: 61px;
        min-width: 61px;
        margin-left: 15px;
        text-align: right;
        font-weight: 400;
        color: white;
        opacity: .5;
        border-color: transparent;
        padding: 0;
        justify-content: flex-end;
        padding-right: 3px;

        html.mod-transitions-enabled & {
          transition: opacity 100ms ease-out, background-color 100ms ease-out;
        }

        &:hover {
          opacity: 1;
        }
      }

      &.mod-in-library {

        background-color: white;
        border: 1px solid #eee;

        font-weight: 500;
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        padding-left: 0px;


        color: rgba(black,.5);

        text-align: right;
        justify-content: flex-end;
      }



      &.mod-editing {
        background-color: white;
        min-width: 25px;
        width: 25px;
        padding: 0;

        html.mod-transitions-enabled & {
          transition: transform 50ms ease-out, box-shadow 50ms ease-out;
        }
        transform: scale(1.1);
        //box-shadow: 0 0 15px 0 rgba(black,.2);
        z-index: 100;
        height: 15px;

        background-color: transparent;
        border-color: transparent;
      }




      &.mod-editing-success {
        background-color: white;
        //min-width: 25px;
        //width: 25px;
        padding: 0;
        height: 15px;

        //background-color: yellowgreen;
        //border: 1px solid darken(yellowgreen,3);
        //transition: background-color 100ms ease-out, border-color 100ms ease-out, color 100ms ease-out;

        background-color: transparent;
        border-color: transparent;

        animation-name: saveurl-mod-editing-success;
        animation-duration: 200ms;

        color: rgba(black,.5);
        color: rgba(green,.9);

        font-weight: 400;

      }




    }


      .saveurl-url-info-two-cols  {
        display: flex;
      }







  .saveurl-url-content-all-wrapper {
    position: relative;

  }



    .saveurl-url-arrow {
      position: absolute;
      top: -13px;
      left: 50%;
      transform: translateX(-50%);
      //background-color: red;
      display: block;


      border-width: 7px;
      border-color: darken(#f7f7f7,10);
      border-style: solid;

      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
    }



  .saveurl-url-content-wrapper {
    border-radius: 2px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 3px;
    border-bottom: 1px solid  darken(#f3f3f3,5);

    //box-shadow: 0 2px 10px 0 rgba(black,.03);

    &.mod-spinner {
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid  darken(#f3f3f3,5);
    }

    @media (max-width: 650px) {
      margin-left: 0px;
      margin-right: 0px;
    }

  }





    .saveurl-url-spinner-wrapper {
      //padding-bottom: 20px;
      text-align: center;
    }



    .saveurl-box {

      &:last-of-type {
        padding-bottom: 0;
      }


      &.mod-preview {
        padding-top: 0;
      }


      &.mod-notes {
      }

      &.mod-rating {
      }

      &.mod-tags {
      }

    }

    .saveurl-box-inner {
      position: relative;
      //background-color: white;
      //border-radius: 2px;
      //border-bottom: 1px solid darken(#eee,1);
      //border-left: 1px solid lighten(#eee,2);
      //border-right: 1px solid lighten(#eee,2);
      //border-top: 1px solid lighten(#eee,3);
      //padding: 15px 15px;

      .saveurl-box.mod-tags & {
        background-color: transparent;
      }
    }












    .saveurl-label {
      display: block;
      font-size: 12px;
      color: rgba(black,.5);
      line-height: 1.2;
      font-weight: 500;
      //margin-top: 10px;
      margin-left: 3px;
    }



    @keyframes saveurl-label-spinner-spin {
      0% {
      transform: rotate(0deg);
      }
      100% {
      transform: rotate(360deg);
      }
    }

    .saveurl-label-spinner {
      display: inline-block;
      animation: saveurl-label-spinner-spin 500ms infinite linear;
      width: 11px;
      height: 11px;
      margin-top: 1px;
      margin-left: 3px;
      border-width: 1px;
      border-style: solid;
      border-radius: 20px;
      border-color: rgba(black,.2);
      border-top-color: rgba(black,.9);

    }






  .saveurl-notes-wrapper {
    display: flex;
    border-radius: 2px;
  }


    .saveurl-notes-label {
      width: 50px;
      min-width: 50px;
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.4;
      padding: 5px 0;
      //background-color: darken(white,.5);
      //border-right: 1px solid #eee;
      padding-top: 2px;
      padding-left: 2px;
      color: rgba(black,.5);
    }



    .saveurl-notes-input.saveurl-notes-input {
      opacity: .8;
      font-size: 13px;
      display: block;
      width: 100%;
      max-width: none;
      min-width: 0;
      min-height: 0;
      overflow: hidden;
      padding: 15px 18px;
      outline: none;
      resize: none;
      border: 1px solid darken(#eee,1);
      border-top: none;
      background: white;
      border-radius: 0px;

      html.mod-transitions-enabled & {
        transition: border-color 100ms ease-out, background-color 100ms ease-out, font-size 100ms ease-out;
      }

      &:hover {
        //border-color: darken(#eee,7);
      }

      &:focus {
        border-left-color: darken(#eee,5);
        border-right-color: darken(#eee,5);
        background-color: lighten(#eee,4);
      }

      &.mod-empty {
        //font-style: italic;
      }
    }














  .saveurl-tags {
    padding-top: 10px;
    padding-bottom: 10px;
  }




  .saveurl-last-save {
    color: YellowGreen;
  }

  .saveurl-last-save-enter-active {
    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-out, transform 100ms ease-out;
    }
  }
  .saveurl-last-save-leave-active {
    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-out, transform 100ms ease-out;
    }
  }
  .saveurl-last-save-enter {
    //transform: translateY(-10px) ;
    //transform:  scale(1.02);
    opacity: 0;
  }
  .saveurl-last-save-leave-to {
    //transform: translateY(10px);
    //transform:  scale(0.98);
    opacity: 0;
  }













</style>












<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .saveurl-url-main-wrapper {
  }

  .saveurl-url-wrapper {
  }

  .saveurl-url {
    background-color: darken(white,68);
    border-color: darken(white,63);
    color: rgba(white,.8);

    &:hover {
    }

    &:focus {
    }
    &::selection {
      background: rgba(white,.5);
    }
  }

  .saveurl-url-wrapper.mod-invalid .saveurl-url {
    background-color: #672a13;
    border-color: darken(#672a13, 5);
  }



  .saveurl-url-wrapper.mod-valid .saveurl-url {
    background-color: darken(white,68);
    border-color: darken(white,63);
    color: rgba(white,.6);
    &:hover {
      color: rgba(white,.8);
    }
    &:focus {
      color: rgba(white,.9);
    }
  }

  .saveurl-url-wrapper.mod-disabled .saveurl-url {
    background-color: darken(white,78);
    border-color: darken(white,70);
    color: rgba(white,.3);
  }



  .saveurl-url-favicon {
  }

  .saveurl-url-user-image {
    background-color: #eee;
  }



  .saveurl-url-info.mod-invalid-url {
    background-color: #672a13;
    border-color: darken(#672a13, 5);
    color: rgba(white,.7);
  }


  .saveurl-url-content-wrapper {
    background-color: darken(white,68);
    border-color: darken(white,63);
  }



  .saveurl-url-under {
    background-color: darken(white,68);
    border-color: darken(white,63);
  }

  .saveurl-url-info.mod-in-library {
    color: rgba(white,.5);
  }
  .saveurl-url-info.mod-editing-success {
    color: #18a118;
  }
  .saveurl-url-arrow {
    border-bottom-color: darken(white,63);
  }

  .saveurl-url-info.mod-no-preview {
    color: #cf9120;
    border-color: #6e6a64;
    background-color: #5e5a54;
  }


  .saveurl-notes-label {
    color: rgba(white,.5);
  }


  .saveurl-notes-wrapper {
    background-color: darken(white,68);
    border-color: darken(white,63);
  }

  .saveurl-notes-input.saveurl-notes-input {
    background-color: darken(white,68);
    border-color: darken(white,63);
    color: white;

    &:hover {
    }

    &:focus {
      background-color: darken(white,70);
      border-color: darken(white,63);
    }

    &.mod-empty {
    }

    &::placeholder {
      color: rgba(white,.5);
    }

  }












}
</style>
