<script>
export default {

  props: [

    'item',
    'context',
    'itemLayout',

    'isHighlighted',
    'selected',
    'unseen',
    'tagsPopoverOpen',
    'noTags',
    'followedTagsFirst',

    'title',
    'description',
    'titleThumbSlice',
    'thumbnail',
    'thumbnailHeight',
    'thumbnailWidth',
    'thumbnailColor',

    'selectOverlayClick',
    'linkClick',
    'linkStandardClick',

    'itemThumbLoad',

  ],

}
</script>



<template>
<div

  :data-masonry="itemLayout=='grid' || itemLayout=='card' || itemLayout=='card-small'"
  class="item item-grid"
  :class="['mod-'+itemLayout, {'mod-selected': selected}]"

>

  <button v-if="$store.state.itemsSelectMode" type="button" class="item-select-overlay" key="item-select-overlay" @click="selectOverlayClick"></button>


  <div v-if="unseen" class="item-unseen-notification" key="item-unseen-notification" v-tooltip="'New since your last visit. <br>Added ' + $dfFormatDistanceFromNow(item.date_created) + '.'"></div>


  <a v-if="(itemLayout!='card-small') && (itemLayout!='card-fixed-small')" :href="item.url" @click="linkStandardClick" @mouseup="linkClick"  class="item-grid-thumb-wrapper" key="item-grid-thumb-wrapper" rel="noopener noreferrer nofollow" target="_blank">

    <div v-if="thumbnail" class="item-grid-thumb" :style="{ backgroundImage: (itemLayout=='grid-fixed' || itemLayout=='card-fixed' ? 'url(' + thumbnail + ')' : '') }">
      <img v-if="itemLayout=='grid' || itemLayout=='card'" :src="thumbnail" @load="itemThumbLoad" :width="thumbnailWidth" :height="thumbnailHeight">
    </div>

    <div v-if="!thumbnail" class="item-grid-thumb-title-slice">{{titleThumbSlice}}</div>

    <div v-if="!thumbnail" class="item-grid-thumb mod-blank" :style="{ backgroundColor: thumbnailColor }"></div>


  </a>






  <a :href="item.url" @click="linkStandardClick" @mouseup="linkClick"  class="item-grid-hover-wrapper" key="item-grid-hover-wrapper" v-if="!$store.state.itemsSelectMode && (itemLayout=='grid' || itemLayout=='grid-fixed')" rel="noopener noreferrer nofollow" target="_blank">

    <div class="item-grid-hover-title" :title="title">
      <div class="item-grid-hover-favicon" :style="{backgroundImage: 'url(\'https://s2.googleusercontent.com/s2/favicons?domain_url=' + item.url +'\')'}"></div>
      {{title}}
    </div>

    <div class="item-grid-hover-description" :title="description" v-if="description">{{description}}</div>


    <div class="item-grid-hover-note" v-if="item.owner_notes">
      <itemnote
        :item-layout="itemLayout"
        :item-owner-username="item.owner.username"
        :item-owner-profile-image="item.owner.profile_image"
        :item-owner-notes="item.owner_notes"
      />
    </div>


    <div class="item-grid-hover-rating" v-if="item.rating">
      <itemrate
        :item-layout="itemLayout"
        :item-rating="item.rating"
      />
    </div>

  </a>





  <a  v-if="itemLayout=='card'  || itemLayout=='card-fixed' || itemLayout=='card-small' || itemLayout=='card-fixed-small'" :href="item.url" @click="linkStandardClick" @mouseup="linkClick" class="item-grid-text-wrapper" key="item-grid-text-wrapper" rel="noopener noreferrer nofollow" target="_blank">


    <div class="item-grid-favicon" :style="{backgroundImage: 'url(\'https://s2.googleusercontent.com/s2/favicons?domain_url=' + item.url +'\')'}"></div>

    <div class="item-grid-title" :title="title">
      {{title}}
    </div>

    <div v-if="description" class="item-grid-description" :title="description">{{description}}</div>



    <div class="item-grid-details-wrapper">


      <div class="item-grid-note" v-if="item.owner_notes">
        <itemnote
          :item-layout="itemLayout"
          :item-owner-username="item.owner.username"
          :item-owner-profile-image="item.owner.profile_image"
          :item-owner-notes="item.owner_notes"
        />
      </div>


      <div class="item-grid-rating" v-if="item.rating">
        <itemrate
          :item-layout="itemLayout"
          :item-rating="item.rating"
        />
      </div>

      <!--
      <router-link :to="'/'+item.owner.username" v-tooltip="item.owner.username">
        <div class="item-grid-user-avatar" :title="item.owner.username">
          <img :src="item.owner.profile_image" />
        </div>
        <div class="item-grid-user-name">{{item.owner.username}}</div>
      </router-link>

      <div class="item-grid-date-added" :title="$dfFormatC(item.date_created)">
        {{item.date_created|dfFormatDistanceFromNow}}
      </div>
      -->


    </div>


  </a>





  <itemnav

    :item="item"
    :context="context"
    :item-layout="itemLayout"

    :selected="selected"
    :tags-popover-open="tagsPopoverOpen"
    :no-tags="noTags"

  />





</div>
</template>































<style lang="scss">

  @import "../../../common/style/_variables.scss";


  $clr-light-green-600: green;
  $clr-red: red;
  $clr-grey-200: grey;
  $clr-light-blue-400: blue;
  $clr-orange: orange;



  /*------------------------------------*\
    Layout - Grid
  \*------------------------------------*/

  .item-grid {
    display: block;
    text-align: left;

    //width: 100%;

    font-size: 0;
    position: relative;
    //overflow: hidden;

    position: relative;
    border-radius: 6px;



    .item-grid:hover & {
      opacity: 1;
    }

    &.mod-grid-fixed {
      //height: 200px;
      width: 100%;
    }

    &.mod-card-fixed {
      //height: 200px;
      width: 100%;
    }

    &.mod-card-fixed-small {
      //height: 100px;
      width: 100%;
    }

    &.mod-card {
      border-top:    1px solid darken(white,7);
      border-right:  1px solid darken(white,8);
      border-left:   1px solid darken(white,8);
      border-bottom: 1px solid darken(white,10);
    }

    &.mod-card-fixed {
      border-top:    1px solid darken(white,7);
      border-right:  1px solid darken(white,8);
      border-left:   1px solid darken(white,8);
      border-bottom: 1px solid darken(white,10);
    }

    &.mod-card-small {
      border-top:    1px solid darken(white,7);
      border-right:  1px solid darken(white,8);
      border-left:   1px solid darken(white,8);
      border-bottom: 1px solid darken(white,10);
    }

    &.mod-card-fixed-small {
      border-top:    1px solid darken(white,7);
      border-right:  1px solid darken(white,8);
      border-left:   1px solid darken(white,8);
      border-bottom: 1px solid darken(white,10);

    }

  }




  .item-grid-thumb-wrapper {
    display: block;
    width: 100%;
    //height: 100%;
    border-radius: 6px;

    border-top:    1px solid darken(white,7);
    border-right:  1px solid darken(white,8);
    border-left:   1px solid darken(white,8);
    border-bottom: 1px solid darken(white,10);


    outline: none;

    position: relative;


    .item-outer.mod-grid-fixed & {
      height: 100%;
      height: 160px;
    }


    .item-outer.mod-card & {
      border: none;
      border-bottom: 1px solid darken(white,4);
      border-radius: 6px 6px 0 0;
    }

    .item-outer.mod-card-fixed & {
      height: 160px;
      border: none;
      border-bottom: 1px solid darken(white,4);
      border-radius: 6px 6px 0 0;
    }

  }

    .item-grid-thumb {
      width: 100%;
      //height: 100%;
      background-size: cover;
      border-radius: 6px;
      background-position: 50% 50%;
      background-color: darken(white,2);

      overflow: hidden;


      & > img {
        width: 100%;
        //max-width: 100%;
        min-height: 140px;
        height: auto;
        object-fit: contain;
        border-radius: 6px;

        .item-outer.mod-card & {
          border: none;
          border-radius: 6px 6px 0 0;
        }
        .item-outer.mod-card-fixed & {
          border: none;
          border-radius: 6px 6px 0 0;
        }
      }


      .item-outer.mod-grid-fixed & {
        height: 100%;
      }

      .item-outer.mod-grid-fixed & > img {
        height: 160px;
      }


      .item-outer.mod-card-fixed & {
        height: 100%;
      }

      .item-outer.mod-card-fixed & > img {
        height: 160px;
      }

      .item-outer.mod-card-fixed-small & {
        height: 100%;
      }

      .item-outer.mod-card-fixed-small & > img {
        height: 160px;
      }

      &.mod-blank {
        height: 160px;

        background-size: 100%;
        background-position: 50% 50%;
        background-image: linear-gradient(145deg, rgba(white,.1), rgba(black,.12));
      }


      .item-outer.mod-card & {
        border: none;
        border-radius: 6px 6px 0 0;
      }
      .item-outer.mod-card-fixed & {
        border: none;
        border-radius: 6px 6px 0 0;
      }
    }

    .item-grid-thumb-title-slice {
      position: absolute;
      z-index: 0;
      top: 38%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 48px;
      line-height: 0;
      font-weight: 600;
      white-space: nowrap;
      color: rgba(white,.7);
      text-transform: uppercase;

      top: 49%;
    }








  .item-grid-hover-wrapper {
    position: absolute;
    //top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background-color: rgba(black,.7);
    background-color: rgba(white,1);
    //background-image: linear-gradient(to bottom, rgba(black,.5), rgba(black,.8));

    padding: 8px 10px 5px 10px;
    overflow: hidden;
    border-radius: 0 0 6px 6px;
    opacity: 0;

    //border-top: 1px solid darken(white,20);

    box-shadow: 0 -2px 10px -5px rgba(black,.3);

    html.mod-transitions-enabled & {
      transition: opacity 100ms ease-in-out;
    }

    .item-grid:hover & {
      opacity: 1;
    }
  }

    .item-grid-hover-favicon {
      vertical-align: top;
      width: 16px;
      height: 16px;
      opacity: 1;
      //background-color: white;
      //border-radius: 2px;
      border-radius: 6px;

      position: absolute;
      top: 11px;
      left: 10px;

      display: none;
      // OUT OF SCOPE SELECTOR
      .items.mod-show-favicon & {
        display: inline-block;
      }

    }


    .item-grid-hover-title {
      color: black;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;

      max-height: 37px;
      overflow: hidden;
      padding-top: 2px;
      margin-bottom: 5px;

      text-indent: 0;
      // OUT OF SCOPE SELECTOR
      .items.mod-show-favicon & {
        text-indent: 22px;
      }
    }


    .item-grid-hover-description {
      font-size: 12px;
      color: black;
      line-height: 1.3;
      opacity: .7;
      margin-top: 5px;
      margin-bottom: 7px;

      max-height: 29px;
      overflow: hidden;
    }


    .item-grid-hover-note {
      position: relative;
      opacity: 1;
      display: block;
      margin-bottom: 5px;
    }
    .item-grid-hover-rating {
      margin-bottom: 3px;
    }









  .item-grid-text-wrapper {
    display: block;
    vertical-align: top;
    //width: calc(100% - 180px);
    background-color: white;
    overflow: hidden;

    position: relative;


    .item-grid.mod-card & {
      border-radius: 0 0 6px 6px;
    }
    .item-grid.mod-card-fixed & {
      height: 135px;
      border-radius: 0 0 6px 6px;
    }

    .item-grid.mod-card-small & {
      border-radius: 6px;
    }
    .item-grid.mod-card-fixed-small & {
      height: 135px;
      border-radius: 6px;
    }

  }


    .item-grid-favicon {
      vertical-align: top;
      width: 16px;
      height: 16px;
      opacity: 1;
      background-color: white;
      border-radius: 2px;
      margin-right: 3px;
      position: absolute;
      top: 11px;
      left: 13px;
      opacity: 1;

      display: none;
      // OUT OF SCOPE SELECTOR
      .items.mod-show-favicon & {
        display: inline-block;
      }
    }



    .item-grid-title {
      font-size: 15px;
      font-weight: 600;
      color: #333;
      line-height: 1.2;

      overflow: hidden;
      height: 35px;

      margin-left: 13px;
      margin-right: 13px;
      margin-top: 11px;
      margin-bottom: 0px;


      text-indent: 0;
      // OUT OF SCOPE SELECTOR
      .items.mod-show-favicon & {
        text-indent: 22px;
      }


    }

    .item-grid-description {
      font-size: 13px;
      opacity: .5;
      line-height: 1.2;
      overflow: hidden;
      max-height: 15px;

      margin-left: 13px;
      margin-right: 13px;
      margin-top: 5px;
    }







    .item-grid-details-wrapper {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 2px;
      padding-top: 10px;
      position: static;

      .item-grid.mod-card-fixed & {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
      }

      .item-grid.mod-card-fixed-small & {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
      }

    }





    .item-grid-note {
      position: relative;
      opacity: 1;
      display: block;
      margin-bottom: 7px;
    }

    .item-grid-rating {
      margin-bottom: 10px;
    }






      .item-grid-user-name {
        font-weight: 400;
        display: inline-block;
        line-height: 1.5;
        font-size: 12px;
        vertical-align: baseline;
        color: rgba(black,.6);
        margin-right: 8px;
      }



      .item-grid-user-avatar {
        width: 16px;
        height: 16px;
        display: inline-block;
        border-radius: 30px;
        opacity: .2;
        margin-top: 0px;
        margin-right: 6px;
        html.mod-transitions-enabled & {
          transition: opacity 70ms ease-in-out;
        }

        & > img {
          width: 100%;
          height: auto;
          border-radius: 30px;
        }

      }


      .item-grid-date-added {
        font-size: 12px;
        display: inline-block;
        vertical-align: baseline;
        font-weight: 400;
        color: rgba(black,.6);
        //padding-right: 10px;
        //border-right: 1px solid #eee;

      }
















</style>











<style lang="scss">
@import "../common/style/_variables.scss";
html.mod-app-theme-dark.mod-app-theme-dark.mod-app-theme-dark {



  .item-grid.mod-card,
  .item-grid.mod-card-fixed,
  .item-grid.mod-card-small,
  .item-grid.mod-card-fixed-small {
    //background-color: darken(white,83);
    border-top-color: darken(white,79);
    border-right-color: darken(white,78);
    border-left-color: darken(white,78);
    border-bottom-color: darken(white,78);
  }



  .item-grid-thumb-wrapper {
    //background-color: darken(white,83);
    border-top-color: darken(white,79);
    border-right-color: darken(white,78);
    border-left-color: darken(white,78);
    border-bottom-color: darken(white,78);
  }

  .item-grid-thumb {
    background-color: darken(white,80);
  }

  .item-grid-thumb-title-slice {
    //color: rgba(white,.5);
    //font-weight: 600;
  }


  .item-grid-text-wrapper {
    background-color: darken(white,83);
    color: rgba(white,.5);
  }

  .item-grid-favicon {
    background-color: darken(white,83);
  }

  .item-grid-title {
    color: rgba(white,.6);
  }





  .item-grid-hover-wrapper {
    background-color: darken(white,83);
    //box-shadow: 0 -2px 10px -5px rgba(black,.3);
  }


    .item-grid-hover-title {
      color: white;
    }


    .item-grid-hover-description {
      color: white;
    }

















}
</style>
