import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router/router.js';
import Fuse from 'fuse.js';
import MobileDetect from 'mobile-detect';
import __ from '../../common/script/dashdash.js';
import _merge from 'lodash/merge';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';

Vue.use(Vuex);

var NODE_ENV = process.env.NODE_ENV;
var BUILD = process.env.BUILD;
var VERSION = process.env.VERSION;
var CORE_URL = process.env.CORE_URL;
var WEBAPP_URL = process.env.WEBAPP_URL;
var HQ_URL = process.env.HQ_URL;
var CHROME_EXTENSION_ID = process.env.CHROME_EXTENSION_ID;
var CHROME = (BUILD==='chrome') || (BUILD==='devchrome');
var md = new MobileDetect(window.navigator.userAgent);



export default new Vuex.Store({







  /*----------------------------------------*\
    OPTIONS
  \*----------------------------------------*/
  strict: (BUILD==='devwebapp') || (BUILD==='devchrome'),






















  /*----------------------------------------*\
    STATE
  \*----------------------------------------*/
  state: {

    NODE_ENV: NODE_ENV,
    BUILD: BUILD,
    VERSION: VERSION,

    deviceMobile: md.mobile() ? md.mobile(): false,

    chrome: CHROME,
    apiHost: CORE_URL,
    api: CORE_URL + '/api',
    webapp:  WEBAPP_URL,
    hq: HQ_URL,
    chromeExtensionId: CHROME_EXTENSION_ID,

    user: null,
    windowWidth: null,
    windowHeight: null,
    overflowModFix: 0,
    unreadNotifs: null,
    hqUpdates: null,
    tags: null,
    tagsFilter: '',
    followedTagsUsers: null,
    profilePageMeta: null,
    tagPageMeta: null,
    contentScrolled: null,
    dialogbox: 0,
    publishInviteModal: 0,
    viewer: 0,
    jumpnotif: 0,
    sidebarVisible: 0,
    sidebarSpinner: 0,
    sidebarStatus: '',
    activityVisible: 0,
    userMenu: 0,
    unseenItems: null,
    itemsSelected: [],
    itemsSelectMode: false,
    items: null,
    itemsLoading: null,
    itemsMeta: null,
    titlebarMenuEnabled: false,
    activeDefaultItemLayout: null,
    trackedRequests: {},

    appTheme: 'default',
    itemLayout: 'panel',
    itemLayoutFavs: false,
    itemClickAction: 'viewer',
    viewerComments: false,
    transitionsEnabled: true,
    sidebarCollapse: true,
    viewerExpandLevel: 2,
    topbarUseTagColor: true,
    chromeLastPage: null,
    chromeOpenSave: false,
    chromeWidget: false,

    eventReadAllNotifs: +(new Date()),
    eventGetUnseenItems: +(new Date()),
    eventFollowOrUnfollowTag: +(new Date()),
    eventSaveThumbChange: {change: +(new Date()), payload: void 0},
    eventItemThumbLoad: +(new Date()),
    eventCloseDialogbox: +(new Date()),
    eventBatchEdited: +(new Date()),
    eventBatchDeleted: +(new Date()),

  },



























  /*----------------------------------------*\
    MUTATIONS
  \*----------------------------------------*/
  mutations: {

    /*------------------------------------*\
      General
    \*------------------------------------*/

    user: (s, p)                 => {Vue.set(s,'user', p)},
    windowWidth: (s, p)          => {Vue.set(s,'windowWidth', p)},
    windowHeight: (s, p)         => {Vue.set(s,'windowHeight', p)},
    overflowModFix: (s, p)       => {Vue.set(s,'overflowModFix', p)},
    unreadNotifs: (s, p)         => {Vue.set(s,'unreadNotifs', p)},
    hqUpdates: (s, p)            => {Vue.set(s,'hqUpdates', p)},
    tags: (s, p)                 => {Vue.set(s,'tags', p)},
    tagsFilter: (s, p)           => {Vue.set(s,'tagsFilter', p)},
    followedTagsUsers: (s, p)    => {Vue.set(s,'followedTagsUsers', p)},
    profilePageMeta: (s, p)      => {Vue.set(s,'profilePageMeta', p)},
    tagPageMeta: (s, p)          => {Vue.set(s,'tagPageMeta', p)},
    contentScrolled: (s, p)      => {Vue.set(s,'contentScrolled', p)},
    dialogbox: (s, p)            => {Vue.set(s,'dialogbox', p)},
    publishInviteModal: (s, p)   => {Vue.set(s,'publishInviteModal', p)},
    viewer: (s, p)               => {Vue.set(s,'viewer', p)},
    jumpnotif: (s, p)            => {Vue.set(s,'jumpnotif', p)},
    sidebarVisible: (s, p)       => {Vue.set(s,'sidebarVisible', p)},
    sidebarSpinner: (s, p)       => {Vue.set(s,'sidebarSpinner', p)},
    sidebarStatus: (s, p)        => {Vue.set(s,'sidebarStatus', p)},
    activityVisible: (s, p)      => {Vue.set(s,'activityVisible', p)},
    userMenu: (s, p)             => {Vue.set(s,'userMenu', p)},
    unseenItems: (s, p)          => {Vue.set(s,'unseenItems', p)},
    items: (s, p)                => {Vue.set(s,'items', p)},
    itemsLoading: (s, p)         => {Vue.set(s,'itemsLoading', p)},
    itemsMeta: (s, p)            => {Vue.set(s,'itemsMeta', p)},
    itemsSelected: (s, p)        => {Vue.set(s,'itemsSelected', p)},
    itemsSelectMode: (s, p)      => {Vue.set(s,'itemsSelectMode', p)},
    titlebarMenuEnabled: (s, p)  => {Vue.set(s,'titlebarMenuEnabled', p)},
    activeDefaultItemLayout: (s, p) => {Vue.set(s,'activeDefaultItemLayout', p)},


    /*------------------------------------*\
      LocalStorage
    \*------------------------------------*/
    appTheme: (s, p)            =>  { localStorage.setItem('appTheme', JSON.stringify(p));
                                      s.appTheme = p; },
    itemLayout: (s, p)          =>  { localStorage.setItem('itemLayout', JSON.stringify(p));
                                      s.itemLayout = p; },
    itemLayoutFavs: (s, p)      =>  { localStorage.setItem('itemLayoutFavs', JSON.stringify(p));
                                      s.itemLayoutFavs = p; },
    itemClickAction: (s, p)     =>  { localStorage.setItem('itemClickAction', JSON.stringify(p));
                                      s.itemClickAction = p; },
    viewerComments: (s, p)      =>  { localStorage.setItem('viewerComments', JSON.stringify(p));
                                      s.viewerComments = p; },
    transitionsEnabled: (s, p)  =>  { localStorage.setItem('transitionsEnabled', JSON.stringify(p));
                                      s.transitionsEnabled = p; },
    sidebarCollapse: (s, p)     =>  { localStorage.setItem('sidebarCollapse', JSON.stringify(p));
                                      s.sidebarCollapse = p; },
    viewerExpandLevel: (s, p)   =>  { localStorage.setItem('viewerExpandLevel', JSON.stringify(p));
                                      s.viewerExpandLevel = p; },
    topbarUseTagColor: (s, p)   =>  { localStorage.setItem('topbarUseTagColor', JSON.stringify(p));
                                      s.topbarUseTagColor = p; },
    chromeLastPage: (s, p)      =>  { localStorage.setItem('chromeLastPage', JSON.stringify(p));
                                      s.chromeLastPage = p; },
    chromeOpenSave: (s, p)      =>  { localStorage.setItem('chromeOpenSave', JSON.stringify(p));
                                      s.chromeOpenSave = p; },
    chromeWidget: (s, p)        =>  { localStorage.setItem('chromeWidget', JSON.stringify(p));
                                      s.chromeWidget = p; },




    /*------------------------------------*\
      Events
    \*------------------------------------*/
    eventReadAllNotifs: (s, p)         => { Vue.set( s, 'eventReadAllNotifs',       +(new Date()) ) },
    eventGetUnseenItems: (s, p)        => { Vue.set( s, 'eventGetUnseenItems',      +(new Date()) ) },
    eventFollowOrUnfollowTag: (s, p)   => { Vue.set( s, 'eventFollowOrUnfollowTag', +(new Date()) ) },
    eventSaveThumbChange: (s, p)       => { Vue.set( s, 'eventSaveThumbChange', {change: +(new Date()), payload: p}) },
    eventItemThumbLoad: (s, p)         => { Vue.set( s, 'eventItemThumbLoad',       +(new Date()) ) },
    eventCloseDialogbox: (s, p)        => { Vue.set( s, 'eventCloseDialogbox',      +(new Date()) ) },
    eventBatchEdited: (s, p)           => { Vue.set( s, 'eventBatchEdited',      +(new Date()) ) },
    eventBatchDeleted: (s, p)          => { Vue.set( s, 'eventBatchDeleted',      +(new Date()) ) },



    /*------------------------------------*\
      User Operations
    \*------------------------------------*/
    updateUser: function(s, p){ _merge(s.user, p); },




    /*------------------------------------*\
      Viewer Operations
    \*------------------------------------*/
    updateViewerItem: function(s, p){ s.viewer ? (s.viewer.item = p) : null; },
    updateViewerKind: function(s, p){ s.viewer ? (s.viewer.kind = p) : null; },




    /*------------------------------------*\
      Tracked Requests Operations
    \*------------------------------------*/
    addTrackedRequest: function(s, p){ s.trackedRequests[p.id] = p.request; },
    deleteTrackedRequest: function(s, p){ delete s.trackedRequests[p.id]; },




    /*------------------------------------*\
      Items Operations
    \*------------------------------------*/

    itemsConcat: function(s, p){
      s.items = s.items.concat(p);
    },

    addItemAsFirst: function(s, p){
      s.items.unshift(p);
    },

    replaceItem: function(s, p){
      let index = _findIndex(s.items, {pk: p.pk});
      index > -1 ? s.items.splice(index, 1, p) : null;
    },

    updateItem: function(s, p){
      let item = _find(s.items, {pk: p.pk});
      _merge(item, p);
    },

    deleteItem: function(s, p){
      let index = _findIndex(s.items, {pk: p.pk});
      index > -1 ? s.items.splice(index, 1) : null;
    },

    deleteItems: function(s, p){
      _remove(s.items, function(item){
        return p.indexOf(item.pk) > -1;
      });
      s.items.push([]); s.items.pop();
    },

    deleteAllItems: function(s, p){
      s.items = [];
    },

    replaceItemURLData: function(s, p){
      let item = _find(s.items, {pk: p.pk});
      item ? (item.urldata = p.urldata) : null;
    },

    replaceItemTags: function(s, p){
      let item = _find(s.items, {pk: p.pk});
      item ? (item.tags = p.tags) : null;
    },

    unseenItemsCountZero: function(s, p){
      if (s.unseenItems) {
        s.unseenItems.count = 0;
      }
    },



    /*------------------------------------*\
      Selected Items Operations
    \*------------------------------------*/

    selectItem: function(s, p){
      s.itemsSelected === '*' ? s.itemsSelected = [] : null;
      s.itemsSelected.push(p);
    },

    unselectItem: function(s, p){
      s.itemsSelected === '*' ? s.itemsSelected = [] : null;
      let index = s.itemsSelected.indexOf(p);
      index > -1 ? s.itemsSelected.splice(index, 1) : null;
    },

    selectAllItems: function(s, p){
      s.itemsSelected = '*';
    },

    unselectAllItems: function(s, p){
      s.itemsSelected = [];
      s.itemsSelected.push([]); s.itemsSelected.pop();
    },




    /*------------------------------------*\
      Tags Operations
    \*------------------------------------*/

    moveTag: function(s, p){
      var tag = _find(s.tags, {pk: p.pk});
      var tagIndex = _findIndex(s.tags, {pk: p.pk});
      var aboveTag = _find(s.tags, {pk: p.above});
      var aboveTagIndex = _findIndex(s.tags, {pk: p.above});


      // if moved tag is parent and has children, don't allow to nest it under other tags
      var requestedTagHasChildren = !!_find(s.tags, {parent: p.pk});
      var requestedActionIsNesting = !!aboveTag.parent;
      if (requestedActionIsNesting && requestedTagHasChildren) return;


      // nest or un-nest...
      if (aboveTag.parent) {
        tag.parent = aboveTag.parent;
      } else {
        tag.parent = null;
      }


      if (tagIndex === aboveTagIndex) {
        // do nothing if not moved to different position...
      } else {

        // change position...
        var removedTag = s.tags.splice(tagIndex, 1)[0];
        if (tagIndex > aboveTagIndex) {
          s.tags.splice(aboveTagIndex, 0, removedTag);
        } else {
          s.tags.splice(aboveTagIndex-1, 0, removedTag);
        }

      }

      //s.tags.push({}); s.tags.pop(); // force update state

    },


    nestTag: function(s, p){
      var tag = _find(s.tags, {pk: p.pk});

      // if moved tag is parent and has children, don't allow to nest it under other tags
      var requestedTagHasChildren = !!_find(s.tags, {parent: p.pk});
      if (requestedTagHasChildren) return;

      // nest...
      tag.parent = p.parent;


      // change tag index so nested tag will be always 1st
      var tagIndex = _findIndex(s.tags, {pk: p.pk});
      var parentTagIndex = _findIndex(s.tags, {pk: p.parent});

      // change position...
      var removedTag = s.tags.splice(tagIndex, 1)[0];
      if (tagIndex > parentTagIndex) {
        s.tags.splice(parentTagIndex+1, 0, removedTag);
      } else {
        s.tags.splice(parentTagIndex, 0, removedTag);
      }

    },

    favoriteTag: function(s, p){
      var tag = _find(s.tags, {pk: p.pk});
      tag.favorite = p.favorite;
    },

    deleteTag: function(s, p){

      // if the tag has children, remove the tag as a parent of those children...
      var requestedTagChildren = _filter(s.tags, {parent: p.pk});
      if (requestedTagChildren) {
        for (var childrenTag of requestedTagChildren) {
          childrenTag.parent = null;
        }
      }

      var tagIndex = _findIndex(s.tags, {pk: p.pk});
      tagIndex > -1 ? s.tags.splice(tagIndex, 1) : null;
      //s.tags.push({}); s.tags.pop(); // force update state
    },


    updateTag: function(s, p){
      let tag = _find(s.tags, {pk: p.pk});
      _merge(tag, p);
    },




    /*------------------------------------*\
      Tag Page Meta Operations
    \*------------------------------------*/

    updateTagPageMeta: function(s, p){
      _merge(s.tagPageMeta, p);
    },




  },






































  /*----------------------------------------*\
    GETTERS
  \*----------------------------------------*/
  getters: {

    userIsAuthenticated: function(s){
      return s.user ? s.user.is_authenticated : false;
    },

    pageWithSidebar: function(s){
      if (s.route.meta.kind==='all') return true;
      if (s.route.meta.kind==='search') return true;
      // show sidebar only "in library" for user own tags...
      if (s.route.params && s.user) {
        if (s.route.meta.kind==='tagpage' && (s.route.params.username === s.user.username)) return true;
      }
      return false;
    },

    topbarColor: function(s){
      if (!s.topbarUseTagColor) return 0;
      // first try to get color from active tag
      if ((s.route.params && s.user) && (s.route.params.username === s.user.username)) {
        var tagSlug = s.route.params.tagslug;
        var tag = _find(s.tags, {slug: tagSlug});
        if (tag) return tag.color;
      }
      // if that fails try to get color from loaded tagPageMeta
      var kind = s.route.meta.kind;
      if ( (kind==='tagpage') && s.tagPageMeta && s.tagPageMeta.color) return s.tagPageMeta.color;
      else return 0;
    },

    titlebarVisible: function(s){
      var kind = s.route.meta.kind;
      if (kind=='tagpage' && s.route.params.username && (s.route.params.username === s.user.username)) return true;
      if (kind=='all') return true;
      if (kind=='search') return true;
      return false;
    },

    CTABarActive: function(s){
      if (s.route.meta.kind!=='tagpage') return false;
      try {
        return s.route.params.username !== s.user.username;
      } catch (TypeError) {
        // noop
      }
      return false;
    },

    visibleItemLayout: function(s){
      if (s.route.meta.kind=='feed') { return 'feed'; }
      if (s.route.meta.kind=='discover') { return 'panel'; }
      if (s.route.meta.kind=='profilepage') { return 'panel'; }
      if (s.route.meta.kind=='search') { return 'panel'; }
      if (s.activeDefaultItemLayout) { return s.activeDefaultItemLayout; }
      else { return s.itemLayout }
    },

    finalSidebarCollapse: function(s){
      if (s.windowWidth>900) return s.sidebarCollapse;
      else return true;
    },

    finalViewerComments: function(s){
      var viewer = s.route.meta.viewer || s.viewer;
      if (['view', 'edit', 'proxy', 'read', 'detail'].indexOf(viewer.kind) > -1) {
        return s.viewerComments;
      }
    },

    favoriteTags: function(s){
      if (s.tags===null) return null;
      if (!s.tags || !s.tags.length) return [];
      var sifter = function(tag){
        return tag.favorite == true;
      };
      return s.tags.filter(sifter);
    },

    parentTagsWithChildren: function(s){
      if (s.tags===null) return null;
      if (!s.tags || !s.tags.length) return [];
      var sifter = function(tag){
        return !tag.parent;
      };
      var parentTags = s.tags.filter(sifter);
      var parentSifter = function(tag){
        var childrenTag = _find(s.tags, {parent: tag.pk});
        return !!childrenTag;
      };
      return parentTags.filter(parentSifter);
    },

    tagsTree: function(s){
      if (s.tags===null) return null;
      if (!s.tags || !s.tags.length) return [];
      var output = [];
      var tagsClone = _cloneDeep(s.tags);

      var colors = {default: 1, brown: 2, red: 3, orange: 4, green: 5, teal: 6, blue: 7, pink: 8, purple: 9};
      var filterValue = s.tagsFilter.trim();
      var specialFilterEnabled = s.tagsFilter.trim().slice(0,1) === ':';
      var specialFilterValue = s.tagsFilter.trim().slice(1);
      var fuzzySearchPKs = [];

      // fuzzy search...
      if ((filterValue) && (!specialFilterEnabled)) {

        //var tagsClone = _cloneDeep(context.state.tags);
        var fuse = new Fuse(s.tags, {
          keys: ['name'],
          threshold: 0.2,
          //distance: 1000,
        })
        var searchedTags = fuse.search(filterValue);
        fuzzySearchPKs = searchedTags.map(function(result, index){
          return result.item.pk;
        }.bind(this));

      }



      // filters...
      for (var tag of tagsClone) {

        // nest in parents...
        if (!tag.parent) output.push(tag);
        if (tag.parent) {
          var parentTag = _find(tagsClone, {pk: tag.parent});
          if (!parentTag.children) (parentTag.children=[]);
          parentTag.children.push(tag);
        }

        // and next, filter the tags...
        var visible = false;

        // show all...
        if (filterValue==='' || filterValue===':') {
          visible = true;
        }

        // fuzzy search...
        else if (!specialFilterEnabled) {
          visible = !!(fuzzySearchPKs.indexOf(tag.pk) > -1);
        }

        // other special filters...
        else if (specialFilterEnabled) {

          // color
          if (Object.keys(colors).indexOf(specialFilterValue) > -1) {
            visible = tag.color === colors[specialFilterValue];
          }
          // favorite
          else if (specialFilterValue==='fav') {
            visible = !!tag.favorite;
          }
          // private
          else if (specialFilterValue === 'priv') {
            visible = tag.visibility === 1;
          }
          // unlisted
          else if (specialFilterValue === 'un') {
            visible = tag.visibility === 2;
          }
          // public
          else if (specialFilterValue === 'pub') {
            visible = tag.visibility === 3;
          }
          // recently used
          else if (specialFilterValue === 'rec') {
            visible = !!tag.recently_used;
          }
          // parent tag
          else if (specialFilterValue.slice(0,6) === 'parent') {
            var parentSlug = specialFilterValue.slice(7);
            var parentTagFoundBySlug = _find(s.tags, {slug: parentSlug});
            if (parentTagFoundBySlug && parentTagFoundBySlug.pk === tag.parent) {
              visible = true;
            }
            else if (tag.slug===parentSlug) {
              visible = true;
            }
          }
          // parent tag pk
          //else if (!isNaN(parseInt(specialFilterValue))) {
          //  //visible = tag.pk == filterValue;
          //  visible = true;
          //}
          // first letter
          else if (specialFilterValue.length===1) {
            var tagFirstLowerLetter = tag.name.trim().toLowerCase().slice(0,1);
            if (specialFilterValue==='#') {
              visible = /\W|_|\d/gi.test(tagFirstLowerLetter);
            }
            else {
              visible = tagFirstLowerLetter === specialFilterValue.toLowerCase();
            }
          }

        }

        tag.__visible = visible;
      }



      return output;
    },

  },























  /*----------------------------------------*\
    ACTIONS
  \*----------------------------------------*/
  actions: {



    /*------------------------------------*\
      DOM Events
    \*------------------------------------*/

    setupWindowEvents: function(context){
      window.addEventListener('resize', function(event){
        context.dispatch('setWindowDimensions');
      });
    },

    setupWindowMessages: function(context){
      window.onmessage = function(event) {
        if (event.data.code=='social-login-callback') {
          context.dispatch('socialLoginCallback', event.data.token);
        }
      };
    },





    /*------------------------------------*\
      Hooks & Initialization
    \*------------------------------------*/

    appCreated: function(context){
      context.dispatch('localStorageBackwardsCompatibility');
      context.dispatch('startApp');
    },

    appMounted: function(context){
      context.dispatch('setupWindowEvents');
      context.dispatch('setupWindowMessages');
    },

    //
    startApp: function(context, fromReset){

      // set window dimensions...
      context.dispatch('setWindowDimensions');

      // if application is started with save url...
      var location = window.location; //console.warn(JSON.parse(JSON.stringify(location)));
      var routeIsSaveURL = location.pathname.indexOf('.') > -1;
      var routeIsChromeSaveURL = location.pathname.indexOf('app/index.html') > -1;

      if (routeIsSaveURL && (!routeIsChromeSaveURL)) router.replace({
        path: '/save',
        query: {
          fronturl: location.href.replace(location.origin, '').substr(1),
        }
      });

      // set env header
      Vue.axios.defaults.headers['GGather-Build'] = BUILD;

      // check for explicit token in url
      if (router.currentRoute.query.jwt) {
        var token = router.currentRoute.query.jwt;
        router.replace( {query: null });
        context.dispatch('explicitTokenLogin', token);
        return false;
      }

      // try to get token
      var token = Vue.cookie.get('jwttoken');

      // refresh jwt token
      if (token) {
        context.dispatch('startAppRefreshJWTToken')
      } else {
        context.dispatch('setAnonymousUser');
        if (!fromReset) context.dispatch('setRedirectAfterAuthCookie');
        context.dispatch('anonRedirect');
      }

      // sync localstorage items
      context.dispatch('syncLocalStorage', 'itemClickAction');
      context.dispatch('syncLocalStorage', 'itemLayout');
      context.dispatch('syncLocalStorage', 'itemLayoutFavs');
      context.dispatch('syncLocalStorage', 'viewerComments');
      context.dispatch('syncLocalStorage', 'transitionsEnabled');
      context.dispatch('syncLocalStorage', 'sidebarCollapse');
      context.dispatch('syncLocalStorage', 'appTheme');
      context.dispatch('syncLocalStorage', 'chromeLastPage');
      context.dispatch('syncLocalStorage', 'chromeOpenSave');
      context.dispatch('syncLocalStorage', 'chromeWidget');

      // set viewer expand level
      if(context.state.chrome) {
        context.commit('viewerExpandLevel', 4);
      } else {
        context.dispatch('syncLocalStorage', 'viewerExpandLevel');
      }

    },

    setTagPageSidebarTag: function(context){
      if (!router.currentRoute.meta) { return false; }
      var kind = router.currentRoute.meta.kind;
      var routeUsername = router.currentRoute.params.username;
      var userUsername = context.state.user.username;
    },




    /*------------------------------------*\
      Auth & User
    \*------------------------------------*/


    // always called after obtaining token and also refreshing it...
    successTokenAuth: function(context, data){
      context.dispatch('removeJWTToken');
      context.dispatch('setJWTToken', data.token);
      context.commit('user', data.user);
      context.dispatch('setTagPageSidebarTag');

      // get tags...
      context.dispatch('getTags');

      // userleap
      //window.UserLeap && window.UserLeap('setUserId', data.user.pk);
      //window.UserLeap('setEmail', options.userEmail);
      //window.UserLeap && window.UserLeap('setAttributes', {
      //  first_name: data.user.first_name,
      //  last_name: data.user.last_name,
      //  is_premium: data.user.is_premium,
      //});
      //window.UserLeap && window.UserLeap('track', 'auth');

      // apm
      window._apm && window._apm.setUserContext({
        id: data.user.pk,
        username: data.username,
        email: data.email,
      })

      // defer it a little
      setTimeout(function() {
        context.dispatch('getUnseenItems');
        context.dispatch('getUnreadNotifs');
      }, 2000);

    },

    badTokenAuth: function(context){
      context.dispatch('setAnonymousUser');
      context.dispatch('setRedirectAfterAuthCookie');
      context.dispatch('anonRedirect');
    },


    signinUser: function(context, data){

      Vue.axios({
        url: context.state.api + '/token/',
        method: 'post',
        data: {
          username: data.username,
          password: data.password,
        },
      }).then(function(response){
        context.dispatch('successTokenAuth', response.data);
        context.dispatch('afterAnonAuth');
        context.dispatch('authRedirect');
        context.commit('dialogbox', false);
        context.dispatch('newLog', {code: 'login'});
        data.onSuccess ? data.onSuccess(response) : null;
      }.bind(this), function(error){
        context.dispatch('badTokenAuth');
        data.onError ? data.onError(error) : null;
      }.bind(this));

    },



    refreshJWTToken: function(context){
      var existingToken = Vue.cookie.get('jwttoken');
      if (!existingToken) {
        console.warn('[refreshJWTToken] no token cookie.');
        return;
      }
      Vue.axios({
        url: context.state.api + '/token/refresh/',
        method: 'post',
        data: {
          token: existingToken,
        }
      }).then(function(response){
        context.dispatch('successTokenAuth', response.data);
        context.dispatch('afterAnonAuth');
        context.dispatch('authRedirect');
        context.commit('dialogbox', false);
      }.bind(this), function(error){
        context.dispatch('badTokenAuth');
      }.bind(this));
    },



    startAppRefreshJWTToken: function(context){
      var aer = window._appEagerRefresh;
      function finished(){
        if (aer.success) {
          context.dispatch('successTokenAuth', aer.responseJSON);
          context.dispatch('authRedirect', true);
          context.commit('dialogbox', false);
        } else {
          context.dispatch('badTokenAuth');
        }
      }
      if (aer.loading) aer.finishCallback = () => finished();
      else finished();
    },



    setAnonymousUser: function(context){

      // make user anon
      context.commit('user', {
        is_authenticated: false,
        is_anonymous: true,
      });

      // apm
      window._apm && window._apm.setUserContext({
        id: null,
        username: null,
        email: null,
      })

    },


    setRedirectAfterAuthCookie: function(context){

      // pathname is original path before changing route to /sign-in
      // set redirect only if page needs auth...
      var currentPageNeedsAuth = Boolean(context.state.route.meta.auth);
      if (currentPageNeedsAuth) {
        var redirectPath = encodeURIComponent( (window.location.href).replace(context.state.webapp, '') );
        Vue.cookie.set('redirectAfterAuth', redirectPath);
      }

    },

    // logout user, nothing else to say, clears things up
    logoutUser: function(context){
      context.dispatch('removeJWTToken');
      context.dispatch('resetApp');
      context.dispatch('newLog', {code: 'logout'});
      router.push({ path: '/sign-in' });

      //window.UserLeap && window.UserLeap.logoutUser();

      //Vue.alertify.log('<span class=\'icon icon-checkmark\'></span> Logged out successfully');
    },

    // stores jwt token to cookie and set default authorization header on axios
    // if there's a provided token parameter then it uses it
    // otherwise it takes existing one from the cookie
    setJWTToken: function(context, token){
      token = token ? token : Vue.cookie.get('jwttoken');
      Vue.cookie.set('jwttoken', token, {
        expires: 14,
        sameSite: 'Strict',
      });
      Vue.axios.defaults.headers['Authorization'] = 'JWT ' + token;
    },

    // remove token
    removeJWTToken: function(context){
      Vue.cookie.delete('jwttoken');
      delete Vue.axios.defaults.headers['Authorization'];
    },

    // explicit token login
    explicitTokenLogin: function(context, token){
      context.dispatch('setJWTToken', token);
      context.dispatch('refreshJWTToken');
    },

    // social login window close token callback
    socialLoginCallback: function(context, token){
      context.dispatch('setJWTToken', token);
      context.dispatch('refreshJWTToken');
    },

    // do it on token obtain success, but not on refresh
    afterAnonAuth: function(context){
      context.commit('itemLayout', 'panel');
      context.commit('itemLayoutFavs', false);
      context.state.chrome ? null : context.commit('sidebarCollapse', false);
    },


    anonRedirect: function(context) {
      var currentPageNeedsAuth = Boolean(context.state.route.meta.auth);
      //var currentPageIsChromeStart = window.location.pathname.indexOf('/app/index.html') > -1;
      if (currentPageNeedsAuth) router.replace('/sign-in');
    },


    authRedirect: function(context, isRefresh){

      // get and remove redirect cookie
      var redirectCookie = Vue.cookie.get('redirectAfterAuth');
      Vue.cookie.delete('redirectAfterAuth');

      // open last closed page (chrome extension only)
      var userAuthenticated = context.state.user && context.state.user.is_authenticated;
      var openChromeCheck = context.state.chrome && context.state.chromeLastPage && (!context.state.chromeOpenSave);
      if (isRefresh && openChromeCheck && userAuthenticated) {
        router.push(context.state.chromeLastPage);
        return;
      }

      // redirect to library page if in landing page and we're only refreshing auth...
      if (isRefresh && (window.location.pathname==='/')) {
        router.replace('/all');
        return;
      }

      // chrome open...
      if (context.state.chrome && (!context.state.chromeOpenSave)) {
        router.replace('/settings/extension');
        return;
      }

      // cookie redirect
      if (redirectCookie) {
        router.replace(decodeURIComponent(redirectCookie));
        return;
      }

      // else from landing page to /all
      if (window.location.pathname==='/'){
        router.replace('/all');
        return;
      }

    },




    /*------------------------------------*\
      Data Fetch
    \*------------------------------------*/

    getUnreadNotifs: function(context){
      if (!context.state.user.is_authenticated) { return false }
      Vue.axios({
        url: context.state.api + '/get-unread-notifs/',
        method: 'get',
      }).then(function(response){
        context.commit('unreadNotifs', response.data);
      }.bind(this), function(error){}.bind(this));
    },

    getUnseenItems: function(context){
      if (!context.state.user.is_authenticated) { return false }
      Vue.axios({
        url: context.state.api + '/get-unseen-items/',
        method: 'get',
      }).then(function(response){
        context.commit('unseenItems', response.data);
        context.commit('eventGetUnseenItems');
      }.bind(this), function(error){}.bind(this));
    },



    /*------------------------------------*\
      Actions
    \*------------------------------------*/

    readAllNotifs: function(context){
      if (context.state.user.is_anonymous) {
        context.commit('dialogbox', {kind: 'signinup', subkind: 'signin'});
        return false;
      }
      Vue.axios({
        url: context.state.api + '/read-all-notifs/',
        method: 'post',
      }).then(function(response){
        context.commit('unreadNotifs', 0);
        context.commit('eventReadAllNotifs');
      }.bind(this));
    },


    // new user log
    newLog: function(context, payload){
      Vue.axios({
        url: context.state.api + '/new-log/',
        method: 'post',
        data: {
          code: payload.code,
          data: JSON.stringify(payload.data),
        },
      });
    },


    setWindowDimensions: function(context){
      var width = window.innerWidth || document.documentElement.clientWidth;
      var height = window.innerHeight || document.documentElement.clientHeight;
      context.commit('windowWidth', width);
      context.commit('windowHeight', height);
    },








    /*------------------------------------*\
      Actions Tags
    \*------------------------------------*/

    getTags: function(context, payload){
      payload = payload ? payload : {};

      if (!payload.force) {
        if ((!context.state.user.is_authenticated) || (context.state.tags)) return false;
      }
      Vue.axios({
        url: context.state.api + '/get-library-tags/',
        method: 'get',
      }).then(function(response){
        context.commit('tags', response.data.tags);
        payload.onSuccess ? payload.onSuccess(response) : null;
      }.bind(this), function(error){
        window.alert('Something went wrong while getting tags...');
        payload.onError ? payload.onError(error) : null;
      });
    },

    getFollowedTagsUsers: function(context, force){
      if (!force) {
        if ((!context.state.user.is_authenticated) || (context.state.followedTagsUsers)) {
          return false;
        }
      }
      Vue.axios({
        url: context.state.api + '/get-fl-tags-users/',
        method: 'get',
      }).then(function(response){
        context.commit('followedTagsUsers', response.data.users);
      }.bind(this));
    },



    apiSyncTags: function(context, payload){
      if (!context.state.tags) return;
      payload = payload ? payload : {};

      var tagsNewData = [];

      for (var i = 0; i < context.state.tags.length; i++) {
        var tag = context.state.tags[i];
        tagsNewData.push({
          pk: tag.pk,
          ppk: tag.parent ? tag.parent : void 0,
          f: tag.favorite ? tag.favorite : void 0,
          i: i,
        });
      }


      if (payload.showSpinner) {
        context.commit('sidebarSpinner', true);
      }

      //context.commit('sidebarStatus', 'Synchronizing tags...');

      Vue.axios({
        url: context.state.api + '/sync-tags/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          'tags_new_data': tagsNewData,
          'tags_in_response': payload.tagsInResponse ? true : false,
        },
      }).then(function(response){

        if (response.data.error) {
          window.alert('apiSyncTags Error: ' + response.data.error);
          context.dispatch('getTags', {force: true});
        }

        if (payload.tagsInResponse) {
          context.commit('tags', response.data.tags);
        }

        context.commit('sidebarSpinner', false);

        //context.commit('sidebarStatus', 'Tags synchronized just now.');
        //setTimeout(function () {
        //  context.commit('sidebarStatus', '');
        //}.bind(this), 1000);

        payload && payload.onSuccess ? payload.onSuccess(response) : null;


      }.bind(this), function(error){

        context.commit('sidebarSpinner', false);

        if (error.response && error.response.data) {
          window.alert('apiSyncTags Error: ' + error.response.data);
        } else {
          window.alert('apiSyncTags Error: ' + 'something went wrong...');
        }

        context.dispatch('getTags', {force: true});

        payload && payload.onError ? payload.onError(error) : null;
      });

    },



    apiNewTag: function(context, payload){
      context.commit('sidebarSpinner', true);
      Vue.axios({
        url: context.state.api + '/new-tag/',
        method: 'post',
        data: {
          name: payload.name,
          parent: payload.parent ? payload.parent : void 0,
        },
      }).then(function(response){
        context.commit('tags', response.data.tags);
        context.commit('sidebarSpinner', false);
        payload && payload.onSuccess ? payload.onSuccess(response) : null;
      }.bind(this), function(error){
        //Vue.alertify.error('Something went wrong while adding tag...');
        var output = Object.keys(error.response.data).map(function(key, index) {
          return key+' '+error.response.data[key]+'<br>'; });
        Vue.alertify.alert(output);
        context.commit('sidebarSpinner', false);
        payload && payload.onError ? payload.onError(error) : null;
      });
    },


    apiDeleteTag: function(context, payload){
      context.commit('sidebarSpinner', true);
      Vue.axios({
        url: context.state.api + '/delete-tag/',
        method: 'delete',
        params: {
          pk: payload.pk,
        },
      }).then(function(response){
        context.commit('deleteTag', {pk: payload.pk});
        context.commit('sidebarSpinner', false);
        payload && payload.onSuccess ? payload.onSuccess(response) : null;
      }.bind(this), function(error){
        var output = Object.keys(error.response.data).map(function(key, index) {
          return key+' '+error.response.data[key]+'<br>'; });
        Vue.alertify.alert(output);
        context.commit('sidebarSpinner', false);
        payload && payload.onError ? payload.onError(error) : null;
      });
    },


    followOrUnfollowTag: function(context, tagPK){
      if (context.state.user.is_anonymous) {
        context.commit('dialogbox', {kind: 'signinup', subkind: 'signin'});
        return false;
      }
      Vue.axios({
        url: context.state.api + '/foru-tag/',
        method: 'post',
        data: {
          tag_pk: tagPK,
        }
      }).then(function(response){
        context.dispatch('getFollowedTagsUsers', 1);
        context.commit('eventFollowOrUnfollowTag');
      }.bind(this));
    },


    /*------------------------------------*\
      Utils & Helpers
    \*------------------------------------*/

    localStorageBackwardsCompatibility: function(context){

      // viewer expand level 1
      (localStorage.getItem('viewerExpandLevel') === '1') && localStorage.setItem('viewerExpandLevel', '2');

      // chrome stuff
      localStorage.removeItem('chromeOpenSave');
      localStorage.removeItem('chromeWidget');

    },

    syncLocalStorage: function(context, itemName){
      var value = localStorage.getItem(itemName);
      if (!value) {
        value = context.state[itemName];
        localStorage.setItem(itemName, JSON.stringify(value));
      }
      try { var parsedValue = JSON.parse(value) }
      catch(e) { var parsedValue = JSON.parse(JSON.stringify(value)) }
      context.commit(itemName, parsedValue);
    },

    resetApp: function(context){

      // reset state
      context.commit('user', null);
      context.commit('unreadNotifs', null);
      context.commit('hqUpdates', null);
      context.commit('tags', null);
      context.commit('tagsFilter', '');
      context.commit('followedTagsUsers', null);
      context.commit('profilePageMeta', null);
      context.commit('tagPageMeta', null);
      context.commit('contentScrolled', null);
      context.commit('dialogbox', 0);
      context.commit('viewer', 0);
      context.commit('jumpnotif', 0);
      context.commit('sidebarVisible', 0);
      context.commit('sidebarSpinner', 0);
      context.commit('sidebarStatus', '');
      context.commit('activityVisible', 0);
      context.commit('userMenu', 0);
      context.commit('unseenItems', null);
      context.commit('itemsSelected', []);
      context.commit('itemsSelectMode', false);
      context.commit('items', null);
      context.commit('itemsLoading', null);
      context.commit('itemsMeta', null);

      context.commit('appTheme', 'default'),
      context.commit('itemLayout', 'panel');
      context.commit('itemLayoutFavs', false);
      context.commit('itemClickAction', 'viewer');
      context.commit('viewerComments', false);
      context.commit('transitionsEnabled', true);
      context.commit('sidebarCollapse', true);
      context.commit('viewerExpandLevel', context.state.chrome ? 4 : 2);
      context.commit('topbarUseTagColor', true);
      context.commit('chromeLastPage', null);
      context.commit('chromeOpenSave', false);
      context.commit('chromeWidget', false);

      // delete cookies
      Vue.cookie.delete('redirectAfterAuth');
      Vue.cookie.delete('jwttoken');

      // apm
      window._apm && window._apm.setUserContext({
        id: null,
        username: null,
        email: null,
      })

      // dispatch startApp
      context.dispatch('startApp', 1);

    }




  },














})
