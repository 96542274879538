<script>
export default {

  props: [
    'url',
    'item',
  ],

  data: function(){
    return {
      fullSize: 0,
      deferLoad: true,
      imgBiggerThanContainer: false,
    };
  },

  mounted: function(){
    this.enableDeferLoad();
  },

  watch: {
    url: function(){
      this.fullSize = 0;
      this.imgBiggerThanContainer = 0;
      this.enableDeferLoad();
    },
    item: function(){
      this.fullSize = 0;
      this.imgBiggerThanContainer = 0;
      this.enableDeferLoad();
    },
    '$store.state.viewerExpandLevel': function(){
      this.calculateImgBiggerThanContainer();
    },
  },

  methods: {

    calculateImgBiggerThanContainer: function(){
      this.imgBiggerThanContainer = 0;

      if ( (!this.$refs.imgEl) || (!this.$refs.embedimgInner) ) { return false }

      var embedimgInner = this.$refs.embedimgInner;
      var imgEl = this.$refs.imgEl;

      if ( imgEl.naturalWidth > (embedimgInner.clientWidth-20) ) {
        this.imgBiggerThanContainer = 1;
      }

      if ( imgEl.naturalHeight > (embedimgInner.clientHeight-20) )  {
        this.imgBiggerThanContainer = 1;
      }

    },

    enableDeferLoad: function(){
      this.deferLoad = true;
      setTimeout(function() {
        this.deferLoad = false;
      }.bind(this), 100);
    },

  },

};
</script>


<template>
<div class="embedimg" @click.prevent="fullSize=!fullSize" :class="{'mod-full-size': fullSize, 'mod-bigger-than-container': imgBiggerThanContainer}">
  <div class="embedimg-inner common-light-scrollbar">

    <div v-if="deferLoad" class="embedimg-spinner">
      <div class="embedimg-spinner-inner">
        <spinner kind="wave"></spinner>
      </div>
    </div>

    <div v-else class="embedimg-content" ref="embedimgInner">
      <img class="embedimg-img" :src="url" ref="imgEl"  @load="calculateImgBiggerThanContainer">
    </div>




  </div>

  <div class="embedimg-bg">
    <div class="embedimg-bg-inner" :style="{backgroundImage: 'url('+url+')'}"></div>
  </div>

</div>
</template>


<style lang="scss" scoped>

  @import "../../../common/style/_variables.scss";


  .embedimg {
    display: block;
    width: auto;
    height: 100%;
    position: relative;

    user-select: none;

    background-color: #0e0e0e;


    &.mod-bigger-than-container {
      cursor: zoom-in;
    }

    &.mod-bigger-than-container.mod-full-size {
      cursor: zoom-out;
    }

  }


    .embedimg-inner {
      overflow: auto;
      width: auto;
      height: 100%;
      display: block;
      position: relative;
      z-index: 10;
    }


    .embedimg-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

      .embedimg-spinner-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
      }





    .embedimg-content {
      height: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      position: relative;


      .mod-full-size & {
        padding: 0;
      }
      .mod-full-size.mod-bigger-than-container & {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
      }

    }


    .embedimg-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 5;
      overflow: hidden;
    }

    .embedimg-bg-inner {
      display: block;
      width: 110%;
      height: 110%;
      transform: translate(-5%, -5%);
      background-size: cover;
      background-position: 50% 50%;
      z-index: 5;
      opacity: .2;
      filter: blur(25px);
    }



    .embedimg-img {
      display: block;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
      position: relative;
      z-index: 10;

      object-fit: contain;

      //box-shadow: 0 0 15px 0 rgba(black, .5);

      .mod-full-size & {
        object-fit: none;
        //min-width: 100%;
        width: auto;
        height: auto;
        max-width: none;
        max-height: none;
      }
    }

</style>
